import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {isValidPhoneNumber} from 'react-phone-number-input';
import {strings} from '../../../localization/i18n';
import PhoneController from '../../../ui/comps/src/dynamic-form/form-components/phone-component/PhoneController.component';
import OTPVerificationComponent from './OTPVerificationComponent';

export default function VerifiablePhone(props: any): JSX.Element {
	const {
		getNetworkCallProviderForResend,
		getNetworkCallProviderForVerify,
		sessionStorageKeys,
	} = props;

	const {getValues, setValue, clearErrors} = useFormContext();

	const [verified, setVerified] = useState<boolean>(
		// getValues(props?.fieldData?.name)?.verified ?? false,
		props.dynamicConstraints?.defaultValue?.verified ?? false,
	);
	const [showModal, setShowModal] = useState<boolean>(false);

	const handleModalClose = () => {
		setShowModal(false);
	};

	const [phoneNumberObj, setPhoneNumberObj] = useState<any>({
		countryCode: props?.dynamicConstraints?.defaultValue?.countryCode,
		phoneNumber: props?.dynamicConstraints?.defaultValue?.phoneNumber,
	});

	const handleOTPSentClick = (e: any) => {
		e.preventDefault();
		const value = getValues(props.fieldData.name);
		if (
			value != null &&
			value.countryCode != null &&
			value.phoneNumber != null &&
			isValidPhoneNumber(value.countryCode + value.phoneNumber)
		) {
			setShowModal(true);
			setPhoneNumberObj(value);
		}
	};

	useEffect(() => {
		if (props.dynamicConstraints.defaultValue != null) {
			const phoneNumberModel = {
				countryCode: props.dynamicConstraints.defaultValue.countryCode,
				phoneNumber: props.dynamicConstraints.defaultValue.phoneNumber,
				verified: props.dynamicConstraints.defaultValue.verified ?? false,
			};
			setPhoneNumberObj(phoneNumberModel);
			setValue(props.fieldData.name, phoneNumberModel);
		}
	}, []);

	return (
		<>
			<div
				id={`div_om_contact_otp_${props?.fieldData?.name}`}
				className={'form-item'}
			>
				<div id={`div_contact_otp_${props?.fieldData?.name}`}>
					{strings(props?.fieldData?.label) ?? ''}
				</div>
				{/* view mode */}
				{/* {JSON.stringify(contact)} */}
				<PhoneController
					fieldData={props.fieldData}
					configProvider={props.configProvider}
					control={props.control}
					errors={props.errors}
					verified={verified ?? false}
					dynamicConstraints={props.dynamicConstraints}
					isViewOnlyMode={props.isViewOnlyMode}
					additionalValidator={(_data: any) => {
						if (!verified) {
							return strings('ui.comps.verifiable.phone', {
								label: strings(props.fieldData.label ?? ''),
							});
						}
						return true;
					}}
					callbackHandler={{
						onChange: (data: any) => {
							if (
								(props.dynamicConstraints?.defaultValue?.countryCode !==
									data?.countryCode ||
									props.dynamicConstraints?.defaultValue?.phoneNumber !==
										data?.phoneNumber) &&
								verified === true
							) {
								setVerified(false);
							}
						},
					}}
				/>
				{!verified && (
					<div className="text-right mt5">
						<button
							onClick={e => handleOTPSentClick(e)}
							className="btn btn-link noBtnStyle"
						>
							{strings('otpScreen.verifyPhoneButton')}
						</button>
					</div>
				)}
				{showModal && (
					<OTPVerificationComponent
						fieldData={props.fieldData}
						handleModalClose={handleModalClose}
						showModal={showModal}
						unverifiedNumber={phoneNumberObj}
						otpLength={props?.fieldData?.otpLength ?? 4}
						setShowModal={setShowModal}
						clearErrors={clearErrors}
						setVerified={setVerified}
						setValue={setValue}
						phoneNumberObj={phoneNumberObj}
						getNetworkCallProviderForResend={getNetworkCallProviderForResend}
						getNetworkCallProviderForVerify={getNetworkCallProviderForVerify}
						sessionStorageKeys={sessionStorageKeys}
						onCancel={() => {
							setVerified(false);
							setValue(props.fieldData.name, {
								...(phoneNumberObj ?? {}),
								verified: false,
							});
						}}
						onSuccessfulVerification={() => {
							clearErrors(props.fieldData.name);
							setVerified(true);
							setValue(props.fieldData.name, {
								...(phoneNumberObj ?? {}),
								verified: true,
							});
							setShowModal(false);
						}}
					/>
				)}
			</div>
		</>
	);
}
