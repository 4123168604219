import {Service} from 'typedi';

import encryptedStorageHelperInstance from '../../../../../../storage/EncryptedStorageHelper';
import {sessionStorageKeys} from '../../../../interface/storage/constants/SessionKeys';

@Service()
export default class RoleManager {
	private roles: Array<string> = [];

	constructor() {
		this.refreshRoles();
	}

	/**
	 * This method is used to refresh the roles in role manager instance.
	 * This method fetched the roles from storage and updated the member variable.
	 */
	refreshRoles(): void {
		encryptedStorageHelperInstance
			.getItem<Array<string>>(sessionStorageKeys.USER_ROLES)
			.then((roles: Array<string> | null) => {
				this.roles = roles ?? [];
			})
			.catch(error => {
				console.log(
					'RoleManager.constructor: error fetching roles: ',
					JSON.stringify(error, null, 4),
				);
				this.roles = [];
			});
	}

	/**
	 * This method is used to check whether a role is available to the user.
	 * @param {string} role to check
	 * @returns {boolean}
	 */
	isRoleAvailable(role: string): boolean {
		let isValid = false;
		if (this.roles.indexOf(role) > -1) {
			isValid = true;
		}
		return isValid;
	}

	/**
	 * This method is used to check whether a role is available to the user.
	 * @param {Array<string>} roles to check
	 * @returns {boolean}
	 */
	areRolesAvailable(roles: Array<string>): boolean {
		let isValid = false;
		roles.forEach((role: string) => {
			isValid = this.isRoleAvailable(role);
		});
		return isValid;
	}
}
