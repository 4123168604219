export enum NotificationMessageType {
	alert,
	toast,
	globalToast,
}

export enum NotificationMessageLevel {
	default = 'default',
	information = 'information',
	success = 'success',
	error = 'error',
	warning = 'warning',
 }

export enum NotificationVisibility {
	local = 'local',
	global = 'global',
	dialog = 'dialog'
}

export type NotificationMessage = {
	message: string;
	heading?: string; // only used for alerts
	positiveActionTitle?: string; // only used for alerts
	negativeActionTitle?: string; // only used for alerts
	positiveAction?: () => void; // only used for alerts
	negativeAction?: () => void; // only used for alerts
	type: NotificationMessageType;
	level: NotificationMessageLevel;
	visibility?: NotificationVisibility;
	closeButtonEnabled?: boolean|false;
	persistOnRouteChange?: boolean|false;
	documentIdToFocus?: string;
	callbackHandler?: () => void;
};
