import {AreaCodeModel} from '../common/AreaCodeModel';
import BaseResponseModel from '../../../../../../../network/interface/BaseResponseModel';

export default class FetchCountriesResponse implements BaseResponseModel {
	private readonly _countries: Array<AreaCodeModel>;

	constructor({countries}: FetchCountriesResponseType) {
		this._countries = countries ?? [];
	}

	/**
	 * This method returns the response received from
	 * /minerva/moCountryDropDown/fetchCountries API call
	 * @returns {FetchCountriesResponseType}
	 */
	getResponseDataObject(): FetchCountriesResponseType {
		return {
			countries: this._countries,
		};
	}
}

export type FetchCountriesResponseType = {
	countries?: Array<AreaCodeModel>;
};
