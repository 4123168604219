class DocumentReferenceSearchCriteriaValidationModel {
    constructor(
        private _constraints: string = '',
        private _isError: boolean = false
    ) {}
    set constraintsError(value: string) {
        this._constraints = value
    }
    get constraintsError(): string {
        return this._constraints
    }
    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}
export default DocumentReferenceSearchCriteriaValidationModel