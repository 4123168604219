class UpdateEditDocumentValidationModel {
    constructor(
        private _conditionIdError: string = '',
        private _encounterIdError: string = '',
        private _locationIdError: string = '',
        private _physicianIdError: string = '',
        private _createdDateError: string = '',
        private _isError: boolean = false
    ) {}
    set conditionIdError(value: string) {
        this._conditionIdError = value
    }
    get conditionIdError(): string {
        return this._conditionIdError
    }
    set encounterIdError(value: string) {
        this._encounterIdError = value
    }
    get encounterIdError(): string {
        return this._encounterIdError
    }
    set locationIdError(value: string) {
        this._locationIdError = value
    }
    get locationIdError(): string {
        return this._locationIdError
    }
    set physicianIdError(value: string) {
        this._physicianIdError = value
    }
    get physicianIdError(): string {
        return this._physicianIdError
    }
    set createdDateError(value: string) {
        this._createdDateError = value
    }
    get createdDateError(): string {
        return this._createdDateError
    }
    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}
export default UpdateEditDocumentValidationModel