import {ViewableField} from '../../../../../common/src/application/dynamicFormUtil/types/DynamicForm.types';
import {strings} from '../../../../../localization/i18n';

const ViewableFieldComponent = ({
	viewableField,
}: {
	viewableField: ViewableField;
}) => {
	return (
		<>
			{viewableField.type === 'text' ? (
				<>
					<span>
						<b>{strings(viewableField.label)}</b>:
					</span>
					<span>{viewableField.value}</span>{' '}
				</>
			) : (
				''
			)}
		</>
	);
};
export default ViewableFieldComponent;
