import {strings} from '../../../../localization/i18n';

const InformationText = (props: any): JSX.Element => {
	const {className, text, level} = props;

	// default is div
	const DynamicInfoTag = level
		? (`h${level}` as keyof JSX.IntrinsicElements)
		: 'div';

	return (
		<>
			{text && (
				<DynamicInfoTag className={className}>{strings(text)}</DynamicInfoTag>
			)}
		</>
	);
};

export default InformationText;
