import DynamicForm from '../../ui/comps/src/dynamic-form/dynamic.form';
import {ConfigSingleton} from '../../utils/wrappers/ui-comp/config-transformer';

import {sandboxFormData} from './SandboxFormData';

export default function FormSandbox(props: any) {
	let configObj = ConfigSingleton.getInstance();

	return (
		<div>
			<div>Form sandbox</div>
			<DynamicForm
				dynamicFormData={sandboxFormData}
				configProvider={configObj}
			/>
		</div>
	);
}
