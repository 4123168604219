import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import './formBuilder.scss';


const FormBuilderComponent = (): JSX.Element => {
	const navigate = useNavigate();

	const location = useLocation();
	console.log(location.pathname);

	const gotoAttributes = () => {
		return navigate('attributes');
	};

	const gotoLayouts = () => {
		return navigate('layouts');
	};

	const gotoSection = () => {
		return navigate('/formbuilder/sections');
	};

	const gotoRules = () => {
		return navigate('/formbuilder/rules');
	};

	const gotoTest = () => {
		return navigate('/formbuilder/test');
	};

	return (
		<div className="cardOuter-blank">
			<div className="outer-box">
				<div className="outer-box-left">
					<div className="mt15">
						<h3>Form Builder</h3>

						<div className="usrLeftMenu mt15">
							<ul>
								<li>
									<a
										className={
											location.pathname
												.toLocaleLowerCase()
												.includes('attribute')
												? 'active'
												: ''
										}
										href="javascript:void(0);"
										onClick={gotoAttributes}>
										Attributes
									</a>
								</li>
								<li>
									<a
										href="javascript:void(0);"
										className={
											location.pathname.toLocaleLowerCase().includes('section')
												? 'active'
												: ''
										}
										onClick={gotoSection}>
										Sections
									</a>
								</li>
								<li>
									<a
										href="javascript:void(0);"
										className={
											location.pathname.toLocaleLowerCase().includes('layout')
												? 'active'
												: ''
										}
										onClick={gotoLayouts}>
										Layouts
									</a>
								</li>
								<li>
									<a
										href="javascript:void(0);"
										className={
											location.pathname.toLocaleLowerCase().includes('rule')
												? 'active'
												: ''
										}
										onClick={gotoRules}>
										Rules
									</a>
								</li>
								<li>
									<a
										href="javascript:void(0);"
										className={
											location.pathname.toLocaleLowerCase().includes('test')
												? 'active'
												: ''
										}
										onClick={gotoTest}>
										Test Component
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="outer-box-left-shadow"></div>
				<div className="outer-box-right">
					<div className="cardOuter formBuilderTable">
						<Outlet/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormBuilderComponent;
