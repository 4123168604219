import InformationText from '../information-text/information.component';
import {LinkCompActionType} from './link.components.type';
import {strings} from '../../../../localization/i18n';

const LinkComponent = (props: LinkCompActionType): JSX.Element => {
	const {
		url,
		isTargetBlank,
		text,
		level,
		label,
		clickHandler,
		attributeCssClassName,
	} = props.fieldData ? props.fieldData : props;

	const className = attributeCssClassName;

	const {linkActionHandler, dynamicConstraints, isViewOnlyMode} = props;

	const isDisabled =
		isViewOnlyMode === true
			? isViewOnlyMode
			: dynamicConstraints?.disabled ?? false;

	return url ? (
		text ? (
			label ? (
				<>
					{strings(label)}
					<a
						href={url}
						className={`${className} ${isDisabled ? 'disabled' : ''}`}
						target={isTargetBlank ? 'blank' : ''}>
						<InformationText level={level} text={text} />
					</a>
				</>
			) : (
				<>
					<a
						href={url}
						className={`${className} ${isDisabled ? 'disabled' : ''}`}
						target={isTargetBlank ? 'blank' : ''}>
						<InformationText level={level} text={text} />
					</a>
				</>
			)
		) : (
			<></>
		)
	) : text ? (
		label ? (
			<>
				<div
					className={
						props.fieldData ? `form-item ${className}` : `${className}`
					}>
					<div className={props.fieldData ? 'form-group' : ''}>
						{strings(label)}
						<span className={className}>
							<button
								role="link"
								type="button"
								disabled={isDisabled}
								onClick={() =>
									clickHandler
										? clickHandler()
										: linkActionHandler
										? linkActionHandler()
										: null
								}
								className={'btn btn-link noBtnStyle vat preRegBtn'}>
								<InformationText level={level} text={text} />
							</button>
						</span>
					</div>
				</div>
			</>
		) : (
			<>
				<div
					className={
						props.fieldData ? `form-item ${className}` : `${className}`
					}>
					<div className={props.fieldData ? 'form-group' : ''}>
						<button
							role="link"
							type="button"
							disabled={isDisabled}
							onClick={() =>
								clickHandler
									? clickHandler()
									: linkActionHandler
									? linkActionHandler()
									: null
							}
							className={'btn btn-link noBtnStyle vat'}>
							<InformationText level={level} text={text} />
						</button>
					</div>
				</div>
			</>
		)
	) : (
		<></>
	);
};

export default LinkComponent;
