import {Service} from 'typedi';
import APIExecutor, {APIRequest} from '../../../network/APIExecutor';
import {APIUrl} from '../../../network/values/URLInfo';
import CustomizeWidgetRequestModel from '../models/requestModel/customizeWidgetRequestModel';

@Service()
class DashboardNetworkRepository {
	/**
	 * fetches user's dashboard details
	 * @returns : {Promise<Record<string, any>>}
	 */
	getUserDashboards(): Promise<Record<string, any>> {
		const userDashboards = APIExecutor.postHTTP(
			new APIRequest(APIUrl.GET_USER_DASHBOARDS, {}),
		);
		return userDashboards;
	}

	customizeWidget(
		customizeWidgetRequestModel: CustomizeWidgetRequestModel,
	): Promise<Record<string, any>> {
		const customizeWidget = APIExecutor.postHTTP(
			new APIRequest(APIUrl.CUSTOMIZE_WIDGET, customizeWidgetRequestModel),
		);
		return customizeWidget;
	}
}

export default DashboardNetworkRepository;
