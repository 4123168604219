import _ from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
	extractDefaultStateAndConstraints,
	updateForm,
	getWatchableFields,
	getParsedFormConfig,
	removeSection as _removeSection,
	addSection as _addSection,
	parseFormModel,
	removeSectionFromFormModel,
	removeLastSection,
	addFirstSection,
} from '../../../../../common/src/application/dynamicFormUtil/dynamicFormUtil';
import {
	DynamicFormDataV2,
	FormState,
} from '../../../../../common/src/application/dynamicFormUtil/types/DynamicForm.types';
import {DynamicFieldConstraints} from '../../../../../common/src/application/dynamicFormUtil/types/FormRules.types';

/**
 * use this hook for fetching dynamic constraints to be applied on a form
 * @param dynamicFormData form data from api response
 * @param _formModel form model
 * @returns dynamic form render hook
 */
export default function useDynamicForm(
	dynamicFormData: DynamicFormDataV2,
	_formModel?: FormState,
) {
	// resultant dynamicFormData
	const [formConfig, setFormConfig] = useState<DynamicFormDataV2>(() => ({
		...getParsedFormConfig(dynamicFormData, _formModel),
	}));

	const [formModel, setFormModel] = useState<FormState | null>(
		_formModel != null ? _.cloneDeep(parseFormModel(_formModel)) : null,
	);

	useEffect(() => {
		setFormModel(() =>
			_formModel != null ? _.cloneDeep(parseFormModel(_formModel)) : null,
		);
	}, [_formModel]);

	function addSection(sectionName: string) {
		setFormConfig(prev => {
			if (!prev.formData[0].isHidden) {
				return _.cloneDeep({
					rules: prev.rules,
					formData: _addSection(prev.formData, sectionName),
				});
			} else {
				let newData = _.cloneDeep(prev);
				newData.formData[0].isHidden = false;
				return _.cloneDeep({
					rules: prev.rules,
					formData: addFirstSection(newData.formData),
				});
			}
		});
	}

	const removeSection = useCallback((sectionName: string) => {
		setFormConfig(prev => {
			if (prev.formData.length === 1) {
				let newData = _.cloneDeep(prev);
				newData.formData[0].isHidden = true;
				return _.cloneDeep({
					rules: prev.rules,
					formData: removeLastSection(newData.formData),
				});
			} else {
				return _.cloneDeep({
					rules: prev.rules,
					formData: _removeSection(prev.formData, sectionName),
				});
			}
		});
		setFormModel(prev =>
			prev != null
				? _.cloneDeep(removeSectionFromFormModel(prev, sectionName))
				: null,
		);
	}, []);

	useEffect(() => {
		setFormConfig(() => ({
			...getParsedFormConfig(dynamicFormData, _formModel),
		}));
	}, [dynamicFormData, _formModel]);

	// for fallback and reset handling
	const [defaultStateAndConstraints, setDefaultStateAndConstraints] = useState<{
		state: FormState;
		constraints: DynamicFieldConstraints;
	}>(extractDefaultStateAndConstraints(formConfig.formData, formModel));

	// fields to watch over (value of these fields cause dynamicity on other fields)
	const fieldsToWatch = useMemo(
		() => getWatchableFields(formConfig.rules ?? []),
		[formConfig],
	);

	// constraints for rendering and input config
	const [dynamicConstraints, setDynamicConstraints] =
		useState<DynamicFieldConstraints>(() => ({
			...updateForm(
				// merge form state and default values here
				defaultStateAndConstraints.state,
				formConfig.rules ?? [],
				formConfig.formData ?? [],
				defaultStateAndConstraints.constraints,
			),
		}));

	// initial constraints
	useEffect(() => {
		setDefaultStateAndConstraints(() => ({
			...extractDefaultStateAndConstraints(formConfig.formData, formModel),
		}));
	}, [formConfig, formModel]);

	// initial constraints
	useEffect(() => {
		const x = updateForm(
			defaultStateAndConstraints.state,
			formConfig.rules ?? [],
			formConfig.formData ?? [],
			defaultStateAndConstraints.constraints,
		);
		setDynamicConstraints(() => x);
	}, [
		formConfig,
		defaultStateAndConstraints.state,
		defaultStateAndConstraints.constraints,
	]);

	// update form constraints according to the form state
	function updateConstraints(formState: FormState) {
		const x = updateForm(
			formState,
			formConfig.rules ?? [],
			formConfig.formData ?? [],
			defaultStateAndConstraints.constraints,
		);
		setDynamicConstraints(() => x);
	}
	return {
		formConfig,
		defaultStateAndConstraints,
		fieldsToWatch,
		formRenderConstraints: dynamicConstraints,
		updateConstraints,
		addSection,
		removeSection,
		formModel,
	};
}
