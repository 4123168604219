import {
	DynamicFormDataV2,
	FormField,
	FormFieldType,
	FormModel,
	FormSegment,
	FormState,
	SubFieldType,
} from './types/DynamicForm.types';
import {
	DynamicFieldConstraints,
	FormFieldProperties,
	UpdatedFormRule,
	UpdatedFormRules,
} from './types/FormRules.types';
import {unset} from 'lodash/fp';
import {FormData} from './types/DynamicForm.types';
import _ from 'lodash';
import flatten from 'flat';
import {strings} from '../../../../localization/i18n';

/**
 * extracts default (fallback) form state and constraints
 * for fallbacks, resets, and first renders
 * @param formSegments form segments from api response
 * @param formModel form model
 * @returns default (fallback) form state and constraints
 */
export function extractDefaultStateAndConstraints(
	formSegments: FormData,
	formModel?: FormState | null,
): {
	state: FormState;
	constraints: DynamicFieldConstraints;
} {
	if (formSegments == null || formSegments.length === 0) {
		return {
			state: {},
			constraints: {},
		};
	}

	const defaultFormState: FormState = {};
	const dynamicFieldConstraints: DynamicFieldConstraints = {};

	formSegments?.forEach(formSegment => {
		formSegment?.formFields?.forEach(formField => {
			// form state
			defaultFormState[formField.name] =
				formModel?.[formField.name] ?? formField.defaultValue ?? null;

			// form constraints
			dynamicFieldConstraints[formField.name] = {
				disabled: formField.disabled ?? false,
				defaultValue:
					formModel?.[formField.name] ?? formField.defaultValue ?? null,
				required: formField.constraints?.required?.value ?? false,
				visible: formField.visible ?? true,
				helpText: formField.helpText ?? null,
				errorMessage: getErrorMessageForFormField(formField),
				maxDate: formField.maxDate ?? null,
				minDate: formField.minDate ?? null,
				disableFuture: formField.disableFuture ?? false,
				disablePast: formField.disablePast ?? false,
			};

			if (formField.fields != null) {
				if (formField?.fields?.country != null) {
					defaultFormState[formField.fields.country.name] =
						formModel?.[formField.fields.country.name];
					dynamicFieldConstraints[formField.fields.country.name] = {
						disabled: formField.fields.country.disabled ?? false,
						defaultValue:
							formModel?.[formField.fields.country.name] ??
							formField.fields.country.defaultValue ??
							null,
						required:
							formField.fields.country.constraints?.required?.value ?? false,
						visible: formField.fields.country.visible ?? true,
						helpText: formField.fields.country.helpText ?? null,
					};
				}
				if (formField?.fields?.state != null) {
					defaultFormState[formField.fields.state.name] =
						formModel?.[formField.fields.state.name];
					dynamicFieldConstraints[formField.fields.state.name] = {
						disabled: formField.fields.state.disabled ?? false,
						defaultValue:
							formModel?.[formField.fields.state.name] ??
							formField.fields.state.defaultValue ??
							null,
						required:
							formField.fields.state.constraints?.required?.value ?? false,
						visible: formField.fields.state.visible ?? true,
						helpText: formField.fields.state.helpText ?? null,
					};
				}
				if (formField?.fields?.city != null) {
					defaultFormState[formField.fields.city.name] =
						formModel?.[formField.fields.city.name];
					dynamicFieldConstraints[formField.fields.city.name] = {
						disabled: formField.fields.city.disabled ?? false,
						defaultValue:
							formModel?.[formField.fields.city.name] ??
							formField.fields.city.defaultValue ??
							null,
						required:
							formField.fields.city.constraints?.required?.value ?? false,
						visible: formField.fields.city.visible ?? true,
						helpText: formField.fields.city.helpText ?? null,
					};
				}
				if (formField?.fields?.line1 != null) {
					defaultFormState[formField.fields.line1.name] =
						formModel?.[formField.fields.line1.name];
					dynamicFieldConstraints[formField.fields.line1.name] = {
						disabled: formField.fields.line1.disabled ?? false,
						defaultValue:
							formModel?.[formField.fields.line1.name] ??
							formField.fields.line1.defaultValue ??
							null,
						required:
							formField.fields.line1.constraints?.required?.value ?? false,
						visible: formField.fields.line1.visible ?? true,
						helpText: formField.fields.line1.helpText ?? null,
					};
				}
				if (formField?.fields?.line2 != null) {
					defaultFormState[formField.fields.line2.name] =
						formModel?.[formField.fields.line2.name];
					dynamicFieldConstraints[formField.fields.line2.name] = {
						disabled: formField.fields.line2.disabled ?? false,
						defaultValue:
							formModel?.[formField.fields.line2.name] ??
							formField.fields.line2.defaultValue ??
							null,
						required:
							formField.fields.line2.constraints?.required?.value ?? false,
						visible: formField.fields.line2.visible ?? true,
						helpText: formField.fields.line2.helpText ?? null,
					};
				}

				if (formField?.fields?.line3 != null) {
					defaultFormState[formField.fields.line3.name] =
						formModel?.[formField.fields.line3.name];
					dynamicFieldConstraints[formField.fields.line3.name] = {
						disabled: formField.fields.line3.disabled ?? false,
						defaultValue:
							formModel?.[formField.fields.line3.name] ??
							formField.fields.line3.defaultValue ??
							null,
						required:
							formField.fields.line3.constraints?.required?.value ?? false,
						visible: formField.fields.line3.visible ?? true,
						helpText: formField.fields.line3.helpText ?? null,
					};
				}

				if (formField?.fields?.zipcode != null) {
					defaultFormState[formField.fields.zipcode.name] =
						formModel?.[formField.fields.zipcode.name];
					dynamicFieldConstraints[formField.fields.zipcode.name] = {
						disabled: formField.fields.zipcode.disabled ?? false,
						defaultValue:
							formModel?.[formField.fields.zipcode.name] ??
							formField.fields.zipcode.defaultValue ??
							null,
						required:
							formField.fields.zipcode.constraints?.required?.value ?? false,
						visible: formField.fields.zipcode.visible ?? true,
						helpText: formField.fields.zipcode.helpText ?? null,
					};
				}

				if (formField?.fields?.county != null) {
					defaultFormState[formField.fields.county.name] =
						formModel?.[formField.fields.county.name];
					dynamicFieldConstraints[formField.fields.county.name] = {
						disabled: formField.fields.county.disabled ?? false,
						defaultValue:
							formModel?.[formField.fields.county.name] ??
							formField.fields.county.defaultValue ??
							null,
						required:
							formField.fields.county.constraints?.required?.value ?? false,
						visible: formField.fields.county.visible ?? true,
						helpText: formField.fields.county.helpText ?? null,
					};
				}
			}
		});
	});

	return {
		state: defaultFormState,
		constraints: dynamicFieldConstraints,
	};
}

/**
 * return new set of constraints with state change
 * @param formState form state
 * @param formRules form rules
 * @param formData form data
 * @param defaultFormConstraints default constraints
 * @returns new constraints
 */
export function updateForm(
	formState: any,
	formRules: UpdatedFormRules,
	formData: FormData,
	defaultFormConstraints: DynamicFieldConstraints,
): DynamicFieldConstraints {
	const dynamicFieldConstraints: DynamicFieldConstraints = {};

	const formFieldTypes = new Map();
	formData?.forEach(section => {
		section?.formFields?.forEach(formField => {
			formFieldTypes.set(formField.name, formField.type);
		});
	});

	// for every rule
	formRules.forEach(formRule => {
		const operator = formRule.parents.operator;

		let allConstraintsMet: boolean;
		if (operator === 'OR') {
			// for "OR" operator
			allConstraintsMet = false;
			formRule.parents.fields.forEach(parent => {
				allConstraintsMet ||
					(allConstraintsMet = applyOperator(
						parent.value,
						_.get(formState, parent.name + '.value') ??
							_.get(formState, parent.name),
						parent.comparator ?? '==',
					));
			});
		} else {
			// for "AND" operator
			allConstraintsMet = true;
			formRule.parents.fields.forEach(parent => {
				allConstraintsMet &&
					(allConstraintsMet = applyOperator(
						parent.value,
						_.get(formState, parent.name + '.value') ??
							_.get(formState, parent.name),
						parent.comparator,
					));
			});
		}

		formRule.children.forEach(child => {
			// if met, then change, else revert
			if (allConstraintsMet) {
				dynamicFieldConstraints[child] = {
					...defaultFormConstraints[child],
					...dynamicFieldConstraints[child],
					...unset('parents', unset('children', formRule)),
				};
			}
		});
	});

	return {...defaultFormConstraints, ...dynamicFieldConstraints};
}

/**
 * compares the expected and actual value of a field using the given operator
 * @param expectedValue expected value of the input field
 * @param actualValue actual value of the input field
 * @param operator operator to be applied
 * @returns result of the expression
 */
export function applyOperator(
	expectedValue: any,
	actualValue: any,
	operator: any,
): boolean {
	switch (operator) {
		case '>':
			return actualValue > expectedValue;
		case '<':
			return actualValue < expectedValue;
		case '<=':
			return actualValue <= expectedValue;
		case '>=':
			return actualValue >= expectedValue;
		case '!=':
			return actualValue != expectedValue;
		default:
			return actualValue === expectedValue;
	}
}

/**
 * gets the name of all watchable fields in a form
 * @param formRules form rules from api response
 * @returns array of watchable fields
 */
export function getWatchableFields(formRules: UpdatedFormRules): Array<string> {
	const watchableFields = new Set<string>();
	formRules.forEach(formRule => {
		formRule.parents.fields.forEach(parentField => {
			watchableFields.add(parentField.name);
		});
	});
	return Array.from(watchableFields);
}

/**
 * This method is used to remove a section from the provided form config and update the labelSuffix of each section.
 * labelSuffix is used to display title of the section.
 * @param formData
 * @param formSectionName
 * @returns {DynamicFormDataV2}
 */
export function removeSection(
	formData: FormData,
	formSectionName: string,
): FormData {

	const newFormConfigData = _.filter(formData, o => {
		return formSectionName !== o.name;
	});

	return parseReplicableFields(newFormConfigData);
}

/**
 * adds form segment to form config
 * @param formData form config to add section to
 * @param sectionNameToReplicate section name to replicate
 * @returns new form config with added segment
 */
export function addSection(
	formData: FormData,
	sectionNameToReplicate: string,
): FormData {
	const newFormData: FormData = [];

	// adds the segment
	formData.forEach((segment: FormSegment) => {
		const oldSection = _.cloneDeep(segment);
		if (
			segment.canReplicate &&
			segment.isReplicateEnabled &&
			sectionNameToReplicate === segment.name
		) {
			oldSection.isReplicateEnabled = false;
			oldSection.isRemoveEnabled = true;
			newFormData.push(oldSection);

			const toAdd = addNewSegment(segment);
			newFormData.push(...toAdd);
		} else {
			newFormData.push(oldSection);
		}
	});

	return parseReplicableFields(newFormData);
}

/**
 * This function is used to update various constraints in the visible form config based on the data received from the exposed function.
 * @param formData to update
 * @param name of the field to update
 * @param properties to update for the aforementioned field
 */
export function updateConstraintsForName(
	formData: FormData,
	name: string,
	properties: FormFieldProperties,
): FormData {
	const newFormData: FormData = [];
	formData.forEach((segment: FormSegment) => {
		const newSegment = _.cloneDeep(segment);
		newSegment.formFields = [];
		segment.formFields.forEach((field: FormField) => {
			const newField = _.cloneDeep(field);
			if (newField.name === name) {
				newField.options = properties.options;
			}
			newSegment.formFields.push(newField);
		});
		newFormData.push(newSegment);
	});
	return newFormData;
}

/**
 * This method is used to extract form data and parse the same for replicable sections.
 * @param {DynamicFormDataV2} formConfig
 * @param {FormModel | undefined} formModel
 * @returns {DynamicFormDataV2}
 */
export function getParsedFormConfig(
	formConfig: DynamicFormDataV2,
	formModel?: FormModel,
): DynamicFormDataV2 {
	const toReturn: DynamicFormDataV2 = {
		formData: [],
		rules: parseRules(formConfig),
	};

	const newFormData: FormData = _.cloneDeep(formConfig.formData);

	newFormData.forEach((segment: FormSegment) => {
		if (segment.canReplicate) {
			const toAdd = _.cloneDeep(segment);

			toAdd.arrayIndex = 0;
			toAdd.originalName = segment.name;
			toAdd.formFields.forEach((field: FormField) => {
				// retain original name
				field.originalName = `${field.name}`;
				if (field.fields != null) {
					if (field.fields.country != null) {
						field.fields.country.originalName = field.fields.country.name;
					}
					if (field.fields.state != null) {
						field.fields.state.originalName = field.fields.state.name;
					}
					if (field.fields.city != null) {
						field.fields.city.originalName = field.fields.city.name;
					}
					if (field.fields.line1 != null) {
						field.fields.line1.originalName = field.fields.line1.name;
					}
					if (field.fields.line2 != null) {
						field.fields.line2.originalName = field.fields.line2.name;
					}
					if (field.fields.line3 != null) {
						field.fields.line3.originalName = field.fields.line3.name;
					}
					if (field.fields.zipcode != null) {
						field.fields.zipcode.originalName = field.fields.zipcode.name;
					}
					if (field.fields.county != null) {
						field.fields.county.originalName = field.fields.county.name;
					}
				}
			});

			// toAdd.originalName will always be not null inside this block,
			// checking to maintain typescript null check
			if (toAdd.originalName) {
				toAdd.name = `${toAdd.originalName}.${toAdd.arrayIndex}`;
				toAdd.formFields.forEach((field: FormField) => {
					field.name = `${toAdd.name}.${field.originalName ?? field.name}`;
					if (field.fields != null) {
						if (field.fields.country != null) {
							field.fields.country.name = `${toAdd.name}.${
								field.fields.country.originalName ?? field.fields.country.name
							}`;
						}
						if (field.fields.state != null) {
							field.fields.state.name = `${toAdd.name}.${
								field.fields.state.originalName ?? field.fields.state.name
							}`;
						}
						if (field.fields.city != null) {
							field.fields.city.name = `${toAdd.name}.${
								field.fields.city.originalName ?? field.fields.city.name
							}`;
						}
						if (field.fields.line1 != null) {
							field.fields.line1.name = `${toAdd.name}.${
								field.fields.line1.originalName ?? field.fields.line1.name
							}`;
						}
						if (field.fields.line2 != null) {
							field.fields.line2.name = `${toAdd.name}.${
								field.fields.line2.originalName ?? field.fields.line2.name
							}`;
						}
						if (field.fields.line3 != null) {
							field.fields.line3.name = `${toAdd.name}.${
								field.fields.line3.originalName ?? field.fields.line3.name
							}`;
						}
						if (field.fields.county != null) {
							field.fields.county.name = `${toAdd.name}.${
								field.fields.county.originalName ?? field.fields.county.name
							}`;
						}
						if (field.fields.zipcode != null) {
							field.fields.zipcode.name = `${toAdd.name}.${
								field.fields.zipcode.originalName ?? field.fields.zipcode.name
							}`;
						}
					}
				});
			}

			toAdd.isReplicateEnabled = false;
			toAdd.isRemoveEnabled = true;
			toReturn.formData.push(toAdd);

			if (formModel?.[segment.name]?.length > 1) {
				toReturn.formData.push(
					...addNewSegment(toAdd, formModel?.[segment.name]?.length - 1),
				);
			}
			if (
				segment.minSections === 0 &&
				(formModel?.[segment.name]?.length === 0 ||
					formModel?.[segment.name]?.length == null)
			) {
				toReturn.formData = removeLastSection(toReturn.formData);
			}
		} else {
			toReturn.formData.push(_.cloneDeep(segment));
		}
	});

	toReturn.formData = parseReplicableFields(toReturn.formData);
	return toReturn;
}

function addNewSegment(segment: FormSegment, reps?: number): FormData {
	const toReturn: Array<FormSegment> = [];

	for (let i = 0; i < (reps ?? 1); i++) {
		const tempSegment = _.cloneDeep(segment);

		if (segment.arrayIndex != null) {
			tempSegment.arrayIndex = segment.arrayIndex + i + 1;
		}

		// tempSegment.originalName will always be not null inside this block,
		// checking to maintain typescript null check
		if (tempSegment.originalName) {
			tempSegment.name = `${tempSegment.originalName}.${tempSegment.arrayIndex}`;
			tempSegment.formFields.forEach((field: FormField) => {
				field.name = `${tempSegment.name}.${field.originalName ?? field.name}`;
				if (field.fields != null) {
					if (field.fields.country != null) {
						field.fields.country.name = `${tempSegment.name}.${
							field.fields.country.originalName ?? field.fields.country.name
						}`;
					}
					if (field.fields.state != null) {
						field.fields.state.name = `${tempSegment.name}.${
							field.fields.state.originalName ?? field.fields.state.name
						}`;
					}
					if (field.fields.city != null) {
						field.fields.city.name = `${tempSegment.name}.${
							field.fields.city.originalName ?? field.fields.city.name
						}`;
					}
					if (field.fields.line1 != null) {
						field.fields.line1.name = `${tempSegment.name}.${
							field.fields.line1.originalName ?? field.fields.line1.name
						}`;
					}
					if (field.fields.line2 != null) {
						field.fields.line2.name = `${tempSegment.name}.${
							field.fields.line2.originalName ?? field.fields.line2.name
						}`;
					}
					if (field.fields.line3 != null) {
						field.fields.line3.name = `${tempSegment.name}.${
							field.fields.line3.originalName ?? field.fields.line3.name
						}`;
					}
					if (field.fields.county != null) {
						field.fields.county.name = `${tempSegment.name}.${
							field.fields.county.originalName ?? field.fields.county.name
						}`;
					}
					if (field.fields.zipcode != null) {
						field.fields.zipcode.name = `${tempSegment.name}.${
							field.fields.zipcode.originalName ?? field.fields.zipcode.name
						}`;
					}
				}
			});
		}

		tempSegment.isReplicateEnabled = false;
		tempSegment.isRemoveEnabled = true;

		toReturn.push(tempSegment);
	}

	return toReturn;
}

function parseReplicableFields(formData: FormData): FormData {
	const toReturn = _.cloneDeep(formData);

	const sectionCounter: Record<string, {count: number; lastIndex: number}> = {};
	const firstElementIndexForSection: Record<string, number> = {};

	// updates counter and adds index to segments
	toReturn.forEach((segment: FormSegment, index: number) => {
		if (segment.canReplicate && segment.originalName != null) {
			if (sectionCounter[segment.originalName] != null) {
				sectionCounter[segment.originalName].count =
					sectionCounter[segment.originalName].count + 1;
				sectionCounter[segment.originalName].lastIndex = index;
			} else {
				sectionCounter[segment.originalName] = {
					count: 1,
					lastIndex: index,
				};
				firstElementIndexForSection[segment.originalName] = index;
			}
			segment.isRemoveEnabled = true; // set all to false
			segment.isReplicateEnabled = false; // set all to true
			segment.labelSuffix = ` - ${sectionCounter[segment.originalName].count}`;
			const replicableSegmentIndex =
				sectionCounter[segment.originalName].count - 1;
			segment.arrayIndex = replicableSegmentIndex;
			segment.name = `${segment.originalName}.${replicableSegmentIndex}`;
			segment.formFields.forEach((formField: FormField) => {
				formField.name = `${segment.name}.${formField.originalName}`;
			});
		}
	});

	// changes the replicateEnabled and removeEnabled properties
	Object.entries(sectionCounter).map(([_key, {count, lastIndex}]) => {
		if (toReturn.length >= lastIndex && toReturn[lastIndex] != null) {
			if (
				toReturn[lastIndex].maxSections != null &&
				(toReturn[lastIndex].maxSections ?? 0) > count
			) {
				toReturn[lastIndex].isReplicateEnabled = true;
			}

			if (
				toReturn[lastIndex].minSections != null &&
				(toReturn[lastIndex].minSections ?? 0) >= count &&
				firstElementIndexForSection[_key] != null
			) {
				toReturn[firstElementIndexForSection[_key]].isRemoveEnabled = false;
			}
		}
	});

	return toReturn;
}

export function parseFormModel(formModel: Record<string, any>): FormModel {
	// {a: [{x: y}, {x: z}]} => {a.0.x: y, a.1.x: z}
	let toReturn: FormModel = {};
	Object.keys(formModel).forEach(key => {
		if (Array.isArray(formModel[key])) {
			toReturn = {
				...toReturn,
				...flatten({[key]: formModel[key]}, {maxDepth: 3}),
			};
		} else {
			toReturn = {...toReturn, [key]: formModel[key]};
		}
	});
	return toReturn;
}

export default function parseRules(
	formConfig: DynamicFormDataV2,
): UpdatedFormRules {
	const newRules: UpdatedFormRules = [];

	// stores fieldName and maxSegment value (0 if not replicable)
	const isReplicableMap: Record<string, number> = {};
	const segmentMap: Record<string, string> = {};

	formConfig.formData.forEach(segment => {
		segment.formFields?.forEach(field => {
			isReplicableMap[field.name] = segment.maxSections ?? 0;
			segmentMap[field.name] = segment.name;
		});
	});

	// modify formRules
	formConfig.rules?.forEach(rule => {
		const areSomeParentsReplicable = rule.parents.fields.reduce(
			(prev, parentField) => {
				return prev || isReplicableMap[parentField.name] > 0;
			},
			false,
		);

		const areChildrenReplicable = rule.children.reduce(
			(prev, child) => prev && isReplicableMap[child] > 0,
			true,
		);

		if (areSomeParentsReplicable && areChildrenReplicable) {
			// [ONE TO ONE]
			const oneToOneRules: UpdatedFormRules = [];
			const timesToReplicate = isReplicableMap[rule.children[0]];
			for (let i = 0; i < timesToReplicate; i++) {
				const tempRule: UpdatedFormRule = _.cloneDeep(rule);

				tempRule.children = tempRule.children.map(
					child => `${segmentMap[child]}.${i}.${child}`,
				);
				tempRule.parents = {
					..._.cloneDeep(tempRule.parents),
					fields: tempRule.parents.fields.map(field => ({
						..._.cloneDeep(field),
						name:
							isReplicableMap[field.name] > 0
								? `${segmentMap[field.name]}.${i}.${field.name}`
								: field.name,
					})),
				};

				oneToOneRules.push(tempRule);
			}
			newRules.push(...oneToOneRules);
		} else if (!areSomeParentsReplicable && areChildrenReplicable) {
			// ONE TO MANY
			const oneToManyRule: UpdatedFormRule = _.cloneDeep(rule);
			const newChildren: Array<string> = [];
			oneToManyRule.children.forEach(child => {
				if (isReplicableMap[child] == 0) {
					newChildren.push(child);
				} else {
					for (let i = 0; i < isReplicableMap[child]; i++) {
						newChildren.push(`${segmentMap[child]}.${i}.${child}`);
					}
				}
			});
			oneToManyRule.children = newChildren;

			newRules.push(oneToManyRule);
		} else {
			// NOTHiNG TO CHANGE
			newRules.push(rule);
		}
	});

	return newRules;
}

export function removeSectionFromFormModel(
	formModel: FormModel,
	segmentNameAndIndex: string,
): FormModel {
	const newFormModel = _.cloneDeep(formModel);
	const [segmentName, index] = segmentNameAndIndex.split('.');
	return _.omitBy(
		newFormModel,
		(_value, key) =>
			key.split('.')[0] === segmentName && key.split('.')[1] === index,
	);
}

function getErrorMessageForFormField(
	formField: FormField,
): string | Record<string, string> {
	switch (formField.type) {
		case 'dropdown':
		case 'radio':
		case 'checkboxGroup':
		case 'checkbox':
			return (
				strings('forms.errorMessage.select') +
				strings(formField.label ?? '').toLowerCase()
			);
		case 'address':
			return {
				line1:
					strings('forms.errorMessage.enter') +
					strings(formField.fields?.line1?.label ?? '').toLowerCase(),
				line2:
					strings('forms.errorMessage.enter') +
					strings(formField.fields?.line2?.label ?? '').toLowerCase(),
				line3:
					strings('forms.errorMessage.enter') +
					strings(formField.fields?.line3?.label ?? '').toLowerCase(),
				country:
					strings('forms.errorMessage.select') +
					strings(formField.fields?.country?.label ?? '').toLowerCase(),
				state:
					strings('forms.errorMessage.select') +
					strings(formField.fields?.state?.label ?? '').toLowerCase(),
				city:
					strings('forms.errorMessage.select') +
					strings(formField.fields?.city?.label ?? '').toLowerCase(),
				county:
					strings('forms.errorMessage.select') +
					strings(formField.fields?.county?.label ?? '').toLowerCase(),
				zipcode:
					strings('forms.errorMessage.enter') +
					strings(formField.fields?.zipcode?.label ?? '').toLowerCase(),
			};
		case 'email':
		case 'phone':
		case 'text':
		case 'password':
		case 'number':
		case 'textarea':
			return (
				strings('forms.errorMessage.enter') +
				strings(formField.label ?? '').toLowerCase()
			);
		case 'date':
		case 'dateTime':
		case 'time':
			return (
				strings('forms.errorMessage.enterOrSelect') +
				strings(formField.label ?? '').toLowerCase()
			);
		case 'upload':
			return (
				strings('forms.errorMessage.upload') +
				strings(formField.label ?? '').toLowerCase()
			);
		default:
			return (
				strings('forms.errorMessage.enter') +
				strings(formField.label ?? '').toLowerCase()
			);
	}
}

export function removeLastSection(formData: FormData): FormData {
	const newFormData = _.cloneDeep(formData);
	newFormData[0].formFields.forEach(field => {
		if (field.customProps == null) {
			field.customProps = {
				originalVisible: field.visible,
			};
		} else {
			field.customProps.originalVisible = field.visible;
		}
		field.visible = false;
	});
	newFormData[0].isHidden = true;
	return newFormData;
}

export function addFirstSection(formData: FormData): FormData {
	const newFormData = _.cloneDeep(formData);
	newFormData[0].formFields.forEach(field => {
		field.visible = field.customProps?.originalVisible ?? false;
	});
	return newFormData;
}
