import {
	LoadingMsgObjType,
	TableHeader,
	TableProps,
} from '../../../../../../common/src/interface/dataGrid/IDataGrid';
import {strings} from '../../../../../../localization/i18n';
import Loader from '../../../Loader';
import parseAccess from '../../../utils/objectAccessParser/objectAccessParser';

import '../../DataGrid.scss';
import CustomColumnButtonsOrIcons from '../CustomColumnButtonsOrIcons';
import TableRowChild from '../TableRowChild';

export default function Table(props: TableProps) {
	const {
		headers,
		rowData,

		className,

		configProvider,

		rowSelectionEnabled = false,
		selectAllRowsEnabled,
		isLoading = false,

		rowRefs,
		selectAllRowRef,
		onSelectAllRecordsClick,
		onRowSelectClick,
		extraProps,

		tableRowChild,
		unavailableComponent,
		checkIsRowDisable,
		loadingMsgObject,
	} = props;

	const loadingMsgRecord: LoadingMsgObjType = {
		show: loadingMsgObject ? loadingMsgObject.show : false,
		msg:
			loadingMsgObject && loadingMsgObject.msg
				? loadingMsgObject.msg
				: strings('dataGrid.loadingRecords'),
	};

	const RenderCell = (_props: any) => {
		const {row, header} = _props;
		return (
			parseAccess(row, header.mappingField, header, configProvider) ??
			(header.includeComponent ? (
				header.includeComponent(_props)
			) : header.buttonOrIcons ? (
				<CustomColumnButtonsOrIcons {..._props} />
			) : unavailableComponent != null ? (
				unavailableComponent(_props)
			) : (
				<span className="unavailableText">{strings('dataGrid.unavailable')}</span>
			))
		);
	};

	return (
		<div className={className}>
			{(rowData == null || (Array.isArray(rowData) && rowData.length === 0)) &&
			!isLoading ? (
				// will be changed by UI team
				<div className="noRecordCard">
					<div className="pageBreak">
						<span aria-hidden="true" className="image"></span>
						<span className="text text-hint">
							{strings('docSearchObj.noRecord')}
						</span>
					</div>
				</div>
			) : (
				<>
					{isLoading && (
						<>
							<Loader />
							{loadingMsgRecord?.show && (
								<div className="noRecordCard">
									<div className="pageBreak">
										<span aria-hidden="true" className="image"></span>
										<span className="text text-hint">
											{loadingMsgRecord.msg}
										</span>
									</div>
								</div>
							)}
						</>
					)}
					{!isLoading && (
						<div className="tableLayout mt-3">
							<table>
								<thead>
									<tr>
										{rowSelectionEnabled && (
											<th className="checkbox-cell">
												{selectAllRowsEnabled && (
													<div className="form-check">
														<label
															htmlFor="selectAllInput"
															className={'sr-only'}>
															{strings('accessibility.selectAll')}
														</label>
														<input
															id="selectAllInput"
															className="form-check-input"
															type="checkbox"
															ref={selectAllRowRef}
															onClick={onSelectAllRecordsClick}
															defaultChecked={rowData && rowData[0]?.selectAll}
														/>
													</div>
												)}
											</th>
										)}

										{headers.map((header: TableHeader, cellIndex: number) => (
											<th
												key={header.mappingField}
												className={`td${cellIndex + 1}`}>
												<div>{strings(header.label)}</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{rowData?.map(
										(row: Record<string, any>, rowIndex: number) => (
											<>
												<tr
													key={`row_${rowIndex}`}
													className={`${
														checkIsRowDisable(row) ? 'disabled' : ''
													}`}>
													{rowSelectionEnabled && (
														<td className="first checkbox-cell">
															<div className="form-check">
																<label
																	htmlFor="selectCheckBox"
																	className={'sr-only'}>{strings('accessibility.select')}</label>
																<input
																	id="selectCheckBox"
																	className="form-check-input"
																	type="checkbox"
																	name={`row_${rowIndex}`}
																	ref={rowRefs.current[rowIndex]}
																	onClick={onRowSelectClick}
																	defaultChecked={
																		row?.selectAll ? true : row?.checked
																	}
																/>
															</div>
														</td>
													)}
													{headers.map(
														(header: TableHeader, cellIndex: number) => (
															<td key={`${header.mappingField}_${rowIndex}`}>
																<div>
																	<strong className="d-inline-block d-lg-none xs-label text-label">
																		{strings(header.label) + ': '}
																	</strong>
																	<RenderCell
																		row={row}
																		header={header}
																		extraProps={extraProps}
																	/>
																</div>
															</td>
														),
													)}
												</tr>
												<TableRowChild
													tableRowChild={tableRowChild}
													row={row}
													headers={headers}
													extraProps={extraProps}
												/>
											</>
										),
									)}
								</tbody>
							</table>
						</div>
					)}
				</>
			)}
		</div>
	);
}
