import {
	DynamicFormDataV2,
	FormSegment,
} from '../../common/src/application/dynamicFormUtil/types/DynamicForm.types';

const loginTypeSegment: FormSegment = {
	formFields: [
		{
			name: 'loginType',
			type: 'radio',
			options: [
				{value: 'email', label: 'user.info.email'},
				{value: 'username', label: 'user.info.username'},
				{value: 'phone', label: 'user.info.phone'},
			],
			defaultValue: 'username',
			constraints: {
				required: {
					value: true,
				},
			},
		},
	],
	name: 'loginTypeSegment',
	isCollapsable: false,
	label: '',
};

const loginCredentialsSegment: FormSegment = {
	formFields: [
		{
			type: 'email',
			name: 'email',
			label: 'user.info.email',
			visible: false,
			placeholder: 'login.placeholders.email',
		},
		{
			type: 'text',
			name: 'username',
			label: 'user.info.username',
			visible: false,
			placeholder: 'login.placeholders.username',
		},
		{
			type: 'phone',
			name: 'phone',
			label: 'user.info.phone',
			visible: false,
			placeholder: 'login.placeholders.phone',
		},
		{
			type: 'password',
			name: 'password',
			label: 'user.info.password',
			placeholder: 'login.placeholders.password',
			constraints: {
				required: {
					value: true,
				},
			},
		},
		{
			type: 'link',
			name: 'forgotCredentials',
			text: 'login.view.forgotCredentials',
		},
	],
	name: 'loginCredentialsSegment',
	isCollapsable: false,
	label: '',
};

export const sandboxFormData: DynamicFormDataV2 = {
	formData: [loginTypeSegment, loginCredentialsSegment],
	rules: [
		{
			children: ['email'],
			parents: {fields: [{name: 'loginType', value: 'email'}]},
			visible: true,
			// required: true,
		},
		{
			children: ['username'],
			parents: {fields: [{name: 'loginType', value: 'username'}]},
			visible: true,
			// required: true,
		},
		{
			children: ['phone'],
			parents: {fields: [{name: 'loginType', value: 'phone'}]},
			visible: true,
			// required: true,
		},
	],
};
