import HeaderProvider, {
	ConnectionStatus,
} from '../../common/src/application/network/header/NetworkRequestHeader';

class NetworkHeaderProvider implements HeaderProvider {
	getDeviceInfo(): string {
		return 'appVersion|deviceBrand|deviceModel|deviceScreenResolution|deviceOs|deviceOsVersion|deviceNetworkProvider|deviceNetworkType';
	}

	getHeaders(url: string): Record<string, string> {
		return {};
	}

	/**
	 * Return the current connections status of the internet.
	 * @return ConnectionStatus {@link ConnectionStatus.isConnected} means if Wi-Fi/mobile data is turned on. {@link ConnectionStatus.isInternetReachable} true means if internet is reachable otherwise no internet connection.
	 */
	getConnectionStatus(): Promise<ConnectionStatus> {
		return new Promise<ConnectionStatus>(resolve => {
			resolve({
				//TODO add implementation for internet connection status, based on that respective error will be automatically selected to shown on UI
				isConnected: true,
				isInternetReachable: true,
			});
		});
	}
}

export default NetworkHeaderProvider;
