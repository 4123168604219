/**
 * This method is used to generate a string with capitalized words.
 * @param {string} string to capitalize.
 * @returns {string} updated string with first letter of each word capitalized.
 */
import storageHelperInstance from '../../../storage/StorageHelper';
import {sessionStorageKeys} from '../interface/storage/constants/SessionKeys';

export const capitalizeFirstLetter = (
	string: string | null | undefined,
): string | null => {
	if (string != null && isValueAvailable(string)) {
		const tempString = string.toLowerCase().slice(0);
		return tempString.replace(/(?:^|\s)\S/g, function (a) {
			return a.toUpperCase();
		});
	}
	return null;
};

/**
 * This method is used to generate a string with first letter of the input string capitalized.
 * @param {string} string to capitalize.
 * @returns {string} updated string with first letter of the input string capitalized.
 */
export const capitalizeFirstLetterOfString = (
	string: string | null | undefined,
): string | null => {
	if (string != null && isValueAvailable(string)) {
		const tempString = string.slice(0);
		return `${string.charAt(0).toUpperCase()}${tempString.slice(1)}`;
	}
	return null;
};

/**
 * This method is used to check if a string can be parsed as a number.
 * @param {string} stringVal string value to test.
 * @returns {boolean} whether the string can be parsed to a number.
 */
export const isStringNumber = (stringVal: string): boolean => {
	if (stringVal != null && isValueAvailable(stringVal)) {
		return /^\d+$/.test(stringVal);
	}
	return false;
};

/**
 * This method checks whether the provided variable is a valid JSON object
 * @param {any} value
 * @returns {boolean} whether the given value is a valid JSON object
 */
export const isObjectValid = (value: any): boolean => {
	if (value != null && typeof value == 'object') {
		return Object.keys(value).length != 0;
	}
	return false;
};

/**
 * This method checks whether the provided variable is a valid array
 * @param {any} value
 * @returns {boolean} whether the given value is a valid array
 */
export const isArrayValid = (value: any): boolean => {
	if (value != null && Array.isArray(value)) {
		return value.length != 0;
	}
	return false;
};

/**
 * This method checks whether the given value is not null and not empty
 * @param {string | Record<string, unknown>} value value to be checked
 * @returns {boolean} whether the given value is not null and not empty
 */
export const isValueAvailable = (
	value:
		| string
		| Record<string, unknown>
		| Array<any>
		| null
		| undefined
		| number,
): boolean => {
	return (
		value != null &&
		value !== 'null' &&
		value !== 'undefined' &&
		value !== '' &&
		(Array.isArray(value) || typeof value == 'object'
			? isObjectValid(value) || isArrayValid(value)
			: true)
	);
};

/**
 * This method is used to generate a string for given property of given list separated by given separator.
 * This method will throw error if accessed property is not string
 * @param list to iterate on
 * @param property to be used to generate string
 * @param separator to separate elements, defaults to ', '
 * @returns {string} computed string from input parameters
 */
export const generatePropertyStringWithSeparator = (
	list: Array<Record<string, any>>,
	property: string,
	separator?: string,
): string => {
	if (!isValueAvailable(list) || !isValueAvailable(property)) return '';
	return list
		.map((value: Record<string, any>) => {
			return (value[property] as string) ?? '';
		})
		.join(separator || ', ');
};

/**
 * This method is used to sanitize a string for a given regex value
 * @param {string} value to be sanitized
 * @param {string} regex to be used to sanitize the given string
 * @returns {string} sanitized value
 */
export const sanitizeStringForRegex = (
	value: string,
	regex: RegExp,
): string => {
	return value.replace(regex, '');
};

/**
 * This method is used to find unique items in the concatenated arrays.
 * @param {any[]} arrayList
 * @returns {any[]}
 */
export const getUniqueElementsFromArrayList = (arrayList: any[]) => {
	let arrayWithDuplicates: any[] = [];
	let uniqueElements: any[] = [];
	if (!arrayList) {
		return uniqueElements;
	}
	arrayList.forEach(function (array: any) {
		if (array instanceof Array) {
			arrayWithDuplicates = arrayWithDuplicates.concat(array);
		}
	});
	uniqueElements = arrayWithDuplicates.filter(
		(n, i) => arrayWithDuplicates.indexOf(n) === i,
	);
	return uniqueElements;
};

export const convertMapValueToList = (objectValue: any, keyName: string) => {
	const finalArray: Array<any> = [];
	objectValue?.forEach((object: any) => {
		finalArray.push(object?.[keyName]);
	});
	return finalArray;
};

/**
 * This method is used to validate emails or comma seperated multiple emails
 * @param emails
 */
export const areEmailsValid = (emails: string) => {
	let isEmailValid = true;
	let serverConfig: Record<string, any> = {};
	storageHelperInstance
		.getItem(sessionStorageKeys.SERVER_CONFIG)
		.then(response => {
			serverConfig = response;
		});
	if (emails) {
		// Split string by comma into an array
		const emailArray = emails.split(',');
		const regex = new RegExp(serverConfig.emailRegex);
		for (let i = 0; i < emailArray.length; i++) {
			// Trim whitespaces from email address
			emailArray[i] = emailArray[i].trim();
			if (emailArray[i] == '' || !regex.test(emailArray[i])) {
				isEmailValid = false;
				break;
			}
		}
	}
	return isEmailValid;
};

export const initialsFromName = (name: string, order: string) => {
	let initials;
	const arrayInitials = name ? name.split(' ') : [];
	let firstName, lastName;
	const length = arrayInitials.length;
	if (length > 0 && arrayInitials[0] !== '') {
		firstName = arrayInitials[0][0];
	}
	if (arrayInitials.length > 1) {
		if (arrayInitials[length - 1] !== '') {
			lastName = arrayInitials[length - 1][0];
		}
	}
	if (order && order !== '' && order === 'lf') {
		if (firstName && lastName) {
			initials = lastName + firstName;
		} else if (firstName) {
			initials = firstName;
		} else if (lastName) {
			initials = lastName;
		}
	} else {
		if (firstName && lastName) {
			initials = firstName + lastName;
		} else if (firstName) {
			initials = firstName;
		} else if (lastName) {
			initials = lastName;
		}
	}
	if (initials) {
		initials = initials.toUpperCase();
	} else {
		initials = 'U';
	}
	return initials;
};

/**
 * Convert {@link any} value to Number. if fails, return {@link undefined.}
 * @param {any} value of {@link any} type
 * @return {Number} Converted number if success, otherwise will return {@link undefined}
 */
export const toNumber = (value?: any) => {
	const number = Number(value);
	if (!isNaN(number)) {
		return number;
	} else {
		return undefined;
	}
};

export const replaceName = (name: string) => {
	if (name) {
		name = name.replace(/\^/g, ' ');
	}
	return name;
};
