import {Control} from 'react-hook-form';
import {strings} from '../../../localization/i18n';
import AddressDropdown from '../../customComps/AddressDropdown';
import {
	getNetworkCallProviderForResend,
	getNetworkCallProviderForVerify,
} from '../../../common/src/application/helpers/VerifiableComponents.helper';
import {sessionStorageKeys} from '../../../common/src/interface/storage/constants/SessionKeys';
import VerifiablePhone from '../../customComps/VerifiableContact/verifiableContact';

export default function getCustomComponentSelector(): Record<
	string,
	(props: any) => JSX.Element
> {
	return {
		address: (props: any) => {
			return (
				<AddressDropdown
					dropdownProps={{
						line1: props.fieldData.fields?.line1
							? {
									...props.fieldData.fields?.line1,
									textInputClassName: 'textInput',
									name: props.fieldData.fields?.line1?.name ?? '',
									control: props.control as Control<any, object>,
									constraints: props.fieldData.fields?.line1?.constraints,
							  }
							: undefined,
						line2: props.fieldData.fields?.line2
							? {
									...props.fieldData.fields?.line2,
									textInputClassName: 'textInput',
									control: props.control as Control<any, object>,
									name: props.fieldData.fields?.line2?.name ?? '',
									constraints: props.fieldData.fields?.line2?.constraints,
							  }
							: undefined,
						line3: props.fieldData.fields?.line3
							? {
									...props.fieldData.fields?.line3,
									textInputClassName: 'textInput',
									control: props.control as Control<any, object>,
									name: props.fieldData.fields?.line3?.name ?? '',
									constraints: props.fieldData.fields?.line3?.constraints,
							  }
							: undefined,
						zipcode: props.fieldData.fields?.zipcode
							? {
									...props.fieldData.fields?.zipcode,
									textInputClassName: 'textInput',
									control: props.control as Control<any, object>,
									name: props.fieldData.fields?.zipcode?.name ?? '',
									constraints: props.fieldData.fields?.zipcode?.constraints,
									isToCallApi:
										props.fieldData.fields?.zipcode?.isToCallApi || false,
							  }
							: undefined,
						country: props.fieldData.fields?.country
							? {
									// always pass empty array since the options are controlled by AddressDropdown
									options: [],
									name: props.fieldData.fields?.country?.name ?? '',
									constraints: props.fieldData.fields?.country?.constraints,
									control: props.control as Control<any, object>,
									label:
										props.fieldData.fields?.country?.label != null
											? strings(props.fieldData.fields?.country?.label)
											: undefined,
									isMulti: false,
									searchable: true,
									dynamicConstraints:
										props.allDynamicConstraints?.[
											props.fieldData.fields?.country?.name
										],
							  }
							: undefined,
						state: props.fieldData.fields?.state
							? {
									// always pass empty array since the options are controlled by AddressDropdown
									options: [],
									name: props.fieldData.fields?.state?.name ?? '',
									constraints: props.fieldData.fields?.state?.constraints,
									control: props.control as Control<any, object>,
									label:
										props.fieldData.fields?.state?.label != null
											? strings(props.fieldData.fields?.state?.label)
											: undefined,
									isMulti: false,
									searchable: true,
									dynamicConstraints:
										props.allDynamicConstraints?.[
											props.fieldData.fields?.state?.name
										],
							  }
							: undefined,
						city: props.fieldData.fields?.city
							? {
									// always pass empty array since the options are controlled by AddressDropdown
									options: [],
									name: props.fieldData.fields?.city?.name ?? '',
									constraints: props.fieldData.fields?.city?.constraints,
									control: props.control as Control<any, object>,
									label:
										props.fieldData.fields?.city?.label != null
											? strings(props.fieldData.fields?.city?.label)
											: undefined,
									isMulti: false,
									searchable: true,
									dynamicConstraints:
										props.allDynamicConstraints?.[
											props.fieldData.fields?.city?.name
										],
							  }
							: undefined,
						county: props.fieldData.fields?.county
							? {
									options: [],
									name: props.fieldData.fields?.county?.name ?? '',
									constraints: props.fieldData.fields?.county?.constraints,
									control: props.control as Control<any, object>,
									label:
										props.fieldData.fields?.county?.label != null
											? strings(props.fieldData.fields?.county?.label)
											: undefined,
									isMulti: false,
									searchable: true,
									dynamicConstraints:
										props.allDynamicConstraints?.[
											props.fieldData.fields?.county?.name
										],
							  }
							: undefined,
					}}
					control={props.control}
					configProvider={props.configProvider}
					name={props.fieldData.name}
					dynamicConstraints={props.dynamicConstraints}
					allDynamicConstraints={props.allDynamicConstraints}
					isViewOnlyMode={props.isViewOnlyMode}
					register={props.register}
				/>
			);
		},
		verifiablePhone: (props: any) => {
			return (
				<VerifiablePhone
					{...props}
					customProps={{contactType: 'phone'}}
					getNetworkCallProviderForResend={getNetworkCallProviderForResend}
					getNetworkCallProviderForVerify={getNetworkCallProviderForVerify}
					sessionStorageKeys={sessionStorageKeys}
				/>
			);
		},
	};
}
