import React from 'react';
import {NavLink} from 'react-router-dom';
import {strings} from '../../../../../localization/i18n';
import CustomNavLink from '../../CustomNavLink';

function NavBarRight(props: any) {
	const {navItemsRight}: {navItemsRight: Array<Record<string, any>>} = props;

	function renderMenuItem(menuItem: Record<string, any>, key: any) {
		switch (menuItem.type) {
			case 'link':
				return menuItem.path ? (
					<CustomNavLink
						to={menuItem.path}
						key={key}
						ariaLabel={strings(menuItem.name)}
						role="button">
						{menuItem.className ? (
							<span className={menuItem.className}></span>
						) : (
							strings(menuItem.name)
						)}
					</CustomNavLink>
				) : (
					<button
						key={key}
						onClick={menuItem.clickHandler}
						aria-label={strings(menuItem.name)}
						className="noBtnStyle">
						{menuItem.className ? (
							<span className={menuItem.className}></span>
						) : (
							strings(menuItem.name)
						)}
					</button>
				);
			case 'custom':
				return (
					<React.Fragment key={key}>{menuItem.component({})}</React.Fragment>
				);
			case 'dropdown':
				return (
					<li className="nav-item dropdown" key={key}>
						<div className="dropdown">
							<button
								className="noBtn-style dropdown-toggle userName"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								aria-label={strings(menuItem.name)}
								id={`nav-item_${menuItem.name}`}>
								<span className="userName-text">
									<span className="userName-text-inner">
										{menuItem.className ? (
											<span className={menuItem.className}></span>
										) : (
											strings(menuItem.name)
										)}
									</span>
								</span>
								<span
									className="fIcon icon-profile1 rightNavIcon userName-icon"
									aria-label={strings(menuItem.name)}></span>
							</button>
							<ul
								className="dropdown-menu dropdown-menu-right"
								aria-labelledby="dropdownMenuLink">
								{menuItem.subMenuItems.map((dropdownItem: any, key: any) => (
									<li className="nav-item" key={key}>
										{dropdownItem.path ? (
											<CustomNavLink
												to={dropdownItem.path}
												className={
													dropdownItem.className
														? `dropdown-item ${dropdownItem.className}`
														: 'dropdown-item'
												}>
												{strings(dropdownItem.name)}
											</CustomNavLink>
										) : (
											<button
												className={
													dropdownItem.className
														? `noBtn-style dropdown-item ${dropdownItem.className}`
														: 'noBtn-style dropdown-item'
												}
												onClick={dropdownItem.clickHandler}
												key={key}>
												{strings(dropdownItem.name)}
											</button>
										)}
									</li>
								))}
							</ul>
						</div>
					</li>
				);
		}
	}

	return (
		<>
			{navItemsRight.map((item: Record<string, any>, key: any) =>
				renderMenuItem(item, key),
			)}
		</>
	);
}

export default NavBarRight;
