import {useEffect, useState} from 'react';
import {getCurrentYear} from '../../common/src/utils/DateTimeUtils';
import {strings} from '../../localization/i18n';

const Footer = (props: any) => {
	const [currentYear, setCurrentYear] = useState<number>();

	useEffect(() => {
		getCurrentYear().then(data => {
			setCurrentYear(data);
		});
	}, []);

	return (
		<>
			{currentYear && (
				<div>
					<footer className="inrFooter">
						{strings('footer.poweredByText', {currentYear: currentYear})}
					</footer>
					<div className="inrFooterSpace"></div>
				</div>
			)}
		</>
	);
};

export default Footer;
