import {Service} from 'typedi';
import APIExecutor, {APIRequest} from '../../../network/APIExecutor';
import BaseRequestModel from '../../../network/interface/BaseRequestModel';
import NetworkRequestMethodEnum from '../../../../enums/NetworkRequestMethodEnum';

@Service()
export default class GenericNetworkCallRepository {
	/**
	 * This method is used to make API call for the received API URL with provided data
	 * @param {BaseResponseModel} request model for the received API URL
	 * @param {string} url endpoint for API call
	 * @param {Record<string, any>} headers for request
	 * @param {NetworkRequestMethodEnum | undefined} method of API call, defaults to POST
	 * @return {Promise<{
	 * 						data: Record<string, any>;
	 * 						status: number;
	 * 						headers: Record<string, any>;
	 * 					}>}
	 */
	callGenericAPI(
		request: BaseRequestModel,
		url: string,
		headers: Record<string, any>,
		method?: NetworkRequestMethodEnum,
	): Promise<{
		data: Record<string, any>;
		status: number;
		headers: Record<string, any>;
	}> {
		return new Promise<{
			data: Record<string, any>;
			status: number;
			headers: Record<string, any>;
		}>((resolve, reject) => {
			let apiPromise: Promise<{
				data: Record<string, any>;
				status: number;
				headers: Record<string, any>;
			}>;
			switch (method) {
				case NetworkRequestMethodEnum.GET:
					apiPromise = APIExecutor.getHTTP<Record<string, any>>(
						new APIRequest(
							url,
							request.getRequestDataObject(),
							undefined,
							headers,
							undefined,
							method,
						),
					);
					break;
				case NetworkRequestMethodEnum.PUT:
					apiPromise = APIExecutor.putHTTP<Record<string, any>>(
						new APIRequest(
							url,
							request.getRequestDataObject(),
							undefined,
							headers,
							undefined,
							method,
						),
					);
					break;
				case NetworkRequestMethodEnum.DELETE:
					apiPromise = APIExecutor.deleteHTTP<Record<string, any>>(
						new APIRequest(
							url,
							request.getRequestDataObject(),
							undefined,
							headers,
							undefined,
							method,
						),
					);
					break;
				case NetworkRequestMethodEnum.POST:
				default:
					apiPromise = APIExecutor.postHTTP<Record<string, any>>(
						new APIRequest(
							url,
							request.getRequestDataObject(),
							undefined,
							headers,
							undefined,
							method,
						),
					);
					break;
			}
			apiPromise
				.then(
					(value: {
						data: Record<string, any>;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(value);
					},
				)
				.catch(error => {
					reject(error);
				});
		});
	}
}
