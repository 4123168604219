import {EncryptStorage} from 'encrypt-storage';
import {EncryptedStorageHelper} from '../common/src/interface/storage/IEncryptedStorageHelper';
import {isValueAvailable} from '../common/src/utils/StringUtils';
import storageHelperInstance from './StorageHelper';

var EncryptedStorage: EncryptStorage;

if (global.ConfigurationHolder?.storageType === 'localStorage') {
	EncryptedStorage = new EncryptStorage('secret-key', {
		storageType: 'localStorage',
	});
} else {
	EncryptedStorage = new EncryptStorage('secret-key', {
		storageType: 'sessionStorage',
	});
}

/**
 * This method is used to save a json object or string against the given key
 * @param {string} key to save the given value against
 * @param {string/object} value to save against the given key
 * @returns void
 */
const setItemSync = <T = any>(key: string, value: T): void => {
	if (global.ConfigurationHolder?.disableStorageEncryption) {
		storageHelperInstance.setItemSync(key, value);
	} else {
		EncryptedStorage.setItem(storageHelperInstance.getKeyName(key), value);
	}
};

/**
 * This method is used to fetch a stored json object or string against the given key
 * @param {string} key to fetch the given value against
 * @returns {any}  which gives with json object or string if exists or null
 */
const getItemSync = (key: string): any => {
	const data = global.ConfigurationHolder?.disableStorageEncryption
		? storageHelperInstance.getItemSync(key)
		: EncryptedStorage.getItem(storageHelperInstance.getKeyName(key));
	if (isValueAvailable(data)) {
		return data;
	} else {
		return null;
	}
};

/**
 * This method is used to save a json object or string against the given key
 * @param {string} key to save the given value against
 * @param {string/object} value to save against the given key
 * @returns {Promise<void>}
 */
const setItem = <T = any>(key: string, value: T): Promise<void> => {
	return new Promise<void>(resolve => {
		if (global.ConfigurationHolder?.disableStorageEncryption) {
			storageHelperInstance.setItemSync(key, value);
			resolve();
		} else {
			EncryptedStorage.setItem(storageHelperInstance.getKeyName(key), value);
			resolve();
		}
	});
};

/**
 * This method is used to fetch a stored json object or string against the given key
 * @param {string} key to fetch the given value against
 * @returns {Promise<any>} a promise which resolves with json object or string if exists or null
 */
const getItem = <T = any>(key: string): Promise<T | null> => {
	return new Promise(resolve => {
		const data = global.ConfigurationHolder?.disableStorageEncryption
			? storageHelperInstance.getItemSync(key)
			: EncryptedStorage.getItem(storageHelperInstance.getKeyName(key));
		if (isValueAvailable(data)) {
			resolve(data);
		} else {
			resolve(null);
		}
	});
};

/**
 * This method is used to remove a stored value against the given key
 * @param {string} key to remove the given value against
 * @returns {Promise<void>}
 */
export const removeItem = (key: string): Promise<void> => {
	return new Promise<void>(resolve => {
		if (global.ConfigurationHolder?.disableStorageEncryption) {
			storageHelperInstance.removeItem(key).then(res => {
				resolve();
			});
		} else {
			EncryptedStorage.removeItem(storageHelperInstance.getKeyName(key));
		}
		resolve();
	});
};

/**
 * This method is used to remove all stored key/value pairs from the storage
 * @param {string} key to remove the given value against
 * @returns {void}
 */
export const clearStorage = (): void => {
	if (global.ConfigurationHolder?.disableStorageEncryption) {
		storageHelperInstance.clearStorage();
	}
	EncryptedStorage.clear();
};

const encryptedStorageHelperInstance: EncryptedStorageHelper = {
	setItem: setItem,
	getItem: getItem,
	removeItem: removeItem,
	clearStorage: clearStorage,
	getItemSync: getItemSync,
	setItemSync: setItemSync,
};

export default encryptedStorageHelperInstance;
