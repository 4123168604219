import _ from 'lodash';
import moment from 'moment';
import {Observable, Subscriber} from 'rxjs';
import Container, {Service} from 'typedi';
import {UploadDocEnums} from '../enums/UploadDocEnums';

import {strings} from '../../../../../../localization/i18n';

import worker_script from './worker.js';

import UploaderViewModel from '../viewModel/UploaderViewModel';
import {generateDeferredPromise} from '../../../network/DeferredPromise';
import {calendarToServerDateFormatGMT} from '../../../../utils/DateTimeUtils';
import {
	calculateFileSize,
	checkDemographics,
	getFileExtension,
} from '../../../../utils/CommonUtils';

@Service()
class UploaderHelperService {
	unknownTime = '--/--';
	maxBatchRequestSizeConstant = 1024 * 1024 * 1024;
	maxNumberOfFilesPerBatchConstant = 50;
	maxBatchConcurrentlyAllowed = 5;
	uploadingStatus = {
		notStarted: 'notStarted',
		uploading: 'uploading',
		uploaded: 'uploaded',
		error: 'error',
	};
	private uploaderType = '';

	private actionContext = '';

	private directiveScopeVariables: Record<string, any> = {};

	private documentType = '';
	private xhrUploadRequestList = [];

	getDocumentType = (): string => {
		return this.documentType;
	};
	setDocumentType = (defaultDocumentTypeKey: string) => {
		this.documentType = defaultDocumentTypeKey;
	};

	getXhrUploadRequestList = () => {
		return this.xhrUploadRequestList;
	};

	setUploaderType = (uploaderTypeToBeSet: string) => {
		this.uploaderType = uploaderTypeToBeSet;
	};

	getUploaderType = (): string => {
		return this.uploaderType;
	};

	setActionContext = (actionContextToBeSet: any) => {
		this.actionContext = actionContextToBeSet;
	};

	getActionContext = (): string => {
		return this.actionContext;
	};

	/**
	 * it check the limit of batch
	 * @param fileItem
	 * @param numberOfFilesInCurrentBatch
	 * @param currentBatchSize
	 * @returns
	 */
	checkLimitForBatch = (
		fileItem: any,
		numberOfFilesInCurrentBatch: any,
		currentBatchSize: any,
	): boolean => {
		const fileSize = fileItem.size;
		const fileName = fileItem.name;
		const afterAddingFileSize = fileSize + currentBatchSize;
		const afterAddingCount = numberOfFilesInCurrentBatch + 1;
		if (
			afterAddingCount < this.maxNumberOfFilesPerBatchConstant &&
			afterAddingFileSize < this.maxBatchRequestSizeConstant
		) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * create dicom study batch
	 * @param dicomStudies
	 * @param currentBatchIndex
	 * @param timerObject
	 * @returns
	 */
	createStudyBatches = (
		dicomStudies: any,
		currentBatchIndex: number,
		timerObject: Record<string, any>,
	): Array<Record<string, any>> => {
		const dicomStudiesBatches: Array<Record<string, any>> = [];
		let currentBatchObject: Record<string, any> = {
			progress: 0,
			numberOfFiles: 0,
			batchSize: 0,
		};
		let currentBatchItemList: Array<Record<string, any>> = [];
		let currentBatchSize = 0;
		let numberOfFilesInCurrentBatch = 0;

		for (const studyId in dicomStudies) {
			if (_.has(dicomStudies, studyId)) {
				dicomStudies[studyId].scannedForBatchUpload = true;
				if (dicomStudies[studyId].sendToServer) {
					const currentStudy = dicomStudies[studyId];
					currentStudy.error = false;
					if (
						!currentStudy.batchIndexList ||
						currentStudy.batchIndexList.length <= 0
					) {
						currentStudy.batchIndexList = [];
					}
					const instancesList = currentStudy.instanceList;
					instancesList.forEach((currentInstance: any) => {
						currentInstance.scannedForBatchUpload = true;
						if (
							!currentInstance.uploaded &&
							currentInstance.batchIndex === undefined
						) {
							currentInstance.error = false;
							currentStudy.uploadTouched = false;
							timerObject.totalSize += currentInstance.size;
							if (
								this.checkLimitForBatch(
									currentInstance,
									numberOfFilesInCurrentBatch,
									currentBatchSize,
								)
							) {
								currentInstance.batchIndex = currentBatchIndex;
								currentBatchItemList.push(currentInstance);
								currentBatchSize += currentInstance.size;
								numberOfFilesInCurrentBatch++;
								currentBatchObject.numberOfFiles = numberOfFilesInCurrentBatch;
								currentBatchObject.batchSize = currentBatchSize;
							} else {
								currentBatchObject.itemList = currentBatchItemList;
								dicomStudiesBatches.push(currentBatchObject);
								currentBatchItemList = [currentInstance];
								currentBatchSize = currentInstance.size;
								numberOfFilesInCurrentBatch = 1;
								currentBatchObject = {
									progress: 0,
									numberOfFiles: numberOfFilesInCurrentBatch,
									batchSize: currentBatchSize,
								};
								currentStudy.batchIndexList.push(currentBatchIndex);
								currentBatchIndex++;
								currentInstance.batchIndex = currentBatchIndex;
							}
						}
					});
					if (currentBatchItemList.length !== 0) {
						currentBatchObject.itemList = currentBatchItemList;
						dicomStudiesBatches.push(currentBatchObject);
						currentStudy.batchIndexList.push(currentBatchIndex);
						currentBatchIndex++;
					}
					currentBatchObject = {progress: 0, numberOfFiles: 0, batchSize: 0};
					currentBatchItemList = [];
					numberOfFilesInCurrentBatch = 0;
					currentBatchSize = 0;
				}
			}
		}
		return dicomStudiesBatches;
	};

	/**
	 * create dicom batch list
	 * @param nonDicomList
	 * @param currentBatchIndex
	 * @param timerObject
	 * @returns
	 */
	createBatchesForNonDicomUploads = (
		nonDicomList: Array<any>,
		currentBatchIndex: any,
		timerObject: Record<string, any>,
	): Array<Record<string, any>> => {
		const nonDicomBatchesList: Array<Record<string, any>> = [];
		let currentBatchObject: Record<string, any> = {
			progress: 0,
			numberOfFiles: 0,
			batchSize: 0,
		};
		let currentBatchItemList: Array<Record<string, any>> = [];
		let currentBatchSize = 0;
		let numberOfFilesInCurrentBatch = 0;
		nonDicomList.forEach((nonDicom: any) => {
			if (!nonDicom.uploaded && nonDicom.batchIndex === undefined) {
				nonDicom.error = false;
				nonDicom.uploadTouched = false;
				timerObject.totalSize += nonDicom.size;
				if (
					currentBatchSize < this.maxBatchRequestSizeConstant &&
					numberOfFilesInCurrentBatch < this.maxNumberOfFilesPerBatchConstant
				) {
					nonDicom.batchIndex = currentBatchIndex;
					currentBatchItemList.push(nonDicom);
					currentBatchSize += nonDicom.size;
					numberOfFilesInCurrentBatch++;
					currentBatchObject.numberOfFiles = numberOfFilesInCurrentBatch;
					currentBatchObject.batchSize = currentBatchSize;
				} else {
					currentBatchObject.itemList = currentBatchItemList;
					nonDicomBatchesList.push(currentBatchObject);
					currentBatchItemList = [nonDicom];
					currentBatchSize = nonDicom.size;
					numberOfFilesInCurrentBatch = 1;
					currentBatchObject = {
						progress: 0,
						numberOfFiles: 1,
						batchSize: currentBatchSize,
					};
					currentBatchIndex++;
					nonDicom.batchIndex = currentBatchIndex;
				}
			}
		});
		if (currentBatchItemList.length !== 0) {
			currentBatchObject.itemList = currentBatchItemList;
			nonDicomBatchesList.push(currentBatchObject);
		}
		return nonDicomBatchesList;
	};

	/**
	 * create batch object to upload
	 * @param uploadingObj
	 * @param uploadBatchObject
	 * @param timerObject
	 * @returns
	 */
	createBatchesToUpload = (
		uploadingObj: Record<string, any>,
		uploadBatchObject: any,
		timerObject: Record<string, any>,
	): Record<string, any> => {
		const dicomStudies = uploadingObj.listOfDicomFiles;
		const nonDicomList = uploadingObj.listOfNonDicomFiles;
		let nonDicomBatchesList: Array<any> = [];
		let dicomBatchList: Array<Record<string, any>> = [];
		if (dicomStudies) {
			let currentBatchIndex = 0;
			if (
				uploadBatchObject.dicomBatchList &&
				uploadBatchObject.dicomBatchList.length > 0
			) {
				currentBatchIndex = uploadBatchObject.dicomBatchList.length;
			}
			dicomBatchList = this.createStudyBatches(
				dicomStudies,
				currentBatchIndex,
				timerObject,
			);
		}
		if (nonDicomList) {
			let currentBatchIndex = 0;
			if (
				uploadBatchObject.nonDicomBatchesList &&
				uploadBatchObject.nonDicomBatchesList.length > 0
			) {
				currentBatchIndex = uploadBatchObject.nonDicomBatchesList.length;
			}
			nonDicomBatchesList = this.createBatchesForNonDicomUploads(
				nonDicomList,
				currentBatchIndex,
				timerObject,
			);
		}
		if (
			uploadBatchObject.dicomBatchList &&
			uploadBatchObject.dicomBatchList.length > 0
		) {
			uploadBatchObject.dicomBatchList =
				uploadBatchObject.dicomBatchList.concat(dicomBatchList);
		} else {
			uploadBatchObject.dicomBatchList = dicomBatchList;
		}
		if (
			uploadBatchObject.nonDicomBatchesList &&
			uploadBatchObject.nonDicomBatchesList.length > 0
		) {
			uploadBatchObject.nonDicomBatchesList =
				uploadBatchObject.nonDicomBatchesList.concat(nonDicomBatchesList);
		} else {
			uploadBatchObject.nonDicomBatchesList = nonDicomBatchesList;
		}
		const responseObject = {
			nonDicomBatchesList: nonDicomBatchesList,
			dicomBatchList: dicomBatchList,
		};
		return responseObject;
	};

	/**
	 * set the values for directive scope variables
	 * @param scope
	 * @param mainFileArray
	 * @param incomingQueue
	 */
	setDirectiveScopeVariables = (
		scope: Record<string, any>,
		mainFileArray: Array<any>,
		incomingQueue: Array<any>,
	): void => {
		this.directiveScopeVariables.scanObj = scope.scanObj;
		this.directiveScopeVariables.showRetryMessage = scope.showRetryMessage;
		this.directiveScopeVariables.uploadingObj = scope.uploadingObj;
		this.directiveScopeVariables.uploadPageObj = scope.uploadPageObj;
		this.directiveScopeVariables.uploaderPageUIObject =
			scope.uploaderPageUIObject;
		this.directiveScopeVariables.counterObject = scope.counterObject;
		this.directiveScopeVariables.timerObject = scope.timerObject;
		this.directiveScopeVariables.uploadBatchObject = scope.uploadBatchObject;
		this.directiveScopeVariables.mainFileArray = mainFileArray;
		this.directiveScopeVariables.incomingQueue = incomingQueue;
		this.directiveScopeVariables.uploadDone = scope.uploadDone;
		this.directiveScopeVariables.responseObject = scope.responseObject;
		this.directiveScopeVariables.editFormOpened = scope.editFormOpened;
	};

	/**
	 * used to get directive scope variables
	 * @returns directiveScopeVariables
	 */
	getDirectiveScopeVariables = (): Record<string, any> => {
		return this.directiveScopeVariables;
	};

	/**
	 * used to check if file exist in uploaded document list
	 * @param uploadedDocumentsObject
	 * @param incomingFileItem
	 * @returns alreadyExists
	 */
	checkIfFileExistsInUploadedDocumentsList = (
		uploadedDocumentsObject: Array<Record<string, any>>,
		incomingFileItem: File,
	): boolean => {
		let alreadyExists = false;
		for (let ind = 0; ind < uploadedDocumentsObject.length; ind++) {
			if (
				uploadedDocumentsObject[ind].fileType === UploadDocEnums.NON_DICOM &&
				uploadedDocumentsObject[ind].name === incomingFileItem.name &&
				parseInt(uploadedDocumentsObject[ind].fileSize) ===
					incomingFileItem.size
			) {
				alreadyExists = true;
				break;
			}
		}
		return alreadyExists;
	};

	/**
	 * used to check if file exist in queue
	 * @param queue
	 * @param fileItem
	 * @returns alreadyExists
	 */
	checkIfFileExistsInQueue = (queue: Array<any>, fileItem: File): boolean => {
		let alreadyExists = false;
		for (let ind = 0; ind < queue.length; ind++) {
			if (
				queue[ind].name === fileItem.name &&
				queue[ind].type === fileItem.type &&
				queue[ind].size === fileItem.size
			) {
				alreadyExists = true;
				break;
			}
		}
		return alreadyExists;
	};

	/**
	 * used to remove duplicate files from incoming list
	 * @param incomingQueue
	 * @param mainQueue
	 * @param counterObject
	 * @param uploadedDocumentsObject
	 * @returns mainQueue
	 */
	removeDuplicateFilesFromIncomingList = (
		incomingQueue: Array<any>,
		mainQueue: Array<any>,
		counterObject: Record<string, any>,
		uploadedDocumentsObject: Array<Record<string, any>>,
	): Array<Record<string, any>> => {
		if (uploadedDocumentsObject) {
			if (!incomingQueue || !incomingQueue.length) {
				return [];
			} else {
				for (let i = 0; i < incomingQueue.length; i++) {
					if (
						this.checkIfFileExistsInUploadedDocumentsList(
							uploadedDocumentsObject,
							incomingQueue[i],
						)
					) {
						counterObject.currentScannedItems++;
						incomingQueue.splice(i, 1);
						i--;
					}
				}
			}
		}
		if (!incomingQueue || !incomingQueue.length) {
			return [];
		}
		if (!mainQueue || !mainQueue.length) {
			return incomingQueue;
		}
		for (let i = 0; i < incomingQueue.length; i++) {
			if (this.checkIfFileExistsInQueue(mainQueue, incomingQueue[i])) {
				counterObject.currentScannedItems++;
				incomingQueue.splice(i, 1);
				i--;
			}
		}
		mainQueue = mainQueue.concat(incomingQueue);
		return mainQueue;
	};

	extensionFilter = (
		filesArray: Array<any>,
		counterObject: Record<string, any>,
		uploadPageObj: Record<string, any>,
	): Promise<Record<string, any>> => {
		const extensionFilterStartTime = new Date().getTime();
		const invalidFilesList: Array<any> = [];
		const validFilesList: any = [];
		const dicomDirFileList: any = [];
		const promiseList: Array<Promise<boolean>> = [];
		const defferedObj: any = {};
		let timeoutCounter = 10;

		return new Promise((resolve, reject) => {
			const worker = new Worker(worker_script, {
				name: 'dicomParserWorker',
				type: 'module',
			});
			filesArray.forEach(function (fileItem: any, index: number) {
				defferedObj[index] = generateDeferredPromise();
				promiseList.push(defferedObj[index].promise);
				if (index % 500 === 0) {
					timeoutCounter += 10;
				}
				if (!fileItem.scanned) {
					setTimeout(function () {
						worker.postMessage({
							fileItem: fileItem,
							index: index,
							uploadPageObj: uploadPageObj,
						});
					}, timeoutCounter);
					worker.onmessage = e => {
						const returnObject = e.data;
						const result = returnObject.flag;
						const indexOfReturnedObject = returnObject.index;
						if (result === 'invalid') {
							counterObject.currentScannedItems++;
							filesArray[indexOfReturnedObject].scanned = true;
							invalidFilesList.push(filesArray[indexOfReturnedObject]);
						} else if (result === 'valid') {
							validFilesList.push(filesArray[indexOfReturnedObject]);
						} else {
							filesArray[indexOfReturnedObject].scanned = true;
							counterObject.currentScannedItems++;
							dicomDirFileList.push(filesArray[indexOfReturnedObject]);
						}
						defferedObj[indexOfReturnedObject].resolve();
					};
				} else {
					defferedObj[index].resolve();
				}
			});
			Promise.all(promiseList).then(
				rep => {
					if (rep) {
						const extensionFilterEndTime = new Date().getTime();
						//alert("Time in filtering extension"+ ( extensionFilterEndTime- extensionFilterStartTime));
						worker.terminate();
						resolve({
							validFilesList: validFilesList,
							invalidFilesList: invalidFilesList,
							dicomDirFileList: dicomDirFileList,
						});
					}
				},
				error => {
					console.log(error);
				},
			);
		});
	};

	/**
	 * parse dicom directory file item and update the map
	 * @param fileItem
	 * @param dataSet
	 * @param dicomDirMap
	 * @param scanObj
	 * @returns
	 */
	parseDICOMDIRFileItemAndUpdateMap = (
		fileItem: any,
		dataSet: any,
		dicomDirMap: Record<string, any>,
		scanObj: Record<string, any>,
	): Record<string, any> => {
		const studyDetails: any = {};
		studyDetails['noOfSeries'] = 0;
		studyDetails['noOfImages'] = 0;
		let patientName;
		let patientDOB;
		let patientSex;
		let patientDescription;
		let patientId;
		let studyIuid;
		let accession;
		let description;
		let modality;
		let seriesIuid;
		let seriesNo;
		let seriesDescription;
		let sopIuid;
		let fileNamePath;
		let studyDate;

		for (let i = 0; i < dataSet.elements.x00041220.items.length; i++) {
			if (!scanObj.cancelScan) {
				const dataSetElement = dataSet.elements.x00041220.items[i].dataSet;
				const directoryRecordType = dataSetElement.string('x00041430');
				if (directoryRecordType === 'PATIENT') {
					patientName = dataSetElement.string('x00100010');
					patientDOB = dataSetElement.string('x00100030');
					patientSex = dataSetElement.string('x00100040');
					patientDescription = dataSetElement.string('x00081030');
					patientId = dataSetElement.string('x00100020');
				} else if (directoryRecordType === 'STUDY') {
					studyIuid = dataSetElement.string('x0020000d');
					accession = dataSetElement.string('x00080050');
					description = dataSetElement.string('x00081030');
					studyDate = dataSetElement.string('x00080020');
				} else if (directoryRecordType === 'SERIES') {
					studyDetails['noOfSeries'] = studyDetails['noOfSeries'] + 1;
					modality = dataSetElement.string('x00080060');
					seriesIuid = dataSetElement.string('x0020000e');
					seriesNo = dataSetElement.string('x00200011');
					seriesDescription = dataSetElement.string('x0008103e');
				} else if (directoryRecordType === 'IMAGE') {
					studyDetails['noOfImages'] = studyDetails['noOfImages'] + 1;
					sopIuid = dataSetElement.string('x00080018');
					if (_.isUndefined(sopIuid)) {
						sopIuid = dataSetElement.string('x00041511');
					}

					fileNamePath = dataSetElement.string('x00041500');

					const mapKeyPrefix = fileItem.webkitRelativePath.replace(
						'DICOMDIR',
						'',
					);
					let mapKey = mapKeyPrefix + fileNamePath;
					mapKey = mapKey.replace(/\\/g, '/');

					dicomDirMap[mapKey] = {
						patientName: patientName,
						patientDOB: patientDOB,
						patientSex: patientSex,
						modality: modality,
						description: description,
						//institutionName:institutionName,
						studyIuid: studyIuid,
						seriesIuid: seriesIuid,
						sopIuid: sopIuid,
						fileType: 'DICOM',
						//studySize:parseFloat(thisFileItem._file.size),
						patientId: patientId,
						fileNamePath: fileNamePath,
						studyDate: studyDate,
					};
				}
			}
		}
		return dicomDirMap;
	};
	/**
	 * parse dicom dir file items
	 * @param filesList
	 * @param scanObj
	 * @returns
	 */
	parseDicomDIRFileItems = (
		filesList: Array<any>,
		scanObj: Record<string, any>,
		parseDicom: any,
	): Promise<Record<string, any>> => {
		const allPromiseResponse = new Promise<Record<string, any>>(
			(resolve, reject) => {
				const dicomDIRMap: Record<string, any> = {};
				const promisesList: any = [];
				filesList.forEach(dicomDirFileObject => {
					if (!scanObj.cancelScan) {
						const promised = new Promise((resolve, error) => {
							const reader = new FileReader();

							reader.readAsArrayBuffer(dicomDirFileObject);
							const vm: UploaderHelperService = Container.get(
								UploaderHelperService,
							);

							reader.onload = file => {
								const arrayBuffer = reader.result as ArrayBuffer;
								const byteArray = new Uint8Array(arrayBuffer);

								try {
									const dataSet = parseDicom(byteArray);
									//var dicomDIRJSObject = dicomParser.explicitDataSetToJS(dataSet);
									//console.log("DICOM DIR js object")
									vm.parseDICOMDIRFileItemAndUpdateMap(
										dicomDirFileObject,
										dataSet,
										dicomDIRMap,
										scanObj,
									);
								} catch (err) {
									console.log(
										'Error in parsing DICOMDIR ' +
											dicomDirFileObject.webkitRelativePath,
										err,
									);
								}
								resolve(dicomDIRMap);
							};
						});
						promisesList.push(promised);
					}
				});
				Promise.all(promisesList).then(function (dicomDirMapList) {
					resolve(dicomDIRMap);
				});
			},
		);

		return allPromiseResponse;
	};

	/**
	 * used to check performance of extension filtering
	 * @param timeTakenInMS
	 * @param numberOfFiles
	 * @returns slow
	 */
	checkSlowPerformanceOfExtensionFiltering = (
		timeTakenInMS: any,
		numberOfFiles: any,
	): boolean => {
		let slow = false;
		try {
			if (numberOfFiles && timeTakenInMS) {
				if (numberOfFiles > 2000) {
					const scanningRate = numberOfFiles / (timeTakenInMS / 1000);
					console.log('Scan rate is: ', scanningRate);
					if (scanningRate < 1000) {
						slow = true;
					}
				}
			}
		} catch (ex) {
			console.log('Error while measuring performance of scanning');
		}

		return slow;
	};

	/**
	 * get file extension
	 * @param fileItem
	 * @returns extension
	 */
	getFileExtension = (fileItem: any): string => {
		const filename = fileItem.name;
		let extension = '';
		if (filename.indexOf('.') > -1) {
			const fileSplit = filename.split('.');
			if (fileSplit.length >= 2) {
				extension = filename.split('.').pop();
			}
		}

		if (extension) {
			extension = extension.toLowerCase();
		}
		const isNumericExtension = /^\d+$/.test(extension);

		if (isNumericExtension) {
			extension = '';
		}
		return extension;
	};

	/**
	 * check dicom extension
	 * @param extension
	 * @param dicomExtensions
	 * @returns
	 */
	checkDicomExtension = (extension: any, dicomExtensions: any): boolean => {
		if (dicomExtensions.indexOf(extension) === -1) {
			return false;
		} else {
			return true;
		}
	};

	/**
	 * setLabelsForUploaderItem
	 * @param {item}
	 * @param {documentType}
	 * @returns {Item.labelObject}
	 */
	setLabelsForUploaderItem(
		item: Record<string, any>,
		documentType?: string,
	): Record<string, any> {
		let documentTypeSelected;
		if (!documentType) {
			documentTypeSelected =
				item.formObjectTemp && item.formObjectTemp.documentTypeSelected
					? item.formObjectTemp.documentTypeSelected
					: item.formObject.documentTypeSelected;
		} else {
			documentTypeSelected = documentType;
		}
		item.labelObject = {};
		item.labelObject.locationFlag = true;
		item.labelObject.documentDateFlag = true;
		item.labelObject.physicianFlag = true;
		switch (documentTypeSelected) {
			case UploadDocEnums.PRESCRIPTIONS:
			case UploadDocEnums.PRESCRIPTION:
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.prescriptionDateLabelText',
				);
				break;
			case UploadDocEnums.DISCHARGE_NOTE:
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.attendingPhysicianLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.dischargeDateLabelText',
				);
				break;
			case UploadDocEnums.LABORATORY_REPORTS:
			case UploadDocEnums.LABORATORY_REPORT:
			case UploadDocEnums.TEST_RESULTS:
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.labLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.orderedByLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.testDateLabelText',
				);
				break;

			case UploadDocEnums.RADIOLOGY_REPORTS:
			case UploadDocEnums.IMAGING_STUDIES:
			case UploadDocEnums.RADIOLOGY_REPORT:
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.imagingCentreLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.orderedByLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.testDateLabelText',
				);
				break;
			case UploadDocEnums.DICOM_IMAGES:
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.imagingCentreLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.orderedByLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.studyDateLabelText',
				);
				break;
			case UploadDocEnums.DOCTORS_LETTER:
				item.labelObject.locationFlag = false;
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.letterDateLabelText',
				);
				break;

			case UploadDocEnums.REFERRAL_NOTE:
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.referralDateLabelText',
				);
				break;
			case UploadDocEnums.CONSENT_FORMS:
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.consentDateLabelText',
				);
				break;
			case UploadDocEnums.CERTIFICATES_LETTERS:
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.letterDateLabelText',
				);
				break;
			case UploadDocEnums.BILLS_INVOICE:
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.invoiceDateLabelText',
				);
				break;
			case UploadDocEnums.PATIENT_IMAGE:
			case UploadDocEnums.QUESTIONNAIRE_ASSESSMENTS:
			case UploadDocEnums.ADVANCE_DIRECTIVES:
			case UploadDocEnums.OTHERS:
			case UploadDocEnums.PATIENT_IMAGES:
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.createdDateLabelText',
				);
				break;
			case UploadDocEnums.INSURANCE:
				item.labelObject.locationFlag = false;
				item.labelObject.documentDateFlag = false;
				item.labelObject.physicianFlag = false;
				break;
			default:
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.locationLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.createdDateLabelText',
				);
		}
		return item.labelObject;
	}

	/**
	 * used to match study data with patient data
	 * @param studyData
	 * @param defaultConfig
	 * @param patientObject
	 * @param checkAnonymous
	 */
	matchStudyDataWithPatientData = (
		studyData: any,
		defaultConfig: any,
		patientObject: any,
		checkAnonymous: any,
	): void => {
		if (defaultConfig.DicomUIDataMatchField === 'patientName') {
			let nameInStudy = studyData.patientName;
			const patientNameList = patientObject.name;
			let patientName = '';
			if (patientNameList && patientNameList.length > 0) {
				let patNameObj = patientObject.name[patientObject.name.length - 1];
				if (patNameObj.useCode === 'nickname') {
					patNameObj = patientObject.name[patientObject.name.length - 2];
				}
				patientName = patNameObj.text;
			}

			let nameInStudyList = nameInStudy.split('^');
			if (nameInStudyList.length > 1) {
				nameInStudyList = nameInStudyList.filter((name: any) => {
					return name !== '' ? name : false;
				});
				const lastName = nameInStudyList[0];
				nameInStudyList.shift();
				nameInStudy = nameInStudyList.join(' ') + ' ' + lastName;
			} else {
				nameInStudy = nameInStudyList[0];
			}

			console.log(
				'nameInStudy: ' + nameInStudy + ', patientName: ' + patientName,
			);
			if (
				nameInStudy !== patientName &&
				((checkAnonymous && patientName !== 'Anonymous Anonymous') ||
					!checkAnonymous)
			) {
				console.log('name mismatch...');
				if (checkDemographics(defaultConfig.showConfirmButton)) {
					studyData.sendToServer = false;
					studyData.nameMismatch = true;
				} else {
					studyData.confirmMessage = true;
					studyData.nameConfirmMessage = true;
				}
			}
		} else if (defaultConfig.DicomUIDataMatchField === 'patientId') {
			const patIdInStudy = studyData.patientId;
			const patId = strings('findIdentifier')(
				patientObject.identifier,
				'patientId',
			);

			console.log('patIdInStudy: ' + patIdInStudy + ', patId: ' + patId);
			if (patId) {
				if (patIdInStudy != patId) {
					console.log('patId mismatch...');
					if (checkDemographics(defaultConfig.showConfirmButton)) {
						studyData.sendToServer = false;
						studyData.patIdMismatch = true;
					} else {
						studyData.confirmMessage = true;
						studyData.patIdConfirmMessage = true;
					}
				}
			}
		}
	};

	/**
	 * addOrUpdateStudyObjectForDicomInstance
	 * @param dicomStudyMap
	 * @param dicomDirMap
	 * @param fileItem
	 * @param uploadingObject
	 * @param uploadPageObj
	 */
	addOrUpdateStudyObjectForDicomInstance = (
		dicomStudyMap: Record<string, any>,
		dicomDirMap: Record<string, any>,
		fileItem: any,
		uploadingObject: Record<string, any>,
		uploadPageObj: Record<string, any>,
	): void => {
		const webkitRelativePath = fileItem.webkitRelativePath;
		const dicomDirMapObjectForFileitem = dicomDirMap[webkitRelativePath];
		const studyIuid = dicomDirMapObjectForFileitem.studyIuid;

		//assigning series ID soup id study Id to fileItem to use them further :

		fileItem.seriesIuid = dicomDirMapObjectForFileitem.seriesIuid;
		fileItem.studyIuid = dicomDirMapObjectForFileitem.studyIuid;
		fileItem.sopIuid = dicomDirMapObjectForFileitem.sopIuid;

		if (!dicomStudyMap[studyIuid]) {
			const studyObject: any = {
				noOfInstances: 1,
				studySize: parseFloat(fileItem.size),
				studyIuid: studyIuid,
				studyName: dicomDirMapObjectForFileitem.studyName,
				patientName: dicomDirMapObjectForFileitem.patientName,
				patientDOB: dicomDirMapObjectForFileitem.patientDOB,
				patientSex: dicomDirMapObjectForFileitem.patientSex,
				patientDescription: dicomDirMapObjectForFileitem.patientDescription,
				patientId: dicomDirMapObjectForFileitem.patientId,
				modality: dicomDirMapObjectForFileitem.modality,
				description: dicomDirMapObjectForFileitem.description,
				fileType: 'DICOM',
				instanceList: [fileItem],
				formObject: {
					documentDateSelected: moment(
						dicomDirMapObjectForFileitem.studyDate,
						'YYYYMMDD',
					).toDate(),
					documentTypeSelected: 'DICOM Images',
					isValidUploadDate: true,
				},
			};
			studyObject.labelObject = this.setLabelsForUploaderItem(studyObject);
			dicomStudyMap[studyIuid] = studyObject;

			const patIdPatt = /^\s*\S+\s*$/m;
			console.log(
				'Space in pat id: ' +
					patIdPatt.test(dicomDirMapObjectForFileitem.patientId),
			);
			if (
				!dicomDirMapObjectForFileitem.patientId ||
				dicomDirMapObjectForFileitem.patientId.indexOf(',') != -1 ||
				!patIdPatt.test(dicomDirMapObjectForFileitem.patientId)
			) {
				dicomStudyMap[studyIuid].sendToServer = false;
				dicomStudyMap[studyIuid].patientIdError = true;
			} else {
				dicomStudyMap[studyIuid].sendToServer = true;
				if (
					uploadPageObj.defaultConfig &&
					checkDemographics(uploadPageObj.defaultConfig.confirmButtonSwitch)
				) {
					this.matchStudyDataWithPatientData(
						dicomStudyMap[studyIuid],
						uploadPageObj.defaultConfig,
						uploadingObject.patient,
						uploadingObject.checkAnonymous,
					);
				} else {
					dicomStudyMap[studyIuid].sendToServer = true;
				}
			}
		} else {
			const studyObject = dicomStudyMap[studyIuid];
			studyObject.studySize = studyObject.studySize + parseFloat(fileItem.size);
			studyObject.noOfInstances = studyObject.noOfInstances + 1;
			studyObject.instanceList.push(fileItem);
		}
	};

	/**
	 *
	 * @param fileItem
	 * @param scanObj
	 * @param worker
	 * @param index
	 * @returns
	 */
	parseDicomItem = (
		fileItem: any,
		scanObj: Record<string, any>,
		worker: any,
		index: number,
		parserDeferObject: any,
	): Promise<Record<string, any>> => {
		parserDeferObject[index] = generateDeferredPromise();
		const transferObject = {
			fileItem: fileItem,
			messageName: 'dicomParse',
			index: index,
		};
		if (!scanObj.cancelScan) {
			worker.postMessage(transferObject);
			worker.onmessage = (e: any) => {
				const result = e.data;
				parserDeferObject[result.index].resolve({
					status: result.status,
					dicomFileItemObject: result.dicomFileItemObject,
					index: result.index,
				});
			};
		} else {
			parserDeferObject[index].resolve({status: false, index: index});
		}
		return parserDeferObject[index].promise;
	};

	/**
	 * update non dicom batch list
	 * @param fileItem
	 * @param nonDicomList
	 */
	updateNonDicomList = (fileItem: any, nonDicomList: Array<any>): void => {
		fileItem.formObject = {
			documentTypeSelected: this.getDocumentType(),
		};
		fileItem.labelObject = this.setLabelsForUploaderItem(fileItem);
		const extension = this.getFileExtension(fileItem);
		const extensionResponseObject = this.getFileExtensionAndIconClass(
			fileItem.name,
		);
		fileItem.iconClass =
			extensionResponseObject && extensionResponseObject.iconClass
				? extensionResponseObject.iconClass
				: '';
		nonDicomList.push(fileItem);
	};

	/**
	 * add only valid item to queue
	 * @param filesList
	 * @param dicomDirMap
	 * @param counterObject
	 * @param uploadingObj
	 * @param uploadPageObj
	 * @param scanObj
	 * @returns promise
	 */
	addValidItemsToQueue = (
		filesList: Array<any>,
		dicomDirMap: any,
		counterObject: Record<string, any>,
		uploadingObj: Record<string, any>,
		uploadPageObj: Record<string, any>,
		scanObj: Record<string, any>,
	): Promise<Record<string, any>> => {
		let totalSize = 0;
		let addedItemsCount = 0;
		const nonDicomList = uploadingObj.listOfNonDicomFiles;
		const dicomStudyMap = uploadingObj.listOfDicomFiles;
		const promiseList: any = [];
		const invalidDicomFiles: any = [];
		const deferObject: any = {};
		const parserDeferObject: any = {};

		const allPromise = new Promise<Record<string, any>>((resolve, reject) => {
			let timeoutCounter = 10;
			const worker = new Worker(worker_script, {
				name: 'dicomParserWorker',
				type: 'module',
			});
			const startingParsingTime = new Date().getTime();
			if (!scanObj.cancelScan) {
				filesList.forEach(function (fileItem, index) {
					deferObject[index] = generateDeferredPromise();
					if (!fileItem.scanned && !scanObj.cancelScan) {
						if (index % 500 === 0) {
							timeoutCounter += 10;
						}
						promiseList.push(deferObject[index].promise);

						setTimeout(function () {
							const vm = Container.get(UploaderHelperService);
							totalSize += fileItem.size;
							const extensionName = vm.getFileExtension(fileItem);
							fileItem.fileSelectorType = uploadPageObj.currentInputType;
							if (
								vm.checkDicomExtension(
									extensionName,
									uploadPageObj.allowedFileTypeObjects.dicom,
								)
							) {
								let webkitRelativePath = fileItem.webkitRelativePath;
								if (dicomDirMap[webkitRelativePath]) {
									counterObject.currentScannedItems++;
									fileItem.scanned = true;
									fileItem.uploaded = false;
									fileItem.invalid = false;
									fileItem.error = false;
									addedItemsCount++;
									vm.addOrUpdateStudyObjectForDicomInstance(
										dicomStudyMap,
										dicomDirMap,
										fileItem,
										uploadingObj,
										uploadPageObj,
									);
									deferObject[index].resolve();
									//console.log("File present in dicomDirMap");
								} else {
									const responsePromise = vm.parseDicomItem(
										fileItem,
										scanObj,
										worker,
										index,
										parserDeferObject,
									);
									responsePromise.then((responseObject: any) => {
										const returnedIndex = responseObject.index;
										if (responseObject.status === true) {
											const dicomFileItemObject =
												responseObject.dicomFileItemObject;
											webkitRelativePath =
												filesList[returnedIndex].webkitRelativePath;
											dicomDirMap[webkitRelativePath] = dicomFileItemObject;
											vm.addOrUpdateStudyObjectForDicomInstance(
												dicomStudyMap,
												dicomDirMap,
												filesList[returnedIndex],
												uploadingObj,
												uploadPageObj,
											);
											addedItemsCount++;
										} else {
											totalSize -= filesList[returnedIndex].size;
											invalidDicomFiles.push(filesList[returnedIndex]);
										}
										filesList[returnedIndex].scanned = true;
										filesList[returnedIndex].uploaded = false;
										filesList[returnedIndex].invalid = false;
										filesList[returnedIndex].error = false;
										counterObject.currentScannedItems++;
										deferObject[returnedIndex].resolve();
										if (returnedIndex !== 0 && returnedIndex % 500 === 0) {
											//utilityService.keepUserLoggedIn();
										}
									});
								}
							} else {
								fileItem.scanned = true;
								fileItem.uploaded = false;
								fileItem.invalid = false;
								fileItem.error = false;
								counterObject.currentScannedItems++;
								addedItemsCount++;
								vm.updateNonDicomList(fileItem, nonDicomList);
								deferObject[index].resolve();
							}
						}, timeoutCounter);
					}
				});
			} else {
				worker.terminate();
			}
			Promise.all(promiseList).then(function (promiseResponseList) {
				const parsingEndingTime = new Date().getTime();
				//alert("Time in parsing :" + (parsingEndingTime-startingParsingTime))
				worker.terminate();
				const responseMap = {
					nonDicomList: nonDicomList,
					dicomStudies: dicomStudyMap,
					invalidDicomFiles: invalidDicomFiles,
					addedItemsCount: addedItemsCount,
					totalSize: totalSize,
				};
				resolve(responseMap);
			});
		});
		return allPromise;
	};

	/**
	 * get file extension and icon class
	 * @param fileName
	 * @returns
	 */
	getFileExtensionAndIconClass = (
		fileName: any,
	): Record<string, any> | null => {
		if (!fileName) {
			return null;
		}
		//mapping of fileExtension with iconClass here
		const extensionIconMap: any = {
			doc: {iconClass: 'icon-word-document'},
			docx: {iconClass: 'icon-word-document'},
			pdf: {iconClass: 'icon-PDF-file'},
			zip: {iconClass: 'icon-zip_file'},
			png: {iconClass: 'icon-PNG_image'},
			jpg: {iconClass: 'icon-JPG_image'},
			jpeg: {iconClass: 'icon-JPG_image'},
			txt: {iconClass: 'icon-text-file'},
			mp4: {iconClass: 'icon-MP4'},
			xml: {iconClass: 'icon-XML'},
		};

		const fileExtension = getFileExtension(fileName);
		if (fileExtension && extensionIconMap && extensionIconMap[fileExtension]) {
			const resultObj = {
				fileExtension: fileExtension,
				iconClass: extensionIconMap[fileExtension]['iconClass'],
			};
			return resultObj;
		} else {
			return null;
		}
	};

	/**
	 * add valid item to queue slowly
	 * @param filesList
	 * @param dicomDirMap
	 * @param counterObject
	 * @param uploadingObj
	 * @param uploadPageObj
	 * @param scanObj
	 * @returns
	 */
	addValidItemsToQueueSlowly = (
		filesList: Array<any>,
		dicomDirMap: Record<string, any>,
		counterObject: Record<string, any>,
		uploadingObj: Record<string, any>,
		uploadPageObj: Record<string, any>,
		scanObj: Record<string, any>,
	) => {
		let totalSize = 0;
		let addedItemsCount = 0;
		const nonDicomList = uploadingObj.listOfNonDicomFiles;
		const dicomStudyMap = uploadingObj.listOfDicomFiles;

		const promiseList: Record<string, any> = {};

		const invalidDicomFiles: Array<Record<string, any>> = [];

		const lengthOfFilesList = filesList.length;

		const deferObject: any = {};
		const parserDeferObject: any = {};

		let index = 0;

		let promiseIndex = 0;

		const worker = new Worker('./UploadWorker');

		const deferResonse = new Promise((resolve, reject) => {
			const parse100Files = (startIndex: number, remaining: number) => {
				const deferOfParse100 = generateDeferredPromise<void>();
				console.log(startIndex, remaining);
				if (index < lengthOfFilesList) {
					promiseList[promiseIndex] = [];
					let counted = 0;
					const toBeCounted = remaining;
					for (index = startIndex; index < remaining + startIndex; index++) {
						if (index < lengthOfFilesList) {
							const newPromise = new Promise((resolve, reject) => {
								const fileItem = filesList[index];

								deferObject[index] = generateDeferredPromise();
								promiseList[promiseIndex].push(deferObject[index].promise);
								totalSize += fileItem.size;
								const extensionName = this.getFileExtension(fileItem);
								if (
									this.checkDicomExtension(
										extensionName,
										uploadPageObj.allowedFileTypeObjects.dicom,
									)
								) {
									let webkitRelativePath = fileItem.webkitRelativePath;
									if (dicomDirMap[webkitRelativePath]) {
										counterObject.currentScannedItems++;
										fileItem.scanned = true;
										fileItem.uploaded = false;
										fileItem.invalid = false;
										fileItem.error = false;
										addedItemsCount++;
										this.addOrUpdateStudyObjectForDicomInstance(
											dicomStudyMap,
											dicomDirMap,
											fileItem,
											uploadingObj,
											uploadPageObj,
										);
										deferObject[index].resolve();
										counted++;
										if (toBeCounted === counted) {
											deferOfParse100.resolve();
										}
										//console.log("File present in dicomDirMap");
									} else {
										const responsePromise = this.parseDicomItem(
											fileItem,
											scanObj,
											worker,
											index,
											parserDeferObject,
										);
										responsePromise.then((responseObject: any) => {
											const returnedIndex = responseObject.index;
											if (responseObject.status === true) {
												const dicomFileItemObject: any =
													responseObject.dicomFileItemObject;
												webkitRelativePath =
													filesList[returnedIndex].webkitRelativePath;
												dicomDirMap[webkitRelativePath] = dicomFileItemObject;
												this.addOrUpdateStudyObjectForDicomInstance(
													dicomStudyMap,
													dicomDirMap,
													filesList[returnedIndex],
													uploadingObj,
													uploadPageObj,
												);
												addedItemsCount++;
											} else {
												totalSize -= filesList[returnedIndex].size;
												invalidDicomFiles.push(filesList[returnedIndex]);
											}
											filesList[returnedIndex].scanned = true;
											filesList[returnedIndex].uploaded = false;
											filesList[returnedIndex].invalid = false;
											filesList[returnedIndex].error = false;
											counterObject.currentScannedItems++;
											resolve(true);
											counted++;
											if (toBeCounted === counted) {
												deferOfParse100.resolve();
											}
											if (returnedIndex !== 0 && returnedIndex % 250 === 0) {
												//utilityService.keepUserLoggedIn();
												console.log('keep user logged in');
											}
										});
									}
								} else {
									fileItem.scanned = true;
									fileItem.uploaded = false;
									fileItem.invalid = false;
									fileItem.error = false;
									counterObject.currentScannedItems++;
									addedItemsCount++;
									this.updateNonDicomList(fileItem, nonDicomList);
									deferObject[index].resolve();
									counted++;
									if (toBeCounted === counted) {
										deferOfParse100.resolve();
									}
								}
							});
						} else {
							deferOfParse100.resolve();
						}
					}
					deferOfParse100.promise.then(function () {
						promiseIndex++;
						parse100Files(index, Math.min(100, lengthOfFilesList - index));
					});
				} else {
					worker.terminate();
					const responseMap = {
						nonDicomList: nonDicomList,
						dicomStudies: dicomStudyMap,
						invalidDicomFiles: invalidDicomFiles,
						addedItemsCount: addedItemsCount,
						totalSize: totalSize,
					};
					resolve(responseMap);
				}
			};

			parse100Files(0, Math.min(100, lengthOfFilesList));
		});
		return deferResonse;
	};

	/**
	 * remove invalid dicom files
	 * @param invalidDicomFiles
	 * @param validFilesList
	 */
	removeInvalidDicoms = (
		invalidDicomFiles: Array<any>,
		validFilesList: Array<any>,
	) => {
		invalidDicomFiles.forEach((invalidDicomItem: any) => {
			for (let i = 0; i < validFilesList.length; i++) {
				const validItem = validFilesList[i];
				if (
					validItem.name === invalidDicomItem.name &&
					validItem.size === invalidDicomItem.size &&
					validItem.type === invalidDicomItem.type
				) {
					validFilesList.splice(i, 1);
				}
			}
		});
	};

	/**
	 * used to parse and filter files
	 * @param filesArray
	 * @param counterObject
	 * @param uploadingObj
	 * @param uploadPageObj
	 * @param scanObj
	 * @returns
	 */
	parseAndFilterFiles = (
		filesArray: Array<any>,
		counterObject: Record<string, any>,
		uploadingObj: Record<string, any>,
		uploadPageObj: Record<string, any>,
		scanObj: Record<string, any>,
		parseDicom: any,
	): Observable<Record<string, any>> => {
		const pareseAndFilterFilesResponse = new Observable(
			(subscriber: Subscriber<Record<string, any>>) => {
				const extensionFilterStartTime = moment().toDate().getTime();
				this.extensionFilter(filesArray, counterObject, uploadPageObj).then(
					response => {
						const extensionFilterFinishTime = moment().toDate().getTime();
						const responseObject: any = response;
						const validFilesList = responseObject.validFilesList;
						let invalidFilesList = responseObject.invalidFilesList;
						const dicomDirFileList = responseObject.dicomDirFileList;
						const dicomDIRMapResponsePromise = this.parseDicomDIRFileItems(
							dicomDirFileList,
							scanObj,
							parseDicom,
						);
						dicomDIRMapResponsePromise.then(dicomDirMap => {
							let responsePromise;
							if (
								this.checkSlowPerformanceOfExtensionFiltering(
									extensionFilterFinishTime - extensionFilterStartTime,
									filesArray.length,
								) &&
								!dicomDirFileList.length
							) {
								console.log(
									'scanning is detected as slow and no dicom dir present hence keeping memory consumption low to make process faster',
								);
								responsePromise = this.addValidItemsToQueueSlowly(
									validFilesList,
									dicomDirMap,
									counterObject,
									uploadingObj,
									uploadPageObj,
									scanObj,
								);
							} else {
								console.log(
									'scanning is detected as fast hence using full memory utilization to give better results ',
								);
								responsePromise = this.addValidItemsToQueue(
									validFilesList,
									dicomDirMap,
									counterObject,
									uploadingObj,
									uploadPageObj,
									scanObj,
								);
							}

							responsePromise.then((responseMap: any) => {
								const invalidDicomFiles = responseMap.invalidDicomFiles;
								invalidFilesList = invalidFilesList.concat(invalidDicomFiles);
								invalidFilesList = invalidFilesList.concat(dicomDirFileList);
								this.removeInvalidDicoms(invalidDicomFiles, validFilesList);
								counterObject.invalidItems += invalidFilesList.length;
								counterObject.validItems += responseMap.addedItemsCount;
								subscriber.next(responseMap);
							});
						});
					},
				);
			},
		);

		return pareseAndFilterFilesResponse;
	};

	/**
	 * set file types list
	 * @param inputType
	 * @param defaultConfig
	 * @param uploadPageObj
	 * @returns fileTypesLists
	 */
	setFileTypesLists = (
		inputType: string,
		defaultConfig: Record<string, any>,
		uploadPageObj: Record<string, any>,
	): Array<string> => {
		const allowedFileTypeObjects = uploadPageObj.allowedFileTypeObjects;
		let fileTypeList = uploadPageObj.fileTypeList;

		if (inputType == 'folder') {
			console.log('files selected using folder');
			console.log(
				'value of allowedUploadFolderExtensions :' +
					defaultConfig.allowedUploadFolderExtensions,
			);
			if (
				defaultConfig.allowedUploadFolderExtensions.length === 0 ||
				defaultConfig.allowedUploadFolderExtensions === undefined
			) {
				allowedFileTypeObjects['dicom'] = defaultConfig.defaultExtensions[
					'dicom'
				]
					? defaultConfig.defaultExtensions['dicom']
					: [];
				allowedFileTypeObjects['nonDicom'] = defaultConfig.defaultExtensions[
					'nonDicom'
				]
					? defaultConfig.defaultExtensions['nonDicom']
					: [];
			} else {
				allowedFileTypeObjects['dicom'] = defaultConfig
					.allowedUploadFolderExtensions['dicom']
					? defaultConfig.allowedUploadFolderExtensions['dicom']
					: [];
				allowedFileTypeObjects['nonDicom'] = defaultConfig
					.allowedUploadFolderExtensions['nonDicom']
					? defaultConfig.allowedUploadFolderExtensions['nonDicom']
					: [];
			}
		} else if (inputType == 'dropzone') {
			console.log('files selected using dropzone');
			console.log(
				'value of allowedDropzoneExtensions :' +
					defaultConfig.allowedDropzoneExtensions,
			);
			if (
				defaultConfig.allowedDropzoneExtensions.length === 0 ||
				defaultConfig.allowedDropzoneExtensions === undefined
			) {
				console.log('filetypes list set to default for dropzone');
				allowedFileTypeObjects['dicom'] = defaultConfig.defaultExtensions[
					'dicom'
				]
					? defaultConfig.defaultExtensions['dicom']
					: [];
				allowedFileTypeObjects['nonDicom'] = defaultConfig.defaultExtensions[
					'nonDicom'
				]
					? defaultConfig.defaultExtensions['nonDicom']
					: [];
			} else {
				allowedFileTypeObjects['dicom'] = defaultConfig
					.allowedDropzoneExtensions['dicom']
					? defaultConfig.allowedDropzoneExtensions['dicom']
					: [];
				allowedFileTypeObjects['nonDicom'] = defaultConfig
					.allowedDropzoneExtensions['nonDicom']
					? defaultConfig.allowedDropzoneExtensions['nonDicom']
					: [];
			}
		} else {
			console.log('files selected using files button');
			console.log(
				'value of allowedUploadFileExtensions :' +
					defaultConfig.allowedUploadFileExtensions,
			);
			if (
				defaultConfig.allowedUploadFileExtensions.length === 0 ||
				defaultConfig.allowedUploadFileExtensions === undefined
			) {
				allowedFileTypeObjects['dicom'] = defaultConfig.defaultExtensions[
					'dicom'
				]
					? defaultConfig.defaultExtensions['dicom']
					: [];
				allowedFileTypeObjects['nonDicom'] = defaultConfig.defaultExtensions[
					'nonDicom'
				]
					? defaultConfig.defaultExtensions['nonDicom']
					: [];
			} else {
				allowedFileTypeObjects['dicom'] = defaultConfig
					.allowedUploadFileExtensions['dicom']
					? defaultConfig.allowedUploadFileExtensions['dicom']
					: [];
				allowedFileTypeObjects['nonDicom'] = defaultConfig
					.allowedUploadFileExtensions['nonDicom']
					? defaultConfig.allowedUploadFileExtensions['nonDicom']
					: [];
			}
		}
		fileTypeList = _.cloneDeep(allowedFileTypeObjects.dicom);
		fileTypeList = fileTypeList.concat(allowedFileTypeObjects.nonDicom);

		return fileTypeList;
	};

	/**
	 * checkAtLeastOneValidNonDicomToBeUploaded
	 * @param listOfNonDicomFiles
	 * @returns
	 */
	checkAtLeastOneValidNonDicomToBeUploaded = (
		listOfNonDicomFiles: Array<any>,
	): boolean => {
		let result = false;
		if (listOfNonDicomFiles) {
			for (let i = 0; i < listOfNonDicomFiles.length; i++) {
				if (!listOfNonDicomFiles[i].uploaded) {
					result = true;
					break;
				}
			}
		}
		return result;
	};

	/**
	 * checkDicomStudiesUploaded
	 * @param studyObject
	 * @param dicomBatchList
	 * @returns
	 */
	checkDicomStudiesUploaded = (
		studyObject: Record<string, any>,
		dicomBatchList: Array<any>,
	): boolean => {
		let uploaded = true;
		const batchIndexList = studyObject.batchIndexList;
		if (!batchIndexList || batchIndexList.length <= 0) {
			uploaded = false;
		} else if (!dicomBatchList) {
			uploaded = false;
		} else {
			batchIndexList.forEach((batchIndex: number) => {
				const batchObject = dicomBatchList[batchIndex];
				if (!batchObject.uploaded) {
					uploaded = false;
					return true;
				}
			});
		}
		return uploaded;
	};

	/**
	 * checkAtLeastOneValidStudyToBeUploaded
	 * @param listOfDicomFiles
	 * @param dicomBatchList
	 * @returns
	 */
	checkAtLeastOneValidStudyToBeUploaded = (
		listOfDicomFiles: Array<any>,
		dicomBatchList: Array<any>,
	): boolean => {
		let result = false;

		for (const key in listOfDicomFiles) {
			if (_.has(listOfDicomFiles, key)) {
				const val = listOfDicomFiles[key];
				if (
					val.sendToServer &&
					this.checkDicomStudiesUploaded(val, dicomBatchList)
				) {
					result = true;
					break;
				}
			}
		}
		return result;
	};

	/**
	 * setValueInStudy
	 * @param uploadingObj
	 * @param dicomBatch
	 * @param keyName
	 * @param value
	 */
	setValueInStudy = (
		uploadingObj: Record<string, any>,
		dicomBatch: any,
		keyName: any,
		value: any,
	): void => {
		const itemList = dicomBatch.itemList;
		const studyIuid = itemList[0]['studyIuid'];
		uploadingObj.listOfDicomFiles[studyIuid][keyName] = value;
	};

	/**
	 * create File Data JSONObject For DicomUpload
	 * @param dicomBatch
	 * @returns fileDataJSON
	 */
	createFileDataJSONObjectForDicomUpload = (
		dicomBatch: Record<string, any>,
	): Record<string, any> => {
		const dicomInstanceList = dicomBatch.itemList;
		const fileDataJSON: Record<string, any> = {};
		for (let i = 0; i < dicomInstanceList.length; i++) {
			const indivitualObjectForFileData = {
				seriesIuid: dicomInstanceList[i].seriesIuid,
				fileSelectorType: dicomInstanceList[i].fileSelectorType,
				sopIuid: dicomInstanceList[i].sopIuid,
				fileName: dicomInstanceList[i].name,
				fileSize: dicomInstanceList[i].size,
				filePath: dicomInstanceList[i].filePath,
				fileExtension: 'dcm',
			};
			const aliasName = 'file_' + i;
			dicomInstanceList[i].aliasName = aliasName;
			fileDataJSON[aliasName] = indivitualObjectForFileData;
		}
		return fileDataJSON;
	};

	/**
	 * add files to formData dicom
	 * @param formData a
	 * @param dicomBatch
	 * @returns formData
	 */
	addFilesToFormDataDICOM = (
		formData: Record<string, any>,
		dicomBatch: any,
	): Record<string, any> => {
		const itemList = dicomBatch.itemList;
		for (let i = 0; i < itemList.length; i++) {
			const aliasName = 'file_' + i;
			formData[aliasName] = itemList[i];
		}
		return formData;
	};

	/**
	 * createFormDataForDicomBatchUploading
	 * @param uploadingObj
	 * @param dicomBatch
	 * @returns formData
	 */
	createFormDataForDicomBatchUploading = (
		uploadingObj: Record<string, any>,
		dicomBatch: Record<string, any>,
	): Record<string, any> => {
		const fileDataJSON =
			this.createFileDataJSONObjectForDicomUpload(dicomBatch);
		const studyIuid = dicomBatch.itemList[0].studyIuid;
		const studyObject = uploadingObj.listOfDicomFiles[studyIuid];
		// TODO get date from backend instead of using new date
		let formData: Record<string, any> = {
			sourceType: this.getUploaderType(),
			actionContext: this.getActionContext(),
			type: studyObject.formObject.documentTypeSelected,
			patientId: uploadingObj.patientId,
			patientSex: studyObject.patientSex,
			patientDescription: studyObject.patientDescription,
			patientDOB: studyObject.patientDOB,
			patientName: studyObject.patientName,
			studyDesc: studyObject.description,
			customDetailsMap: JSON.stringify(uploadingObj.customDetailsMap),
			noOfInstances: studyObject.noOfInstances,
			studyModality: studyObject.modality,
			studyIuid: studyIuid,
			uploadingUniqueId: uploadingObj.uploadingUniqueId,
			uploadedDate: calendarToServerDateFormatGMT(new Date()),
			fileType: studyObject.fileType,
			applicationId: uploadingObj.applicationId
				? uploadingObj.applicationId
				: '',
			fileData: JSON.stringify(fileDataJSON),
			reason:
				studyObject.formObject && studyObject.formObject.patientCondition
					? studyObject.formObject.patientCondition.value
					: '',
			linkedLocationResource:
				studyObject.formObject && studyObject.formObject.location
					? studyObject.formObject.location.value
					: '',
			practitionerId:
				studyObject.formObject && studyObject.formObject.physician
					? studyObject.formObject.physician.value
					: '',
			encounterId:
				studyObject.formObject && studyObject.formObject.encounter
					? studyObject.formObject.encounter.value
					: '',
			studyDate:
				studyObject.formObject && studyObject.formObject.documentDateSelected
					? calendarToServerDateFormatGMT(
							studyObject.formObject.documentDateSelected,
					  )
					: calendarToServerDateFormatGMT(new Date()),
		};
		_.each(formData, (value: any, key: string) => {
			if (_.isUndefined(value)) {
				delete formData[key];
			}
		});
		formData = this.addFilesToFormDataDICOM(formData, dicomBatch);
		return formData;
	};

	/**
	 * reset values in study
	 * @param uploadingObj
	 * @param dicomBatch
	 * @param uploadBatchObject
	 * @param keyName
	 * @param value
	 */
	resetValueInStudy = (
		uploadingObj: Record<string, any>,
		dicomBatch: any,
		uploadBatchObject: any,
		keyName: any,
		value: any,
	): void => {
		const itemList = dicomBatch.itemList;
		const studyIuid = itemList[0]['studyIuid'];
		dicomBatch[keyName] = value;
		const studyObject = uploadingObj.listOfDicomFiles[studyIuid];
		studyObject[keyName] = dicomBatch[keyName];
		const batchIndexList = studyObject.batchIndexList;
		batchIndexList.forEach((batchIndex: any) => {
			const uploadInProgressForDicomBatch =
				uploadBatchObject.dicomBatchList &&
				uploadBatchObject.dicomBatchList[batchIndex]
					? uploadBatchObject.dicomBatchList[batchIndex][keyName]
					: true;
			studyObject[keyName] =
				studyObject[keyName] || uploadInProgressForDicomBatch;
		});
	};

	/**
	 * setValueInNonDicom
	 * @param nonDicomBatch
	 * @param keyName
	 * @param value
	 */
	setValueInNonDicom = (
		nonDicomBatch: Record<string, any>,
		keyName: string,
		value: any,
	): void => {
		const itemList = nonDicomBatch.itemList;
		itemList.forEach((nonDicom: any) => {
			nonDicom[keyName] = value;
		});
	};

	/**
	 * createFileDataJSONObjectForNonDicomUpload
	 * @param nonDicomBatch
	 * @returns fileDataJSON
	 */
	createFileDataJSONObjectForNonDicomUpload = (
		nonDicomBatch: Record<string, any>,
	): Record<string, any> => {
		const itemList = nonDicomBatch.itemList;
		const fileDataJSON: Record<string, any> = {};
		for (let i = 0; i < itemList.length; i++) {
			const item = itemList[i];

			const indivitualObjectForFileData = {
				name: 'name_' + i,
				fileName: item.name,
				fileSize: item.size,
				description: item.formObject.description
					? item.formObject.description
					: '',
				fileSelectorType: item.fileSelectorType,
				type: item.formObject.documentTypeSelected
					? typeof item.formObject.documentTypeSelected === 'string'
						? item.formObject.documentTypeSelected
						: item.formObject.documentTypeSelected?.value
					: '',
				created:
					item.formObject &&
					item.formObject.documentDateSelected &&
					item.labelObject.documentDateFlag
						? calendarToServerDateFormatGMT(
								item.formObject.documentDateSelected,
						  )
						: '',
				linkedToStudy:
					item.formObject.study && item.formObject.study.value
						? item.formObject.study.value
						: '',
				linkedStudyModality:
					item.formObject?.study && item.formObject.study.label
						? item.formObject.study.label.indexOf('-') > -1
							? item.formObject.study.label.split('-')[0]
							: item.formObject.study.label
						: '',
				linkedStudyDescription:
					item.formObject?.study && item.formObject.study.label
						? item.formObject.study.label.indexOf('-') > -1
							? item.formObject.study.label.split('-')[1]
							: ''
						: '',
				linkedLocationResource:
					item.formObject &&
					item.formObject.location &&
					item.labelObject.locationFlag &&
					item.formObject.location.value
						? item.formObject.location.value
						: '',
				practitionerId:
					item.formObject &&
					item.formObject.physician &&
					item.labelObject.physicianFlag
						? item.formObject.physician.value
						: '',
				encounterId:
					item.formObject && item.formObject.encounter
						? item.formObject.encounter.value
						: '',
				reason:
					item.formObject && item.formObject.patientCondition
						? item.formObject.patientCondition.value
						: '',
			};
			const aliasName = 'file_' + i;
			item.aliasName = aliasName;
			fileDataJSON[aliasName] = indivitualObjectForFileData;
		}
		return fileDataJSON;
	};

	/**
	 * addFilesToFormDataNonDicom
	 * @param formData
	 * @param nonDicomBatch
	 * @returns formData
	 */
	addFilesToFormDataNonDicom = (
		formData: Record<string, any>,
		nonDicomBatch: Record<string, any>,
	): Record<string, any> => {
		const itemList = nonDicomBatch.itemList;
		for (let i = 0; i < itemList.length; i++) {
			const aliasName = 'file_' + i;
			formData[aliasName] = itemList[i];
		}
		return formData;
	};

	/**
	 * createFormDataForNonDicomBatchUploading
	 * @param uploadingObj
	 * @param nonDicomBatch
	 * @returns formData
	 */
	createFormDataForNonDicomBatchUploading = (
		uploadingObj: Record<string, any>,
		nonDicomBatch: Record<string, any>,
	) => {
		const fileDataJSON =
			this.createFileDataJSONObjectForNonDicomUpload(nonDicomBatch);
		let formData: Record<string, any> = {
			sourceType: this.getUploaderType(),
			actionContext: this.getActionContext(),
			patientId: uploadingObj.patientId,
			uploadingUniqueId: uploadingObj.uploadingUniqueId,
			fileType: 'NON_DICOM',
			customDetailsMap: JSON.stringify(uploadingObj.customDetailsMap),
			applicationId: uploadingObj.applicationId
				? uploadingObj.applicationId
				: '',
			fileData: JSON.stringify(fileDataJSON),
			uploadedDate: calendarToServerDateFormatGMT(),
		};
		_.each(formData, (value: any, key: string) => {
			if (_.isUndefined(value)) {
				delete formData[key];
			}
		});
		formData = this.addFilesToFormDataNonDicom(formData, nonDicomBatch);
		return formData;
	};

	/**
	 * handleSuccessForNonDicomBatchUpload
	 * @param uploadingObj
	 * @param nonDicomBatch
	 * @param index
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param response
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns promise
	 */
	handleSuccessForNonDicomBatchUpload = (
		uploadingObj: Record<string, any>,
		nonDicomBatch: Record<string, any>,
		index: number,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		response: any,
		responseObject: any,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<void> => {
		return new Promise<void>((resolve, reject) => {
			const currentUploadingNonDicomBatchIndexList =
				uploadBatchObject.currentUploadingNonDicomBatchIndexList;
			const uploadedNonDicomBatchList =
				uploadBatchObject.uploadedNonDicomBatchList;
			nonDicomBatch.uploaded = true;
			this.setValueInNonDicom(nonDicomBatch, 'uploadInProgress', false);
			uploaderViewModel.updateUploaderModelSubject();
			uploadedNonDicomBatchList.push(index);
			currentUploadingNonDicomBatchIndexList.splice(
				currentUploadingNonDicomBatchIndexList.indexOf(index),
				1,
			);
			if (response.documentListMap) {
				if (response.documentListMap.errorFileAliasList) {
					response.documentListMap.errorFileAliasList.forEach(
						(errorFileAlias: any) => {
							const indexString = errorFileAlias.substring(5);
							const index = Number(indexString);
							nonDicomBatch.itemList[index].error = true;
							responseObject.errorFileList.push(nonDicomBatch.itemList[index]);
						},
					);
				}
				if (response.documentListMap.invalidFileAliasList) {
					response.documentListMap.invalidFileAliasList.forEach(
						(invalidFileAlias: any) => {
							const indexString = invalidFileAlias.substring(5);
							const index = Number(indexString);
							nonDicomBatch.itemList[index].invalid = true;
							responseObject.invalidFileList.push(
								nonDicomBatch.itemList[index],
							);
						},
					);
				}
				if (response.documentListMap.successFileAliasList) {
					response.documentListMap.successFileAliasList.forEach(
						(successFileAlias: any) => {
							const indexString = successFileAlias.substring(5);
							const index = Number(indexString);
							nonDicomBatch.itemList[index].uploaded = true;
							responseObject.successFileList.push(
								nonDicomBatch.itemList[index],
							);
						},
					);
					counterObject.uploaded +=
						response.documentListMap.successFileAliasList.length;
				}
			} else {
				responseObject.errorFileList = nonDicomBatch.itemList;
			}
			const responsePromise = this.selectAndUploadNewBatchToUpload(
				uploadingObj,
				uploadBatchObject,
				counterObject,
				timerObject,
				responseObject,
				uploaderViewModel,
				cancelUploadMap,
			);
			responsePromise.then(response => {
				resolve();
			});
		});
	};

	/**
	 * handleNotUploadingNonDicomBatch
	 * @param uploadingObj
	 * @param nonDicomBatch
	 * @param index
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns promise
	 */
	handleNotUploadingNonDicomBatch = (
		uploadingObj: Record<string, any>,
		nonDicomBatch: Record<string, any>,
		index: number,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		responseObject: any,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<void> => {
		return new Promise<void>((resolve, reject) => {
			const currentUploadingNonDicomBatchIndexList =
				uploadBatchObject.currentUploadingNonDicomBatchIndexList;
			const uploadedNonDicomBatchList =
				uploadBatchObject.uploadedNonDicomBatchList;
			nonDicomBatch.uploaded = true;
			uploadedNonDicomBatchList.push(index);
			currentUploadingNonDicomBatchIndexList.splice(
				currentUploadingNonDicomBatchIndexList.indexOf(index),
				1,
			);
			const responsePromise = this.selectAndUploadNewBatchToUpload(
				uploadingObj,
				uploadBatchObject,
				counterObject,
				timerObject,
				responseObject,
				uploaderViewModel,
				cancelUploadMap,
			);
			responsePromise.then(resp => {
				resolve();
			});
		});
	};

	/**
	 * handleErrorForNonDicomBatchUpload
	 * @param uploadingObj
	 * @param nonDicomBatch
	 * @param index
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param response
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns promise
	 */
	handleErrorForNonDicomBatchUpload = (
		uploadingObj: Record<string, any>,
		nonDicomBatch: any,
		index: number,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		response: Record<string, any>,
		responseObject: Record<string, any>,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<void> => {
		return new Promise<void>((resolve, reject) => {
			const currentUploadingNonDicomBatchIndexList =
				uploadBatchObject.currentUploadingNonDicomBatchIndexList;
			const uploadedNonDicomBatchList =
				uploadBatchObject.uploadedNonDicomBatchList;
			nonDicomBatch.uploaded = true;
			nonDicomBatch.error = true;
			this.setValueInNonDicom(nonDicomBatch, 'uploadInProgress', false);
			uploaderViewModel.updateUploaderModelSubject();
			uploadedNonDicomBatchList.push(index);
			currentUploadingNonDicomBatchIndexList.splice(
				currentUploadingNonDicomBatchIndexList.indexOf(index),
				1,
			);
			responseObject.errorFileList = responseObject.errorFileList.concat(
				nonDicomBatch.itemList,
			);
			this.setValueInNonDicom(nonDicomBatch, 'error', true);
			uploaderViewModel.updateUploaderModelSubject();
			const responsePromise = this.selectAndUploadNewBatchToUpload(
				uploadingObj,
				uploadBatchObject,
				counterObject,
				timerObject,
				responseObject,
				uploaderViewModel,
				cancelUploadMap,
			);
			responsePromise.then(resp => {
				resolve();
			});
		});
	};

	/**
	 * calculate speed
	 * @param size
	 * @param time
	 * @returns speed
	 */
	calculateSpeed = (size: number, time: number): number => {
		let speed = 0;
		if (!time) {
			speed = 0;
		} else if (size < 0 || time < 0) {
			speed = 0;
		} else {
			speed = size / time;
		}
		return speed;
	};

	/**
	 * calculate estimated time
	 * @param speed
	 * @param size
	 * @returns estimatedTime
	 */
	calculateEstimatedTime = (speed: number, size: number): number => {
		let estimatedTime = 0;
		if (!speed) {
			estimatedTime = 0;
		} else if (speed < 0 || size < 0) {
			estimatedTime = 0;
		} else {
			estimatedTime = size / speed;
		}
		return estimatedTime;
	};

	/**
	 * timeConversion
	 * @param time
	 * @returns result_1
	 */
	timeConversion = (time: number): string => {
		if (isNaN(time)) {
			return this.unknownTime;
		}
		let result;
		let result_1: string;
		const _second = 1000;
		const _minute = _second * 60;
		const _hour = _minute * 60;
		const _day = _hour * 24;
		const days = Math.floor(time / _day);
		const hours = Math.floor((time % _day) / _hour);
		const minutes = Math.floor((time % _hour) / _minute);
		const seconds = Math.floor((time % _minute) / _second);
		function pad2(number: any) {
			return (number < 10 ? '0' : '') + number;
		}
		if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
			return this.unknownTime;
		}
		if (hours === 0) {
			if (minutes === 0) {
				result_1 = pad2(seconds) + ' sec';
			} else {
				result_1 = pad2(minutes) + ' min ' + pad2(seconds) + ' sec';
			}
		} else {
			result_1 =
				pad2(hours) + ' h ' + pad2(minutes) + ' min ' + pad2(seconds) + ' sec';
		}
		return result_1;
	};

	/**
	 * handleProgressForBatch
	 * @param progressEvent
	 * @param timerObject
	 * @param batchObject
	 * @param uploaderViewModel
	 */
	handleProgressForBatch = (
		progressEvent: ProgressEvent,
		timerObject: Record<string, any>,
		batchObject: Record<string, any>,
		uploaderViewModel: UploaderViewModel,
	): void => {
		batchObject.progress = parseInt(
			((100.0 * progressEvent.loaded) / progressEvent.total).toString(),
		);
		timerObject.sizeUploaded +=
			progressEvent.loaded - batchObject.previousUploadedSize;
		const currentTime = new Date().getTime();
		timerObject.avgSpeed = this.calculateSpeed(
			timerObject.sizeUploaded,
			currentTime - timerObject.zeroStartTime,
		);
		timerObject.formattedSpeed = calculateFileSize(timerObject.avgSpeed * 1000);
		timerObject.remainingTime = this.calculateEstimatedTime(
			timerObject.avgSpeed,
			timerObject.totalSize - timerObject.sizeUploaded,
		);
		timerObject.formattedRemainingTime = this.timeConversion(
			timerObject.remainingTime,
		);
		batchObject.previousUploadedSize = progressEvent.loaded;
		uploaderViewModel.updateUploaderModelSubject();
	};

	/**
	 * uploadNonDicomBatchOnServer
	 * @param uploadingObj
	 * @param nonDicomBatch
	 * @param index
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns promise
	 */
	uploadNonDicomBatchOnServer = (
		uploadingObj: Record<string, any>,
		nonDicomBatch: any,
		index: number,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		responseObject: Record<string, any>,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<any> => {
		return new Promise<any>((resolve, reject) => {
			nonDicomBatch.previousUploadedSize = 0;
			if (nonDicomBatch.itemList.length > 0) {
				this.setValueInNonDicom(nonDicomBatch, 'uploadTouched', true);
				this.setValueInNonDicom(nonDicomBatch, 'uploadInProgress', true);
				uploaderViewModel.updateUploaderModelSubject();
				const formData = this.createFormDataForNonDicomBatchUploading(
					uploadingObj,
					nonDicomBatch,
				);
				const newRef = cancelUploadMap.createRef();
				const config = {
					onUploadProgress: (progressEvent: ProgressEvent) =>
						this.handleProgressForBatch(
							progressEvent,
							timerObject,
							nonDicomBatch,
							uploaderViewModel,
						),
					cancelRef: newRef,
				};
				uploaderViewModel.docUploadAndSave(formData, config).subscribe(
					rp => {
						const responsePromise = this.handleSuccessForNonDicomBatchUpload(
							uploadingObj,
							nonDicomBatch,
							index,
							uploadBatchObject,
							counterObject,
							timerObject,
							rp,
							responseObject,
							uploaderViewModel,
							cancelUploadMap,
						);
						responsePromise.then(response => {
							resolve(rp);
						});
					},
					error => {
						const responsePromise = this.handleErrorForNonDicomBatchUpload(
							uploadingObj,
							nonDicomBatch,
							index,
							uploadBatchObject,
							counterObject,
							timerObject,
							error,
							responseObject,
							uploaderViewModel,
							cancelUploadMap,
						);
						responsePromise.then(function () {
							resolve(responseObject);
						});
					},
				);
			} else {
				console.log('No need to upload this batch as no item present in batch');
				const responsePromise = this.handleNotUploadingNonDicomBatch(
					uploadingObj,
					nonDicomBatch,
					index,
					uploadBatchObject,
					counterObject,
					timerObject,
					responseObject,
					uploaderViewModel,
					cancelUploadMap,
				);
				responsePromise.then(resp => {
					resolve(resp);
				});
			}
		});
	};

	/**
	 * selectAndUploadNewBatchToUpload
	 * @param uploadingObj
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns resolve
	 */
	selectAndUploadNewBatchToUpload = (
		uploadingObj: Record<string, any>,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		responseObject: any,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<void> => {
		return new Promise<void>((resolve, rejcet) => {
			const dicomBatchList = uploadBatchObject.dicomBatchList;
			const nonDicomBatchesList = uploadBatchObject.nonDicomBatchesList;
			const uploadedDicomBatchList = uploadBatchObject.uploadedDicomBatchList;
			const uploadedNonDicomBatchList =
				uploadBatchObject.uploadedNonDicomBatchList;
			const currentUploadingDicomBatchIndexList =
				uploadBatchObject.currentUploadingDicomBatchIndexList;
			const currentUploadingNonDicomBatchIndexList =
				uploadBatchObject.currentUploadingNonDicomBatchIndexList;
			const currentTotalUploadingBatchSize =
				currentUploadingDicomBatchIndexList.length +
				currentUploadingNonDicomBatchIndexList.length;
			if (currentTotalUploadingBatchSize < this.maxBatchConcurrentlyAllowed) {
				if (
					dicomBatchList &&
					dicomBatchList.length > 0 &&
					uploadedDicomBatchList.length +
						currentUploadingDicomBatchIndexList.length !==
						dicomBatchList.length
				) {
					const maxCurrentUploadingIndex = Math.max.apply(
						null,
						currentUploadingDicomBatchIndexList,
					);
					const lastUploadedIndex = Math.max.apply(
						null,
						uploadedDicomBatchList,
					);
					const nextIndexToUpload =
						Math.max(maxCurrentUploadingIndex, lastUploadedIndex) + 1;
					currentUploadingDicomBatchIndexList.push(nextIndexToUpload);
					if (
						dicomBatchList[nextIndexToUpload] &&
						!dicomBatchList[nextIndexToUpload].uploaded
					) {
						this.uploadDicomBatchToServer(
							uploadingObj,
							dicomBatchList[nextIndexToUpload],
							nextIndexToUpload,
							uploadBatchObject,
							counterObject,
							timerObject,
							responseObject,
							uploaderViewModel,
							cancelUploadMap,
						).subscribe(response => {
							resolve();
						});
					} else {
						resolve();
					}
				} else if (
					nonDicomBatchesList &&
					nonDicomBatchesList.length > 0 &&
					uploadedNonDicomBatchList.length +
						currentUploadingNonDicomBatchIndexList.length !==
						nonDicomBatchesList.length
				) {
					if (currentUploadingNonDicomBatchIndexList.length > 0) {
						const maxCurrentUploadingIndex = Math.max.apply(
							null,
							currentUploadingNonDicomBatchIndexList,
						);
						const lastUploadedIndex = Math.max.apply(
							null,
							uploadedNonDicomBatchList,
						);
						const nextIndexToUpload =
							Math.max(maxCurrentUploadingIndex, lastUploadedIndex) + 1;
						currentUploadingNonDicomBatchIndexList.push(nextIndexToUpload);
						if (
							nonDicomBatchesList[nextIndexToUpload] &&
							!nonDicomBatchesList[nextIndexToUpload].uploaded
						) {
							const responsePromise = this.uploadNonDicomBatchOnServer(
								uploadingObj,
								nonDicomBatchesList[nextIndexToUpload],
								nextIndexToUpload,
								uploadBatchObject,
								counterObject,
								timerObject,
								responseObject,
								uploaderViewModel,
								cancelUploadMap,
							);
							responsePromise.then(function () {
								resolve();
							});
						} else {
							resolve();
						}
					} else {
						if (nonDicomBatchesList[0] && !nonDicomBatchesList[0].uploaded) {
							currentUploadingNonDicomBatchIndexList.push(0);
							const responsePromise = this.uploadNonDicomBatchOnServer(
								uploadingObj,
								nonDicomBatchesList[0],
								0,
								uploadBatchObject,
								counterObject,
								timerObject,
								responseObject,
								uploaderViewModel,
								cancelUploadMap,
							);
							responsePromise.then(function () {
								resolve();
							});
						} else {
							resolve();
						}
					}
				} else {
					if (
						currentUploadingDicomBatchIndexList.length === 0 &&
						currentUploadingNonDicomBatchIndexList.length === 0
					) {
						const currentTime = new Date().getTime();
					}
					resolve();
				}
			} else {
				resolve();
			}
		});
	};

	/**
	 * handleSuccessForDicomBatchUpload
	 * @param uploadingObj
	 * @param dicomBatch
	 * @param index
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param response
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns promise
	 */
	handleSuccessForDicomBatchUpload = (
		uploadingObj: Record<string, any>,
		dicomBatch: any,
		index: number,
		uploadBatchObject: any,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		response: any,
		responseObject: any,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<void> => {
		return new Promise((resolve, reject) => {
			const currentUploadingDicomBatchIndexList =
				uploadBatchObject.currentUploadingDicomBatchIndexList;
			const uploadedDicomBatchList = uploadBatchObject.uploadedDicomBatchList;
			currentUploadingDicomBatchIndexList.splice(
				currentUploadingDicomBatchIndexList.indexOf(index),
				1,
			);
			if (response.documentListMap) {
				if (
					response.documentListMap.errorFileAliasList &&
					response.documentListMap.errorFileAliasList.length
				) {
					const studyIuid = dicomBatch.itemList[0].studyIuid;
					const studyObject = uploadingObj.listOfDicomFiles[studyIuid];
					studyObject.error = true;
					response.documentListMap.errorFileAliasList.forEach(
						(errorFileAlias: any) => {
							const indexString = errorFileAlias.substring(5);
							const index = Number(indexString);
							dicomBatch.itemList[index].error = true;
							responseObject.errorFileList.push(dicomBatch.itemList[index]);
						},
					);
				}
				if (response.documentListMap.invalidFileAliasList) {
					response.documentListMap.invalidFileAliasList.forEach(
						(invalidFileAlias: any) => {
							const indexString = invalidFileAlias.substring(5);
							const index = Number(indexString);
							dicomBatch.itemList[index].invalid = true;
							responseObject.invalidFileList.push(dicomBatch.itemList[index]);
						},
					);
				}
				if (response.documentListMap.successFileAliasList) {
					response.documentListMap.successFileAliasList.forEach(
						(successFileAlias: any) => {
							const indexString = successFileAlias.substring(5);
							const index = Number(indexString);
							dicomBatch.itemList[index].uploaded = true;
							dicomBatch.success = true;
							responseObject.successFileList.push(dicomBatch.itemList[index]);
						},
					);
					counterObject.uploaded +=
						response.documentListMap.successFileAliasList.length;
				}
			} else {
				responseObject.errorFileList = dicomBatch.itemList;
			}
			dicomBatch.uploaded = true;
			this.resetValueInStudy(
				uploadingObj,
				dicomBatch,
				uploadBatchObject,
				'uploadInProgress',
				false,
			);
			uploaderViewModel.updateUploaderModelSubject();
			uploadedDicomBatchList.push(index);
			const responsePromise = this.selectAndUploadNewBatchToUpload(
				uploadingObj,
				uploadBatchObject,
				counterObject,
				timerObject,
				responseObject,
				uploaderViewModel,
				cancelUploadMap,
			);
			responsePromise.then(function () {
				resolve();
			});
		});
	};

	/**
	 * handleErrorForDicomBatchUpload
	 * @param uploadingObj
	 * @param dicomBatch
	 * @param index
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param response
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns promise
	 */
	handleErrorForDicomBatchUpload = (
		uploadingObj: Record<string, any>,
		dicomBatch: Record<string, any>,
		index: number,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		response: any,
		responseObject: Record<string, any>,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<void> => {
		return new Promise<void>((resolve, reject) => {
			const currentUploadingDicomBatchIndexList =
				uploadBatchObject.currentUploadingDicomBatchIndexList;
			const uploadedDicomBatchList = uploadBatchObject.uploadedDicomBatchList;
			currentUploadingDicomBatchIndexList.splice(
				currentUploadingDicomBatchIndexList.indexOf(index),
				1,
			);
			dicomBatch.uploaded = true;
			dicomBatch.error = true;
			this.resetValueInStudy(
				uploadingObj,
				dicomBatch,
				uploadBatchObject,
				'uploadInProgress',
				false,
			);
			uploaderViewModel.updateUploaderModelSubject();
			uploadedDicomBatchList.push(index);
			const studyIuid = dicomBatch.itemList[0].studyIuid;
			const studyObject = uploadingObj.listOfDicomFiles[studyIuid];
			studyObject.error = true;
			dicomBatch.itemList.forEach((instance: any) => {
				instance['error'] = true;
			});
			responseObject.errorFileList = responseObject.errorFileList.concat(
				dicomBatch.itemList,
			);
			const responsePromise = this.selectAndUploadNewBatchToUpload(
				uploadingObj,
				uploadBatchObject,
				counterObject,
				timerObject,
				responseObject,
				uploaderViewModel,
				cancelUploadMap,
			);
			responsePromise.then(resp => {
				resolve();
			});
		});
	};

	/**
	 * handleNotUploadingDicomBatch
	 * @param uploadingObj
	 * @param dicomBatch
	 * @param index
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns promise
	 */
	handleNotUploadingDicomBatch = (
		uploadingObj: Record<string, any>,
		dicomBatch: Record<string, any>,
		index: number,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		responseObject: any,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<void> => {
		return new Promise<void>((resolve, reject) => {
			const currentUploadingDicomBatchIndexList =
				uploadBatchObject.currentUploadingDicomBatchIndexList;
			const uploadedDicomBatchList = uploadBatchObject.uploadedDicomBatchList;
			currentUploadingDicomBatchIndexList.splice(
				currentUploadingDicomBatchIndexList.indexOf(index),
				1,
			);
			dicomBatch.uploaded = true;
			uploadedDicomBatchList.push(index);
			const responsePromise = this.selectAndUploadNewBatchToUpload(
				uploadingObj,
				uploadBatchObject,
				counterObject,
				timerObject,
				responseObject,
				uploaderViewModel,
				cancelUploadMap,
			);
			responsePromise.then(function () {
				resolve();
			});
		});
	};

	/**
	 * uploadDicomBatchToServer
	 * @param uploadingObj
	 * @param dicomBatch
	 * @param index
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param responseObject
	 * @param uploaderViewModel
	 * @returns
	 */
	uploadDicomBatchToServer = (
		uploadingObj: Record<string, any>,
		dicomBatch: any,
		index: number,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		responseObject: Record<string, any>,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Observable<Record<string, any>> => {
		return new Observable((subscriber: Subscriber<Record<string, any>>) => {
			dicomBatch.previousUploadedSize = 0;
			if (dicomBatch.itemList.length > 0) {
				dicomBatch.uploadInProgress = true;
				this.setValueInStudy(uploadingObj, dicomBatch, 'uploadTouched', true);
				this.setValueInStudy(
					uploadingObj,
					dicomBatch,
					'uploadInProgress',
					true,
				);
				uploaderViewModel.updateUploaderModelSubject();
				console.time('upload a batch on server');

				const formData = this.createFormDataForDicomBatchUploading(
					uploadingObj,
					dicomBatch,
				);
				const dateTime = new Date();
				const newRef = cancelUploadMap.createRef();
				const config = {
					onUploadProgress: (progressEvent: ProgressEvent) =>
						this.handleProgressForBatch(
							progressEvent,
							timerObject,
							dicomBatch,
							uploaderViewModel,
						),
					cancelRef: newRef,
				};
				uploaderViewModel.docUploadAndSave(formData, config).subscribe(
					response => {
						const responsePromise = this.handleSuccessForDicomBatchUpload(
							uploadingObj,
							dicomBatch,
							index,
							uploadBatchObject,
							counterObject,
							timerObject,
							response,
							responseObject,
							uploaderViewModel,
							cancelUploadMap,
						);
						responsePromise.then(resp => {
							subscriber.next(response);
						});
					},
					error => {
						const responsePromise = this.handleErrorForDicomBatchUpload(
							uploadingObj,
							dicomBatch,
							index,
							uploadBatchObject,
							counterObject,
							timerObject,
							error,
							responseObject,
							uploaderViewModel,
							cancelUploadMap,
						);
						responsePromise.then(function (response) {
							subscriber.next(responseObject);
						});
					},
				);
			} else {
				console.log('No need to upload this batch as no item present in batch');
				const responsePromise = this.handleNotUploadingDicomBatch(
					uploadingObj,
					dicomBatch,
					index,
					uploadBatchObject,
					counterObject,
					timerObject,
					responseObject,
					uploaderViewModel,
					cancelUploadMap,
				);
				responsePromise.then(resp => {
					subscriber.next({});
				});
			}
		});
	};

	/**
	 * startUploadInBatch
	 * @param uploadingObj
	 * @param uploadBatchObject
	 * @param counterObject
	 * @param timerObject
	 * @param uploaderViewModel
	 * @param cancelUploadMap
	 * @returns promise
	 */
	startUploadInBatch = (
		uploadingObj: Record<string, any>,
		uploadBatchObject: Record<string, any>,
		counterObject: Record<string, any>,
		timerObject: Record<string, any>,
		uploaderViewModel: UploaderViewModel,
		cancelUploadMap: Record<string, any>,
	): Promise<any> => {
		const allPromise = new Promise<any>((resolve, reject) => {
			const responseObject = {
				successFileList: [],
				errorFileList: [],
				invalidFileList: [],
			};
			timerObject.zeroStartTime = new Date().getTime();
			const promiseList: Array<Promise<any>> = [];
			uploadBatchObject.time = new Date().getTime();
			const dicomBatchList = uploadBatchObject.dicomBatchList;
			const nonDicomBatchesList = uploadBatchObject.nonDicomBatchesList;
			const currentUploadingNonDicomBatchIndexList =
				uploadBatchObject.currentUploadingNonDicomBatchIndexList;
			const currentUploadingDicomBatchIndexList =
				uploadBatchObject.currentUploadingDicomBatchIndexList;
			let currentlyUploadingBatches = 0;

			if (dicomBatchList) {
				let i = 0;
				dicomBatchList.forEach((dicomBatch: Record<string, any>) => {
					if (
						currentlyUploadingBatches < this.maxBatchConcurrentlyAllowed &&
						!dicomBatch.uploaded &&
						dicomBatch.itemList.length > 0
					) {
						currentUploadingDicomBatchIndexList.push(i);
						const uploadDicomResponsePromise = this.uploadDicomBatchToServer(
							uploadingObj,
							dicomBatch,
							i,
							uploadBatchObject,
							counterObject,
							timerObject,
							responseObject,
							uploaderViewModel,
							cancelUploadMap,
						);
						promiseList.push(
							new Promise((resolve, reject) => {
								uploadDicomResponsePromise.subscribe(response => {
									resolve(response);
								});
								currentlyUploadingBatches++;
							}),
						);
					}
					i++;
				});
			}
			if (nonDicomBatchesList) {
				let i = 0;
				if (currentlyUploadingBatches < this.maxBatchConcurrentlyAllowed) {
					nonDicomBatchesList.forEach((nonDicomBatch: any) => {
						if (
							currentlyUploadingBatches < this.maxBatchConcurrentlyAllowed &&
							!nonDicomBatch.uploaded &&
							nonDicomBatch.itemList.length > 0
						) {
							currentUploadingNonDicomBatchIndexList.push(i);
							const uploadNonDicomResponsePromise =
								this.uploadNonDicomBatchOnServer(
									uploadingObj,
									nonDicomBatch,
									i,
									uploadBatchObject,
									counterObject,
									timerObject,
									responseObject,
									uploaderViewModel,
									cancelUploadMap,
								);
							promiseList.push(
								new Promise((resolve, reject) => {
									uploadNonDicomResponsePromise.then(function (response) {
										resolve(response);
									});
									currentlyUploadingBatches++;
								}),
							);
						}
						i++;
					});
				}
			}

			Promise.all(promiseList).then((allPromisesResult: any) => {
				//promiseList is list of all the promises created for all dicom and non dicom files, which is equal to number of files uploaded.
				//allPromiseResult is array of promise results for all the promises.
				//responeObject is Object which contains list of  successFileList,errorFileList,invalidFileList
				resolve(allPromisesResult);
			});
		});
		return allPromise;
	};
}

export default UploaderHelperService;
