import i18n, {TFunction} from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import memoize from 'lodash.memoize';
import GlobalObserversInstance from '../common/src/utils/observers/GlobalObservers';

export const fallbackLng = ['en'];

const languageDetectorOptions = {
	// order and from where user language should be detected
	order: ['cookie', 'localStorage', 'navigator'],

	// keys or params to lookup language from
	lookupCookie: 'locale',
	lookupLocalStorage: 'locale',

	// cache user language on
	caches: ['localStorage', 'cookie'],
	excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

	// only detect languages that are in the whitelist
	checkWhitelist: true,
};

/**
 * initializes i18n service
 */
export function initI18n(): Promise<TFunction> {
	const availableLanguages = global.ConfigurationHolder?.languagesList;
	const defaultLng = selectedLanguage();
	return i18n
		.use(HttpApi) // load translation using xhr -> see /public/locales. We will add locales in the next step
		.use(LanguageDetector) // detect user language
		.use(initReactI18next) // pass the i18n instance to react-i18next.
		.init({
			fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
			supportedLngs: availableLanguages,
			lng: defaultLng,
			detection: languageDetectorOptions,
			interpolation: {
				escapeValue: false,
			},
			backend: {
				loadPath:
					!process.env.NODE_ENV || process.env.NODE_ENV === 'development'
						? '/locales/{{lng}}/translation.json'
						: '/locales/{{lng}}/translation.json',
			},
		});
}

/**
 * This exports a function which is used to resolve strings from localization json files
 * @param key key for the value to be shown mentioned in main.json
 * @param param optional config object, format the string based on the object key-value provided
 */
const strings = memoize(
	(key, params = {}) => i18n.t(key, params), // function
	(key, params) => JSON.stringify([key, params]), // cache key resolver
);

/**
 * This return the language selected currently.
 * @returns returns the language which is currently set to i18n
 */
const selectedLanguage = (): string => {
	let language = localStorage.getItem('locale');
	return language ? language : global.ConfigurationHolder?.defaultLanguage;
};

/**
 * TODO @Aniket, Please add functionality to check whether the language is RTL or not.
 */
const isSelectedLanguageRtl = (): boolean => {
	return false;
};

/**
 * This method is used for changing language for i18n and started reading keys from selected language
 * @param language for the language option selected from the UI
 */
const changeLanguage = (language: string) => {
	const selectedLang = selectedLanguage();
	i18n.changeLanguage(language);
	document
		.getElementsByTagName('body')[0]
		.setAttribute('dir', i18n.dir(language));

	if (selectedLang !== language) {
		GlobalObserversInstance.languageChangeObserver.publish(language);
	}
};

const uses24HourClock = (): boolean => {
	return global.ConfigurationHolder?.dates?.is24HoursFormat;
};

export {
	i18n,
	strings,
	selectedLanguage,
	changeLanguage,
	uses24HourClock,
	isSelectedLanguageRtl,
};
