import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FieldType } from '../../types/FieldTypeEnum';
import ErrorText from '../components/ErrorText';
import HelpText from '../components/HelpText';
import Label from '../components/Label';

export default function CheckboxGroupComponent(props: any) {
	const [checked, setChecked] = useState<{ [key: string]: boolean }>({});

	useEffect(() => {
		const stateMap: Record<string, boolean> = {};
		props.fieldData?.options?.forEach((option: any) => {
			stateMap[option.value] =
				props.dynamicConstraints?.defaultValue?.[option.value] ?? false;
		});
		setChecked(props.field.value);
	}, [props.field.value]);

	const handleChange: any = (key: string) => {
		const updatedValue = { ...checked, [key]: !checked[key] };
		setChecked(updatedValue);
		props.field.onChange(updatedValue);
	};

	const labelId = props?.fieldData?.name;
	const { isViewOnlyMode, dynamicConstraints } = props;

	return (
		<div
			id={`div_checkbox_grp_${labelId}`}
			className={
				props.fieldData.attributeCssClassName
					? `form-item ${props.fieldData.attributeCssClassName}`
					: 'form-item'
			}>
			<div>
				<Label
					htmlFor={labelId}
					id={`checkbox_grp_label_${labelId}`}
					required={dynamicConstraints.required ?? false}
					content={props.fieldData?.label}
					configProvider={props.configProvider}
				/>
				<div
					className={`form-check-inline-outer ${
						props.fieldData?.attributeCssClassName?.search(
							'form-check-block',
						) !== -1
							? 'ml-0'
							: ''
					}`}
					id={labelId}>
					{props.fieldData?.options?.map((option: any, key: number) => {
						return (
							<React.Fragment key={key}>
								<div
									className={` form-check ${
										props.fieldData?.attributeCssClassName != null &&
										props.fieldData?.attributeCssClassName?.search(
											'form-check-block',
										) !== -1
											? 'form-check-block mb10'
											: 'form-check-inline'
									}`}>
									<input
										id={props.fieldData.name + '_' + option.value}
										className="form-check-input"
										aria-describedby={`error_${props.fieldData.name} ${props.fieldData.name}_helpText_${props.fieldData.name}`}
										type="checkbox"
										name={option.value}
										disabled={
											isViewOnlyMode === true
												? isViewOnlyMode
												: dynamicConstraints?.disabled ?? false
										}
										onBlur={props.field.onBlur}
										onClick={() => handleChange(option.value)}
										defaultChecked={
											props.dynamicConstraints?.defaultValue?.[option.value] ??
											false
										}
										checked={props?.field?.value?.[option?.value] ?? false}
									/>
									<Label
										id={`checkbox_option_${option.value}_${key}`}
										htmlFor={props.fieldData.name + '_' + option.value}
										content={option.label}
										className="form-check-label"
										configProvider={props.configProvider}
									/>
								</div>
							</React.Fragment>
						);
					})}
				</div>
				{_.get(props.errors, props.fieldData.name) == null && (
					<HelpText
						content={dynamicConstraints?.helpText}
						name={props.fieldData.name}
					/>
				)}
				<ErrorText
					errorObject={_.get(props.errors, props.fieldData.name)}
					label={props.fieldData.label}
					fieldType={FieldType.OPTION}
				/>
			</div>
		</div>
	);
}
