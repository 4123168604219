class PatientShowValidationModel {
    constructor(
        private _patientIDError: string = '',
        private _isError: boolean = false
    ) {}
    set patientIDError(value: string) {
        this._patientIDError = value
    }
    get patientIDError(): string {
        return this._patientIDError
    }
    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}
export default PatientShowValidationModel