import {NavigateOptions} from 'react-router-dom';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import encryptedStorageHelperInstance from '../../storage/EncryptedStorageHelper';
import {sessionStorageKeys} from '../../common/src/interface/storage/constants/SessionKeys';
import {Location} from 'history';
import UserService from '../../common/src/application/modules/user/service/User.service';
import Container from 'typedi';
export const angularAppRoutes = [
	'/tasks',
	'/dashboard',
	'/registerUser',
	'/registerUserSuccess/accountReady',
	'/listDashboards',
	'/listDashboards/:filter',
	'/records',
	'/myRecords',
	'/widgets',
	'/showAllRecords',
	'/manageLinkedUsers',
	'/users',
	'/userGroups',
	'/agreementsList',
	'/patientSearch',
	'/patientConsentList',
	'/appointmentsList',
	'/bills/list',
	'/communication/inbox',
	'/userGuide/listings',
	'/referral/list',
	'/careplanListing',
	'/appointments',
	'/triggerManager',
	'/task/taskTemplateList',
	'/careplanTemplateListing',
	'/physicians',
	'/newAppointmentSystemOperations/list',
	'/newAppointmentSystem/list',
	'/templates',
	'/activityList',
	'/createQuestionnaire',
	'/questionnaireGroupCreate',
	'/myHealth',
	'/test/list/',
	'/video/activeSessions',
	'/registerPhysicianUser',
	'/physicianUserList',
	'/masterData',
	'/locationList',
	'/healthcareServiceRegionList',
	'/scheduleList',
	'/deviceList',
	'/holiday/list',
	'/ecm',
	'/notificationTemplate/list',
	'/notificationManager/list',
	'/cyborg/careFacility/list',
	'/inbox/helpdesk/newQuery',
	'/sendQueueList',
	'/patientSignup',
	'/scheduleMeetings',
	'/practitionerReferral/list',
	'/integrateThirdPartyApplication/mainBanner',
	'/taskCenterListAll',
	'/careGroupList',
];
export const angularAppRouteForPatientBanner = [
	{
		route: ':patientId/referral/list',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: ':patientId/carePlan/list',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: ':patientId/serviceRequest/list',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: ':patientId/integrateThirdPartyApplication/mphrx',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: ':patientId/integrateThirdPartyApplication/navigationBanner',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: ':patientId/appointmentsList',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: ':patientId/referrals',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: ':patientId/questionnaireResponses',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: ':patientId/manageLinkedUsers',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: true,
	},
	{
		route: '/patient/:patientId/bookHomeServices',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
	{
		route: '/patient/:patientId/bookAppointment',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
	{
		route: '/patient/:patientId/consentRequest',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
	{
		route: '/scheduleMeeting/:patientId',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
	{
		route: '/exportPatientRecords/:patientId',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
	{
		route: '/assignCarePlan/:patientId',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
	{
		route: '/createReferralRequest/:patientId',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
	{
		route: '/sendQuestionnaire/:patientId',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
	{
		route: '/patient/orders/create/:patientId',
		removalOfPatientFromRoute: false,
		additionOfPatientToRoute: false,
	},
];
export const getRoutesForPatientBanner = (
	patientId: string | null,
): Array<string> => {
	let angularAppRoutes: Array<string> = [];
	if (patientId) {
		angularAppRoutes = angularAppRouteForPatientBanner.map(route => {
			return route?.route.replace(/:patientId/g, patientId);
		});
	}
	return angularAppRoutes;
};

const otherRoutes = [
	'/appointment/details/:appointmentId',
	'/patient/:patientId/appointment/:appointmentId/details',
	'/meetingDetails/:appointmentId',
	'encounter/:encounterId/dischargeSummary',
];

/**
 * This method is used for the redirection from react to angular
 * @param providedRoute : providedRoute actual routes without param values on which we want to redirect
 * @param navigate : navigate is a method which we use for internal navigation
 * @param location : location is the Location Object which we use to access current location
 * @param state : state is the data which we pass while internal navigation
 * @param finalUrl : finalUrl is the similar provided route but contains actual param values
 * @param isExternalRedirection : isExternalRedirection is used for choosing the redirection method
 * if true then we will use window.location.href for redirection else we will use navigate method
 */
export const customNavigate = (
	providedRoute: string,
	navigate: (routPath: string, state?: NavigateOptions) => void,
	location: Location,
	state?: NavigateOptions,
	finalUrl?: string,
	isExternalRedirection?: boolean,
) => {
	let isReactRoute = true;
	let patientId: string | null = encryptedStorageHelperInstance.getItemSync(
		sessionStorageKeys.PATIENT_ID,
	);
	const userService: UserService = Container.get(UserService);
	if (global.ConfigurationHolder.enableAngularTransition) {
		if (
			angularAppRoutes.indexOf(providedRoute) > -1 ||
			otherRoutes.indexOf(providedRoute) > -1
		) {
			isReactRoute = false;
			if (
				global.ConfigurationHolder.redirectToOldUrlAfterLogin &&
				location.pathname.indexOf(
					global.ConfigurationHolder.logout.redirectUrl,
				) < 0 &&
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.AUTH_TOKEN,
				)
			) {
				userService.setRedirectAfterLoginUrl(location.pathname);
			}
			if (finalUrl) {
				window.location.href =
					global.ConfigurationHolder?.urls?.angularApplicationFrontend +
					finalUrl;
			} else {
				window.location.href =
					global.ConfigurationHolder?.urls?.angularApplicationFrontend +
					providedRoute;
			}
		} else if (
			getRoutesForPatientBanner(patientId).indexOf(providedRoute) !== -1
		) {
			isReactRoute = false;
			if (
				global.ConfigurationHolder.redirectToOldUrlAfterLogin &&
				location.pathname.indexOf(
					global.ConfigurationHolder.logout.redirectUrl,
				) < 0 &&
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.AUTH_TOKEN,
				)
			) {
				userService.setRedirectAfterLoginUrl(location.pathname);
			}
			const routesForPatientBanner = getRoutesForPatientBanner(patientId);
			window.location.href =
				global.ConfigurationHolder.urls.angularApplicationFrontend +
				getUpdatedRouteForPatientBanner(
					patientId,
					routesForPatientBanner[routesForPatientBanner.indexOf(providedRoute)],
				);
		} else if (
			location.pathname.indexOf(RoutePathInfo.editUserProfile.path) !== -1
		) {
			isReactRoute = false;
			if (
				global.ConfigurationHolder.redirectToOldUrlAfterLogin &&
				location.pathname.indexOf(
					global.ConfigurationHolder.logout.redirectUrl,
				) < 0 &&
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.AUTH_TOKEN,
				)
			) {
				userService.setRedirectAfterLoginUrl(location.pathname);
			}
			if (location.search.indexOf('?tab=') === -1) {
				window.location.href =
					global.ConfigurationHolder.urls.angularApplicationFrontend +
					location.pathname;
			} else {
				isReactRoute = true;
			}
		}
	}
	if (isReactRoute) {
		if (isExternalRedirection) {
			if (finalUrl) {
				window.location.href = finalUrl;
			} else {
				window.location.href = providedRoute;
			}
		} else {
			if (state) {
				if (finalUrl) {
					navigate(finalUrl, state);
				} else {
					navigate(providedRoute, state);
				}
			} else {
				if (finalUrl) {
					navigate(finalUrl);
				} else {
					navigate(providedRoute);
				}
			}
		}
	}
};

export const getUpdatedRouteForPatientBanner = (
	patientId: string | null,
	pathname: string,
): string => {
	let finalPathName: string = '';
	if (patientId) {
		angularAppRouteForPatientBanner.forEach(route => {
			const currentRoutePath = route?.route.replace(/:patientId/g, patientId);
			if (currentRoutePath === pathname && route.removalOfPatientFromRoute) {
				finalPathName = currentRoutePath.replace(/\/patient\//g, '/');
			} else if (
				currentRoutePath === pathname &&
				route.additionOfPatientToRoute
			) {
				finalPathName = '/patient/' + pathname;
			} else if (currentRoutePath === pathname) {
				finalPathName = currentRoutePath;
			}
		});
	}
	return finalPathName;
};

export const isRouteToBeExcluded = (path: string): boolean => {
	let isRouteExcluded = false;
	if (path.match(new RegExp(/\/patient\/recordDetails\/*/g))) {
		isRouteExcluded = true;
	}
	return isRouteExcluded;
};
