import BaseRequestModel from '../../../network/interface/BaseRequestModel';

export default class SetAgreementStatusRequestModel
	implements BaseRequestModel
{
	private readonly _readDate?: string;
	private readonly _status: AgreementStatusEnum;
	private readonly _agreementId: number;

	constructor({readDate, status, agreementId}: SetAgreementStatusModel) {
		this._readDate = readDate;
		this._status = status;
		this._agreementId = agreementId;
	}

	/**
	 * This method is used to get the request model for moManageAgreements/setAgreementAsRead
	 */
	getRequestDataObject(): SetAgreementStatusModel {
		return {
			readDate: this._readDate,
			status: this._status,
			agreementId: this._agreementId,
		};
	}
}

export type SetAgreementStatusModel = {
	readDate?: string;
	status: AgreementStatusEnum;
	agreementId: number;
};

export enum AgreementStatusEnum {
	ACCEPTED = 'ACCEPTED',
	REJECTED = 'REJECTED',
}
