import BaseResponseModel from '../../../../network/interface/BaseResponseModel';

class UploaderConfigResponseModel implements BaseResponseModel {
	private readonly defaultConfig?: DefaultConfig;
	private readonly xds?: XDS;
	private readonly documentType?: string[];
	private readonly singleFileUpload?: boolean;
	private readonly defaultDocumentTypeKey?: string;
	private readonly documentTypeLocationCategoryMap?: Record<string, any>;
	private readonly locationTypeCategories?: Record<string, any>;
	private readonly uploaderTypeMapWithUserType?: UploadertypeMapWithUserType;
	private readonly bulkEditEnabled?: boolean;
	private readonly bulkEditOptionViewMap?: BulkEditOptionViewMap;
	private readonly showUploaderUsabilityHint?: boolean;
	private readonly documentEditOnUploadEnabled?: boolean;
	private readonly editableFieldsOnDocumentUpload?: Record<string, boolean>;
	private readonly currentInputType?: string;
	private readonly invalidFileNameCounter?: number;
	private readonly allowedFileTypeObjects?: Record<string, any>;
	private readonly fileTypeList?: Array<any>;
	private readonly documentTypeList?: Array<string>;

	constructor({
		defaultConfig,
		xds,
		documentType,
		singleFileUpload,
		defaultDocumentTypeKey,
		documentTypeLocationCategoryMap,
		locationTypeCategories,
		uploaderTypeMapWithUserType,
		bulkEditEnabled,
		bulkEditOptionViewMap,
		showUploaderUsabilityHint,
		documentEditOnUploadEnabled,
		editableFieldsOnDocumentUpload,
		currentInputType,
		invalidFileNameCounter,
		allowedFileTypeObjects,
		fileTypeList,
	}: UploaderConfigResponse) {
		this.defaultConfig = defaultConfig;
		this.xds = xds;
		this.documentType = documentType;
		this.singleFileUpload = singleFileUpload;
		this.defaultDocumentTypeKey = defaultDocumentTypeKey;
		this.documentTypeLocationCategoryMap = documentTypeLocationCategoryMap;
		this.locationTypeCategories = locationTypeCategories;
		this.uploaderTypeMapWithUserType = uploaderTypeMapWithUserType;
		this.bulkEditEnabled = bulkEditEnabled;
		this.bulkEditOptionViewMap = bulkEditOptionViewMap;
		this.showUploaderUsabilityHint = showUploaderUsabilityHint;
		this.documentEditOnUploadEnabled = documentEditOnUploadEnabled;
		this.editableFieldsOnDocumentUpload = editableFieldsOnDocumentUpload;
		this.currentInputType = currentInputType;
		this.invalidFileNameCounter = invalidFileNameCounter;
		this.allowedFileTypeObjects = allowedFileTypeObjects;
		this.fileTypeList = fileTypeList;
	}

	/**
	 * returns uploader config response
	 * @returns {UploaderConfigResponse}
	 */
	getResponseDataObject(): UploaderConfigResponse {
		return {
			defaultConfig: this.defaultConfig,
			xds: this.xds,
			documentType: this.documentType,
			singleFileUpload: this.singleFileUpload,
			defaultDocumentTypeKey: this.defaultDocumentTypeKey,
			documentTypeLocationCategoryMap: this.documentTypeLocationCategoryMap,
			locationTypeCategories: this.locationTypeCategories,
			uploaderTypeMapWithUserType: this.uploaderTypeMapWithUserType,
			bulkEditEnabled: this.bulkEditEnabled,
			bulkEditOptionViewMap: this.bulkEditOptionViewMap,
			showUploaderUsabilityHint: this.showUploaderUsabilityHint,
			documentEditOnUploadEnabled: this.documentEditOnUploadEnabled,
			editableFieldsOnDocumentUpload: this.editableFieldsOnDocumentUpload,
			currentInputType: this.currentInputType,
			invalidFileNameCounter: this.invalidFileNameCounter,
			allowedFileTypeObjects: this.allowedFileTypeObjects,
			fileTypeList: this.fileTypeList,
		};
	}
}

export type UploaderConfigResponse = {
	defaultConfig?: DefaultConfig;
	xds?: XDS;
	documentType?: string[];
	singleFileUpload?: boolean;
	defaultDocumentTypeKey?: string;
	documentTypeLocationCategoryMap?: Record<string, any>;
	locationTypeCategories?: Record<string, any>;
	uploaderTypeMapWithUserType?: UploadertypeMapWithUserType;
	bulkEditEnabled?: boolean;
	bulkEditOptionViewMap?: BulkEditOptionViewMap;
	showUploaderUsabilityHint?: boolean;
	documentEditOnUploadEnabled?: boolean;
	editableFieldsOnDocumentUpload?: Record<string, boolean>;
	fileTypeList?: Array<any>;
	currentInputType?: string;
	allowedFileTypeObjects?: Record<string, any>;
	invalidFileNameCounter?: number;
};

export type allowedExtensions = {
	nonDicom?: string[];
	dicom?: string[];
};
export type DefaultConfig = {
	allowedServiceExtensions?: string[];
	allowedBulkuploaderExtensions?: string[];
	cleanUpJobDayOfRunning?: number[];
	cleanUpJobHourOfRunning?: number[];
	maxNoOfThreadUsedSending?: string;
	maxNoOfThreadUsedForMorphing?: string;
	batchSizeForSending?: string;
	connectionTimeout?: string;
	welcomeText?: string;
	emails?: string;
	moveFileFromMorphToCacheAfterHours?: string;
	allowDeleteOnCloseNStart?: string;
	sendMailForBulkUploaderServiceFromClient?: string;
	allowedUploadFileExtensions?: allowedExtensions;
	allowedUploadFolderExtensions?: allowedExtensions;
	allowedDropzoneExtensions?: allowedExtensions;
	defaultExtensions?: allowedExtensions;
	mimeTypeFileExtensionMapping?: Record<string, any>;
	confirmButtonSwitch?: string;
	showConfirmButton?: string;
	linkStudySwitch?: string;
	showTimer?: string;
	cancelScanning?: boolean;
	autoUpload?: boolean;
	DicomUIDataMatchField?: string;
	highUploadDicom?: string;
	showCommentBox?: boolean;
};

export type XDS = {
	enabled?: boolean;
};

export type UploadertypeMapWithUserType = {
	PATIENT?: string;
	PRACTITIONER?: string;
	OTHERS?: string;
	GUEST?: string;
};

export type BulkEditOptionViewMap = {
	documentType?: boolean;
	patientCondition?: boolean;
	patientEncounter?: boolean;
	location?: boolean;
	createdDate?: boolean;
	physician?: boolean;
};

export default UploaderConfigResponseModel;
