import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import UserService from './common/src/application/modules/user/service/User.service';
import Container from 'typedi';
import {
	AppContextInterface,
	GlobalStateContext,
} from './common/src/utils/context/GlobalStateContext';
import RoutePathInfo from './common/src/enums/RoutingPath';

const PrivateRoute = (props: any) => {
	const [authenticated, setAuthenticated] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const accessTo: Array<string> = props.accessTo;

	const {user} = useContext<AppContextInterface>(GlobalStateContext);

	const navigator = useNavigate();

	useEffect(() => {
		var userService: UserService;
		userService = Container.get(UserService);

		userService.isAuthenticated().then(res => {
			if (res && user?.userType && accessTo.indexOf(user.userType) === -1) {
				navigator(RoutePathInfo.notAccessible.path);
			} else {
				setAuthenticated(res);
				setIsLoading(false);
			}
		});
	}, [user]);

	return (
		<>
			{!isLoading &&
				(authenticated === true ? (
					<Outlet />
				) : (
					<Navigate
						to={global.ConfigurationHolder.defaultLandingPage}
						replace
					/>
				))}
		</>
	);
};

export default PrivateRoute;
