import {
	AgreementApprovalCriteriaListModel,
	AgreementsModel,
	ContentResultModel,
} from '../../user/models/UserModel';
import {selectedLanguage} from '../../../../../../localization/i18n';

export default class Agreement {
	private readonly _agreementId: number | undefined;
	private readonly _contentResult: Array<ContentResultModel> | undefined;
	private readonly _lastUpdated: string | undefined;
	private _readDate: string | undefined;

	constructor({
		agreementId,
		contentResult,
		lastUpdated,
		readDate,
	}: AgreementsModel) {
		this._agreementId = agreementId;
		this._contentResult = contentResult;
		this._lastUpdated = lastUpdated;
		this._readDate = readDate;
	}

	get agreementId(): number | undefined {
		return this._agreementId;
	}

	get contentResult(): Array<ContentResultModel> | undefined {
		return this._contentResult;
	}

	get localizedContentToRender(): ContentResultModel | undefined {
		const list = this._contentResult?.filter(result => {
			return result.language === selectedLanguage();
		});
		if (list != null && list.length > 0) {
			return list[0];
		}
		return undefined;
	}

	get lastUpdated(): string | undefined {
		return this._lastUpdated;
	}

	get readDate(): string | undefined {
		return this._readDate;
	}

	public updateReadDate(value: string | undefined) {
		this._readDate = value;
	}

	public updateApprovalCriteriaChecked(
		toUpdate: boolean,
		name: string | undefined,
	): void {
		this.localizedContentToRender?.agreementApprovalCriteriaList?.forEach(
			(value: AgreementApprovalCriteriaListModel) => {
				if (name === value.name) {
					value.isChecked = toUpdate;
				}
			},
		);
	}

	/**
	 * This method is used to validate an agreement model before API is called to accept the given agreement.
	 * @param agreementToValidate
	 */
	static validateAgreementForAcceptance(
		agreementToValidate: Agreement,
	): boolean {
		let isValid = true;
		if (
			agreementToValidate.localizedContentToRender
				?.agreementApprovalCriteriaList != null
		) {
			agreementToValidate.localizedContentToRender?.agreementApprovalCriteriaList.forEach(
				criteria => {
					if (criteria.isChecked !== true) {
						isValid = false;
					}
				},
			);
		}
		return isValid;
	}
}
