import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Loader from '../ui/comps/src/Loader';
import RoutePathInfo from '../common/src/enums/RoutingPath';
import FormBuilderComponent from './formbuilder';
import PrivateRoute from '../PrivateRoute';

const AttributeComponent = lazy(
	() => import('./components/attribute/Attribute.component'),
);
const LayoutComponent = lazy(
	() => import('./components/layout/layout.component'),
);
const CreateAttributeComponenet = lazy(
	() => import('./components/attribute/createAttribute'),
);
const CreateLayoutComponenet = lazy(
	() => import('./components/layout/create.layout'),
);
const SectionComponent = lazy(
	() => import('./components/section/Section.component'),
);
const CreateSectionComponent = lazy(
	() => import('./components/section/createSection'),
);
const RulesComponent = lazy(
	() => import('./components/formRules/rules.component'),
);

const CreateRuleComponent = lazy(() => import('./components/formRules/create.rule'))

const TestComponent = lazy(() => import('./components/test.component'));

const accessTo = ["OTHERS"]

const FormBuilderRoutes = (
    
    <>

        <Route path="/" element={<PrivateRoute accessTo={accessTo} />}>
            <Route path={RoutePathInfo.formBuilder.path} element={<FormBuilderComponent/>}>
                <Route
                index
                    element={
                        <Suspense fallback={<Loader />}>
                            <AttributeComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="attributes"
                    element={
                        <Suspense fallback={<Loader />}>
                            <AttributeComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="sections"
                    element={
                        <Suspense fallback={<Loader />}>
                            <SectionComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="layouts"
                    element={
                        <Suspense fallback={<Loader />}>
                            <LayoutComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="createAttribute"
                    element={
                        <Suspense fallback={<Loader />}>
                            <CreateAttributeComponenet />
                        </Suspense>
                    }
                />
                <Route
                    path="editAttribute"
                    element={
                        <Suspense fallback={<Loader />}>
                            <CreateAttributeComponenet />
                        </Suspense>
                    }
                />
                <Route
                    path="createSection"
                    element={
                        <Suspense fallback={<Loader />}>
                            <CreateSectionComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="editSection"
                    element={
                        <Suspense fallback={<Loader />}>
                            <CreateSectionComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="createLayout"
                    element={
                        <Suspense fallback={<Loader />}>
                            <CreateLayoutComponenet />
                        </Suspense>
                    }
                />
                <Route
                    path="editLayout"
                    element={
                        <Suspense fallback={<Loader />}>
                            <CreateLayoutComponenet />
                        </Suspense>
                    }
                />
                <Route
                    path="rules"
                    element={
                        <Suspense fallback={<Loader />}>
                            <RulesComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="createRule"
                    element={
                        <Suspense fallback={<Loader />}>
                            <CreateRuleComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="editRule"
                    element={
                        <Suspense fallback={<Loader />}>
                            <CreateRuleComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="test"
                    element={
                        <Suspense fallback={<Loader />}>
                            <TestComponent />
                        </Suspense>
                    }
                />
                <Route
                    path="*"
                    element={
                        <Suspense fallback={<Loader />}>
                            <AttributeComponent />
                        </Suspense>
                    }
                />

            </Route>
        </Route>
    
    </>
)

export default FormBuilderRoutes;