/**
 * This enumeration is used to distinguish between different types of network request method
 */
enum NetworkRequestMethodEnum {
	POST = 'post',
	GET = 'get',
	PUT = 'put',
	DELETE = 'delete',
}

export default NetworkRequestMethodEnum;
