import _ from 'lodash';
import {useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {Control, useFormContext} from 'react-hook-form';
import Container from 'typedi';
import {DropdownOption} from '../../common/src/application/dynamicFormUtil/types/DynamicForm.types';
import {
	LoaderProps,
	LoaderType,
} from '../../common/src/application/modules/generic/models/Loader';
import {NotificationMessage} from '../../common/src/application/modules/generic/models/NotificationMessage';
import AddressViewModel from '../../common/src/application/modules/generic/ui/comps/address/viewmodel/Address.viewmodel';
import {isValueAvailable} from '../../common/src/utils/StringUtils';
import {strings} from '../../localization/i18n';
import SelectComponent from '../../ui/comps/src/dynamic-form/form-components/DropDown.component';
import InputComponent from '../../ui/comps/src/dynamic-form/form-components/Input.component';
import Loader from '../../ui/comps/src/Loader';

type AddressState = {
	countries?: Array<DropdownOption>;
	states?: Array<DropdownOption>;
	cities?: Array<DropdownOption>;
	counties?: Array<DropdownOption>;
	line1?: string;
	line2?: string;
	line3?: string;
	country?: string;
	state?: string;
	city?: string | null;
	county?: string;
	zipcode?: string;
};

const defaultState: AddressState = {
	countries: undefined,
	states: undefined,
	cities: undefined,
	counties: undefined,
	zipcode: undefined,
};

enum AddressStateActions {
	ACTION_SET_COUNTRIES,
	ACTION_SET_COUNTRY,
	ACTION_SET_STATES,
	ACTION_SET_STATE,
	ACTION_SET_CITIES,
	ACTION_SET_CITY,
	ACTION_SET_COUNTIES,
	ACTION_SET_COUNTY,
	ACTION_SET_LINE1,
	ACTION_SET_LINE2,
	ACTION_SET_LINE3,
	ACTION_SET_ZIPCODE,
}

function validateZipcode(zipcode: string, constrains: any): boolean {
	let pattern = '';
	if (constrains.maxLength?.value) {
		pattern = `^.{1,${constrains.maxLength?.value}}$`;
		if (constrains.minLength?.value) {
			pattern = `^.{${constrains.minLength?.value},${constrains.maxLength?.value}}$`;
		}
	}
	return isValueAvailable(pattern)
		? RegExp(pattern).test(zipcode)
			? constrains.pattern?.value != null
				? RegExp(constrains.pattern?.value).test(zipcode)
				: true
			: false
		: false;
}

const AddressDropdown = ({
	control,
	dropdownProps,
	configProvider,
	name,
	register,
	dynamicConstraints,
	allDynamicConstraints,
	isViewOnlyMode,
}: any) => {
	const addressContainer = Container.of(`Address_${name}`);
	const viewModel = addressContainer.get(AddressViewModel);
	const {
		getValues,
		setValue,
		formState: {errors},
	} = useFormContext();

	const [loader, setLoader] = useState<LoaderProps>({
		type: LoaderType.OverScreen,
		isToShowLoader: false,
	});

	const [addressError, setAddressError] = useState<Record<string, any>>();

	const calledZipcode = useRef<number>(0);

	function reducer(
		state: AddressState,
		action: {
			type: AddressStateActions;
			payload?: AddressState;
		},
	): AddressState {
		const {type, payload} = action;
		switch (type) {
			case AddressStateActions.ACTION_SET_COUNTRIES:
				if (payload && payload.countries) {
					return {
						...state,
						countries: payload.countries,
						country: undefined,
						states: [],
						state: undefined,
						cities: [],
						city: null,
					};
				}
				break;

			case AddressStateActions.ACTION_SET_COUNTRY:
				if (payload && payload.country) {
					return {
						...state,
						country: payload.country,
					};
				}
				break;

			case AddressStateActions.ACTION_SET_STATES:
				if (payload && payload.states) {
					return {
						...state,
						states: payload.states,
						state: undefined,
						cities: [],
						city: null,
					};
				}
				break;

			case AddressStateActions.ACTION_SET_STATE:
				if (payload && payload.state) {
					return {
						...state,
						state: payload.state,
					};
				}
				break;

			case AddressStateActions.ACTION_SET_CITIES:
				if (payload && payload.cities) {
					return {
						...state,
						cities: payload.cities,
					};
				}
				break;
			case AddressStateActions.ACTION_SET_CITY:
				if (payload && payload.city) {
					return {
						...state,
						city: payload.city,
					};
				}
				break;
			case AddressStateActions.ACTION_SET_COUNTIES:
				if (payload && payload.counties) {
					return {
						...state,
						counties: payload.counties,
					};
				}
				break;
			case AddressStateActions.ACTION_SET_COUNTY:
				if (payload && payload.county) {
					return {
						...state,
						county: payload.county,
					};
				}
				break;
			case AddressStateActions.ACTION_SET_LINE1:
				if (payload && payload.line1) {
					return {
						...state,
						line1: payload.line1,
					};
				}
				break;
			case AddressStateActions.ACTION_SET_LINE2:
				if (payload && payload.line2) {
					return {
						...state,
						line2: payload.line2,
					};
				}
				break;
			case AddressStateActions.ACTION_SET_LINE3:
				if (payload && payload.line3) {
					return {
						...state,
						line3: payload.line3,
					};
				}
				break;
			case AddressStateActions.ACTION_SET_ZIPCODE:
				if (payload && payload.zipcode) {
					return {
						...state,
						zipcode: payload.zipcode,
					};
				}
		}

		return state;
	}

	const [state, dispatch] = useReducer(reducer, defaultState);

	useEffect(() => {
		let defaultValueObject: any | null = {
			...(allDynamicConstraints?.[dropdownProps?.country?.name]?.defaultValue !=
				null && {
				country: allDynamicConstraints[dropdownProps.country.name].defaultValue,
			}),
			...(allDynamicConstraints?.[dropdownProps?.state?.name]?.defaultValue !=
				null && {
				state: allDynamicConstraints[dropdownProps.state.name].defaultValue,
			}),
			...(allDynamicConstraints?.[dropdownProps?.city?.name]?.defaultValue !=
				null && {
				city: allDynamicConstraints[dropdownProps.city.name].defaultValue,
			}),
			...(allDynamicConstraints?.[dropdownProps?.zipcode?.name]?.defaultValue !=
				null && {
				zipcode: allDynamicConstraints[dropdownProps.zipcode.name].defaultValue,
			}),
		};

		if (Object.keys(defaultValueObject).length === 0) {
			defaultValueObject = null;
		}
		viewModel.initialize(
			defaultValueObject,
			dropdownProps?.zipcode?.isToCallApi ?? false,
		);

		const countriesSubscription = viewModel.getCountriesObservable().subscribe({
			next(data: Array<DropdownOption>) {
				dispatch({
					type: AddressStateActions.ACTION_SET_COUNTRIES,
					payload: {countries: data},
				});
			},
		});

		const statesSubscription = viewModel.getStatesObservable().subscribe({
			next(data: Array<DropdownOption>) {
				if (dropdownProps?.state?.name != null) {
					if (data.length === 1) {
						setValue(dropdownProps.state.name, data[0].value);
					} else if (calledZipcode.current > 1) {
						setValue(dropdownProps.state.name, null);
					}
					dispatch({
						type: AddressStateActions.ACTION_SET_STATES,
						payload: {states: data},
					});
				}
			},
		});

		const citiesSubscription = viewModel.getCitiesObservable().subscribe({
			next: (data: Array<DropdownOption>) => {
				if (dropdownProps?.city?.name != null) {
					if (data.length === 1) {
						setValue(dropdownProps.city.name, data[0].value);
					} else if (calledZipcode.current > 1) {
						setValue(dropdownProps.city.name, null);
					}
					dispatch({
						type: AddressStateActions.ACTION_SET_CITIES,
						payload: {cities: data},
					});
				}
			},
		});

		const loaderSubscription = viewModel.fetchLoaderObservable().subscribe({
			next(loader: LoaderProps) {
				setLoader(() => ({...loader}));
			},
		});

		const countiesSubscription = viewModel.getCountiesObservable().subscribe({
			next(data: Array<DropdownOption>) {
				setAddressError({});
				if (dropdownProps?.county?.name != null) {
					if (data.length === 1) {
						setValue(dropdownProps.county.name, data[0].value);
						dispatch({
							type: AddressStateActions.ACTION_SET_COUNTY,
							payload: {county: data[0].label},
						});
					} else if (calledZipcode.current > 1) {
						setValue(dropdownProps.county.name, null);
					}
					dispatch({
						type: AddressStateActions.ACTION_SET_COUNTIES,
						payload: {counties: data},
					});
				}
			},
		});

		const notificationSubscription = viewModel
			.fetchNotificationMessageObservable()
			.subscribe({
				next(notificationMessage: NotificationMessage) {
					setAddressError(notificationMessage);
				},
			});

		return function cleanup() {
			countriesSubscription.unsubscribe();
			statesSubscription.unsubscribe();
			citiesSubscription.unsubscribe();
			loaderSubscription.unsubscribe();
			countiesSubscription.unsubscribe();
			notificationSubscription.unsubscribe();
			// Container.reset(`AddressDropdown_${name}`);
		};
	}, [
		dropdownProps?.city?.defaultValue,
		dropdownProps?.city?.name,
		dropdownProps?.country?.defaultValue,
		dropdownProps?.country?.name,
		dropdownProps?.county?.name,
		dropdownProps?.state?.name,
		dropdownProps?.state?.defaultValue,
		dropdownProps?.zipcode?.isToCallApi,
		dropdownProps?.zipcode?.name,
		viewModel,
	]);

	const handleZipcodeBlur = () => {
		if (
			dropdownProps.zipcode != null &&
			dropdownProps.zipcode.isToCallApi &&
			dropdownProps.zipcode.name != null
		) {
			const zipCodeValue = state.zipcode
				? state.zipcode
				: getValues()[dropdownProps.zipcode.name];
			const isValid = validateZipcode(
				zipCodeValue,
				dropdownProps.zipcode.constraints,
			);

			if (isValid) {
				calledZipcode.current = calledZipcode.current + 1;
				viewModel.fetchCounties(zipCodeValue);
			}
		}
	};

	useEffect(() => {
		if (addressError?.message) {
			errors[dropdownProps.zipcode.name] = {
				message: addressError.message,
			};
		} else if (isValueAvailable(errors[dropdownProps?.zipcode?.name])) {
			delete errors[dropdownProps.zipcode.name];
		}
	}, [
		dropdownProps?.zipcode?.name,
		errors,
		addressError,
		addressError?.message,
	]);

	const zipCodeValue = useMemo(
		() => getValues()[dropdownProps.zipcode?.name ?? ''],
		[getValues()[dropdownProps.zipcode?.name]],
	);

	useEffect(() => {
		if (zipCodeValue && zipCodeValue !== state.zipcode) {
			handleZipcodeBlur();
		}
	}, [state.zipcode, dropdownProps.zipcode?.name, zipCodeValue]);

	return (
		<>
			{dynamicConstraints.visible && (
				<>
					{loader.isToShowLoader && <Loader />}
					{/* line 1 */}
					{isValueAvailable(dropdownProps.line1) && (
						<InputComponent
							register={register}
							control={control as Control<any, object>}
							errors={errors}
							fieldData={{
								label: dropdownProps.line1?.label
									? strings(dropdownProps.line1.label)
									: 'Line1',
								name: dropdownProps.line1.name,
								type: 'text',
								constraints: dropdownProps.line1.constraints,
							}}
							dynamicConstraints={
								allDynamicConstraints?.[dropdownProps?.line1?.name]
							}
							configProvider={configProvider}
							isViewOnlyMode={isViewOnlyMode}
						/>
					)}

					{/* line 2 */}
					{isValueAvailable(dropdownProps.line2) && (
						<InputComponent
							register={register}
							control={control as Control<any, object>}
							errors={errors}
							fieldData={{
								label: dropdownProps.line2?.label
									? strings(dropdownProps.line2.label)
									: 'Line2',
								name: dropdownProps.line2.name,
								type: 'text',
								constraints: dropdownProps.line2.constraints,
							}}
							dynamicConstraints={
								allDynamicConstraints?.[dropdownProps?.line2?.name]
							}
							configProvider={configProvider}
							isViewOnlyMode={isViewOnlyMode}
						/>
					)}

					{/* line 3 */}
					{isValueAvailable(dropdownProps.line3) && (
						<InputComponent
							register={register}
							control={control as Control<any, object>}
							errors={errors}
							fieldData={{
								label: dropdownProps.line3?.label
									? strings(dropdownProps.line3.label)
									: 'Line3',
								name: dropdownProps.line3.name,
								type: 'text',
								constraints: dropdownProps.line3.constraints,
							}}
							dynamicConstraints={
								allDynamicConstraints?.[dropdownProps?.line3?.name]
							}
							configProvider={configProvider}
							isViewOnlyMode={isViewOnlyMode}
						/>
					)}

					{/* zip code */}
					{isValueAvailable(dropdownProps.zipcode) &&
						allDynamicConstraints?.[dropdownProps.zipcode.name]?.visible && (
							<InputComponent
								register={register}
								control={control as Control<any, object>}
								errors={errors}
								configProvider={configProvider}
								isViewOnlyMode={isViewOnlyMode}
								fieldData={{
									label: dropdownProps.zipcode?.label
										? strings(dropdownProps.zipcode.label)
										: 'zipcode',
									name: dropdownProps.zipcode.name,
									type: 'text',
								}}
								dynamicConstraints={
									allDynamicConstraints?.[dropdownProps?.zipcode?.name]
								}
								callbackHandler={{
									onBlur: () => handleZipcodeBlur(),
									onChange: (event: any) => {
										dispatch({
											type: AddressStateActions.ACTION_SET_ZIPCODE,
											payload: {zipcode: event?.target?.value},
										});
									},
								}}
								constraints={dropdownProps.zipcode.constraints}
							/>
						)}

					{/* county */}
					{isValueAvailable(dropdownProps.county) && (
						<SelectComponent
							register={register}
							control={control as Control<any, object>}
							errors={errors}
							configProvider={configProvider}
							isViewOnlyMode={isViewOnlyMode}
							fieldData={{
								label: dropdownProps.county?.label
									? strings(dropdownProps.county.label)
									: 'county',
								name: dropdownProps.county.name,
								type: 'text',
								options: state.counties ?? ([] as any),
							}}
							constraints={dropdownProps.county.constraints}
							dynamicConstraints={
								allDynamicConstraints?.[dropdownProps?.county?.name]
							}
							callbackHandler={{
								onChange: (_selectedItems: string[] | string | null) => {
									if (typeof _selectedItems === 'string') {
										dispatch({
											type: AddressStateActions.ACTION_SET_COUNTY,
											payload: {county: _selectedItems},
										});
									}
								},
							}}
						/>
					)}

					{/* country */}
					{isValueAvailable(dropdownProps.country) && (
						<SelectComponent
							callbackHandler={{
								onChange: (_selectedItems: string | null) => {
									if (typeof _selectedItems === 'string') {
										viewModel.fetchStates(_selectedItems);
										dispatch({
											type: AddressStateActions.ACTION_SET_COUNTRY,
											payload: {country: _selectedItems},
										});
										if (dropdownProps?.state?.name != null) {
											setValue(dropdownProps.state.name ?? '', undefined);
										}
										if (dropdownProps?.city?.name != null) {
											setValue(dropdownProps.city.name, null);
										}
									}
								},
							}}
							control={control as Control<any, object>}
							errors={errors}
							fieldData={{
								...dropdownProps.country,
								options: state.countries ?? ([] as any),
							}}
							dynamicConstraints={
								allDynamicConstraints?.[dropdownProps?.country?.name]
							}
							configProvider={configProvider}
							isViewOnlyMode={isViewOnlyMode}
						/>
					)}

					{/* state */}
					{isValueAvailable(dropdownProps.state) && (
						<SelectComponent
							callbackHandler={{
								onChange: (_selectedItems: string | null) => {
									if (typeof _selectedItems === 'string') {
										dispatch({
											type: AddressStateActions.ACTION_SET_STATE,
											payload: {state: _selectedItems},
										});
										if (
											dropdownProps.zipcode == null ||
											!dropdownProps.zipcode.isToCallApi
										) {
											viewModel.fetchCities(
												state.country ?? '',
												_selectedItems,
											);
											if (dropdownProps?.city?.name != null) {
												setValue(dropdownProps.city.name, null);
											}
										}
									}
								},
							}}
							control={control as Control<any, object>}
							errors={errors}
							fieldData={{
								...dropdownProps.state,
								options: state.states ?? ([] as any),
							}}
							dynamicConstraints={{
								...allDynamicConstraints?.[dropdownProps?.state?.name],
							}}
							configProvider={configProvider}
							isViewOnlyMode={isViewOnlyMode}
						/>
					)}

					{/* city */}
					{isValueAvailable(dropdownProps.city) && (
						<SelectComponent
							callbackHandler={{
								onChange: (_selectedItems: string | null) => {
									if (typeof _selectedItems === 'string') {
										dispatch({
											type: AddressStateActions.ACTION_SET_CITY,
											payload: {city: _selectedItems},
										});
									}
								},
							}}
							control={control as Control<any, object>}
							errors={errors}
							fieldData={{
								...dropdownProps.city,
								options: state.cities ?? ([] as any),
							}}
							dynamicConstraints={
								allDynamicConstraints?.[dropdownProps?.city?.name]
							}
							configProvider={configProvider}
							isViewOnlyMode={isViewOnlyMode}
						/>
					)}
				</>
			)}
		</>
	);
};

export default AddressDropdown;
