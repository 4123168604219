import parseAccess from '../../../utils/objectAccessParser/objectAccessParser';
import {strings} from '../../../../../../localization/i18n';
import {APIRequestType, IApiExecutorService} from '../../../utils/core.mapper';
import {ConfigProvider} from '../../../utils/config.mapper';
import {isValueAvailable} from '../../../utils/uiCompsUtils';

export const formatLabel = (
	object: any,
	formatRule: any,
	joinCharacter?: string,
): string =>
	formatRule
		.map((obj: {k: string; v: string}) => {
			let [k, v] = Object.entries(obj)[0];
			return `${strings(k)}${k ? ': ' : ''}${parseAccess(object, v)}`;
		})
		.join(joinCharacter ? joinCharacter : ', ');

const formatValueLabelRecord = (
	obj: any,
	options: any,
	joinCharacter?: string,
): any => {
	var value = parseAccess(obj, options.value);
	var label = formatLabel(obj, options.label, joinCharacter);
	var result = {
		value: value,
		label: label,
	};
	return result;
};

export const processRequestData = (
	searchString: string,
	remoteDropdownRequestModel: Record<string, any>,
) => {
	const searchStringAccessKey =
		remoteDropdownRequestModel.searchStringAccessKey;
	const searchStringKey = remoteDropdownRequestModel.searchStringKey;
	if (
		isValueAvailable(searchStringAccessKey) &&
		isValueAvailable(searchStringKey) &&
		searchString
	) {
		remoteDropdownRequestModel.requestModel[searchStringAccessKey][
			searchStringKey
		] = searchString;
	} else if (
		!isValueAvailable(searchStringAccessKey) &&
		isValueAvailable(searchStringKey)
	) {
		remoteDropdownRequestModel.requestModel[searchStringKey] = searchString;
	}

	return remoteDropdownRequestModel.requestModel;
};

const loadOptions = (
	apiUrl: string,
	valueField: string,
	options: any,
	accessString: string,
	remoteDropdownRequestModel: Record<string, any>,
	configProvider: ConfigProvider,
	requestExecutor: IApiExecutorService,
	joinCharacter?: string,
) => {
	/*
	 * Gets the dropdown options on the basis of search query provided by search input
	 */
	const promiseOptions = async (inputValue: string) => {
		//requestData[`${valueField}:startsWith`] = inputValue
		const requestData = processRequestData(
			inputValue,
			remoteDropdownRequestModel,
		);
		const apiRequest: APIRequestType = {
			url: apiUrl,
			body: requestData,
			headers: {},
			params: {},
			method: 'post',
			baseUrl: configProvider.getConfig().applicationServerUri,
		};

		const _options = requestExecutor
			.postHTTP(apiRequest)
			.then(({data, headers, status}) => {
				let x = parseAccess(data, accessString).map((obj: any) =>
					formatValueLabelRecord(obj, options[0], joinCharacter),
				);
				return x;
			});

		return _options;
	};

	return promiseOptions;
};

// TODO: export formatValueLabelRecord through rewire for testing only!
export {loadOptions, formatValueLabelRecord};
