import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class SendToEmrOrPacsModel implements BaseRequestModel {
	private readonly destinationList?: Array<any> | null;
	private readonly id?: number;
	private readonly xdsMap?: Record<string, any>;

	constructor({destinationList, id, xdsMap}: SendToEmrOrPacsRequestData) {
		this.destinationList = destinationList;
		this.id = id;
		this.xdsMap = xdsMap;
	}

	/**
	 * returns sendToEmrOrPacs request
	 * @returns {SendToEmrOrPacsRequestData}
	 */
	getRequestDataObject(): SendToEmrOrPacsRequestData {
		return {
			destinationList: this.destinationList,
			id: this.id,
			xdsMap: this.xdsMap,
		};
	}
}

export type SendToEmrOrPacsRequestData = {
	destinationList?: Array<any> | null;
	id?: number;
	xdsMap?: Record<string, any>;
};

export default SendToEmrOrPacsModel;
