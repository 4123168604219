import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class DocumentListRequestModel implements BaseRequestModel {

    private readonly docsCount?: number
    private readonly profilePictureType?: string
    private readonly skipCount: number | undefined
    private readonly statusIn: string | undefined
    private readonly sortDesc: string | undefined
    private readonly authParams: string | undefined
    private readonly typeText: string | undefined
    private readonly subject: string | undefined
    private readonly searchElementsList: Array<string> | undefined

    constructor({
                    docsCount,
                    profilePictureType,
                    skipCount,
                    statusIn,
                    sortDesc,
                    authParams,
                    searchElementsList,
                    typeText,
                    subject
                }: DocumentListRequestData) {
        this.docsCount = docsCount,
            this.profilePictureType = profilePictureType,
            this.skipCount = skipCount,
            this.statusIn = statusIn,
            this.sortDesc = sortDesc,
            this.authParams = authParams,
            this.typeText = typeText
            this.subject = subject
            this.searchElementsList = searchElementsList
    }

    /**
     * returns document list request
     * @returns {Record<string, any>}
     */
    getRequestDataObject(): Record<string, any> {
        const toReturn = {
            "constraints": {
                "profilePictureType": this.profilePictureType,
                "_count": this.docsCount,
                "_skip": this.skipCount,
                "status:in": this.statusIn,
                "_sort:desc": this.sortDesc,
                "type:text:in": this.typeText,
            }
        };

        if (this.subject != null) {
            (toReturn.constraints as any).subject = this.subject;
        } else {
            (toReturn.constraints as any).authParams = this.authParams;
        }

        if (this.searchElementsList != null) {
            (toReturn.constraints as any).searchElementsList = this.searchElementsList;
        }
        return toReturn;
    }

    getParsedData(): DocumentListRequestData {
        return {
            docsCount: this.docsCount,
            profilePictureType: this.profilePictureType,
            skipCount: this.skipCount,
            statusIn: this.statusIn,
            sortDesc: this.sortDesc,
            authParams: this.authParams,
            typeText: this.typeText,
            subject: this.subject,
            searchElementsList: this.searchElementsList,
        }
    }


}

export type DocumentListRequestData = {
    docsCount?: number;
    profilePictureType?: string;
    skipCount?: number;
    statusIn?: string;
    sortDesc?: string;
    authParams?: string;
    typeText?: string;
    subject?: string;
    searchElementsList?: Array<string>,
}

export default DocumentListRequestModel
