import NavBarLogo from '../navBar/components/NavBarLogo';
import NavBarItems from '../navBar/components/NavBarItems';
import NavBarRight from '../navBar/components/NavBarRight';
import './css/navBar.scss';
import {MenuItemsModel} from './types/navBar.types';
import {strings} from '../../../../localization/i18n';
import $ from 'jquery';

import {toggleClass} from '../utils/uiCompsUtils';

/**
 * Renders complete NavBar on the UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function NavBar(props: any) {
	const {
		navbarLogoDetails,
		navItems,
		navItemsRight,
		userLoggedIn,
		configData,
	}: {
		navbarLogoDetails: Record<string, any>;
		navItems: Array<MenuItemsModel>;
		navItemsRight: Array<Record<string, any>>;
		userLoggedIn: boolean;
		configData: Map<string, any>;
	} = props;

	/**
	 * renders navBar Items inclucluding the dropDown
	 * @returns {JSX.Element}
	 */
	function renderNavBarItems() {
		if (userLoggedIn) {
			return (
				<NavBarItems navItems={navItems} configData={configData}></NavBarItems>
			);
		}
	}

	function renderMobileNavBar() {
		if (userLoggedIn) {
			return (
				<button
					data-toggle="collapse"
					aria-expanded="false"
					className="threeBars"
					id="threeBars"
					onClick={() => toggleClass('topnav', 'showMobileNav')}>
					<span className="bar one"></span>
					<span className="bar two"></span>
					<span className="bar three"></span>
					<span className="sr-only">Toggle Navigation</span>
				</button>
			);
		}
	}

	/**
	 * renders extra navigations of on the navBar
	 * @returns {JSX.Element}
	 */
	function renderNavBarRight() {
		return <NavBarRight navItemsRight={navItemsRight}></NavBarRight>;
	}

	const focusOnDomID = () => { 
        $("h1").focus();
    }

	return (
		<>
		<button className="skipHeader" onClick={focusOnDomID}>{strings('accessibility.skipToMainContent')}</button>
			<nav className="topnav" id="topnav">

				{renderMobileNavBar()}
				<NavBarLogo navbarLogoDetails={navbarLogoDetails}></NavBarLogo>
				{renderNavBarItems()}
				<div className="rightNav withoutLogin">{props.children}</div>
				<div className="rightNav">
					<ul className="navbar-nav">{renderNavBarRight()}</ul>
				</div>
			</nav>
			<div className="topNavSpacer"></div>
			<div className="clearfix"></div>
		</>
	);
}

export default NavBar;
