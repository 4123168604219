import Configuration from '../common/src/ApplicationConfig.json';
import axios from 'axios';

const mergeJson = (baseJson: any, childJson: any) => {
	if (!baseJson || !childJson) {
		return baseJson;
	}
	for (const key in baseJson) {
		let value = baseJson[key];
		if (value instanceof Object && !Array.isArray(value)) {
			mergeJson(value, childJson[key]);
		} else {
			baseJson[key] =
				childJson[key] === null || childJson[key] === undefined
					? value
					: childJson[key];
		}
	}
	for (const key in childJson) {
		// add those keys which are not present in baseJson but in childJson
		if (baseJson[key] == null) {
			baseJson[key] = childJson[key];
		}
	}

	return baseJson;
};

const loadConfiguration = (): Promise<any> => {
	return new Promise<any>(resolve => {
		let finalConfigObject = {};
		axios
			.get('./external/ExternalConfig.json')
			.then(externalConfig => {
				console.log('Initiating Final(Product+custom) config');
				if (externalConfig.data !== undefined) {
					finalConfigObject = mergeJson(Configuration, externalConfig.data);
				} else {
					finalConfigObject = Configuration;
				}
			})
			.catch(() => {
				console.log('Initiating prodcut config');
				finalConfigObject = Configuration;
			})
			.finally(() => {
				global.ConfigurationHolder = finalConfigObject;
				resolve(global.ConfigurationHolder);
			});
	});
};

export default loadConfiguration;
