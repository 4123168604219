import { Observable, Subscriber } from 'rxjs';
import Container, { Service } from 'typedi';
import { strings } from '../../../../../../localization/i18n';
import ErrorModel from '../../../network/model/ErrorModel';
import { LoaderType } from '../../generic/models/Loader';
import {
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../generic/models/NotificationMessage';
import BasicSubscribersViewModel from '../../generic/viewModel/BasicSubscribers.viewmodel';
import { NotificationEvents } from '../enums/notificationEvents';
import NotificationSearchRequestModel, {
	NotificationSearchConstraints,
} from '../models/requestModels/NotificationSearchRequestModel';
import { ReadNotificationRequestData } from '../models/requestModels/ReadNotificationRequestModel';
import NotificationSearchResponseModel, {
	NotificationResponse,
} from '../models/responseModels/NotificationSearchResponseModel';
import ReadNotificationResponseModel, {
	ReadNotificationResponseData,
} from '../models/responseModels/ReadNotificationResponseModel';
import NotificationNetworkRepository from '../repositories/NotificationsNetworkRepository';
const SOMETHING_WENT_WRONG = strings('errors.somethingWentWrong');
@Service()
class NotificationViewModel extends BasicSubscribersViewModel<
NotificationEvents,
Record<string, any>
> {
	private readonly notificationNetworkRepo: NotificationNetworkRepository;
	constructor() {
		super();
		this.notificationNetworkRepo = Container.get(NotificationNetworkRepository);
	}

	/**
	 * calls fetchNotifications from notification network repository
	 *@param {NotificationSearchConstraints} requestData
	 * @returns {Observable<NotificationResponse>}
	 */
	fetchNotifications(
		requestData: NotificationSearchConstraints,
	): Observable<NotificationResponse> {
		return new Observable((subscriber: Subscriber<NotificationResponse>) => {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OverScreen,
			});
			const requestModel: NotificationSearchRequestModel =
				new NotificationSearchRequestModel(requestData);
			this.notificationNetworkRepo
				.fetchNotifications(requestModel)
				.then(response => {
					const responseModel = response as NotificationSearchResponseModel;
					subscriber.next(responseModel.getResponseDataObject());
					subscriber.complete();
				})
				.catch((error: ErrorModel) => {
					subscriber.error(error);
					this.notificationMessageSubject.next({
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
						visibility: NotificationVisibility.global,
						message: error.message ? error.message : SOMETHING_WENT_WRONG,
					});
				})
				.finally(() => {
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				});
		});
	}

	/**
	 * calls fetchNotifications from notification network repository
	 *@param {NotificationSearchConstraints} requestData
	 * @returns {Observable<NotificationResponse>}
	 */
	fetchUnreadNotifications(): Observable<NotificationResponse> {
		const requestData: NotificationSearchConstraints = {
			count: 10,
			skip: 0,
			sortDesc: 'dateCreated',
			auditTrail: false,
			read: false,
		}
		return new Observable((subscriber: Subscriber<NotificationResponse>) => {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OverScreen,
			});
			const requestModel: NotificationSearchRequestModel =
				new NotificationSearchRequestModel(requestData);
			this.notificationNetworkRepo
				.fetchNotifications(requestModel)
				.then(response => {
					const responseModel = response as NotificationSearchResponseModel;
					subscriber.next(responseModel.getResponseDataObject());
					subscriber.complete();
				})
				.catch((error: ErrorModel) => {
					subscriber.error(error);
					this.notificationMessageSubject.next({
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
						visibility: NotificationVisibility.global,
						message: error.message ? error.message : SOMETHING_WENT_WRONG,
					});
				})
				.finally(() => {
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				});
		});
	}

	/**
	 * calls API from notification network repository to mark as read
	 *@param {ReadNotificationRequestData} requestData
	 * @returns {Observable<ReadNotificationResponseModel>}
	 */
	markNotificationAsRead(
		requestData: ReadNotificationRequestData,
	): Observable<ReadNotificationResponseData> {
		return new Observable(
			(subscriber: Subscriber<ReadNotificationResponseData>) => {
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OverScreen,
				});
				this.notificationNetworkRepo
					.markNotificationAsRead(requestData)
					.then(response => {
						const responseModel = response as ReadNotificationResponseModel;
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
							visibility: NotificationVisibility.global,
							message: responseModel.getResponseDataObject().message
								? responseModel.getResponseDataObject().message
								: strings('helpDesk.queryList.apiMessage.success'),
						});
						subscriber.next(responseModel.getResponseDataObject());
						subscriber.complete();
					})
					.catch(error => {
						subscriber.error(error);
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
							visibility: NotificationVisibility.global,
							message: error.message ? error.message : SOMETHING_WENT_WRONG,
						});
					})
					.finally(() => {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OverScreen,
						});
					});
			},
		);
	}
}

export default NotificationViewModel;
