import {strings} from '../../../../../localization/i18n';

const UndefinedComponent = ({fieldData}: {fieldData: any}): JSX.Element => {
	return (
		<div id={`div_undefinedFieldComp_${fieldData?.name}`} key={fieldData.name}>
			<p style={{color: 'red'}}>{strings('form.field.invalidField')}</p>
		</div>
	);
};

export default UndefinedComponent;
