import {Controller, ControllerRenderProps, FieldValues} from 'react-hook-form';
import {selectedLanguage, strings} from '../../../../../localization/i18n';
import Label from './components/Label';
import ErrorText from './components/ErrorText';
import HelpText from './components/HelpText';
import 'react-datepicker/dist/react-datepicker.css';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers';
import {FieldType} from '../types/FieldTypeEnum';
import {isValidDate} from '../../utils/uiCompsUtils';
import CalendarIcon from './CalendarIcon';
import ClockIcon from './ClockIcon';
import _ from 'lodash';
import {useEffect, useState} from 'react';

const DatePickerComponent = (props: any): JSX.Element => {
	const {
		fieldData,
		errors,
		control,
		callbackHandler,
		dynamicConstraints,
		configProvider,
		isViewOnlyMode,
	} = props;

	const {
		label,
		name,
		placeholder,
		helpText,
		type,
		constraints,
		dateFormat,
		timeFormat,
		minDate,
		maxDate,
		minTime,
		maxTime,
		showCurrentDate = false,
		showCurrentTime = false,
		disablePast = false,
		disableFuture = false,
		view24hrsFormat = false,
		showStartOfDay = false,
		showEndOfDay = false,
		attributeCssClassName,
	} = fieldData;

	const className = attributeCssClassName;

	const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

	const makeLocalAppearUTC = (value: string | number | Date) => {
		const dateTime = new Date(value);
		const utcFromLocal = new Date(
			dateTime.getTime() + getTimezoneOffset(dateTime),
		);
		return utcFromLocal;
	};

	const localToUTC = (dateTime: Date) => {
		const utcFromLocal = new Date(
			dateTime.getTime() - getTimezoneOffset(dateTime),
		);
		return utcFromLocal;
	};

	const [langLocale, setLangLocale] = useState<Locale | undefined>();

	useEffect(() => {
		const language = selectedLanguage();
		if (language !== 'en') {
			import(`date-fns/locale/${selectedLanguage()}`).then(localeModule => {
				setLangLocale(localeModule.default);
			});
		} else {
			setLangLocale(undefined);
		}
	}, []);

	const inputDateFormat: string =
		(type === 'date' || type === 'dob' || type === 'dateTime') && dateFormat
			? dateFormat
			: global.ConfigurationHolder?.minervaDateFormat.replace('mm', 'MM');
	let inputTimeFormat: string =
		type === 'time' && timeFormat
			? timeFormat
			: view24hrsFormat
			? global.ConfigurationHolder?.minerva24hrsTimeFormat
			: global.ConfigurationHolder?.minervaTimeFormat;

	function handleDateChange(
		value: Date | null,
		field: ControllerRenderProps<FieldValues, any>,
	) {
		if (showStartOfDay || showEndOfDay) {
			if (showStartOfDay) {
				value?.setHours(0, 0, 0, 0);
			}
			if (showEndOfDay) {
				value?.setHours(23, 59, 59, 999);
			}
		} else {
			const currentDate = new Date();
			value?.setHours(currentDate.getHours());
			value?.setMinutes(currentDate.getMinutes());
			value?.setSeconds(currentDate.getSeconds());
			value?.setMilliseconds(currentDate.getMilliseconds());
		}
		if (type === 'dob' && value) {
			value.setHours(0);
			value.setMinutes(0);
			value.setSeconds(0);
			value.setMilliseconds(0);
			value = localToUTC(value);
		}
		field.onChange(value);
		if (callbackHandler?.onChange) {
			callbackHandler?.onChange(value);
		}
	}

	function handleTimeChange(
		value: Date | null,
		field: ControllerRenderProps<FieldValues, any>,
	) {
		field.onChange(value);
		if (callbackHandler?.onChange) {
			callbackHandler?.onChange(value);
		}
	}

	function handleDateTimeBlur(
		value: Date | null,
		field: ControllerRenderProps<FieldValues, any>,
	) {
		if (callbackHandler?.onBlur) {
			callbackHandler?.onBlur(value);
		}
	}

	const nowDate: any = showCurrentDate ? new Date() : null;
	const nowTime: any = showCurrentTime ? new Date().getTime() : null;

	const ampm: boolean = !view24hrsFormat;

	const validateDateChecked = (v: Date | null): boolean => {
		let hasErrors = false;
		if (typeof v === 'string') v = new Date(v);
		if (v != null) {
			hasErrors = !isValidDate(v);
		}
		return !hasErrors || strings('forms.attributes.errorText.date.invalidDate');
	};

	const validateTimeChecked = (v: Date | null): boolean => {
		let hasErrors = false;
		if (v != null) {
			hasErrors = !isValidDate(v);
		}
		return !hasErrors || strings('forms.attributes.errorText.time.invalidTime');
	};

	const validateMinDateChecked = (v: Date | null): boolean => {
		let hasErrors = false;
		if (v != null) {
			if (v < minDate) {
				hasErrors = true;
			}
		}
		return (
			!hasErrors ||
			strings('forms.attributes.errorText.date.minDate', {minDate: minDate})
		);
	};

	const validateMinTimeChecked = (v: Date | null): boolean => {
		let hasErrors = false;
		if (v != null) {
			if (v < minTime) {
				hasErrors = true;
			}
		}
		return (
			!hasErrors ||
			strings('forms.attributes.errorText.time.minTime', {minTime: minTime})
		);
	};

	const validateMaxDateChecked = (v: Date | null): boolean => {
		let hasErrors = false;
		if (v != null) {
			if (v > maxDate) {
				hasErrors = true;
			}
		}
		return (
			!hasErrors ||
			strings('forms.attributes.errorText.date.maxDate', {maxDate: maxDate})
		);
	};

	const validateMaxTimeChecked = (v: Date | null): boolean => {
		let hasErrors = false;
		if (v != null) {
			if (v > maxTime) {
				hasErrors = true;
			}
		}
		return (
			!hasErrors ||
			strings('forms.attributes.errorText.time.maxTime', {maxTime: maxTime})
		);
	};

	const validateDisableFutureChecked = (v: Date | null): boolean => {
		let hasErrors = false;
		if (v != null) {
			if (disableFuture && v > new Date()) {
				hasErrors = true;
			}
		}
		return (
			!hasErrors || strings('forms.attributes.errorText.date.disableFuture')
		);
	};

	const validateDisablePastChecked = (v: Date | null): boolean => {
		let hasErrors = false;
		if (v != null) {
			if (disablePast && v < new Date()) {
				hasErrors = true;
			}
		}
		return !hasErrors || strings('forms.attributes.errorText.date.disablePast');
	};

	return (
		<>
			{true && (
				<div
					id={`div_om_datePickerComp_${name}`}
					className={className ? `form-item ${className}` : 'form-item'}>
					<div id={`div_datePickerComp_${name}`} className="form-group">
						<div className={type === 'dateTime' ? 'row row-small' : ''}>
							{(type === 'date' || type === 'dateTime' || type === 'dob') && (
								<div className={type === 'dateTime' ? 'col-6' : ''}>
									<Label
										htmlFor={name}
										content={label}
										required={dynamicConstraints.required ?? false}
										configProvider={configProvider}
									/>
								</div>
							)}

							{(type === 'time' || type === 'dateTime') && (
								<div className={type === 'dateTime' ? 'col-6' : ''}>
									<label htmlFor={`${name}_time`}>
										Time<span className={'text-danger'}>*</span>
									</label>
								</div>
							)}
						</div>
						<div className={type === 'dateTime' ? 'row row-small' : ''}>
							{(type === 'date' || type === 'dateTime' || type === 'dob') && (
								<div className={type === 'dateTime' ? 'col-6' : ''}>
									<Controller
										name={name}
										control={control}
										defaultValue={
											dynamicConstraints?.defaultValue
												? new Date(dynamicConstraints?.defaultValue)
												: showCurrentDate
												? nowDate
												: null
										}
										rules={{
											required: {
												value: dynamicConstraints?.required ?? false,
												message: constraints?.required?.message ?? '',
											},
											validate: {
												invalidDate: validateDateChecked,
												minDateChecked: validateMinDateChecked,
												maxDateChecked: validateMaxDateChecked,
												disableFutureChecked: validateDisableFutureChecked,
												disablePastChecked: validateDisablePastChecked,
											},
										}}
										render={({field}) => (
											<LocalizationProvider
												dateAdapter={AdapterDateFns}
												locale={langLocale}>
												<DatePicker
													label={
														placeholder
															? strings(placeholder)
															: strings(`form.placeholder.${type}`, {
																	label: strings(label),
															  })
													}
													views={['year', 'month', 'day']}
													disabled={
														isViewOnlyMode ? true : dynamicConstraints?.disabled
													}
													minDate={minDate ? new Date(minDate) : undefined}
													maxDate={maxDate ? new Date(maxDate) : undefined}
													disableFuture={disableFuture}
													disablePast={disablePast}
													className="form-control"
													value={
														type === 'dob'
															? field.value
																? makeLocalAppearUTC(field.value)
																: null
															: field.value ?? null
													}
													inputFormat={inputDateFormat}
													onChange={value => {
														handleDateChange(value, field);
														field.onBlur();
													}}
													components={{
														OpenPickerIcon: CalendarIcon,
													}}
													renderInput={({inputRef, inputProps, InputProps}) => (
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																position: 'relative',
															}}
															className="customDatePicker">
															<label className="sr-only" htmlFor={name}>
																{strings('accessibility.datePicker')}
															</label>
															<input
																id={name}
																aria-describedby={`error_${name} ${name}_helpText_${name}`}
																ref={inputRef}
																className="form-control"
																{...inputProps}
																placeholder={placeholder ? placeholder : strings("forms.attributes.datePicker.dataFormats."+inputDateFormat.toLowerCase())}
																onBlur={inputRef => {
																	field.onBlur();
																	handleDateTimeBlur(
																		new Date(inputRef.currentTarget.value),
																		field,
																	);
																}}
															/>
															{InputProps?.endAdornment}
														</div>
													)}
												/>
											</LocalizationProvider>
										)}
									/>
								</div>
							)}

							{(type === 'time' || type === 'dateTime') && (
								<div className={type === 'dateTime' ? 'col-6' : ''}>
									<Controller
										name={name}
										control={control}
										defaultValue={
											dynamicConstraints?.defaultValue
												? new Date(dynamicConstraints?.defaultValue)
												: showCurrentTime
												? nowTime
												: null
										}
										rules={{
											required: {
												value: dynamicConstraints?.required ?? false,
												message: constraints?.required?.message ?? '',
											},
											validate: {
												invalidTime: validateTimeChecked,
												minTimeChecked: validateMinTimeChecked,
												maxTimeChecked: validateMaxTimeChecked,
												invalidDate: validateDateChecked,
												minDateChecked: validateMinDateChecked,
												maxDateChecked: validateMaxDateChecked,
												disableFutureChecked: validateDisableFutureChecked,
												disablePastChecked: validateDisablePastChecked,
											},
										}}
										render={({field}) => (
											<LocalizationProvider
												dateAdapter={AdapterDateFns}
												locale={langLocale}>
												<TimePicker
													label={
														placeholder
															? strings(placeholder)
															: strings(`form.placeholder.${type}`, {
																	label: strings(label),
															  })
													}
													views={['hours', 'minutes']}
													disabled={
														isViewOnlyMode ? true : dynamicConstraints?.disabled
													}
													minTime={minTime ? new Date(minTime) : undefined}
													maxTime={maxTime ? new Date(maxTime) : undefined}
													ampm={ampm}
													className="form-control"
													value={field.value ?? null}
													inputFormat={inputTimeFormat}
													onChange={value => handleTimeChange(value, field)}
													components={{
														OpenPickerIcon: ClockIcon,
													}}
													renderInput={({inputRef, inputProps, InputProps}) => (
														<div
															style={{display: 'flex', alignItems: 'center'}}
															className="customDatePicker customTimePicker">
															<label
																className="sr-only"
																htmlFor={`${name}_time`}>
																{strings('accessibility.datePicker')}
															</label>
															<input
																className="form-control"
																aria-describedby={`error_${name} ${name}_helpText_${name}`}
																id={`${name}_time`}
																ref={inputRef}
																{...inputProps}
																onBlur={inputRef => {
																	field.onBlur();
																	handleDateTimeBlur(
																		new Date(inputRef.currentTarget.value),
																		field,
																	);
																}}
															/>
															{InputProps?.endAdornment}
														</div>
													)}
												/>
											</LocalizationProvider>
										)}
									/>
								</div>
							)}
						</div>
						{_.get(errors, name) == null && (
							<HelpText content={dynamicConstraints?.helpText} name={name} />
						)}
						{(type === 'date' || type === 'dob') && errors != null && (
							<ErrorText
								errorObject={_.get(errors, name)}
								name={name}
								fieldType={FieldType.DATE}
								label={label}
							/>
						)}
						{type === 'time' && errors != null && (
							<ErrorText
								errorObject={_.get(errors, name)}
								name={name}
								fieldType={FieldType.TIME}
								label={label}
							/>
						)}
						{type === 'dateTime' && errors != null && (
							<ErrorText
								errorObject={_.get(errors, name)}
								name={name}
								fieldType={FieldType.DATE}
								label={label}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default DatePickerComponent;
