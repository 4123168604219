class ErrorModel {
	readonly statusCode?: number;
	readonly message?: string;
	readonly response?: Record<string, any>;
	readonly errorType: ErrorType;

	constructor(
		statusCode?: number,
		message?: string,
		response?: Record<string, any>,
		errorType: ErrorType = ErrorType.SOMETHING_WENT_WRONG,
	) {
		this.statusCode = statusCode;
		this.message = message;
		this.response = response;
		this.errorType = errorType;
	}
}

export enum ErrorType {
	CONNECTION_ERROR,
	/**
	 * Error response from server, look into {@link ErrorModel#statusCode} and {@link ErrorModel#response}  object for  more info
	 */
	SOMETHING_WENT_WRONG,
}

export default ErrorModel;
