class ShareDocValidationModel {
	constructor(
		private _actionNameError: string = '',
		private _externalEmailIdsError: string = '',
		private _noOfDaysForExpiryError: string = '',
		private _patientIdError: string = '',
		private _shareWithError: string = '',
		private _sharingPinError: string = '',
		private _sharingTypeError: string = '',
		private _resourceIdsError: string = '',
		private _selectedResourceIdsError: string = '',
		private _searchCriteriaError: string = '',
		private _isError: boolean = false,
	) {}

	get getActionNameError(): string {
		return this._actionNameError;
	}

	set actionNameError(value: string) {
		this._actionNameError = value;
	}

	get externalEmailIdsError(): string {
		return this._externalEmailIdsError;
	}

	set externalEmailIdsError(value: string) {
		this._externalEmailIdsError = value;
	}

	get noOfDaysForExpiryError(): string {
		return this._noOfDaysForExpiryError;
	}

	set noOfDaysForExpiryError(value: string) {
		this._noOfDaysForExpiryError = value;
	}

	get patientIdError(): string {
		return this._patientIdError;
	}

	set patientIdError(value: string) {
		this._patientIdError = value;
	}

	get shareWithError(): string {
		return this._shareWithError;
	}

	set shareWithError(value: string) {
		this._shareWithError = value;
	}

	get sharingPinError(): string {
		return this._sharingPinError;
	}

	set sharingPinError(value: string) {
		this._sharingPinError = value;
	}

	get sharingTypeError(): string {
		return this._sharingTypeError;
	}

	set sharingTypeError(value: string) {
		this._sharingTypeError = value;
	}

	get resourceIdsError(): string {
		return this._resourceIdsError;
	}

	set resourceIdsError(value: string) {
		this._resourceIdsError = value;
	}

	set selectedResourceIdsError(value: string) {
		this._selectedResourceIdsError = value;
	}
	get selectedResourceIdsError(): string {
		return this._selectedResourceIdsError;
	}
	set searchCriteriaError(value: string) {
		this._searchCriteriaError = value;
	}
	get searchCriteriaError(): string {
		return this._searchCriteriaError;
	}

	get isError(): boolean {
		return this._isError;
	}

	set isError(value: boolean) {
		this._isError = value;
	}
}
export default ShareDocValidationModel;
