import analyticsHelperInstance from "./AnalyticsHelper";

const logEvent = (event : string, options: any = null) => {
	try {
		analyticsHelperInstance.logEvent(event,options);
	} catch(e) {
		console.error(`Could not log event "${event}", firebase instance might not be defined.`)
	}
}

export default logEvent
