class PatientActionBannerRequestModel {
    private readonly constraints?:Record<string, any>;

    constructor({constraints}:PatientActionBannerRequestModelType) {
        this.constraints = constraints
    }

    /**
     * This method is used to get the final request model to be used for API call
     * @return {PatientActionBannerRequestModelType}
     */
    getRequestDataObject():PatientActionBannerRequestModelType{
        return {
            constraints:this.constraints
        }
    }
}
export type PatientActionBannerRequestModelType={
    constraints?:Record<string, any>
}
export default PatientActionBannerRequestModel