import {strings} from '../../localization/i18n';
import {ConfigSingleton} from '../../utils/wrappers/ui-comp/config-transformer';
import DynamicForm from '../../ui/comps/src/dynamic-form/dynamic.form';
import {PageButton} from '../../ui/comps/src/ActionButtons/IPageButton';

const DeactivateUserModal = (props: any) => {
	const {deactivateUserFormData, closeDeactivateUserDialog, deactivateUser} =
		props;

	const deactivateUserButtons: PageButton[] = [
		{
			name: 'userProfile.deactivate.noButton',
			className: 'btn btn-secondary btn-space',
			isAction: true,
			visible: true,
			type: 'button',
			clickHandler: closeDeactivateUserDialog,
		},
		{
			name: 'userProfile.deactivate.yesButton',
			className: 'btn btn-default btn-space',
			isAction: true,
			visible: true,
			type: 'submit',
			clickHandler: e => deactivateUserHandler(e),
		},
	];

	const deactivateUserHandler = (data: any) => {
		if (data.deactivateReason != null) {
			deactivateUser(data.deactivateReason);
		}
	};

	return (
		<>
			{
				<form name="deactivateForm">
					<div
						id="deactivate_popupModal_body"
					>
						<p className="mb15" id="deactivate_popupModal_bodyText">
							{strings('userProfile.deactivate.confirmBoxMessage')}
						</p>
						<div>
							<DynamicForm
								dynamicFormData={deactivateUserFormData}
								onSubmit={deactivateUserHandler}
								actionButtons={deactivateUserButtons}
								configProvider={ConfigSingleton.getInstance()}
							/>
						</div>
					</div>
				</form>
			}
		</>
	);
};
export default DeactivateUserModal;
