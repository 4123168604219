import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class PatientSearchRequestModel implements BaseRequestModel {
	private readonly constraints: PatientSearchConstraints;
	constructor({constraints}: PatientSearchRequestData) {
		this.constraints = constraints;
	}

	/**
	 * returns patient search request data for PatientSearchRequestModel
	 * @returns {PatientSearchRequestData}
	 */
	getRequestDataObject(): PatientSearchRequestData {
		return {
			constraints: this.constraints,
		};
	}
}

export type PatientSearchRequestData = {
	constraints: PatientSearchConstraints;
};

export type PatientSearchConstraints = {
	sortCondition?: string;
	_count?: number;
	_skip?: number;
	_fetchTotalCount?: boolean;
	birthDateFrom?: string;
	birthDateTo?: string;
	ageTo?: string;
	ageFrom?: string;
	locationIdentifiers?: string;
	searchElementsStartsWith?: Array<string>;
	searchElementsListExact?: Array<string>;
	profilePictureType?: string;
};

export default PatientSearchRequestModel;
