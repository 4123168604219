import { FieldType } from '../types/FieldTypeEnum';
import { strings } from '../../../../../localization/i18n';
import ErrorText from './components/ErrorText';
import HelpText from './components/HelpText';
import Label from './components/Label';
import _ from 'lodash';

const TextareaComponent = (props: any): JSX.Element => {
	const {
		fieldData,
		register,
		errors,
		callbackHandler,
		dynamicConstraints,
		configProvider,
		isViewOnlyMode
	} = props;

	const {
		label,
		name,
		constraints,
		placeholder,
		regex,
		type,
		resizable,
		maxRows,
		maxCols,
		attributeCssClassName,
	} = fieldData;

	const className = attributeCssClassName;

	const identityRegex = null;

	/*
	 * use regex is provided through field,
	 * else - use no regex
	 */
	let regExp = regex && regex !== '' ? regex : identityRegex;

	/*
	 * use resizable is provided through field,
	 * else - use default
	 */
	let isResizable = resizable || resizable === undefined ? true : false;

	return (
		<>
			<div
				id={`div_om_textAreaComp_${name}`}
				className={className ? `form-item ${className}` : 'form-item'}>
				<div id={`div_textAreaComp_${name}`} className="form-group ">
					<span className={'textareaLabel'}>
						<Label
							htmlFor={name}
							content={label}
							required={dynamicConstraints.required ?? false}
							configProvider={configProvider}
						/>
					</span>
					<div className={'textAreaInput'}>
						{maxRows && maxCols ? (
							<textarea
								type="text"
								id={name}
								aria-describedby={`error_${name} ${name}_helpText_${name}`}
								placeholder={
									placeholder
										? strings(placeholder)
										: strings(`form.placeholder.${type}`, {
												label: strings(label),
										  })
								}
								{...register(`${name}`, {
									required: {
										value: dynamicConstraints?.required ?? false,
										message: constraints?.required?.message ?? '',
									},
									maxLength: {
										value: parseInt(constraints?.maxLength?.value) ?? null,
										message: constraints?.maxLength?.message ?? '',
									},
									minLength: {
										value: parseInt(constraints?.minLength?.value) ?? null,
										message: constraints?.minLength?.message ?? '',
									},
									shouldUnregister: true,
									value: dynamicConstraints?.defaultValue,
									pattern: {
										value: regExp,
									},
								})}
								className="form-control"
								disabled={
									isViewOnlyMode === true
										? isViewOnlyMode
										: dynamicConstraints?.disabled ?? false
								}
								style={!isResizable ? {resize: 'none'} : {resize: 'both'}}
								{...callbackHandler}
								rows={maxRows}
								cols={maxCols}
							/>
						) : (
							<textarea
								type="text"
								id={name}
								aria-describedby={`${strings(label)}_error_${props?.name}`}
								placeholder={
									placeholder
										? strings(placeholder)
										: strings(`form.placeholder.${type}`, {
												label: strings(label),
										  })
								}
								{...register(`${name}`, {
									required: {
										value: dynamicConstraints?.required ?? false,
										message: constraints?.required?.message ?? '',
									},
									maxLength: {
										value: parseInt(constraints?.maxLength?.value) ?? null,
										message: constraints?.maxLength?.message ?? '',
									},
									minLength: {
										value: parseInt(constraints?.minLength?.value) ?? null,
										message: constraints?.minLength?.message ?? '',
									},
									shouldUnregister: true,
									value: dynamicConstraints?.defaultValue,
									pattern: {
										value: regExp,
									},
								})}
								className="form-control"
								disabled={
									isViewOnlyMode === true
										? isViewOnlyMode
										: dynamicConstraints?.disabled ?? false
								}
								style={!isResizable ? {resize: 'none'} : {resize: 'both'}}
								{...callbackHandler}
							/>
						)}

						{_.get(errors, name) == null && (
							<HelpText content={dynamicConstraints?.helpText} name={name} />
						)}
						{errors != null && (
							<ErrorText
								errorObject={_.get(errors, name)}
								name={name}
								label={label}
								fieldType={FieldType.TEXT}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default TextareaComponent;
