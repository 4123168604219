import {AreaCodeModel} from '../common/AreaCodeModel';
import BaseResponseModel from '../../../../../../../network/interface/BaseResponseModel';
import {DropdownOption} from "../../../../../../../dynamicFormUtil/types/DynamicForm.types";

export default class FetchCitiesResponse implements BaseResponseModel {
	private readonly _county: Array<DropdownOption>;
	private readonly _zipcode?: string;
	private readonly _city?: Array<DropdownOption & {code: string}>;
	private readonly _state?: string;

	constructor({county, zipcode, city, state}: FetchZipCountyResponseType) {
		this._county = county ?? [];
		this._zipcode = zipcode;
		this._city = city;
		this._state = state;
	}

	/**
	 * This method returns the response received from
	 * /minerva/moCountryDropDown/fetchZipCountyData API call
	 * @returns {FetchCitiesResponseType}
	 */
	getResponseDataObject(): FetchZipCountyResponseType {
		return {
			county: this._county,
            zipcode: this._zipcode,
            city: this._city,
            state: this._state
		};
	}
}

export type FetchZipCountyResponseType = {
	county?: Array<DropdownOption>;
    zipcode?: string;
    city?: Array<DropdownOption & {code: string}>;
    state?:string;
};
