import {useContext, useEffect, useState} from 'react';
import {
	appointmentLocationAddress,
	extractDisplayFieldsFromPatient,
	findAllIdentifiers,
	findElementInTelecom,
	getAllAddress,
	idCheckFilter,
	telecomListForSystemPatientBanner,
} from '../../utils/filters/filters';
import {strings} from '../../localization/i18n';
import {unknowCheckFilter} from '../../ui/comps/src/utils/uiCompsUtils';
import {sessionStorageKeys} from '../../common/src/interface/storage/constants/SessionKeys';
import {Identifier} from '../../common/src/interface/patientBanner/IPatientBannerHelper';
import PATIENTBANNER from '../../common/src/enums/PatientBanner';
import {calculateAgeFromDob} from '../../common/src/utils/DateTimeUtils';
import PatientBannerNavbar from './PatientBanner.navbar';
import {PatientDetailsContext, IPatientContext} from './PatientDetails';
import './patientSearch.scss';
import Container from 'typedi';
import PatientSearchViewModel from '../../common/src/application/modules/patientSearch/viewModels/PatientSearchViewModel';
import encryptedStorageHelperInstance from '../../storage/EncryptedStorageHelper';
import PhotoComponent from '../../ui/comps/src/dynamic-form/form-components/photo/photo.component';
import {Photo} from '../../common/src/application/modules/documents/models/responseModel/PatientShowResponseModel';
import {ConfigSingleton} from '../../utils/wrappers/ui-comp/config-transformer';

const PatientBanner = (props: any): JSX.Element => {
	var userRenderConfig: Record<string, any> =
		encryptedStorageHelperInstance.getItemSync(
			sessionStorageKeys.SERVER_CONFIG,
		);

	const patientSearchViewModel: PatientSearchViewModel = Container.get(
		PatientSearchViewModel,
	);

	let {patientDetails, patientId} = useContext<IPatientContext>(
		PatientDetailsContext,
	);

	const [patientData, setPatientData] =
		useState<Record<string, any>>(patientDetails);

	const [displayFields, setDisplayFields] = useState<Record<string, any>>({});
	let photo: Photo = {data: '', title: '', contentType: ''};
	let renderProfilePicture = false;

	useEffect(() => {
		// converting flattened name from displayFields to fhir
		if (patientData) {
			setDisplayFields(extractDisplayFieldsFromPatient(patientData));
		} else {
			patientSearchViewModel
				.fetchPatientDetails(patientId)
				.then((patientResponse: any) => {
					if (!patientResponse.isError) {
						if (patientResponse) {
							setPatientData({...patientResponse});
							setDisplayFields(
								extractDisplayFieldsFromPatient({...patientResponse}),
							);
						}
					}
				});
		}
	}, []);

	let identifiers: Array<Identifier> | string = findAllIdentifiers(
		userRenderConfig,
		patientData?.identifier,
		PATIENTBANNER.ALL,
	);
	let phones: string[] = telecomListForSystemPatientBanner(
		userRenderConfig,
		global.ConfigurationHolder,
		patientData?.telecom,
		PATIENTBANNER.PHONE,
	);
	const primaryPhone = findElementInTelecom(patientData.telecom, 'phone');
	let emails: string[] = telecomListForSystemPatientBanner(
		userRenderConfig,
		global.ConfigurationHolder,
		patientData?.telecom,
		PATIENTBANNER.EMAIL,
	);
	const primaryEmail = findElementInTelecom(patientData.telecom, 'email');
	let addresses: string[] = getAllAddress(
		userRenderConfig,
		patientData?.address,
	);
	if (
		patientData?.photo &&
		patientData.photo?.length > 0 &&
		patientData.photo[0].data &&
		patientData.photo[0].contentType
	) {
		photo = patientData.photo[0];
		renderProfilePicture = true;
	}

	return (
		<>
			<div className="patientBanner">
				<div className="patInfo">
					<div
						className={
							patientData?.deceased?.value
								? 'grey avatar medium'
								: 'avatar medium'
						}
						aria-hidden="true"
					>
						{renderProfilePicture ? (
							<PhotoComponent
								base64PhotoString={`data:${photo.contentType};base64,${photo.data}`}
								base64PhotoMimetype={photo.contentType}
								picUploaded={true}
								showInitials={false}
								fieldData={{name: 'bannerProfilePicture'}}
								renderProfilePicture={renderProfilePicture}
								configProvider={ConfigSingleton.getInstance()}
							/>
						) : patientData?.nameInitials ? (
							patientData.nameInitials
						) : (
							'U'
						)}
					</div>

					<div className="row1 d-flex justify-content-between">
						<div
							className={
								patientData?.deceased?.value ? 'grey patName' : 'patName'
							}
						>
							{displayFields.displayName}
						</div>
						<div className="demographics">
							<div className="dListCont inline">
								<p className="dList">
									<em>{strings('patientSearch.list.cellLabel.dob') + ': '} </em>
									<span>{unknowCheckFilter(displayFields.dob)}</span>
								</p>

								<p className="dList">
									{global.ConfigurationHolder.patientBanner.showPatientAge &&
										calculateAgeFromDob(displayFields.dob).age && (
											<>
												<em>
													{strings('patientSearch.list.cellLabel.age') + ': '}
												</em>
												<span>
													{calculateAgeFromDob(displayFields.dob).age}
												</span>
											</>
										)}
								</p>
								<p className="dList">
									<em>
										{strings('patientSearch.list.cellLabel.gender') + ': '}
									</em>
									<span>{unknowCheckFilter(displayFields.gender, 'true')}</span>
								</p>
								<p className="dList break-small">
									<span className="tabularTooltip reverse not-small">
										<button className="btn btn-link noBtnStyle tooltipLink">
											<span className="tooltipLinkText link-text">
												{identifiers &&
													Array.isArray(identifiers) &&
													identifiers.length > 0 && (
														<>
															<em>
																{strings('patientSearch.list.cellLabel.id') +
																	': '}
															</em>
															<span>
																{identifiers[identifiers.length - 1].value}
																{identifiers.length > 1 ? '...' : ''}
															</span>
														</>
													)}
											</span>
											<div className="tabularTooltip-box">
												<div className="arrowUp"></div>
												<div className="tabularTooltip-box-inr">
													<div className="tabularTooltip-box-inr2">
														<div className="tooltip-head" aria-hidden="true">
															<div className="row">
																<div className="col-3">
																	{strings('patientSearch.list.cellLabel.id') +
																		': '}
																</div>
																<div className="col-4">
																	{strings('patientBanner.assigningAuthority')}
																</div>
																<div className="col-5">
																	{strings('patientBanner.typeCode')}
																</div>
															</div>
														</div>

														{global.ConfigurationHolder.patientBanner
															.showPatientIdTooltip &&
															Array.isArray(identifiers) &&
															identifiers.map(
																(identifier: Identifier, index: number) => {
																	return (
																		<div key={index} className="tooltip-list">
																			<div className="row">
																				<div className="col-3">
																					<em className="sr-only">
																						{strings(
																							'patientSearch.list.cellLabel.id',
																						) + ': '}
																					</em>{' '}
																					{idCheckFilter(identifier.value)}
																				</div>
																				<div className="col-4">
																					<em className="sr-only">
																						{strings(
																							'patientBanner.assigningAuthority',
																						)}
																					</em>{' '}
																					{identifier.system}
																				</div>
																				<div className="col-5">
																					<em className="sr-only">
																						{strings('patientBanner.typeCode')}
																					</em>{' '}
																					{identifier.type
																						? strings(
																								'patientBanner.typeCodeAndAssigningAuthorityMapping.' +
																									identifier.type,
																						  )
																						: identifier.type}
																				</div>
																			</div>
																		</div>
																	);
																},
															)}
													</div>
												</div>
											</div>
										</button>
									</span>
								</p>
								{global.ConfigurationHolder.patientBanner.showDeceasedDate &&
									patientData?.deceased?.value && (
										<ul>
											<li>
												<span className="labelText">
													{strings('patientSearch.diedLabel')}
												</span>
												{unknowCheckFilter(displayFields.deceasedDate)}
												{displayFields.dob && displayFields.deceasedDate && (
													<>
														<span>
															{strings('patientSearch.ageOfDeathLabel')}
														</span>
														<span>
															{
																calculateAgeFromDob(
																	displayFields.dob,
																	displayFields.deceasedDate,
																).age
															}
														</span>
													</>
												)}
											</li>
										</ul>
									)}
							</div>
						</div>
					</div>

					<div className="row2">
						<div className="demographics">
							<div className="dListCont inline">
								{addresses &&
									addresses.length > 0 &&
									appointmentLocationAddress(
										userRenderConfig,
										patientData?.address,
									) && (
										<p className="dList break-small">
											<span className="textTooltip">
												<div tabIndex={0} className=" noBtnStyle tooltipLink">
													<span className="tooltipLinkText noUnderline">
														<em>{strings('patientBanner.address') + ': '}</em>
														<span>
															{' '}
															{appointmentLocationAddress(
																userRenderConfig,
																patientData?.address,
															)}
															{addresses.length > 1 ? '...' : ''}
														</span>
													</span>
													{global.ConfigurationHolder.patientBanner
														.showAddressTooltip && (
														<span className="textTooltip-box w-350">
															<span className="arrowUp"></span>
															<span className="textTooltip-box-inr">
																<span className="tooltipLabelText">
																	{addresses.map(
																		(address: string, index: number) => {
																			return (
																				<span
																					key={index}
																					className="tooltipText"
																				>
																					{address}
																				</span>
																			);
																		},
																	)}
																</span>
															</span>
														</span>
													)}
												</div>
											</span>
										</p>
									)}

								{patientData?.telecom &&
									phones &&
									phones.length > 0 &&
									primaryPhone && (
										<p className="dList break-small">
											<span className="textTooltip">
												<div tabIndex={0} className=" noBtnStyle tooltipLink">
													<span className="tooltipLinkText noUnderline">
														<em>
															{strings('patientSearch.list.cellLabel.phone') +
																': '}
														</em>
														<span>
															{primaryPhone}
															{phones.length > 1 ? '...' : ''}
														</span>
													</span>
													<div className="textTooltip-box w-250">
														<div className="arrowUp"></div>
														<div className="textTooltip-box-inr">
															<div className="tooltipLabelText">
																<em className="tooltipLabel">
																	{strings('patientBanner.others')}
																</em>
																{phones.map((phone: string, index: number) => {
																	return (
																		<div key={index} className="tooltipText">
																			{phone}
																		</div>
																	);
																})}
															</div>
														</div>
													</div>
												</div>
											</span>
										</p>
									)}

								{patientData?.telecom &&
									emails &&
									emails.length > 0 &&
									primaryEmail && (
										<p className="dList break-small">
											<span className="textTooltip">
												<div tabIndex={0} className=" noBtnStyle tooltipLink">
													<span className="tooltipLinkText noUnderline">
														<em>
															{strings('patientSearch.list.cellLabel.email') +
																': '}{' '}
														</em>
														<span>
															{findElementInTelecom(
																patientData.telecom,
																'email',
															)}
															{emails.length > 1 ? '...' : ''}
														</span>
													</span>
													<div className="textTooltip-box w-250">
														<div className="arrowUp"></div>
														<div className="textTooltip-box-inr">
															<div className="tooltipLabelText">
																<em className="tooltipLabel">
																	{strings('patientBanner.others')}
																</em>
																{emails.map((email: string, index: number) => {
																	return (
																		<div key={index} className="tooltipText">
																			{email}
																		</div>
																	);
																})}
															</div>
														</div>
													</div>
												</div>
											</span>
										</p>
									)}
							</div>
						</div>
					</div>

					<div className="clearfix"></div>
				</div>
			</div>
			<PatientBannerNavbar
				patientId={patientId}
				configData={global.ConfigurationHolder.navigationBar}
			/>
		</>
	);
};

export default PatientBanner;
