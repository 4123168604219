import BaseRequestModel from '../../../../../../../network/interface/BaseRequestModel';

export default class FetchStatesRequest implements BaseRequestModel {
	private readonly _lang: string;
	private readonly _countryId: number;

	constructor({lang, countryId}: FetchStatesRequestType) {
		this._lang = lang;
		this._countryId = countryId;
	}

	/**
	 * This method is used to get the request body to be sent
	 * in /minerva/moCountryDropDown/fetchStates API call
	 * @returns {FetchStatesRequestType}
	 */
	getRequestDataObject(): FetchStatesRequestType {
		return {
			lang: this._lang,
			countryId: this._countryId,
		};
	}
}

export type FetchStatesRequestType = {
	lang: string;
	countryId: number;
};
