import {isValueAvailable} from './StringUtils';
import 'moment';
import 'moment/locale/en-ie';
import moment from 'moment-timezone';
import {selectedLanguage, strings, uses24HourClock} from '../../../localization/i18n';
import {sessionStorageKeys} from '../interface/storage/constants/SessionKeys';
import encryptedStorageHelperInstance from '../../../storage/EncryptedStorageHelper';

let convertDateAccordingToTimeZone = false;

export enum HoursToSetTypeEnum {
	START_DAY = 'startDay',
	END_DAY = 'endDay',
}

/**
 * This function is used to set the convertDateAccordingToTimeZone config for date parsing.
 * Since we currently use an async way to get data from storage,
 * this must be called from the first file to be loaded for web and mobile in order
 * for the config to be updated before using date parsing routines.
 * @param {boolean} convertDateToTimeZone, Config to parse date according ingested timezone
 */
export const setConvertDateAccordingToTimeZone = (
	convertDateToTimeZone: boolean,
) => {
	convertDateAccordingToTimeZone = convertDateToTimeZone;
};

/**
 * @deprecated
 * use getFormattedDateWithTimeZone instead
 * @param {string | undefined} date optional date in string format to be converted
 * @param {string | undefined} timeZone to parse date for
 * @returns {string | undefined} formatted date
 */
export const parseDateAccordingToTimeZone = (
	date?: string,
	timeZone?: string,
): string | undefined => {
	// TODO: Create common date parser for timeZone
	return date;
};

/**
 * This method is used to validate date for given date format.
 * Date format is an optional field which defaults to Config.dates.dateFormat
 * @param dateToValidate
 * @param dateFormat
 * @param strict
 */
export const validateDate = (
	dateToValidate?: string,
	dateFormat: string = global.ConfigurationHolder?.dates?.dateFormat,
	strict = false,
) => {
	if (isValueAvailable(dateToValidate)) {
		return moment(dateToValidate, dateFormat, strict).isValid();
	}
	return false; // empty string, null or undefined is invalid date
};

/**
 * This method is used to check whether the input date is valid
 * @param {Date | null} date to verify
 * return {boolean}
 */
export const isValidDate = (date?: Date | null): boolean => {
	return date instanceof Date && !isNaN(date.getDate());
};

/**
 *
 * @returns number for currentyear from server
 */
export const getCurrentYear = (): Promise<number> => {
	return new Promise<number>(resolve => {
		encryptedStorageHelperInstance
			.getItem(sessionStorageKeys.SERVER_CONFIG)
			.then(data => {
				if (data?.copyrightYear) {
					resolve(data.copyrightYear);
				} else {
					resolve(new Date().getFullYear());
				}
			});
	});
};

/**
 * This method is used to get date in required format
 * @param {Date | null} date to format
 * @param {string | null} format according to which date has to be formatted
 * return {string | undefined}
 */
export const getFormattedDate = (
	date?: Date | string | null,
	format?: string | undefined,
): string | undefined => {
	if (
		(date instanceof Date && isValidDate(date) && isValueAvailable(format)) ||
		(typeof date === 'string' &&
			isValueAvailable(date) &&
			isValueAvailable(format))
	) {
		return moment(date).format(format as string);
	} else {
		return undefined;
	}
};

/**
 * This method is used to get date in format set in config
 * @param {Date | string | undefined} date to format
 * return {string | undefined}
 */
export const getDate = (
	date: string | Date | undefined,
): string | undefined => {
	if (
		(date instanceof Date && isValidDate(date)) ||
		(typeof date === 'string' && isValueAvailable(date))
	) {
		moment.locale(selectedLanguage());
		return moment(date).format(global.ConfigurationHolder?.dates?.dateFormat);
	}

	return undefined;
};

/**
 * This method is used to get dob and set it in format of config and timezone UTC
 * @param date  {Date | string | undefined} dob to format
 * @returns
 */
export const viewDob = (
	date: string | Date | undefined,
): string | undefined => {
	if (
		(date instanceof Date && isValidDate(date)) ||
		(typeof date === 'string' && isValueAvailable(date))
	) {
		moment.locale(selectedLanguage());
		return moment(date)
			.utcOffset(0, false)
			.format(global.ConfigurationHolder?.dates?.dateFormat);
	}

	return undefined;
};

/**
 * This method is used to get date in format set in params
 * @param {Date | string | undefined} date to format
 * return {string | undefined}
 */
export const getDateByFormatString = (
	date: string | Date | undefined,
	format: string | undefined,
): string | undefined => {
	if (
		(date instanceof Date && isValidDate(date)) ||
		(typeof date === 'string' && isValueAvailable(date))
	) {
		moment.locale(selectedLanguage());
		return moment(date).format(format);
	}

	return undefined;
};

/**
 * This method is used to get date in format set in config along timezone
 * if the timezone config is enabled
 * @param {Date | string | undefined} date to format
 * @param {string | null} ingestedTimezone timezone to be used for parsing
 * return {string | undefined}
 */
export const getFormattedDateWithTimeZone = (
	date: string | Date | undefined,
	ingestedTimezone?: string,
): string | undefined => {
	let timeZone: string = moment.tz.guess();
	if (
		(date instanceof Date && isValidDate(date)) ||
		(typeof date === 'string' && isValueAvailable(date))
	) {
		let dateFormat = global.ConfigurationHolder?.dates?.dateFormat;
		moment.locale(selectedLanguage());
		if (convertDateAccordingToTimeZone) {
			dateFormat += ' z';
			if (ingestedTimezone && isValueAvailable(ingestedTimezone)) {
				timeZone = ingestedTimezone;
			}
		}

		return moment.tz(date, timeZone).format(dateFormat);
	}

	return undefined;
};

/**
 * This method is used to get date and time in format set in config along timezone
 * if the timezone config is enabled, Internally, this method formats the time in 12/24 hours according
 * to the 24HourFormat configuration.
 * @param {Date | string | undefined} date to format
 * @param {string | null} ingestedTimezone timezone to be used for parsing
 * return {string | undefined}
 */
export const getFormattedDateTime = (
	date: string | Date | undefined,
	ingestedTimezone?: string,
): string | undefined => {
	let timeZone: string = moment.tz.guess();
	if (
		(date instanceof Date && isValidDate(date)) ||
		(typeof date === 'string' && isValueAvailable(date))
	) {
		let dateFormat = is24HoursFormat()
			? global.ConfigurationHolder?.dates?.dateFormatWithTime_24hr
			: global.ConfigurationHolder?.dates?.dateFormatWithTime;
		moment.updateLocale(selectedLanguage(), null);
		if (convertDateAccordingToTimeZone) {
			dateFormat += ' z';
			if (ingestedTimezone && isValueAvailable(ingestedTimezone)) {
				timeZone = ingestedTimezone;
			}
		}

		return moment.tz(date, timeZone).format(dateFormat);
	}

	return undefined;
};

/**
 * This method is used to get date and time with weekday according to format set in config along timezone
 * if the timezone config is enabled, Internally, this method formats the time in 12/24 hours according
 * to the 24HourFormat configuration.
 * @param {Date | string | undefined} date to format
 * @param {string | null} ingestedTimezone timezone to be used for parsing
 * return {string | undefined}
 */
export const getFormattedDateTimeWithDay = (
	date: string | Date | undefined,
	ingestedTimezone?: string,
): string | undefined => {
	let timeZone: string = moment.tz.guess();
	if (
		(date instanceof Date && isValidDate(date)) ||
		(typeof date === 'string' && isValueAvailable(date))
	) {
		let dateFormat = is24HoursFormat()
			? global.ConfigurationHolder?.dates?.dateFormatWithDayAndTime_24hr
			: global.ConfigurationHolder?.dates?.dateFormatWithDayAndTime;
		moment.locale(selectedLanguage());
		if (convertDateAccordingToTimeZone) {
			dateFormat += ' z';
			if (ingestedTimezone && isValueAvailable(ingestedTimezone)) {
				timeZone = ingestedTimezone;
			}
		}

		return moment.tz(date, timeZone).format(dateFormat);
	}

	return undefined;
};

/**
 * This is function returns the date & time string in server format
 * @param {Date} date object require to convert date into server format
 * @return {string | undefined}, returns the server formatted date string
 */
export const getServerFormatDate = (
	date: Date | undefined,
): string | undefined => {
	return date ? date.toISOString() : undefined;
};

/**
 * Converts formatted string for e.g. 10/23/1993 to server date format 1993-10-23T00:00:00.000Z.
 * Similarly, if date is with time format 10/23/1993 11:23 pm it will convert it to 1993-10-23T23:23:00.000Z
 *
 * @param {string} date formatted date in the string format
 * @param {string} currDateFormat the format of the date passed
 * @param {HoursToSetTypeEnum} hoursToSetType (optional) pass the type of day to set time automatically for e.g. if {@link HoursToSetTypeEnum.START_DAY} passed the resulted string will be set to 1993-10-23T00:00:00.000Z. Else, if {@link HoursToSetTypeEnum.END_DAY} the result will be set to 1993-10-23T23:59:59.999Z
 * @return {string} Server formatted date, usually required in API requests.
 */
export const getServerDateFromFormattedString = (
	date: string,
	currDateFormat: string,
	hoursToSetType?: HoursToSetTypeEnum,
): string => {
	const momentDate = moment.utc(date, currDateFormat);
	if (hoursToSetType === HoursToSetTypeEnum.END_DAY) {
		momentDate.endOf('day');
	} else if (hoursToSetType === HoursToSetTypeEnum.START_DAY) {
		momentDate.startOf('day');
	}
	return momentDate.toISOString();
};

/**
 * This is function returns the date & time string in server format
 * @param {string} date object require to convert date into server format
 * @return {string | undefined}, returns the server formatted date string
 */
export const convertDobInServerString = (
	date: string | undefined,
): string | undefined => {
	const momentObject = moment(
		date,
		global.ConfigurationHolder?.dates?.typeAbleDateFormat,
	).utc(true);
	return momentObject.toISOString();
};

/**
 * This function checks if user has selected 24 hours format on android or
 * iOS devices and check for the config in case of web to format the time in 24 hours or 12 hours
 */
const is24HoursFormat = (): boolean => {
	return uses24HourClock();
};

/**
 * This function calculates age from dob or between dob and deceased date
 * @param birthdate
 * @param {Date} deceasedDate optional parameter for date of death
 * @returns {{years?: number, months?: number, days?: number, age: string|''}}, returns age in day month year
 */
export const calculateAgeFromDob = (
	birthdate: Date,
	deceasedDate?: Date,
): {years?: number; months?: number; days?: number; age: string | ''} => {
	let age = '';
	let result: {
		years?: number;
		months?: number;
		days?: number;
		age: string | '';
	} = {age: ''};
	if (birthdate) {
		const today = deceasedDate ? new Date(deceasedDate) : new Date();
		const DOB = new Date(birthdate);
		let totalMonths =
			(today.getFullYear() - DOB.getFullYear()) * 12 +
			today.getMonth() -
			DOB.getMonth();
		totalMonths += today.getDate() < DOB.getDate() ? -1 : 0;
		let years = today.getFullYear() - DOB.getFullYear();
		if (DOB.getMonth() > today.getMonth()) years = years - 1;
		else if (DOB.getMonth() === today.getMonth())
			if (DOB.getDate() > today.getDate()) years = years - 1;

		let days;
		let months;

		if (DOB.getDate() > today.getDate()) {
			months = totalMonths % 12;
			if (months == 0) months = 11;
			const x = today.getMonth();
			switch (x) {
				case 1:
				case 3:
				case 5:
				case 7:
				case 8:
				case 10:
				case 12: {
					const a = DOB.getDate() - today.getDate();
					days = 31 - a;
					break;
				}
				default: {
					const a = DOB.getDate() - today.getDate();
					days = 30 - a;
					break;
				}
			}
		} else {
			days = today.getDate() - DOB.getDate();
			if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
			else months = (totalMonths % 12) + 1;
		}
		if (years < 1) {
			months < 1
				? (age = days + ` ${strings('calculateAge.days')}`)
				: days == 0
				? (age = months + ` ${strings('calculateAge.months')}`)
				: (age = months + ` ${strings('calculateAge.months')}` + days + ` ${strings('calculateAge.days')}`);
		} else {
			age = years + ` ${strings('calculateAge.years')}`;
		}
		result = {years: years, months: months, days: days, age: age};
	}

	return result;
};

export const addDays = (date: Date, days: number): Date => {
	date.setDate(date.getDate() + days);
	return date;
};
export const filterDataOnDate = (
	data: Record<string, any>,
	startDate: Date,
	endDate: Date,
) => {
	if (data) {
		data = data.filter(
			(el: {date: string | number | Date}) =>
				new Date(el.date).getTime() >= startDate.setHours(0, 0, 0, 0) &&
				new Date(el.date).getTime() <= endDate.setHours(23, 59, 59, 999),
		);
	}
	return data;
};

/**
 * This method is used to get the to date with timezone
 * @param date
 * @param timezone
 */
export const toTimezone = (date: Date, timezone?: string): Date => {
	const timeZone = timezone ?? moment.tz.guess();
	return date && timeZone ? convertTimezoneToLocal(date, timeZone) : date;
};

/**
 * This method is used to get the from date with timezone
 * @param date
 * @param timezone
 */
export const fromTimezone = (date: Date, timezone?: string): Date => {
	const timeZone = timezone ?? moment.tz.guess();
	return date && timeZone ? convertTimezoneToLocal(date, timeZone, true) : date;
};

/**
 * This method is used to convert from timezone to Offset
 * @param timezone
 * @param fallback
 */
export function timezoneToOffset(timezone: string, fallback: number) {
	timezone = timezone.replace(/:/g, '');
	const requestedTimezoneOffset =
		Date.parse('Jan 01, 1970 00:00:00 ' + timezone) / 60000;
	return isNaN(requestedTimezoneOffset) ? fallback : requestedTimezoneOffset;
}

/**
 * This method is used to add minutes to the Date
 * @param date
 * @param minutes
 */
export const addDateMinutes = (date: Date, minutes: any): Date => {
	date = new Date(date.getTime());
	date.setMinutes(date.getMinutes() + minutes);
	return date;
};

/**
 * Find diff in days between two dates
 * @param date1
 * @param date2
 * @param inputFormat
 */
export function daysDiffBetweenDates(
	date1: string,
	date2: string,
	inputFormat: string,
): number {
	return moment(date1, inputFormat).diff(moment(date2, inputFormat), 'd');
}

/**
 * This method is used to convert timezone to local
 * @param date
 * @param timezone
 * @param reverse
 */
export const convertTimezoneToLocal = (
	date: Date,
	timezone: any,
	reverse?: boolean,
): Date => {
	const reverseValue = reverse ? -1 : 1;
	const dateTimezoneOffset = date.getTimezoneOffset();
	const timezoneOffset = timezoneToOffset(timezone, dateTimezoneOffset);
	return addDateMinutes(
		date,
		reverseValue * (timezoneOffset - dateTimezoneOffset),
	);
};

/**
 * This method is used to convert date to +GMT format
 * @param date
 */
export const convertDateToGMTFormat = (
	date: Date,
	hoursToSetType?: HoursToSetTypeEnum,
	hours?: number,
	minutes?: number,
	seconds?: number,
	milliSeconds?: number,
): string => {
	let gmtFormatString = '';
	if (hoursToSetType === HoursToSetTypeEnum.START_DAY) {
		gmtFormatString = moment.utc(date.setHours(0, 0, 0, 0)).local().format();
	} else if (hoursToSetType === HoursToSetTypeEnum.END_DAY) {
		gmtFormatString = moment
			.utc(date.setHours(23, 59, 59, 999))
			.local()
			.format();
	} else {
		gmtFormatString = moment
			.utc(
				date.setHours(
					hours ?? 0,
					minutes ?? 0,
					seconds ?? 0,
					milliSeconds ?? 0,
				),
			)
			.local()
			.format();
	}
	return gmtFormatString;
};
export const isInBusinessHour = () => {
	//TODO: this is web perspective code required in Audit trail specialized for QH, and it is same in angular,
	// will check later and correct it out.
	const now = moment().tz('Australia/Brisbane');
	const weekday = now.format('dddd');
	const timeofday = now.format('HHmm');
	const intTimeofday = parseInt(timeofday);
	if (
		weekday.toLowerCase() == 'saturday' ||
		weekday.toLowerCase() == 'sunday'
	) {
		return false;
	} else {
		if (intTimeofday < 800 || intTimeofday > 1700) {
			return false;
		} else {
			return true;
		}
	}
};

/**
 * it converts calendar date to serverDateFormat
 * @param date
 * @returns string of serverDateFormat
 */
export const calendarToServerDateFormat = (date?: Date | null | undefined) => {
	const sendToServerDateFormat: string =
		global.ConfigurationHolder?.dates?.sendToServerDateFormat;
	if (date && date instanceof Date && isValidDate(date)) {
		return moment(date).format(sendToServerDateFormat);
	} else {
		return moment(new Date()).format(sendToServerDateFormat);
	}
};

/**
 * it converts calendar date to serverDate GMT Format
 * @param date
 * @returns string of serverDateGMTFormat
 */
export const calendarToServerDateFormatGMT = (
	date?: Date | null | undefined,
) => {
	const sendToServerDateFormat: string =
		global.ConfigurationHolder?.dates?.sendToServerDateFormatGMT;
	if (date && date instanceof Date && isValidDate(date)) {
		return moment(date).format(sendToServerDateFormat);
	} else {
		return moment(new Date()).format(sendToServerDateFormat);
	}
};

/**
 *
 * @returns the current browser timezone
 */
export const getBrowserTimezone = () => {
	return moment.tz.guess();
};
