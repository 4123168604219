import {array} from '@amcharts/amcharts4/core';
import {props} from 'lodash/fp';
import {useState} from 'react';
import {strings} from '../../localization/i18n';
import DialogBox from '../../ui/comps/src/DialogBox/dialogBox.component';
import PageToolbar from '../../ui/comps/src/pageToolbar/PageToolbar';
import './aboutUs.scss';

export const AboutUsContent = (props: any) => {
	const convertStringToList = (str: string) => {
		let returnList: Array<string> = str.split(', ');
		return returnList;
	};

	const componentListString: string = strings(
		'aboutUs.installedComponentsDetail',
	);
	const list: Array<string> = convertStringToList(componentListString);

	return (
		<>
			<div className="aboutUsModal">
				<section>
					<h3 role="heading">{strings('aboutUs.productName')}</h3>
					<p>{strings('aboutUs.productVersion')}</p>
				</section>

				<section className="mt15">
					<h3>{strings('aboutUs.manufacturerLabel')}</h3>
					<p className="semiBold">{strings('aboutUs.manufacturerName')}</p>
					<p>
						{strings('aboutUs.manufacturerAddress')}
						<div>
							<a
								href={`http://${strings('aboutUs.distributorURL')}`}
								target="_blank"
								id="ab_mnf_nameURL"
								rel="noreferrer"
							>
								{strings('aboutUs.manufacturerURL')}
							</a>
						</div>
					</p>
				</section>

				<section className="mt15">
					<h3>{strings('aboutUs.distributorLabel')}</h3>
					<p className="semiBold">{strings('aboutUs.distributorName')}</p>
					<p>
						{strings('aboutUs.distributorAddress')}
						<div>
							<a
								href={'http://' + strings('aboutUs.distributorURL')}
								target="_blank"
								rel="noreferrer"
							>
								{strings('aboutUs.distributorURL')}
							</a>
						</div>
					</p>
				</section>

				<section className="mt15">
					<h3>{strings('aboutUs.installedComponentsLabel')}</h3>
					<div className="installedDetails" id="installedDetails">
						<div>
							{list?.map((item: string, key: number) => {
								return [<p key={key}>{item}</p>];
							})}
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
