import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Container from 'typedi';
import DataGrid from '../../../ui/comps/src/dataGrid/DataGrid';
import {
	APIDetails,
	GridActionButtonOrIcon,
	TableHeader,
} from '../../../common/src/interface/dataGrid/IDataGrid';
import {ConfigSingleton} from '../../../utils/wrappers/ui-comp/config-transformer';
import {APIExecutorTransformer} from '../../../utils/wrappers/core/core.transformer';
import PageToolbar from '../../../ui/comps/src/pageToolbar/PageToolbar';
import FormsSearchColumn from './search-components/FormsSearchColumn';
import {APIUrl} from '../../../common/src/application/network/values/URLInfo';
import SubmissionDetails from './data-components/SubmissionDetails';
import AdditionalDetails from './data-components/AdditionalDetails';
import Action from './data-components/Action';
import LinkComponent from '../../../ui/comps/src/LinkComponent/link.component';
import {CompositeFormType} from '../../../common/src/application/modules/forms/composite/enum/CompositeFormType';
import encryptedStorageHelperInstance from '../../../storage/EncryptedStorageHelper';
import {sessionStorageKeys} from '../../../common/src/interface/storage/constants/SessionKeys';
import {addClass} from '../../../ui/comps/src/utils/uiCompsUtils';
import './formListing.scss';
import CompositeFormListingViewModel, {
	CompositeFormsNavigationEnum,
} from '../../../common/src/application/modules/forms/composite/viewmodel/CompositeFormListingViewModel';
import {NavigationEvent} from '../../../common/src/application/modules/generic/models/NavigationEvent';
import {NotificationMessage} from '../../../common/src/application/modules/generic/models/NotificationMessage';
import ToastNotification, {
	showNotification,
} from '../../../ui/comps/src/notification/view/ToastNotification';
import {LoaderProps} from '../../../common/src/application/modules/generic/models/Loader';
import Loader from '../../../ui/comps/src/Loader';
import {CompositeFormsFilterConfig} from '../../../common/src/application/modules/forms/composite/models/CompositeFormListModel';
import DialogBox from '../../../ui/comps/src/DialogBox/dialogBox.component';
import {strings} from '../../../localization/i18n';
import {DialogBoxSizeEnum} from '../../../ui/comps/src/DialogBox/dialogBoxEnum';
import {PageButton} from '../../../ui/comps/src/ActionButtons/IPageButton';
import {setFocusOnModalClose} from '../../../utils/filters/filters';

const FormsListing = () => {
	let patientId = useParams().patientId;
	if (!patientId) {
		patientId = encryptedStorageHelperInstance.getItemSync(
			sessionStorageKeys.PATIENT_ID,
		);
	}
	const navigate = useNavigate();

	const formListingContainer = Container.of('CompositeFormListingViewModel');
	const formListingViewModel = formListingContainer.get(
		CompositeFormListingViewModel,
	);

	useEffect(() => {
		const navigationSubscriber = formListingViewModel
			.fetchNavigationObservable()
			.subscribe({
				next: (response: NavigationEvent<any, any>) => {
					if (response.event === CompositeFormsNavigationEnum.VIEW_FORM) {
						navigate(`/patient/${patientId}/createForm`, {
							state: {...response.additionalData},
						});
					}
				},
			});

		const notificationSubscriber = formListingViewModel
			.fetchNotificationMessageObservable()
			.subscribe({
				next: (response: NotificationMessage) => {
					showNotification({
						type: response.visibility,
						level: response.level,
						message: response.message,
						persistOnRouteChange: response.persistOnRouteChange,
						documentIdToFocus: response.documentIdToFocus,
					});
				},
			});

		return () => {
			navigationSubscriber.unsubscribe();
			notificationSubscriber.unsubscribe();
		};
	}, []);

	const FormName = (props: any): JSX.Element => {
		const {row} = props;
		return (
			<>
				<span className={'fs-semiBold'}>
					{strings(`compositeForms.list.${row?.type}`)}
				</span>
			</>
		);
	};

	let columnHeaders: TableHeader[] = [
		{
			label: 'formsListing.headers.formName',
			mappingField: 'formType',
			includeComponent: FormName,
		},
		{
			label: 'formsListing.headers.submissionDetails',
			mappingField: 'submissionDetails',
			includeComponent: SubmissionDetails,
		},
		{
			label: 'formsListing.headers.additionalDetails',
			mappingField: 'additionalDetails',
			includeComponent: AdditionalDetails,
		},
		{
			label: 'formsListing.headers.action',
			mappingField: 'action',
			includeComponent: Action,
		},
	];

	const [showDiscardFormDialog, setShowDiscardFormDialog] =
		useState<boolean>(false);

	const [additionalData, setAdditionalData] = useState<
		Record<string, number | undefined> & {
			type: CompositeFormType;
		}
	>();

	const openDiscardFormDialog = (
		data: Record<string, number | undefined> & {
			type: CompositeFormType;
		},
	) => {
		setAdditionalData(data);
		setShowDiscardFormDialog(true);
	};

	const closeDiscardFormDialog = () => {
		setShowDiscardFormDialog(false);
		setFocusOnModalClose();
	};

	const discardForm = (data: Record<string, any> | undefined) => {
		if (data && data.formResponseId) {
			formListingViewModel.discardForm(data.formResponseId);
			formsSearch(defaultConstraints);
			setShowDiscardFormDialog(false);
		}
	};

	const downloadForm = (data: Record<string, any>) => {
		formListingViewModel.downloadForm(data.formResponseId);
	};

	const defaultConstraints: Record<string, any> = {
		_count: 10,
		_skip: 0,
		patientId: patientId,
		'_sort:desc': 'submittedDate',
	};

	const [apiDetails, setApiDetails] = useState<APIDetails>({
		restUri: APIUrl.COMPOSITE_FORM_LIST,
		requestBody: defaultConstraints,
	});

	const [configDetails, setConfigDetails] =
		useState<CompositeFormsFilterConfig>();

	let configObj = ConfigSingleton.getInstance();
	let requestExecutor = new APIExecutorTransformer();

	//const [isListAscending, setIsListAscending] = useState<boolean>(false);

	const topActionButtons: Array<GridActionButtonOrIcon> = [
		{
			visible: true,
			clickHandler: () => console.log('sort by Submitted On'),
		},
	];

	// const sortOptions: Array<GridActionButtonOrIcon> = [
	// 	{
	// 		label: 'formsListing.sortOptions.submittedOn',
	// 		visible: true,
	// 		clickHandler: () => console.log('sort by Submitted On'),
	// 	},
	// ];

	// const sortAction = (sortKey?: string) => {
	// 	delete defaultConstraints['_sort:asc'];
	// 	delete defaultConstraints['_sort:desc'];
	// 	if (sortKey) {
	// 		defaultConstraints[sortKey] = 'submittedDate';
	// 		setIsListAscending(!isListAscending);
	// 	} else {
	// 		defaultConstraints['_sort:desc'] = 'submittedDate';
	// 	}
	// 	formsSearch(defaultConstraints);
	// };

	const showLeftFilter = () => {
		addClass('leftFilterViewer', 'outer-box-filterOverlay');
	};

	const formsListActionButtons: Array<GridActionButtonOrIcon> = [
		{
			isAction: false,
			visible: true,
			className: 'd-lg-none btnWithIconOnly fIcon icon-search iconSize ',
			clickHandler: showLeftFilter,
		},
		/*{
			name: 'formsListing.actionButtons.sortBy',
			visible: true,
			className: 'btn-secondary',
			clickHandler: () => sortAction('_sort:desc'),
			buttonOptions: sortOptions,
		},
		{
			visible: true,
			'aria-label': 'formsListing.ascDscBtn',
			className:
				'btn-secondary btnWithIconOnly fIcon iconSize' + isListAscending
					? 'btn-secondary btnWithIconOnly fIcon iconSize icon-sort_ascending'
					: 'btn-secondary btnWithIconOnly fIcon iconSize icon-sort_descending',
			clickHandler: () => {
				isListAscending ? sortAction('_sort:desc') : sortAction('_sort:asc');
			},
		},*/
	];

	const pageToolbarDetails = {
		isBackBtnVisible: false,
		pageTitle: 'formsListing.heading',
		actionButtons: formsListActionButtons,
	};

	const [loadFormsListStatus, setLoadFormsListStatus] =
		useState<boolean>(false);

	const formsSearch = (constraints: any) => {
		setLoadFormsListStatus(false);
		const finalSearchData = constraints;
		if (finalSearchData) {
			setApiDetails({
				restUri: APIUrl.COMPOSITE_FORM_LIST,
				requestBody: finalSearchData,
			});
			setLoadFormsListStatus(true);
		} else {
			console.log(
				'following error occurred while loading forms list',
				finalSearchData,
			);
		}
	};

	const viewFormAction = (formResponseId: number, type: CompositeFormType) => {
		formListingViewModel.viewForm(formResponseId, type);
	};

	const fetchFormsFilterConfig = function (patientId: number) {
		formListingViewModel
			.fetchFormsFilterConfig(patientId)
			.subscribe((response: CompositeFormsFilterConfig) => {
				setConfigDetails(response);
			});
	};

	const discardFormActionButtons: PageButton[] = [
		{
			name: 'formsListing.discardFormDialog.noButton',
			isAction: false,
			visible: true,
			type: 'button',
			clickHandler: closeDiscardFormDialog,
		},
		{
			name: 'formsListing.discardFormDialog.yesButton',
			isAction: true,
			visible: true,
			type: 'button',
			clickHandler: () => discardForm(additionalData),
		},
	];

	useEffect(() => {
		if (patientId != null) {
			fetchFormsFilterConfig(parseInt(patientId));
			formsSearch(defaultConstraints);
		}
	}, []);

	const [showLoader, setShowLoader] = useState<boolean>(false);

	useEffect(() => {
		const notificationMessageSubscriber = formListingViewModel
			.fetchNotificationMessageObservable()
			.subscribe({
				next: (response: NotificationMessage) => {
					showNotification({
						type: response.visibility,
						level: response.level,
						message: response.message,
						persistOnRouteChange: response.persistOnRouteChange,
						closeButtonEnabled: true,
					});
				},
			});

		const loaderSubscriber = formListingViewModel
			.fetchLoaderObservable()
			.subscribe({
				next: (response: LoaderProps) => {
					setShowLoader(response.isToShowLoader);
				},
			});

		return () => {
			notificationMessageSubscriber.unsubscribe();
			loaderSubscriber.unsubscribe();
		};
	}, [formListingViewModel]);

	return (
		<>
			{showLoader && <Loader />}
			<div className="cardOuter-blank">
				<div className="outer-box" id="leftFilterViewer">
					{configDetails && (
						<FormsSearchColumn
							formsSearch={formsSearch}
							searchRequestData={defaultConstraints}
							configDetails={configDetails}
						/>
					)}
					<div className="outer-box-left-shadow"></div>
					<div className="outer-box-right">
						<div className="cardOuter min-h-100">
							<PageToolbar
								className="mb15"
								pageToolbarDetails={pageToolbarDetails}
							/>
							<ToastNotification type="local" />
							{configDetails && configDetails.showPreRegFormFillLink && (
								<LinkComponent
									text={strings('compositeForms.headings.preRegistration')}
									label={strings(
										'compositeForms.list.preRegistrationLinklabel',
									)}
									clickHandler={() =>
										navigate(`/patient/${patientId}/createForm`, {
											state: {
												type: CompositeFormType.PRE_REGISTRATION,
												patientId: patientId ? Number(patientId) : null,
											},
										})
									}
									className="semiBold"
								/>
							)}
							{/* this is for testing purpose only */}
							{/* <LinkComponent
								text={'Consent Form'}
								label={'For consent, please fill in the '}
								clickHandler={() =>
									navigate(`/patient/${patientId}/createForm`, {
										state: {
											type: CompositeFormType.CONSENT_FORM,
											patientId: patientId ? Number(patientId) : null,
										},
									})
								}
							/> */}
							{loadFormsListStatus && (
								<DataGrid
									apiDetails={apiDetails}
									configProvider={configObj}
									requestExecutor={requestExecutor}
									headers={columnHeaders}
									globalSearch={false}
									topActionButtons={topActionButtons}
									className="formListing"
									extraProps={{
										viewForm: viewFormAction,
										downloadForm: downloadForm,
										openDiscardFormDialog: openDiscardFormDialog,
									}}
								/>
							)}
							{showDiscardFormDialog && (
								<DialogBox
									title={strings('formsListing.discardFormDialog.title')}
									bodyText={strings('formsListing.discardFormDialog.bodyText')}
									showDialog={showDiscardFormDialog}
									showCloseIcon={true}
									dialogSize={DialogBoxSizeEnum.medium}
									actionButtons={discardFormActionButtons}
									toggleDialogBox={() => closeDiscardFormDialog()}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default FormsListing;
