/**
 * List of Minerva and IDM Apis
 */
export const APIUrl = {
	API_LOGIN: 'api/login',
	GET_USERDETAILS: 'moUser/getUserDetails',
	FIRST_LOGIN_CHANGEPASSWORD: 'firstLoginChangePassword',
	MO_REFRESH_TOKEN_REFRESH_TOKEN_FROM_EXTERNAL_PROVIDER:
		'moRefreshToken/refreshTokenFromExternalProvider',
	PATIENT_SIGNUP: 'patientSignUp',
	OAUTH2_AUTHENTICATE: 'oauth2/authenticate',
	MO_OBSERVATION_SAVE_LIST: 'moObservation/saveList',
	MO_SERVICE_REQUEST_FETCH: 'moServiceRequest/fetch',

	/* TODO: change this once endpoint is available */
	FIREBASE_CONFIG: 'util/firebaseConfig',

	GET_SIGNUP_CONFIG: 'util/getSignUpConfig',
	MO_SIGNUP_SEND_ONE_TIME_PASSWORD: 'moSignUp/sendOneTimePassword',
	MO_SIGNUP_VERIFY_ONE_TIME_PASSWORD: 'moSignUp/verifyOneTimePassword',
	MO_SIGNUP_VALIDATE_DATE_OF_BIRTH: 'moSignUp/validateDateOfBirth',
	MO_SIGNUP_VALIDATE_DEMOGRAPHICS: 'moSignUp/validateDemographics',
	SIGNUP_USER: 'moSignUp/signUpUser',

	MO_USER_REGISTRATION_GET_USER_REGISTRATION_CONFIG:
		'moUserRegistration/getUserRegistrationConfig',
	MO_USER_REGISTRATION_MODE_SELECTION: 'moUserRegistration/modeSelection',
	MO_USER_REGISTRATION_SEND_ONE_TIME_PASSWORD:
		'moUserRegistration/sendOneTimePassword',
	MO_USER_REGISTRATION_VERIFY_ONE_TIME_PASSWORD:
		'moUserRegistration/verifyOneTimePassword',
	MO_USER_REGISTRATION_VALIDATE_DATE_OF_BIRTH:
		'moUserRegistration/validateDateOfBirth',
	MO_USER_REGISTRATION_VALIDATE_DEMOGRAPHICS:
		'moUserRegistration/validateDemographics',
	USER_REGISTRATION: 'moUserRegistration/userRegistration',

	//patientProfile API
	PATIENT_PROFILE_GET_DETAILS: 'moPatient/getPatientProfileDetails',
	PATIENT_PROFILE_SUBMIT_DETAILS: 'moPatientProfile/sendRequestForReview',
	PATIENT_PROFILE_CONFIG_DETAILS: 'selectList/renderProfileConfig',
	PATIENT_PROFILE_VERIFY_OTP: 'moPatientProfile/verifyOneTimePassword',
	PATIENT_PROFILE_SEND_OTP: 'user/sendOneTimePassword',
	PATIENT_PROFILE_VALIDATE_SEND_OTP:
		'moPatientProfile/validatePatientContactAndSendOTP',
	PATIENT_PROFILE_VALIDATE_DVS_DOC: 'moUser/validateDVSDocument',
	PATIENT_PROFILE_VALIDATE_DVS_INPUT: 'moUser/validateDVSInputValue',
	PATIENT_PROFILE_VALIDATE_COVERAGE: 'moPatientProfile/validateCoverage',
	PATIENT_PROFILE_GET_HISTORY_DETAILS:
		'moPatient/getPatientProfileHistoryDetails',

	SOCIAL_HISTORY: 'observation/search',
	CONDITION_SEARCH: 'Condition/search',
	ALLERGYINTOLLERANCE_SEARCH: 'moAllergyIntolerance/fetchAllergies',
	HEALTH_CONCERNS: 'moCondition/fetchConditions',
	MEDICATION: 'moMedicationRequest/search',
	CARE_PLAN_SEARCH: 'carePlan/search',
	ENCOUNTER_SEARCH: 'encounter/search',
	OBSERVATION_SEARCH: 'observation/search',
	GOAL_SEARCH: 'goal/search',
	MEDICATION_REQUEST_SEARCH: 'MedicationRequest/search',
	SERVICE_REQUEST_SEARCH: 'serviceRequest/search',
	QUESTIONNAIRE_RESPONSE_SEARCH: 'questionnaireResponse/search',
	PROCEDURE_SEARCH: 'moProcedure/search',
	DIAGNOSTIC_ORDER_SEARCH: 'DiagnosticOrder/search',
	FAMILY_MEMBER_HISTORY_SEARCH: 'familyMemberHistory/search',
	IMMUNIZATION_SEARCH: 'moImmunization/search',
	MO_ACCOUNT_SEARCH: 'MoAccount/search',
	DEVICE_SEARCH: 'device/search',
	CLINICAL_IMPRESSION_SEARCH: 'ClinicalImpression/search',
	DOCUMENTREFERENCE_SEARCH: 'MoDocumentReference/search',
	MO_APPOINTMENT_FETCH_APPOINEMENT: 'moAppointment/fetchAppointments',
	LOCATION_SHOW: 'Location/show/',
	PRACTIONER_SHOW: 'Practitioner/show/',
	CLINICAL_NOTES: 'moClinicalImpression/searchClinicalImpressions',
	RENDER_CONFIG_WITHOUT_LOGIN: 'user/renderWithoutLoginConfigList',
	MO_USER_SET_NEW_PASSWORD: 'moUser/setNewPasswordWithOtp',
	DISCHARGE_SUMMARY: 'moComposition/show',
	MO_DOCUMENT_REFERENCE_UPLOAD_AND_SAVE: 'moDocumentReference/uploadAndSave',
	MEDICATION_ADMINISTRATION: 'moMedicationAdministration/search',
	GET_CONFIG_FOR_UPLOADER: 'selectList/getConfigForUploader',
	GET_DOCUMENTLISTING_CONFIGURATIONS:
		'selectList/getDocumentListingConfigurations',
	DOCUMENT_REFERENCE_SEARCH_DATA: 'documentReference/fetchDocRefSearchData',
	LOCATION_SEARCH: 'Location/search',
	ORGANIZATION_SEARCH: 'Organization/search',
	CHECK_INITIAL_SPEED: 'questionnaire/checkInitialSpeed/',
	MO_DOCUMENT_REFERENCE_UPDATE_VISIBILITY:
		'MoDocumentReference/updateVisibility',
	MO_DOCUMENT_REFERENCE_SEND_TO_REPOSITORY:
		'moDocumentReference/sendToRepository',
	MO_BULK_DOWNLOAD_CREATE_BULK_ACTION_REQUEST:
		'MoBulkDownload/createBulkActionRequest',
	DOWNLOAD_DIOCM_CANCEL_DOWNLOAD_REQUEST:
		'downloadDicom/cancelDownloadRequest/',
	MO_BULK_DOWNLOAD_CANCEL_BULK_ACTION_REQUEST:
		'MoBulkDownload/cancelBulkDownloadReferenceZip',
	MO_UPDATE_DOCUMENT: 'MoDocumentReference/update',
	IMAGE_STUDY_SEARCH: 'imagingStudy/search',
	PATIENT_SHOW: 'Patient/show/',
	DOCUMENT_REFRENCE_UPDATE: 'documentReference/update/',
	DOCUMENT_REFRENCE_DOWNLOAD_DOCUMENT:
		'documentReference/downloadUploadedDocument/',
	SEARCHLIST_FORWARD_CONFIG: 'selectList/getForwardConfig',
	MO_PRACTITIONER_SEARCH: 'moPractitioner/fetchPractitioners',
	MO_MOBILE_REGISTER_DEVICE: 'moMobileApp/registerDevice',
	RESET_PASSWORD: 'user/resetPassword',
	CHANGE_PASSWORD_ON_FIRST_LOGIN: 'user/changePasswordOnFirstLogin',
	MO_USER_FORGOT_USERNAME: 'moUser/forgotUserName',
	MO_USER_RETRIEVE_USERNAME: 'moUser/retrieveUserName',
	TFA_RESEND_OTP: 'citadelAuth/resendTFAOTP',
	TFA_VALIDATE_OTP: 'citadelAuth/validateTFAOTP',
	QUESTIONNAIRE_RESPONSE_SHOW_APPLICATION:
		'questionnaireResponse/showApplication',
	MO_PATIENT_SEARCH: 'moPatient/search',
	LOGOUT: 'api/logout',
	USER_RENDERCONFIG_LIST: 'user/renderConfigList',
	MO_ENCOUNTER_SEARCH: 'moEncounter/search',
	FETCH_AGREEMENTS_BY_USER: 'moManageAgreements/fetchAcceptedAgreementsByUser',
	SET_AGREEMENT_AS_READ: 'moManageAgreements/setAgreementAsRead',
	GET_VITAL_CONFIG: 'selectList/getVitalConfig',
	GET_VITALS_DETAILS: 'moObservation/getAllVitals',
	GET_VITALS: 'moObservation/getVitals',
	PATIENT_ACTION_BANNER_SHOW: 'moPatient/showPatientBannerActionsItems',
	DELETE_VITAL: 'moObservation/deleteVitals',
	ADD_VITAL: 'moObservation/saveList',
	UPDATE_VITAL: 'moObservation/updateVitals',
	FETCH_ALL_AUDIT_ENTRIES: 'moCustomAudit/fetchAllAuditEntries',
	GET_AUDIT_GROUPED_ACTIONS: 'audit/getAuditGroupedActions',
	OAUTH2_INDEX: 'Oauth2/index',
	GET_FORM_RESPONSE_ATTRIBUTES_DATA:
		'formResponse/getFormAttributesAndFormData',
	GET_PUBLIC_FORM_RESPONSE_ATTRIBUTES_DATA:
		'formResponse/getUnAuthFormAttributesAndFormData',
	SUBMIT_FORM_RESPONSE: 'formResponse/submitFormResponse',
	SUBMIT_PUBLIC_FORM_RESPONSE: 'formResponse/submitUnAuthFormResponse',
	SHARE_VITAL: 'moObservation/shareVitals',
	MO_BULK_REQUEST_EXPORT: 'MoBulkExport/createBulkExportRequest',
	DELETE_ENCOUNTER: 'Encounter/deleteEncounter',
	FETCH_USER: 'moUser/search',
	FETCH_PATIENT: 'moPatient/search',
	FETCH_LINKED_PATIENT: 'moConsent/fetchLinkedPatients',
	DOWNLOAD_EXTERNAL_DISCHARGE_SUMMARY: 'Encounter/dischargeSummary/',
	FETCH_APPOINTMENT_CANCELLATION_REASON:
		'MoAppointment/fetchPhlebotomyAppointmentCancellationReasons',
	COMPOSITE_FORM_LIST: 'formResponse/searchFormResponse',
	COMPOSITE_FORMS_FILTER_CONFIG: 'formResponse/getListingConfigsAndSearchData',
	COMPOSITE_FORMS_DISCARD: 'formResponse/delete',
	COMPOSITE_FORM_DOWNLOAD: 'formResponse/downloadFormResponse',
	MO_ENCOUNTER_REASON_SEARCH: 'moEncounterReasonValueSetList/search/',
	MO_ENCOUNTER_CREATE: 'moEncounter/save/',
	MO_ENCOUNTER_UPDATE: 'moEncounter/update/',
	MO_SAVE_CLINICAL_IMPRESSION: 'moClinicalImpression/saveClinicalImpression',
	GET_BANNER_ACTION_ITEMS: 'moPatient/showPatientBannerActionsItems/',
	DELINK_PATIENT: '/moConsent/update',
	GENERATE_DOCUMENT_VIEW_URL: 'documentReference/generateDocumentViewURL?id=',
	SEND_QR_RESPONSE_TO_EMR_OR_PACS: 'documentReference/sendQResponseToEmrOrPacs',
	SEND_DOCUMENT_TO_EMR_OR_PACS: 'documentReference/sendDocumentToEmrOrPacs',
	VIEW_DISCHARGE_SUMMARY: 'Encounter/dischargeSummary',
	MOUSER_DEACTIVATE_USER: 'moUser/deactivateUser',
	USER_PROFILE_DETAILS: 'moUserProfile/getUserProfileDetails',
	USER_PROFILE_CONFIG: 'moUserProfile/getUserProfileConfig',
	SAVE_COMMUNICATION_PREFERENCES: 'moUserProfile/saveCommunicationPreferences',
	CHANGE_PASSWORD: 'user/changePassword',
	DELETE_ACCOUNT: 'moUserProfile/deleteUser',
	REVOKE_CONSENT_IDX: 'oauth2Helper/revokeConsent',
	CHANGE_RELATIONSHIP: 'moConsent/update',
	MO_HELP_DESK_SEARCH: 'moHelpdesk/search',
	SEARCH_NOTIFICATION: '/Notification/search',
	GET_USER_DASHBOARDS: 'user/getUserDashboards',
	CUSTOMIZE_WIDGET: 'widgetMapping/customizeWidget',
	MO_FETCH_COUNTRIES: 'moCountryDropDown/fetchCountries',
	MO_FETCH_STATES: 'moCountryDropDown/fetchStates',
	MO_FETCH_CITIES: 'moCountryDropDown/fetchCities',
	MO_FETCH_ZIPCOUNTY: 'moCountryDropDown/fetchZipCountyData',
	MO_DIAGNOSTIC_ORDER_SEARCH: 'MoDiagnosticOrder/search',
	CREATE_LINK_PATIENT: 'moPatient/createAndLinkPatient',
	SEND_OTP_TO_LINK_USER: 'patientLinking/sendOtpToLinkingUser',
	VALIDATE_OTP: 'moPatientLinking/validateOtp',
	LINK_DEPENDENT_PATIENT_WITH_USER:
		'patientLinking/linkDependentPatientWithUser',
	SEARCH_PATIENT_FOR_LINKING: 'moPatientLinking/searchPatientForLinking',
	FETCH_GRANTEE_USER: 'moConsent/fetchGranteeUsers',
	SEND_INVITE_CODE: 'moConsent/sendInviteCode',
	FETCH_LOCATION_USING_ROUTER: 'moLocation/fetchLocationUsingRouter',
	SAVE_CONSENT_REQUEST: 'consent/saveConsentRequest',
	MARK_NOTIFICATION_AS_READ: 'notificationEngine/markNotificationAsRead',
	DIAGNOSTIC_REPORT_SEARCH: 'DiagnosticReport/search',
	MO_DIAGNOSTIC_ORDER_SHOW: 'moDiagnosticOrder/show',
	DIAGNOSTIC_ORDER_SHOW: 'DiagnosticOrder/show/',
	IMAGING_STUDY_SHOW: 'ImagingStudy/show',
	DIAGNOSTIC_ORDER_GET_NAME_FROM_DICOM: 'diagnosticOrder/getNameFromDicom',
	INSTANCE_FETCH_IMAGE: 'instance/fetchImage',
	INSTANCE_SHOW: 'Instance/show',
	IMAGING_STUDY_SEARCH_MAP_FOR_VIEWER:
		'selectList/imagingStudySearchMapForViewer',
	RESULT_OBSERVATION_SEARCH: 'Observation/search',
	SHARE_RECORD: 'DiagnosticOrder/share',
	FETCH_PDF_BY_STREAM: 'MoDiagnosticOrder/fetchPdfByteStream',
	FETCH_DIAGNOSTIC_ORDER: 'diagnosticOrder/show/',
	GET_LAST_VIEWED_REPORT: 'audit/getLastViewedRecord',
	UPDATE_RECENTLY_VIEWED_ORDER_QUERE:
		'moDiagnosticOrder/updateRecentlyViewedOrderQueue/',
	DOWNLOAD_SUMMARY: 'DiagnosticOrder/downloadSummary',
	GET_VIEWER_URL: 'viewer/generateDiagnosticViewerURL',
	GENERATE_NONCE_TOKEN: 'moNonce/generateNonceToken',
	MO_BULK_DOWNLOAD: 'MoBulkDownload/bulkDownload',
	COMPOSITE_FORM_SEND_ONE_TIME_PASSWORD: 'moSignUp/sendOneTimePassword',
	COMPOSITE_FORM_VERIFY_ONE_TIME_PASSWORD: 'moSignUp/verifyOneTimePassword',
	RENDER_OBSERVATION_RESULT: 'pdfRenderer/renderObservationResult',
	UPDATE_USER_SETTINGS: 'user/updateSettings',
	MARK_AS_REVIEW: 'moDiagnosticOrder/markAsReviewed/',
	Encounter_DischargeSummary: 'Encounter/dischargeSummary/',
	OAUTH_LOGOUT_PROVIDER: 'oauth2/logout?provider',
};

/**
 * Mapping of APIs with API version, if no version specified
 * V1 will be used by default
 */

const APIVersion: Record<string, string> = {
	[APIUrl.API_LOGIN]: 'V2',
	[APIUrl.SIGNUP_USER]: 'V2',
	[APIUrl.MO_SIGNUP_SEND_ONE_TIME_PASSWORD]: 'V2',
	[APIUrl.MO_SIGNUP_VERIFY_ONE_TIME_PASSWORD]: 'V2',
	[APIUrl.MO_SIGNUP_VALIDATE_DATE_OF_BIRTH]: 'V2',
	[APIUrl.MO_SIGNUP_VALIDATE_DEMOGRAPHICS]: 'V2',
	[APIUrl.MO_OBSERVATION_SAVE_LIST]: 'V4',
	[APIUrl.MO_USER_SET_NEW_PASSWORD]: 'V2',
	[APIUrl.MO_USER_FORGOT_USERNAME]: 'V2',
	[APIUrl.MO_USER_RETRIEVE_USERNAME]: 'V2',
	[APIUrl.PATIENT_ACTION_BANNER_SHOW]: 'V2',
	[APIUrl.GET_VITALS]: 'V4',
	[APIUrl.DELETE_VITAL]: 'V4',
	[APIUrl.ADD_VITAL]: 'V4',
	[APIUrl.UPDATE_VITAL]: 'V4',
	[APIUrl.SHARE_VITAL]: 'V4',
	[APIUrl.GET_BANNER_ACTION_ITEMS]: 'V2',
	[APIUrl.MO_BULK_REQUEST_EXPORT]: 'V1',
	[APIUrl.MO_DIAGNOSTIC_ORDER_SEARCH]: 'V3',
	[APIUrl.DIAGNOSTIC_ORDER_SHOW]: 'V3',
	[APIUrl.CREATE_LINK_PATIENT]: 'V2',
	[APIUrl.FETCH_PDF_BY_STREAM]: 'V3',
	[APIUrl.UPDATE_RECENTLY_VIEWED_ORDER_QUERE]: 'V2',
	[APIUrl.COMPOSITE_FORM_SEND_ONE_TIME_PASSWORD]: 'V2',
	[APIUrl.COMPOSITE_FORM_VERIFY_ONE_TIME_PASSWORD]: 'V2',
	[APIUrl.MARK_AS_REVIEW]: 'V3',
};

/**
 * Get API version for the specific URL
 * @param url
 */
export function getAPIVersion(url: string): string {
	return APIVersion[getURLPath(url)] ?? 'V1';
}
export function getURLPath(url: string): string {
	const urlPath = '';
	for (const urlPathKey in APIVersion) {
		const isFound = url.indexOf(urlPathKey, 0) != -1 ? true : false;
		if (isFound) {
			return urlPathKey;
		}
	}
	return urlPath;
}
