import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input/input';
import {getPhoneNumberObject} from './phonenumber.validator';
import 'react-phone-number-input/style.css';
import ErrorText from '../components/ErrorText';
import Label from '../components/Label';
import './phone.component.scss';
import {strings} from '../../../../../../localization/i18n';
import CountrySelect, {getFinalCountryMap} from './country.select.component';
import {useEffect, useState} from 'react';
import {FieldType} from '../../types/FieldTypeEnum';
import {PhoneNumberType} from './PhoneNumberType';
import {CountryCode} from 'libphonenumber-js/types';
import _ from 'lodash';
import HelpText from '../components/HelpText';
import VerifiedIcon from '../../customComponents/VerifiableContact/VerifiedIcon';

const PhoneComponent = (props: any): JSX.Element => {
	const {
		fieldData,
		errors,
		callbackHandler,
		configProvider,
		dynamicConstraints,
		isViewOnlyMode,
	} = props;
	const {label, name, placeholder, type, attributeCssClassName} = fieldData;

	const countries = getFinalCountryMap(configProvider.getConfig().countries);

	const [phoneNumber, setPhoneNumber] = useState<PhoneNumberType>({
		phoneNumber: dynamicConstraints?.defaultValue
			? dynamicConstraints?.defaultValue?.countryCode +
			  (dynamicConstraints?.defaultValue?.phoneNumber ?? '')
			: undefined,
		countryCode: dynamicConstraints?.defaultValue
			? parsePhoneNumber(
					dynamicConstraints?.defaultValue?.countryCode +
						dynamicConstraints?.defaultValue?.phoneNumber,
			  )?.country
			: configProvider.getConfig().defaultCountry,
	});

	function handleChange(data: any) {
		let res: PhoneNumberType;
		res = {
			...phoneNumber,
			phoneNumber: data,
		};
		setPhoneNumber(() => res);
	}

	useEffect(() => {
		let res = null;
		if (phoneNumber.phoneNumber != null) {
			res = {
				phoneNumber: phoneNumber.phoneNumber?.substr(
					phoneNumber.countryCode?.length
						? // plus one to remove the "+" symbol
						  ('+' + countries?.[phoneNumber.countryCode]).toString().length
						: 0,
				),
				countryCode: '+' + countries?.[phoneNumber.countryCode],
			};
		}
		props.field?.onChange(res);
		if (callbackHandler?.onChange) {
			callbackHandler?.onChange(res);
		}
	}, [phoneNumber]);

	useEffect(() => {
		const phoneObj: PhoneNumberType | undefined = props?.field?.value;
		const allCountriesCode: Array<string> = Object.keys(countries);
		if (
			phoneObj &&
			allCountriesCode.length > 0 &&
			allCountriesCode.indexOf(phoneObj?.countryCode) > -1
		) {
			const countryCodeValue = countries[phoneObj.countryCode];
			setPhoneNumber(() => ({
				phoneNumber: '+' + countryCodeValue + phoneObj?.phoneNumber,
				countryCode: phoneObj?.countryCode,
			}));
		}
		if (props.field.value === null) {
			setPhoneNumber(() => ({
				countryCode:
					phoneNumber.countryCode ?? configProvider.getConfig().defaultCountry,
				phoneNumber: null,	
			}));
		}
	}, [props.field.value]);

	function handleBlur(e: any) {
		props?.field?.onBlur();
		if (callbackHandler?.onBlur) {
			callbackHandler.onBlur(getPhoneNumberObject(e));
		}
	}

	return (
		<>
			<div
				id={`div_om_phoneComp_${name}`}
				className={
					attributeCssClassName
						? `form-item ${attributeCssClassName}`
						: 'form-item'
				}>
				<div id={`div_phoneComp_${name}`} className="form-group">
					<Label
						htmlFor={name}
						content={label}
						required={dynamicConstraints?.required ?? false}
						configProvider={configProvider}
					/>
					<div
						id={`div_countrySelect_${name}`}
						className="form-control phoneWrapper">
						<fieldset
							disabled={
								isViewOnlyMode === true
									? isViewOnlyMode
									: dynamicConstraints?.disabled ?? false
							}>
							<CountrySelect
								value={phoneNumber.countryCode}
								onChange={(data: any) =>
									setPhoneNumber(prev => ({...prev, countryCode: data}))
								}
								dynamicConstraints={dynamicConstraints}
								countries={countries}
							/>
							<PhoneInput
								className={'PhoneInputInput'}
								placeholder={
									placeholder
										? strings(placeholder)
										: strings(`form.placeholder.${type}`, {
												label: strings(label),
										  })
								}
								country={phoneNumber.countryCode as CountryCode}
								aria-describedby={`error_${name} ${name}_helpText_${name}`}
								international={true}
								id={name}
								onChange={e => handleChange(e)}
								onBlur={(e: any) => handleBlur(e)}
								value={phoneNumber.phoneNumber ?? undefined}
							/>
							{props.verified != null && (
								<VerifiedIcon verified={props.verified} />
							)}
						</fieldset>
					</div>
					{_.get(errors, name) == null && (
						<HelpText content={dynamicConstraints?.helpText} name={name} />
					)}
					<ErrorText
						errorObject={_.get(errors, name)}
						name={name}
						defaultError="forms.attributes.errorText.phoneNumber"
						fieldType={FieldType.TEXT}
					/>
				</div>
			</div>
		</>
	);
};

export default PhoneComponent;
