type maybeString = string | null | undefined;

export const hasSpecialCharacters = (str: maybeString): boolean =>
	str == null ? false : /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(str);

export const hasNumericalCharacters = (str: maybeString): boolean =>
	str == null ? false : /(?=.*[0-9])/.test(str);

export const hasUpperCaseCharacters = (str: maybeString): boolean =>
	str == null ? false : /(?=.*[A-Z])/.test(str);

export const notLessThan =
	(num: number) =>
	(str: maybeString): boolean =>
		str == null ? false : str.length >= num;

export const notMoreThan =
	(num: number) =>
	(str: maybeString): boolean =>
		str == null ? false : str.length <= num;

export const hasLowerCaseCharacters = (str: maybeString): boolean =>
	str == null ? false : /(?=.*[a-z])/.test(str);

export const notBeginWithSpecialCharacter = (str: maybeString): boolean =>
	str == null ? false : !/^[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(str);

export const notBeginWithNumber = (str: maybeString): boolean =>
	str == null ? false : !/^[0-9]/.test(str);

export const notContainsSpace = (str: maybeString): boolean =>
	str == null ? false : !/\s/.test(str);

export const generateValidationMap = (constraints: any) => {
	let returnObject: Record<string, Function> = {
		...(constraints.minLength && {
			minLength: notLessThan(constraints.minLength),
		}),
		...(constraints.maxLength && {
			maxLength: notMoreThan(constraints.maxLength),
		}),
		...(constraints.specialCharacters && {
			specialCharacters: hasSpecialCharacters,
		}),
		...(constraints.numericalCharacters && {
			numericalCharacters: hasNumericalCharacters,
		}),
		...(constraints.upperCaseCharacters && {
			upperCaseCharacters: hasUpperCaseCharacters,
		}),
		...(constraints.lowerCaseCharacters && {
			lowerCaseCharacters: hasLowerCaseCharacters,
		}),
		...(constraints.notBeginWithSpecialCharacter && {
			notBeginWithSpecialCharacter: notBeginWithSpecialCharacter,
		}),
		...(constraints.notBeginWithNumber && {
			notBeginWithNumber: notBeginWithNumber,
		}),
		...(constraints.notContainsSpace && {notContainsSpace: notContainsSpace}),
	};

	return returnObject;
};

export const getValidationReport = (
	validationMap: any,
	inputString: string,
): any => {
	let a = Object.keys(validationMap).map((validationEntry: any) => [
		validationEntry,
		validationMap[validationEntry](inputString),
	]);
	return Object.fromEntries(a);
};
