import {createContext} from 'react';

export const GlobalStateContext = createContext<AppContextInterface>({
	user: null,
	removeAllCookies: null,
	serverConfig: {},
});

export type AppContextInterface = {
	user: any;
	removeAllCookies: {(): void} | null;
	serverConfig: Record<string, any>;
};
