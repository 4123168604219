import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Loader from '../../ui/comps/src/Loader';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import PrivateRoute from '../../PrivateRoute';

const AuditListing = lazy(() => import('./AuditTrail.listing'));

const accessTo: Array<string> = ['OTHERS', 'PATIENT'];

const AuditRoutes = (
	<>
		<Route path="/" element={<PrivateRoute accessTo={accessTo} />}>
			{/* Add authenticated routes under privateRoutes */}
			<Route
				path={RoutePathInfo.auditList.path}
				element={
					<Suspense fallback={<Loader />}>
						<AuditListing />
					</Suspense>
				}
			/>
		</Route>
	</>
);

export default AuditRoutes;
