import ActionButtons from '../ActionButtons/ActionButtons';
import {strings} from '../../../../localization/i18n';
import './css/pageToolbar.scss';

function PageToolbar(props: any) {
	const {
		pageToolbarDetails,
		pageTitleClass,
		pageSubTitleClass,
	}: {
		pageToolbarDetails: any;
		pageTitleClass: any;
		pageSubTitleClass: any;
		backBtnClickHandler: any;
	} = props;
	return (
		<div
			className={
				pageTitleClass
					? `${pageTitleClass} page-title d-flex`
					: 'page-title d-flex'
			}>
			{pageToolbarDetails.isBackBtnVisible && (
				<div className="back-button">
					<button
						onClick={() =>
							pageToolbarDetails.backBtnClickHandler
								? pageToolbarDetails.backBtnClickHandler()
								: null
						}>
						<span className="sr-only">{strings('accessibility.goBack')}</span>{' '}
						<span
							aria-hidden="true"
							className="fIcon icon-back2 backButton backButton2 pull-left flip"></span>
					</button>
				</div>
			)}
			{/* do not change the h1 "id" */}
			<div className="page-title-text" tabIndex={-1}>
				<h1
					id="page_title"
					className={pageToolbarDetails?.pageTitle ? '' : 'sr-only'}
					tabIndex={0}>
					{strings(pageToolbarDetails.pageTitle)}
				</h1>
			</div>
			{pageToolbarDetails.childComponent}
			<ActionButtons actionButtons={pageToolbarDetails.actionButtons} />
		</div>
	);
}

export default PageToolbar;
