import {Subject} from 'rxjs';

/**
 * permissionDeniesObserver is used to publish the values to the subscriber
 * in case API responds with 403 or 404 status code
 * @permissionDeniedSubject - is used to publish and subscribe to the observer
 */
const permissionDeniedSubject = new Subject();
const permissionDeniedObserver = {
	/**
	 * This is used to publish values to the subscribers.
	 * @param {number} statusCode - In permission denied case status values will be either 403 or 404
	 */
	publish(statusCode: number) {
		return permissionDeniedSubject.next(statusCode);
	},
	/**
	 * This function is used to listen to events by subscribing to the permissionDeniedObserver.
	 */
	observable: () => permissionDeniedSubject.asObservable(),
};

/**
 * serverDownObserver is used to publish the values to the subscriber
 * in case API responds with 502 or 503 status code
 * @serverDownSubject - is used to publish and subscribe to the observer
 */
const serverDownSubject = new Subject();
const serverDownObserver = {
	/**
	 * This is used to publish values to the subscribers.
	 * @param {number} statusCode - In this case status values will be either 502 or 503
	 */
	publish(statusCode: number) {
		return serverDownSubject.next(statusCode);
	},
	/**
	 * This function is used to listen to events by subscribing to the serverDownObserver.
	 */
	observable: () => serverDownSubject.asObservable(),
};

/**
 * logoutObserver is used to publish the values to the subscriber
 * in case API responds with 401 or to logout the user form application
 * @logoutSubject - is used to publish and subscribe to the observer
 */
const logoutSubject = new Subject();
const logoutObserver = {
	/**
	 * This is used to publish values to the subscribers.
	 * @param {boolean} logoutUser - In logout case boolean value should be passed as true
	 */
	publish(logoutUser: boolean) {
		return logoutSubject.next(logoutUser);
	},
	/**
	 * This function is used to listen to events by subscribing to the logoutObserver.
	 */
	observable: () => logoutSubject.asObservable(),
};

const userContextSubject = new Subject();
const userContextObserver = {
	/**
	 * This is used to publish values to the subscribers.
	 * @param {any} user - In case of userLogin, pass user Object and in case of login , and null in case of logout or 401
	 */
	publish(user: any) {
		return userContextSubject.next(user);
	},
	/**
	 * This function is used to listen to events by subscribing to the userContextObserver.
	 */
	observable: () => userContextSubject.asObservable(),
};

const languageChangeSubject = new Subject<string>();
const languageChangeObserver = {
	/**
	 * This is used to publish values to the subscribers.
	 * @param {string} languageChangedTo - language tag to which the language is changes
	 */
	publish(languageChangedTo: string) {
		return languageChangeSubject.next(languageChangedTo);
	},
	/**
	 * This function is used to listen to events by subscribing to the languageChangeObserver.
	 */
	observable: () => languageChangeSubject.asObservable(),
};

const notificationCountSubject = new Subject<number>();
const notificationCountObserver = {
	/**
	 * This is used to publish count value to the subscribers.
	 * @param {number} notificationCount - updated count for notifications
	 */
	publish(notificationCount: number) {
		return notificationCountSubject.next(notificationCount);
	},
	/**
	 * This function is used to listen to events by subscribing to the notificationCountObserver.
	 */
	observable: () => notificationCountSubject.asObservable(),
};

const GlobalObserversInstance = {
	permissionDeniedObserver: permissionDeniedObserver,
	serverDownObserver: serverDownObserver,
	logoutObserver: logoutObserver,
	userContextObserver: userContextObserver,
	languageChangeObserver: languageChangeObserver,
	notificationCountObserver: notificationCountObserver
};

export default GlobalObserversInstance;
