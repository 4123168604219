import Label from './components/Label';

export default function RawHTML(props: any) {
	return props.fieldData?.defaultValue ? (
		<div
			className={
				props.fieldData?.attributeCssClassName
					? `form-item ${props.fieldData?.attributeCssClassName}`
					: 'form-item'
			}>
			<Label
				htmlFor={`div_rawHTMLComp_${props?.fieldData?.name}`}
				content={props.fieldData?.label}
				configProvider={props.configProvider}
			/>
			<div
				id={`div_rawHTMLComp_${props?.fieldData?.name}`}
				{...(props.fieldData?.name && { className: props.fieldData.name })}
				dangerouslySetInnerHTML={{
					__html: props.fieldData.defaultValue,
				}}></div>
		</div>
	) : (
		<></>
	);
}
