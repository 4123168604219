import React, {
	useEffect,
	useState,
	SyntheticEvent,
	useRef,
	createRef,
	useMemo,
} from 'react';
import createRequestBody from './utils/requestBodyCreator';
import {nextPage, previousPage} from './utils/paginationButtonUtil';
import Table from './components/Table/Table';
import {APIRequestType} from '../utils/core.mapper';
import TopActionButtons from './components/TopActionButtons';
// import FilterTags from './components/FilterTags';
import {
	CallBackDataType,
	DataGridProps,
} from '../../../../common/src/interface/dataGrid/IDataGrid';
import {strings} from '../../../../localization/i18n';
import _ from 'lodash';

export default function DataGrid(props: DataGridProps) {
	const {
		apiDetails,
		isDataAvailable = false,
		tableData,
		headers,
		paginationEnabled = true,
		pageSize = 10,

		className,

		globalSearch = true,
		rowSelectionEnabled = false,
		selectAllRowsEnabled = true,
		filters,

		topActionButtons,

		configProvider,
		requestExecutor,
		filterTagCallback,
		enableFilterTags = false,
		searchFieldPlaceholder,
		extraProps,
		callbackHandler,
		tableRowChild,
		unavailableComponent,
		isRowDisableCallback,
		rowSelectionCallbackHandler,
		loadingMsgObject,
		gridCounter,
		gridPreservedData,
	} = props;

	let gridSelectionCounter = gridCounter ? gridCounter?.show : false;

	/**
	 * LOADERS
	 */
	const [isLoading, setIsLoading] = useState(true);

	//const [gridCounterFlag, setGridCounterFlag] = useState<boolean>(false);

	/**
	 * DATA
	 */
	const [rowData, setRowData] = useState<any | null>(null);
	const [tableHeaders, setTableHeaders] = useState<any | null>(headers);
	const [totalRecords, setTotalRecords] = useState<number>(0);

	const [gridCallBackData, setGridCallBackData] = useState<CallBackDataType>();

	/**
	 * FLAG
	 */
	const [onFirstPage, setOnFirstPage] = useState<boolean>(false);

	/**
	 * INTERNAL SEARCH
	 */
	const [globalSearchString, setGlobalSearchString] = useState<any>(null);
	const globalSearchRef = useRef<any>();

	const handleGlobalSearch = () => {
		setGlobalSearchString(() => globalSearchRef.current.value);
	};

	const handleGlobalSearchReset = () => {
		if (globalSearchString !== '' || globalSearchString !== null) {
			setGlobalSearchString('');
		}
		globalSearchRef.current.value = null;
		if (apiDetails?.searchStringName) {
			const stringName: string = apiDetails.searchStringName;
			delete apiDetails.requestBody?.constraints[stringName];
		}
	};

	/**
	 * PAGINATION
	 */
	const [skip, setSkip] = useState<number>(0);
	const [count, setCount] = useState(pageSize);
	const [displayCount, setDisplayCount] = useState(pageSize);

	const [paginationButtonsDisabled, setPaginationButtonsDisabled] =
		useState(true);

	const [isDetailsUpdated, setIsDetailsUpdated] = useState<boolean>(false);

	const onPageBtnPrev = (e: SyntheticEvent) => {
		e.preventDefault();
		setIsLoading(true);
		let [newSkip, newCount] = previousPage(pageSize, skip);
		if (newSkip === 0) {
			setOnFirstPage(true);
		} else {
			setOnFirstPage(false);
		}
		setSkip(newSkip);
		setCount(newCount);
		setDisplayCount(displayCount);
	};

	const onPageBtnNext = (e: SyntheticEvent) => {
		e.preventDefault();
		setIsLoading(true);
		let [newSkip, newCount] = nextPage(pageSize, totalRecords, skip);
		if (newSkip !== 0) {
			setOnFirstPage(false);
		}
		setSkip(newSkip);
		setCount(newCount);
		setDisplayCount(displayCount);
	};

	/**
	 * ROW SELECTION
	 */
	const rowRefs = useRef<any>([]);
	const selectAllRowRef = useRef<any>();

	useMemo(() => (rowRefs.current = rowData?.map(() => createRef())), [rowData]);

	const getSelectedRowData = () => {
		const res: any[] = [];
		if (!gridCallBackData?.selectAll) {
			rowRefs?.current?.forEach((row: any, idx: number) => {
				if (row?.current?.checked) {
					res.push(rowData?.[idx]);
				}
			});
		}
		return res;
	};

	const getDeselectedRowData = () => {
		const res: any[] = [];
		if (gridCallBackData?.selectAll) {
			rowRefs?.current?.forEach((row: any, idx: number) => {
				if (row?.current?.checked === false) {
					res.push(rowData?.[idx]);
				}
			});
		}
		return res;
	};

	const onSelectAllRecordsClick = () => {
		rowRefs?.current?.forEach((row: any) => {
			if (selectAllRowRef?.current?.checked) {
				row.current.checked = true;
			} else {
				row.current.checked = false;
			}
		});
		if (rowSelectionCallbackHandler) {
			const callBackData: CallBackDataType = {
				selectedRowsData: getSelectedRowData(),
				deselectedRowData: getDeselectedRowData(),
				selectAll:
					gridPreservedData && gridPreservedData.selectAll
						? gridPreservedData.selectAll
						: Number(totalRecords) === getSelectedRowData().length,
				totalRecordsCount: totalRecords,
				selectedCount: getSelectedRowData().length,
				deselectedCount: getDeselectedRowData().length,
				uiniqueIdKey: gridPreservedData?.uiniqueIdKey,
			};
			setGridCallBackData(_.cloneDeep(callBackData));
			rowSelectionCallbackHandler(callBackData);
		}
	};

	const unSelectAllRow = () => {
		rowRefs?.current?.forEach((row: any) => {
			if (row?.current) {
				row.current.checked = false;
			}
		});
		if (rowSelectionCallbackHandler) {
			const callBackData: CallBackDataType = {
				selectedRowsData: [],
				deselectedRowData: getDeselectedRowData(),
				selectAll: false,
				totalRecordsCount: 0,
				selectedCount: getSelectedRowData().length,
				deselectedCount: getDeselectedRowData().length,
				uiniqueIdKey: gridPreservedData?.uiniqueIdKey,
			};
			setGridCallBackData(_.cloneDeep(callBackData));
			rowSelectionCallbackHandler(callBackData);
		}
	};

	const onRowSelectClick = () => {
		if (getSelectedRowData().length === rowRefs.current.length) {
			selectAllRowRef.current.checked = true;
		} else {
			selectAllRowRef.current.checked = false;
		}
		if (rowSelectionCallbackHandler) {
			const callBackData: CallBackDataType = {
				selectedRowsData: getSelectedRowData(),
				deselectedRowData: getDeselectedRowData(),
				selectAll:
					gridPreservedData && gridPreservedData.selectAll
						? gridPreservedData.selectAll
						: false,
				totalRecordsCount: totalRecords,
				selectedCount: getSelectedRowData().length,
				deselectedCount: getDeselectedRowData().length,
				uiniqueIdKey: gridPreservedData?.uiniqueIdKey,
			};
			setGridCallBackData(_.cloneDeep(callBackData));
			rowSelectionCallbackHandler(callBackData);
		}
	};

	/**
	 * API REQUEST
	 */
	useEffect(() => {
		// this block will be used when we updated the API Details from the first Page
		if (skip === 0) {
			handleSearch();
		}
		// this block will be used when we updated the API Details not from the first page
		if(skip!==0){
			setIsDetailsUpdated(true);
			setSkip(() => 0)
		}
	}, [apiDetails, globalSearchString, filters]);


	useEffect(() => {
		// this block will be executed when the API Details is not updated on first page
		if(isDetailsUpdated){
			setIsDetailsUpdated(false);
			handleSearch();
		}
		// these two below blocks is used for previous and next page event
		if (skip !== 0) {
			handleSearch();
		}
		if (onFirstPage) {
			handleSearch();
		}
	}, [skip]);

	useEffect(() => {
		if (gridPreservedData?.selectAll) {
			rowRefs?.current.forEach((row: any) => {
				if (row?.current) {
					row.current.checked = true;
				}
			});
		}
	}, [skip]);

	const checkAndAddUniqueKeysToRow = (
		data: Record<string, any>,
	): Promise<Record<string, any>> => {
		return new Promise<Record<string, any>>((resolve, reject) => {
			if (gridPreservedData) {
				// set uniqueId from preserved data
				if (gridPreservedData.uiniqueIdKey) {
					let selectedUniqueIds: Array<any> = [];
					const keyName = gridPreservedData.uiniqueIdKey;
					data['uniqueKey'] = keyName;
					gridPreservedData?.selectedRowsData?.forEach(
						(element: Record<string, any>) => {
							selectedUniqueIds.push(element[keyName]);
						},
					);
					data['selectAll'] = gridPreservedData.selectAll;
					data?.list?.forEach((element: Record<string, any>) => {
						element['checked'] = selectedUniqueIds.includes(element[keyName]);
						element['selectAll'] = gridPreservedData.selectAll;
					});
					resolve(data);
				} else {
					resolve(data);
				}
			} else {
				// resolve unmodified data
				resolve(data);
			}
		});
	};

	const handleSearch = () => {
		if (isDataAvailable) {
			setRowData(tableData);
			setIsLoading(false);
		} else {
			setIsLoading(true);
			const apiRequest: APIRequestType = {
				url: apiDetails.restUri,
				body: createRequestBody(count, skip, apiDetails, globalSearchString),
				headers: {},
				params: {},
				method: 'post',
				baseUrl: configProvider.getConfig().applicationServerUri,
			};

			requestExecutor
				.postHTTP(apiRequest)
				.then(({data, headers, status}) => {
					if (gridPreservedData) {
						checkAndAddUniqueKeysToRow(data)
							.then((response: Record<string, any>) => {
								if (callbackHandler) {
									let tempList = response.list;
									callbackHandler(tempList);
									setRowData(tempList);
								} else {
									setRowData(response.list);
								}
								setTotalRecords(response.totalCount);
								if (response.totalCount < pageSize) {
									setDisplayCount(displayCount);
								}
								if (response.totalCount <= pageSize) {
									setPaginationButtonsDisabled(true);
								} else {
									setPaginationButtonsDisabled(false);
								}
							})
							.catch(() => {
								if (callbackHandler) {
									let tempList = data.list;
									callbackHandler(tempList);
									setRowData(tempList);
								} else {
									setRowData(data.list);
								}
								setTotalRecords(data.totalCount);
								if (data.totalCount < pageSize) {
									setDisplayCount(displayCount);
								}
								if (data.totalCount <= pageSize) {
									setPaginationButtonsDisabled(true);
								} else {
									setPaginationButtonsDisabled(false);
								}
							});
					} else {
						if (callbackHandler) {
							let tempList = data.list;
							callbackHandler(tempList);
							setRowData(tempList);
						} else {
							setRowData(data.list);
						}
						setTotalRecords(data.totalCount);
						if (data.totalCount < pageSize) {
							setDisplayCount(displayCount);
						}
						if (data.totalCount <= pageSize) {
							setPaginationButtonsDisabled(true);
						} else {
							setPaginationButtonsDisabled(false);
						}
					}
				})
				.catch((err: any) => {
					console.error('Could not fetch table data', err);
				})
				.finally(() => {
					setIsLoading(false);
					if (globalSearchRef.current) {
						globalSearchRef.current.value = globalSearchString;
					}
					if (!gridPreservedData) {
						unSelectAllRow();
					}
				});
		}
	};

	const checkIsRowDisable = (row: Record<string, any>) => {
		let flag = false;
		if (isRowDisableCallback && row) {
			flag = isRowDisableCallback(row);
		}
		return flag;
	};

	/**
	 *  The below method is used to select or DeSelect All rows present in All Pages
	 *  Accordingly reset all the variables of bulkOperationObject
	 * * */
	const selectAllRows = () => {
		if (gridCallBackData) {
			gridCallBackData.selectAll = true;
			gridCallBackData.selectedRowsData = [];
			gridCallBackData.deselectedRowData = [];
			gridCallBackData.selectedCount = totalRecords;
			gridCallBackData.deselectedCount = 0;
			gridCallBackData.totalRecordsCount = totalRecords;
			setGridCallBackData(_.cloneDeep(gridCallBackData));
			if (rowSelectionCallbackHandler) {
				rowSelectionCallbackHandler(gridCallBackData);
			}
			rowRefs.current.forEach((row: any) => {
				if (row?.current) {
					row.current.checked = true;
				}
			});
			if (selectAllRowRef?.current) {
				selectAllRowRef.current.checked = true;
			}
		}
	};

	/**
	 *  The below method is used to DeSelect all selected rows
	 *  Accordingly reset all the variables of bulkOperationObject
	 * * */
	const clearAllRows = () => {
		if (gridCallBackData) {
			gridCallBackData.selectAll = false;
			gridCallBackData.selectedRowsData = [];
			gridCallBackData.deselectedRowData = [];
			gridCallBackData.selectedCount = totalRecords;
			gridCallBackData.deselectedCount = 0;
			gridCallBackData.totalRecordsCount = totalRecords;
			setGridCallBackData(_.cloneDeep(gridCallBackData));
			if (rowSelectionCallbackHandler) {
				rowSelectionCallbackHandler(gridCallBackData);
			}
			rowRefs.current.forEach((row: any) => {
				if (row?.current) {
					row.current.checked = false;
				}
			});
			if (selectAllRowRef?.current) {
				selectAllRowRef.current.checked = false;
			}
		}
	};

	return (
		<>
			{/* {enableFilterTags && (
				<FilterTags tags={filters} filterTagCallback={filterTagCallback} />
			)} */}
			{globalSearch && (
				<div className="dataGridFilter dataGridFilter-positioner">
					<div className="input-group withSpace mt15">
						<input
							type="text"
							name="gridSearchBar"
							ref={globalSearchRef}
							className="form-control"
							placeholder={
								searchFieldPlaceholder
									? strings(searchFieldPlaceholder)
									: 'Enter search keywords'
							}
							disabled={isLoading}
						/>
						<div className="input-group-append" id="button-addon4">
							<button
								onClick={handleGlobalSearchReset}
								className="btn btn-secondary"
								type="button"
								disabled={isLoading}>
								Reset
							</button>
							<button
								onClick={handleGlobalSearch}
								className="btn btn-primary"
								type="button"
								disabled={isLoading}>
								Search
							</button>

							{topActionButtons?.map((e: any, key: any) => (
								<TopActionButtons
									className="btn btn-secondary"
									name={e.name}
									clickHandler={e.clickHandler}
									getSelectedRowData={getSelectedRowData}
									key={key}
								/>
							))}
						</div>
					</div>
				</div>
			)}
			{gridSelectionCounter &&
				totalRecords > 0 &&
				(gridCallBackData?.selectAll ||
					(gridCallBackData?.selectedRowsData &&
						gridCallBackData.selectedRowsData?.length > 0)) && (
					<div>
						{/* couter starts */}
						{((gridCallBackData?.selectAll &&
							gridCallBackData?.deselectedRowData?.length === 0) ||
							(!gridCallBackData?.selectAll &&
								gridCallBackData?.deselectedRowData?.length ===
									totalRecords)) && (
							<span>
								{strings(gridCounter?.txtWhenAllSelected, {
									totalCount: totalRecords,
								})}
								{'  '}
							</span>
						)}
						{gridCallBackData?.selectedRowsData.length > 0 &&
							gridCallBackData?.selectedRowsData?.length < totalRecords && (
								<span>
									{strings(gridCounter?.txtWhenFewSelected, {
										totalCount: totalRecords,
										selectedResourceSize: gridCallBackData.selectedCount,
									})}
									{'  '}
								</span>
							)}

						{gridCallBackData?.selectAll &&
							gridCallBackData?.deselectedRowData?.length > 0 && (
								<span>
									{strings(gridCounter?.txtWhenSelectedAndFewDeselected, {
										totalCount: totalRecords,
										count: totalRecords - gridCallBackData?.deselectedCount,
									})}
									{'  '}
								</span>
							)}

						{/* counter end */}

						{/* selection/deselction start */}
						{!gridCallBackData?.selectAll &&
							gridCallBackData?.selectedRowsData?.length < totalRecords && (
								<span className="ml15">
									<button
										role="link"
										type="button"
										onClick={() => selectAllRows()}
										className={'btn btn-link noBtnStyle vat'}>
										{strings(gridCounter?.selectAllLinkTitle, {
											totalCount: totalRecords,
										})}
										{'  '}
									</button>
								</span>
							)}

						{(gridCallBackData?.selectedRowsData?.length !== 0 ||
							gridCallBackData?.selectAll) && (
							<span className="ml15">
								<button
									role="link"
									type="button"
									onClick={() => clearAllRows()}
									className={'btn btn-link noBtnStyle vat'}>
									{strings(gridCounter?.clearAllLinkTitle)}
									{'  '}
								</button>
							</span>
						)}
						{/* selection/deselction end */}
					</div>
				)}
			<Table
				headers={tableHeaders}
				rowData={rowData}
				className={className}
				handleSearch={handleSearch}
				isLoading={isLoading}
				rowSelectionEnabled={rowSelectionEnabled}
				selectAllRowsEnabled={selectAllRowsEnabled}
				rowRefs={rowRefs}
				selectAllRowRef={selectAllRowRef}
				onSelectAllRecordsClick={onSelectAllRecordsClick}
				onRowSelectClick={onRowSelectClick}
				configProvider={configProvider}
				extraProps={extraProps}
				tableRowChild={tableRowChild}
				unavailableComponent={unavailableComponent}
				checkIsRowDisable={checkIsRowDisable}
				loadingMsgObject={loadingMsgObject}
			/>
			{paginationEnabled && totalRecords !== 0 && (
				<div className="align-right mt-3">
					<div className="pagination">
						<div className="pager_links">
							{!isLoading && (
								<>
									{displayCount === 0 ? 0 : skip + 1} -{' '}
									{totalRecords < displayCount + skip
										? totalRecords
										: displayCount + skip}{' '}
									of <strong>{totalRecords}</strong>
								</>
							)}
						</div>
						<div className="np_links">
							<div className="btn-group" role="group">
								<button
									aria-label="Previous"
									onClick={onPageBtnPrev}
									disabled={
										paginationButtonsDisabled || skip === 0 || isLoading
									}
									className="btn btn-secondary btn-prev">
									<span
										aria-hidden="true"
										className="fIcon iconSize1 icon-arrow_left1"></span>
								</button>
								<button
									aria-label="Next"
									onClick={onPageBtnNext}
									disabled={
										paginationButtonsDisabled ||
										skip + count === totalRecords ||
										isLoading
									}
									className="btn btn-secondary btn-next">
									<span
										aria-hidden="true"
										className="fIcon iconSize1 icon-arrow_right1"></span>
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className="clearfix"></div>
		</>
	);
}
