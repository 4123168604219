import {useEffect, useRef, useState} from 'react';
import {strings} from '../../../../../localization/i18n';
import {NotificationProps} from '../interface/INotification';
import {notificationStateObserver} from '../service/NotificationService';
import {routeChangeDataStateObserver} from '../service/RouteChangeService';
import './Notification.scss';
import $ from 'jquery';
import 'jquery-ui-bundle';

const ToastNotification = ({type}: {type: 'local' | 'global' | 'dialog'}) => {
	const [visible, setIsVisible] = useState<boolean>(false);
	const [notification, setNotification] = useState<
		NotificationProps | undefined
	>(undefined);
	const [newRoute, setNewRoute] = useState<string>('');
	const [counter, setCounter] = useState<number>(0);

	useEffect(() => {
		let count;
		if (visible) {
			count = counter + 1;
			setCounter(count);
		}
		if (
			notification &&
			(notification.persistOnRouteChange !== true ||
				(notification.persistOnRouteChange === true && count !== 1))
		) {
			closeNotification();
		} else if (
			notification &&
			visible &&
			notification.persistOnRouteChange === true &&
			counter === 1
		) {
			// setPersistOnRouteChangeFlag(!persistOnRouteChangeFlag)
			// setIsVisible(!persistOnRouteChangeFlag)
		}
	}, [newRoute]);

	useEffect(() => {
		const notificationSubsriber = notificationStateObserver
			.observable()
			.subscribe({
				next: (notificationData: NotificationProps) => {
					if (notificationData?.type === type && notificationData?.message) {
						setNotification(notificationData);
						setIsVisible(true);
					} else if (notificationData.closeNotification === true) {
						setCounter(0);
						setNotification(undefined);
						setIsVisible(false);
					}
				},
			});

		const routeChangeSubsriber = routeChangeDataStateObserver
			.observable()
			.subscribe({
				next: location => {
					setNewRoute(location.pathname);
				},
			});

		return () => {
			notificationSubsriber.unsubscribe();
			routeChangeSubsriber.unsubscribe();
		};
	}, [type]);

	const closeNotification = () => {
		setNotification(undefined);
		setIsVisible(false);
	};

	const notifcationBarRef = useRef<any>();

	useEffect(() => {
		if (visible && notifcationBarRef.current) {
			notifcationBarRef.current.focus();
		}
	}, [visible]);

	const focusOnDomID = () => {
		if (notification && notification.documentIdToFocus) {
			if (notification.documentIdToFocus.indexOf('.') > -1) {
				document.getElementById(notification.documentIdToFocus)?.focus();
			}
			$(`#${notification.documentIdToFocus}`).trigger('focus');
		}
	};

	$(function () {
		$('#notifcationBar').on('keypress', function (e) {
			if (e.key === 'Enter' && notification && notification.documentIdToFocus) {
				if (notification.documentIdToFocus.indexOf('.') > -1) {
					document.getElementById(notification.documentIdToFocus)?.focus();
				}
				$(`#${notification.documentIdToFocus}`).trigger('focus');
			}
		});
	});

	return (
		<>
			{visible && notification != null && (
				<div
					className={'alert withClose alert-' + notification?.level}
					ref={notifcationBarRef}
					tabIndex={-1}
					onClick={focusOnDomID}
					id="notifcationBar">
					<span tabIndex={0} role="alert">
						{strings(notification?.message)}
					</span>

					{notification?.closeButtonEnabled && (
						<button
							data-dismiss="alert"
							aria-label={strings('accessibility.close')}
							className="close"
							onClick={closeNotification}>
							<span
								aria-hidden="true"
								className="fIcon icon-cancel1 iconClose"></span>
						</button>
					)}
				</div>
			)}
		</>
	);
};

export const showNotification = (notification: NotificationProps) => {
	clearNotification();
	notificationStateObserver.publish(notification);
};

export const clearNotification = () => {
	notificationStateObserver.publish({closeNotification: true});
};

export default ToastNotification;
