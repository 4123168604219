export enum UploadDocEnums {
	DICOM = 'dicom',
	NON_DICOM = 'non-dicom',
	STRING = 'string',
	PRESCRIPTIONS = 'Prescriptions',
	PRESCRIPTION = 'Prescription',
	DISCHARGE_NOTE = 'Discharge Note',
	LABORATORY_REPORTS = 'Laboratory Reports',
	LABORATORY_REPORT = 'Laboratory Report',
	TEST_RESULTS = 'Test Results',
	RADIOLOGY_REPORTS = 'Radiology Reports',
	IMAGING_STUDIES = 'Imaging Studies',
	RADIOLOGY_REPORT = 'Radiology Report',
	DICOM_IMAGES = 'DICOM Images',
	DOCTORS_LETTER = `Doctor's Letter`,
	REFERRAL_NOTE = 'Referral Note',
	CONSENT_FORMS = 'Consent Forms',
	CERTIFICATES_LETTERS = 'Certificates / Letters',
	BILLS_INVOICE = 'Bills / Invoice',
	PATIENT_IMAGE = 'Patient Image',
	QUESTIONNAIRE_ASSESSMENTS = 'Questionnaire / Assessments',
	ADVANCE_DIRECTIVES = 'Advance Directives',
	OTHERS = 'Others',
	PATIENT_IMAGES = 'Patient Images',
	INSURANCE = 'Insurance',
	FULL_EDITABLE = 'fullEditable',
}
