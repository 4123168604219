import {pickBy} from 'lodash/fp';
import {Controller} from 'react-hook-form';
import {strings} from '../../../../../../localization/i18n';
import CheckboxGroupComponent from './CheckboxGroup.component';

export default function CheckboxGroup(props: any) {
	const pickChecked = (checked: Record<string, any>): Array<string> =>
		Object.keys(pickBy((v: boolean) => v, checked));

	const maxChecked = parseInt(props.fieldData?.customProps?.maxChecked);
	const minChecked = parseInt(props.fieldData?.customProps?.minChecked);

	const validateMaxChecked = (v: Record<string, any>): boolean => {
		let hasErrors = false;
		if (maxChecked != null) {
			const numberOfCheckedEntries: number = pickChecked(v).length;
			if (numberOfCheckedEntries > maxChecked) {
				hasErrors = true;
			}
		}
		return !hasErrors;
	};

	const validateMinChecked = (v: Record<string, any>): boolean => {
		let hasErrors = false;
		if (minChecked != null) {
			const numberOfCheckedEntries: number = pickChecked(v).length;
			if (numberOfCheckedEntries < minChecked) {
				hasErrors = true;
			}
		}
		return !hasErrors;
	};

	const stateMap: Record<string, boolean> = {};
	props.fieldData?.options?.forEach((option: any) => {
		stateMap[option.value] =
			props.dynamicConstraints?.defaultValue?.[option.value] ?? false;
	});

	const requiredError = (v: Record<string, boolean>) => {
		if (!props.dynamicConstraints.required) return true;
		if (v == null) return false;
		return Object.values(v).reduce((prev, e) => e || prev, false);
	};

	return (
		<Controller
			control={props.control}
			name={props.fieldData.name}
			render={opts => <CheckboxGroupComponent {...opts} {...props} />}
			defaultValue={stateMap}
			rules={{
				validate: {
					minError:
						validateMinChecked ||
						strings('forms.attributes.errorText.minChecked', {
							min: props.fieldData?.customProps?.minChecked,
						}),
					maxError:
						validateMaxChecked ||
						strings('forms.attributes.errorText.maxChecked', {
							max: props.fieldData?.customProps?.maxChecked,
						}),
					required: requiredError,
				},
			}}
		/>
	);
}
