export enum NotificationTypeEnum {
	ALL,
	HIGH_ALERT,
	MEDIUM_ALERT,
	INFO_MSG,
}

export enum NotificationResourceTypeEnum {
	QUESTIONNAIRE = 'Questionnaire',
	QUESTIONNAIRE_RESPONSE = 'QuestionnaireResponse',
	TASK = 'Task',
	SERVICE_REQUEST = 'ServiceRequest',
	REFERRAL = 'Referral',
	SERVICE_REQUEST_AUDIT = 'ServiceRequestAudit',
	APPOINTMENT = 'Appointment',
	DIAGNOSTIC_ORDER = 'DiagnosticOrder',
	DIAGNOSTIC_REPORT = 'DiagnosticReport',
	IMAGE_STUDY = 'ImagingStudy',
	OBSERVATION = 'Observation',
	MEDICATION_ORDER = 'MedicationOrder',
	CONDITION = 'Condition',
	ALLERGY_INTOLERANCE = 'AllergyIntolerance',
	SUBJECT = 'Subject',
	PATIENT = 'Patient',
	HELP_DESK_QUERY = 'HelpdeskQuery',
	HELP_DESK_QUERY_RESPONSE = 'HelpdeskQueryResponse',
	BILLS_ORDERS = 'Bills/Orders',
	SHARING = 'Sharing',
	DOCUMENT_REFERENCE = 'DocumentReference',
	COMMUNICATION_CHAIN = 'CommunicationChain',
	COMMUNICATION = 'Communication',
	PATIENT_PROFILE = 'PatientProfile',
	CONSENT = 'Consent',
}

export enum SubjectTypeEnum {
	LINKED_PATIENT_LIST = 'LinkedPatientList',
	MANAGE_LINKED_USER = 'ManagedLinkedUser',
	APPOINTMENT_DETAILS = 'appointmentDetails',
	LIVE_MEETING = 'liveMeeting',
	HOME_APPOINTMENT_DETAILS = 'HomeAppointmentDetails',
	APPOINTMENT_STATUS_CHANGED = 'APPOINTMENT_STATUS_CHANGED',
}
