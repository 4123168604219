import {Service} from 'typedi';
import APIExecutor, {APIRequest} from '../../../network/APIExecutor';
import {APIUrl} from '../../../network/values/URLInfo';
import ImageStudyRequestModel from '../models/requestModel/ImageStudyRequestModel';
import UpdateEditDocumentRequestModel from '../models/requestModel/UpdateEditDocumentRequestModel';
import UpdateVisibilityRequestModel from '../models/requestModel/UpdateVisibilityRequestModel';
import SendToIHERepositoryRequetsModel from '../models/requestModel/SendToIHERepositoryRequetsModel';
import BulkActionRequestModel from '../models/requestModel/BulkActionRequestModel';
import CancelBulkUploadRequestModel from '../models/requestModel/CancelBulkUploadRequestModel';
import DocumentReferenceUpdateRequestModel from '../models/requestModel/DocumentReferenceUpdateRequestModel';
import DocumentReferenceDownloadRequestModel from '../models/requestModel/DocumentReferenceDownloadRequestModel';
import SendToEmrOrPacsModel from '../models/requestModel/SendToEmrOrPacsModel';
import ShareDocRequestModel from '../models/requestModel/ShareDocRequestModel';
import DocumentListResponse, {
	DocumentListResponseType,
} from '../models/responseModel/DocumentListResponseModel';
import DocumentListRequestModel from '../models/requestModel/DocumentListRequestModel';
import DocumentDeleteRequestModel from '../models/requestModel/DocumentDeleteRequestModel';

@Service()
class DocumentListNetworkRepository {
	/**
	 * This method is used to make API call for MoDocumentReference/search
	 * @param {DocumentListRequestModel} request model for MoDocumentReference/search API
	 * @return {Promise<DocumentListResponse>}
	 */
	documentReferenceSearch(
		request: DocumentListRequestModel,
	): Promise<DocumentListResponse> {
		return new Promise<DocumentListResponse>((resolve, reject) => {
			APIExecutor.postHTTP<DocumentListResponseType>(
				new APIRequest(
					APIUrl.DOCUMENTREFERENCE_SEARCH,
					request.getRequestDataObject(),
				),
			)
				.then(
					(value: {
						data: DocumentListResponseType;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(new DocumentListResponse(value.data));
					},
				)
				.catch(error => {
					reject(error);
				});
		});
	}

	/**
	 * fetches configurations for document Lists
	 * @returns {Promise<Record<string, any>>}
	 */
	fetchDocumentListingConfig(): Promise<Record<string, any>> {
		const documentListingConfigRequest = APIExecutor.postHTTP(
			new APIRequest(APIUrl.GET_DOCUMENTLISTING_CONFIGURATIONS, {}),
		);
		return documentListingConfigRequest;
	}

	/**
	 * fetches seearchlist forward configs from back end
	 * @returns {Promise<Record<string, any>>}
	 */
	fetchForwardConfig(): Promise<Record<string, any>> {
		const fetchForwardConfigRequest = APIExecutor.postHTTP(
			new APIRequest(APIUrl.SEARCHLIST_FORWARD_CONFIG, {}),
		);
		return fetchForwardConfigRequest;
	}

	/**
	 * gets patient details
	 * @param {number} patientID
	 * @returns {Promise<Record<string, any>>}
	 */

	fetchPatientDetails(patientID?: number | null): Promise<Record<string, any>> {
		const finalURL = APIUrl.PATIENT_SHOW + patientID;
		const fetchPatientDetailsRequest = APIExecutor.postHTTP(
			new APIRequest(finalURL, {}),
		);
		return fetchPatientDetailsRequest;
	}

	/**
	 * gets the stidy for DICOM type files
	 * @param {ImageStudyRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	getImageStudyFormDicom(
		data: ImageStudyRequestModel,
	): Promise<Record<string, any>> {
		const imageStudySearchRequest = APIExecutor.postHTTP(
			new APIRequest(APIUrl.IMAGE_STUDY_SEARCH, data),
		);
		return imageStudySearchRequest;
	}

	/**
	 * This method is used to make API call for documentReference/update
	 * @param {DocumentDeleteRequestModel} request model for documentReference/update API
	 * @return {Promise<boolean>}
	 */
	deleteDocumentReference(
		request: DocumentDeleteRequestModel,
	): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			APIExecutor.putHTTP<Record<string, any>>(
				new APIRequest(
					`${APIUrl.DOCUMENT_REFRENCE_UPDATE}${request.getSubject()}`,
					request.getRequestDataObject(),
				),
			)
				.then(() => {
					resolve(true);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	/**
	 * updates or edits the document
	 * @param {UpdateEditDocumentRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	updateEditedDocument(
		data: UpdateEditDocumentRequestModel,
	): Promise<Record<string, any>> {
		const updateEditedDocumentResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.MO_UPDATE_DOCUMENT, data),
		);
		return updateEditedDocumentResponse;
	}

	/**
	 * updates the document visibility
	 * @param {UpdateVisibilityRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	updateVisibility(
		data: UpdateVisibilityRequestModel,
	): Promise<Record<string, any>> {
		const updateVisibilityResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.MO_DOCUMENT_REFERENCE_UPDATE_VISIBILITY, data),
		);
		return updateVisibilityResponse;
	}

	/**
	 * sends document to Ihe repository
	 * @param {SendToIHERepositoryRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	sendToIHERepository(
		data: SendToIHERepositoryRequetsModel,
	): Promise<Record<string, any>> {
		const sendToIHERepositoryResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.MO_DOCUMENT_REFERENCE_SEND_TO_REPOSITORY, data),
		);
		return sendToIHERepositoryResponse;
	}

	/**
	 * sends bulk action request
	 * @param {BulkActionRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	sendBulkRequest(data: BulkActionRequestModel): Promise<Record<string, any>> {
		const sendBulkRequestResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.MO_BULK_DOWNLOAD_CREATE_BULK_ACTION_REQUEST, data),
		);
		return sendBulkRequestResponse;
	}

	/**
	 * cancels dicomDownload requests
	 * @param {number} dicomRequestId
	 * @returns {Promise<Record<string, any>>}
	 */
	cancelDicomDownload(dicomRequestId: number): Promise<Record<string, any>> {
		const finalUrl =
			APIUrl.DOWNLOAD_DIOCM_CANCEL_DOWNLOAD_REQUEST + dicomRequestId;
		const cancelDicomDownloadResponse = APIExecutor.postHTTP(
			new APIRequest(finalUrl, {}),
		);
		return cancelDicomDownloadResponse;
	}

	/**
	 * cancels bulkdownload requests
	 * @param {CancelBulkUploadRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	cancelBulkDownload(
		data: CancelBulkUploadRequestModel,
	): Promise<Record<string, any>> {
		const cancelBulkDownloadResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.MO_BULK_DOWNLOAD_CANCEL_BULK_ACTION_REQUEST, data),
		);
		return cancelBulkDownloadResponse;
	}

	/**
	 * updates document refecernce when document is deleted
	 * @param {DocumentReferenceUpdateRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	updateDocumentReference(
		data: DocumentReferenceUpdateRequestModel,
		subjectID: number,
	): Promise<Record<string, any>> {
		const finalUrl: string = APIUrl.DOCUMENT_REFRENCE_UPDATE + subjectID;
		const updateDocumentReferenceResponse = APIExecutor.putHTTP(
			new APIRequest(finalUrl, data),
		);
		return updateDocumentReferenceResponse;
	}

	/**
	 * fetches Referret documment form the storage
	 * @param {DoumentReferenceDownloadResponseModel} data
	 * @param {number} subjectID
	 * @returns {Promise<Record<string, any>>}
	 */
	downloadUploadDocument(
		data: DocumentReferenceDownloadRequestModel,
		subjectID: number,
	): Promise<Record<string, any>> {
		const finalUrl: string =
			APIUrl.DOCUMENT_REFRENCE_DOWNLOAD_DOCUMENT + subjectID;
		const downloadUploadDocumentResponse = APIExecutor.getHTTP(
			new APIRequest(finalUrl, data, 'arraybuffer'),
		);
		return downloadUploadDocumentResponse;
	}

	/**
	 * questionnaire response showApplication
	 * @param {DoumentReferenceDownloadResponseModel} data
	 * @param {number} subjectID
	 * @returns {Promise<Record<string, any>>}
	 */
	showQuestionnaireResponse(
		data: any,
		appId: number,
	): Promise<Record<string, any>> {
		const finalUrl: string =
			APIUrl.QUESTIONNAIRE_RESPONSE_SHOW_APPLICATION + appId;
		const showQuestionnaireResponseApplication = APIExecutor.postHTTP(
			new APIRequest(finalUrl, data),
		);
		return showQuestionnaireResponseApplication;
	}

	/**
	 * fetche document view url string
	 * @returns {Promise<Record<string, any>>}
	 */
	fetchDocumentViewUrl(id: number): Promise<Record<string, any>> {
		const documentViewUrlRequest = APIExecutor.postHTTP(
			new APIRequest(APIUrl.GENERATE_DOCUMENT_VIEW_URL + id, {}),
		);
		return documentViewUrlRequest;
	}

	/**
	 * sendToEmrOrPacs
	 * @param {SendToEmrOrPacsModel}
	 * @returns {Promise<Record<string, any>>}
	 */
	sendToEmrOrPacs(
		data: SendToEmrOrPacsModel,
		qResponse: boolean,
	): Promise<Record<string, any>> {
		const documentViewUrlRequest = qResponse
			? APIExecutor.postHTTP(
					new APIRequest(APIUrl.SEND_QR_RESPONSE_TO_EMR_OR_PACS, data),
			  )
			: APIExecutor.postHTTP(
					new APIRequest(APIUrl.SEND_DOCUMENT_TO_EMR_OR_PACS, data),
			  );
		return documentViewUrlRequest;
	}

	/**
	 * create bulk action request for doc sharing
	 * @param {ShareDocRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	createBulkRequestForDocSharing(
		data: ShareDocRequestModel,
	): Promise<Record<string, any>> {
		const createBulkRequestForDocSharingResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.MO_BULK_REQUEST_EXPORT, data),
		);
		return createBulkRequestForDocSharingResponse;
	}

	/**
	 * create bulk action request for document
	 * @param {BulkActionRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	createBulkRequestForDocument(
		data: BulkActionRequestModel,
	): Promise<Record<string, any>> {
		const createBulkRequestForDocumentResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.MO_BULK_DOWNLOAD_CREATE_BULK_ACTION_REQUEST, data),
		);
		return createBulkRequestForDocumentResponse;
	}

	/**
	 * fetch default practitioner
	 * @param {BulkActionRequestModel} data
	 * @returns {Promise<Record<string, any>>}
	 */
	fetchDefaultPractitioner(
		data: Record<string, any>,
	): Promise<Record<string, any>> {
		const fetchDefaultPractitioner = APIExecutor.postHTTP(
			new APIRequest(APIUrl.MO_PRACTITIONER_SEARCH, data),
		);
		return fetchDefaultPractitioner;
	}
}

export default DocumentListNetworkRepository;
