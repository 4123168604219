import {strings} from '../../localization/i18n';
import './documents.scss';
import {APIUrl} from '../../common/src/application/network/values/URLInfo';
import NonceService from '../../common/src/application/modules/user/service/Nonce.service';
import Container from 'typedi';
import {useState} from 'react';

const DocDownloadStatus = (props: any) => {
	const NonceServiceContainer = Container.of('NonceService');
	const nonceService = NonceServiceContainer.get(NonceService);

	const bulkDownloadZipFile = (bulkDicomId: number) => {
		nonceService.generateNonceToken().subscribe({
			next: (nonceTokenData: Record<string, any>) => {
				window.open(
					global.ConfigurationHolder.urls?.applicationServer +
						'/minerva/' +
						APIUrl.MO_BULK_DOWNLOAD +
						'?id=' +
						bulkDicomId +
						'&nonceToken=' +
						nonceTokenData.nonceToken,
					'_blank',
				);
			},
		});
	};

	const [showDownloadDiv, setShowDownloadDiv] = useState<boolean>(true);

	const toggleDownloadDiv = () => {
		setShowDownloadDiv(!showDownloadDiv);
	};

	const [showDownloadModal, setShowDownloadModal] = useState<boolean>(true);

	const closeDownloadBox = () => {
		setShowDownloadModal(false);
	};

	return (
		<>
			{props?.documentDownloadModel?.downloadStatusList?.length > 0 &&
				showDownloadModal && (
					<div className="dicomDownloadPopup" id="ddp_Box">
						<div className="popTitle" id="ddp_title">
							<p className="pull-right flip setMargin" id="ddp_crossExpend">
								<button
									id="ddp_arrowDown"
									onClick={toggleDownloadDiv}
									className={
										showDownloadDiv
											? 'myIcon myIconW arrowUpSignBtn arrowUpSignBtnS'
											: 'myIcon myIconW arrowDownSignBtn arrowDownSignBtnS'
									}></button>
								{props?.documentDownloadModel?.downloadReady && (
									<button
										id="ddp_cross2"
										className="myIcon myIconW crossSignBtn crossSignBtnS"
										onClick={() => {
											closeDownloadBox();
										}}></button>
								)}
							</p>
							{!props?.documentDownloadModel?.downloadReady && (
								<h2 id="ddp_titleTextText0">
									{strings(
										'downloadDicom.downloadBoxHeaderTextForDownloadPreparing',
									)}
								</h2>
							)}
							{props?.documentDownloadModel?.downloadReady && (
								<h2 id="ddp_titleTextText1">
									{strings(
										'downloadDicom.downloadBoxHeaderTextForDownloadReady',
									)}
								</h2>
							)}
						</div>

						{showDownloadDiv && (
							<div className="popList" id="ddp_listOuter">
								<ul id="ddp_list">
									{props?.documentDownloadModel?.downloadStatusList?.map(
										(downloadStatus: Record<string, any>, index: number) => (
											<li id={`ddp_list_${index}`}>
												{/*bulk download status*/}
												{downloadStatus.messageType ===
													'BULK_DOWNLOAD_STATUS' && (
													<div className="row">
														{downloadStatus.status === 'PENDING' && (
															<div
																id={`ddp_pending_${index}`}
																className="col-8">
																<span className="fIcon icon-zip_files mr_5"></span>{' '}
																{strings('downloadDicom.pendingStatusText')}
															</div>
														)}
														{downloadStatus.status === 'PROCESSING' && (
															<div
																id={`ddp_processing_${index}`}
																className="col-8">
																<span className="fIcon icon-zip_files mr_5"></span>{' '}
																{strings('downloadDicom.processingStatusText')}
															</div>
														)}
														{downloadStatus.status === 'FAILED' &&
															downloadStatus.retries < 3 && (
																<div
																	id={`ddp_failed1_${index}`}
																	className="col-8">
																	<span className="fIcon icon-zip_files mr_5"></span>{' '}
																	{strings(
																		'downloadDicom.failRetringStatusText',
																	)}
																</div>
															)}
														{downloadStatus.status === 'FAILED' &&
															downloadStatus.retries === 3 && (
																<div
																	id={`ddp_failed2_${index}`}
																	className="col-8">
																	<span className="fIcon icon-zip_files mr_5"></span>{' '}
																	{strings('downloadDicom.failedStatusText')}
																</div>
															)}
														{downloadStatus.status === 'PROCESSED' && (
															<div
																id={`ddp_processed_${index}`}
																className="col-8 c_point"
																onClick={() => {
																	bulkDownloadZipFile(downloadStatus.id);
																}}>
																<span className="fIcon icon-downloaded mr_5"></span>{' '}
																{strings('downloadDicom.processedStatusText')}
															</div>
														)}
														<div
															className="downloadSpinnerPosition"
															id={`ddp_action_${index}`}>
															{(downloadStatus.status !== 'PROCESSED' ||
																downloadStatus.status === 'FAILED') &&
																downloadStatus.retries < 3 && (
																	<span
																		id={`ddp_spinner_${index}`}
																		className="spinner vam"></span>
																)}
														</div>
													</div>
												)}
											</li>
										),
									)}
								</ul>
							</div>
						)}
					</div>
				)}
		</>
	);
};

export default DocDownloadStatus;
