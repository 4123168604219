import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Loader from '../../ui/comps/src/Loader';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import PrivateRoute from '../../PrivateRoute';

const LinkedPatient = lazy(() => import('./components/linkedPatient'));
const LinkedNewPatient = lazy(() => import('./components/LinkNewPatient'));

const accessTo: Array<string> = ['PATIENT'];

const LinkedPatientRoutes = (
	<>
		<Route path="/" element={<PrivateRoute accessTo={accessTo} />}>
			{/* Add authenticated routes under privateRoutes */}
			<Route
				path={RoutePathInfo.linkedPatient.path}
				element={
					<Suspense fallback={<Loader />}>
						<LinkedPatient />
					</Suspense>
				}
			/>
			<Route
				path={RoutePathInfo.linkedNewPatient.path}
				element={
					<Suspense fallback={<Loader />}>
						<LinkedNewPatient />
					</Suspense>
				}
			/>
		</Route>
	</>
);

export default LinkedPatientRoutes;
