import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

export default class FormVerifyOTPRequest implements BaseRequestModel {
	private readonly _countryCode: string | undefined;
	private readonly _phoneNumber: string | undefined;
	private readonly _email: string | undefined;
	private readonly _otp: string;

	constructor({
		countryCode,
		phoneNumber,
		email,
		otp,
	}: {
		countryCode?: string;
		phoneNumber?: string;
		email?: string;
		otp: string;
	}) {
		this._countryCode = countryCode;
		this._phoneNumber = phoneNumber;
		this._email = email;
		this._otp = otp;
	}

	/**
	 * This method is used to get the request body to be sent
	 * in /minerva/moSignUp/verifyOneTimePassword API call for composite form verification
	 * @returns {FormVerifyOTPRequestType}
	 */
	getRequestDataObject(): FormVerifyOTPRequestType {
		const toReturn: FormVerifyOTPRequestType = {
			source: 'FORM_RESPONSE',
			otp: this._otp,
		};
		if (this._countryCode != null && this._phoneNumber != null) {
			toReturn.countryCode = this._countryCode;
			toReturn.phoneNumber = this._phoneNumber;
		} else {
			toReturn.email = this._email;
		}
		return toReturn;
	}
}

export type FormVerifyOTPRequestType = {
	countryCode?: string;
	phoneNumber?: string;
	email?: string;
	source: string;
	otp: string;
};
