import BaseRequestModel from '../../../../../../../network/interface/BaseRequestModel';

export default class FetchZipCountyRequest implements BaseRequestModel {
	private readonly _zipCode: string;

	constructor({zipCode}: FetchZipCountyRequestType) {
		this._zipCode = zipCode;
	}

	/**
	 * This method is used to get the request body to be sent
	 * in /minerva/moCountryDropDown/fetchZipCountyData API call
	 * @returns {FetchZipCountyRequestType}
	 */
	getRequestDataObject(): FetchZipCountyRequestType {
		return {
			zipCode: this._zipCode,
		};
	}
}

export type FetchZipCountyRequestType = {
	zipCode: string;
};
