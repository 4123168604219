import { useTimer } from "react-timer-hook";
import { strings } from "../../../../../localization/i18n";

const Timer = ({expiryTimestamp, setCountDownTimerExpired} : any) => {

const {
    seconds, 
    minutes
    } = useTimer({expiryTimestamp, onExpire: () => setCountDownTimerExpired(true)})

    return <>
             <div>
			 	{strings("resendOTP.timer")} {minutes < 10 ? "0"+minutes : minutes}:{seconds < 10 ? "0"+seconds : seconds}
			</div>
     
    </>

}

export default Timer;