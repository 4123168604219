import NetworkHeaderProvider from '../../../../../utils/network/NetworkHeaderProvider';
import { isValueAvailable } from '../../../utils/StringUtils';
import { getAPIVersion } from '../values/URLInfo';
import { sessionStorageKeys } from '../../../interface/storage/constants/SessionKeys';
import encryptedStorageHelperInstance from '../../../../../storage/EncryptedStorageHelper';
import { APIHeaderKeys } from '../values/HeaderConstants';
import { getBrowserTimezone } from '../../../utils/DateTimeUtils';

/**
 * Get network headers for the Minerva API calls. Headers are generated based on the
 * platform the application is running. The response may contain different headers based
 * on the platform and url.
 * @param url API method of complete URL
 * @return Record<String,String> Returns request headers for API calls.
 */
export function getRequestHeaders(
	url: string,
): Promise<Record<string, string>> {
	return new Promise<Record<string, string>>(resolve => {
		const provider = new NetworkHeaderProvider();
		const recordHeader = provider.getHeaders(url);
		recordHeader['browser-timezone'] = getBrowserTimezone();
		recordHeader[APIHeaderKeys.API_INFO] = getApiInfo(url, provider);
		recordHeader[APIHeaderKeys.USER_AGENT] = global.platform
		encryptedStorageHelperInstance
			.getItem(sessionStorageKeys.AUTH_TOKEN)
			.then(response => {
				if (response != null && isValueAvailable(response)) {
					recordHeader[APIHeaderKeys.X_AUTH_TOKEN] = response;
				}
			})
			.finally(() => {
				resolve(recordHeader);
			});
	});
}

/**
 * Return api-info for the request headers
 * @param url
 * @param provider
 */
export function getApiInfo(
	url: string,
	provider: NetworkHeaderProvider,
): string {
	const apiVersion = getAPIVersion(url);
	const deviceInfo = isValueAvailable(provider.getDeviceInfo())
		? provider.getDeviceInfo()
		: 'appVersion|deviceBrand|deviceModel|deviceScreenResolution|deviceOs|deviceOsVersion|deviceNetworkProvider|deviceNetworkType';
	const apiInfo = `${apiVersion}|${deviceInfo}`;
	return apiInfo;
}

/**
 * Contract for fetching headers which are specific to platforms.
 */
interface HeaderProvider {
	/**
	 * Headers specific to platforms
	 * @param url
	 */
	getHeaders(url: string): Record<string, string>;

	/**
	 * Returns the device info as per below format
	 * appVersion|deviceBrand|deviceModel|deviceScreenResolution|deviceOs|deviceOsVersion|deviceNetworkProvider|deviceNetworkType
	 */
	getDeviceInfo(): string;

	/**
	 * Returns the current connection status of the internet
	 */
	getConnectionStatus(): Promise<ConnectionStatus>;
}
export type ConnectionStatus = {
	isConnected: boolean | null;
	isInternetReachable: boolean | null;
};
export default HeaderProvider;
