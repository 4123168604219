import {FieldType} from '../types/FieldTypeEnum';
import {strings} from '../../../../../localization/i18n';
import ErrorText from './components/ErrorText';
import HelpText from './components/HelpText';
import Label from './components/Label';
import _ from 'lodash';

const InputComponent = (props: any): JSX.Element => {
	const {
		fieldData,
		register,
		errors,
		callbackHandler,
		dynamicConstraints,
		configProvider,
		isViewOnlyMode,
	} = props;

	const {label, name, constraints, placeholder, type, attributeCssClassName} =
		fieldData;

	const className = attributeCssClassName;

	/*
	 * TODO: fetch these from a global config
	 */
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const identityRegex = null;

	/*
	 * use default email regex if input type is email
	 * else - use no regex
	 */
	let regExp = type === 'email' ? emailRegex : identityRegex;

	return (
		<>
			<div
				id={`div_om_inputComp_${name}`}
				className={className ? `form-item ${className}` : 'form-item'}>
				<div id={`div_inputComp_${name}`} className="form-group">
					<Label
						htmlFor={name}
						content={label}
						configProvider={configProvider}
						required={dynamicConstraints?.required ?? false}
					/>
					<input
						type="text"
						id={name}
						aria-describedby={`error_${name} ${name}_helpText_${name}`}
						disabled={
							isViewOnlyMode === true
								? isViewOnlyMode
								: dynamicConstraints?.disabled ?? false
						}
						placeholder={
							placeholder
								? strings(placeholder)
								: strings(`form.placeholder.${type}`, {
										label: strings(label),
								  })
						}
						{...register(`${name}`, {
							required: {
								value: dynamicConstraints?.required ?? false,
								message: constraints?.required?.message ?? '',
							},
							maxLength: {
								value: parseInt(constraints?.maxLength?.value) ?? null,
								message: constraints?.maxLength?.message ?? '',
							},
							minLength: {
								value: parseInt(constraints?.minLength?.value) ?? null,
								message: constraints?.minLength?.message ?? '',
							},
							shouldUnregister: true,
							value: dynamicConstraints?.defaultValue,
							pattern: {
								value: constraints?.pattern?.value
									? new RegExp(constraints?.pattern?.value)
									: regExp,
								message: constraints?.pattern?.message ?? '',
							},
							...(callbackHandler?.onChange != null && {
								onChange: (e: any) => callbackHandler.onChange(e),
							}),
							...(callbackHandler?.onBlur != null && {
								onBlur: () => callbackHandler.onBlur(),
							}),
						})}
						className="form-control"
					/>
					{_.get(errors, name) == null && (
						<HelpText content={dynamicConstraints?.helpText} name={name} />
					)}
					{errors != null && (
						<ErrorText
							errorObject={_.get(errors, name)}
							label={label}
							name={name}
							fieldType={FieldType.TEXT}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default InputComponent;
