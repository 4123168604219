import {Container, Service} from 'typedi';

import APIExecutor, {APIRequest} from '../../../network/APIExecutor';
import {APIUrl} from '../../../network/values/URLInfo';
import SetAgreementStatusRequestModel from '../models/SetAgreementStatusRequestModel';
import Agreement from '../models/Agreement';
import AgreementRepositoryInterface from './interfaces/AgreementRepository.interface';
import UserService from '../../user/service/User.service';
import encryptedStorageHelperInstance from '../../../../../../storage/EncryptedStorageHelper';
import {sessionStorageKeys} from '../../../../interface/storage/constants/SessionKeys';

@Service()
class AgreementRepository implements AgreementRepositoryInterface {
	private userService: UserService;
	constructor() {
		this.userService = Container.get(UserService);
	}
	/**
	 * This method is used to fetch all agreements from storage
	 * @param {string | undefined} languageTag to fetch language specific agreements in case getUserDetailsFromCache is false
	 * @param {string | undefined} deviceUID only sent in case of mobile application.
	 * @param {boolean} getUserDetailsFromCache denotes whether fresh API call has to be made
	 * @return {Promise<Array<Agreement>>}
	 */
	fetchAgreements(
		getUserDetailsFromCache = false,
		languageTag?: string,
		deviceUID?: string,
	): Promise<Array<Agreement>> {
		return new Promise<Array<Agreement>>((resolve, reject) => {
			this.userService
				.fetchAgreementsForUser(getUserDetailsFromCache, languageTag, deviceUID)
				.subscribe({
					next: agreements => {
						if (agreements != null) {
							resolve(agreements);
						} else {
							reject(new Error('User details is not fetched yet.'));
						}
					},
					error: error => {
						reject(error);
					},
				});
		});
	}

	/**
	 * This method is used to fetch all accepted agreements
	 * @return {Promise<Record<string, any>>} containing status, response body and response headers
	 */
	fetchAllReadAgreements(): Promise<Record<string, any>> {
		return APIExecutor.getHTTP(
			new APIRequest(APIUrl.FETCH_AGREEMENTS_BY_USER, {}),
		);
	}

	/**
	 * This method is used to save all accepted agreements
	 * @return {Promise<void>}
	 */
	saveAllReadAgreements(readAgreementList?: Array<Agreement>): Promise<void> {
		return encryptedStorageHelperInstance.setItem(
			sessionStorageKeys.READ_AGREEMENTS,
			readAgreementList,
		);
	}

	/**
	 * This method is used to accept or reject a particular agreement
	 * @param setAgreementStatusRequestModel
	 * @return {Promise<Record<string, any>>} containing status, response body and response headers
	 */
	setAgreementStatus(
		setAgreementStatusRequestModel: SetAgreementStatusRequestModel,
		headers?: Record<string, any>,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.SET_AGREEMENT_AS_READ,
				setAgreementStatusRequestModel.getRequestDataObject(),
				undefined,
				headers,
			),
		);
	}

	fetchAgreement(agreementId: string | undefined | null): Promise<Agreement> {
		//TODO fetch agreement from Cache.
		return new Promise<Agreement>(resolve => {
			const agreement = new Agreement({});
			resolve(agreement);
		});
	}
}

export default AgreementRepository;
