export enum AuditEnum {
    PATIENT = 'patient',
    USERNAME = 'username',
    ON_DATE = 'onDate',
    TO_FROM_DATE = 'FormDate',
    TO_DATE = 'toDate',
    FROM_DATE='fromDate',
    AUDIT_TYPE = 'auditType',
    ACTION = 'action',
    ACTION_ON_USER= 'actionOnUser'
}