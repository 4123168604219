import BaseRequestModel from '../../../network/interface/BaseRequestModel';
import BaseResponseModel from '../../../network/interface/BaseResponseModel';
import NetworkRequestMethodEnum from '../../../../enums/NetworkRequestMethodEnum';

export default class NetworkCallProvider<
	REQ extends BaseRequestModel,
	RES extends BaseResponseModel,
	T,
> {
	private readonly _url: string;
	private readonly _headers: Record<string, any>;
	private readonly _method: NetworkRequestMethodEnum;
	private _request: REQ | undefined;
	private _response: RES | undefined;
	private _isSuccessful: boolean | undefined;
	private _errorMessage: string | undefined;
	responseParser: (_response: {
		data: Record<string, any>;
		status: number;
		headers: Record<string, any>;
	}) => {
		response: RES;
		isSuccessful: boolean;
		errorMessage?: string;
		successMessage?: string;
	};
	requestGenerator: (_data: T) => REQ;

	constructor(
		url: string,
		headers: Record<string, any>,
		method: NetworkRequestMethodEnum,
		requestGenerator: (_data: T) => REQ,
		responseParser: (_response: {
			data: Record<string, any>;
			status: number;
			headers: Record<string, any>;
		}) => {
			response: RES;
			isSuccessful: boolean;
			errorMessage?: string;
			successMessage?: string;
		},
	) {
		this.requestGenerator = requestGenerator;
		this.responseParser = responseParser;
		this._url = url;
		this._headers = headers;
		this._method = method;
	}

	get request(): REQ | undefined {
		return this._request;
	}

	get response(): RES | undefined {
		return this._response;
	}

	get url(): string {
		return this._url;
	}

	get headers(): Record<string, any> {
		return this._headers;
	}

	get method(): NetworkRequestMethodEnum {
		return this._method;
	}

	get isSuccessful(): boolean | undefined {
		return this._isSuccessful;
	}

	get errorMessage(): string | undefined {
		return this._errorMessage;
	}

	/**
	 * This method is used to generate the request model to be
	 * used for API call.
	 * @param _data
	 */
	setRequestModel(_data: T): void {
		this._request = this.requestGenerator(_data);
	}

	/**
	 * This method is used to parse and save the response model,
	 * successful flag and error message if any.
	 * @param _response
	 */
	setResponseModel(_response: {
		data: Record<string, any>;
		status: number;
		headers: Record<string, any>;
	}): void {
		const {response, isSuccessful, errorMessage} =
			this.responseParser(_response);
		this._response = response;
		this._isSuccessful = isSuccessful;
		this._errorMessage = errorMessage;
	}
}
