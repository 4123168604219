import {FieldType} from '../types/FieldTypeEnum';
import {strings} from '../../../../../localization/i18n';
import ErrorText from './components/ErrorText';
import HelpText from './components/HelpText';
import Label from './components/Label';
import _ from 'lodash';

const NumberComponent = (props: any): JSX.Element => {
	const {
		fieldData,
		register,
		errors,
		callbackHandler,
		dynamicConstraints,
		configProvider,
		isViewOnlyMode,
	} = props;

	const {
		label,
		name,
		constraints,
		placeholder,
		helpText,
		type,
		isDecimalAllowed = true,
		attributeCssClassName,
	} = fieldData;

	const className = attributeCssClassName;

	const onKeypressHandler = (event: any) => {
		if (!isDecimalAllowed) {
			return event.charCode >= 48 && event.charCode <= 57
				? true
				: event.preventDefault();
		} else {
			return true;
		}
	};

	const getMinMsg = (): any => {
		if (constraints?.min?.message) {
			return strings(constraints?.min?.message);
		} else if (constraints?.min?.value && constraints?.max?.value) {
			// show combined message
			return strings('forms.attributes.errorText.minMaxMsg', {
				min: constraints?.min?.value,
				max: constraints?.max.value,
			});
		} else if (constraints?.min?.value) {
			return strings('forms.attributes.errorText.minMsg', {
				min: constraints?.min?.value,
			});
		} else {
			return '';
		}
	};

	const getMaxMsg = (): any => {
		if (constraints?.max?.message) {
			return strings(constraints?.max?.message);
		} else if (constraints?.min?.value && constraints?.max?.value) {
			// show combined message
			return strings('forms.attributes.errorText.minMaxMsg', {
				min: constraints?.min?.value,
				max: constraints?.max.value,
			});
		} else if (constraints?.max?.value) {
			return strings('forms.attributes.errorText.maxMsg', {
				max: constraints?.max?.value,
			});
		} else {
			return '';
		}
	};

	return (
		<>
			<div
				id={`div_om_numberComp_${name}`}
				className={className ? `form-item ${className}` : 'form-item'}>
				<div id={`div_numberComp_${name}`} className="form-group">
					<Label
						htmlFor={name}
						content={label}
						required={dynamicConstraints?.required ?? false}
						configProvider={configProvider}
					/>
					<input
						type="number"
						id={name}
						aria-describedby={`error_${name} ${name}_helpText_${name}`}
						disabled={
							isViewOnlyMode === true
								? isViewOnlyMode
								: dynamicConstraints?.disabled ?? false
						}
						placeholder={
							placeholder
								? strings(placeholder)
								: strings(`form.placeholder.${type}`, {
										label: strings(label),
								  })
						}
						{...register(`${name}`, {
							valueAsNumber: false,
							required: {
								value: dynamicConstraints?.required ?? false,
								message: strings(constraints?.required?.message) ?? '',
							},
							maxLength: {
								value: parseInt(constraints?.maxLength?.value) ?? null,
								message: strings(constraints?.maxLength?.message) ?? '',
							},
							minLength: {
								value: parseInt(constraints?.minLength?.value) ?? null,
								message: strings(constraints?.minLength?.message) ?? '',
							},
							min: {
								value: Number(constraints?.min?.value) ?? null,
								message: getMinMsg(),
							},
							max: {
								value: Number(constraints?.max?.value) ?? null,
								message: getMaxMsg(),
							},
							shouldUnregister: true,
							value: dynamicConstraints?.defaultValue,
						})}
						className="form-control"
						{...callbackHandler}
						onKeyPress={event => onKeypressHandler(event)}
					/>

					{_.get(errors, name) == null && (
						<HelpText content={strings(helpText)} name={name} />
					)}
					{errors != null && (
						<ErrorText
							errorObject={_.get(errors, name)}
							name={name}
							fieldType={FieldType.TEXT}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default NumberComponent;
