export enum DocListEnum {
	ENTERED_IN_ERROR = 'entered-in-error',
	INACTIVE = 'inactive',
	BULK_DOWNLOAD = 'BULK_DOWNLOAD',
	BULK_DELETE = 'BULK_DELETE',
	BULK_SEND_TO_EMR = 'BULK_SEND_TO_EMR',
	PATIENT = 'patient',
	PHYSICIAN = 'physician',
	DICOM = 'DICOM',
	HYDMEDIA = 'HYDMEDIA',
}
