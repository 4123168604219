import BaseResponseModel from '../../../../network/interface/BaseResponseModel';
import {DynamicFormDataV2} from '../../../../dynamicFormUtil/types/DynamicForm.types';

class DocumentListConfigResponseModel implements BaseResponseModel {
	private readonly bulkActionOptionsAllowed?: Array<string>;
	private readonly uploadedDocumentsTypes?: Array<string>;
	private readonly defaultDocumentStatus?: Array<string>;
	private readonly defaultDeletedDocumentStatus?: Array<string>;
	private readonly uploaderSourceTypes?: Array<any>;
	private readonly xds?: XDS;
	private readonly mdmDocumentReviewTypes?: Array<any>;
	private readonly mdmDocumentConfidentialityStatus?: Array<string>;
	private readonly showDocumentDeleteHint?: boolean;
	private readonly docRefSearchFilters?: DocumentRefSearchFilter;
	private readonly documentShareFormData?: DynamicFormDataV2;

	constructor({
		bulkActionOptionsAllowed,
		uploadedDocumentsTypes,
		defaultDocumentStatus,
		defaultDeletedDocumentStatus,
		uploaderSourceTypes,
		xds,
		mdmDocumentReviewTypes,
		mdmDocumentConfidentialityStatus,
		showDocumentDeleteHint,
		docRefSearchFilters,
		documentShareFormData,
	}: DocumentListConfigData) {
		this.bulkActionOptionsAllowed = bulkActionOptionsAllowed;
		this.uploadedDocumentsTypes = uploadedDocumentsTypes;
		this.defaultDocumentStatus = defaultDocumentStatus;
		this.defaultDeletedDocumentStatus = defaultDeletedDocumentStatus;
		this.uploaderSourceTypes = uploaderSourceTypes;
		this.xds = xds;
		this.mdmDocumentReviewTypes = mdmDocumentReviewTypes;
		this.mdmDocumentConfidentialityStatus = mdmDocumentConfidentialityStatus;
		this.showDocumentDeleteHint = showDocumentDeleteHint;
		this.docRefSearchFilters = docRefSearchFilters;
		this.documentShareFormData = documentShareFormData;
	}

	/**
	 * returns document list config response
	 * @returns {DocumentListConfigData}
	 */
	getResponseDataObject(): DocumentListConfigData {
		return {
			bulkActionOptionsAllowed: this.bulkActionOptionsAllowed,
			uploadedDocumentsTypes: this.uploadedDocumentsTypes,
			defaultDocumentStatus: this.defaultDocumentStatus,
			defaultDeletedDocumentStatus: this.defaultDeletedDocumentStatus,
			uploaderSourceTypes: this.uploaderSourceTypes,
			xds: this.xds,
			mdmDocumentReviewTypes: this.mdmDocumentReviewTypes,
			mdmDocumentConfidentialityStatus: this.mdmDocumentConfidentialityStatus,
			showDocumentDeleteHint: this.showDocumentDeleteHint,
			docRefSearchFilters: this.docRefSearchFilters,
			documentShareFormData: this.documentShareFormData,
		};
	}
}

type UploaderSourceType = {
	PATIENT?: Array<any>;
	PRACTITIONER?: Array<string>;
	OTHERS?: Array<string>;
};

type XDS = {
	enabled?: boolean;
	documentTypes?: Array<string>;
	mimeTypes?: Array<string>;
};

type RefSearchType = {
	visible?: boolean;
};
type DocumentRefSearchFilter = {
	documentType?: RefSearchType;
	surgeryDate?: RefSearchType;
	uploadedDate?: RefSearchType;
	condition?: RefSearchType;
	physician?: RefSearchType;
	encounter?: RefSearchType;
	sourceType?: RefSearchType;
	visibility?: RefSearchType;
	docStatus?: RefSearchType;
};
export type DocumentListConfigData = {
	bulkActionOptionsAllowed?: Array<string>;
	uploadedDocumentsTypes?: Array<string>;
	defaultDocumentStatus?: Array<string>;
	defaultDeletedDocumentStatus?: Array<string>;
	uploaderSourceTypes?: Array<any>;
	xds?: XDS;
	mdmDocumentReviewTypes?: Array<any>;
	mdmDocumentConfidentialityStatus?: Array<string>;
	showDocumentDeleteHint?: boolean;
	docRefSearchFilters?: DocumentRefSearchFilter;
	documentShareFormData?: DynamicFormDataV2;
};

export default DocumentListConfigResponseModel;
