import AsyncSelect from 'react-select/async';
import {Controller} from 'react-hook-form';
import {strings} from '../../../../../../localization/i18n';
import {loadOptions} from './helper';
import Label from '../components/Label';
import ErrorText from '../components/ErrorText';
import HelpText from '../components/HelpText';
import {FieldType} from '../../types/FieldTypeEnum';
import {useEffect} from 'react';
import {isValueAvailable} from '../../../utils/uiCompsUtils';
import _ from 'lodash';

const RemoteDropdownComponent = (props: any): JSX.Element => {
	const {
		fieldData,
		control,
		formModel,
		remoteDropdownRequestModel,
		configProvider,
		requestExecutor,
		errors,
		dynamicConstraints,
		isViewOnlyMode,
	} = props;
	const {
		label,
		name,
		isMulti,
		placeholder,
		defaultValue,
		disabled,
		apiUrl,
		valueField,
		options,
		accessString,
		constraints,
		attributeCssClassName,
		joinCharacter,
	} = fieldData;

	const className = attributeCssClassName;

	// options for reference
	/* const _options = {
		value: 'title.value.value',
		label: [{'User': 'userId.value'}, {'Body :^)': 'body'}],
	}; */

	return (
		<>
			<div
				id={`div_om_remoteDropDownComp_${name}`}
				className={className ? `form-item ${className}` : 'form-item'}>
				<div id={`div_remoteDropDownComp_${name}`} className="form-group">
					<Label
						htmlFor={'select_' + name}
						content={label}
						required={dynamicConstraints?.required ?? false}
						configProvider={configProvider}
					/>
					<Controller
						name={name}
						control={control}
						defaultValue={formModel?.[name] ?? defaultValue}
						shouldUnregister={true}
						rules={{
							required: {
								value: dynamicConstraints?.required ?? false,
								message: constraints?.required?.message ?? '',
							},
						}}
						render={({field}) => (
							<AsyncSelect
								inputId={'select_' + name}
								{...field}
								placeholder={strings(placeholder)}
								isMulti={isMulti ?? false}
								onBlur={field.onBlur}
								aria-labelledby={`error_${name} ${name}_helpText_${name}`}
								// needs to be searchable in order to work as intended
								isSearchable={true}
								isDisabled={
									isViewOnlyMode ? true : dynamicConstraints?.disabled
								}
								cacheOptions
								defaultOptions={true}
								loadOptions={loadOptions(
									apiUrl,
									valueField,
									options,
									accessString,
									remoteDropdownRequestModel
										? remoteDropdownRequestModel
										: null,
									configProvider,
									requestExecutor,
									joinCharacter,
								)}
							/>
						)}
					/>
					{!_.get(errors, name) && (
						<HelpText content={dynamicConstraints?.helpText} name={name} />
					)}
					<ErrorText
						errorObject={_.get(errors, name)}
						name={name}
						label={label}
						fieldType={FieldType.OPTION}
					/>
				</div>
			</div>
		</>
	);
};

export default RemoteDropdownComponent;
