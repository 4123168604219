import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {NotificationMessage} from '../models/NotificationMessage';
import {LoaderProps, LoaderType} from '../models/Loader';
import {NavigationEvent} from '../models/NavigationEvent';
import FormDataViewModel from './FormDataViewModel';

export default abstract class BasicSubscribersViewModel<
	T1,
	T2,
> extends FormDataViewModel {
	protected readonly notificationMessageSubject: Subject<NotificationMessage>;
	protected readonly loaderSubject: BehaviorSubject<LoaderProps>;
	// Used for handling navigation when the agreements are accepted or declined,
	// and user should navigate to a screen based on given event
	protected readonly navigationSubject: Subject<NavigationEvent<T1, T2>>;

	protected constructor() {
		super();
		this.notificationMessageSubject = new Subject<NotificationMessage>();
		this.loaderSubject = new BehaviorSubject<LoaderProps>({
			isToShowLoader: false,
			type: LoaderType.OnScreen,
		});
		this.navigationSubject = new Subject<NavigationEvent<T1, T2>>();
	}

	/**
	 * This method is used to get the observer where notification messages are posted
	 * returns Observable<NotificationMessage>
	 */
	public fetchNotificationMessageObservable(): Observable<NotificationMessage> {
		return this.notificationMessageSubject.asObservable();
	}

	/**
	 * This method is used to get the observer where loader information is posted
	 * returns Observable<Loader>
	 */
	public fetchLoaderObservable(): Observable<LoaderProps> {
		return this.loaderSubject.asObservable();
	}

	/**
	 * This method is used to get the observer where navigation information is posted
	 * returns Observable<AgreementNavigationEvents>
	 */
	public fetchNavigationObservable(): Observable<NavigationEvent<T1, T2>> {
		return this.navigationSubject.asObservable();
	}
}
