import BaseRequestModel from "../../../../network/interface/BaseRequestModel";

class DocumentReferenceDownloadRequestModel implements BaseRequestModel {
    private readonly docRefId?: number
    private readonly constraints?: DocumentReferenceDownloadConstraints

    constructor({
                    docRefId,
                    constraints
                }: DocumentReferenceDownloadRequestData) {
        this.docRefId = docRefId;
        this.constraints = constraints
    }

    /**
     * returns document reference download request
     * @returns {DocumentReferenceDownloadRequestData}
     */
    getRequestDataObject(): DocumentReferenceDownloadRequestData {
        return {
            docRefId: this.docRefId,
            constraints: this.constraints
        }
    }
}

export type DocumentReferenceDownloadRequestData = {
    docRefId?: number,
    constraints?: DocumentReferenceDownloadConstraints
}

export type DocumentReferenceDownloadConstraints = {
    authParams?: string
    subject?:string
}

export default DocumentReferenceDownloadRequestModel