import BaseResponseModel from '../../../../network/interface/BaseResponseModel';

class NotificationSearchResponseModel implements BaseResponseModel {
	private readonly list?: AppNotification[];
	private readonly totalCount?: number;
	constructor({list, totalCount}: NotificationResponse) {
		this.list = list;
		this.totalCount = totalCount;
	}

	/**
	 * return response data from Reason search Notification
	 * @returns {NotificationResponse}
	 */
	getResponseDataObject(): NotificationResponse {
		return {
			list: this.list,
			totalCount: this.totalCount,
		};
	}
}

export type NotificationResponse = {
	list?: AppNotification[];
	totalCount?: number;
};

export type AppNotification = {
	auditRetriesLeft?: number;
	auditStatus?: string;
	countryCode?: string;
	dateCreatedCopy?: string;
	failedRetries?: number;
	id?: number;
	isMobilePushAvailable?: boolean;
	lastUpdated?: string;
	message?: string;
	notificationPreference?: string;
	notificationType?: NotificationType;
	overrideUserPreference?: boolean;
	priority?: NotificationPriority;
	read?: boolean;
	recipientType?: NotificationRecipient;
	resource?: NotificationResource;
	status?: NotificationStatus;
	subject?: string;
	toEmail?: string;
	toPhoneNumber?: string;
	toUser?: number;
	userLanguage?: string;
	userName?: string;
	userType?: string;
	version?: number;
	patientId?: string;
	keyVariables?: KeyVariables;
};

type KeyVariables = {
	messageVariables?: Record<string, any>;
};

type NotificationType = {
	declaringClass?: string;
	inAppMessage?: boolean;
	key?: string;
	value?: string;
};

type NotificationPriority = {
	declaringClass?: string;
	key?: string;
	value?: string;
};

type NotificationRecipient = {
	declaringClass?: string;
	key?: string;
	value?: string;
};
type NotificationResource = {
	name?: string;
	_id?: number;
	transitiveResources?: TransitiveResources;
	event?: string;
	location?: string;
	status?: string;
};

type TransitiveResources = {
	url?: string;
	diagnosticOrder?: string | number;
	diagnosticOrderId?: string | number;
	patient?: string;
	_id?: string;
	readOnlyNotification?: string;
};
type NotificationStatus = {
	declaringClass?: string;
	key?: string;
	value?: string;
};
export default NotificationSearchResponseModel;
