import _ from 'lodash';
import { FieldType } from '../types/FieldTypeEnum';
import ErrorText from './components/ErrorText';
import HelpText from './components/HelpText';
import Label from './components/Label';

export default function CheckBoxComponent(props: any) {
	const {
		fieldData,
		register,
		errors,
		callbackHandler,
		dynamicConstraints,
		isViewOnlyMode,
		configProvider,
	} = props;

	const { label, name, constraints } = fieldData;

	return (
		<div
			id={`div_om_checkBoxComp_${name}`}
			className={fieldData?.attributeCssClassName}>
			<div id={`div_checkBoxComp_${name}`} className="form-group">
				<fieldset
					className="form-check-inline"
					disabled={isViewOnlyMode ? true : dynamicConstraints?.disabled}>
					<input
						type="checkbox"
						id={name}
						{...register(`${name}`, {
							...constraints,
							shouldUnregister: true,
						})}
						aria-describedby={`error_${name} ${name}_helpText_${name}`}
						defaultChecked={dynamicConstraints?.defaultValue ?? false}
						className="form-control form-check-input"
						{...callbackHandler}
					/>
					<Label
						htmlFor={name}
						content={label}
						className={
							isViewOnlyMode
								? 'form-check-label disabled'
								: dynamicConstraints?.disabled
									? 'form-check-label disabled'
									: 'form-check-label'
						}
						configProvider={configProvider}
					/>
				</fieldset>
				{_.get(errors, name) == null && (
					<HelpText content={dynamicConstraints?.helpText} name={name} />
				)}
				{errors != null && <ErrorText
					errorObject={_.get(errors, name)}
					name={name}
					label={label}
					fieldType={FieldType.OPTION}
				/>}
			</div>
		</div>
	);
}
