import {Subject} from 'rxjs';
import {DynamicFormDataV2} from '../../../dynamicFormUtil/types/DynamicForm.types';

abstract class FormDataViewModel {
	protected constructor() {
		this.configModelSubject = new Subject<DynamicFormDataV2>();
	}

	protected readonly configModelSubject: Subject<DynamicFormDataV2>;

	public getFormConfigModel(): Subject<DynamicFormDataV2> {
		return this.configModelSubject;
	}
}

export default FormDataViewModel;
