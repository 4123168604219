import {Observable, Subject} from 'rxjs';
import { NotificationProps } from '../interface/INotification';

const notificationStateSubject = new Subject<NotificationProps>();
export const notificationStateObserver = {
	/**
	 * This is used to publish values to the subscribers.
	 * @param {NotificationProps} notificationData - This observer lets system know when to show / hide a toast / notification message.
	 */
	publish(notificationData: NotificationProps) {
		return notificationStateSubject.next(notificationData);
	},

	/**
	 * This function is used to listen to events by subscribing to the notificationStateSubject.
	 */
	observable: (): Observable<NotificationProps> =>
    notificationStateSubject.asObservable() as Observable<NotificationProps>,
};
