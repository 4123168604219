import BaseResponseModel from "../../../../network/interface/BaseResponseModel";

class DocumentReferenceSearchResponseModel implements BaseResponseModel {
    private readonly docRefType?: Array<string>;
    private readonly docRefPractitioner?: Array<any>;
    private readonly docRefEncounter?: Array<any>;
    private readonly docRefCondition?: Array<any>;
    private readonly docRefServiceRequest?: Array<any>;
    private readonly questionnaireResponse?: Array<any>;
    private readonly careFacility?: Array<any>;
    private readonly docRefTask?: Array<any>;
    private readonly msg?: string;
    private readonly status?: string;
    private readonly httpStatusCode?: number


    constructor({
                    docRefType,
                    docRefPractitioner,
                    docRefEncounter,
                    docRefCondition,
                    docRefServiceRequest,
                    questionnaireResponse,
                    careFacility,
                    docRefTask,
                    msg,
                    status,
                    httpStatusCode
                }: DocumentReferenceSearchResponseData) {
        this.docRefType = docRefType;
        this.docRefPractitioner = docRefPractitioner;
        this.docRefEncounter = docRefEncounter;
        this.docRefCondition = docRefCondition;
        this.docRefServiceRequest = docRefServiceRequest;
        this.questionnaireResponse = questionnaireResponse;
        this.careFacility = careFacility;
        this.docRefTask = docRefTask;
        this.msg = msg;
        this.status = status;
        this.httpStatusCode = httpStatusCode;
    }

    /**
     * returns document reference search data response
     * @returns {DocumentReferenceSearchResponseData}
     */
    getResponseDataObject(): DocumentReferenceSearchResponseData {
        return {
            docRefType: this.docRefType,
            docRefPractitioner: this.docRefPractitioner,
            docRefEncounter: this.docRefEncounter,
            docRefCondition: this.docRefCondition,
            docRefServiceRequest: this.docRefServiceRequest,
            questionnaireResponse: this.questionnaireResponse,
            careFacility: this.careFacility,
            docRefTask: this.docRefTask,
            msg: this.msg,
            status: this.status,
            httpStatusCode: this.httpStatusCode
        }
    }
}


export type DocumentReferenceSearchResponseData = {
    docRefType?: Array<string>;
    docRefPractitioner?: Array<any>;
    docRefEncounter?: Array<any>;
    docRefCondition?: Array<any>;
    docRefServiceRequest?: Array<any>;
    questionnaireResponse?: Array<any>;
    careFacility?: Array<any>;
    docRefTask?: Array<any>;
    msg?: string;
    status?: string;
    httpStatusCode?: number
}

export default DocumentReferenceSearchResponseModel