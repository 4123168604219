import BaseResponseModel from "../../../../network/interface/BaseResponseModel";

class PatientActionBannerResponseModel implements BaseResponseModel{
    private readonly listItem?:Array<string>;
    private readonly patientBannerMenuItems?:Array<Record<string, any>>;

    constructor({listItem, patientBannerMenuItems}:PatientActionBannerResponseModelType) {
        this.listItem = listItem;
        this.patientBannerMenuItems = patientBannerMenuItems
    }

    /**
     * returns patient banner action response
     * @returns {PatientActionBannerResponseModelType}
     */
    getResponseDataObject():PatientActionBannerResponseModelType{
        return {
            listItem:this.listItem,
            patientBannerMenuItems:this.patientBannerMenuItems
        }
    }
}

export type PatientActionBannerResponseModelType = {
    listItem?:Array<string>
    patientBannerMenuItems?:Array<Record<string, any>>
}

export default PatientActionBannerResponseModel