import BaseResponseModel from "../../../../network/interface/BaseResponseModel";

class DocumentReferencUpdateResponseModel implements BaseResponseModel {
    private readonly extension?: Array<DocumentReferencUpdateResponseExtension>;
    private readonly subject?: SubJectData;
    private readonly description?: string;
    private readonly type?: TypeKeyData;
    private readonly lastUpdated?: string;
    private readonly authorPractitionerRole?: Array<any>;
    private readonly id?: number;
    private readonly relatesTo?: Array<any>;
    private readonly authorRelatedPerson?: Array<any>;
    private readonly identifier?: Array<any>;
    private readonly authorDevice?: Array<any>;
    private readonly visible?: boolean;
    private readonly indexed?: IndexedType;
    private readonly author?: Array<any>;
    private readonly authorOrganization?: Array<any>;
    private readonly contentDocument?: Array<ContentDocumenttype>;
    private readonly securityLabel?: Array<any>;
    private readonly status?: string;
    private readonly masterIdentifier?: MasterIdentifierData;
    private readonly documentClass?: Array<any>;
    private readonly authorPatient?: Array<any>;
    private readonly resourceType?: string;

    constructor({
                    extension,
                    subject,
                    description,
                    type,
                    lastUpdated,
                    authorPractitionerRole,
                    id,
                    relatesTo,
                    authorRelatedPerson,
                    identifier,
                    authorDevice,
                    visible,
                    indexed,
                    author,
                    authorOrganization,
                    contentDocument,
                    securityLabel,
                    status,
                    masterIdentifier,
                    documentClass,
                    authorPatient,
                    resourceType
                }: DocumentReferencUpdateResponseData) {
        this.extension = extension;
        this.subject = subject;
        this.description = description;
        this.type = type;
        this.lastUpdated = lastUpdated;
        this.authorPractitionerRole = authorPractitionerRole;
        this.id = id;
        this.relatesTo = relatesTo;
        this.authorRelatedPerson = authorRelatedPerson;
        this.identifier = identifier;
        this.authorDevice = authorDevice;
        this.visible = visible;
        this.indexed = indexed;
        this.author = author;
        this.authorOrganization = authorOrganization;
        this.contentDocument = contentDocument;
        this.securityLabel = securityLabel;
        this.status = status;
        this.masterIdentifier = masterIdentifier;
        this.documentClass = documentClass;
        this.authorPatient = authorPatient;
        this.resourceType = resourceType;
    }

    /**
     * returns document reference update response
     * @returns {DocumentReferencUpdateResponseData}
     */
    getResponseDataObject(): DocumentReferencUpdateResponseData {
        return {
            extension: this.extension,
            subject: this.subject,
            description: this.description,
            type: this.type,
            lastUpdated: this.lastUpdated,
            authorPractitionerRole: this.authorPractitionerRole,
            id: this.id,
            relatesTo: this.relatesTo,
            authorRelatedPerson: this.authorRelatedPerson,
            identifier: this.identifier,
            authorDevice: this.authorDevice,
            visible: this.visible,
            indexed: this.indexed,
            author: this.author,
            authorOrganization: this.authorOrganization,
            contentDocument: this.contentDocument,
            securityLabel: this.securityLabel,
            status: this.status,
            masterIdentifier: this.masterIdentifier,
            documentClass: this.documentClass,
            authorPatient: this.authorPatient,
            resourceType: this.resourceType
        }
    }

}

export type DocumentReferencUpdateResponseData = {
    extension?: Array<DocumentReferencUpdateResponseExtension>;
    subject?: SubJectData;
    description?: string;
    type?: TypeKeyData;
    lastUpdated?: string;
    authorPractitionerRole?: Array<any>;
    id?: number;
    relatesTo?: Array<any>;
    authorRelatedPerson?: Array<any>;
    identifier?: Array<any>;
    authorDevice?: Array<any>;
    visible?: boolean;
    indexed?: IndexedType;
    author?: Array<any>;
    authorOrganization?: Array<any>;
    contentDocument?: Array<ContentDocumenttype>;
    securityLabel?: Array<any>;
    masterIdentifier?: MasterIdentifierData;
    documentClass?: Array<any>;
    authorPatient?: Array<any>;
    resourceType?: string;
    status?: string
}

export type DocumentReferencUpdateResponseExtension = {
    value?: Array<DocumentReferencUpdateResponseExtValu>;
    url?: string
}

export type DocumentReferencUpdateResponseExtValu = {
    fileName?: string;
    isDownloaded?: boolean;
    xeroStatus?: string;
    linkedStudyDescription?: string;
    uploadingUniqueId?: string;
    sqCreateStatus?: string;
    mimeType?: string;
    noOfRetries?: string;
    modInstance?: string;
    fileSize?: string;
    linkedStudyModality?: string;
    sourceType?: string;
    deleteModInstance?: string;
    fileSelectorType?: string;
    isQuestionnaireUpload?: boolean;
    uploadedBy?: Array<string>;
    fileType?: string;
    uniqueId?: string;
    studyIuid?: string
}

export type SubJectData = {
    ref?: string;
    id?: number
}
export type TypeKeyData = {
    coding?: Array<any>;
    extension?: Array<any>;
    text?: string
}
export type IndexedType = {
    extension?: Array<any>;
    value?: string
}

export type ContentDocumenttype = {
    attachment?: ContentDocAtachMentType
}

export type ContentDocAtachMentType = {
    title?: string;
    contentType?: string;
    creation?: ContentDocAtachmentCreationType
}

export type ContentDocAtachmentCreationType = {
    extension?: Array<any>;
    value?: string
}

export  type  MasterIdentifierData = {
    useCode?: string;
    extension?: Array<any>;
    type?: MasterIdentifierType;
    value?: string
}

export type MasterIdentifierType = {
    coding?: Array<any>;
    extension?: Array<any>;
    text?: string
}
export default DocumentReferencUpdateResponseModel