import React, {lazy, Suspense} from 'react';
import {Route} from 'react-router-dom';
import RoutePathInfo from '../../../common/src/enums/RoutingPath';
import Loader from '../../../ui/comps/src/Loader';

const ImageViewerComponent = lazy(
	() => import('./components/viewer/ImageViewer'),
);
const ImageViewerRoutes = (
	<>
		<Route
			path={RoutePathInfo.imageViewer.path}
			element={
				<Suspense fallback={<Loader />}>
					<ImageViewerComponent />
				</Suspense>
			}
		/>
	</>
);

export default ImageViewerRoutes;
