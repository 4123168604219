import {Service} from 'typedi';
import {strings} from '../../../../../../localization/i18n';
import {isValueAvailable} from '../../../../utils/StringUtils';
import APIExecutor, {APIRequest} from '../../../network/APIExecutor';
import ErrorModel from '../../../network/model/ErrorModel';
import {APIUrl} from '../../../network/values/URLInfo';
import NotificationSearchRequestModel from '../models/requestModels/NotificationSearchRequestModel';
import {ReadNotificationRequestData} from '../models/requestModels/ReadNotificationRequestModel';
import NotificationSearchResponseModel from '../models/responseModels/NotificationSearchResponseModel';
import ReadNotificationResponseModel, {
	ReadNotificationResponseData,
} from '../models/responseModels/ReadNotificationResponseModel';
const SOMETHING_WENT_WRONG = strings('errorMessage.somethingWentWrong');
@Service()
class NotificationNetworkRepository {
	/**
	 * sends API request for fetching request data
	 *@param {NotificationSearchRequestModel} requestModel
	 * @returns {Promise<NotificationSearchResponseModel | ErrorModel>}
	 */
	fetchNotifications(
		requestModel: NotificationSearchRequestModel,
	): Promise<NotificationSearchResponseModel | ErrorModel> {
		const requestData = requestModel.getRequestDataObject();
		const finalRequestData: Record<string, any> = {
			_count: requestData.count,
			_skip: requestData.skip,
			'_sort:desc': requestData.sortDesc,
			auditTrail: requestData.auditTrail,
			read: requestData.read,
		};

		if (isValueAvailable(requestData.priority)) {
			finalRequestData['priority'] = requestData.priority;
		}
		return new Promise((resolve, reject) => {
			APIExecutor.postHTTP(
				new APIRequest(APIUrl.SEARCH_NOTIFICATION, {
					constraints: finalRequestData,
				}),
			)
				.then(({data}) => {
					const dataModel: NotificationSearchResponseModel =
						new NotificationSearchResponseModel(data);
					resolve(dataModel);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	/**
	 * sends API request to mark notification as read
	 *@param {ReadNotificationRequestData} requestData
	 * @returns {Promise<ReadNotificationResponseModel | ErrorModel>}
	 */
	markNotificationAsRead(
		requestData: ReadNotificationRequestData,
	): Promise<ReadNotificationResponseModel | ErrorModel> {
		return new Promise((resolve, reject) => {
			APIExecutor.postHTTP(
				new APIRequest(APIUrl.MARK_NOTIFICATION_AS_READ, requestData),
			)
				.then(({data}) => {
					const requestData: ReadNotificationResponseModel =
						new ReadNotificationResponseModel(data);
					resolve(requestData);
				})
				.catch(error => {
					reject(error);
				});
		});
	}
}

export default NotificationNetworkRepository;
