import ActionButtons from '../ActionButtons/ActionButtons';
import {DialogBoxType} from './dialogBox.type';
import './dialogBox.scss';
import ToastNotification from '../notification/view/ToastNotification';
import {createRef, useEffect} from 'react';
import encryptedStorageHelperInstance from '../../../../storage/EncryptedStorageHelper';

const DialogBox = (props: DialogBoxType): JSX.Element => {
	const {
		showDialog,
		title,
		children,
		bodyText,
		showCloseIcon,
		actionButtons,
		toggleDialogBox,
		dialogSize,
		closeOnClickAway = true,
	} = props;

	const shiftFocusOnModalClose = () => {
		toggleDialogBox();
		//focus on button from where the modal was opened
		document
			.getElementById(
				encryptedStorageHelperInstance.getItemSync('idToFocus') ?? '',
			)
			?.focus();
		encryptedStorageHelperInstance.removeItem('idToFocus');
	};

	useEffect(() => {
		if (document.getElementById(`div_modalCompContent_${title}`)) {
			document.getElementById(`div_modalCompContent_${title}`)?.focus();
		}
	}, [showDialog]);

	useEffect(() => {
		function keyListener(e: any) {
			const listener = keyListenersMap.get(e.keyCode);
			return listener && listener(e);
		}
		document.addEventListener('keydown', keyListener);

		return () => document.removeEventListener('keydown', keyListener);
	});

	const modalRef = createRef<HTMLDivElement>();
	const handleTabKey = (e: any) => {
		const focusableModalElements = modalRef?.current?.querySelectorAll(
			'a[href], button, textarea, input, select, radio',
		);
		let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

		if (!isTabPressed) {
			return;
		}
		if (focusableModalElements && focusableModalElements.length !== 0) {
			const firstFocusableElement = focusableModalElements[0] as HTMLElement;
			const lastFocusableElement = focusableModalElements[
				focusableModalElements.length - 1
			] as HTMLElement;

			if (e.shiftKey) {
				// if shift key pressed for shift + tab combination
				if (document.activeElement === firstFocusableElement) {
					lastFocusableElement.focus(); // add focus for the last focusable element
					e.preventDefault();
				}
			} else {
				// if tab key is pressed
				if (document.activeElement === lastFocusableElement) {
					// if focused has reached to last focusable element then focus first focusable element after pressing tab
					firstFocusableElement.focus(); // add focus for the first focusable element
					e.preventDefault();
				}
			}
		}
	};

	const keyListenersMap = new Map([[9, handleTabKey]]);

	return (
		<>
			{showDialog ? (
				<div
					id={`div_om_dialogBoxComp_${title}`}
					onClick={() => {
						closeOnClickAway && shiftFocusOnModalClose();
					}}
					className={'modal cstm-modal'}
					role="dialog">
					<div
						id={`div_dialogBoxComp_${title}`}
						className={`modal-dialog modal-dialog-centered ${
							dialogSize && 'modal-' + dialogSize
						}`}>
						<div
							id={`div_modalCompContent_${title}`}
							className="modal-content"
							tabIndex={-1}
							aria-label="Dialog Box"
							onClick={e => {
								closeOnClickAway && e.stopPropagation();
							}}
							ref={modalRef}>
							<div id={`div_modalCompHeader_${title}`} className="modal-header">
								<div
									className="h2"
									role="heading"
									id={`modal-title_${title}`}
									tabIndex={-1}>
									{title ? title : null}
								</div>
								{showCloseIcon ? (
									<button
										id={`modalCompCloseBtn_${title}`}
										onClick={() => shiftFocusOnModalClose()}
										type="button"
										className={'close'}
										data-dismiss="modal">
										<span
											className="fIcon icon-cancel1"
											aria-hidden="true"></span>
										<span className="sr-only">Close</span>
									</button>
								) : null}
							</div>
							<ToastNotification type="dialog" />
							<div id={`div_modalCompBody_${title}`} className={'modal-body'}>
								<p>{children ? children : bodyText}</p>
							</div>
							{actionButtons && actionButtons.length > 0 ? (
								<div id={`modalCompFooter_${title}`} className="modal-footer">
									<ActionButtons actionButtons={actionButtons}></ActionButtons>
								</div>
							) : null}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

export default DialogBox;
