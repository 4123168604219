import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class UpdateVisibilityRequestModel implements BaseRequestModel {
    private readonly id?: string
    private readonly visible?: string

    constructor({
                    id,
                    visible
                }: UpdateVisibilityRequestData) {
        this.id = id;
        this.visible = visible
    }

    /**
     * returns update visibility request
     * @returns {UpdateVisibilityRequestData}
     */
    getRequestDataObject(): UpdateVisibilityRequestData {
        return {
            id: this.id,
            visible: this.visible
        }
    }
}

export type UpdateVisibilityRequestData = {
    id?: string;
    visible?: string
}
export default UpdateVisibilityRequestModel;