import './App.css';
import './assets/css/iconFonts.css';
import Card from './ui/comps/src/card/Card';
import AppRoutedComponent from './App.routing';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';

import $ from 'jquery';
import 'bootstrap';

import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {strings} from './localization/i18n';
import RoutePathInfo from './common/src/enums/RoutingPath';

function App() {
	//for handling page titles
	let currLoc = useLocation();
	useEffect(() => {
		const curTitle = Object.values(RoutePathInfo).find(
			item => item.path === currLoc.pathname,
		);
		if (curTitle && curTitle.title) {
			document.title = strings('pageTitle.' + curTitle.title);
		} else {
			document.title = strings('pageTitle.default');
		}
	}, [currLoc]);

	useEffect(() => {
		$('[data-toggle="tooltip"]').tooltip();
	}, []);

	return (
		<div className="App">
			<Card className="mainContainer">
				<ErrorBoundary>
					<AppRoutedComponent />
				</ErrorBoundary>
			</Card>
		</div>
	);
}

export default App;
