import {useEffect, useReducer} from 'react';
import Container from 'typedi';
import {LoaderProps} from '../../../common/src/application/modules/generic/models/Loader';
import {NavigationEvent} from '../../../common/src/application/modules/generic/models/NavigationEvent';
import {OTPVerificationNavigationEvents} from '../../../common/src/application/modules/generic/otp/enums/OTPVerificationNavigationEvents';
import OTPVerificationViewModel from '../../../common/src/application/modules/generic/otp/viewmodel/OTP.verification.viewmodel';
import {strings} from '../../../localization/i18n';
import DialogBox from '../../../ui/comps/src/DialogBox/dialogBox.component';
import Loader from '../../../ui/comps/src/Loader';
import ToastNotification, {
	showNotification,
} from '../../../ui/comps/src/notification/view/ToastNotification';
import OtpComponent from '../../../ui/comps/src/otp/otp.component';

type OTPVerificationState = {
	loading: boolean;
};

enum OTPVerificationStateActions {
	ACTION_LOADING,
}

function reducer(
	currentState: OTPVerificationState,
	{
		type,
		payload,
	}: {
		type: OTPVerificationStateActions;
		payload: {
			loader?: LoaderProps;
		};
	},
): OTPVerificationState {
	switch (type) {
		case OTPVerificationStateActions.ACTION_LOADING:
			return {
				...currentState,
				loading: payload.loader?.isToShowLoader ?? false,
			};
	}
}

const containerInstance = Container.of('OTPVerificationView');

export default function OTPVerificationComponent(props: any) {
	const {
		getNetworkCallProviderForResend,
		getNetworkCallProviderForVerify,
		sessionStorageKeys,
	} = props;

	const [{loading}, dispatch] = useReducer(reducer, {loading: true});

	const viewModel = containerInstance.get(OTPVerificationViewModel);

	useEffect(() => {
		const loaderSubscription = viewModel.fetchLoaderObservable().subscribe({
			next: (value: LoaderProps) => {
				dispatch({
					type: OTPVerificationStateActions.ACTION_LOADING,
					payload: {loader: value},
				});
			},
		});
		const navigationSubscription = viewModel
			.fetchNavigationObservable()
			.subscribe({
				next: (
					value: NavigationEvent<
						OTPVerificationNavigationEvents,
						Record<string, any>
					>,
				) => {
					switch (value.event) {
						case OTPVerificationNavigationEvents.CANCEL:
							props.onCancel && props.onCancel();
							break;
						case OTPVerificationNavigationEvents.CONTINUE:
							props.onSuccessfulVerification &&
								props.onSuccessfulVerification();
							break;
					}
					containerInstance.reset();
				},
			});
		const notificationMessageSubscription = viewModel
			.fetchNotificationMessageObservable()
			.subscribe({
				next: notificationMessage =>
					showNotification({
						message: notificationMessage.message,
						level: notificationMessage.level,
						type: notificationMessage.visibility,
						persistOnRouteChange: notificationMessage.persistOnRouteChange,
						closeButtonEnabled: notificationMessage.closeButtonEnabled,
						callbackHandler: notificationMessage.callbackHandler,
					}),
			});

		// TODO props
		viewModel.initialize(
			getNetworkCallProviderForResend(
				props.unverifiedNumber?.countryCode,
				props.unverifiedNumber?.phoneNumber,
			),
			getNetworkCallProviderForVerify(
				props.unverifiedNumber?.countryCode,
				props.unverifiedNumber?.phoneNumber,
			),
		);
		viewModel.onClickResend(true);

		return () => {
			loaderSubscription.unsubscribe();
			navigationSubscription.unsubscribe();
			notificationMessageSubscription.unsubscribe();
		};
	}, []);

	/**
	 * This function is used to initiate resend OTP API call.
	 */
	function resendOTP(): void {
		viewModel.onClickResend();
	}

	function validateOTP(data: any) {
		viewModel.onClickContinue(data.OTP);
	}

	return (
		<>
			{loading && <Loader />}
			<DialogBox
				toggleDialogBox={props.handleModalClose}
				showDialog={props.showModal}
				title={strings('otpScreen.verifyPhoneButton')}
				showCloseIcon={true}
			>
				<ToastNotification type="local" />
				<OtpComponent
					sessionStorageKeys={sessionStorageKeys}
					submitHandler={(data: any) => {
						validateOTP(data);
					}}
					resendOtpHandler={() => resendOTP()}
					showResendTimer={false}
				/>
			</DialogBox>
		</>
	);
}
