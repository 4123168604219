import {Service} from 'typedi';
import APIExecutor, {APIRequest} from '../../../network/APIExecutor';
import {APIUrl} from '../../../network/values/URLInfo';
import DocumentReferenceSearchCriteriaRequestModel from '../models/requestModel/DocumentReferenceSearchCriteriaRequestModel';
import LocationSearchRequestModel from '../models/requestModel/LocationSearchRequestModel';
import OrganizationSearchRequestModel from '../models/requestModel/OrganizationSearchRequestModel';
import EncounterSearchRequestModel from '../models/requestModel/EncounterSearchRequestModel';
import CheckInitialSpeedRequestModel from '../models/requestModel/CheckInitialSpeedRequestModel';
import ConditionSearchrequestModel from '../models/requestModel/ConditionSearchRequestModel';
@Service()
class UploaderNetworkRepository {
	/**
	 * uploads and save the document provided
	 * @param {FormData} documentData
	 * @returns {Promise<Record<string, any>>}
	 */
	uploadAndSaveDocument(
		documentData: FormData,
		config: Record<string, any>,
	): Promise<Record<string, any>> {
		const docUploadAndSaveRequest = APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.MO_DOCUMENT_REFERENCE_UPLOAD_AND_SAVE,
				documentData,
			),
			config,
		);
		return docUploadAndSaveRequest;
	}

	/**
	 * gets uploader configurations
	 * @returns {Promise<Record<string, any>>}
	 */
	fetchUploaderConfig(): Promise<Record<string, any>> {
		const uploaderConfigRequest = APIExecutor.postHTTP(
			new APIRequest(APIUrl.GET_CONFIG_FOR_UPLOADER, {}),
		);
		return uploaderConfigRequest;
	}

	/**
	 * get mo conditions from subjectID
	 * @param {ConditionSearchrequestModel} searchData
	 * @returns {Promise<Record<string, any>>}
	 */
	moConditionSearch(
		searchData: ConditionSearchrequestModel,
	): Promise<Record<string, any>> {
		const conditionSearchResponse = APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.CONDITION_SEARCH,
				searchData.getRequestDataObject(),
			),
		);
		return conditionSearchResponse;
	}

	/**
	 * search document reference
	 * @param {DocumentReferenceSearchCriteriaRequestModel} docRefRequestData
	 * @returns {Promise<Record<string, any>>}
	 */
	docRefSearchData(
		docRefRequestData: DocumentReferenceSearchCriteriaRequestModel,
	): Promise<Record<string, any>> {
		const docRefSearchResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.DOCUMENT_REFERENCE_SEARCH_DATA, docRefRequestData),
		);
		return docRefSearchResponse;
	}

	/**
	 * gets location search data
	 * @param {LocationSearchRequestModel} searchCriteria
	 * @returns {Promise<Record<string, any>>}
	 */
	locationSearch(
		searchCriteria: LocationSearchRequestModel,
	): Promise<Record<string, any>> {
		const locationSearchResponse = APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.LOCATION_SEARCH,
				searchCriteria.getRequestDataObject(),
			),
		);
		return locationSearchResponse;
	}

	/**
	 * finds organisations
	 * @param {OrganizationSearchRequestModel} searchCriteria
	 * @returns {Promise<Record<string, any>>}
	 */
	organizationSearch(
		searchCriteria: OrganizationSearchRequestModel,
	): Promise<Record<string, any>> {
		const organizationSearchResponse = APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.ORGANIZATION_SEARCH,
				searchCriteria.getRequestDataObject(),
			),
		);
		return organizationSearchResponse;
	}

	/**
	 * finds requried encounters
	 * @param {EncounterSearchRequestModel} searchCriteria
	 * @returns {Promise<Record<string, any>>}
	 */
	encounterSearch(
		searchCriteria: EncounterSearchRequestModel,
	): Promise<Record<string, any>> {
		const encounterSearchResponse = APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.ENCOUNTER_SEARCH,
				searchCriteria.getRequestDataObject(),
			),
		);
		return encounterSearchResponse;
	}

	/**
	 * checks initials speed
	 * @param {CheckInitialSpeedRequestModel} finalData
	 * @returns {Promise<Record<string, any>>}
	 */
	checkInitialSpeed(
		finalData: CheckInitialSpeedRequestModel,
	): Promise<Record<string, any>> {
		const checkInitialSpeedResponse = APIExecutor.postHTTP(
			new APIRequest(APIUrl.CHECK_INITIAL_SPEED, finalData),
		);
		return checkInitialSpeedResponse;
	}
}

export default UploaderNetworkRepository;
