import {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {MenuItemsModel} from '../../common/src/application/modules/user/models/UserModel';
import {sessionStorageKeys} from '../../common/src/interface/storage/constants/SessionKeys';
import {strings} from '../../localization/i18n';
import encryptedStorageHelperInstance from '../../storage/EncryptedStorageHelper';
import PatientActionDropdown from './PatientActionDropdown.component';
import PatientSearchViewModel from '../../common/src/application/modules/patientSearch/viewModels/PatientSearchViewModel';
import Container from 'typedi';
import PatientActionBannerRequestModel, {
	PatientActionBannerRequestModelType,
} from '../../common/src/application/modules/patientSearch/models/requestModels/PatientActionBannerRequestModel';
import {NotificationMessage} from '../../common/src/application/modules/generic/models/NotificationMessage';
import {showNotification} from '../../ui/comps/src/notification/view/ToastNotification';
import Loader from '../../ui/comps/src/Loader';
import {isValueAvailable} from '../../ui/comps/src/utils/uiCompsUtils';
import CustomNavLink from '../../ui/comps/src/CustomNavLink';
import _ from 'lodash';

const patientBannerContainer = Container.of('PatientBannerContainer');

const PatientBannerNavbar = (props: any): JSX.Element => {
	const patientSearchViewModel: PatientSearchViewModel =
		patientBannerContainer.get(PatientSearchViewModel);
	let location = useLocation();

	const [actionsDropDownList, setActionsDropDownList] = useState([]);

	const [dropdownItemsData, setDropdownItemsData] = useState<
		Array<Record<string, any>>
	>([]);

	const [menuItemsData, setMenuItemData] = useState<Array<MenuItemsModel>>([]);

	const [navItems, setNavItems] = useState<Array<MenuItemsModel>>([]);
	const navItemsRef = useRef<any>({});
	navItemsRef.current = navItems;

	const [activeIndex, setActiveIndex] = useState(
		encryptedStorageHelperInstance.getItemSync(
			sessionStorageKeys.DEFAULT_PATIENT_BANNER_MENUITEM,
		)?.id,
	);

	const {patientId, configData} = props;
	if (global.ConfigurationHolder?.enableAngularTransition) {
		encryptedStorageHelperInstance.setItemSync(
			sessionStorageKeys.PATIENT_ID,
			patientId,
		);
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const loaderSubject = patientSearchViewModel
			.fetchLoaderObservable()
			.subscribe(response => {
				setIsLoading(response.isToShowLoader);
			});
		const notificationSubject = patientSearchViewModel
			.fetchNotificationMessageObservable()
			.subscribe({
				next: (response: NotificationMessage) => {
					showNotification({
						type: response.visibility,
						level: response.level,
						message: response.message,
						persistOnRouteChange: response.persistOnRouteChange,
					});
				},
			});
		return () => {
			loaderSubject.unsubscribe();
			notificationSubject.unsubscribe();
		};
	}, [patientSearchViewModel]);

	const setPatientBannerActionsDropdownList = (): void => {
		const getPatientActionBannerRequestModelType: PatientActionBannerRequestModelType =
			{
				constraints: {
					_id: patientId,
				},
			};
		const getPatientActionBannerRequestModel: PatientActionBannerRequestModel =
			new PatientActionBannerRequestModel(
				getPatientActionBannerRequestModelType,
			);
		patientSearchViewModel
			.getPatientActionBannerDetails(
				getPatientActionBannerRequestModel,
				patientId,
			)
			.subscribe(
				response => {
					const responseData: Record<string, any> = response;
					if (responseData) {
						const patientBannerMenuItems = responseData.patientBannerMenuItems;
						if (isValueAvailable(patientBannerMenuItems)) {
							let tempMenuItemsData: Array<MenuItemsModel> = [];
							let tempDropdownItemsData: Array<Record<string, any>> = [];
							setNavItems(patientBannerMenuItems);
							patientBannerMenuItems.forEach(
								(menuItem: Record<string, any>, index: number) => {
									if (index < defaultNoOfMenuItems - 1) {
										tempMenuItemsData.push(patientBannerMenuItems[index]);
									} else {
										tempDropdownItemsData.push(patientBannerMenuItems[index]);
									}
									if (index === patientBannerMenuItems?.length - 1) {
										setMenuItemData(tempMenuItemsData);
										setDropdownItemsData(tempDropdownItemsData);
									}
								},
							);
						}
						setActionsDropDownList(responseData.listItem);
					}
				},
				error => {
					setActionsDropDownList([]);
				},
			);
	};

	const getRedirectUrl = (menuItem: MenuItemsModel): string => {
		let url: string;
		if (
			menuItem.name === 'overview' ||
			menuItem.name === 'apps' ||
			menuItem.name === 'carePlans'
		) {
			url = '/patient' + menuItem.path + '/' + patientId;
		} else if (menuItem.name == 'records') {
			url = '/patient' + menuItem.path + '/%3Fsubject=' + patientId;
		} else if (
			menuItem.name === 'observations' ||
			menuItem.name === 'formsList'
		) {
			url = '/patient/' + patientId + menuItem.path;
		} else {
			url = patientId + menuItem.path;
		}
		return url;
	};

	const [defaultNoOfMenuItems, setDefaultNoOfMenuItems] = useState(
		configData.defaultNumberOfPatientMenuItems,
	);

	const breakPoints = configData.breakpoints;

	/**
	 * adds menuItems in dropdown when screen is resized
	 */
	const whenScreenResized = () => {
		const screenSize = window.innerWidth;
		let numberOfMenuItems: number = defaultNoOfMenuItems;
		if (screenSize >= breakPoints.xxl) {
			numberOfMenuItems = 11;
		} else if (screenSize < breakPoints.xxl && screenSize >= breakPoints.xl) {
			numberOfMenuItems = 10;
		} else if (screenSize < breakPoints.xl && screenSize >= breakPoints.l) {
			numberOfMenuItems = 9;
		} else if (screenSize < breakPoints.l && screenSize >= breakPoints.m) {
			numberOfMenuItems = 7;
		} else if (screenSize < breakPoints.m && screenSize >= breakPoints.s) {
			numberOfMenuItems = 5;
		} else if (screenSize < breakPoints.s) {
			numberOfMenuItems = 3;
		}
		updateMenuAndDropdownMenuItems(numberOfMenuItems);
	};

	const updateMenuAndDropdownMenuItems = (numberOfMenuItems: number) => {
		if (navItemsRef?.current?.length) {
			let tempMenuData: Array<MenuItemsModel> = [];
			let tempDropdownData: Array<MenuItemsModel> = [];
			setDropdownItemsData([]);
			for (let i = 0; i < navItemsRef?.current?.length; i++) {
				if (i < numberOfMenuItems) {
					tempMenuData.push(navItemsRef?.current[i]);
				} else {
					tempDropdownData.push(navItemsRef?.current[i]);
				}
			}
			setMenuItemData(_.cloneDeep(tempMenuData));
			setDropdownItemsData(_.cloneDeep(tempDropdownData));
		}
	};

	useEffect(() => {
		whenScreenResized();
		window.addEventListener('resize', whenScreenResized);
		return () => {
			window.removeEventListener('resize', whenScreenResized);
		};
	}, []);

	useEffect(() => {
		menuItemsData.forEach(menuItem => {
			if (location.pathname.includes(menuItem.path)) {
				setActiveIndex(menuItem.id);
			}
			//to set observation as active index in case of reload and navigation from vitalDetail page
			if (
				location.pathname.includes('/vitalDetail') &&
				menuItem.path === '/vitals'
			) {
				setActiveIndex(menuItem.id);
			}
		});
	}, [location, menuItemsData]);

	useEffect(() => {
		setPatientBannerActionsDropdownList();
	}, []);

	const callbackHandler = (data: Record<string, any> | undefined) => {
		setActiveIndex(data && data.id);
	};

	const menuData =
		menuItemsData.length > 0 &&
		menuItemsData.map((menuItem: MenuItemsModel, key: number) => {
			return (
				<li className={'nav-item'} key={key}>
					<CustomNavLink
						role="link"
						className={activeIndex === menuItem.id ? 'active' : 'inactive'}
						to={getRedirectUrl(menuItem)}
						callBackHandler={callbackHandler}
						callBackData={{id: menuItem.id}}
						ariaCurrent={activeIndex === menuItem.id ? 'page' : false}
					>
						{strings('patientBanner.' + menuItem.name)}
					</CustomNavLink>
				</li>
			);
		});
	return (
		<>
			{isLoading && <Loader />}
			<div className="patientBannerNav">
				<div className="patBannerNav">
					<nav className="navbar navbar-expand-sm">
						<ul className="navbar-nav">
							{menuData}
							{dropdownItemsData.length > 0 && (
								<li className="nav-item dropdown">
									<button
										className="nav-link dropdown-toggle noBtnStyle"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										id="patNavMoreDropdown"
									>
										<span className="sr-only">More</span>
										<span
											className="fIcon icon-more1 more-item"
											aria-hidden="true"
										></span>
									</button>
									<div
										className="dropdown-menu dropdown-menu-right"
										aria-labelledby="patNavMoreDropdown"
									>
										{dropdownItemsData.length > 0 &&
											dropdownItemsData.map(
												(dropdownItem: any, key: number) => (
													<CustomNavLink
														key={key}
														className="dropdown-item"
														to={getRedirectUrl(dropdownItem)}
														callBackHandler={callbackHandler}
														callBackData={{id: dropdownItem.id}}
														ariaCurrent={
															activeIndex === dropdownItem.id ? 'page' : false
														}
													>
														{strings('patientBanner.' + dropdownItem.name)}
													</CustomNavLink>
												),
											)}
									</div>
								</li>
							)}
						</ul>
					</nav>
				</div>
				<div className="patBannerAction">
					{actionsDropDownList.length !== 0 && (
						<PatientActionDropdown
							patientId={patientId}
							data={actionsDropDownList}
						/>
					)}
				</div>
				<div className="clearfix"></div>
			</div>
		</>
	);
};

export default PatientBannerNavbar;
