import {Container, Service} from 'typedi';
import DocumentListNetworkRepository from '../networkRepository/DocumentListNetworkRepository';
import {Observable, Subject, Subscriber} from 'rxjs';
import DocumentListConfigResponseModel, {
	DocumentListConfigData,
} from '../models/responseModel/DocumentListConfigResponseModel';
import ImageStudyRequestModel, {
	ImageStudyConstraints,
} from '../models/requestModel/ImageStudyRequestModel';
import UpdateEditDocumentRequestModel, {
	UpdateEditDocumentRequestData,
} from '../models/requestModel/UpdateEditDocumentRequestModel';
import UpdateVisibilityRequestModel, {
	UpdateVisibilityRequestData,
} from '../models/requestModel/UpdateVisibilityRequestModel';
import SendToIHERepositoryRequetsModel, {
	SendToIHERepositoryRequetsData,
} from '../models/requestModel/SendToIHERepositoryRequetsModel';
import BulkActionRequestModel, {
	BulkActionRequestData,
} from '../models/requestModel/BulkActionRequestModel';
import CancelBulkUploadRequestModel, {
	CancelBulkUploadRequestData,
} from '../models/requestModel/CancelBulkUploadRequestModel';
import DocumentReferenceUpdateRequestModel, {
	DocumentReferenceUpdateRequestData,
} from '../models/requestModel/DocumentReferenceUpdateRequestModel';
import DocumentReferenceDownloadRequestModel, {
	DocumentReferenceDownloadRequestData,
} from '../models/requestModel/DocumentReferenceDownloadRequestModel';
import DocumentReferencUpdateResponseModel, {
	DocumentReferencUpdateResponseData,
} from '../models/responseModel/DocumentReferencUpdateResponseModel';
import BulkActionResponseModel, {
	BulkRequestResponseData,
} from '../models/responseModel/BulkActionResponseModel';
import CancleBulkDownLoadRequestResponseModel from '../models/responseModel/CancleBulkDownLoadRequestResponseModel';
import PatientShowResponseModel from '../models/responseModel/PatientShowResponseModel';
import PatientShowValidationModel from '../models/validationModel/PatientShowValidationModel';
import ImageStudyValidationModel from '../models/validationModel/ImageStudyValidationModel';
import UpdateEditDocumentValidationModel from '../models/validationModel/UpdateEditDocumentValidationModel';
import UpdateVisibilityValidationModel from '../models/validationModel/UpdateVisibilityValidationModel';
import SendToIHERepositoryValidationModel from '../models/validationModel/SendToIHERepositoryValidationModel';
import BulkActionValidationModel from '../models/validationModel/BulkActionValidationModel';
import CancelBulkUploadValidationModel from '../models/validationModel/CancelBulkUploadValidationModel';
import DocumentReferenceDownloadRequestValidation from '../models/validationModel/DocumentReferenceDownloadRequestValidation';
import DocumentReferenceUpdateValidationModel from '../models/validationModel/DocumentReferenceUpdateValidationModel';
import {strings} from '../../../../../../localization/i18n';
import BasicSubscribersViewModel from '../../generic/viewModel/BasicSubscribers.viewmodel';
import {DocumentListingNavigationEvents} from '../enums/DocumentListingNavigationEvents';
import {LoaderType} from '../../generic/models/Loader';
import {
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../generic/models/NotificationMessage';
import SendToEmrOrPacsModel, {
	SendToEmrOrPacsRequestData,
} from '../models/requestModel/SendToEmrOrPacsModel';
import SendToEmrOrPacsRequestValidation from '../models/validationModel/SendToEmrOrPacsRequestValidation';
import {
	getXDSDocument,
	hasAnyRoles,
	nameFromList,
} from '../../../../utils/CommonUtils';
import {DynamicFormDataV2} from '../../../dynamicFormUtil/types/DynamicForm.types';
import VITAL from '../../../../enums/VitalEnum';
import {areEmailsValid, isValueAvailable} from '../../../../utils/StringUtils';
import ShareDocRequestModel, {
	ShareDocRequestModelType,
} from '../models/requestModel/ShareDocRequestModel';
import ShareDocValidationModel from '../models/validationModel/ShareDocValidationModel';
import DocumentListResponse, {
	DocumentListOptionsMenu,
	DocumentListResponseType,
	DocumentModel,
} from '../models/responseModel/DocumentListResponseModel';
import DocumentListRequestModel, {
	DocumentListRequestData,
} from '../models/requestModel/DocumentListRequestModel';
import RoleManager from '../../user/helper/RoleManager';
import ShareRecordsRequest from '../../share/model/ShareRecordsRequest';
import SearchCriteria from '../../share/model/SearchCriteria';
import ShareConstraint from '../../share/model/ShareConstraint';
import UserService from '../../user/service/User.service';
import DocumentDeleteRequestModel from '../models/requestModel/DocumentDeleteRequestModel';
import UploaderViewModel from './UploaderViewModel';
import {DocListEnum} from '../enums/DocuListEnum';
import {getFormattedDate} from '../../../../utils/DateTimeUtils';
import {UploaderConfigResponse} from '../models/responseModel/UploaderConfigResponseModel';
import LocationSearchRequestModel, {
	LocationSearchRequestData,
} from '../models/requestModel/LocationSearchRequestModel';
import _ from 'lodash';
import encryptedStorageHelperInstance from '../../../../../../storage/EncryptedStorageHelper';
import {sessionStorageKeys} from '../../../../interface/storage/constants/SessionKeys';
import WebsocketService from '../../../../../../utils/websocket';

export class Pair<T, U> {}

@Service()
class DocumentListViewModel extends BasicSubscribersViewModel<
	DocumentListingNavigationEvents,
	Record<string, any>
> {
	private documenListNetworkRepository: DocumentListNetworkRepository;
	private readonly roleManager: RoleManager;
	private readonly userService: UserService;
	private readonly documentListSubject: Subject<DocumentListResponseType>;
	private documentListConfigResponseData: DocumentListConfigData = {
		defaultDocumentStatus: [],
		uploadedDocumentsTypes: [],
	};
	private documentList: DocumentListResponseType = {list: [], totalCount: 0};
	private documentListModel: Record<string, any>;
	private documentListModelSubject: Subject<Record<string, any>>;
	private showDataGridSubject: Subject<boolean>;
	private config = global.ConfigurationHolder;
	private uploaderViewModel: UploaderViewModel =
		Container.get(UploaderViewModel);
	private websocketService: WebsocketService;

	constructor() {
		super();
		this.documenListNetworkRepository = Container.get(
			DocumentListNetworkRepository,
		);
		this.documentListSubject = new Subject<DocumentListResponseType>();
		this.roleManager = Container.get(RoleManager);
		this.userService = Container.get(UserService);
		// fetch user details from storage, ignore the observable
		this.userService.getUserDetails(true);
		this.documentListModelSubject = new Subject<Record<string, any>>();
		this.documentListModel = {
			translatedSourceTypeList: [],
			docRefTypeList: [],
			docRefPractitionerList: [],
			taskList: [],
			taskObjectMap: {},
			questionnaireResponseList: [],
			questionnaireResponseMap: {},
			careFacilityList: [],
			careFacilityMap: {},
			patientConditionList: [],
			documentStatuses: [],
			patientEncounterList: [],
			patientSurgeryList: [],
			serviceRequestDateMap: {},
		};
		this.showDataGridSubject = new Subject<boolean>();
		this.websocketService = Container.get(WebsocketService);
	}

	/**
	 * This method is used to get the observer where fetched document list is posted
	 * @returns Observable<Pair<number, DocumentListResponseType>>
	 */
	getDocumentListObservable(): Observable<
		Pair<number, DocumentListResponseType>
	> {
		return this.documentListSubject.asObservable();
	}

	/**
	 * This method is used to fetch the document list for given parameters.
	 * @param skip count of documents to skip
	 * @param count of documents to fetch
	 * @param encounterId if available
	 * @param typeTextIn to filter type
	 * @param patientId of the patient for whom to fetch documents
	 */
	fetchDocumentList(
		skip: number,
		count = 10,
		encounterId?: string,
		typeTextIn?: string,
		patientId?: string,
	): void {
		this.loaderSubject.next({
			isToShowLoader: true,
			type: LoaderType.OnScreen,
		});
		const req: DocumentListRequestData = {
			docsCount: count,
			skipCount: skip,
			sortDesc: 'lastUpdated',
			statusIn:
				this.documentListConfigResponseData.defaultDocumentStatus?.join(),
		};

		if (encounterId != null) {
			req.searchElementsList = [`enc_${encounterId}`];
		}

		if (typeTextIn != null) {
			req.typeText = [typeTextIn].join();
		} else {
			req.typeText =
				this.documentListConfigResponseData.uploadedDocumentsTypes?.join();
		}

		if (patientId != null) {
			req.subject = patientId;
		} else {
			req.authParams = 'showSelf';
		}

		this.documenListNetworkRepository
			.documentReferenceSearch(new DocumentListRequestModel(req))
			.then((response: DocumentListResponse) => {
				const data = response.getResponseDataObject();

				data.list?.forEach((item: DocumentModel) => {
					const actionList = [DocumentListOptionsMenu.DOCUMENT_VIEW];

					if ((global as any).platform === 'web') {
						// DOCUMENT_VIEW and DOCUMENT_DOWNLOAD are equivalent for mobile platforms
						actionList.push(DocumentListOptionsMenu.DOCUMENT_DOWNLOAD);
					}

					if (
						this.roleManager.isRoleAvailable('ROLE_EXTERNAL_DOCUMENT_SHARE')
					) {
						actionList.push(DocumentListOptionsMenu.DOCUMENT_SHARE);
					}

					if (
						this.roleManager.isRoleAvailable('ROLE_DOCUMENT_DELETE') ||
						this.roleManager.isRoleAvailable('ROLE_DOCUMENT_ADMIN')
					) {
						actionList.push(DocumentListOptionsMenu.DOCUMENT_DELETE);
					}

					if (
						(item.uploadedBy != null &&
							item.uploadedBy.length > 0 &&
							item.uploadedBy.indexOf(String(this.userService.getUserId())) >
								-1) ||
						this.roleManager.isRoleAvailable('ROLE_DOCUMENT_ADMIN')
					) {
						actionList.push(DocumentListOptionsMenu.DOCUMENT_EDIT);
					}
					item.availableOptions = actionList;
				});
				// update internal list state for VM
				this.documentList.list?.concat(data.list ?? []);
				this.documentList.totalCount = data.totalCount;
				this.documentListSubject.next(this.documentList);
			})
			.catch(error => {
				this.notificationMessageSubject.next({
					type: NotificationMessageType.toast,
					level: NotificationMessageLevel.error,
					visibility: NotificationVisibility.local,
					message: strings('errors.somethingWentWrong'),
				});
				console.log(
					'DocumentListViewModel.fetchDocumentList error',
					JSON.stringify(error, null, 4),
				);
			})
			.finally(() => {
				this.loaderSubject.next({
					isToShowLoader: false,
					type: LoaderType.OnScreen,
				});
			});
	}

	/**
	 * This method is used to share a document externally
	 * @param {string} patientId
	 * @param {string} documentId
	 * @param {string | undefined} contentDocumentType
	 */
	shareDocument(
		patientId: string,
		documentId: string,
		contentDocumentType?: string,
	): void {
		if (!isValueAvailable(contentDocumentType)) {
			this.notificationMessageSubject.next({
				type: NotificationMessageType.alert,
				level: NotificationMessageLevel.default,
				visibility: NotificationVisibility.global,
				heading: strings('document.alert.title.shareFailure'),
				message: strings('document.alert.message.shareFailure'),
				positiveActionTitle: strings('document.alert.button.ok'),
			});
		} else {
			const shareRecordRequest: ShareRecordsRequest = new ShareRecordsRequest(
				'DOCUMENT_INSTANT_SHARING',
				[documentId],
				new SearchCriteria(
					new ShareConstraint({
						patientId: patientId,
					}),
				),
			);
			this.navigationSubject.next({
				event: DocumentListingNavigationEvents.SHARE,
				additionalData: {
					shareRecordRequest: shareRecordRequest.getData(),
					module: 'document',
				},
			});
		}
	}

	/**
	 * This method is used to delete a document if it matches the deletion criteria.
	 * An alert is generated to confirm the deletion action from user.
	 * @param {DocumentModel} item to check and delete.
	 */
	deleteDocument(item: DocumentModel) {
		if (
			!(
				item.source != null &&
				(((this.userService.isPatientUser()
					? item.source === DocListEnum.PATIENT
					: item.source === DocListEnum.PHYSICIAN) &&
					item.uploadedBy != null &&
					item.uploadedBy.length > 0 &&
					item.uploadedBy.indexOf(String(this.userService.getUserId())) > -1) ||
					this.roleManager.isRoleAvailable('ROLE_DOCUMENT_ADMIN'))
			)
		) {
			this.notificationMessageSubject.next({
				type: NotificationMessageType.alert,
				level: NotificationMessageLevel.default,
				visibility: NotificationVisibility.global,
				heading: strings('document.alert.title.deleteFailure'),
				message: strings('document.alert.message.deleteFailure'),
				positiveActionTitle: strings('document.alert.button.ok'),
			});
		} else {
			this.notificationMessageSubject.next({
				type: NotificationMessageType.alert,
				level: NotificationMessageLevel.default,
				visibility: NotificationVisibility.global,
				heading: strings('document.alert.title.deleteConfirmation'),
				message: strings('document.alert.message.deleteConfirmation'),
				positiveActionTitle: strings('document.alert.button.yes'),
				positiveAction: () => {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OverScreen,
					});
					this.documenListNetworkRepository
						.deleteDocumentReference(
							new DocumentDeleteRequestModel(item.documentId),
						)
						.then(() => {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.success,
								visibility: NotificationVisibility.local,
								message: strings('document.toast.success.delete'),
							});
							this.navigationSubject.next({
								event: DocumentListingNavigationEvents.DOCUMENT_LIST_REFRESH,
								additionalData: {},
							});
						})
						.catch(error => {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
								visibility: NotificationVisibility.local,
								message: strings('errors.somethingWentWrong'),
							});
							console.log(
								'DocumentListViewModel.deleteDocument error',
								JSON.stringify(error, null, 4),
							);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OverScreen,
							});
						});
				},
				negativeActionTitle: strings('document.alert.button.cancel'),
			});
		}
	}

	/**
	 * fetches document list confugurations
	 * @returns {<DocumentListConfigResponseModel>}
	 */
	fetchDocumentListingConfig(): Observable<DocumentListConfigData> {
		const documentListingConfigResponse = new Observable(
			(subscriber: Subscriber<DocumentListConfigData>) => {
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OnScreen,
				});
				this.documenListNetworkRepository
					.fetchDocumentListingConfig()
					.then(({data, headers, status}) => {
						const documentListConfigResponseModel: DocumentListConfigResponseModel =
							new DocumentListConfigResponseModel(data);
						const documentListConfigResponseData: DocumentListConfigData =
							documentListConfigResponseModel.getResponseDataObject();
						this.documentListConfigResponseData = {
							...documentListConfigResponseModel.getResponseDataObject(),
						};
						subscriber.next(documentListConfigResponseData);
						subscriber.complete();
					})
					.catch(error => {
						subscriber.error(error);
					})
					.finally(() => {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OnScreen,
						});
					});
			},
		);
		return documentListingConfigResponse;
	}

	/**
	 * gets patient details
	 * @param {number} patientID
	 * @returns {<PatientShowResponseModel | PatientShowValidationModel>}
	 */
	fetchPatientDetails(
		patientID?: number | null,
	): Observable<PatientShowResponseModel | PatientShowValidationModel> {
		const fetchPatientDetailsResponse = new Observable(
			(
				subscriber: Subscriber<
					PatientShowResponseModel | PatientShowValidationModel
				>,
			) => {
				const patientShowValidationModel: PatientShowValidationModel =
					this.validatePatientRequestData(patientID);
				if (!patientShowValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.fetchPatientDetails(patientID)
						.then(({data, headers, status}) => {
							const patientShowResponseModel: PatientShowResponseModel =
								new PatientShowResponseModel(data);
							subscriber.next(patientShowResponseModel);
							subscriber.complete();
						})
						.catch(error => {
							subscriber.error(error);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(patientShowValidationModel);
				}
			},
		);
		return fetchPatientDetailsResponse;
	}

	private validatePatientRequestData(
		patientID?: number | null,
	): PatientShowValidationModel {
		const patientShowValidationModel: PatientShowValidationModel =
			new PatientShowValidationModel();
		if (!patientID) {
			patientShowValidationModel.patientIDError = strings(
				'patientUploadRecords.errors.patientIDMissing',
			);
			patientShowValidationModel.isError = true;
		}

		return patientShowValidationModel;
	}

	/**
	 * return images study data of dicom
	 * @param {ImageStudyRequestModel} requestdata
	 * @returns {<Record<string, any> | ImageStudyValidationModel>}
	 */
	getImageStudyFormDicom(
		requestdata: ImageStudyRequestModel,
	): Observable<Record<string, any> | ImageStudyValidationModel> {
		const getImageStudyFormDicomResponse = new Observable(
			(
				subscriber: Subscriber<Record<string, any> | ImageStudyValidationModel>,
			) => {
				const imageStudyValidationModel: ImageStudyValidationModel =
					this.validateImageStudyRequestModel(requestdata);
				if (!imageStudyValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.getImageStudyFormDicom(requestdata)
						.then(({data, headers, status}) => {
							if (data.totalCount > 0) {
								subscriber.next(data);
								subscriber.complete();
							} else {
								this.notificationMessageSubject.next({
									type: NotificationMessageType.toast,
									level: NotificationMessageLevel.error,
									message: strings(
										'patientUploadRecords.listDocuments.studyInProcessLabel',
									),
									visibility: NotificationVisibility.local,
								});
								subscriber.next(undefined);
								subscriber.complete();
							}
						})
						.catch(error => {
							subscriber.error(error);
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
								message: strings(
									'patientUploadRecords.listDocuments.errorMsgOpenViewer',
								),
								visibility: NotificationVisibility.local,
							});
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(imageStudyValidationModel);
				}
			},
		);
		return getImageStudyFormDicomResponse;
	}

	/**
	 * validates getImageStudyFormDicom methode request data
	 * @param {ImageStudyRequestModel} data
	 * @returns {ImageStudyValidationModel}
	 */
	private validateImageStudyRequestModel(
		data: ImageStudyRequestModel,
	): ImageStudyValidationModel {
		const imageStudyValidationModel: ImageStudyValidationModel =
			new ImageStudyValidationModel();
		const imageStudyConstraints: ImageStudyConstraints =
			data.getRequestDataObject();
		if (!imageStudyConstraints.constraints) {
			imageStudyValidationModel.constraintsError = strings(
				'patientUploadRecords.errors.constraintsMissing',
			);
			imageStudyValidationModel.isError = true;
		}
		return imageStudyValidationModel;
	}

	/**
	 * update and edit document
	 * @param data
	 * @returns {<Record<string, any>>}
	 */
	updateEditedDocument(
		data: UpdateEditDocumentRequestModel,
	): Observable<Record<string, any> | UpdateEditDocumentValidationModel> {
		const updateEditedDocumentResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | UpdateEditDocumentValidationModel
				>,
			) => {
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OnScreen,
				});
				this.documenListNetworkRepository
					.updateEditedDocument(data)
					.then(({data, headers, status}) => {
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.success,
							message: strings('documentUpdate.updated'),
							visibility: NotificationVisibility.local,
						});
						subscriber.next(data);
						subscriber.complete();
					})
					.catch(error => {
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
							message: strings('documentUpdate.error'),
							visibility: NotificationVisibility.dialog,
						});
						subscriber.error(error);
					})
					.finally(() => {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OnScreen,
						});
					});
			},
		);

		return updateEditedDocumentResponse;
	}

	private validateUpdateEditDocumentRequestModel(
		data: UpdateEditDocumentRequestModel,
	): UpdateEditDocumentValidationModel {
		const updateEditDocumentValidationModel: UpdateEditDocumentValidationModel =
			new UpdateEditDocumentValidationModel();
		const updateEditDocumentRequestData: UpdateEditDocumentRequestData =
			data.getRequestDataObject();
		if (!updateEditDocumentRequestData.conditionId) {
			updateEditDocumentValidationModel.conditionIdError = strings(
				'patientUploadRecords.errors.conditionIdMissing',
			);
			updateEditDocumentValidationModel.isError = true;
		}
		if (!updateEditDocumentRequestData.encounterId) {
			updateEditDocumentValidationModel.encounterIdError = strings(
				'patientUploadRecords.errors.encounterIdMissing',
			);
			updateEditDocumentValidationModel.isError = true;
		}
		if (!updateEditDocumentRequestData.locationId) {
			updateEditDocumentValidationModel.locationIdError = strings(
				'patientUploadRecords.errors.locationIdMissing',
			);
			updateEditDocumentValidationModel.isError = true;
		}
		if (!updateEditDocumentRequestData.physicianId) {
			updateEditDocumentValidationModel.physicianIdError = strings(
				'patientUploadRecords.errors.physicianIdMissing',
			);
			updateEditDocumentValidationModel.isError = true;
		}
		if (!updateEditDocumentRequestData.createdDate) {
			updateEditDocumentValidationModel.createdDateError = strings(
				'patientUploadRecords.errors.createdDateMissing',
			);
			updateEditDocumentValidationModel.isError = true;
		}
		return updateEditDocumentValidationModel;
	}

	/**
	 * updates document reference visibility
	 * @param data
	 * @returns {<Record<string, any>>}
	 */
	updateVisibility(
		data: UpdateVisibilityRequestData,
	): Observable<Record<string, any> | UpdateVisibilityValidationModel> {
		const updateVisibilityRequestModel: UpdateVisibilityRequestModel =
			new UpdateVisibilityRequestModel(data);
		const updateVisibilityResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | UpdateVisibilityValidationModel
				>,
			) => {
				const updateVisibilityValidationModel: UpdateVisibilityValidationModel =
					this.validateUpdateVisibilityRequestModel(
						updateVisibilityRequestModel,
					);
				if (!updateVisibilityValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.updateVisibility(updateVisibilityRequestModel)
						.then(({data, headers, status}) => {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.success,
								message: strings(
									'docSearchObj.searchResult.manageVisibility.successMessage',
								),
								visibility: NotificationVisibility.local,
							});
							subscriber.next({response: data, status: true});
							subscriber.complete();
						})
						.catch(error => {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
								message: strings(
									'docSearchObj.searchResult.manageVisibility.errorMessage',
								),
								visibility: NotificationVisibility.local,
							});
							subscriber.error(error);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(updateVisibilityValidationModel);
				}
			},
		);

		return updateVisibilityResponse;
	}

	/**
	 * validates the request model
	 * @param {UpdateVisibilityRequestModel} data
	 * @returns {UpdateVisibilityValidationModel}
	 */

	private validateUpdateVisibilityRequestModel(
		data: UpdateVisibilityRequestModel,
	): UpdateVisibilityValidationModel {
		const uppdateVisibilityValidationModel: UpdateVisibilityValidationModel =
			new UpdateVisibilityValidationModel();
		const updateVisibilityRequestData: UpdateVisibilityRequestData =
			data.getRequestDataObject();
		if (!updateVisibilityRequestData.id) {
			uppdateVisibilityValidationModel.idError = strings(
				'patientUploadRecords.errors.idMissing',
			);
			uppdateVisibilityValidationModel.isError = true;
		}
		if (!updateVisibilityRequestData.visible) {
			uppdateVisibilityValidationModel.visibleError = strings(
				'patientUploadRecords.errors.visibleMissing',
			);
			uppdateVisibilityValidationModel.isError = true;
		}
		return uppdateVisibilityValidationModel;
	}

	/**
	 * sends docuemnts to the repository
	 * @param data
	 * @returns {<Record<string, any>>}
	 */
	sendToIHERepository(
		data: SendToIHERepositoryRequetsModel,
	): Observable<Record<string, any> | SendToIHERepositoryValidationModel> {
		const sendToIHERepositoryResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | SendToIHERepositoryValidationModel
				>,
			) => {
				const sendToIHERepositoryValidationModel: SendToIHERepositoryValidationModel =
					this.validateSendToIHERepositoryRequetsModel(data);
				if (!sendToIHERepositoryValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.sendToIHERepository(data)
						.then(({data, headers, status}) => {
							subscriber.next(data);
							subscriber.complete();
						})
						.catch(error => {
							subscriber.error(error);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(sendToIHERepositoryValidationModel);
				}
			},
		);

		return sendToIHERepositoryResponse;
	}

	/**
	 * validates sendtoIHERequestModel before sending Apirequest
	 * @param {SendToIHERepositoryRequetsModel} data
	 * @returns {SendToIHERepositoryValidationModel}
	 */
	private validateSendToIHERepositoryRequetsModel(
		data: SendToIHERepositoryRequetsModel,
	): SendToIHERepositoryValidationModel {
		const sendToIHERepositoryValidationModel: SendToIHERepositoryValidationModel =
			new SendToIHERepositoryValidationModel();
		const sendToIHERepositoryRequetsData: SendToIHERepositoryRequetsData =
			data.getRequestDataObject();
		if (!sendToIHERepositoryRequetsData.id) {
			sendToIHERepositoryValidationModel.idError = strings(
				'patientUploadRecords.errors.destinationListMissing',
			);
			sendToIHERepositoryValidationModel.isError = true;
		}
		return sendToIHERepositoryValidationModel;
	}

	/**
	 * sends bulk download action
	 * @param data
	 * @returns {<Record<string, any>>}
	 */
	sendBulkRequest(
		data: BulkActionRequestModel,
	): Observable<BulkActionResponseModel | BulkActionValidationModel> {
		const sendBulkRequestResponse = new Observable(
			(
				subscriber: Subscriber<
					BulkActionResponseModel | BulkActionValidationModel
				>,
			) => {
				const bulkActionValidationModel: BulkActionValidationModel =
					this.validateBulkActionRequestModel(data);
				if (!bulkActionValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.sendBulkRequest(data)
						.then(({data, headers, status}) => {
							const bulkActionResponseModel: BulkActionResponseModel =
								new BulkActionResponseModel(data);
							subscriber.next(bulkActionResponseModel);
							subscriber.complete();
						})
						.catch(error => {
							const bulkActionResponseModel: BulkActionResponseModel =
								new BulkActionResponseModel(error);
							subscriber.error(bulkActionResponseModel);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(bulkActionValidationModel);
				}
			},
		);

		return sendBulkRequestResponse;
	}

	private validateBulkActionRequestModel(
		data: BulkActionRequestModel | ShareDocRequestModel,
	): BulkActionValidationModel {
		const bulkActionValidationModel: BulkActionValidationModel =
			new BulkActionValidationModel();
		const bulkActionRequestData: BulkActionRequestData =
			data.getRequestDataObject();
		if (!bulkActionRequestData.actionName) {
			bulkActionValidationModel.actionNameError = strings(
				'patientUploadRecords.errors.actionNameMissing',
			);
			bulkActionValidationModel.isError = true;
		}
		if (!bulkActionRequestData.searchCriteria) {
			bulkActionValidationModel.searchCriteriaError = strings(
				'patientUploadRecords.errors.searchCriteriaMissing',
			);
			bulkActionValidationModel.isError = true;
		}
		return bulkActionValidationModel;
	}

	/**
	 * cancels dicom download in viewModel
	 * @param data
	 * @returns {<Record<string, any>>}
	 */
	cancelDicomDownload(dicomRequestId: number): Observable<Record<string, any>> {
		const cancelDicomDownloadResponse = new Observable(
			(subscriber: Subscriber<Record<string, any>>) => {
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OnScreen,
				});
				this.documenListNetworkRepository
					.cancelDicomDownload(dicomRequestId)
					.then(({data, headers, status}) => {
						subscriber.next(data);
						subscriber.complete();
					})
					.catch(error => {
						subscriber.error(error);
					})
					.finally(() => {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OnScreen,
						});
					});
			},
		);

		return cancelDicomDownloadResponse;
	}

	/**
	 * cancel's bulk download for non dicom documents
	 * @param data
	 * @returns {<Record<string, any>>}
	 */
	cancelBulkDownload(
		data: CancelBulkUploadRequestModel,
	): Observable<
		CancleBulkDownLoadRequestResponseModel | CancelBulkUploadValidationModel
	> {
		const cancelBulkDownloadResponse = new Observable(
			(
				subscriber: Subscriber<
					| CancleBulkDownLoadRequestResponseModel
					| CancelBulkUploadValidationModel
				>,
			) => {
				const cancelBulkUploadValidationModel: CancelBulkUploadValidationModel =
					this.validateCancelBulkUploadRequestModel(data);
				if (!cancelBulkUploadValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.cancelBulkDownload(data)
						.then(({data, headers, status}) => {
							const cancleBulkDownLoadRequestResponseModel: CancleBulkDownLoadRequestResponseModel =
								new CancleBulkDownLoadRequestResponseModel(data);
							subscriber.next(cancleBulkDownLoadRequestResponseModel);
							subscriber.complete();
						})
						.catch(error => {
							const cancleBulkDownLoadRequestResponseModel: CancleBulkDownLoadRequestResponseModel =
								new CancleBulkDownLoadRequestResponseModel(error);
							subscriber.error(cancleBulkDownLoadRequestResponseModel);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(cancelBulkUploadValidationModel);
				}
			},
		);

		return cancelBulkDownloadResponse;
	}

	/**
	 * validate cancel bulk uploade request data
	 * @param {CancelBulkUploadRequestModel} data
	 * @returns {CancelBulkUploadValidationModel}
	 */
	private validateCancelBulkUploadRequestModel(
		data: CancelBulkUploadRequestModel,
	): CancelBulkUploadValidationModel {
		const cancelBulkUploadValidationModel: CancelBulkUploadValidationModel =
			new CancelBulkUploadValidationModel();
		const cancelBulkUploadRequestData: CancelBulkUploadRequestData =
			data.getRequestDataObject();
		if (!cancelBulkUploadRequestData.id) {
			cancelBulkUploadValidationModel.idError = strings(
				'patientUploadRecords.errors.idMissing',
			);
			cancelBulkUploadValidationModel.isError = true;
		}
		return cancelBulkUploadValidationModel;
	}

	updateDocumentReference(
		data: DocumentReferenceUpdateRequestData,
		docID: number,
	): Observable<
		DocumentReferencUpdateResponseData | DocumentReferenceUpdateValidationModel
	> {
		const documentReferenceUpdateRequestModel: DocumentReferenceUpdateRequestModel =
			new DocumentReferenceUpdateRequestModel(data);
		const updateDocumentReferenceResponse = new Observable(
			(
				subscriber: Subscriber<
					| DocumentReferencUpdateResponseData
					| DocumentReferenceUpdateValidationModel
				>,
			) => {
				const documentReferenceUpdateValidationModel: DocumentReferenceUpdateValidationModel =
					this.validateDocumentReferenceUpdateRequestModel(
						documentReferenceUpdateRequestModel,
						docID,
					);
				if (!documentReferenceUpdateValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.updateDocumentReference(documentReferenceUpdateRequestModel, docID)
						.then(({data, headers, status}) => {
							const documentReferencUpdateResponseModel: DocumentReferencUpdateResponseModel =
								new DocumentReferencUpdateResponseModel(data);
							this.notificationMessageSubject.next({
								type: NotificationMessageType.alert,
								level: NotificationMessageLevel.success,
								message: strings('document.toast.success.delete'),
								visibility: NotificationVisibility.local,
							});
							subscriber.next(
								documentReferencUpdateResponseModel.getResponseDataObject(),
							);
							subscriber.complete();
						})
						.catch(error => {
							const documentReferencUpdateResponseModel: DocumentReferencUpdateResponseModel =
								new DocumentReferencUpdateResponseModel(error);
							this.notificationMessageSubject.next({
								type: NotificationMessageType.alert,
								level: NotificationMessageLevel.error,
								message: strings('errors.somethingWentWrong'),
								visibility: NotificationVisibility.local,
							});
							subscriber.error(documentReferencUpdateResponseModel);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(documentReferenceUpdateValidationModel);
				}
			},
		);

		return updateDocumentReferenceResponse;
	}

	/**
	 * validate update document reference APi request model
	 * @param {DocumentReferenceUpdateRequestModel} data
	 * @returns {DocumentReferenceUpdateValidationModel}
	 */

	private validateDocumentReferenceUpdateRequestModel(
		data: DocumentReferenceUpdateRequestModel,
		docID: number,
	): DocumentReferenceUpdateValidationModel {
		const documentReferenceUpdateValidationModel: DocumentReferenceUpdateValidationModel =
			new DocumentReferenceUpdateValidationModel();
		const documentReferenceUpdateRequestData: DocumentReferenceUpdateRequestData =
			data.getRequestDataObject();
		if (!documentReferenceUpdateRequestData.status) {
			documentReferenceUpdateValidationModel.statusError = strings(
				'patientUploadRecords.errors.statusMissing',
			);
			documentReferenceUpdateValidationModel.isError = true;
		}

		if (!docID) {
			documentReferenceUpdateValidationModel.DocIDError = strings(
				'patientUploadRecords.errors.docIDMissing',
			);
			documentReferenceUpdateValidationModel.isError = true;
		}

		return documentReferenceUpdateValidationModel;
	}

	/**
	 * download the selected document
	 * @param {DocumentReferenceDownloadRequestData} data
	 * @param {number} subjectID
	 * @returns {<Record<string, any> | DocumentReferenceDownloadRequestValidation>}
	 */
	downloadUploadDocument(
		data: DocumentReferenceDownloadRequestData,
		subjectID: number,
	): Observable<
		Record<string, any> | DocumentReferenceDownloadRequestValidation
	> {
		const documentReferenceDownloadRequestModel: DocumentReferenceDownloadRequestModel =
			new DocumentReferenceDownloadRequestModel(data);
		const downloadUploadDocumentResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | DocumentReferenceDownloadRequestValidation
				>,
			) => {
				const doumentRefenceDownLoadRequesteValidation: DocumentReferenceDownloadRequestValidation =
					this.validateDocumentRefenceDownloadRequestModel(
						documentReferenceDownloadRequestModel,
					);
				if (!doumentRefenceDownLoadRequesteValidation.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.downloadUploadDocument(
							documentReferenceDownloadRequestModel,
							subjectID,
						)
						.then(({data, headers, status}) => {
							subscriber.next(data);
							subscriber.complete();
						})
						.catch(error => {
							subscriber.error(error);
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
								message: strings(
									'docSearchObj.searchResult.list.fileNotFoundErrorMessage',
								),
								visibility: NotificationVisibility.local,
							});
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(doumentRefenceDownLoadRequesteValidation);
				}
			},
		);

		return downloadUploadDocumentResponse;
	}

	/**
	 * validates document download reference request data
	 * @param {DocumentReferenceDownloadRequestModel} data
	 * @returns {DocumentReferenceDownloadRequestData}
	 */
	private validateDocumentRefenceDownloadRequestModel(
		data: DocumentReferenceDownloadRequestModel,
	): DocumentReferenceDownloadRequestValidation {
		const documentReferenceDownloadRequestValidation: DocumentReferenceDownloadRequestValidation =
			new DocumentReferenceDownloadRequestValidation();
		const doumentRefrenceDownLoadRequestData: DocumentReferenceDownloadRequestData =
			data.getRequestDataObject();
		if (!doumentRefrenceDownLoadRequestData.docRefId) {
			documentReferenceDownloadRequestValidation.docRefIdError = strings(
				'patientUploadRecords.errors.docRefIdMissing',
			);
			documentReferenceDownloadRequestValidation.isError = true;
		}
		if (!doumentRefrenceDownLoadRequestData.constraints) {
			documentReferenceDownloadRequestValidation.constraintsError = strings(
				'patientUploadRecords.errors.constraintsMissing',
			);
			documentReferenceDownloadRequestValidation.isError = true;
		}
		return documentReferenceDownloadRequestValidation;
	}

	/**
	 * questionnaire response showApplication
	 * @param {any} data
	 * @param {number} subjectID
	 * @returns {<Record<string, any>>}
	 */
	showQuestionnaireResponse(
		data: any,
		appId: number,
	): Observable<Record<string, any>> {
		const showQuestionnaireResponseApplication = new Observable(
			(subscriber: Subscriber<Record<string, any>>) => {
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OnScreen,
				});
				this.documenListNetworkRepository
					.showQuestionnaireResponse(data, appId)
					.then(({data, headers, status}) => {
						subscriber.next(data);
						subscriber.complete();
					})
					.catch(error => {
						subscriber.error(error);
					})
					.finally(() => {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OnScreen,
						});
					});
			},
		);
		return showQuestionnaireResponseApplication;
	}

	/**
	 * fetch forward config
	 * @returns {<Record<string, any>>}
	 */
	fetchForwardConfig(): Observable<Record<string, any>> {
		const showQuestionnaireResponseApplication = new Observable(
			(subscriber: Subscriber<Record<string, any>>) => {
				this.documenListNetworkRepository
					.fetchForwardConfig()
					.then(({data, headers, status}) => {
						subscriber.next(data);
						subscriber.complete();
					})
					.catch(error => {
						subscriber.error(error);
					});
			},
		);
		return showQuestionnaireResponseApplication;
	}

	/**
	 * generate document url
	 * @param {number} uploadedRecordId
	 * @returns {string}
	 */
	generateDocumentUrl(
		uploadedRecordId: number,
	): Observable<Record<string, any>> {
		const documentUrlResponse = new Observable(
			(subscriber: Subscriber<Record<string, any>>) => {
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OnScreen,
				});
				this.documenListNetworkRepository
					.fetchDocumentViewUrl(uploadedRecordId)
					.then(({data, headers, status}) => {
						subscriber.next(data);
						subscriber.complete();
					})
					.catch(error => {
						subscriber.error(error);
					})
					.finally(() => {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OnScreen,
						});
					});
			},
		);
		return documentUrlResponse;
	}

	/**
	 * validates sendEmrOrPacs request data
	 * @param {SendToEmrOrPacsModel} data
	 * @returns {SendToEmrOrPacsRequestData}
	 */
	private validateSendToEmrOrPacsRequestModel(
		data: SendToEmrOrPacsModel,
	): SendToEmrOrPacsRequestValidation {
		const sendToEmrOrPacsRequestValidation: SendToEmrOrPacsRequestValidation =
			new SendToEmrOrPacsRequestValidation();
		const sendToEmrOrPacsRequestData: SendToEmrOrPacsRequestData =
			data.getRequestDataObject();
		if (!sendToEmrOrPacsRequestData.id) {
			sendToEmrOrPacsRequestValidation.uploadRecordId = strings(
				'patientUploadRecords.errors.idMissing',
			);
			sendToEmrOrPacsRequestValidation.isError = true;
		}
		return sendToEmrOrPacsRequestValidation;
	}

	/**
	 * sendToEmrOrPacs
	 * @param {SendToEmrOrPacsRequestData} data
	 * @returns {<Record<string, any>}
	 */
	sendToEmrOrPacs(
		data: SendToEmrOrPacsRequestData,
		qResponse: boolean,
	): Observable<Record<string, any> | SendToEmrOrPacsRequestValidation> {
		const sendToEmrOrPacsRequestModel: SendToEmrOrPacsModel =
			new SendToEmrOrPacsModel(data);
		const sendToEmrOrPacsModelResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | SendToEmrOrPacsRequestValidation
				>,
			) => {
				const sendToEmrOrPacsRequestValidation: SendToEmrOrPacsRequestValidation =
					this.validateSendToEmrOrPacsRequestModel(sendToEmrOrPacsRequestModel);
				if (!sendToEmrOrPacsRequestValidation.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.documenListNetworkRepository
						.sendToEmrOrPacs(sendToEmrOrPacsRequestModel, qResponse)
						.then(({data, headers, status}) => {
							subscriber.next(data);
							if (status === 400) {
								this.notificationMessageSubject.next({
									type: NotificationMessageType.alert,
									level: NotificationMessageLevel.error,
									message: strings('sendDocumentsModal.fileNotFound'),
									visibility: NotificationVisibility.local,
								});
							}
							if (status === 200) {
								this.notificationMessageSubject.next({
									type: NotificationMessageType.alert,
									level: NotificationMessageLevel.success,
									message: strings(
										'sendDocumentsModal.sentRequestNotification',
									),
									visibility: NotificationVisibility.local,
								});
							}
							subscriber.complete();
						})
						.catch(error => {
							subscriber.error(error);
							this.notificationMessageSubject.next({
								type: NotificationMessageType.alert,
								level: NotificationMessageLevel.error,
								message: strings('sendDocumentsModal.exceptionOccured'),
								visibility: NotificationVisibility.global,
							});
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(sendToEmrOrPacsRequestValidation);
				}
			},
		);

		return sendToEmrOrPacsModelResponse;
	}

	/**
	 * checkIfDocumentDeleteAllowed
	 * @deprecated
	 * @param {documentReference}
	 * @param {user}
	 * @returns {boolean}
	 */
	checkIfDocumentDeleteAllowed(
		documentReference: Record<string, any>,
		user: any,
		isAllowed: boolean,
	): boolean {
		let documentDeleteEnabled = false;
		const uploaderList = documentReference.uploadedBy;
		if (isAllowed) {
			documentDeleteEnabled = true;
			documentReference.deletable = false;
			if (
				documentReference.status ===
				global.ConfigurationHolder?.deletedDocumentStatus
			) {
				documentReference.deletable = false;
			} else if (hasAnyRoles(['ROLE_DOCUMENT_ADMIN'], user)) {
				documentReference.deletable = true;
			} else if (uploaderList && uploaderList.length > 0) {
				if (typeof user.id !== 'string') {
					if (user.id.toString() === uploaderList[0])
						documentReference.deletable = true;
				} else if (user.id === uploaderList[0])
					documentReference.deletable = true;
			}
		}
		return documentDeleteEnabled;
	}

	/**
	 * checkIfDocumentDeleteAllowed
	 * @param {documentReference}
	 * @param {patientEncounterList}
	 * @returns {void}
	 */
	addEncounterDetailsToDocumentReferenceObject(
		documentReference: Record<string, any>,
		patientEncounterList: Array<Record<string, any>>,
	): void {
		if (documentReference.encounterId) {
			patientEncounterList?.forEach(function (encounterObject) {
				if (
					encounterObject.id &&
					documentReference.encounterId === encounterObject.id.toString()
				) {
					documentReference.encounterStartDate = encounterObject.startDate;
					documentReference.encounterLocationName =
						encounterObject.locationName;
				}
			});
		}
	}

	/**
	 * isDocumentEditable
	 * @param {documentReference}
	 * @param {user}
	 * @returns {void}
	 */
	isDocumentEditable(documentReference: Record<string, any>, user: any): void {
		documentReference.isEditable = false;

		if (hasAnyRoles(['ROLE_DOCUMENT_ADMIN'], user)) {
			documentReference.isEditable = true;
			return;
		}
		if (
			documentReference.uploadedBy &&
			documentReference.uploadedBy.indexOf(user.id.toString()) !== -1
		) {
			documentReference.isEditable = true;
		}
	}

	/**
	 * isDocumentEditable
	 * @param {documentReference}
	 * @param {patientId}
	 * @returns {url}
	 */
	generateXDSUrlIfExists(
		documentReference: Record<string, any>,
		patientId: number,
	): string {
		let url = '';
		const xdsMap = getXDSDocument(documentReference);
		if (xdsMap.isXDSDocument) {
			const docId = documentReference.documentId
				? documentReference.documentId
				: '';
			url =
				'/XDS/fetchDocumentByDocIdAndRepoId/' +
				docId +
				'?repoUniqueId=' +
				xdsMap.repoUniqueId +
				'&docUniqueId=' +
				xdsMap.docUniqueId +
				'&subject=' +
				patientId;
		} else {
			url = '';
		}
		return url;
	}

	/**
	 * This method is used to initialize the configuration fetching logic for dynamic form.
	 * @deprecated common share module to be used, refer to shareDocument function
	 * @param {vitalConfig}
	 * @return {void}
	 */
	public fetchShareDocFormDataConfig(
		configurationObject: DocumentListConfigData,
	): void {
		encryptedStorageHelperInstance
			.getItem(sessionStorageKeys.SERVER_CONFIG)
			.then(serverConfig => {
				if (configurationObject.documentShareFormData) {
					configurationObject.documentShareFormData?.formData?.map(segment => {
						segment.formFields.map(formField => {
							if (
								formField.name === 'sharingWithPin' &&
								serverConfig.isSharingWithoutPinActive === false
							) {
								formField.visible = false;
							} else if (
								formField.name === 'pinVitalShare' &&
								serverConfig.isSharingWithoutPinActive === false
							) {
								formField.visible = true;
								formField.required = true;
							}
						});
					});
					this.configModelSubject.next(
						configurationObject?.documentShareFormData,
					);
				}
			});
	}

	/**
	 * This method is used to share doc data before sharing it
	 * @param shareDocFormData
	 * @param data
	 * @param ConfigurationHolder
	 */
	public validateDataBeforeSharing(
		shareDocFormData: DynamicFormDataV2,
		data: any,
		ConfigurationHolder: Record<string, any>,
		isSharingWithoutPinActive: boolean | undefined,
	): boolean {
		let isValid = true;
		shareDocFormData.formData.map(segment => {
			segment.formFields.map(formField => {
				if (formField.name == VITAL.RECIPIENT_VITAL_SHARE) {
					const isEmailsValid = areEmailsValid(data.recipientVitalShare);
					if (!isEmailsValid) {
						isValid = false;
						formField.helpText = strings(
							'forms.attributes.helpText.invalidExternalEmailsMessage',
						);
					}
				} else if (
					formField.name == VITAL.PIN_VITAL_SHARE &&
					!(isSharingWithoutPinActive && data.sharingWithPin === 'false')
				) {
					const sharingPin = String(data.pinVitalShare);
					if (
						data.pinVitalShare >
							ConfigurationHolder.vitals.sharingPinMaxValue ||
						sharingPin.length > ConfigurationHolder.vitals.sharingPinLength ||
						(sharingPin.length > 0 && Number(sharingPin[0]) == 0) ||
						sharingPin.length != ConfigurationHolder.vitals.sharingPinLength
					) {
						isValid = false;
						formField.helpText = strings(
							'forms.attributes.helpText.invalidSharingPinMessage',
						);
					}
				}
			});
		});
		this.configModelSubject.next(shareDocFormData);
		return isValid;
	}

	/**
	 * This method is used to prepare Share Document Request Model
	 * @deprecated common share module to be used, refer to shareDocument function
	 * @param data
	 * @param patientId
	 * @param actionName
	 * @param selectedResourceIds
	 */
	public prepareShareDocRequestModel(
		data: any,
		patientId: number,
		actionName: string,
		selectedResourceIds: Array<number>,
	): ShareDocRequestModelType {
		const shareDocRequestModelType: ShareDocRequestModelType = {
			actionName: '',
			additionalInfo: {
				externalEmailIds: [],
				message: '',
				noOfDaysForExpiry: 0,
				shareWith: [],
				sharingType: '',
				sharingWithPin: true,
			},
			searchCriteria: {
				constraints: {
					patientId: 0,
				},
			},
			selectedResourceIds: [],
		};
		shareDocRequestModelType.actionName = actionName;
		shareDocRequestModelType.additionalInfo['externalEmailIds'] =
			data.recipientVitalShare.split(',');
		shareDocRequestModelType.additionalInfo['noOfDaysForExpiry'] = Number(
			data.validVitalShare,
		);
		shareDocRequestModelType.additionalInfo['message'] = data.messageVitalShare;
		if (data.pinVitalShare) {
			shareDocRequestModelType.additionalInfo['sharingPin'] = String(
				data.pinVitalShare,
			);
		}
		shareDocRequestModelType.additionalInfo['sharingType'] =
			VITAL.EXTERNAL_SHARING_TYPE;
		shareDocRequestModelType.additionalInfo['sharingWithPin'] =
			data.sharingWithPin === 'true';
		shareDocRequestModelType.searchCriteria['constraints']['patientId'] =
			patientId;
		shareDocRequestModelType.selectedResourceIds = selectedResourceIds;

		return shareDocRequestModelType;
	}

	/**
	 * This method is used to share documents
	 * @deprecated common share module to be used, refer to shareDocument function
	 * @returns {Observable<BulkRequestResponseData | ShareDocValidationModel | BulkActionValidationModel>}
	 * @param shareDocRequestModel
	 */
	shareDocs(
		shareDocRequestModel: ShareDocRequestModel,
	): Observable<
		| BulkRequestResponseData
		| ShareDocValidationModel
		| BulkActionValidationModel
	> {
		return new Observable(
			(
				subscriber: Subscriber<
					| BulkRequestResponseData
					| ShareDocValidationModel
					| BulkActionValidationModel
				>,
			) => {
				const shareDocValidationModel: ShareDocValidationModel =
					this.validateShareDocRequestModel(shareDocRequestModel);
				const bulkActionValidationModel: BulkActionValidationModel =
					this.validateBulkActionRequestModel(shareDocRequestModel);
				if (!bulkActionValidationModel.isError) {
					if (!shareDocValidationModel.isError) {
						this.loaderSubject.next({
							isToShowLoader: true,
							type: LoaderType.OverScreen,
						});
						this.documenListNetworkRepository
							.createBulkRequestForDocSharing(shareDocRequestModel)
							.then(({data}) => {
								const shareDocResponseModel: BulkActionResponseModel =
									new BulkActionResponseModel(data);
								this.notificationMessageSubject.next({
									type: NotificationMessageType.toast,
									level: NotificationMessageLevel.success,
									message: strings(
										'records.sharing.toastMessages.successMessageForCDASharing',
									),
									visibility: NotificationVisibility.local,
									closeButtonEnabled: true,
								});
								subscriber.next(shareDocResponseModel.getResponseDataObject());
								subscriber.complete();
							})
							.catch(error => {
								this.notificationMessageSubject.next({
									type: NotificationMessageType.toast,
									level: NotificationMessageLevel.error,
									message: strings(
										'records.sharing.toastMessages.rejectedMessage',
									),
									visibility: NotificationVisibility.dialog,
									closeButtonEnabled: true,
								});
								subscriber.error(error);
							})
							.finally(() => {
								this.loaderSubject.next({
									isToShowLoader: false,
									type: LoaderType.OnScreen,
								});
							});
					} else {
						subscriber.next(shareDocValidationModel);
					}
				} else {
					subscriber.next(bulkActionValidationModel);
				}
			},
		);
	}

	/**
	 * This method is used to validate share vital request model
	 * @deprecated common share module to be used, refer to shareDocument function
	 * @returns {ShareDocRequestModel}
	 * @param shareDocRequestModel
	 */
	validateShareDocRequestModel(
		shareDocRequestModel: ShareDocRequestModel,
	): ShareDocValidationModel {
		const shareDocValidationModel: ShareDocValidationModel =
			new ShareDocValidationModel();
		const shareDocRequestDataModel =
			shareDocRequestModel.getRequestDataObject();
		if (
			!shareDocRequestDataModel.additionalInfo.externalEmailIds ||
			shareDocRequestDataModel.additionalInfo.externalEmailIds.length == 0
		) {
			shareDocValidationModel.externalEmailIdsError = strings(
				'vitals.errors.externalEmailMissingError',
			);
			shareDocValidationModel.isError = true;
		}
		if (!shareDocRequestDataModel.additionalInfo.noOfDaysForExpiry) {
			shareDocValidationModel.noOfDaysForExpiryError = strings(
				'vitals.errors.numberOfDaysMissingError',
			);
			shareDocValidationModel.isError = true;
		}
		if (!shareDocRequestDataModel.searchCriteria.constraints.patientId) {
			shareDocValidationModel.patientIdError = strings(
				'vitals.errors.patientIdMissingError',
			);
			shareDocValidationModel.isError = true;
		}
		if (!shareDocRequestDataModel.additionalInfo.sharingType) {
			shareDocValidationModel.sharingTypeError = strings(
				'vitals.errors.sharingTypeMissingError',
			);
			shareDocValidationModel.isError = true;
		}
		return shareDocValidationModel;
	}

	/**
	 * used to show error notification for form error.
	 */
	formErrorHandler(documentIdToFocus: string) {
		this.notificationMessageSubject.next({
			type: NotificationMessageType.toast,
			level: NotificationMessageLevel.error,
			message: strings('errors.genericFormError'),
			visibility: NotificationVisibility.dialog,
			documentIdToFocus: documentIdToFocus,
		});
	}

	fetchDocumentListModelSubjectObservable = () => {
		return this.documentListModelSubject.asObservable();
	};

	/**
	 * it refresh the record of document list model
	 */
	updateDocumentListModelSubject = () => {
		this.documentListModelSubject.next(this.documentListModel);
	};

	/**
	 * setTranslationForUploaderSourceTypes
	 * @param configurationObject
	 * @param user
	 */
	setTranslationForUploaderSourceTypes = (
		configurationObject: DocumentListConfigData | Record<string, any>,
		user: Record<string, any>,
	): void => {
		if (configurationObject && isValueAvailable(configurationObject)) {
			const sourceTypeList = configurationObject.uploaderSourceTypes
				? configurationObject.uploaderSourceTypes[user.userType]
				: [];
			if (sourceTypeList && sourceTypeList.length) {
				this.documentListModel.translatedSourceTypeList = [];
				sourceTypeList.forEach((eachSource: string) => {
					const translatedObject = {
						key: eachSource,
						translatedValue: strings(
							'docSearchObj.searchFilter.source.' + eachSource,
						),
					};
					this.documentListModel.translatedSourceTypeList.push(
						translatedObject,
					);
				});
				this.updateDocumentListModelSubject();
			}
		}
	};

	/**
	 * function to load documentFilters -documentType,Practitioners,condition, encounter start here
	 * @param user
	 * @param uploaderViewModel
	 * @param patientId
	 */
	initializeDataForDocumentSearch = (
		user: Record<string, any>,
		uploaderViewModel: UploaderViewModel,
		patientId: number,
	): void => {
		const searchConstraints: Record<string, any> = {
			constraints: {
				_fields: 'author,extension,type,taskId,careFacility,contextDocument',
			},
		};
		if (!user) {
			searchConstraints.constraints['authParams'] = 'showSelf';
		} else {
			searchConstraints.constraints['subject'] = patientId;
		}
		this.loaderSubject.next({
			isToShowLoader: true,
			type: LoaderType.OnScreen,
		});
		uploaderViewModel.docRefSearchData(searchConstraints).subscribe(
			(response: any) => {
				//for documentType
				this.documentListModel.docRefTypeList = [];
				response.docRefType.forEach((type: string) => {
					if (type) {
						this.documentListModel.docRefTypeList.push(type);
					}
				});

				//for Practitioner
				this.documentListModel.docRefPractitionerList = [];
				response.docRefPractitioner.forEach((record: Record<string, any>) => {
					const objectToBeAdded = {
						username: nameFromList(record.name),
						identifier: record.identifier[0] ? record.identifier[0].value : '',
						id: record.id,
						participant: record,
					};
					this.documentListModel.docRefPractitionerList.push(objectToBeAdded);
				});

				//for task List
				this.documentListModel.taskObjectMap = [];
				response.docRefTask.forEach((task: Record<string, any>) => {
					if (task.category && task.dueDate) {
						this.documentListModel.taskList.push(task);
						this.documentListModel.taskObjectMap[task.id] =
							task.name + ' ( ' + task.dueDate + ' )';
					}
				});

				//for questionnaire response
				this.documentListModel.questionnaireResponseList = [];
				response.questionnaireResponse.forEach(
					(questionnaireResponse: Record<string, any>) => {
						if (
							questionnaireResponse.identifier &&
							questionnaireResponse.identifier.value &&
							questionnaireResponse.authored
						) {
							this.documentListModel.questionnaireResponseList.push(
								questionnaireResponse,
							);

							const questionnaireDate = getFormattedDate(
								questionnaireResponse.authored.value,
								this.config.dates.dateFormat,
							);

							this.documentListModel.questionnaireResponseMap[
								questionnaireResponse.identifier.value
							] =
								questionnaireResponse.questionnaire.subjectType +
								' ( ' +
								questionnaireDate +
								' )';
						}
					},
				);

				//For careFacility
				this.documentListModel.careFacilityList = [];
				response.careFacility.forEach((careFacility: Record<string, any>) => {
					this.documentListModel.careFacilityList.push(careFacility);
					this.documentListModel.careFacilityMap[careFacility.id] =
						careFacility.serviceTitle;
				});

				//for condition
				this.documentListModel.patientConditionList = [];
				this.documentListModel.patientConditionList = response.docRefCondition;
				//for encounter

				//for documentStatus
				this.documentListModel.documentStatuses = ['ACTIVE', 'INACTIVE'];

				this.documentListModel.patientEncounterList = [];
				response.docRefEncounter.forEach((encounter: Record<string, any>) => {
					if (encounter.id) {
						this.documentListModel.patientEncounterList.push(encounter);
					}
				});
				this.updateDocumentListModelSubject();

				this.documentListModel.patientSurgeryList = [];
				response.docRefServiceRequest.forEach(
					(surgery: Record<string, any>) => {
						if (surgery.occurrence && surgery.id) {
							this.documentListModel.patientSurgeryList.push(surgery);
							this.documentListModel.serviceRequestDateMap[surgery.id] = surgery
								.occurrence.occurrenceDateTime
								? surgery.occurrence.occurrenceDateTime.value
								: null;
						}
					},
				);
				this.updateDocumentListModelSubject();
				this.showDataGridSubject.next(true);
				//setSearchParams(requestParams);
				this.loaderSubject.next({
					isToShowLoader: false,
					type: LoaderType.OnScreen,
				});
			},
			(error: any) => {
				console.log(
					'initializeDataForDocumentSearch: error occurred: ',
					error.message,
				);
				this.showDataGridSubject.next(true);
				this.loaderSubject.next({
					isToShowLoader: false,
					type: LoaderType.OnScreen,
				});
			},
		);
	};

	fetchDataGridFlagObservable = () => {
		return this.showDataGridSubject.asObservable();
	};

	/**
	 * This method is used to create download bulk action request
	 * @returns {Observable<BulkRequestResponseData | BulkActionValidationModel>}
	 */
	createDelteBulkActionRequest(
		action: string,
		totalDocumentsCount: number,
		subjectID: number | string | undefined,
		emrDestinationForBulkAction: any | undefined,
		selectAll: boolean,
		deSelectedResourceIds: Array<number | string> | undefined,
		selectedResourceIds: Array<number | string>,
		searchCriteria: Record<string, any>,
	): Observable<BulkRequestResponseData | BulkActionValidationModel> {
		return new Observable(
			(
				subscriber: Subscriber<
					BulkRequestResponseData | BulkActionValidationModel
				>,
			) => {
				const bullkActionRequestData: BulkActionRequestData = {
					actionName: action,
				};
				if (selectAll && subjectID) {
					bullkActionRequestData.deSelectedResourceIds = deSelectedResourceIds;
					bullkActionRequestData.patientId = subjectID;
					if (deSelectedResourceIds) {
						searchCriteria.constraints._count =
							totalDocumentsCount - deSelectedResourceIds.length;
					}
					bullkActionRequestData.searchCriteria = searchCriteria;
				} else {
					bullkActionRequestData.selectedResourceIds = selectedResourceIds;
					searchCriteria.constraints._count = selectedResourceIds.length;
					bullkActionRequestData.searchCriteria = searchCriteria;
				}
				if (bullkActionRequestData.searchCriteria?.constraints?._count <= 1) {
					subscriber.complete();
				}
				bullkActionRequestData.searchCriteria = searchCriteria;
				if (emrDestinationForBulkAction) {
					bullkActionRequestData.sendAllToEMRDetails =
						emrDestinationForBulkAction;
				}
				const bulkActionRequestModel: BulkActionRequestModel =
					new BulkActionRequestModel(bullkActionRequestData);
				const bulkActionValidationModel: BulkActionValidationModel =
					this.validateBulkActionRequestModel(bulkActionRequestModel);
				if (!bulkActionValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OverScreen,
					});
					this.documenListNetworkRepository
						.createBulkRequestForDocument(bulkActionRequestModel)
						.then(({data}) => {
							const bulkActionResponseModel: BulkActionResponseModel =
								new BulkActionResponseModel(data);
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.success,
								message: strings(
									'docSearchObj.searchResult.deleteAllDocuments.deleteRequestCreated',
								),
								visibility: NotificationVisibility.local,
								closeButtonEnabled: true,
							});
							subscriber.next(bulkActionResponseModel.getResponseDataObject());
							subscriber.complete();
						})
						.catch(error => {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
								message: strings(
									'docSearchObj.searchResult.deleteAllDocuments.deleteFailed',
								),
								visibility: NotificationVisibility.local,
								closeButtonEnabled: true,
							});
							subscriber.error(error);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(bulkActionValidationModel);
				}
			},
		);
	}

	/**
	 * This method is used to create download bulk action request
	 * @returns {Observable<BulkRequestResponseData | BulkActionValidationModel>}
	 */
	createDownloadBulkActionRequest(
		action: string,
		totalDocumentsCount: number,
		subjectID: number | string | undefined,
		emrDestinationForBulkAction: any | undefined,
		selectAll: boolean,
		deSelectedResourceIds: Array<number | string> | undefined,
		selectedResourceIds: Array<number | string>,
		searchCriteria: Record<string, any>,
	): Observable<BulkRequestResponseData | BulkActionValidationModel> {
		return new Observable(
			(
				subscriber: Subscriber<
					BulkRequestResponseData | BulkActionValidationModel
				>,
			) => {
				const bullkActionRequestData: BulkActionRequestData = {
					actionName: action,
				};
				if (selectAll && subjectID) {
					bullkActionRequestData.deSelectedResourceIds = deSelectedResourceIds;
					bullkActionRequestData.patientId = subjectID;
					if (deSelectedResourceIds) {
						searchCriteria.constraints._count =
							totalDocumentsCount - deSelectedResourceIds.length;
					}
					bullkActionRequestData.searchCriteria = searchCriteria;
				} else {
					bullkActionRequestData.selectedResourceIds = selectedResourceIds;
					searchCriteria.constraints._count = selectedResourceIds.length;
					bullkActionRequestData.searchCriteria = searchCriteria;
				}
				if (bullkActionRequestData.searchCriteria?.constraints?._count <= 1) {
					subscriber.complete();
				}
				bullkActionRequestData.searchCriteria = searchCriteria;
				if (emrDestinationForBulkAction) {
					bullkActionRequestData.sendAllToEMRDetails =
						emrDestinationForBulkAction;
				}
				encryptedStorageHelperInstance
					.getItem(sessionStorageKeys.AUTH_TOKEN)
					.then(token => {
						if (!this.websocketService.status() && token) {
							this.websocketService.createWebSocketConnectionWithToken(token);
						}
					});
				const bulkActionRequestModel: BulkActionRequestModel =
					new BulkActionRequestModel(bullkActionRequestData);
				const bulkActionValidationModel: BulkActionValidationModel =
					this.validateBulkActionRequestModel(bulkActionRequestModel);
				if (!bulkActionValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OverScreen,
					});
					this.documenListNetworkRepository
						.createBulkRequestForDocument(bulkActionRequestModel)
						.then(({data}) => {
							const bulkActionResponseModel: BulkActionResponseModel =
								new BulkActionResponseModel(data);
							encryptedStorageHelperInstance
								.getItem(sessionStorageKeys.AUTH_TOKEN)
								.then(token => {
									if (token) {
										const message = {
											token: token,
											message: 'GET_BULK_DOWNLOAD_STATUS',
											proc: 'DEP',
											id: data.id,
										};
										this.websocketService.send(message);
									}
								});
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.success,
								message: strings(
									'docSearchObj.searchResult.downloadAllDocuments.downloadRequestCreated',
								),
								visibility: NotificationVisibility.local,
								closeButtonEnabled: true,
							});
							subscriber.next(bulkActionResponseModel.getResponseDataObject());
							subscriber.complete();
						})
						.catch(error => {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
								message: strings(
									'docSearchObj.searchResult.downloadAllDocuments.downloadFailed',
								),
								visibility: NotificationVisibility.local,
								closeButtonEnabled: true,
							});
							subscriber.error(error);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(bulkActionValidationModel);
				}
			},
		);
	}

	/**
	 * This method is used to create sendToEmr bulk action request
	 * @returns {Observable<BulkRequestResponseData | BulkActionValidationModel>}
	 */
	createSendEmrBulkActionRequest(
		action: string,
		totalDocumentsCount: number,
		subjectID: number | string | undefined,
		emrDestinationForBulkAction: any | undefined,
		selectAll: boolean,
		deSelectedResourceIds: Array<number | string> | undefined,
		selectedResourceIds: Array<number | string>,
		searchCriteria: Record<string, any>,
	): Observable<BulkRequestResponseData | BulkActionValidationModel> {
		return new Observable(
			(
				subscriber: Subscriber<
					BulkRequestResponseData | BulkActionValidationModel
				>,
			) => {
				const bullkActionRequestData: BulkActionRequestData = {
					actionName: action,
				};
				if (selectAll && subjectID) {
					bullkActionRequestData.deSelectedResourceIds = deSelectedResourceIds;
					bullkActionRequestData.patientId = subjectID;
					if (deSelectedResourceIds) {
						searchCriteria.constraints._count =
							totalDocumentsCount - deSelectedResourceIds.length;
					}
					bullkActionRequestData.searchCriteria = searchCriteria;
				} else {
					bullkActionRequestData.selectedResourceIds = selectedResourceIds;
					searchCriteria.constraints._count = selectedResourceIds.length;
					bullkActionRequestData.searchCriteria = searchCriteria;
				}
				if (bullkActionRequestData.searchCriteria?.constraints?._count <= 1) {
					subscriber.complete();
				}
				bullkActionRequestData.searchCriteria = searchCriteria;
				if (emrDestinationForBulkAction) {
					bullkActionRequestData.sendAllToEMRDetails =
						emrDestinationForBulkAction;
				}
				const bulkActionRequestModel: BulkActionRequestModel =
					new BulkActionRequestModel(bullkActionRequestData);
				const bulkActionValidationModel: BulkActionValidationModel =
					this.validateBulkActionRequestModel(bulkActionRequestModel);
				if (!bulkActionValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OverScreen,
					});
					this.documenListNetworkRepository
						.createBulkRequestForDocument(bulkActionRequestModel)
						.then(({data}) => {
							const bulkActionResponseModel: BulkActionResponseModel =
								new BulkActionResponseModel(data);
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.success,
								message: strings(
									'docSearchObj.searchResult.sendAllDocumentsToEMR.sendRequestCreated',
								),
								visibility: NotificationVisibility.local,
								closeButtonEnabled: true,
							});
							subscriber.next(bulkActionResponseModel.getResponseDataObject());
							subscriber.complete();
						})
						.catch(error => {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
								message: strings(
									'docSearchObj.searchResult.sendAllDocumentsToEMR.sendFailed',
								),
								visibility: NotificationVisibility.global,
								closeButtonEnabled: true,
							});
							subscriber.error(error);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(bulkActionValidationModel);
				}
			},
		);
	}

	/**
	 * load all uploader configs from uploader view model
	 * @param user
	 * @returns
	 */
	fetchUploaderConfigResponse = (
		user: Record<string, any>,
	): Observable<UploaderConfigResponse> => {
		return new Observable((subcriber: Subscriber<UploaderConfigResponse>) => {
			this.uploaderViewModel.fetchUploaderConfigs(user).subscribe({
				next(response) {
					if (response) {
						subcriber.next(response);
						subcriber.complete();
					}
				},
			});
		});
	};

	/**
	 * initialize labels for form fields
	 * @param editedDocument
	 */
	initLabels = (editedDocument: Record<string, any>) => {
		this.uploaderViewModel.setLabelsForUploaderItem(
			editedDocument,
			editedDocument.documentType,
		);
	};

	/**
	 * get location type for document type
	 * @param editModel
	 * @param documentType
	 * @returns
	 */
	getLocationTypesForDocumentType = (
		editModel: Record<string, any>,
		documentType?: string,
	) => {
		let locationTypeList: any = [];
		if (!documentType) {
			for (const locationCat in editModel.locationTypeCategories) {
				if (_.has(editModel.locationTypeCategories, locationCat)) {
					locationTypeList = locationTypeList.concat(
						editModel.locationTypeCategories[locationCat],
					);
				}
			}
		} else {
			if (
				editModel.documentTypeLocationCategoryMap &&
				editModel.documentTypeLocationCategoryMap[documentType]
			) {
				editModel.documentTypeLocationCategoryMap[documentType].forEach(
					(locationCategory: any) => {
						locationTypeList = locationTypeList.concat(
							editModel.locationTypeCategories[locationCategory],
						);
					},
				);
			}
		}
		return _.uniq(locationTypeList);
	};

	/**
	 * get unique element based on resource ids
	 * @param list
	 * @returns
	 */
	getUniqueElementBasedOnResourceId = (list: Array<Record<string, any>>) => {
		const uniqueElements: Array<Record<string, any>> = [];
		if (!list) {
			return uniqueElements;
		}
		list?.map(listElement => {
			let found = false;
			for (let i = 0; i < uniqueElements.length; i++) {
				if (listElement.value === uniqueElements[i].value) {
					found = true;
					break;
				}
			}
			if (!found) {
				uniqueElements.push(listElement);
			}
		});
		return uniqueElements;
	};

	/**
	 * intialize edit document modal
	 */
	initEditDocument = (
		uploaderConfigResponse: UploaderConfigResponse,
		user: Record<string, any>,
		patientId: number,
		editedDocument: Record<string, any>,
		config: Record<string, any>,
	): Observable<Record<string, any>> => {
		return new Observable((subscriber: Subscriber<Record<string, any>>) => {
			const editModel: Record<string, any> = {};
			editModel.uploaderTypeMapWithUserType =
				uploaderConfigResponse.uploaderTypeMapWithUserType;
			try {
				editModel.uploaderScreenType =
					editModel.uploaderTypeMapWithUserType[user.userType];
				if (!editModel.uploaderScreenType) {
					editModel.uploaderScreenType = 'restrictedEditable';
				}
				editModel.showEditableFields = true;
			} catch (ex) {
				editModel.uploaderScreenType = 'restrictedEditable';
				editModel.showEditableFields = true;
			} finally {
				const documentTypeList = uploaderConfigResponse?.documentType?.map(
					(documentType: string) => {
						return {label: documentType, value: documentType};
					},
				);
				editModel.documentTypeList = documentTypeList;
				editModel.documentTypeLocationCategoryMap =
					uploaderConfigResponse?.documentTypeLocationCategoryMap;
				editModel.locationTypeCategories =
					uploaderConfigResponse?.locationTypeCategories;
				this.initLabels(editedDocument);
				const promiseList = [];
				promiseList.push(
					new Promise<void>(resolve => {
						this.uploaderViewModel
							.fetchPatientActiveEncounters(patientId)
							.subscribe({
								next(encounterList) {
									if (encounterList && encounterList.length > 0) {
										editModel.encounterList = encounterList;
										resolve();
										encounterList?.forEach((item: Record<string, any>) => {
											if (
												Number(editedDocument.encounterId) ===
												Number(item.value)
											) {
												editedDocument.encounter = item;
											}
										});
									} else {
										resolve();
									}
								},
							});
					}),
				);
				promiseList.push(
					new Promise<void>(resolve => {
						this.uploaderViewModel
							.fetchPatientConditionsForPhysicianUpload(user, patientId)
							.subscribe({
								next(responseData: Record<string, any>) {
									if (responseData) {
										editModel.patientConditionList = responseData.list
											? responseData.list
											: [];
										if (responseData.list && responseData.list.length > 0) {
											editModel.patientConditionList =
												editModel?.patientConditionList?.map(
													(item: Record<string, any>) => {
														return {label: item?.code?.text, value: item.id};
													},
												);
											editModel?.patientEncounterList?.forEach(
												(item: Record<string, any>) => {
													if (editedDocument.condition === item.value) {
														editedDocument.condition = item;
													}
												},
											);
										}
									}
									resolve();
								},
							});
					}),
				);
				promiseList.push(
					new Promise<void>(resolve => {
						const loctionSearchCriteria: LocationSearchRequestData = {
							fields: 'name,type',
							typeIn: this.getLocationTypesForDocumentType(
								editModel,
								undefined,
							).join(','),
						};
						const locationSearchRequestModel: LocationSearchRequestModel =
							new LocationSearchRequestModel(loctionSearchCriteria);
						this.uploaderViewModel
							.locationSearch(locationSearchRequestModel, true)
							.subscribe((response: Record<string, any>) => {
								if (response) {
									const responseList = response.list;
									resolve();
									editModel.originalLocationList = _.cloneDeep(responseList);
									if (responseList && responseList.length) {
										const locationList = responseList?.map(
											(item: Record<string, any>) => {
												return {label: item.name, value: item.id};
											},
										);
										editModel.locationList =
											this.getUniqueElementBasedOnResourceId(locationList);
									}
								} else {
									resolve();
								}
							});
					}),
				);
				Promise.all(promiseList).then(resp => {
					const data = {
						editModel: editModel,
						editedDocument: editedDocument,
					};
					subscriber.next(data);
					subscriber.complete();
				});
			}
		});
	};

	/**
	 * change label on document type select
	 * @param editedDocument
	 * @param type
	 */
	handleLabelChange = (editedDocument: Record<string, any>, type: string) => {
		this.uploaderViewModel.setLabelsForUploaderItem(editedDocument, type);
	};

	/**
	 *
	 * @param locationList
	 * @param documentTypeLocationCategoryMap
	 * @param locationTypeCategories
	 * @param documentType
	 * @returns list
	 */
	getlocationForLocationTypeFilter = (
		locationList: Array<Record<string, any>>,
		documentTypeLocationCategoryMap: Record<string, any>,
		locationTypeCategories: Record<string, any>,
		documentType: string,
	): Array<Record<string, any>> => {
		return this.uploaderViewModel.locationForLocationTypeFilter(
			locationList,
			documentTypeLocationCategoryMap,
			locationTypeCategories,
			documentType,
		);
	};

	/**
	 * used to load default practitioner for given constraint
	 * @param constraint
	 */
	loadDefaultPractioner = (
		requestData: Record<string, any>,
	): Observable<Record<string, any>> => {
		return new Observable((subscriber: Subscriber<Record<string, any>>) => {
			this.documenListNetworkRepository
				.fetchDefaultPractitioner(requestData)
				.then(({data}) => {
					subscriber.next(data);
					subscriber.complete();
				})
				.catch(error => {
					subscriber.error(error);
				});
		});
	};
}

export default DocumentListViewModel;
