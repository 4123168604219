enum VITAL {
	BLOOD_PRESSURE = 'BloodPressure',
	FAHRENHEIT = 'fahrenheit',
	CELCIUS = 'celsius',
	PERCENTAGE = 'percentage',
	FASTING = 'Fasting',
	RANDOM = 'Random',
	AFTER_MEAL = 'AfterMeal',
	OVERVIEW_TYPE = 'overviewType',
	DETAIL_TYPE = 'detailType',
	SYSTOLIC = 'Systolic',
	DIASTOLIC = 'Diastolic',
	BMI_UPPER = 'BMI',
	VALUE = 'value',
	UNIT = 'unit',
	VALUE_HANDLER = 'valueHandler',
	UNIT_HANDLER = 'unitHandler',
	BREATHS_MIN = 'breaths/min',
	BEATS_MIN = 'beats/min',
	SYSTOLIC_VALUE = 'systolicValue',
	DIASTOLIC_VALUE = 'diastolicValue',
	MMHG = 'mmHg',
	CMH2O = 'cmH2O',
	CENTIMETER = 'cm',
	FEET = 'ft',
	INCH = 'in',
	METER = 'm',
	KILOGRAM = 'kg',
	POUND = 'lb',
	FEET_INCH = 'ft-in',
	MG_DL = 'mg/dL',
	MMOL_L = 'mmol/L',
	FEET_VALUE = 'feetValue',
	INCH_VALUE = 'inchValue',
	ADD = 'add',
	UPDATE = 'update',
	PARAM_ID = 'paramId',
	USUAL = 'usual',
	PRACTITIONER_U = 'PRACTITIONER',
	PRACTITIONER_C = 'Practitioner',
	PATIENT = 'PATIENT',
	SELF = 'Self',
	METRIC = 'metric',
	IMPERIAL = 'imperial',
	HEIGHT_VALUE = 'heightValue',
	WEIGHT_VALUE = 'weightValue',
	WEIGHT_METRIC_VALUE = 'weightMetricUnit',
	WEIGHT_IMPERIAL_VALUE = 'weightImperialUnit',
	HEIGHT_UNIT_IMPERIAL = 'heightUnitImperial',
	HEIGHT_UNIT_METRIC = 'heightUnitMetric',
	TODAY = 'today',
	YESTERDAY = 'yesterday',
	THREEDAYS = '3days',
	SEVENDAYS = '7days',
	ONEMONTH = '30days',
	CUSTOM = 'custom',
	BMI_VALUE_UPDATE = 'bmiValueUpdate',
	SHARING = 'sharing',
	SHARE = 'share',
	RECIPIENT_VITAL_SHARE = 'recipientVitalShare',
	PIN_VITAL_SHARE = 'pinVitalShare',
	EXTERNAL_SHARING_TYPE = 'EXTERNAL_SHARING',
	ROLE_DELETE_VITAL = 'ROLE_DELETE_VITAL',
	ROLE_UPDATE_VITAL = 'ROLE_UPDATE_VITAL',
	ROLE_ADMIN_OBSERVATION = 'ROLE_ADMIN_OBSERVATION',
	ROLE_CREATE_OBSERVATION = 'ROLE_CREATE_OBSERVATION',
	ROLE_SHARE_VITAL = 'ROLE_SHARE_VITAL',
	TEMPERATURE = 'Temperature',
}
export default VITAL;
