import NetworkCallProvider from '../modules/generic/models/NetworkCallProvider';
import FormResendOTPRequest from '../modules/forms/composite/models/FormResendOTPRequest';
import FormResendOTPResponse from '../modules/forms/composite/models/FormResendOTPResponse';
import {APIUrl} from '../network/values/URLInfo';
import NetworkRequestMethodEnum from '../../enums/NetworkRequestMethodEnum';
import FormVerifyOTPRequest from '../modules/forms/composite/models/FormVerifyOTPRequest';
import FormVerifyOTPResponse from '../modules/forms/composite/models/FormVerifyOTPResponse';
import {strings} from '../../../../localization/i18n';

/**
 * This method provides NetworkCallProvider for sending OTP for forms
 * @param {string} countryCode to send in API request
 * @param {string} phoneNumber to send in API request
 * @param {string} email to send in API request
 */
export const getNetworkCallProviderForResend = (
	countryCode?: string,
	phoneNumber?: string,
	email?: string,
) => {
	if (
		(countryCode == null && phoneNumber == null && email == null) ||
		(countryCode != null && phoneNumber == null) ||
		(countryCode == null && phoneNumber != null)
	) {
		throw new Error(
			'Trying to initialize getNetworkCallProviderForResend with invalid data',
		);
	}

	return new NetworkCallProvider<
		FormResendOTPRequest,
		FormResendOTPResponse,
		null
	>(
		APIUrl.COMPOSITE_FORM_SEND_ONE_TIME_PASSWORD,
		{},
		NetworkRequestMethodEnum.POST,
		(): FormResendOTPRequest => {
			return new FormResendOTPRequest({
				countryCode: countryCode,
				phoneNumber: phoneNumber,
				email: email,
			});
		},
		(_response: {
			data: Record<string, any>;
			status: number;
			headers: Record<string, any>;
		}): {
			response: FormResendOTPResponse;
			isSuccessful: boolean;
			errorMessage?: string;
			successMessage?: string;
		} => {
			const responseModel = new FormResendOTPResponse(_response.data);
			const responseObject = responseModel.getResponseDataObject();
			const getErrorMessage = () => {
				if (!(responseObject.success)) {
					switch (responseObject.info?.code) {
						case 'MOSR65':
							return strings('otpScreen.maxLimitReached');
					}
				}
				return undefined;
			};
			const getSuccessMessage = () => {
				if (
					(responseObject.success) &&
					responseObject.info?.totalSentsAvailable != null
				) {
					return strings('otpScreen.otpSentSuccessfully', {
						sentsAvailable: responseObject.info?.totalSentsAvailable,
					});
				}
				return undefined;
			};
			return {
				response: responseModel,
				isSuccessful: responseObject.success,
				errorMessage: getErrorMessage(),
				successMessage: getSuccessMessage(),
			};
		},
	);
};

/**
 * This method provides NetworkCallProvider for verifying OTP for forms
 * @param {string} countryCode to send in API request
 * @param {string} phoneNumber to send in API request
 * @param {string} email to send in API request
 */
export const getNetworkCallProviderForVerify = (
	countryCode?: string,
	phoneNumber?: string,
	email?: string,
) => {
	if (
		(countryCode == null && phoneNumber == null && email == null) ||
		(countryCode != null && phoneNumber == null) ||
		(countryCode == null && phoneNumber != null)
	) {
		throw new Error(
			'Trying to initialize getNetworkCallProviderForVerify with invalid data',
		);
	}

	return new NetworkCallProvider<
		FormVerifyOTPRequest,
		FormVerifyOTPResponse,
		string
	>(
		APIUrl.COMPOSITE_FORM_VERIFY_ONE_TIME_PASSWORD,
		{},
		NetworkRequestMethodEnum.POST,
		(_otp: string): FormVerifyOTPRequest => {
			return new FormVerifyOTPRequest({
				countryCode: countryCode,
				phoneNumber: phoneNumber,
				email: email,
				otp: _otp,
			});
		},
		(_response: {
			data: Record<string, any>;
			status: number;
			headers: Record<string, any>;
		}): {
			response: FormVerifyOTPResponse;
			isSuccessful: boolean;
			errorMessage?: string;
			successMessage?: string;
		} => {
			const responseModel = new FormVerifyOTPResponse(_response.data);
			const responseObject = responseModel.getResponseDataObject();
			const getErrorMessage = () => {
				if (!(responseObject.success)) {
					switch (responseObject.error?.code) {
						case 'ONM68':
							return strings('otpScreen.incorrectOTP', {
								attemptsLeft: responseObject.error?.attemptsLeft ?? 0,
							});
						case 'ONF69':
							return strings('otpScreen.maxLimitReached');
					}
				}
				return undefined;
			};
			const getSuccessMessage = () => {
				if (responseObject.success) {
					return strings('otpScreen.verified');
				}
				return undefined;
			};
			return {
				response: responseModel,
				isSuccessful: responseObject.success,
				errorMessage: getErrorMessage(),
				successMessage: getSuccessMessage(),
			};
		},
	);
};
