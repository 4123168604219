import { TableHeader } from "../../../../../common/src/interface/dataGrid/IDataGrid";
import { ConfigProvider } from "../config.mapper";
import { getFormattedDate } from "../uiCompsUtils";

const parseAccess = (object: any, accessString :string = '', header?: TableHeader, configProvider?: ConfigProvider): any => {

	const keys: Array<string> = accessString.split(/[,[\].]+?/).filter(Boolean);
	let res = keys.reduce((res, key) => res?.[key as keyof {}], object);
	if(header?.dataType === 'date'){
		const datesObject : Record<string, string>= configProvider?.getConfig().datesFormatsObject ?? {}
		const format = datesObject[header.dateFormat ?? 'dateFormat']
		let value  = getFormattedDate(res, format)
		res = value ?? res
	}
	return res;
};

export default parseAccess;
