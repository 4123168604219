class CancelBulkUploadValidationModel {
    constructor(
        private _idError: string = "",
        private _isError: boolean = false
    ) {

    }

    set idError(value: string) {
        this._idError = value
    }
    get idError(): string {
        return this._idError
    }

    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}

export default CancelBulkUploadValidationModel