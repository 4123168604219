import {strings} from '../../../../../../localization/i18n';

const HelpText = (props: any): JSX.Element => {
	// todo - create help icon and place the value inside that.
	const {content, name} = props;
	return (
		<>
			{content && (
				<div className="form-text" id={`${name}_helpText_${name}`}>
					{strings(content)}
				</div>
			)}
		</>
	);
};

export default HelpText;
