/**
 * This enumeration is used to map the route paths.
 */
const RoutePathInfo = {
	auth: {
		path: '/auth',
		title: 'userLogin',
	},
	startYourJourney: {
		path: '/startYourJourney',
		title: 'startYourJourney',
	},
	signup: {
		path: '/patientSignup',
		title: 'patientSignup',
	},
	registerSuccess: {
		path: '/registerSuccess',
		title: 'registerSuccess',
	},
	dashBoard: {
		path: '/dashboard',
		title: 'dashBoard',
	},
	patientSearch: {
		path: '/patientSearch',
		title: 'patientSearch',
	},
	sysSettings: {
		path: '/sysSettings',
		title: 'sysSettings',
	},
	widgets: {
		path: '/widgets',
		title: 'widgets',
	},
	registerSuccessAccountReady: {
		path: '/registerSuccess/accountReady',
		title: 'registerSuccess',
	},
	registerUser: {
		path: '/registerUser',
		title: 'userResgistration',
	},
	registerUserSuccessAccountReady: {
		path: '/registerUserSuccess/accountReady',
		title: 'registerSuccess',
	},
	appointmentList: {
		path: '/appointmentsList',
		title: 'appoinmentList',
	},
	formBuilder: {
		path: 'formBuilder',
		title: 'formBuilder',
	},
	forgotUsername: {
		path: '/forgotUsername',
		title: 'forgotUsername',
	},
	forgotPassword: {
		path: '/forgotPassword',
		title: 'forgotPassword',
	},
	twoFactorAuth: {
		path: '/twoFactorAuth',
		title: 'twoFactorAuth',
	},
	identiferBasedTfaAuth: {
		path: '/identiferTwoFactorAuth',
		title: 'identiferTwoFactorAuth',
	},
	firstLoginChangePassword: {
		path: '/firstLoginChangePassword',
		title: 'firstLoginChangePassword',
	},
	resetPassword: {
		path: '/resetPassword/:verificationCode',
		title: 'resetPassword,',
	},
	notAccessible: {
		path: '/notAccessible',
		title: 'notAccessible',
	},
	defaultLandingPage: {
		path: '/defaultLandingPage',
		title: 'defaultLandingPage',
	},
	createPatient: {
		path: '/patientSave',
		title: 'createPatient',
	},
	patientProfile: {
		path: '/patientProfile/view/:patientId',
		title: 'patientProfile',
	},
	patientProfileFromSearch: {
		path: ':patientId/viewProfile',
		title: 'patientProfile',
	},
	editPatientProfile: {
		path: '/patientProfile/edit/:patientId',
		title: 'patientProfile',
	},
	sendQuestionnaire: {
		path: '/sendQuestionnaire',
		title: 'sendQuestionnaire',
	},
	assignCarePlan: {
		path: '/assignCarePlan',
		title: 'assignCarePlan',
	},
	updatePatient: {
		path: '/assignCarePlan',
		title: 'assignCarePlan',
	},
	patientOverview: {
		path: 'newPatientDetails/:patientId',
		title: 'patientOverview',
	},
	patientTimeline: {
		path: ':patientId/encounters',
		title: 'patientTimeline',
	},
	patientRecords: {
		path: '/patient/showAllRecords/',
		title: 'patientRecords',
	},
	uploadedDocuments: {
		path: ':patientId/uploadedDocuments',
		title: 'uploadedDocuments',
	},
	uploadMedicalRecords: {
		path: ':patientId/uploadMedicalRecords',
		title: 'uploadMedicalRecords',
	},
	vitals: {
		path: '/patient/:patientId/vitals',
		title: 'vitals',
	},
	vitalDetail: {
		path: '/patient/:patientId/vitalDetail/:vitalName/:vitalUniqueCode',
		title: 'vitalDetail',
	},
	patient: {
		path: '/patient',
		title: 'patientDetails',
	},
	questionnaireResponse: {
		path: ':patientId/questionnaireResponses',
		title: 'Questionnaire Responses',
	},
	agreements: {
		path: '/agreements',
		title: 'agreementsView',
	},
	auditList: {
		path: '/audit/list',
		title: 'auditList',
	},
	formListing: {
		path: ':patientId/formsList',
		title: 'formsList',
	},
	createForm: {
		path: ':patientId/createForm',
		title: 'createForm',
	},
	linkedPatient: {
		path: '/linkedPatients',
		title: 'linketPatient',
	},
	results: {path: ':patientId/showAllRecords', title: 'results'},
	medications: {path: '/patient/:patientId/medications', title: 'medications'},
	dischargeSummary: {
		path: 'encounter/:encounterId/dischargeSummary',
		title: 'dischargeSummary',
	},
	viewUserProfile: {
		path: '/userProfile/view',
		title: 'userProfile',
	},
	editUserProfile: {
		path: '/userProfile/edit',
		title: 'userProfile',
	},
	helpDesk: {
		path: '/inbox/helpdesk',
		title: 'helpDesk',
	},
	fillPublicForm: {
		path: 'fillPublicForm',
		title: 'fillPublicForm',
	},
	revokeConsent: {
		path: '/revokeConsent',
		title: 'revokeConsent',
	},
	notifications: {
		path: '/notifications',
		title: 'notifications',
	},
	dashboard: {path: 'patientDetails/:patientId', title: 'dashboard'},
	othersFaq: {
		path: '/oth/faqs',
		title: 'othersFaq',
	},
	phyFaq: {
		path: '/phy/faqs',
		title: 'phyFaq',
	},
	patientFaq: {
		path: 'patient/faqs',
		title: 'patientFaq',
	},
	showAllRecords: {
		path: 'showAllRecords/%3Fsubject=:patientId',
		title: 'showAllRecords',
	},
	orderSummary: {
		path: 'orders/summary/:patientId/:orderId',
		title: 'orderSummary',
	},
	recordDetails: {
		path: 'recordDetails/:patientId/:orderId',
		title: 'recordDetails',
	},
	linkedNewPatient: {
		path: 'linkNewPatient',
		title: 'linkNewPatient',
	},
	imageViewer: {
		path: 'recordDetails/:id/viewer',
		title: 'imageViewer',
	},
	raiseQuery: {
		path: '/inbox/helpdesk/newQuery',
		title: 'raiseQuery',
	},
	appointmentDetails: {
		path: '/appointment/details/:appointmentId',
		title: 'appointmentDetails',
	},
	meetingDetails: {
		path: '/meetingDetails/:appointmentId',
		title: 'meetingDetails',
	},
	patAppointmentDetails: {
		path: '/patient/:patientId/appointment/:appointmentId/details',
		title: 'patAppointmentDetails',
	},
	deletionRequestAccepted: {
		path: '/deletionRequestAccepted',
		title: 'deletionRequestAccepted',
	},
};

export default RoutePathInfo;
