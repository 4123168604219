import BaseResponseModel from '../../../../network/interface/BaseResponseModel';

export default class DocumentListResponse implements BaseResponseModel {
	private readonly _list: Array<DocumentModel> | undefined;
	private readonly _totalCount: number | undefined;

	constructor({list, totalCount}: DocumentListResponseType) {
		this._list = list;
		this._totalCount = totalCount;
	}

	/**
	 * This method returns the response received from /minerva/MoDocumentReference/search API call
	 * @returns {DocumentListResponseType}
	 */
	getResponseDataObject(): DocumentListResponseType {
		return {
			list: this._list,
			totalCount: this._totalCount,
		};
	}
}

export type DocumentListResponseType = {
	totalCount?: number;
	list?: Array<DocumentModel>;
};
export type DocumentModel = {
	modality?: string;
	isDownloaded?: string;
	documentType?: string;
	patientId?: string;
	linkedStudyDescription?: string;
	repositoryUniqueID?: string;
	visitNumber?: string;
	description?: string;
	source?: string;
	encounterId?: string;
	uploadedDate?: string;
	encounterStartDate?: string;
	linkedStudyModality?: string;
	physician?: string;
	encounterLocationName?: string;
	patientName?: string;
	base64MimeType?: string;
	documentUniqueID?: string;
	xdsDocIdentifier?: string;
	condition?: string;
	createdDate?: string;
	base64Image?: string;
	fileSize?: string;
	name?: string;
	documentId?: string;
	location?: string;
	sentDestinationList?: string;
	studyId?: string;
	contentDocumentRecordUrl?: string;
	contentDocumentType?: string;
	fileType?: string;
	status?: string;
	uploadedBy?: Array<string>;

	availableOptions: Array<DocumentListOptionsMenu>
};

export enum DocumentListOptionsMenu {
	DOCUMENT_SHARE,
	DOCUMENT_DELETE,
	DOCUMENT_VIEW,
	DOCUMENT_DOWNLOAD,
	DOCUMENT_EDIT,
}
