import BaseResponseModel from "../../../../network/interface/BaseResponseModel";

class PatientShowResponseModel implements BaseResponseModel {
    private readonly extension?: Array<Record<string, any>>;
    private readonly deceased?: DeceasedData;
    private readonly gender?: string;
    private readonly accessibleActions?: Array<any>;
    private readonly displayName?: string;
    private readonly generalPractitionerOrganization?: Array<any>;
    private readonly lowerCaseName?: Array<NameData>;
    private readonly lastUpdated?: string;
    private readonly linkList?: Array<any>;
    private readonly contact?: Array<ContactArrayData>;
    private readonly identifierHistory?: Array<any>;
    private readonly telecom?: Array<Record<string, any>>;
    private readonly id?: number;
    private readonly UUID?: string;
    private readonly communication?: Array<any>;
    private readonly identifier?: Array<Record<string, any>>;
    private readonly address?: Array<AddressData>;
    private readonly visible?: boolean;
    private readonly active?: ActiveStatusData;
    private readonly birthDate?: BirthDateData;
    private readonly generalPractitionerRole?: Array<any>;
    private readonly version?: number;
    private readonly careProvider?: Array<any>;
    private readonly name?: NameData;
    private readonly nameInitials?: string;
    private readonly resourceType?: string;
    private readonly photo?:Array<Photo>;


    constructor({
                    extension,
                    deceased,
                    gender,
                    accessibleActions,
                    displayName,
                    generalPractitionerOrganization,
                    lowerCaseName,
                    lastUpdated,
                    linkList,
                    contact,
                    identifierHistory,
                    telecom,
                    id,
                    UUID,
                    communication,
                    identifier,
                    address,
                    visible,
                    active,
                    birthDate,
                    generalPractitionerRole,
                    version,
                    careProvider,
                    name,
                    nameInitials,
                    resourceType,
                    photo,
                }: PatientShowResponseData) {
        this.extension = extension;
        this.deceased = deceased;
        this.gender = gender;
        this.accessibleActions = accessibleActions;
        this.displayName = displayName;
        this.generalPractitionerOrganization = generalPractitionerOrganization;
        this.lowerCaseName = lowerCaseName;
        this.lastUpdated = lastUpdated;
        this.linkList = linkList;
        this.contact = contact;
        this.identifierHistory = identifierHistory;
        this.telecom = telecom;
        this.id = id;
        this.UUID = UUID;
        this.communication = communication;
        this.identifier = identifier;
        this.address = address;
        this.visible = visible;
        this.active = active;
        this.birthDate = birthDate;
        this.generalPractitionerRole = generalPractitionerRole;
        this.version = version;
        this.careProvider = careProvider;
        this.name = name;
        this.nameInitials = nameInitials;
        this.resourceType = resourceType;
        this.photo = photo;
    }

    /**
     * returns patient show response
     * @returns {PatientShowResponseData}
     */
    getResponseDataObject(): PatientShowResponseData {
        return {
            extension: this.extension,
            deceased: this.deceased,
            gender: this.gender,
            accessibleActions: this.accessibleActions,
            displayName: this.displayName,
            generalPractitionerOrganization: this.generalPractitionerOrganization,
            lowerCaseName: this.lowerCaseName,
            lastUpdated: this.lastUpdated,
            linkList: this.linkList,
            contact: this.contact,
            identifierHistory: this.identifierHistory,
            telecom: this.telecom,
            id: this.id,
            UUID: this.UUID,
            communication: this.communication,
            identifier: this.identifier,
            address: this.address,
            visible: this.visible,
            active: this.active,
            birthDate: this.birthDate,
            generalPractitionerRole: this.generalPractitionerRole,
            version: this.version,
            careProvider: this.careProvider,
            name: this.name,
            nameInitials: this.nameInitials,
            resourceType: this.resourceType,
            photo: this.photo,
        }
    }
}


export type PatientShowResponseData = {
    extension?: Array<Record<string, any>>;
    deceased?: DeceasedData;
    gender?: string;
    accessibleActions?: Array<any>;
    displayName?: string;
    generalPractitionerOrganization?: Array<any>;
    lowerCaseName?: Array<NameData>;
    lastUpdated?: string;
    linkList?: Array<any>;
    contact?: Array<ContactArrayData>;
    identifierHistory?: Array<any>;
    telecom?: Array<Record<string, any>>;
    id?: number;
    UUID?: string;
    communication?: Array<any>;
    identifier?: Array<Record<string, any>>;
    address?: Array<AddressData>;
    visible?: boolean;
    active?: ActiveStatusData;
    birthDate?: BirthDateData;
    generalPractitionerRole?: Array<any>;
    version?: number;
    careProvider?: Array<any>;
    name?: NameData;
    nameInitials?: string;
    resourceType?: string;
    photo?: Array<Photo>;
}

export type DeceasedData = {
    extension?: Array<any>;
    value?: boolean
}

export type ContactArrayData = {
    communicationLanguages?: Array<any>;
    addresses?: Array<any>;
    extension?: Array<any>;
    address?: Record<string, any>;
    names?: Array<NameData>;
    name?: NameData;
    telecom?: Array<TelecomArrayData>;
    relationship?: Array<RelationShipData>
}

export type TelecomArrayData = {
    modifiedValue?: string;
    useCode?: string;
    extension?: Array<any>;
    system?: string;
    countryCode?: string;
    value?: string;
    primary?: boolean
}
export type RelationShipData = {
    coding?: Array<any>;
    extension?: Array<any>;
    text?: string
}
export type AddressData = {
    country?: string;
    extension?: Array<AddressExtensionData>;
    city?: string;
    line?: Array<string>;
    use?: string;
    useCode?: string;
    state?: string;
    text?: string
}

export type AddressExtensionData = {
    value?: Array<AddressExtensionValueData>;
    url?: string
}

export type AddressExtensionValueData = {
    date?: string
}

export type ActiveStatusData = {
    extension?: Array<any>;
    value?: boolean
}

export type BirthDateData = {
    extension?: Array<any>;
    value?: string
}

export type NameData = {
    given?: Array<string>;
    extension?: Array<any>;
    prefix?: Array<any>;
    use?: string;
    degree?: Array<any>;
    language?: string;
    suffix?: Array<any>;
    useCode?: string;
    text?: string;
    family?: Array<string>
}

export type Photo = {
    data?: string;
    title?: string;
    contentType?: string;
}

export default PatientShowResponseModel