import {Container, Service} from 'typedi';
import BasicSubscribersViewModel from '../../../generic/viewModel/BasicSubscribers.viewmodel';
import {
	CompositeFormListItem,
	CompositeFormListModel,
	CompositeFormsFilterConfig,
	CompositeFormsRequestModel,
} from '../models/CompositeFormListModel';
import {
	DateDurationFilter,
	FilterData,
	FilterModel,
	RadioGroupFilter,
	RadioGroupModel,
} from '../../../filter/model/FilterModel';
import {Observable, ReplaySubject, Subscriber} from 'rxjs';
import CompositeFormRepository from '../repository/CompositeForm.repository';
import {
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../../generic/models/NotificationMessage';
import {strings} from '../../../../../../../localization/i18n';
import {
	CompositeFormType,
	getFilterStringForCompositeFormType,
} from '../enum/CompositeFormType';
import {
	CompositeFormStatus,
	getCompositeFormStatusString,
} from '../enum/CompositeFormStatus';
import _ from 'lodash';
import {isValueAvailable, toNumber} from '../../../../../utils/StringUtils';
import {
	getServerDateFromFormattedString,
	HoursToSetTypeEnum,
} from '../../../../../utils/DateTimeUtils';
import {LoaderType} from '../../../generic/models/Loader';
import DownloadFormRequest from '../models/DownloadFormRequest';
import {
	DocumentsHelper,
	openStore,
} from '../../../../../../../utils/documents/DocumentsHelper';
import {sessionStorageKeys} from '../../../../../interface/storage/constants/SessionKeys';
import ErrorModel from '../../../../network/model/ErrorModel';

const SOMETHING_WENT_WRONG = strings('errors.somethingWentWrong');
const DISCARD_FROM_DIALOG_HEADER = strings(
	'compositeForms.list.discardFormDialog.title',
);
const DISCARD_FROM_DIALOG_MESSAGE = strings(
	'compositeForms.list.discardFormDialog.bodyText',
);
const YES_BUTTON = strings('compositeForms.list.discardFormDialog.yesButton');
const NO_BUTTON = strings('compositeForms.list.discardFormDialog.noButton');

/**
 * Model of state for rendering on UI
 */
export type CompositeFormListingState = {
	isLoading: boolean;
	list: CompositeFormListItem[];
	showNewFormBanner: boolean;
	filterModel?: FilterModel;
	totalCount: number;
	isFilterApplied: boolean;
	isRefreshing: boolean;
};

/**
 * Enum for navigation to view/fill form, from form listing
 */
export enum CompositeFormsNavigationEnum {
	FILL_FORM,
	VIEW_FORM,
}

/**
 * Viewmodel for listing and searching composite forms
 */
@Service()
export default class CompositeFormListingViewModel extends BasicSubscribersViewModel<
	CompositeFormsNavigationEnum,
	Record<string, any>
> {
	private readonly formsViewStateSubject: ReplaySubject<CompositeFormListingState>;
	private readonly repository: CompositeFormRepository;
	private readonly formsViewState: CompositeFormListingState;
	private formsFilterConfig?: CompositeFormsFilterConfig;
	private defaultFilterModel?: FilterModel;
	private patientId?: number;

	constructor() {
		super();
		this.formsViewStateSubject = new ReplaySubject<CompositeFormListingState>(
			1,
		);
		this.repository = Container.get(CompositeFormRepository);
		this.formsViewState = {
			showNewFormBanner: false,
			filterModel: this.defaultFilterModel,
			list: [],
			totalCount: 0,
			isFilterApplied: false,
			isLoading: false,
			isRefreshing: false,
		};
	}

	/**
	 * One-time initialize viewmodel with patientId. Upon init, this method will automatically fetch first barch of forms with default filter
	 * @param patientId, potient for which forms need to be fetched
	 */
	initialize(patientId: number) {
		this.patientId = patientId;
		this.fetchForms();
	}

	/**
	 * Fetch paginated forms based on filter selected. Also, fetches filter config if being called for first time. Prepare filter data from config to render as well.
	 * @param {Boolean} isRefreshing if true then will discard current form list and fetch first batch of forms again. By default, false.
	 */
	fetchForms(isRefreshing = false) {
		if (this.patientId) {
			if (isRefreshing) {
				this.formsViewState.isRefreshing = isRefreshing;
			} else {
				this.formsViewState.isLoading = true;
			}
			this.updateView();
			Promise.all([
				this.repository.fetchForms(
					this.getFormsRequest(isRefreshing, this.patientId),
				),
				this.formsFilterConfig ??
					this.repository.fetchFormsFilterConfig({
						patientId: this.patientId,
					}),
			])
				.then((value: [CompositeFormListModel, CompositeFormsFilterConfig]) => {
					if (isRefreshing) {
						this.formsViewState.list = value[0].list;
						this.formsViewState.totalCount = value[0].totalCount;
					} else {
						this.formsViewState.list.push(...value[0].list);
						this.formsViewState.totalCount = value[0].totalCount;
					}
					if (this.formsFilterConfig === undefined) {
						this.formsFilterConfig = value[1];
						this.formsViewState.filterModel = this.createFilterModel(
							this.formsFilterConfig,
						);
						this.formsViewState.showNewFormBanner =
							value[1].showPreRegFormFillLink;
					}
				})
				.catch((error: ErrorModel) => {
					this.sendNotification(error.message ?? SOMETHING_WENT_WRONG);
				})
				.finally(() => {
					this.formsViewState.isRefreshing = false;
					this.formsViewState.isLoading = false;
					this.updateView();
				});
		} else {
			this.sendNotification(SOMETHING_WENT_WRONG);
		}
	}

	/**
	 * Discard current form list and fetch first batch of forms again.
	 */
	refreshFormsList() {
		this.fetchForms(true);
	}

	/**
	 * To be called on filter is applied from UI. if received {@link FilterModel} is null, it will not do anything apply filter again.
	 * if it is same as currently selected filter, it will do nothing.
	 * if it is different from currently selected filter then it update filter, update the state.
	 * if the current filter is different from default filter then the flag {@link CompositeFormListingState.isFilterApplied} will be set true, otherwise false.
	 * @param {FilterModel} filterModel, Denotes the filter selected by user. It can be null, if user discard filter changes.
	 */
	onFilterApplied(filterModel?: FilterModel | null) {
		if (
			filterModel &&
			!_.isEqual(this.formsViewState.filterModel, filterModel)
		) {
			this.formsViewState.filterModel = filterModel;
			this.formsViewState.isFilterApplied = !_.isEqual(
				this.defaultFilterModel,
				filterModel,
			);
			this.updateView();
			this.refreshFormsList();
		} else if (
			(filterModel === undefined || filterModel === null) &&
			!_.isEqual(this.defaultFilterModel, this.formsViewState.filterModel)
		) {
			this.formsViewState.filterModel = this.defaultFilterModel;
			this.formsViewState.isFilterApplied = false;
			this.updateView();
			this.refreshFormsList();
		}
	}

	/**
	 * fetches forms list configurations
	 * @returns {<CompositeFormsFilterConfig>}
	 */
	fetchFormsFilterConfig(
		patientId: number,
	): Observable<CompositeFormsFilterConfig> {
		const formsFilterConfigResponse = new Observable(
			(subscriber: Subscriber<CompositeFormsFilterConfig>) => {
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OnScreen,
				});
				this.repository
					.fetchFormsFilterConfig({
						patientId: patientId,
					})
					.then((value: CompositeFormsFilterConfig) => {
						subscriber.next(value);
						subscriber.complete();
					})
					.catch(error => {
						subscriber.error(error);
					})
					.finally(() => {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OnScreen,
						});
					});
			},
		);
		return formsFilterConfigResponse;
	}

	/**
	 * This method is used to inform view-model that the user has clicked on download button from Overflow Menu.
	 * @returns {void}
	 */
	downloadForm(formId: number): void {
		if (formId != null) {
			const formDownloadRequest = new DownloadFormRequest({
				formRequestId: formId,
				mime:
					(global as any).OS === 'ios' ? 'com.adobe.pdf' : 'application/pdf',
				extension: 'pdf',
			});
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OnScreen,
			});
			this.repository
				.downloadForm(formDownloadRequest)
				.then(resp => {
					DocumentsHelper.saveFileToLocalCache(
						formDownloadRequest.getRequestDataObject().extension ?? '',
						formDownloadRequest.getRequestDataObject().formRequestId,
						sessionStorageKeys.COMPOSITE_FORM,
						(global as any).platform === 'web'
							? resp.data
							: resp.request._response,
						_.get(resp.headers, 'content-disposition')?.split('Filename=')[1],
					)
						.then(() => {
							console.log('successful');
						})
						.catch(reason => {
							console.log('error');
							if (reason === 'ApplicationDoesNotExist') {
								this.notificationMessageSubject.next({
									type: NotificationMessageType.toast,
									level: NotificationMessageLevel.error,
									visibility: NotificationVisibility.local,
									message: strings('errors.applicationUnavailable'),
									positiveActionTitle: strings('formsListing.actionButtons.ok'),
									positiveAction: () => {
										openStore(
											DocumentsHelper.getMimeType(
												formDownloadRequest.getRequestDataObject().extension,
											) ?? '',
										);
									},
								});
							} else {
								console.log('esle called');
								this.notificationMessageSubject.next({
									type: NotificationMessageType.toast,
									level: NotificationMessageLevel.error,
									visibility: NotificationVisibility.local,
									message: strings('errors.somethingWentWrong'),
								});
							}
						});
				})
				.catch(error => {
					console.log(
						'CompositeFormViewModel.onClickDownload error',
						JSON.stringify(error, null, 4),
					);
					// TODO: Add specific error code handling
					this.notificationMessageSubject.next({
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
						visibility: NotificationVisibility.local,
						message: strings('errors.somethingWentWrong'),
					});
				})
				.finally(() => {
					console.log('finally called');
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OnScreen,
					});
				});
		}
	}

	/**
	 * This method will show the confirmation dialog to discard the form using notification subject
	 * @param {number} formId Id of form to be discarded
	 */
	discardFormRequest(formId: number) {
		this.notificationMessageSubject.next({
			heading: DISCARD_FROM_DIALOG_HEADER,
			level: NotificationMessageLevel.default,
			message: DISCARD_FROM_DIALOG_MESSAGE,
			negativeActionTitle: NO_BUTTON,
			positiveAction: () => {
				this.discardForm(formId);
			},
			positiveActionTitle: YES_BUTTON,
			type: NotificationMessageType.alert,
			visibility: NotificationVisibility.dialog,
		});
	}

	/**
	 * Discard particular form and refresh list
	 * @param {number} formId Id of form to be discarded
	 */
	discardForm(formId: number) {
		this.loaderSubject.next({
			isToShowLoader: true,
			type: LoaderType.OverScreen,
		});
		this.repository
			?.executeDiscardForm({id: formId})
			.then(value => {
				if (value.status) {
					this.refreshFormsList();
					this.sendNotification(
						strings('compositeForms.list.discardFormSuccess'),
						NotificationMessageLevel.success,
					);
				} else {
					this.sendNotification(SOMETHING_WENT_WRONG);
				}
			})
			.catch((error: ErrorModel) => {
				this.sendNotification(error.message ?? SOMETHING_WENT_WRONG);
			})
			.finally(() => {
				this.loaderSubject.next({
					isToShowLoader: false,
					type: LoaderType.OverScreen,
				});
			});
	}

	/**
	 * Push the navigation event for redirecting to view form screen.
	 * @param {number} formId Id of form to be viewed
	 * @param {CompositeFormType} type type of form to be viewed
	 */
	viewForm(formId: number, type: CompositeFormType) {
		this.navigationSubject.next({
			event: CompositeFormsNavigationEnum.VIEW_FORM,
			additionalData: {formResponseId: formId, type: type, viewMode: true},
		});
	}

	/**
	 * Push the navigation event for redirecting to fill form screen.
	 * @param {number} formId Id of form to be filled
	 * @param {CompositeFormType} type type of form to be viewed
	 */
	fillForm(formId: number, type: CompositeFormType) {
		this.navigationSubject.next({
			event: CompositeFormsNavigationEnum.FILL_FORM,
			additionalData: {formResponseId: formId, type: type},
		});
	}

	/**
	 * Get current state of form through Observable. Whenever the state changes the Observable will push the new state to update UI.
	 * @return {Observable<CompositeFormListingState>} Observable of state for rendering UI
	 */
	fetchStateObservable(): Observable<CompositeFormListingState> {
		return this.formsViewStateSubject.asObservable();
	}

	/**
	 * Create filter model to render filter screen based on {@link CompositeFormsFilterConfig} provided
	 * @param filterConfig Config to configure the filter screen
	 * @return {FilterModel} return {@link FilterModel} which will be used to render Filter screen
	 * @private
	 */
	private createFilterModel(
		filterConfig: CompositeFormsFilterConfig,
	): FilterModel {
		this.defaultFilterModel = {
			filterList: [],
		};
		if (
			filterConfig.formListSearchFilters.formName.visible &&
			filterConfig.uploadedFormTypes.length > 0
		) {
			this.defaultFilterModel.filterList.push(getFormTypeFilters());
		}
		if (filterConfig.formListSearchFilters.formStatus.visible) {
			this.defaultFilterModel.filterList.push(getFormStatusFilters());
		}
		if (
			filterConfig.formReferenceTypes.includes('Visit') &&
			filterConfig.formListSearchFilters.reference.visible
		) {
			this.defaultFilterModel.filterList.push(getVisitLocationFilters());
			this.defaultFilterModel.filterList.push(getVisitDateFilters());
		}

		/**
		 * Get filter for {@link FilterTypes.FORM_TYPE}
		 */
		function getFormTypeFilters(): RadioGroupFilter {
			return {
				filterType: 'radioGroup',
				displayName: strings('compositeForms.list.filters.formType'),
				allText: strings('compositeForms.list.filters.all'),
				nestedFilterList: filterConfig.uploadedFormTypes.map(value => {
					return {
						displayName: getFilterStringForCompositeFormType(
							value as CompositeFormType,
						),
						id: value,
					};
				}),
				selectedItemPos: -1,
				id: FilterTypes.FORM_TYPE,
			};
		}

		/**
		 * Get filter for {@link FilterTypes.STATUS}
		 */
		function getFormStatusFilters(): RadioGroupFilter {
			return {
				filterType: 'radioGroup',
				displayName: strings('compositeForms.list.filters.status'),
				allText: strings('compositeForms.list.filters.all'),
				nestedFilterList: filterConfig.defaultFormStatus.map(value => {
					return {
						displayName: getCompositeFormStatusString(
							value as CompositeFormStatus,
						),
						id: value,
					};
				}),
				selectedItemPos: -1,
				id: FilterTypes.STATUS,
			};
		}

		/**
		 * Get filter for {@link FilterTypes.VISIT_LOCATION}
		 */
		function getVisitLocationFilters(): RadioGroupFilter {
			return {
				filterType: 'radioGroup',
				displayName: strings('compositeForms.list.filters.visitLocation'),
				allText: strings('compositeForms.list.filters.all'),
				nestedFilterList: filterConfig.formEncounter.map<RadioGroupModel>(
					encounter => {
						return {
							displayName: encounter.locationName,
							id: encounter.id.toString(),
						};
					},
				),
				selectedItemPos: -1,
				id: FilterTypes.VISIT_LOCATION,
			};
		}

		/**
		 * Get filter for {@link FilterTypes.VISIT_DATE}
		 */
		function getVisitDateFilters(): DateDurationFilter {
			return {
				filterType: 'dateDuration',
				displayName: strings('compositeForms.list.filters.visitDate'),
				id: FilterTypes.VISIT_DATE,
			};
		}

		return this.defaultFilterModel;
	}

	/**
	 * Update UI by sending event of updated UI state
	 * @private
	 */
	private updateView() {
		this.formsViewStateSubject.next(this.formsViewState);
	}

	/**
	 * Send notification such as toast to UI
	 * @param msg Message to be shown via Alert/Toast
	 * @private
	 */
	private sendNotification(msg: string, level?: NotificationMessageLevel) {
		this.notificationMessageSubject.next({
			type: NotificationMessageType.toast,
			level: level ?? NotificationMessageLevel.error,
			message: msg,
			visibility: NotificationVisibility.local,
		});
	}

	/**
	 * Get request model for fetching form list
	 * @param isRefreshing, if ```true``` will refresh list, otherwise pagination will continuw
	 * @param patientId Patient for which to fetch list
	 * @return {CompositeFormsRequestModel} request model for calling API
	 * @private
	 */
	private getFormsRequest(
		isRefreshing: boolean,
		patientId: number,
	): CompositeFormsRequestModel {
		const filterModel = this.formsViewState.filterModel;
		return {
			encounter: toNumber(getSelectedIdFromFilter(FilterTypes.VISIT_LOCATION)),
			formType: getSelectedIdFromFilter(FilterTypes.FORM_TYPE),
			encounterDate: getEncounterDate(),
			status: getSelectedIdFromFilter(FilterTypes.STATUS),
			_count: 10,
			_skip: isRefreshing ? 0 : this.formsViewState.list.length,
			patientId: patientId,
			'_sort:desc': 'submittedDate',
		};

		/**
		 * Get {@link FilterData} of respective {@link FilterTypes}
		 * @param {FilterTypes} id, key of respective {@link FilterTypes}
		 */
		function getFilterModel<T extends FilterData>(
			id: FilterTypes,
		): T | undefined {
			const filterData = filterModel?.filterList.find(
				filterData => filterData.id === id,
			);
			if (filterData) {
				return filterData as T;
			} else {
				return undefined;
			}
		}

		/**
		 * Get {@link RadioGroupFilter} for respective {@link FilterTypes}
		 * @param {FilterTypes} id, key of respective {@link FilterTypes}
		 */
		function getSelectedIdFromFilter(id: FilterTypes): string | undefined {
			const radioFilter = getFilterModel<RadioGroupFilter>(id);
			return radioFilter?.selectedItemPos === -1
				? undefined
				: radioFilter?.nestedFilterList?.[radioFilter?.selectedItemPos ?? -1]
						?.id;
		}

		/**
		 * Get constraint for Visit date duration, if available.
		 * @return {string[]} return {@link undefined} if not available, otherwise {@link string[]}
		 */
		function getEncounterDate(): string[] | undefined {
			const durationFilterDate = getFilterModel<DateDurationFilter>(
				FilterTypes.VISIT_DATE,
			);
			const dateArr: string[] = [];
			if (
				durationFilterDate?.startDate &&
				isValueAvailable(durationFilterDate?.startDate)
			) {
				dateArr.push(
					`ge${getServerDateFromFormattedString(
						durationFilterDate.startDate,
						global.ConfigurationHolder?.dates?.typeAbleDateFormat,
						HoursToSetTypeEnum.START_DAY,
					)}`,
				);
			}
			if (
				durationFilterDate?.endDate &&
				isValueAvailable(durationFilterDate?.endDate)
			) {
				dateArr.push(
					`lt${getServerDateFromFormattedString(
						durationFilterDate.endDate,
						global.ConfigurationHolder?.dates?.typeAbleDateFormat,
						HoursToSetTypeEnum.END_DAY,
					)}`,
				);
			}
			return dateArr.length > 0 ? dateArr : undefined;
		}
	}
}

/**
 * Types of filter to be used for rendering filters for Composite form listing
 */
enum FilterTypes {
	STATUS = 'STATUS',
	FORM_TYPE = 'FORM_TYPE',
	VISIT_LOCATION = 'VISIT_LOCATION',
	VISIT_DATE = 'VISIT_DATE',
}
