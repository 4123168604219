import BaseResponseModel from "../../../../network/interface/BaseResponseModel";

class UploadAndSaveDocumentResponseModel implements BaseResponseModel {
    private readonly documentListMap?: DocumentListMapData;
    private readonly successAliasMapWithDocRefIds?: Record<string, any>;

    constructor({
                    documentListMap,
                    successAliasMapWithDocRefIds
                }: UploadAndSaveDocumentResponseData) {
        this.documentListMap = documentListMap;
        this.successAliasMapWithDocRefIds = successAliasMapWithDocRefIds;
    }

    /**
     * returns uploadAndSaveDocument response
     * @returns {UploadAndSaveDocumentResponseData}
     */
    getResponseDataObject(): UploadAndSaveDocumentResponseData {
        return {
            documentListMap: this.documentListMap,
            successAliasMapWithDocRefIds: this.successAliasMapWithDocRefIds
        }
    }
}

export type UploadAndSaveDocumentResponseData = {
    documentListMap?: DocumentListMapData;
    successAliasMapWithDocRefIds?: Record<string, any>;
}


export  type DocumentListMapData = {
    successFileAliasList?: Array<string>,
    invalidFileAliasList?: Array<any>,
    errorFileAliasList?: Array<any>
}
export default UploadAndSaveDocumentResponseModel