import Container, {Service} from 'typedi';
import {isValueAvailable} from '../../../../utils/StringUtils';
import PatientSearchRequestModel, {
	PatientSearchConstraints,
} from '../models/requestModels/PatientSearchRequestModel';
import PatientSearchRequestValidationModel from '../models/validationModels/PatientSearchRequestValidationModel';
import {strings} from '../../../../../../localization/i18n';
import DocumentListViewModel from '../../documents/viewModel/DocumentListViewModel';
import PatientShowResponseModel from '../../documents/models/responseModel/PatientShowResponseModel';
import PatientShowValidationModel from '../../documents/models/validationModel/PatientShowValidationModel';
import encryptedStorageHelperInstance from '../../../../../../storage/EncryptedStorageHelper';
import {sessionStorageKeys} from '../../../../interface/storage/constants/SessionKeys';
import {LoaderType} from '../../generic/models/Loader';
import {Observable, Subscriber} from 'rxjs';
import BasicSubscribersViewModel from '../../generic/viewModel/BasicSubscribers.viewmodel';
import {PatientSearchNavigationEvents} from '../enums/PatientSearchNavigationEvents';
import PatientActionBannerRequestModel, {
	PatientActionBannerRequestModelType,
} from '../models/requestModels/PatientActionBannerRequestModel';
import PatientActionBannerResponseModel, {
	PatientActionBannerResponseModelType,
} from '../models/responseModels/PatientActionBannerResponseModel';
import PatientActionBannerValidationModel from '../models/validationModels/PatientActionBannerValidationModel';
import PatientSearchRepository from '../repository/PatientSearchRepository';
import {
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../generic/models/NotificationMessage';
import _ from 'lodash';
import storageHelperInstance from '../../../../../../storage/StorageHelper';

@Service()
class PatientSearchViewModel extends BasicSubscribersViewModel<
	PatientSearchNavigationEvents,
	Record<string, any>
> {
	private documentViewModel: DocumentListViewModel;
	private patientSearchRepository: PatientSearchRepository;

	constructor() {
		super();
		this.documentViewModel = Container.get(DocumentListViewModel);
		this.patientSearchRepository = Container.get(PatientSearchRepository);
	}

	/**
	 * calls
	 * @param requestData
	 * @returns {Observable<Record<string, any>>}
	 */
	setPatientSearchRequest(
		searchParams: PatientSearchConstraints,
	): Record<string, any> | PatientSearchRequestValidationModel {
		const requestData = new PatientSearchRequestModel({
			constraints: searchParams,
		});
		const patientSearchRequestValidationModel: PatientSearchRequestValidationModel =
			this.validatePatientSearchRequestData(requestData);

		if (!patientSearchRequestValidationModel.isError) {
			const finalConstraints: Record<string, any> = {
				'_sort:asc': searchParams.sortCondition,
				_count: searchParams._count,
				_skip: searchParams._skip,
				profilePictureType: 'THUMBNAIL',
			};
			if (searchParams.searchElementsListExact) {
				finalConstraints['searchElementsList:exact'] =
					searchParams.searchElementsListExact;
			}

			if (searchParams.searchElementsStartsWith) {
				finalConstraints['searchElementsList:startsWith'] =
					searchParams.searchElementsStartsWith;
			}

			if (searchParams.locationIdentifiers) {
				finalConstraints['locationIdentifiers'] =
					searchParams.locationIdentifiers;
			}

			if (searchParams.birthDateFrom) {
				finalConstraints['birthDateFrom'] = searchParams.birthDateFrom;
			}

			if (searchParams.birthDateTo) {
				finalConstraints['birthDateTo'] = searchParams.birthDateTo;
			}

			if (searchParams.ageFrom) {
				finalConstraints['ageFrom'] = searchParams.ageFrom;
			}

			if (searchParams.ageTo) {
				finalConstraints['ageTo'] = searchParams.ageTo;
			}

			return {
				constraints: finalConstraints,
			};
		}
		return patientSearchRequestValidationModel;
	}

	/**
	 * validate patient search request parameters
	 * @param requestData
	 * @returns {PatientSearchRequestValidationModel}
	 */
	private validatePatientSearchRequestData(
		requestData: PatientSearchRequestModel,
	): PatientSearchRequestValidationModel {
		const patientSearchRequestValidationModel: PatientSearchRequestValidationModel =
			new PatientSearchRequestValidationModel();
		const patientSearchRequestData = requestData.getRequestDataObject();
		if (!isValueAvailable(patientSearchRequestData.constraints.sortCondition)) {
			patientSearchRequestValidationModel.isError = true;
			patientSearchRequestValidationModel.sortCondition = strings(
				'requestValidationError.requestValidationError.sortConditionError',
			);
		}

		if (
			patientSearchRequestData.constraints._count == undefined ||
			patientSearchRequestData.constraints._count == null
		) {
			patientSearchRequestValidationModel.isError = true;
			patientSearchRequestValidationModel.countError = strings(
				'requestValidationError.requestValidationError.countError',
			);
		}
		if (
			patientSearchRequestData.constraints._skip == undefined ||
			patientSearchRequestData.constraints._skip == null
		) {
			patientSearchRequestValidationModel.isError = true;
			patientSearchRequestValidationModel.skipError = strings(
				'requestValidationError.requestValidationError.skipError',
			);
		}

		if (
			!isValueAvailable(patientSearchRequestData.constraints.profilePictureType)
		) {
			patientSearchRequestValidationModel.isError = true;
			patientSearchRequestValidationModel.profilePictureTypeError = strings(
				'requestValidationError.requestValidationError.profilePictureTypeError',
			);
		}

		return patientSearchRequestValidationModel;
	}

	/**
	 *
	 * @param patientID it will be send as a parameter for fetching patient details from this patient id
	 * @returns patient details for particular patient
	 */
	fetchPatientDetails(
		patientID?: number | null,
	): Promise<PatientShowResponseModel | PatientShowValidationModel> {
		return new Promise((resolve, reject) => {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OnScreen,
			});
			this.documentViewModel
				.fetchPatientDetails(patientID)
				.subscribe(
					(response: PatientShowResponseModel | PatientShowValidationModel) => {
						if (response instanceof PatientShowValidationModel) {
							reject(response);
						} else {
							encryptedStorageHelperInstance
								.setItem(sessionStorageKeys.PATIENT_DETAILS, response)
								.then(() => {
									resolve(response);
								})
								.finally(() => {
									this.loaderSubject.next({
										isToShowLoader: false,
										type: LoaderType.OnScreen,
									});
								});
						}
					},
				);
		});
	}

	/**
	 * This method is used to fetching patient action banner details
	 * @param patientActionBannerRequestModel
	 * @param patientId
	 */
	getPatientActionBannerDetails(
		patientActionBannerRequestModel: PatientActionBannerRequestModel,
		patientId: number,
	): Observable<
		PatientActionBannerResponseModelType | PatientActionBannerValidationModel
	> {
		return new Observable(
			(
				subscriber: Subscriber<
					| PatientActionBannerResponseModelType
					| PatientActionBannerValidationModel
				>,
			) => {
				const patientActionBannerValidationModel: PatientActionBannerValidationModel =
					this.validatePatientActionBannerValidationModel(
						patientActionBannerRequestModel,
					);
				if (!patientActionBannerValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.patientSearchRepository
						.getPatientActionBannerDetails(
							patientActionBannerRequestModel,
							patientId,
						)
						.then(({data}) => {
							encryptedStorageHelperInstance.setItem(
								sessionStorageKeys.PATIENT_BANNER_MENUITEMS,
								data.patientBannerMenuItems,
							);
							const patientActionBannerResponseModel: PatientActionBannerResponseModel =
								new PatientActionBannerResponseModel(data);
							subscriber.next(
								patientActionBannerResponseModel.getResponseDataObject(),
							);
							subscriber.complete();
						})
						.catch(error => {
							subscriber.error(error);
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
								message: strings('errors.somethingWentWrong'),
								visibility: NotificationVisibility.global,
							});
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(patientActionBannerValidationModel);
				}
			},
		);
	}

	/**
	 * This method is used validate PatientActionBannerRequestModel
	 * @return {PatientActionBannerValidationModel}
	 * @param patientActionBannerRequestModel
	 */
	validatePatientActionBannerValidationModel(
		patientActionBannerRequestModel: PatientActionBannerRequestModel,
	): PatientActionBannerValidationModel {
		const patientActionBannerValidationModel: PatientActionBannerValidationModel =
			new PatientActionBannerValidationModel();
		const patientActionBannerConstraints: PatientActionBannerRequestModelType =
			patientActionBannerRequestModel.getRequestDataObject();
		if (!patientActionBannerConstraints.constraints) {
			patientActionBannerValidationModel.constraintsError = strings(
				'patientSearch.requestValidationError.constraintsMissingError',
			);
			patientActionBannerValidationModel.isError = true;
		}
		return patientActionBannerValidationModel;
	}
}

export default PatientSearchViewModel;
