import SearchCriteria, {SearchCriteriaType} from './SearchCriteria';

export default class ShareRecordsRequest {
	private readonly _actionName: string;
	private readonly _selectedResourceIds: Array<string>;
	private readonly _searchCriteria: SearchCriteria;

	constructor(
		actionName: string,
		selectedResourceIds: Array<string>,
		searchCriteria: SearchCriteria,
	) {
		this._actionName = actionName;
		this._selectedResourceIds = selectedResourceIds;
		this._searchCriteria = searchCriteria;
	}

	getData(): ShareRecordsType {
		return {
			actionName: this._actionName,
			selectedResourceIds: this._selectedResourceIds,
			searchCriteria: this._searchCriteria.getData(),
		};
	}
}

export type ShareRecordsType = {
	actionName: string;
	selectedResourceIds: Array<string>;
	searchCriteria: SearchCriteriaType;
};
