import {useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {sessionStorageKeys} from './common/src/interface/storage/constants/SessionKeys';
import {GlobalStateContext} from './common/src/utils/context/GlobalStateContext';
import GlobalObserversInstance from './common/src/utils/observers/GlobalObservers';
import DefaultHeader from './components/header/defaultHeader';
import encryptedStorageHelperInstance from './storage/EncryptedStorageHelper';
import {ConfigSingleton} from './utils/wrappers/ui-comp/config-transformer';
import UserService from './common/src/application/modules/user/service/User.service';
import Container from 'typedi';
import Loader from './ui/comps/src/Loader';
import Footer from './components/footer/footer';
import ToastNotification from './ui/comps/src/notification/view/ToastNotification';
import {ComponentContext} from './ui/comps/src/dynamic-form/context/Component.context';
import getCustomComponentSelector from './utils/wrappers/forms/ComponentSelector.transformer';
import WebsocketService from './utils/websocket';
import _ from 'lodash';
import DocDownloadStatus from './components/documents/DocDownloadStatus';
import {selectedLanguage} from './localization/i18n';
import {isValueAvailable} from './common/src/utils/StringUtils';
import {UserSettingsModel} from './common/src/application/modules/user/models/UserModel';
import {setInLocalStorage} from './common/src/utils/CommonUtils';
import {APIUrl} from './common/src/application/network/values/URLInfo';

const AppContainer = (): JSX.Element => {
	let userService: UserService;
	userService = Container.get(UserService);

	let websocketService: WebsocketService;
	websocketService = Container.get(WebsocketService);

	const [user, setUser] = useState<any>(null);

	const [serverConfig, setServerConfig] = useState<Record<string, any>>({});

	const [documentDownloadModel, setDocumentDownloadModel] = useState<
		Record<string, any>
	>({});

	useEffect(() => {
		setDocumentDownloadModel({});
		const documentDownloadSubscriber = websocketService
			.getDocumentDownloadObservable()
			.subscribe({
				next: (documentDownloadDetails: Record<string, any>) => {
					let data = _.cloneDeep(documentDownloadDetails);
					setDocumentDownloadModel(data);
				},
			});

		return () => {
			documentDownloadSubscriber.unsubscribe();
		};
	}, [websocketService]);

	//initializing config object for passing into UI-COMPS
	ConfigSingleton.getInstance();

	const navigate = useNavigate();
	const [userFetched, setUserFetched] = useState(false);

	const authToken = encryptedStorageHelperInstance.getItemSync(
		sessionStorageKeys.AUTH_TOKEN,
	);

	const removeAllCookies = () => {
		userService.removeAllCookies().then(() => {
			GlobalObserversInstance.userContextObserver.publish(null);
			navigate(global.ConfigurationHolder.defaultLandingPage);
		});
	};

	useEffect(() => {
		GlobalObserversInstance.languageChangeObserver.observable().subscribe({
			next: language => {
				const userSettings: UserSettingsModel =
					encryptedStorageHelperInstance.getItemSync(
						sessionStorageKeys.USER_SETTINGS,
					);
				if (isValueAvailable(userSettings)) {
					userSettings.userLanguage = language;
					const token = encryptedStorageHelperInstance.getItemSync(
						sessionStorageKeys.AUTH_TOKEN,
					);
					let customHeaders = {};
					if (!token) {
						const tempToken = encryptedStorageHelperInstance.getItemSync(
							sessionStorageKeys.TEMP_AUTH_TOKEN,
						);
						customHeaders = userService.getHeaderWithTempToken(
							APIUrl.UPDATE_USER_SETTINGS,
							tempToken,
						);
					}
					userService
						.updateUserSettings(userSettings, customHeaders)
						.then(() => {
							setInLocalStorage(
								sessionStorageKeys.CURRENT_LANGUAGE_TAG,
								language,
							);
							window.location.reload();
						});
				} else {
					window.location.reload();
				}
			},
		});
	}, []);

	//  set default lang in local storage
	setInLocalStorage(
		sessionStorageKeys.CURRENT_LANGUAGE_TAG,
		selectedLanguage(),
	);

	useEffect(() => {
		const serverConfigSubject = userService
			.fetchServerConfigObservable()
			.subscribe({
				next: (config: Record<string, any>) => {
					setServerConfig(config);
				},
			});

		return () => {
			serverConfigSubject.unsubscribe();
		};
	}, []);

	useEffect(() => {
		// for when a new login happens, save user details
		GlobalObserversInstance.userContextObserver.observable().subscribe(user => {
			encryptedStorageHelperInstance.setItemSync(sessionStorageKeys.USER, user);
			setUserFetched(true);
			setUser(() => user);
		});

		GlobalObserversInstance.logoutObserver.observable().subscribe(response => {
			console.log('logout Observer called ');
			removeAllCookies();
		});

		// get user details
		if (!user) {
			const token = encryptedStorageHelperInstance.getItemSync(
				sessionStorageKeys.AUTH_TOKEN,
			);
			if (token) {
				userService.getUserDetails(false).subscribe(response => {
					setUserFetched(true);
					setUser(response);
					userService.getPostLoginConfig();
					userService.getVitalConfig();
					userService.getUserDashboards();
					if (!websocketService.status() && token) {
						websocketService.createWebSocketConnectionWithToken(token);
					}
				});
			} else {
				userService
					.renderConfigWithoutLogin()
					.then((serverConf: Record<string, any>) => {
						encryptedStorageHelperInstance.setItemSync(
							sessionStorageKeys.SERVER_CONFIG,
							serverConf?.data,
						);
						setServerConfig(serverConf?.data);
					})
					.catch(error => {
						console.log('error in getting config ', error);
					})
					.finally(() => {
						setUserFetched(true);
					});
			}
		}
	}, []);

	return (
		<div>
			<GlobalStateContext.Provider
				value={{
					user,
					removeAllCookies,
					serverConfig,
				}}
			>
				<ComponentContext.Provider value={getCustomComponentSelector()}>
					{userFetched ? (
						<div>
							<DefaultHeader></DefaultHeader>
							<div className={'tosterMargin'}>
								<ToastNotification type="global"></ToastNotification>
							</div>
							<Outlet></Outlet>
							<DocDownloadStatus
								documentDownloadModel={documentDownloadModel}
							></DocDownloadStatus>
							{user && authToken && <Footer></Footer>}
						</div>
					) : (
						<Loader></Loader>
					)}
				</ComponentContext.Provider>
			</GlobalStateContext.Provider>
		</div>
	);
};

export default AppContainer;
