import {GridActionButtonOrIcon} from '../../../../../common/src/interface/dataGrid/IDataGrid';
import {strings} from '../../../../../localization/i18n';
import {setFocusId} from '../../utils/uiCompsUtils';

export default function CustomColumnButtonsOrIcons(props: any) {
	const buttons = props.header.buttonOrIcons;
	const rowData = props.row;
	const uniqueId = props.uniqueId;
	let label = '';
	const getIconClassName = (icon: GridActionButtonOrIcon): string => {
		let iconClassName: string = 'fIcon iconSize1 db';

		switch (icon.iconType) {
			case 'edit':
				label = strings('customColumn.edit');
				return iconClassName.concat(' icon-edit1');
			case 'view':
				label = strings('customColumn.view');
				return iconClassName.concat(' icon-show_password');
			case 'delete':
				label = strings('customColumn.delete');
				return iconClassName.concat(' icon-delete2');
			case 'cross':
				label = strings('customColumn.cancel');
				return iconClassName.concat(' icon-cancel1');
			case 'add':
				label = strings('customColumn.add');
				return iconClassName.concat(' icon-add1');
			case 'share':
				label = strings('customColumn.share');
				return iconClassName.concat(' icon-share');
			case 'download':
				label = strings('customColumn.download');
				return iconClassName.concat(' icon-download2');
			default:
				return iconClassName.concat(icon.className ? ' ' + icon.className : '');
		}
	};

	return (
		<div className="sm-right-action-position">
			{buttons && (
				<span className="dib btnContainer iconBtnOnly vat">
					{buttons?.map((btn: GridActionButtonOrIcon, key: number) => {
						return btn.buttonOptions && btn.buttonOptions.length > 0 ? (
							<div className="dropdown" key={key}>
								<button
									className="btn btn-secondary dropdown-toggle btn-sm actionBtn insideTableCell"
									type="button"
									id={`dropdownMenuButton_${uniqueId}`}
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									<em className="sr-only">{strings('accessibility.action')}</em>
									<span
										className="fIcon icon-arrow_down1"
										aria-hidden="true"></span>
								</button>
								<div
									className="dropdown-menu dropdown-menu-right"
									aria-labelledby="dropdownMenuButton">
									{btn.buttonOptions.map(
										(buttonOption: GridActionButtonOrIcon, key: number) => {
											return (
												buttonOption.visible && (
													<button
														className={`dropdown-item ${
															buttonOption.disabled ? 'disabled' : ''
														}`}
														onClick={e => {
															buttonOption.clickHandler(rowData);
															setFocusId(`dropdownMenuButton_${uniqueId}`);
														}}
														role="link"
														disabled={buttonOption.disabled}
														hidden={
															buttonOption.visible === undefined ||
															buttonOption.visible === true
																? false
																: true
														}
														key={key}>
														{buttonOption.icon && buttonOption.icon}
														{buttonOption.label && strings(buttonOption.label)}
													</button>
												)
											);
										},
									)}
								</div>
							</div>
						) : btn.disabled ? (
							<div className="dropdown">
								<button
									className="btn btn-secondary dropdown-toggle btn-sm actionBtn insideTableCell"
									type="button"
									disabled={true}
									id="dropdownMenuButton"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									<em className="sr-only">{strings('accessibility.action')}</em>
									<span
										className="fIcon icon-arrow_down1"
										aria-hidden="true"></span>
								</button>
							</div>
						) : (
							<button
								key={key}
								role={'link'}
								onClick={() => btn.clickHandler(rowData)}
								hidden={
									btn.visible === undefined || btn.visible === true
										? false
										: true
								}
								className={
									'btn btn-space2 vam noUnderline' +
									(btn.label ? ' btn-secondary' : ' btn-link')
								}>
								{btn.label ? (
									strings(btn.label)
								) : (
									<>
										<span className="sr-only">{label}</span>
										<span aria-hidden="true" className={getIconClassName(btn)}>
											{' '}
										</span>
									</>
								)}
							</button>
						);
					})}
				</span>
			)}
		</div>
	);
}
