import encryptedStorageHelperInstance from '../../../../../storage/EncryptedStorageHelper';
import {sessionStorageKeys} from '../../../interface/storage/constants/SessionKeys';
import {isValueAvailable} from '../../../utils/StringUtils';

/** This array is being used to store the two most recent auth tokens
 * for the current instance of the application. */
const oldAuthTokenArray: string[] = [];

/**
 * This function extracts the relevant information from the response headers and stores it in encrypted storage.
 * @param {Record<string, string>} headers, This function has been supplied the response headers.
 * @return {Promise<void>}
 */
export function parseResponseHeaders(
	headers: Record<string, string>,
): Promise<void> {
	const updatedAuthToken: string = headers && headers['x-auth-token'];
	return new Promise<void>(resolve => {
		refreshAuthToken(updatedAuthToken).finally(() => {
			resolve();
		});
	});
}

/**
 * This function is to save the auth token in encrypted storage if token is valid.
 * @param {string} updatedAuthToken, This function has been supplied the updated auth token retrieved
 * from the headers.
 * @return {Promise<void>}
 */
function refreshAuthToken(updatedAuthToken: string): Promise<void> {
	return new Promise<void>(resolve => {
		encryptedStorageHelperInstance
			.getItem(sessionStorageKeys.TEMP_AUTH_TOKEN)
			.then(temp_token => {
				if (!temp_token && isAuthTokenValid(updatedAuthToken)) {
					encryptedStorageHelperInstance
						.setItem(sessionStorageKeys.AUTH_TOKEN, updatedAuthToken)
						.finally(() => {
							resolve();
						});
				} else {
					resolve();
				}
			});
	});
}

/**
 * This function validates the auth token and updates the oldAuthTokenArray with the new token.
 * The conditions to check if the token is valid to save are listed below.
 *  1. If the authentication token is not null or empty.
 *  2. If the authentication token is not already present in the old authentication token array.
 * @param {string} authToken, Updated token received from the response headers.
 * @return {boolean}, Return true or false on the basis of above mentioned conditions. If any of the condition fails the function will return false.
 */
function isAuthTokenValid(authToken: string): boolean {
	if (isValueAvailable(authToken) && !oldAuthTokenArray.includes(authToken)) {
		/** We store the two most recent auth tokens in the array */
		if (oldAuthTokenArray.length < 2) {
			oldAuthTokenArray.push(authToken);
		} else {
			oldAuthTokenArray.splice(0, 1);
			oldAuthTokenArray.push(authToken);
		}
		return true;
	} else {
		return false;
	}
}
