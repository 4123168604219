import {useNavigate} from 'react-router-dom';
import {
	GridActionButtonOrIcon,
	TableHeader,
} from '../../../../common/src/interface/dataGrid/IDataGrid';
import {strings} from '../../../../localization/i18n';
import CustomColumnButtonsOrIcons from '../../../../ui/comps/src/dataGrid/components/CustomColumnButtonsOrIcons';
import {CompositeFormType} from '../../../../common/src/application/modules/forms/composite/enum/CompositeFormType';

const Action = (props: any): JSX.Element => {
	const {row, extraProps} = props;

	const navigate = useNavigate();

	const additionalData: Record<string, number | undefined> & {
		type: CompositeFormType;
	} = {
		formResponseId: row.id,
		type: row.type,
	};

	const viewForm = () => {
		extraProps?.viewForm(additionalData.formResponseId, additionalData.type);
	};

	const discardForm = () => {
		extraProps.openDiscardFormDialog(additionalData);
	};

	const downloadForm = () => {
		extraProps.downloadForm(additionalData);
	};

	const formResponseActionList = row.formResponseActionList;

	const gridDropdownActionButtons: Array<GridActionButtonOrIcon> = [];

	if (formResponseActionList.indexOf('view') > -1) {
		gridDropdownActionButtons.push({
			label: strings('formsListing.actions.view'),
			clickHandler: viewForm,
			disabled: false,
			visible: true,
		});
	}

	if (formResponseActionList.indexOf('edit') > -1) {
		gridDropdownActionButtons.push({
			label: strings('formsListing.actions.edit'),
			clickHandler: () =>
				navigate(`/patient/${row.patientId}/createForm`, {
					state: additionalData,
				}),
			disabled: false,
			visible: true,
		});
	}

	if (formResponseActionList.indexOf('download') > -1) {
		gridDropdownActionButtons.push({
			label: strings('formsListing.actions.download'),
			clickHandler: downloadForm,
			disabled: false,
			visible: true,
		});
	}

	if (formResponseActionList.indexOf('discard') > -1) {
		gridDropdownActionButtons.push({
			label: strings('formsListing.actions.discard'),
			clickHandler: discardForm,
			disabled: false,
			visible: true,
		});
	}

	const gridActionButtons: Array<GridActionButtonOrIcon> = [
		{
			name: 'dropdown',
			label: 'dropdown',
			className: 'btn btn-secondary',
			clickHandler: obj => console.log(obj),
			buttonOptions: gridDropdownActionButtons,
			disabled: row.status === 'cancelled' ? true : false,
		},
	];

	const header: TableHeader = {
		label: 'formsListing.headers.action',
		mappingField: 'action',
		buttonOrIcons: gridActionButtons,
	};

	return <CustomColumnButtonsOrIcons header={header} uniqueId={row.id} />;
};

export default Action;
