import Select from 'react-select';
import {Controller, useFormContext} from 'react-hook-form';
import {strings} from '../../../../../localization/i18n';
import Label from './components/Label';
import HelpText from './components/HelpText';
import ErrorText from './components/ErrorText';
import {
	DropdownOption,
	EditableField,
	ViewableField,
} from '../../../../../common/src/application/dynamicFormUtil/types/DynamicForm.types';
import {FieldType} from '../types/FieldTypeEnum';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import ViewableFieldComponent from './ViewableField.component';
import {parsePhoneNumber} from 'react-phone-number-input';
const SelectComponent = (props: any): JSX.Element => {
	const {
		fieldData,
		control,
		errors,
		callbackHandler,
		dynamicConstraints,
		configProvider,
		isViewOnlyMode,
		formModel,
		setValue,
	} = props;
	const {
		label,
		name,
		options,
		isMulti,
		searchable,
		placeholder,
		constraints,
		attributeCssClassName,
	} = fieldData;

	const className = attributeCssClassName;
	const {getValues} = useFormContext()
	const setInitialViewableField = (): ViewableField[] => {
		const fieldName =
			!_.isEmpty(_.get(getValues(), name))
				? _.get(getValues(), name)
				: dynamicConstraints.defaultValue;
		const fieldDetails = _.find(options, {value: fieldName});
		const viewableField = fieldDetails?.viewableFields
			? fieldDetails?.viewableFields
			: [];
		return viewableField;
	};

	const [viewableFields, setViewableFields] = useState<ViewableField[]>(
		setInitialViewableField(),
	);

	const setInitialEditableViewableFields = (): EditableField[] => {
		const fieldName =
			formModel && formModel[name]
				? formModel[name]
				: dynamicConstraints.defaultValue;
		const fieldDetails = _.find(options, {value: fieldName});
		const editableViewableFields = fieldDetails?.editableFields
			? fieldDetails?.editableFields
			: [];
		return editableViewableFields;
	};

	const [editableFields] = useState<EditableField[]>(
		setInitialEditableViewableFields(),
	);

	const setEditableFieldValues = (editableFields: EditableField[]) => {
		if (!isViewOnlyMode) {
			editableFields?.forEach((editableField: EditableField) => {
				if (
					typeof editableField.value === 'object' &&
					editableField.value !== null &&
					'countryCode' in editableField.value &&
					'phoneNumber' in editableField.value
				) {
					setValue(editableField.name, {
						countryCode: parsePhoneNumber(
							editableField.value.countryCode + editableField.value.phoneNumber,
						)?.country,
						phoneNumber: editableField.value.phoneNumber,
					});
				} else setValue(editableField.name, editableField.value);
			});
		}
	};

	const formattedOptions = options?.map((option: DropdownOption) => {
		return {
			label: strings(option.label),
			value: option.value,
			viewableFields: option.viewableFields,
			editableFields: option.editableFields,
			disabled: option.disabled,
		};
	});

	const handleChange = (field: any, callback: any) => {
		if (Array.isArray(field)) {
			const multiValue = field.map(opt => opt.value);
			let dataForCallback;
			if (multiValue != null && multiValue.length !== 0) {
				dataForCallback = {data: multiValue};
			} else {
				dataForCallback = null;
			}
			callback(dataForCallback);
			if (callbackHandler?.onChange) {
				callbackHandler.onChange(multiValue);
			}
			return;
		}
		callback(field.value);
		setViewableFields(field.viewableFields);
		setEditableFieldValues(field.editableFields);
		if (callbackHandler?.onChange) {
			callbackHandler.onChange(field.value);
		}
	};
	const handleBlur = (field: any) => {
		if (callbackHandler?.onBlur) {
			callbackHandler.onBlur(field);
		}
	};

	useEffect(() => {
		if (editableFields?.length > 0) {
			setTimeout(() => {
				setEditableFieldValues(editableFields);
			}, 10);
		}
	}, []);
	useEffect(() => {
		if (viewableFields?.length > 0) {
			setViewableFields(setInitialViewableField());
		}
	}, [setInitialViewableField, viewableFields?.length]);
	return (
		<>
			<div
				id={`div_om_dropDownComp_${name}`}
				className={className ? `form-item ${className}` : 'form-item'}>
				<div id={`div_dropDownComp_${name}`} className="form-group selectComp">
					<Label
						htmlFor={'select_' + name}
						content={label}
						configProvider={configProvider}
						required={dynamicConstraints?.required ?? false}
					/>
					<div className={'selectType'}>
						<Controller
							name={name}
							control={control}
							defaultValue={dynamicConstraints.defaultValue}
							rules={{
								required: {
									value: dynamicConstraints?.required ?? false,
									message: constraints?.required?.message ?? '',
								},
							}}
							render={({field}) => {
								let value;
								// multi
								if (typeof field?.value === 'object') {
									if (field?.value?.data != null) {
										value = field.value.data.map((val: string) =>
											_.find(options, {value: val}),
										);
										value.filter((val: any) => val == null);
										value.forEach((val: any) => ({
											...val,
											label: strings(val),
										}));
									}
								}
								// single
								else {
									value = _.find(options, {value: field.value}) ?? null;
									if (value != null) {
										value.label = strings(value.label);
									}
								}

								return (
									<Select
										key={'select_' + name}
										aria-labelledby={`error_${name} ${name}_helpText_${name}`}
										inputId={'select_' + name}
										onChange={(e: any) => {
											handleChange(e, field.onChange);
										}}
										onBlur={(e: any) => {
											field.onBlur();
											handleBlur(e);
										}}
										value={value ?? null}
										options={formattedOptions}
										placeholder={
											strings(placeholder) ||
											strings('ui.comps.dropdown.defaultPlaceHolder')
										}
										isMulti={isMulti ?? false}
										isSearchable={searchable ?? true}
										isDisabled={
											isViewOnlyMode === true
												? isViewOnlyMode
												: dynamicConstraints?.disabled ?? false
										}
									/>
								);
							}}
						/>

						{viewableFields?.length > 0 &&
							viewableFields.map((viewableField, key) => (
								<ViewableFieldComponent
									viewableField={viewableField}
									key={key}
								/>
							))}

						{_.get(errors, name) == null && (
							<HelpText content={dynamicConstraints?.helpText} name={name} />
						)}
						{errors != null && (
							<ErrorText
								errorObject={_.get(errors, name)}
								name={name}
								label={label}
								fieldType={FieldType.OPTION}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default SelectComponent;
