import BaseResponseModel from '../../../../network/interface/BaseResponseModel';

export default class FormResendOTPResponse implements BaseResponseModel {
	private readonly _success: boolean | undefined;
	private readonly _info: InfoModel | undefined;

	constructor({success, info}: {success?: boolean; info?: Array<InfoModel>}) {
		this._success = success;
		if (info != null && Array.isArray(info) && info.length > 0) {
			this._info = info[0];
		}
	}

	/**
	 * This method returns the response received from /minerva/moSignUp/sendOneTimePassword API call
	 * @returns {FormResendOTPResponseType}
	 */
	getResponseDataObject(): FormResendOTPResponseType {
		return {
			success: this._success ?? false,
			info: this._info,
		};
	}
}

export type FormResendOTPResponseType = {
	success: boolean;
	info?: InfoModel;
};
export type InfoModel = {
	status?: boolean;
	responseStatus?: number;
	totalSentsAvailable?: number;
	useResetPasswordPinFlow?: boolean;
	message?: string;
	code?: string;
};
