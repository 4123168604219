enum PATIENTBANNER {
    ALL= "all",
    PHONE= "phone",
    EMAIL= "email",
    VERIFIED = "Verified",
    PRIMARY = "Primary",
    SINGLE = "single",
    UNAVAILABLE = "unavailable"
}

export default PATIENTBANNER;