import {strings} from '../../../../localization/i18n';
import ComponentSelector from './component.selector';

import {FormField} from '../../../../common/src/application/dynamicFormUtil/types/DynamicForm.types';
import React from 'react';
import LinkComponent from '../LinkComponent/link.component';
import './formSegment.scss';

const FormSegment = (props: any) => {
	const {
		showHeader,
		formSegment,
		register,
		errors,
		control,
		setFocus,
		setError,
		clearErrors,
		formStateAndConstraints,
		callbackHandlers,
		linkCompHandlers,
		remoteDropdownRequests,
		fileUploadHandler,
		configProvider,
		requestExecutor,
		setValue,
		formModel,
		onAddSegment,
		onRemoveSegment,
		isViewOnlyMode,
		sessionStorageKeys,
	} = props;

	// render form section header
	const FormSegmentHeader = (props: {sectionName?: string}): JSX.Element => {
		return <>{props.sectionName && <h2>{strings(props.sectionName)}</h2>}</>;
	};

	//render form section description
	const FormSegmentDescription = (props: {
		sectionDesc?: string;
	}): JSX.Element => {
		return <>{props.sectionDesc && <p>{strings(props.sectionDesc)}</p>}</>;
	};

	return (
		<>
			{(formStateAndConstraints?.[formSegment.name]?.visible ??
				formSegment.visible ??
				true) && (
				<>
					{/* heading */}
					<div
						id="div_om_formSegment"
						className={
							formSegment.label || formSegment.sectionDesc
								? 'card-header-false'
								: ''
						}>
						{
							<div>
								<FormSegmentHeader sectionName={formSegment.label} />
								<FormSegmentDescription sectionDesc={formSegment.sectionDesc} />
							</div>
						}

						{formSegment.isCollapsable && (
							<button
								id="formSegmentCollapseBtn"
								type="button"
								className="btn btn-info"
								data-toggle="collapse"
								data-target={`#formSegment_${formSegment.name}`}>
								<b> - </b>
							</button>
						)}
					</div>

					{/* form segment data */}
					<div
						id={`formSegment_${formSegment.name}`}
						className={
							formSegment.isCollapsable
								? 'collapse show formOuter'
								: 'formOuter'
						}>
						{formSegment.formFields.map((fieldData: FormField, key: number) => {
							return (
								<React.Fragment key={key}>
									<ComponentSelector
										callbackHandler={callbackHandlers?.[fieldData.name]}
										formStateAndConstraints={formStateAndConstraints}
										fieldData={fieldData}
										register={register}
										setValue={setValue}
										formModel={formModel}
										errors={errors}
										control={control}
										setFocus={setFocus}
										setError={setError}
										clearErrors={clearErrors}
										linkActionHandler={linkCompHandlers?.[fieldData.name]}
										remoteDropdownRequestModel={
											remoteDropdownRequests
												? remoteDropdownRequests[fieldData.name]
												: null
										}
										fileUploadHandler={
											fileUploadHandler
												? fileUploadHandler[fieldData.name]
												: null
										}
										configProvider={configProvider}
										requestExecutor={requestExecutor}
										isViewOnlyMode={isViewOnlyMode}
										sessionStorageKeys={sessionStorageKeys}
									/>
								</React.Fragment>
							);
						})}
						<div className="row row-small w-100 addLinkMargin">
							<div className="col-6">
								{!isViewOnlyMode && formSegment.isReplicateEnabled && (
									<LinkComponent
										fieldData={{
											name: 'addSegment',
											text: strings(
												formSegment.replicateLabel ??
													'forms.sections.replicateLabel',
											),
											visible: formSegment.isReplicateEnabled,
											clickHandler: () => onAddSegment(formSegment.name),
										}}
									/>
								)}
							</div>
							<div className="col-6">
								{!isViewOnlyMode && formSegment.isRemoveEnabled && (
									<div className="setRemoveBtn">
										<LinkComponent
											fieldData={{
												name: 'removeSegment',
												text: strings(
													formSegment.removeLabel ??
														'forms.sections.removeLabel',
												),
												visible: formSegment.isRemoveEnabled,
												clickHandler: () => onRemoveSegment(formSegment.name),
											}}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default FormSegment;
