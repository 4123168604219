import {Service} from 'typedi';
import APIExecutor, {APIRequest} from '../../../network/APIExecutor';
import {APIUrl} from '../../../network/values/URLInfo';
import {selectedLanguage} from '../../../../../../localization/i18n';
import PatientActionBannerRequestModel from '../models/requestModels/PatientActionBannerRequestModel';

@Service()
class PatientSearchRepository {
	/**
	 * This method is used to fetch all the patients in application from the server
	 * @params request parameters used to search patients on the server
	 * @returns all the patient in application from the server
	 */
	fetchPatient(
		fetchPatientRequestModel: Record<string, any>,
	): Promise<Record<string, any>> {
		if (!fetchPatientRequestModel?.constraints?.profilePictureType) {
			fetchPatientRequestModel.constraints['profilePictureType'] = 'THUMBNAIL';
		}
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.FETCH_PATIENT + '?_language=' + selectedLanguage(),
				fetchPatientRequestModel,
			),
		);
	}

	/**
	 * This method is used to fetch all the linked patients in the application from the server
	 * @returns all the linked patients in application from the server
	 */
	fetchLinkedPatient(
		fetchLinkedPatientRequestModel: Record<string, any>,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.FETCH_LINKED_PATIENT,
				fetchLinkedPatientRequestModel,
			),
		);
	}

	/**
	 * fetches patient action banner details
	 * @param {PatientActionBannerRequestModel, patientId}
	 * @return {Record<string, any>}
	 */
	getPatientActionBannerDetails(
		patientActionBannerrRequestModel: PatientActionBannerRequestModel,
		patientId: number,
	): Promise<Record<string, any>> {
		const patientBannerDetails = APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.PATIENT_ACTION_BANNER_SHOW + `/${patientId}`,
				patientActionBannerrRequestModel,
			),
		);
		return patientBannerDetails;
	}
}
export default PatientSearchRepository;
