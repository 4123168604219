import {Service} from 'typedi';

import AddressRepositoryInterface from './interfaces/Address.repository.interface';

import FetchStatesRequest from '../models/state/FetchStatesRequest';
import FetchStatesResponse, {FetchStatesResponseType,} from '../models/state/FetchStatesResponse';

import FetchCountriesRequest from '../models/country/FetchCountriesRequest';
import FetchCountriesResponse, {FetchCountriesResponseType,} from '../models/country/FetchCountriesResponse';

import FetchCitiesRequest from '../models/city/FetchCitiesRequest';
import FetchCitiesResponse, {FetchCitiesResponseType,} from '../models/city/FetchCitiesResponse';

import APIExecutor, {APIRequest} from '../../../../../../network/APIExecutor';
import {APIUrl} from '../../../../../../network/values/URLInfo';
import FetchZipCountyRequest from '../models/county/FetchZipCountyRequest';
import FetchZipCountyResponse, {FetchZipCountyResponseType} from '../models/county/FetchZipCountyResponse';
import NetworkRequestMethodEnum from "../../../../../../../enums/NetworkRequestMethodEnum";

@Service()
export default class AddressRepository
	implements AddressRepositoryInterface
{
	fetchCities(_request: FetchCitiesRequest): Promise<FetchCitiesResponseType> {
		return new Promise<FetchCitiesResponseType>((resolve, reject) => {
			APIExecutor.postHTTP<FetchCitiesResponseType>(
				new APIRequest(APIUrl.MO_FETCH_CITIES, _request.getRequestDataObject()),
			)
				.then(
					(value: {
						data: FetchCitiesResponseType;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(
							new FetchCitiesResponse(value.data).getResponseDataObject(),
						);
					},
				)
				.catch(error => {
					reject(error);
				});
		});
	}

	fetchCountries(
		_request: FetchCountriesRequest,
	): Promise<FetchCountriesResponseType> {
		return new Promise<FetchCountriesResponseType>((resolve, reject) => {
			APIExecutor.postHTTP<FetchCountriesResponseType>(
				new APIRequest(
					APIUrl.MO_FETCH_COUNTRIES,
					_request.getRequestDataObject(),
				),
			)
				.then(
					(value: {
						data: FetchCountriesResponseType;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(
							new FetchCountriesResponse(value.data).getResponseDataObject(),
						);
					},
				)
				.catch(error => {
					reject(error);
				});
		});
	}

	fetchStates(_request: FetchStatesRequest): Promise<FetchStatesResponseType> {
		return new Promise<FetchStatesResponseType>((resolve, reject) => {
			APIExecutor.postHTTP<FetchStatesResponseType>(
				new APIRequest(APIUrl.MO_FETCH_STATES, _request.getRequestDataObject()),
			)
				.then(
					(value: {
						data: FetchStatesResponseType;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(
							new FetchStatesResponse(value.data).getResponseDataObject(),
						);
					},
				)
				.catch(error => {
					reject(error);
				});
		});
	}
	fetchZipCounties(_request: FetchZipCountyRequest): Promise<FetchZipCountyResponseType> {
		return new Promise<FetchZipCountyResponseType>((resolve, reject) => {
			APIExecutor.postHTTP<FetchZipCountyResponseType>(
				new APIRequest(APIUrl.MO_FETCH_ZIPCOUNTY, _request.getRequestDataObject()),
			)
			.then(
				(value: {
					data: FetchZipCountyResponseType;
					status: number;
					headers: Record<string, any>;
				}) => {
					resolve(
						new FetchZipCountyResponse(value.data).getResponseDataObject(),
					)
				}
			).catch(error => {
				reject(error);
			})
		})
	}
}
