import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Loader from '../../ui/comps/src/Loader';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import NotAccessible from './NotAccessible';

const LoginComponent = lazy(() => import('./login/login.component'));
const StartYourJourneyComponent = lazy(
	() => import('./startYourJourney/StartYourJourney'),
);
const DefaultLandingPage = lazy(() => import('./Default.landingpage'));
const TwoFactorComponent = lazy(() => import('./tfa/twoFactorAuth'));
const ForgotPasswordComp = lazy(
	() => import('../forgotPassword/forgotPassword.component'),
);
const ForgotUsernameComp = lazy(
	() => import('../forgotUsername/forgotUsername.component'),
);

const FirstLoginChangePasswordComp = lazy(
	() => import('./firstLoginChangePassword/firstLoginChangePassword.component'),
);

const ResetpasswordComp = lazy(() => import('./resetPassword/resetPassword'));
const AgreementsViewComp = lazy(() => import('./agreements/AgreementView'));

const UserRegistrationComponent = lazy(
	() => import('../userRegistration/userRegistration.component'),
);
const UserRegisterationSuccessful = lazy(
	() => import('../userRegistration/registerSuccessful'),
);

const CompositeForm = lazy(
	() => import('../patientList/forms/composite-forms/CompositeForm'),
);

const OthersFaqComponent = lazy(() => import('../faq/others/Faq'));

const PatientFaqComponent = lazy(() => import('../faq/patient/Faq'));

const PhysicianFaqComponent = lazy(() => import('../faq/physician/Faq'));

const DeletionRequestAccepted = lazy(() => import('./DeletionRequestAccepted'));

const AuthRoutes = (
	<>
		<Route
			path={RoutePathInfo.auth.path}
			element={
				<Suspense fallback={<Loader />}>
					<LoginComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.startYourJourney.path}
			element={
				<Suspense fallback={<Loader />}>
					<StartYourJourneyComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.forgotPassword.path}
			element={
				<Suspense fallback={<Loader />}>
					<ForgotPasswordComp />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.forgotUsername.path}
			element={
				<Suspense fallback={<Loader />}>
					<ForgotUsernameComp />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.twoFactorAuth.path}
			element={
				<Suspense fallback={<Loader />}>
					<TwoFactorComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.resetPassword.path}
			element={
				<Suspense fallback={<Loader />}>
					<ResetpasswordComp />
				</Suspense>
			}
		/>

		<Route
			path={RoutePathInfo.firstLoginChangePassword.path}
			element={
				<Suspense fallback={<Loader />}>
					<FirstLoginChangePasswordComp />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.defaultLandingPage.path}
			element={
				<Suspense fallback={<Loader />}>
					<DefaultLandingPage />
				</Suspense>
			}
		/>

		<Route
			path={RoutePathInfo.agreements.path}
			element={
				<Suspense fallback={<Loader />}>
					<AgreementsViewComp />
				</Suspense>
			}
		/>

		<Route
			path={RoutePathInfo.registerUser.path}
			element={
				<Suspense fallback={<Loader />}>
					<UserRegistrationComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.registerUserSuccessAccountReady.path}
			element={
				<Suspense fallback={<Loader />}>
					<UserRegisterationSuccessful />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.fillPublicForm.path}
			element={
				<Suspense fallback={<Loader />}>
					<CompositeForm />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.othersFaq.path}
			element={
				<Suspense fallback={<Loader />}>
					<OthersFaqComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.patientFaq.path}
			element={
				<Suspense fallback={<Loader />}>
					<PatientFaqComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.phyFaq.path}
			element={
				<Suspense fallback={<Loader />}>
					<PhysicianFaqComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.notAccessible.path}
			element={
				<Suspense fallback={<Loader />}>
					<NotAccessible />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.deletionRequestAccepted.path}
			element={
				<Suspense fallback={<Loader />}>
					<DeletionRequestAccepted />
				</Suspense>
			}
		/>
	</>
);

export default AuthRoutes;
