import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Loader from '../../ui/comps/src/Loader';
import RoutePathInfo from '../../common/src/enums/RoutingPath';

const SignUpComponent = lazy(() => import('./signup.component'));

const SignupV2Routes = (
	<>
		<Route
			path={RoutePathInfo.signup.path}
			element={
				<Suspense fallback={<Loader />}>
					<SignUpComponent />
				</Suspense>
			}
		/>
	</>
);

export default SignupV2Routes;
