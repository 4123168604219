import {lazy, Suspense} from 'react';
import {Route} from 'react-router-dom';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import PrivateRoute from '../../PrivateRoute';
import Loader from '../../ui/comps/src/Loader';

const NotificationList = lazy(() => import('./NotificationsList.component'));
const accessTo: Array<string> = ['PRACTITIONER', 'OTHERS', 'PATIENT'];

const NotificationRoutes = (
	<>
		<Route path="/" element={<PrivateRoute accessTo={accessTo} />}>
			<Route
				path={RoutePathInfo.notifications.path}
				element={
					<Suspense fallback={<Loader />}>
						<NotificationList />
					</Suspense>
				}
			/>
		</Route>
	</>
);

export default NotificationRoutes;
