import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Container from 'typedi';
import {LoaderProps} from '../../common/src/application/modules/generic/models/Loader';
import {NotificationResponse} from '../../common/src/application/modules/notifications/models/responseModels/NotificationSearchResponseModel';
import NotificationViewModel from '../../common/src/application/modules/notifications/viewModels/NotificationViewModel';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import GlobalObserversInstance from '../../common/src/utils/observers/GlobalObservers';
import {strings} from '../../localization/i18n';
import LinkComponent from '../../ui/comps/src/LinkComponent/link.component';
import Loader from '../../ui/comps/src/Loader';
import NotificationCard from '../notifications/notificationComponents/NotificationCard';
import '../notifications/notifications.scss';

const NotificationsContainer = Container.of('NotificationViewModel');
const NotificationDropdown = (): JSX.Element => {
	const notificationViewModel: NotificationViewModel =
		NotificationsContainer.get(NotificationViewModel);

	const navigate = useNavigate();

	const [notificationResponse, setNotificationResponse] = useState<
		NotificationResponse | undefined
	>(undefined);
	const [unreadNotificationsCount, setUnreadNotificationsCount] =
		useState<number>(global.unreadNotifications);

	const [showLoader, setShowLoader] = useState<boolean>(false);

	useEffect(() => {
		GlobalObserversInstance.notificationCountObserver.observable().subscribe({
			next: (count: number) => {
				setUnreadNotificationsCount(count);
			},
		});
	}, []);

	useEffect(() => {
		document
			.getElementById('notificationDropDown')
			?.addEventListener('click', function () {
				fetchTotalNotifications();
			});
		const loaderSubscriber = notificationViewModel
			.fetchLoaderObservable()
			.subscribe({
				next(response: LoaderProps) {
					setShowLoader(response.isToShowLoader);
				},
			});

		return () => {
			loaderSubscriber.unsubscribe();
		};
	}, []);

	useEffect(() => {
		// fetch unread notificaitons
		const unreadNotificationSubscriber = notificationViewModel
			.fetchNotifications({
				count: 10,
				skip: 0,
				sortDesc: 'dateCreated',
				auditTrail: false,
				read: false,
			})
			.subscribe({
				next(response: NotificationResponse) {
					if (response.totalCount && response.totalCount > 0) {
						global.unreadNotifications = response.totalCount;
						setUnreadNotificationsCount(response.totalCount);
						global.showNotificationBadge = true;
					}
				},
			});

		return () => {
			unreadNotificationSubscriber.unsubscribe();
		};
	}, [notificationViewModel]);

	const fetchTotalNotifications = () => {
		notificationViewModel
			.fetchNotifications({
				count: 10,
				skip: 0,
				sortDesc: 'dateCreated',
			})
			.subscribe({
				next(response: NotificationResponse) {
					global.showNotificationBadge = false;
					setNotificationResponse(response);
				},
			});
	};

	return (
		<>
			<li className="nav-item dropdown">
				<div className="dropdown">
					<button
						className="noBtn-style dropdown-toggle"
						type="button"
						onClick={fetchTotalNotifications}
						id="notificationDropDown"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span
							className="fIcon icon-notifications rightNavIcon"
							aria-hidden="true"
						></span>
						{unreadNotificationsCount &&
							unreadNotificationsCount > 0 &&
							global.showNotificationBadge === true && (
								<span className="circle" id="iHdR_notf_linkB">
									{' '}
									{unreadNotificationsCount}
								</span>
							)}
					</button>
					<div
						className="dropdown-menu dropdown-menu-right newNotification"
						aria-labelledby="notificationDropDown"
					>
						{showLoader && <Loader />}
						<div className="notification-dropdownHeader">
							{strings('notification.search.title')}
						</div>
						<ul className="notificationScroll">
							{notificationResponse &&
								notificationResponse.list &&
								notificationResponse.list.length > 0 &&
								notificationResponse.list.map(notification => {
									return <NotificationCard notification={notification} />;
								})}
						</ul>

						<div className="notification-viewAllLink">
							<LinkComponent
								clickHandler={() => navigate(RoutePathInfo.notifications.path)}
								text={strings('notification.search.labels.viewAll')}
							/>
						</div>
					</div>
				</div>
			</li>
		</>
	);
};

export default NotificationDropdown;
