import {
	NotificationResourceTypeEnum,
	SubjectTypeEnum,
} from '../../common/src/application/modules/notifications/enums/notificationTypeEnum';
import {AppNotification} from '../../common/src/application/modules/notifications/models/responseModels/NotificationSearchResponseModel';
import {isAccessible} from '../../common/src/utils/CommonUtils';

/**
 * handles navigation for notification
 * @param notification
 * @param user
 * @returns
 */
export const notificationRedirectionUrl = (
	notification: AppNotification,
	user: Record<string, any>,
): Record<string, any> | undefined => {
	let redirectTo: Record<string, any> | undefined = undefined;
	if (notification?.resource?.transitiveResources?.url) {
		redirectTo = {
			isAngularRoute: true,
			url: notification?.resource?.transitiveResources?.url,
		};
	} else if (
		notification?.resource?.name === NotificationResourceTypeEnum.QUESTIONNAIRE
	) {
		redirectTo = {
			isAngularRoute: true,
			url: '/editTemplate/' + notification.resource._id,
		};
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.QUESTIONNAIRE_RESPONSE
	) {
		redirectTo = {
			isAngularRoute: true,
			url: '/responseList/' + notification.resource._id,
		};
	} else if (
		notification?.resource?.name === NotificationResourceTypeEnum.TASK
	) {
		redirectTo = {
			isAngularRoute: true,
			url: '/taskRouter/' + notification?.resource?._id,
		};
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.SERVICE_REQUEST
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/patient/' +
				notification?.patientId +
				'/serviceRequest/view/' +
				notification?.resource?._id,
		};
	} else if (
		notification?.resource?.name === NotificationResourceTypeEnum.REFERRAL
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/patient/' +
				notification?.patientId +
				'/referral/view/' +
				notification?.resource?._id,
		};
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.SERVICE_REQUEST_AUDIT
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/patient/' +
				notification?.patientId +
				'/changeHistory/serviceRequest/' +
				notification?.resource?._id,
		};
	} else if (
		notification?.resource?.name === NotificationResourceTypeEnum.APPOINTMENT
	) {
		if (notification?.subject === SubjectTypeEnum.LIVE_MEETING) {
			redirectTo = {
				isAngularRoute: true,
				url: '/meeting/start/' + notification?.resource?._id,
			};
		} else if (notification.subject === SubjectTypeEnum.APPOINTMENT_DETAILS) {
			redirectTo = {
				isAngularRoute: true,
				url: '/appointment/details/' + notification?.resource?._id,
			};
		} else if (
			notification?.subject === SubjectTypeEnum.HOME_APPOINTMENT_DETAILS ||
			notification?.subject === SubjectTypeEnum.APPOINTMENT_STATUS_CHANGED
		) {
			redirectTo = {
				isAngularRoute: true,
				url: '/newAppointmentSystem/details/' + notification?.resource?._id,
			};
		} else {
			// making this default redirection to detail page as we got some cases where subject was not populated.
			redirectTo = {
				isAngularRoute: true,
				url: '/appointment/details/' + notification?.resource?._id,
			};
		}
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.DIAGNOSTIC_ORDER
	) {
		redirectTo = {
			isAngularRoute: true,
			url: '/recordDetails/' + notification.resource._id,
		};
	} else if (
		notification?.resource?.name ==
			NotificationResourceTypeEnum.DIAGNOSTIC_REPORT ||
		notification?.resource?.name == NotificationResourceTypeEnum.IMAGE_STUDY ||
		notification?.resource?.name == NotificationResourceTypeEnum.OBSERVATION
	) {
		if (
			notification?.resource?.name ===
				NotificationResourceTypeEnum.OBSERVATION &&
			notification?.resource?.transitiveResources?.diagnosticOrderId
		) {
			redirectTo = {
				isAngularRoute: true,
				url:
					'/recordDetails/' +
					notification.resource.transitiveResources.diagnosticOrderId,
			};
		} else {
			redirectTo = {
				isAngularRoute: true,
				url:
					'/recordDetails/' +
					notification?.resource?.transitiveResources?.diagnosticOrder,
			};
		}
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.MEDICATION_ORDER
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/patientDetails/' +
				notification.resource.transitiveResources?.patient +
				'/medications',
		};
	} else if (
		notification?.resource?.name === NotificationResourceTypeEnum.CONDITION
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/patientDetails/' +
				notification.resource.transitiveResources?.patient +
				'/conditions',
		};
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.ALLERGY_INTOLERANCE
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/patientDetails/' +
				notification.resource.transitiveResources?.patient +
				'/allergies',
		};
	} else if (
		notification?.resource?.name == NotificationResourceTypeEnum.SUBJECT ||
		notification?.resource?.name == NotificationResourceTypeEnum.PATIENT
	) {
		var hasRolePatient = isAccessible(['ROLE_PATIENT'], user);
		redirectTo = {
			isAngularRoute: true,
			url: hasRolePatient
				? '/dashboard'
				: '/patientDetails/' + notification.resource._id,
		};
	} else if (
		notification?.resource?.name ==
			NotificationResourceTypeEnum.HELP_DESK_QUERY ||
		notification?.resource?.name ==
			NotificationResourceTypeEnum.HELP_DESK_QUERY_RESPONSE
	) {
		if (
			notification?.keyVariables?.messageVariables?.hasOwnProperty('<taskId>')
		) {
			redirectTo = {
				isAngularRoute: true,
				url:
					'/taskDetails/' +
					notification.keyVariables.messageVariables['<taskId>'],
			};
		} else {
			if (
				notification?.resource?.name ===
				NotificationResourceTypeEnum.HELP_DESK_QUERY_RESPONSE
			) {
				redirectTo = {
					isAngularRoute: true,
					url:
						'/inbox/helpdesk/queryDetails/' +
						notification.resource.transitiveResources?._id,
				};
			} else {
				redirectTo = {
					isAngularRoute: true,
					url: '/inbox/helpdesk/queryDetails/' + notification.resource?._id,
				};
			}
		}
	} else if (
		notification?.resource?.name === NotificationResourceTypeEnum.BILLS_ORDERS
	) {
		redirectTo = {
			isAngularRoute: true,
			url: '/bills/list/' + notification?.resource?.location,
		};
	} else if (
		notification?.resource?.name == NotificationResourceTypeEnum.SHARING
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/recordDetails/' +
				notification.resource.transitiveResources?.diagnosticOrderId,
		};
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.HELP_DESK_QUERY
	) {
		redirectTo = {
			isAngularRoute: true,
			url: '/inbox/helpdesk/queryDetails/' + notification.resource._id,
		};
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.DOCUMENT_REFERENCE
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/patient/' +
				notification.patientId +
				'/document/' +
				notification.resource._id,
		};
	} else if (
		notification?.resource?.name ===
		NotificationResourceTypeEnum.COMMUNICATION_CHAIN
	) {
		//deprecated
		redirectTo = {
			isAngularRoute: true,
			url:
				'/communication/inbox/communicationChain/' + notification.resource._id,
		};
	} else if (
		notification?.resource?.name ===
			NotificationResourceTypeEnum.COMMUNICATION &&
		notification?.resource?.transitiveResources
	) {
		redirectTo = {
			isAngularRoute: true,
			url:
				'/communication/inbox/communicationChain/' +
				notification.resource.transitiveResources._id,
		};
	} else if (
		notification?.resource?.name == NotificationResourceTypeEnum.PATIENT_PROFILE
	) {
		redirectTo = {
			isAngularRoute: true,
			url: '/patientProfile/view/' + notification.resource._id,
		};
	} else if (
		notification?.resource?.name === NotificationResourceTypeEnum.CONSENT
	) {
		if (notification.subject === SubjectTypeEnum.LINKED_PATIENT_LIST) {
			redirectTo = {
				isAngularRoute: false,
				url: '/linkedPatients',
			};
		} else if (notification?.subject === SubjectTypeEnum.MANAGE_LINKED_USER) {
			redirectTo = {
				isAngularRoute: false,
				url: 'patient/' + notification.patientId + '/manageLinkedUsers',
			};
		}
	} else if (
		notification?.resource?.name === 'bulkActionRequest' &&
		notification?.resource?.status === 'PROCESSED' &&
		notification?.resource?.transitiveResources?.readOnlyNotification ===
			'false'
	) {
		redirectTo = {
			isBulkDownloadRequest: true,
			resourceId: notification?.resource?._id,
		};
	} else {
		redirectTo = undefined;
	}

	return redirectTo;
};
