import { Observable } from "rxjs";
import Container, { Service } from "typedi";
import { LoaderType } from "../../generic/models/Loader";
import BasicSubscribersViewModel from "../../generic/viewModel/BasicSubscribers.viewmodel";
import NonceNetworkRepository from "../network/repository/NonceNetworkRepository";

@Service()
class NonceService extends BasicSubscribersViewModel<
any,
Record<string, any>
> {

    private nonceNetworkRepository: NonceNetworkRepository;
    constructor() {
        super();
        this.nonceNetworkRepository = Container.get(NonceNetworkRepository);
        this.loaderSubject
    }

    /**
     * 
     * @returns Observable with Record<string,any> for returning nonce token from server
     */
    generateNonceToken(): Observable<Record<string, any>> {
        return new Observable<Record<string, any>>((subscriber) => {
            this.loaderSubject.next({
                isToShowLoader: true,
                type: LoaderType.OverScreen
            })
            this.nonceNetworkRepository.generateNonceToken().then(response => {
                if (response?.data?.nonceToken) {
                    subscriber.next(response?.data);
                }
            }).finally(() => {
                this.loaderSubject.next({
                    isToShowLoader: false,
                    type: LoaderType.OverScreen
                })

            })
        })
    }

}
export default NonceService;
