import PasswordStrengthBar from 'react-password-strength-bar';
import {useWatch} from 'react-hook-form';
import React from 'react';
import {strings} from '../../../../../../../localization/i18n';

const PasswordStrengthThirdParty = PasswordStrengthBar as any;

const PasswordStrength = (props: any): JSX.Element => {
	const {control, name, passwordConstraints} = props;

	const pwd = useWatch({
		control: control,
		name: name,
	});

	return (
		<div id={`div_om_password_strength_${name}`} style={{marginTop: '15px'}}>
			<div id={`div_password_strength_${name}`} style={{fontWeight: 'bold'}}>
				{strings('ui.comps.passwordStrength')}
			</div>
			<PasswordStrengthThirdParty
				password={pwd ?? ''}
				minLength={passwordConstraints.minLength ?? 8}
				scoreWords={[null, null, null, null, null]}
				shortScoreWord={null}
			/>
		</div>
	);
};

export default PasswordStrength;
