import {strings} from '../../../../../../localization/i18n';

const Label = (props: any): JSX.Element => {
	const {htmlFor, content, required, configProvider, className} = props;
	return (
		<>
			{content && (
				<span>
					<label
						id={`label_dyn_form_${htmlFor}`}
						htmlFor={htmlFor}
						className={className}>
						{strings(content)}
					</label>
					{required &&
						((!configProvider?.getConfig().optionalToggle ?? true) ||
							required) && <span className="text-danger">*</span>}
					{!required &&
						(configProvider?.getConfig().optionalToggle ?? false) &&
						` (${strings('ui.comps.textInput.optional')})`}
				</span>
			)}
		</>
	);
};

export default Label;
