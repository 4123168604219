import {strings} from '../../localization/i18n';

const NotAccessible = () => {
	return (
		<>
			<div className="pageBreak">
				<span aria-hidden="true" className="image"></span>
				<span className="text text-hint">
					{strings('accessibility.notAccessible')}
				</span>
			</div>
		</>
	);
};

export default NotAccessible;
