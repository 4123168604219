import {IDocumentsHelper} from '../../common/src/interface/documents/IDocumentsHelper';

export const DocumentsHelper: IDocumentsHelper = {
	saveFileToLocalCache: (
		mime: string | null,
		docRefId: any,
		key: string,
		binaryBuffer: string,
		fileName?: string,
	): Promise<boolean> => {
		return new Promise(resolve => {
			if (mime) {
				let blobTypeImage = new Blob([binaryBuffer as BlobPart], {
					type: 'application/pdf',
				});

				// Creating URL for BLOB in order to view and download it
				const url: string = window.URL.createObjectURL(blobTypeImage);

				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName ?? 'test'); //or any other name
				document.body.appendChild(link);
				link.click();
				resolve(true);
			}
		});
	},

	getMimeType: (extension: string | undefined): string | null => {
		throw new Error('Function not implemented.');
	},
};

export const openStore = (mime: string) => {};
