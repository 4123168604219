import APIExecutor, {
	APIRequest,
} from '../../../common/src/application/network/APIExecutor';

import {IApiExecutorService} from '../../../ui/comps/src/utils/core.mapper';

export class APIExecutorTransformer implements IApiExecutorService {
	public postHTTP(data: any) {
		return APIExecutor.postHTTP(data as APIRequest);
	}

	public getHTTP(data: any) {
		return APIExecutor.getHTTP(data as APIRequest);
	}
}
