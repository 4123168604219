import {lazy, Suspense} from 'react';
import {Route} from 'react-router-dom';
import RoutePathInfo from '../../../common/src/enums/RoutingPath';
import Loader from '../../../ui/comps/src/Loader';

const UserProfile = lazy(() => import('../UserProfile'));

const UserProfileRoutes = (
	<>
		<Route
			path={RoutePathInfo.viewUserProfile.path}
			element={
				<Suspense fallback={<Loader />}>
					<UserProfile />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.editUserProfile.path}
			element={
				<Suspense fallback={<Loader />}>
					<UserProfile />
				</Suspense>
			}
		/>
	</>
);

export default UserProfileRoutes;
