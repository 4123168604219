import {PageButton} from '../ActionButtons/IPageButton';
import DynamicForm from '../dynamic-form/dynamic.form';
import Card from '../card/Card';
import './otpComponent.scss';
import {OtpFormTypes} from './types/otpForm.types';
import {useEffect, useState} from 'react';
import {
	DynamicFormDataV2,
	FormSegment,
} from '../../../../common/src/application/dynamicFormUtil/types/DynamicForm.types';
import {ResendOTPTimer} from './components/resendOTPTimer';
import encryptedStorageHelperInstance from '../../../../storage/EncryptedStorageHelper';
import {strings} from '../../../../localization/i18n';
import {showNotification} from '../notification/view/ToastNotification';
const OtpComponent = (props: OtpFormTypes): JSX.Element => {
	const {
		submitHandler,
		otpLabel,
		resendOtpHandler,
		otpSentMessage,
		configProvider,
		showResendTimer = true,
		sessionStorageKeys,
		actionButtonLabel,
		className,
	} = props;

	const [otpFormData, setOtpFormData] = useState<DynamicFormDataV2>({
		formData: [],
		rules: [],
	});

	useEffect(() => {
		if (sessionStorageKeys) {
			const SERVER_CONFIG = encryptedStorageHelperInstance.getItemSync(
				sessionStorageKeys.SERVER_CONFIG,
			);
			setOtpFormData(SERVER_CONFIG.OTPFormData);
		}
	}, []);

	useEffect(() => {
		otpFormData.formData.map((formSegment: FormSegment) => {
			formSegment.formFields.map((field, key) => {
				if (field.type === 'custom') {
					otpFormData.formData[0].formFields[key].customProps = {
						submitHandler: submitHandler,
						resendOtpHandler: resendOtpHandler,
						showResendTimer: showResendTimer,
						resendTimerInSeconds:
							configProvider?.getConfig().resendTimerInSeconds,
					};
					otpFormData.formData[0].formFields[key]['includeComponent'] =
						ResendOTPTimer;
				}
			});
		});
	}, [otpFormData]);

	/**
	 * This function is called on clicking submit button.
	 * @param data
	 */
	const submitOtp = (data: any) => {
		submitHandler(data);
	};

	/**
	 * This function is called in case any error occurs.
	 */
	const errorHandler = () => {
		showNotification({
			level: 'error',
			message: strings('errors.genericFormError'),
			type: 'local',
			documentIdToFocus: 'OTP',
		});
	};

	const otpActionButtons: PageButton[] = [
		{
			name: actionButtonLabel
				? actionButtonLabel
				: 'form.actionButtons.proceed',
			isAction: true,
			visible: true,
			type: 'submit',
			className: 'btn-block',
		},
	];

	const time = new Date();
	time.setSeconds(time.getSeconds() + 10);

	return (
		<>
			<div
				className={
					className
						? `${className} OTPwithoutSignupCard`
						: 'OTPwithoutSignupCard'
				}>
				{otpSentMessage && (
					<Card className="alert alert-success withClose mb15">
						{otpSentMessage}{' '}
					</Card>
				)}
				<DynamicForm
					dynamicFormData={otpFormData}
					onSubmit={submitHandler}
					onError={errorHandler}
					actionButtons={otpActionButtons}
					configProvider={configProvider}
				/>
			</div>
		</>
	);
};

export default OtpComponent;
