import {Controller} from 'react-hook-form';
import {isValidPhoneNumber} from 'react-phone-number-input';
import PhoneComponent from './phone.component';

export default function PhoneController(props: any) {
	const {fieldData, control, dynamicConstraints, verified} = props;
	const {name, constraints} = fieldData;

	return (
		<Controller
			name={name}
			control={control}
			rules={{
				required: {
					value: dynamicConstraints?.required ?? false,
					message: constraints?.required?.message ?? '',
				},
				validate: {
					validPhoneNumber: value =>
						value == null ||
						isValidPhoneNumber(value.countryCode + value.phoneNumber),
					additionalValidation: props?.additionalValidator ?? (() => true),
				},
			}}
			shouldUnregister={true}
			render={({field}) => (
				<PhoneComponent {...props} field={field} verified={verified} />
			)}
		/>
	);
}
