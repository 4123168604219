import './card.scss';
import {CardType} from './card.type';

function Card(props: CardType) {
	/*
	 * gets some optional css properties and child element
	 * used to create card/container elmen around other elements
	 */
	return (
		<div
			id="div_cardComp"
			className={
				props.className
					? props.className + ' '
					: 'cardBeforeLogin'
			}>
			{props.children ? props.children : null}
		</div>
	);
}

export default Card;
