import RawHTML from '../../../ui/comps/src/dynamic-form/form-components/RawHTML.component';
import {AppNotification} from '../../../common/src/application/modules/notifications/models/responseModels/NotificationSearchResponseModel';
import {getFormattedDateTime} from '../../../common/src/utils/DateTimeUtils';
import {strings} from '../../../localization/i18n';
import NotificationViewModel from '../../../common/src/application/modules/notifications/viewModels/NotificationViewModel';
import Container from 'typedi';
import {useContext, useState} from 'react';
import {
	AppContextInterface,
	GlobalStateContext,
} from '../../../common/src/utils/context/GlobalStateContext';
import {useNavigate} from 'react-router-dom';
import {notificationRedirectionUrl} from '../notificationUtils';
import NonceService from '../../../common/src/application/modules/user/service/Nonce.service';
import {APIUrl} from '../../../common/src/application/network/values/URLInfo';

const NonceServiceContainer = Container.of('NonceService');

const NotificationCard = (props: any): JSX.Element => {
	const navigate = useNavigate();
	const {notification}: {notification: AppNotification} = props;
	const {user} = useContext<AppContextInterface>(GlobalStateContext);
	const notificationsViewModel: NotificationViewModel = Container.get(
		NotificationViewModel,
	);
	const nonceService = NonceServiceContainer.get(NonceService);

	const [isRead, setIsRead] = useState<boolean | undefined>(notification.read);

	const processMessageVariables = (
		messageVariables?: Record<string, any>,
	): Record<string, any> => {
		const processedMessageVariable: Record<string, any> = {};
		if (messageVariables) {
			const messageVarKeys = Object.keys(messageVariables);
			messageVarKeys.forEach((varKey: string) => {
				const processedVar = strings(messageVariables[varKey].toString());
				processedMessageVariable[varKey] = processedVar;
			});
		}
		return processedMessageVariable;
	};

	const markNotificationAsRead = () => {
		if (notification.read === false) {
			notificationsViewModel
				.markNotificationAsRead({
					id: notification.id,
					read: true.toString(),
				})
				.subscribe({
					next: data => {
						setIsRead(true);
					},
				});
		}
	};

	const redirectNotificationToDetails = () => {
		const resultingURL = notificationRedirectionUrl(notification, user);
		if (resultingURL !== undefined) {
			if (resultingURL.isBulkDownloadRequest && resultingURL.resourceId) {
				nonceService.generateNonceToken().subscribe({
					next: (nonceTokenData: Record<string, any>) => {
						window.open(
							global.ConfigurationHolder.urls?.applicationServer +
								'/minerva/' +
								APIUrl.MO_BULK_DOWNLOAD +
								'?id=' +
								resultingURL.resourceId +
								'&nonceToken=' +
								nonceTokenData.nonceToken,
							'_blank',
						);
					},
				});
			} else if (resultingURL.isAngularRoute) {
				window.location.href =
					global.ConfigurationHolder.urls.angularApplicationFrontend +
					resultingURL.url;
			} else {
				navigate(resultingURL.url);
			}
		}
	};

	return (
		<li
			role="listitem"
			className={`${!isRead ? 'unread' : ''} ${
				props.className ? props.className : ''
			}`}
		>
			<button
				tabIndex={0}
				role="link"
				className="notificationBlock w-100"
				onClick={e =>
					notification.resource?.transitiveResources?.readOnlyNotification &&
					notification.resource?.transitiveResources?.readOnlyNotification ===
						'true'
						? null
						: redirectNotificationToDetails()
				}
			>
				{notification.priority && notification.priority.key && (
					<span>
						<span className="sr-only">
							{strings('notification.search.ariaLabel.notificationType') +
								': ' +
								strings(
									'notification.search.ariaLabel.' + notification.priority.key,
								)}
						</span>

						<em
							aria-hidden="true"
							className={
								'float-left informationIconList fIcon ' +
								strings(
									'notification.search.icons.' + notification.priority.key,
								)
							}
						></em>
					</span>
				)}

				<div className="notificationContainer">
					<div>
						<p className="notifyTitle">
							<RawHTML
								fieldData={{
									defaultValue: strings(
										notification.message,
										processMessageVariables(
											notification.keyVariables?.messageVariables,
										),
									),
								}}
							></RawHTML>
						</p>
						<div className="notifyTime">
							<span
								className="fIcon icon-notification-reminder iconSize"
								aria-hidden="true"
							></span>
							{notification.subject && (
								<span className="notificationSubject">
									<span className="sr-only">
										{strings('notification.search.ariaLabel.subject')}
									</span>{' '}
									{strings(notification.subject)},
								</span>
							)}
							{notification.dateCreatedCopy && (
								<span className="notificationDateCreated">
									<span>
										<span className="sr-only">
											{strings('notification.search.ariaLabel.date')}
										</span>{' '}
										{getFormattedDateTime(notification.dateCreatedCopy)}
									</span>
								</span>
							)}
						</div>
					</div>
					<div className="clearfix"></div>
				</div>
			</button>

			<p className="mark_read">
				<button
					role="button"
					onClick={e => {
						e.stopPropagation();
						markNotificationAsRead();
					}}
					className="btn btn-link noBtnStyle noUnderline"
					title={strings('notification.search.labels.markRead')}
					data-toggle="tooltip"
				>
					{' '}
					<span
						className="fIcon icon-show_password iconSize"
						aria-hidden="true"
					></span>
				</button>
			</p>
		</li>
	);
};

export default NotificationCard;
