import {Service} from 'typedi';
import APIExecutor, {APIRequest} from '../../../../network/APIExecutor';
import {APIUrl} from '../../../../network/values/URLInfo';
import CompositeFormDataRequest from '../models/CompositeFormDataRequest';
import CompositeFormDataResponse, {
	CompositeFormResponseType,
} from '../models/CompositeFormDataResponse';
import SubmitCompositeFormRequest from '../models/SubmitCompositeFormRequest';
import SubmitCompositeFormResponse, {
	SubmitCompositeFormResponseType,
} from '../models/SubmitCompositeFormResponse';
import CompositeFormRepositoryInterface from './interfaces/CompositeForm.repository.interface';
import {
	CompositeFormListModel, CompositeFormsFilterConfig,
	CompositeFormsFilterConfigRequest,
	CompositeFormsRequestModel, DiscardFormParams, DiscardFormResponse
} from "../models/CompositeFormListModel";
import DownloadFormRequest from "../models/DownloadFormRequest";
import ErrorModel from "../../../../network/model/ErrorModel";
@Service()
class CompositeFormRepository implements CompositeFormRepositoryInterface {
	/**
	 * This method is used to make API call for formResponse/getFormAttributesAndFormData
	 * @param {CompositeFormDataRequest} request model for getFormAttributesAndFormData API
	 * @param isPublic
	 * @return {Promise<CompositeFormDataResponse>}
	 */
	getFormAttributesAndFormData(
		request: CompositeFormDataRequest,
		isPublic?: boolean
	): Promise<CompositeFormDataResponse> {
		return new Promise<CompositeFormDataResponse>((resolve, reject) => {
			APIExecutor.postHTTP<CompositeFormResponseType>(
				new APIRequest(
					isPublic ? APIUrl.GET_PUBLIC_FORM_RESPONSE_ATTRIBUTES_DATA : APIUrl.GET_FORM_RESPONSE_ATTRIBUTES_DATA,
					request.getRequestDataObject(),
				),
			)
				.then(
					(value: {
						data: CompositeFormResponseType;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(new CompositeFormDataResponse(value.data));
					},
				)
				.catch(error => {
					reject(error);
				});
		});
	}

	/**
	 * This method is used to make API call for formResponse/submitForm
	 * @param {SubmitCompositeFormRequest} request model for submitForm API
	 * @return {Promise<SubmitCompositeFormResponse>}
	 */
	submitForm(
		request: SubmitCompositeFormRequest,
		isPublic?: boolean
	): Promise<SubmitCompositeFormResponse> {
		return new Promise<SubmitCompositeFormResponse>((resolve, reject) => {
			APIExecutor.postHTTP<SubmitCompositeFormResponseType>(
				new APIRequest(
					isPublic ? APIUrl.SUBMIT_PUBLIC_FORM_RESPONSE : APIUrl.SUBMIT_FORM_RESPONSE,
					request.getRequestDataObject(),
				),
			)
				.then(
					(value: {
						data: SubmitCompositeFormResponseType;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(new SubmitCompositeFormResponse(value.data));
					},
				)
				.catch(error => {
					reject(error);
				});
		});
	}

	/**
	 * Paginated API call for fetching form list based on filters
	 * @param {CompositeFormsRequestModel} request Request model with filters and pagination data
	 * @return {Promise<CompositeFormListModel>} Form list (paginated)
	 */
	fetchForms(request:CompositeFormsRequestModel):Promise<CompositeFormListModel>{
		return new Promise<CompositeFormListModel>((resolve, reject) => {
			APIExecutor.postHTTP<CompositeFormListModel>(
				new APIRequest(
					APIUrl.COMPOSITE_FORM_LIST,
					request,
				),
			)
				.then(
					(value: {
						data: CompositeFormListModel;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(value.data);
					},
				)
				.catch((error:ErrorModel) => {
					reject(error);
				});
		});
	}

	/**
	 * API call for fetching config for form search filters
	 * @param {CompositeFormsFilterConfigRequest} request
	 * @return {Promise<CompositeFormsFilterConfig>} Config for rendering filters
	 */
	fetchFormsFilterConfig(request:CompositeFormsFilterConfigRequest):Promise<CompositeFormsFilterConfig>{
		return new Promise<CompositeFormsFilterConfig>((resolve, reject) => {
			APIExecutor.postHTTP<CompositeFormsFilterConfig>(
				new APIRequest(
					APIUrl.COMPOSITE_FORMS_FILTER_CONFIG,
					request,
				),
			)
				.then(
					(value: {
						data: CompositeFormsFilterConfig;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(value.data);
					},
				)
				.catch((error:ErrorModel) => {
					reject(error);
				});
		});
	}

	/**
	 * Discard Forms API call
	 * @return {Promise<DiscardFormResponse>} Success or fail
	 * @param params
	 */
	executeDiscardForm(params:DiscardFormParams):Promise<DiscardFormResponse>{
		return new Promise<DiscardFormResponse>((resolve, reject) => {
			APIExecutor.deleteHTTP<DiscardFormResponse>(
				new APIRequest(
					APIUrl.COMPOSITE_FORMS_DISCARD,
					{},
					undefined,
					{},
					params,
				),
			)
				.then(
					(value: {
						data: DiscardFormResponse;
						status: number;
						headers: Record<string, any>;
					}) => {
						resolve(value.data);
					},
				)
				.catch((error:ErrorModel) => {
					reject(error);
				});
		});
	}
	/**
	 * This method is used to make API call for Downloading the form
	 * @param {DownloadFormRequest} request model for Download Form API
	 * @return {Promise<any>}
	 */
	downloadForm(request:DownloadFormRequest):Promise<any>{
		const url = APIUrl.COMPOSITE_FORM_DOWNLOAD
		const bodyData = {'formResponseId': request.getRequestDataObject().formRequestId}

		return new Promise<any>((resolve, reject) => {
			APIExecutor.postHTTP(new APIRequest(url, bodyData, 'arraybuffer'))
				.then(value => {
					resolve(value)
				})
				.catch( (error:ErrorModel) => {
					reject(error)
				})
		})
	}

}

export default CompositeFormRepository;
