import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class BulkActionRequestModel implements BaseRequestModel {
	private readonly actionName?: string;
	private readonly selectedResourceIds?: Array<number | string>;
	private readonly searchCriteria?: Record<string, any>;
	private readonly deSelectedResourceIds?: Array<number | string>;
	private readonly patientId?: number | string;
	private readonly sendAllToEMRDetails?: any;

	constructor({
		actionName,
		selectedResourceIds,
		searchCriteria,
		deSelectedResourceIds,
		patientId,
		sendAllToEMRDetails,
	}: BulkActionRequestData) {
		this.actionName = actionName;
		this.selectedResourceIds = selectedResourceIds;
		this.searchCriteria = searchCriteria;
		this.deSelectedResourceIds = deSelectedResourceIds;
		this.patientId = patientId;
		this.sendAllToEMRDetails = sendAllToEMRDetails;
	}

	/**
	 * returns bulk download action request
	 * @returns {BulkActionRequestData}
	 */
	getRequestDataObject(): BulkActionRequestData {
		return {
			actionName: this.actionName,
			selectedResourceIds: this.selectedResourceIds,
			searchCriteria: this.searchCriteria,
			deSelectedResourceIds: this.deSelectedResourceIds,
			patientId: this.patientId,
			sendAllToEMRDetails: this.sendAllToEMRDetails,
		};
	}
}

export type BulkActionRequestData = {
	actionName?: string;
	selectedResourceIds?: Array<number | string>;
	deSelectedResourceIds?: Array<number | string>;
	patientId?: number | string;
	searchCriteria?: Record<string, any>;
	sendAllToEMRDetails?: any;
};

export default BulkActionRequestModel;
