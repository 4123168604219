import {strings} from '../../../../../../localization/i18n';
import {isValueAvailable} from '../../../utils/uiCompsUtils';
import {ErrorTextType} from '../../types/DynamicFormTypes';
import {FieldType} from '../../types/FieldTypeEnum';

const ErrorText = (props: ErrorTextType): JSX.Element => {
	const {errorObject, label, defaultError, fieldType} = props;

	const getDefaultErrorType = () => {
		let defaultError;
		switch (fieldType) {
			case FieldType.OPTION:
				defaultError = 'form.field.defaultSelectErrorMsg';
				break;
			case FieldType.UPLOAD:
				defaultError = 'form.field.defaultUploadErrorMsg';
				break;
			case FieldType.DATE:
				defaultError = 'form.field.defaultDateErrorMsg';
				break;
			case FieldType.TIME:
				defaultError = 'form.field.defaultTimeErrorMsg';
				break;
			default:
				defaultError = 'form.field.defaultEnterErrorMsg';
		}
		return defaultError;
	};

	const defaultErrorMessage: string = defaultError
		? defaultError
		: getDefaultErrorType();

	return (
		<>
			{errorObject && (
				<div className="text-error" id={`error_${props?.name}`}>
					{isValueAvailable(errorObject.message)
						? strings(errorObject.message)
						: strings(defaultErrorMessage, {fieldLabel: strings(label)})}
				</div>
			)}
		</>
	);
};

export default ErrorText;
