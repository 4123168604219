class LocationSearchValidationModel {
    constructor(
        private _fieldsError: string = '',
        private _nameStartWithError: string = '',
        private _typeInError: string = '',
        private _isError: boolean = false
    ) {}
    set fieldsError(value: string) {
        this._fieldsError = value
    }
    get fieldsError(): string {
        return this._fieldsError
    }
    set nameStartWithError(value: string) {
        this._nameStartWithError = value
    }
    get nameStartWithError(): string {
        return this._nameStartWithError
    }
    set typeInError(value: string) {
        this._typeInError = value
    }
    get typeInError(): string {
        return this._typeInError
    }
    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}
export default LocationSearchValidationModel