import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './photo.scss';
import defaultPhoto from '../../../../assets/userProfile.png';
import {strings} from '../../../../../../localization/i18n';
import {initialsFromName} from '../../../../../../common/src/utils/StringUtils';

const PhotoComponent = (props: any) => {
	const {fieldData, isViewOnlyMode} = props;

	const {name, visible, className} = fieldData;

	const [photo, setPhoto] = useState('');

	const [photoUploaded, setPhotoUploaded] = useState<boolean>(false);

	const [photoSizeExceedFlag, setPhotoSizeExceedFlag] =
		useState<boolean>(false);

	const [wrongFileTypeFlag, setWrongFileTypeFlag] = useState<boolean>(false);

	const [showInitialsFlag, setShowInitialsFlag] = useState(false);

	const [initials, setInitials] = useState('');

	useEffect(() => {
		if (props.picUploaded) {
			setPhoto(props.base64PhotoString);
			setPhotoUploaded(props.picUploaded);
		} else if (props.showInitials) {
			setShowInitialsFlag(props.showInitials);
			setInitials(initialsFromName(props.name, ''));
		} else {
			setPhoto(defaultPhoto);
		}
	}, []);

	const photoHandler = (e: any) => {
		const file: any = e.target.files[0];
		if (file) {
			const fileType: string = file.type;
			const fileSize: any = file.size / 1024;
			validateAndUploadPhoto(file, fileType, fileSize);
		}
	};

	const validateAndUploadPhoto = (
		file: any,
		fileType: string,
		fileSize: number,
	) => {
		const maxPicSizeInKB: any =
			props?.configProvider?.getConfig()?.photoUploader?.maxPicSizeInKB;
		if (
			props?.configProvider
				?.getConfig()
				?.supportedImageTypes?.indexOf(fileType) === -1
		) {
			setWrongFileTypeFlag(true);
		} else if (fileSize > maxPicSizeInKB[fileType]) {
			setPhotoSizeExceedFlag(true);
		} else {
			setPhotoSizeExceedFlag(false);
			setWrongFileTypeFlag(false);
			setShowInitialsFlag(false);
			uploadPhoto(file);
		}
	};

	const uploadPhoto = (file: any) => {
		const reader: any = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setPhoto(reader.result);
				let photoData: any = {};
				photoData.base64PhotoMimetype = file.type;
				photoData.base64PhotoString = reader.result;
				photoData.photoUpdated = true;
				props.handleChange(photoData);
			}
		};
		reader.readAsDataURL(file);
	};

	const removePhotoHandler = () => {
		setPhoto(defaultPhoto);
		setPhotoUploaded(false);
		let photoData: any = {};
		photoData.base64PhotoMimetype = '';
		photoData.base64PhotoString = '';
		photoData.photoUpdated = false;
		props.setPhoto(photoData);
	};

	const uploadIconStyle = {
		zIndex: -3,
		marginTop: -30,
		marginLeft: 50,
	};

	return (
		<>
			{props.renderProfilePicture ? (
				<div
					className="pp-box profile--pic--container"
					id={`sUp_pd_thm1_${name}`}
					style={{display: 'block'}}>
					{!showInitialsFlag ? (
						<img
							src={photo}
							alt=""
							id={`img_${name}`}
							className="img"
							width="100"
							height="100"
						/>
					) : (
						<h1 tabIndex={0}>{initials}</h1>
					)}
					{!photoUploaded ? (
						<span>
							<label htmlFor={`sUp_pd_thmL2i_${name}`} className="dn">
								{strings('patientSignUp.personalDetails.editProfilePicture')}
							</label>
							<label className="c_point" id={`sUp_pd_thmL2_${name}`}>
								<input
									type="file"
									name={name}
									className="imageUploaderBtn"
									accept="image/*"
									id={`sUp_pd_thmL2i_${name}`}
									onChange={e => photoHandler(e)}
									disabled={
										isViewOnlyMode === true
											? isViewOnlyMode
											: props.dynamicConstraints?.disabled ?? false
									}
								/>
								<span
									aria-hidden="true"
									style={uploadIconStyle}
									className="fIcon iconSize2 text-secondary icon-upload_new rightBottom"
									id={`sUp_pd_thmL2iIcon_${name}`}></span>
							</label>
						</span>
					) : (
						<span>
							<button
								id={`sUp_pd_thmL2i_btn_${name}`}
								className="btn btn-link"
								type="button"
								disabled={
									isViewOnlyMode === true
										? isViewOnlyMode
										: props.dynamicConstraints?.disabled ?? false
								}
								onClick={() => removePhotoHandler()}>
								{strings('patientSignUp.personalDetails.removePicture')}
							</button>
						</span>
					)}
				</div>
			) : (
				(visible != null ? visible : true) && (
					<div
						id={`div_om_sUp_pd_thm1_${name}`}
						className={
							className
								? `updateProfile mt20 form-item ${className}`
								: 'updateProfile mt20 form-item'
						}>
						<div
							id={`div_sUp_pd_thm1_${name}`}
							className="pp-boxOuter radius100 form-group">
							<div
								className="pp-box profile--pic--container"
								id={`sUp_pd_thm1_${name}`}
								style={{display: 'block'}}>
								{!showInitialsFlag ? (
									<img
										src={photo}
										alt=""
										id={`img_${name}`}
										className="img"
										width="100"
										height="100"
									/>
								) : (
									<h1 tabIndex={0}>{initials}</h1>
								)}
								{!photoUploaded ? (
									<span>
										<label htmlFor={`sUp_pd_thmL2i_${name}`} className="dn">
											{strings(
												'patientSignUp.personalDetails.editProfilePicture',
											)}
										</label>
										<label className="c_point" id={`sUp_pd_thmL2_${name}`}>
											<input
												type="file"
												name={name}
												className="imageUploaderBtn"
												accept="image/*"
												id={`sUp_pd_thmL2i_${name}`}
												onChange={e => photoHandler(e)}
												disabled={
													isViewOnlyMode === true
														? isViewOnlyMode
														: props.dynamicConstraints?.disabled ?? false
												}
											/>
											<span
												aria-hidden="true"
												style={uploadIconStyle}
												className="fIcon iconSize2 text-secondary icon-upload_new rightBottom"
												id={`sUp_pd_thmL2iIcon_${name}`}></span>
										</label>
									</span>
								) : (
									<span>
										<button
											id={`sUp_pd_thmL2i_btn_${name}`}
											className="btn btn-link"
											type="button"
											disabled={
												isViewOnlyMode === true
													? isViewOnlyMode
													: props.dynamicConstraints?.disabled ?? false
											}
											onClick={() => removePhotoHandler()}>
											{strings('patientSignUp.personalDetails.removePicture')}
										</button>
									</span>
								)}
							</div>
							{photoSizeExceedFlag ? (
								<span
									id={`usr_eUp_t_pic_upload_err_${name}`}
									className="errorText text-center">
									{strings('patientSignUp.personalDetails.imageTooLarge')}
								</span>
							) : (
								''
							)}
							{wrongFileTypeFlag ? (
								<span
									id={`usr_eUp_t_pic_upload_err_${name}`}
									className="errorText text-center">
									{strings('photoModule.wrongFileTypeSelected')}
								</span>
							) : (
								''
							)}
						</div>
					</div>
				)
			)}
		</>
	);
};

export default PhotoComponent;

// it couldn't be declared in separate component because we also perform validation on the basis of other props
PhotoComponent.propTypes = {
	// setPhoto : callback handler which need to be passed as a props for handling the component response
	setValue: PropTypes.func,

	// profilePicUploaded : this field is is required to identify where picture already uploaded or not
	picUploaded: PropTypes.bool,

	// base64PhotoString : field is required for storing the base64 image format
	base64PhotoString: PropTypes.string,

	//base64PhotoMimetype : field is required for storing the base64 image type
	base64PhotoMimetype: PropTypes.string,

	//showInitials : file is used for enabling initials View instead of defaultPhoto
	showInitials: PropTypes.bool,

	renderProfilePicture: PropTypes.bool,

	register: PropTypes.any,

	dynamicConstraints: PropTypes.any,

	isViewOnlyMode: PropTypes.bool,

	fieldData: PropTypes.any,

	configProvider: PropTypes.any,

	field: PropTypes.any,

	handleChange: PropTypes.any,

	//name : field is used to store username which is being used for finding the initials
	name: function (props: any, name: any) {
		if (props['showInitials'] && props[name]) {
			return new Error('please provide patientName');
		}
	},
};
