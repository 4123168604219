import {Observable, Subject} from 'rxjs';

const routeChangeStateSubject = new Subject<any>();
export const routeChangeDataStateObserver = {
	/**
	 * This is used to publish values to the subscribers.
	 * @param {any} routeChangeData - This observer lets system know when to sroute changes
	 */
	publish(routeChangeData: any) {
		return routeChangeStateSubject.next(routeChangeData);
	},

	/**
	 * This function is used to listen to events by subscribing to the routeChangeStateSubject.
	 */
	observable: (): Observable<any> =>
    routeChangeStateSubject.asObservable() as Observable<any>,
};
