import {PageButton} from './IPageButton';
import '../css/style.scss';
import {strings} from '../../../../localization/i18n';

function ActionButtons(props: any) {
	const {actionButtons}: {actionButtons: Array<PageButton>} = props;
	const name =
		actionButtons && actionButtons[0] ? actionButtons[0].name : 'abtn';
	return (
		<div
			id={`div_om_actionButtons_${name}`}
			className="page-title-right-action">
			<div
				id={`div_actionButtons_${name}`}
				className={props.className ? 'btnContainer ' + props.className : 'btnContainer'
				}>
				{actionButtons
					? actionButtons.map((pageButton: PageButton, i: any) =>
							pageButton.visible !== false ? (
								<button
									id={`actionButtons_btn_${pageButton?.name}`}
									key={i}
									disabled={pageButton.disabled ? pageButton.disabled : false}
									aria-label={
										pageButton['aria-label']
											? strings(pageButton['aria-label'])
											: ''
									}
									className={
										'btn btn-space ' +
										(pageButton.className
											? pageButton.isAction
												? 'btn-primary ' + pageButton.className
												: 'btn-secondary ' + pageButton.className
											: pageButton.isAction
											? 'btn-primary '
											: 'btn-secondary ')
									}
									type="button"
									name={strings(pageButton.name) ?? 'button'}
									onClick={
										pageButton.type === 'reset'
											? props.resetHandler
											: pageButton.type === 'submit'
											? props.submitHandler
											: pageButton.clickHandler
									}>
									{pageButton.icon && (
										<span
											aria-hidden={true}
											className={`${pageButton.icon}`}></span>
									)}
									{pageButton.type === 'icon' ? (
										<>
											{pageButton.icon}
											{strings(pageButton.name)}
										</>
									) : (
										strings(pageButton.name)
									)}
								</button>
							) : null,
					  )
					: null}
			</div>
		</div>
	);
}

export default ActionButtons;
