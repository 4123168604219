const FormNotification = (props: any) => {
	const {content, setFocus, name, type = 'error', dismissable = false} = props;

	const handleClick = () => {
		if (name) {
			try {
				setFocus(name);
			} catch (e) {
				/* this could happen if the element to focus is unmounted
				hence, no focusing is needed */
			}
		}
	};

	const notificationTypeMap = {
		error: 'alert-danger',
		warning: 'alert-warning',
		info: 'alert-primary',
	};

	return (
		<div
			id={`div_form_notif_${name}`}
			className={`alert ${
				notificationTypeMap[type as keyof typeof notificationTypeMap]
			} ${dismissable ? 'alert-dismissible' : ''}`}
			onClick={handleClick}>
			{dismissable && (
				<button
					className="btn-nostyles close"
					data-dismiss="alert"
					aria-label="close">
					&times;
				</button>
			)}
			{content}
		</div>
	);
};

export default FormNotification;
