import {useWatch} from 'react-hook-form';
import {getValidationReport} from '../../password/validations';
import {strings} from '../../../../../../../localization/i18n';

const PasswordValidity = (props: any) => {
	const {control, validationMap, name, passwordConstraints} = props;

	const pwd = useWatch({
		control: control,
		name: name,
	});

	const validationReport = getValidationReport(validationMap, pwd);

	const validationStrings: any = {
		minLength: strings('form.validation.minLength', {
			num: passwordConstraints.minLength,
		}),
		maxLength: strings('form.validation.maxLength', {
			num: passwordConstraints.maxLength,
		}),
		specialCharacters: strings('form.validation.specialCharacters'),
		numericalCharacters: strings('form.validation.numericalCharacters'),
		upperCaseCharacters: strings('form.validation.upperCaseCharacters'),
		lowerCaseCharacters: strings('form.validation.lowerCaseCharacters'),
		notBeginWithSpecialCharacter: strings(
			'form.validation.notBeginWithSpecialCharacter',
		),
		notBeginWithNumber: strings('form.validation.notBeginWithNumber'),
		notContainsSpace: strings('form.validation.notContainsSpace'),
	};

	return (
		<>
			<p id={`password_val_p_${name}`} className="validationHeading mt15">
				<strong>{strings('ui.comps.contains')}</strong>
			</p>
			<ul id={`password_val_ul_${name}`} className="dynamicPassword-checklist">
				{Object.keys(validationStrings).map(
					(validationString: string, key: number) =>
						passwordConstraints[validationString] ? (
							<li id={`password_val_li_${key}_${name}`} key={key}>
								<span aria-hidden={'true'}
									className={`${
										validationReport[validationString]
											? 'fIcon icon-ok1 text-success'
											: 'fIcon icon-cancel1 text-danger'
									}`}></span>

								<span>
										<span className={'sr-only'}>
											{`${
												validationReport[validationString]
													? strings('accessibility.pass') + ': '
													: strings('accessibility.failed') + ': '
												}`}
										</span>
										<span> {validationStrings[validationString]}</span>
								</span>
                        </li>
                    ) : null,
            )}
        </ul>
    </>
);
};

export default PasswordValidity;
