import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Loader from '../ui/comps/src/Loader';
import FormSandbox from '../sample/FormSandbox/FormSandbox';

const StyleGuideComponents = lazy(
	() => import('../ui/comps/src/scssCore/StyleGuideElements'),
);

const ExtraRoutes = (
	<>
		<Route
			path="styleguide"
			element={
				<Suspense fallback={<Loader />}>
					<StyleGuideComponents />
				</Suspense>
			}
		/>
		<Route
			path="formSandbox"
			element={
				<Suspense fallback={<Loader />}>
					<FormSandbox />
				</Suspense>
			}
		/>
	</>
);

export default ExtraRoutes;
