import {StorageHelper} from '../common/src/interface/storage/IStorageHelper';
import {isValueAvailable} from '../common/src/utils/StringUtils';

let reactStorage: Storage;

if (global.ConfigurationHolder?.storageType === 'localStorage') {
	reactStorage = localStorage;
} else {
	reactStorage = sessionStorage;
}

/**
 * This method is used to append prefix against the given key
 * @param {string} key to save the given value against
 * @returns {string}
 */
const getKeyName = (key: string): string => {
	return global.ConfigurationHolder?.addPrefixForLocalStorage
		? 'ls.' + key
		: key;
};

/**
 * This method is used to save a json object or string against the given key
 * @param {string} key to save the given value against
 * @param {string/object} value to save against the given key
 * @returns void
 */
const setItemSync = <T = any>(key: string, value: T): void => {
	switch (typeof value) {
		case 'object':
			reactStorage.setItem(getKeyName(key), JSON.stringify(value));
			break;
		default:
			reactStorage.setItem(getKeyName(key), String(value));
	}
};

/**
 * This method is used to fetch a stored json object or string against the given key
 * @param {string} key to fetch the given value against
 * @returns {Promise<any>} a promise which resolves with json object or string if exists or null
 */
const getItemSync = (key: string): any => {
	const data = reactStorage.getItem(getKeyName(key));
	var finalData: any = null;
	if (isValueAvailable(data)) {
		try {
			if (data) {
				finalData = JSON.parse(data);
				return finalData;
			}
		} catch (e) {
			if (data === 'true' || data === 'false') {
				finalData = Boolean(data);
			} else if (!isNaN(Number(data))) {
				finalData = Number(data);
			} else {
				finalData = data;
			}
			return finalData;
		}
	} else {
		return finalData;
	}
};

/**
 * This method is used to save a json object or string against the given key
 * @param {string} key to save the given value against
 * @param {string/object} value to save against the given key
 * @returns {Promise<void>}
 */
const setItem = <T = any>(key: string, value: T): Promise<void> => {
	return new Promise<void>(resolve => {
		switch (typeof value) {
			case 'object':
				reactStorage.setItem(getKeyName(key), JSON.stringify(value));
				resolve();
				break;
			default:
				reactStorage.setItem(getKeyName(key), String(value));
				resolve();
		}
	});
};

/**
 * This method is used to fetch a stored json object or string against the given key
 * @param {string} key to fetch the given value against
 * @returns {Promise<any>} a promise which resolves with json object or string if exists or null
 */
const getItem = <T = any>(key: string): Promise<T | null> => {
	return new Promise(resolve => {
		const data = reactStorage.getItem(getKeyName(key));
		var finalData: any;
		if (isValueAvailable(data)) {
			try {
				if (data) {
					finalData = JSON.parse(data);
					resolve(finalData);
				}
			} catch (e) {
				if (data === 'true' || data === 'false') {
					finalData = Boolean(data);
				} else if (!isNaN(Number(data))) {
					finalData = Number(data);
				} else {
					finalData = data;
				}
				resolve(finalData);
			}
		} else {
			resolve(null);
		}
	});
};

/**
 * This method is used to remove a stored value against the given key
 * @param {string} key to remove the given value against
 * @returns {Promise<void>}
 */
const removeItem = (key: string): Promise<void> => {
	return new Promise<void>(resolve => {
		reactStorage.removeItem(getKeyName(key));
		resolve();
	});
};

/**
 * This method is used to remove all stored key/value pairs from the storage
 * @param {string} key to remove the given value against
 * @returns {void}
 */
export const clearStorage = (): void => {
	reactStorage.clear();
};

const storageHelperInstance: StorageHelper = {
	setItem: setItem,
	getItem: getItem,
	removeItem: removeItem,
	getKeyName: getKeyName,
	clearStorage: clearStorage,
	getItemSync: getItemSync,
	setItemSync: setItemSync,
};

export default storageHelperInstance;
