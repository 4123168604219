import BaseResponseModel from '../../../../network/interface/BaseResponseModel';

class ReadNotificationResponseModel implements BaseResponseModel {
	private readonly errors?: ReadNotificationError[];
	private readonly status?: boolean;
	private readonly message?: string;
	constructor({errors, status, message}: ReadNotificationResponseData) {
		this.errors = errors;
		this.status = status;
		this.message = message;
	}

	/**
	 * return response data for mas notification as read API
	 * @returns {ReadNotificationResponseData}
	 */
	getResponseDataObject(): ReadNotificationResponseData {
		return {
			errors: this.errors,
			status: this.status,
			message: this.message,
		};
	}
}

export type ReadNotificationResponseData = {
	errors?: ReadNotificationError[];
	status?: boolean;
	message?: string;
};

type ReadNotificationError = {
	status?: boolean;
	message?: string;
	code?: string;
	responseStatus?: number;
};

export default ReadNotificationResponseModel;
