import {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import PhotoComponent from './photo.component';

export default function PhotoController(props: any) {
	const {fieldData, dynamicConstraints, register, configProvider, control, isViewOnlyMode} =
		props;
	const {customProps, constraints, name} = fieldData;
	const [imageConfig, setImageConfig] = useState<Record<string, any>>(
		customProps.imageConfig,
	);

	useEffect(() => {
		register(fieldData.name, {
			required: {
				value: dynamicConstraints?.required ?? false,
				message: constraints?.required?.message ?? '',
			},
			shouldUnregister: true,
		});
	}, []);

	// sets a photo or changes previous photo input
	function handleSetPhoto(photoData: any, field: any) {
		const res = {
			profilePicUploaded: photoData.photoUpdated,
			patientProfile: {
				photo: [
					{
						base64Photo: photoData.base64PhotoString,
						base64PhotoMimetype: photoData.base64PhotoMimetype,
					},
				],
			},
		};
		field.onChange(res);
		setImageConfig(() => res);
	}

	return (
		<Controller
			control={control}
			name={name}
			rules={{
				required: {
					value: dynamicConstraints?.required ?? false,
					message: constraints?.required?.message ?? '',
				},
				shouldUnregister: true,
			}}
			
			render={({field}) => (
				<PhotoComponent
					showInitials={customProps?.showInitials}
					picUploaded={imageConfig?.profilePicUploaded}
					base64PhotoString={imageConfig?.patientProfile?.photo[0]?.base64Photo}
					base64PhotoMimetype={
						imageConfig?.patientProfile?.photo[0]?.base64PhotoMimetype
					}
					fieldData={fieldData}
					dynamicConstraints={dynamicConstraints}
					isViewOnlyMode={isViewOnlyMode}
					register={register}
					configProvider={configProvider}
					handleChange={(data: any) => handleSetPhoto(data, field)}
				/>
			)}
		/>
	);
}
