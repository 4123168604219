import React from 'react';
import logEvent from '../../utils/analytics/logEvent';
import getBrowserNameAndVersion from './helper';

class ErrorBoundary extends React.Component<{}, {hasError: boolean}> {
	constructor(props: any) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return {hasError: true};
	}

	componentDidCatch(error: any, errorInfo: any) {
		// Log the error to an error reporting service
		// console.log(error, JSON.stringify(errorInfo));

		let browserNameAndVersion = 'unknown';
		try {
			browserNameAndVersion = getBrowserNameAndVersion();
		} catch (e) {
			/* pass */
		}

		logEvent('crash_stacktrace', {
			errorType: error,
			errorInfo: JSON.stringify(errorInfo),
			browserNameAndVersion: browserNameAndVersion,
			platform: navigator?.platform ?? 'unknown',
		});
	}

	render(): JSX.Element {
		if (this.state.hasError) {
			return (
				<>
					<p>
						Whoops, Something went wrong. Please return to the sign-in page.
					</p>
				</>
			);
		}

		return <>{this.props.children}</>;
	}
}

export default ErrorBoundary;
