class SendToIHERepositoryValidationModel {
    constructor(
        private _destinationListError: string = '',
        private _idError: string = '',
        private _isError: boolean = false
    ) {}
    set destinationListError(value: string) {
        this._destinationListError = value
    }
    get destinationListError(): string {
        return this._destinationListError
    }
    set idError(value: string) {
        this._idError = value
    }
    get idError(): string {
        return this._idError
    }
    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}
export default SendToIHERepositoryValidationModel