import {strings} from '../../../localization/i18n';

const RemoveConsent = (props: any) => {
	return (
		<>
			{
				<div id="removeConsent_redirectModal_body">
					<p className="" id="removeConsent_redirectModal_bodyText1">
						{strings('removeConsent.redirect.ConfirmBoxMessage1')}
					</p>
					<p className="mt15" id="removeConsent_redirectModal_bodyText2">
						{strings('removeConsent.redirect.ConfirmBoxMessage2')}
					</p>
					<p className="mt15" id="removeConsent_redirectModal_bodyText3">
						{strings('removeConsent.redirect.ConfirmBoxMessage3')}
					</p>
				</div>
			}
		</>
	);
};
export default RemoveConsent;
