import { Service } from 'typedi';

import APIExecutor, { APIRequest } from '../../../../network/APIExecutor';
import { APIUrl } from '../../../../network/values/URLInfo';
import UserDetailsRequestModel from '../../models/UserDetailsRequestModel';
import RegisterDeviceRequestModel from '../../models/RegisterDeviceRequestModel';
import RenderConfigWithoutLoginRequestModel from '../../models/RenderConfigWithoutLoginRequestModel';
import LogoutRequestModel from '../../models/LogoutRequestModel';
import { selectedLanguage } from '../../../../../../../localization/i18n';
import { UserRenderConfigRequestData } from '../../models/UserRenderConfigRequestModel';
import { UserSettingsModel } from '../../models/UserModel';

@Service()
class UserNetworkRepository {
	/**
	 * This method is used to make API call for moUser/getUserDetails
	 * @param {UserDetailsRequestModel} userDetailsRequestModel request model for getUserDetails API
	 * @return {Record<string, any>} in format of {data, headers}
	 */
	getUserDetails(
		userDetailsRequestModel?: UserDetailsRequestModel,
		requestHeader?: Record<string, any>,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.GET_USERDETAILS,
				userDetailsRequestModel?.getRequestDataObject() ?? {},
				undefined,
				requestHeader,
			),
		);
	}

	/**
	 * This method is used to make API call for moMobileApp/registerDevice
	 * This API is only used by mobile devices to register themselves as the active device
	 * @param {RegisterDeviceRequestModel} registerDeviceRequestModel request model for registerDevice API
	 * @return {Record<string, any>} in format of {data, headers}
	 */
	moRegisterDevice(
		registerDeviceRequestModel?: RegisterDeviceRequestModel,
		header?: Record<string, any>,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.MO_MOBILE_REGISTER_DEVICE,
				registerDeviceRequestModel?.getRequestDataObject() ?? {},
				undefined,
				header,
			),
		);
	}

	/**
	 * This method is used to make API call for user/renderWithoutLoginConfigList
	 * @param {RenderConfigWithoutLoginRequestModel} renderConfigWithoutLoginRequestModel request model for renderWithoutLoginConfigList API
	 * @return {Record<string, any>} in format of {data, headers}
	 */
	renderConfigWithoutLogin(
		renderConfigWithoutLoginRequestModel?: RenderConfigWithoutLoginRequestModel,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.RENDER_CONFIG_WITHOUT_LOGIN,
				renderConfigWithoutLoginRequestModel?.getRequestDataObject() ?? {},
			),
		);
	}

	/**
	 *
	 * @param requestData req model for fetchRenderConfigList
	 * @returns {Record<string, any>} in format of {data, headers}
	 */
	fetchRenderConfigList(
		requestData: UserRenderConfigRequestData,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.USER_RENDERCONFIG_LIST + '?_language=' + selectedLanguage(),
				requestData,
			),
		);
	}

	/**
	 * This method is used to make API call for api/logout
	 * @param {LogoutRequestModel} logoutRequestModel request model for logout API
	 * @return {Record<string, any>} in format of {data, headers}
	 */
	logout(
		logoutUrl: string,
		logoutRequestModel?: LogoutRequestModel,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				logoutUrl,
				logoutRequestModel?.getRequestDataObject() ?? {},
			),
		);
	}

	/**
	 * This method is used to fetch users from the server
	 * @param searchCriteria : searchCriteria for searching users from the server
	 * @returns the final list of users
	 */
	public search(
		searchCriteria: Record<string, any>,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.FETCH_USER + '?_language=' + selectedLanguage(),
				searchCriteria,
			),
		);
	}

	/**
	 * This method is used to make API call for deactivate user
	 * @param {Record<string, any>} requestObj request for deactivate user
	 * @return {Promise<Record<string, any>>} in format of {data, headers}
	 */
	deactivateUser(
		requestObj: Record<string, any>,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(APIUrl.MOUSER_DEACTIVATE_USER, requestObj ?? {}),
		);
	}

	/**
	 * This method is used to make API call for remove consent from idx
	 * @param {string} source request for remove consent from idx
	 * @return {Promise<Record<string, any>>} in format of {data, headers}
	 */
	removeConsentFromIdx(source: string): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(APIUrl.REVOKE_CONSENT_IDX + '?source=' + source, {}),
		);
	}

	/**
	 * 
	 * @param urlAction it is the api coming from backend based on action edit/changePassword/ update dvs etc. for oauth2 providers
	 * @returns {Promise<Record<string, any>>} in format of {data, headers}
	 */
	updateProfileViaExternalProvider(urlAction: string) {
		return APIExecutor.postHTTP(new APIRequest(urlAction, {}))
	}

	/**
	 * 
	 * @param userSettings userSettingsModel for userSettings
	 * @returns Promise for returning updated user settings 
	 */
	updateUserSettings(
		userSettings: UserSettingsModel,
		customHeaders?: Record<string, any>,
	): Promise<Record<string, any>> {
		return APIExecutor.postHTTP(
			new APIRequest(
				APIUrl.UPDATE_USER_SETTINGS,
				userSettings,
				undefined,
				customHeaders ?? {},
			),
		);
	}
}

export default UserNetworkRepository;
