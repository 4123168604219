import Axios, { AxiosResponse, Canceler, ResponseType } from 'axios';
import NetworkRequestMethodEnum from '../../enums/NetworkRequestMethodEnum';
import { getRequestHeaders } from './header/NetworkRequestHeader';
import { isValueAvailable } from '../../utils/StringUtils';
import { Deferred, generateDeferredPromise } from './DeferredPromise';
import { sessionStorageKeys } from '../../interface/storage/constants/SessionKeys';
import { APIHeaderKeys } from './values/HeaderConstants';
import encryptedStorageHelperInstance from '../../../../storage/EncryptedStorageHelper';
import { APIUrl } from './values/URLInfo';
import { parseResponseHeaders } from './header/NetworkResponseHeader';
import GlobalObserversInstance from '../../utils/observers/GlobalObservers';
import ErrorModel, { ErrorType } from './model/ErrorModel';
import { strings } from '../../../../localization/i18n';
import NetworkHeaderProvider from '../../../../utils/network/NetworkHeaderProvider';
import { getBrowserTimezone } from '../../utils/DateTimeUtils';

const SOMETHING_WENT_WRONG = strings('errors.somethingWentWrong');
const INTERNET_NOT_CONNECTED = strings('errors.internetNotConnected');
const INTERNET_NOT_REACHABLE = strings('errors.internetNotReachable');
const SERVER_NOT_REACHABLE = strings('errors.serverNotReachable');
const INVALID_LOGIN_DETAILS = strings('errors.invalidLoginDetails');
const CancelToken = Axios.CancelToken;

/**
 * TODO collective doc after request/response/refreshtoken
 */
export class APIRequest {
	constructor(
		readonly url: string,
		readonly body: Record<string, any>,
		readonly responseType?: ResponseType,
		readonly headers: Record<string, any> = {},
		readonly params: Record<string, string | number | null | undefined> = {},
		readonly method: NetworkRequestMethodEnum = NetworkRequestMethodEnum.POST,
		readonly baseUrl: string = global.ConfigurationHolder?.urls
			?.applicationServer,
	) { }
}

class APIExecutor {
	private static refreshTokenAPIPromise?: Promise<Record<string, any>> =
		undefined;

	/**
	 * this method is used to execute a POST request
	 * @param request provides details for the API to be executed
	 */
	public static postHTTP<T = Record<string, any>>(
		request: APIRequest,
		eventObj?: Record<string, any>,
	): Promise<{
		data: T;
		status: number;
		headers: Record<string, any>;
	}> {
		if (request.responseType) {
			return this.executeRequest(request, (headers, completeURL) => {
				return Axios.post(completeURL, request.body, {
					headers: headers,
					responseType: request.responseType,
					onUploadProgress: eventObj?.onUploadProgress,
					cancelToken: new CancelToken((c: Canceler) => {
						if (eventObj?.cancelRef) {
							eventObj.cancelRef.current = c;
						}
					}),
				});
			});
		} else {
			return this.executeRequest(request, (headers, completeURL) => {
				return Axios.post(completeURL, request.body, {
					headers: headers,
					onUploadProgress: eventObj?.onUploadProgress,
					cancelToken: new CancelToken((c: Canceler) => {
						if (eventObj?.cancelRef) {
							eventObj.cancelRef.current = c;
						}
					}),
				});
			});
		}
	}

	/**
	 * this method is used to execute a PUT request
	 * @param request provides details for the API to be executed
	 */
	public static putHTTP<T = Record<string, any>>(
		request: APIRequest,
	): Promise<{
		data: T;
		status: number;
		headers: Record<string, any>;
	}> {
		if (request.responseType) {
			return this.executeRequest(request, (headers, completeURL) => {
				return Axios.put(completeURL, request.body, {
					headers: headers,
					responseType: request.responseType,
				});
			});
		} else {
			return this.executeRequest(request, (headers, completeURL) => {
				return Axios.put(completeURL, request.body, { headers: headers });
			});
		}
	}

	/**
	 * this method is used to execute a GET request
	 * @param request provides details for the API to be executed
	 */
	public static getHTTP<T = Record<string, any>>(
		request: APIRequest,
	): Promise<{
		data: T;
		status: number;
		headers: Record<string, any>;
	}> {
		if (request.responseType) {
			return this.executeRequest(request, (headers, completeURL) => {
				return Axios.get(completeURL, {
					headers: headers,
					responseType: request.responseType,
				});
			});
		} else {
			return this.executeRequest(request, (headers, completeURL) => {
				return Axios.get(completeURL, { headers: headers });
			});
		}
	}

	/**
	 * this method is used to execute a DELETE request
	 * @param request provides details for the API to be executed
	 */
	public static deleteHTTP<T = Record<string, any>>(
		request: APIRequest,
	): Promise<{
		data: T;
		status: number;
		headers: Record<string, any>;
	}> {
		return this.executeRequest(request, (headers, completeURL) => {
			return Axios.delete(completeURL, {
				headers: headers,
				params: request.params,
			});
		});
	}

	/**
	 * Execute all type of request methods with the help of request executor,
	 * takes care of request headers, response and error parsing.
	 * This method calls refresh token API when EXPIRES_ON is set to Config.refreshTokenTimeout - 1 seconds from now
	 * All API requests are put on hold until new token is received from refresh token API,
	 * following which new token is used to authenticate for further APi calls
	 * @param request
	 * @param requestExecutor
	 * @private
	 */
	private static executeRequest<T = Record<string, any>>(
		request: APIRequest,
		requestExecutor: (
			headers: Record<string, string>,
			completeURL: string,
		) => Promise<AxiosResponse<any, any>>,
	): Promise<{
		data: T;
		status: number;
		headers: Record<string, any>;
	}> {
		const deferredPromise: Deferred<{
			data: T;
			status: number;
			headers: Record<string, any>;
		}> = generateDeferredPromise<{
			data: T;
			status: number;
			headers: Record<string, any>;
		}>();
		this.shouldBeConnected()
			.then(_ => {
				this.getHeaders(request).then(headers => {
					encryptedStorageHelperInstance
						.getItem<number>(sessionStorageKeys.EXPIRES_ON)
						.then(tokenExpiry => {
							new Promise<boolean>((resolve, reject) => {
								encryptedStorageHelperInstance
									.getItem(sessionStorageKeys.SIGNUP_SOURCE)
									.then(response => {
										if (response) {
											if (
												tokenExpiry != null &&
												global.ConfigurationHolder?.oauth2Providers?.indexOf(
													response.toUpperCase(),
												) != -1 &&
												headers[APIHeaderKeys.X_AUTH_TOKEN] != null &&
												tokenExpiry -
												global.ConfigurationHolder?.refreshTokenTimeout *
												1000 <
												new Date().getTime()
											) {
												this.executeRefreshTokenAPI()
													.then(_responseData => {
														// successfully updated token
														APIExecutor.refreshTokenAPIPromise = undefined;
														resolve(true);
													})
													.catch(error => {
														reject(error);
													});
											} else {
												resolve(true);
											}
										} else {
											resolve(true);
										}
									});
							})
								.then((isToCallAPI: boolean) => {
									isToCallAPI &&
										requestExecutor(
											headers,
											this.getCompleteMinervaUrl(request.baseUrl, request.url),
										)
											.then(response => {
												parseResponseHeaders(response.headers).then(() => {
													deferredPromise.resolve(response as any);
												});
											})
											.catch(error => {
												deferredPromise.reject(
													this.handleGenericErrorCodes(
														error.response,
														request.url,
													),
												);
											});
								})
								.catch(error => {
									deferredPromise.reject(this.getDefaultErrorModel(error));
								});
						});
				});
			})
			.catch((error: ErrorModel) => {
				deferredPromise.reject(error);
			});
		return deferredPromise.promise;
	}

	/**
	 * This function checks the APIs response status code and publish the values to the relevant subscriber
	 * for the graceful handling.
	 * 401 - Publish the boolean value to the logoutObserver subscribers because user must be logged out in case of 401
	 * 403,404 - Publish the status code as value to the permissionDeniedObserver subscribers as logged in user doesn't have permission to access the API
	 * 502,503 - Publish the status code as value to the serverDownObserver subscribers to display the maintenance screen.
	 * @param {Record<string, any>} response
	 * @param {string} urlEndPoint - This is to check identify the URL and fill error model accordingly
	 * @return {ErrorModel} - Returns the generic error model with status code and localized generic error message
	 * @private
	 */
	private static handleGenericErrorCodes(
		response: Record<string, any>,
		urlEndPoint: string,
	): ErrorModel {
		const { status } = response || {};
		switch (status) {
			case 401:
				if (this.isToSendLogoutEvent(urlEndPoint))
					GlobalObserversInstance.logoutObserver.publish(true);
				return this.getErrorModelForUnAuthorizedStatus(urlEndPoint, response);
			case 403:
			case 404:
				GlobalObserversInstance.permissionDeniedObserver.publish(status);
				break;

			case 502:
			case 503:
				GlobalObserversInstance.serverDownObserver.publish(status);
				break;
			case undefined:
				return new ErrorModel(
					undefined,
					SERVER_NOT_REACHABLE,
					undefined,
					ErrorType.CONNECTION_ERROR,
				);
		}

		return new ErrorModel(status, SOMETHING_WENT_WRONG, response);
	}

	/**
	 * This function will return the default generic error code and message if any unexpected error occurred.
	 */
	public static getDefaultErrorModel(error?: any): ErrorModel {
		return new ErrorModel(
			999,
			SOMETHING_WENT_WRONG,
			error,
			ErrorType.SOMETHING_WENT_WRONG,
		);
	}

	/**
	 *
	 * @param urlEndPoint
	 * @private
	 */
	private static isToSendLogoutEvent(urlEndPoint: string): boolean {
		if (
			urlEndPoint === APIUrl.API_LOGIN ||
			urlEndPoint === APIUrl.MO_USER_RETRIEVE_USERNAME
		) {
			return false;
		}
		return true;
	}

	private static getErrorModelForUnAuthorizedStatus(
		urlEndPoint: string,
		response: Record<string, any>,
	): ErrorModel {
		let errorMessage = SOMETHING_WENT_WRONG;
		if (urlEndPoint === APIUrl.API_LOGIN) {
			errorMessage = INVALID_LOGIN_DETAILS;
		}

		return new ErrorModel(401, errorMessage, response);
	}

	/**
	 * Provide default headers in case not explicitly defined
	 * @private
	 * @param request
	 */
	private static getHeaders(
		request: APIRequest,
	): Promise<Record<string, string>> {
		return new Promise<Record<string, string>>(resolve => {
			if (!isValueAvailable(request.headers)) {
				getRequestHeaders(request.url).then(headers => resolve(headers));
			} else {
				request.headers['browser-timezone'] = getBrowserTimezone();
				resolve(request.headers);
			}
		});
	}

	/**
	 * Check the current internet connection. Reject the promise if internet is not connected or not reachable.
	 * Otherwise, resolve promise
	 * @private
	 */
	private static shouldBeConnected(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			new NetworkHeaderProvider()
				.getConnectionStatus()
				.then(status => {
					if (status.isConnected === false) {
						reject(
							new ErrorModel(
								undefined,
								INTERNET_NOT_CONNECTED,
								undefined,
								ErrorType.CONNECTION_ERROR,
							),
						);
					} else if (status.isInternetReachable === false) {
						reject(
							new ErrorModel(
								undefined,
								INTERNET_NOT_REACHABLE,
								undefined,
								ErrorType.CONNECTION_ERROR,
							),
						);
					} else {
						resolve(true);
					}
				})
				.catch(_error => {
					resolve(false);
				});
		});
	}

	/**
	 * This method is used to get complete URL for API executor
	 * @param baseUrl which identifies the server
	 * @param url postfix for the URL to identify the controller and action
	 * @private
	 */
	private static getCompleteMinervaUrl(baseUrl: string, url: string): string {
		if (url.startsWith('http')) {
			return url;
		}
		return `${baseUrl}/minerva/${url}`;
	}

	/**
	 * This method is used to refresh auth token for user before the current token expires
	 * @private
	 */
	private static executeRefreshTokenAPI(): Promise<Record<string, any>> {
		if (APIExecutor.refreshTokenAPIPromise != null) {
			return APIExecutor.refreshTokenAPIPromise;
		}
		const deferredPromise: Deferred<Record<string, any>> =
			generateDeferredPromise<Record<string, any>>();
		const refreshTokenRequest = new APIRequest(
			APIUrl.MO_REFRESH_TOKEN_REFRESH_TOKEN_FROM_EXTERNAL_PROVIDER,
			{},
		);
		this.getHeaders(refreshTokenRequest).then(headers => {
			Axios.post(
				APIExecutor.getCompleteMinervaUrl(
					refreshTokenRequest.baseUrl,
					refreshTokenRequest.url,
				),
				refreshTokenRequest.body,
				{
					headers: headers,
				},
			)
				.then(response => {
					Promise.all([
						encryptedStorageHelperInstance.setItem(
							sessionStorageKeys.AUTH_TOKEN,
							response.data.accessToken,
						),
						encryptedStorageHelperInstance.setItem(
							sessionStorageKeys.EXPIRES_ON,
							new Date().getTime() + response.data.expiresIn * 1000,
						),
					])
						.then(() => {
							console.log(
								'x-auth-token and expires_in updated in sessionStorage',
							);
							deferredPromise.resolve(response.data);
						})
						.catch(error => {
							deferredPromise.reject(error);
						});
				})
				.catch(error => {
					GlobalObserversInstance.logoutObserver.publish(true);
					deferredPromise.reject(error);
				});
		});
		APIExecutor.refreshTokenAPIPromise = deferredPromise.promise;
		return APIExecutor.refreshTokenAPIPromise;
	}
}

export default APIExecutor;
