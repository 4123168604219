import {Observable, Subject, Subscriber} from 'rxjs';
import {Container, Service} from 'typedi';
import encryptedStorageHelperInstance from '../../../../../../storage/EncryptedStorageHelper';
import storageHelperInstance from '../../../../../../storage/StorageHelper';
import {sessionStorageKeys} from '../../../../interface/storage/constants/SessionKeys';
import UserDetailsRequestModel from '../models/UserDetailsRequestModel';
import {
	AgreementsModel,
	MenuItemsModel,
	UserModel,
	UserSettingsModel,
} from '../models/UserModel';
import UserNetworkRepository from '../network/repository/UserNetworkRepository';
import RenderConfigWithoutLoginRequestModel from '../models/RenderConfigWithoutLoginRequestModel';
import RegisterDeviceRequestModel from '../models/RegisterDeviceRequestModel';
import {RegisterDeviceResponse} from '../models/RegisterDeviceResponse';
import RoutePathInfo from '../../../../enums/RoutingPath';
import GlobalObserversInstance from '../../../../utils/observers/GlobalObservers';
import LogoutRequestModel from '../models/LogoutRequestModel';
import {
	selectedLanguage,
	strings,
	changeLanguage,
} from '../../../../../../localization/i18n';
import Agreement from '../../agreements/models/Agreement';
import VitalNetworkRespository from '../../vitals/networkRepository/VitalNetworkRespository';
import {APIHeaderKeys} from '../../../network/values/HeaderConstants';
import {getApiInfo} from '../../../network/header/NetworkRequestHeader';
import {APIUrl} from '../../../network/values/URLInfo';
import NetworkHeaderProvider from '../../../../../../utils/network/NetworkHeaderProvider';
import {LoaderType} from '../../generic/models/Loader';
import BasicSubscribersViewModel from '../../generic/viewModel/BasicSubscribers.viewmodel';
import {UserNavigationEvents} from '../enums/UserNavigationEvents';
import {
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../generic/models/NotificationMessage';
import DashboardNetworkRepository from '../../dashboard/networdRepository/DashboardNetworkRepository';
import {
	Deferred,
	generateDeferredPromise,
} from '../../../network/DeferredPromise';
import WebsocketService from '../../../../../../utils/websocket';
import {getInLocalStorage} from '../../../../utils/CommonUtils';
import {setConvertDateAccordingToTimeZone} from '../../../../utils/DateTimeUtils';

export const configWithLoginWSPromise: Deferred<any> =
	generateDeferredPromise<any>();

@Service()
class UserService extends BasicSubscribersViewModel<
	UserNavigationEvents,
	Record<string, any>
> {
	private userDetails?: UserModel;
	private defaultMenu?: MenuItemsModel;
	private userNetworkRepository: UserNetworkRepository;
	private vitalNetworkRepository: VitalNetworkRespository;
	private dashboardNetworkRepository: DashboardNetworkRepository;
	private readonly serverConfigSubject: Subject<Record<string, any>>;
	private websocketService: WebsocketService;

	constructor() {
		super();
		this.userNetworkRepository = Container.get(UserNetworkRepository);
		this.vitalNetworkRepository = Container.get(VitalNetworkRespository);
		this.dashboardNetworkRepository = Container.get(DashboardNetworkRepository);
		this.serverConfigSubject = new Subject<Record<string, any>>();
		this.websocketService = Container.get(WebsocketService);
	}

	/**
	 *
	 * @returns default menuItem
	 */
	public getDefaultMenu(): string {
		return this.defaultMenu
			? this.defaultMenu.path
			: RoutePathInfo.defaultLandingPage.path;
	}

	/**
	 * This method is used to get the observer where server config details are posted
	 * @returns {Observable<Record<string, any>>}
	 */
	fetchServerConfigObservable(): Observable<Record<string, any>> {
		return this.serverConfigSubject.asObservable();
	}

	/**
	 *
	 * @param token - token value to be stored in storage
	 * @param isTemp - if it needs to be temporary, then set true
	 * @returns - promise after setting
	 */
	setAuthToken(token: string, isTemp?: boolean) {
		if (isTemp) {
			encryptedStorageHelperInstance.setItem(
				sessionStorageKeys.TEMP_AUTH_TOKEN,
				token,
			);
		} else {
			encryptedStorageHelperInstance.setItem(
				sessionStorageKeys.AUTH_TOKEN,
				token,
			);
		}
	}

	/**
	 *
	 * @param isTemp -if needs to fetch temporary, then set true
	 */
	getAuthToken(isTemp?: boolean): Promise<string> {
		if (isTemp) {
			return encryptedStorageHelperInstance
				.getItem(sessionStorageKeys.TEMP_AUTH_TOKEN)
				.then(response => {
					return response;
				});
		} else {
			return encryptedStorageHelperInstance
				.getItem(sessionStorageKeys.AUTH_TOKEN)
				.then(response => {
					return response;
				});
		}
	}

	/**
	 * for fetching the configList after login
	 */
	getPostLoginConfig() {
		this.userNetworkRepository
			.fetchRenderConfigList({lang: selectedLanguage()})
			.then(({data, headers, status}) => {
				encryptedStorageHelperInstance.setItem(
					(global as any).platform === 'web'
						? sessionStorageKeys.SERVER_CONFIG
						: sessionStorageKeys.POST_LOGIN_SERVER_CONFIG,
					data,
				);
				setConvertDateAccordingToTimeZone(data.convertDateAccordingToTimeZone);
				this.serverConfigSubject.next(data);
			});
	}

	/**
	 * for fetching dashboards
	 */
	getUserDashboards() {
		this.dashboardNetworkRepository
			.getUserDashboards()
			.then(({data, headers, status}) => {
				encryptedStorageHelperInstance.setItem(
					sessionStorageKeys.DASHBOARDS,
					data,
				);
			});
	}

	/**
	 *
	 * @param user it takes user as a input for creating full name using the user details
	 * @returns for creating full name using the user details
	 */
	findUserRealName(user: Record<string, any>) {
		const nameList = [];
		if (user.firstName) {
			nameList.push(user.firstName);
		}
		if (user.lastName && user.lastName != 'null') {
			nameList.push(user.lastName);
		}
		const name = nameList.join(' ');

		return name;
	}

	/**
	 * This method is used to parse user details model and get menu items for navigation/
	 * @param {UserModel | undefined} data - user details like default menu items etc
	 * @returns {Array<MenuItemsModel> | undefined} menu items required for the logged-in user
	 */
	private populateMenuItems(
		data?: UserModel,
	): Array<MenuItemsModel> | undefined {
		const menuItems: Array<MenuItemsModel> | undefined = data?.menuItems;
		if (menuItems && menuItems.length > 0) {
			menuItems.map((menuItem: MenuItemsModel) => {
				if (
					menuItem.name === 'My Health' ||
					menuItem.name === 'Linked Patient' ||
					menuItem.name === 'Patients'
				) {
					menuItem.subMenu = data?.patientBannerMenuItems;
				}
			});
		}
		return menuItems;
	}

	/**
	 * This method is used to get the default menu item from menu item list
	 * @param {Array<MenuItemsModel> | undefined} menuItemList - array fon menu items of user
	 * @returns {MenuItemsModel | undefined} default menu item , which will render on the login
	 */
	private selectDefaultMenuItems(
		menuItemList?: Array<MenuItemsModel>,
	): MenuItemsModel | undefined {
		let defaultMenuItem;
		menuItemList?.map((menuItem: MenuItemsModel) => {
			if (menuItem.isDefault == true) {
				defaultMenuItem = menuItem;
			}
		});
		return defaultMenuItem;
	}

	/**
	 * creates header for API request with temp token
	 * to Be used only with APIs needed to be called before complete login
	 * @param apiUrl
	 * @param tempToken
	 * @returns {Record<string, any}
	 */
	getHeaderWithTempToken = (
		apiUrl: string,
		tempToken: string,
	): Record<string, any> => {
		const header: Record<string, any> = {};
		header[APIHeaderKeys.X_AUTH_TOKEN] = tempToken;
		header[APIHeaderKeys.API_INFO] = getApiInfo(
			apiUrl,
			new NetworkHeaderProvider(),
		);
		return header;
	};

	/**
	 * This method is used to fetch user details for the logged-in user
	 * @param {UserDetailsRequestModel} userDetailsRequestModel request model for getUserDetails API
	 * @param {boolean} getUserDetailsFromCache denotes whether fresh API call has to be made
	 * or user details can be fetched from cache, defaults to false
	 * @return {Observable<UserModel>}
	 */
	public getUserDetails(
		getUserDetailsFromCache = false,
		userDetailsRequestModel?: UserDetailsRequestModel,
	): Observable<UserModel | null | undefined> {
		return new Observable(
			(subscriber: Subscriber<UserModel | null | undefined>) => {
				if (getUserDetailsFromCache) {
					if (this.userDetails != null) {
						subscriber.next(this.userDetails);
						subscriber.complete();
					} else {
						encryptedStorageHelperInstance
							.getItem(sessionStorageKeys.USER)
							.then(value => {
								this.userDetails = value;
								subscriber.next(value);
							})
							.catch(error => {
								subscriber.error(error);
							})
							.finally(() => {
								subscriber.complete();
							});
					}
				} else {
					let headers: Record<string, any> | null = null;
					encryptedStorageHelperInstance
						.getItem(sessionStorageKeys.TEMP_AUTH_TOKEN)
						.then((tempToken: string) => {
							if (tempToken) {
								headers = {};
								headers = this.getHeaderWithTempToken(
									APIUrl.GET_USERDETAILS,
									tempToken,
								);
							}
							this.userNetworkRepository
								.getUserDetails(
									userDetailsRequestModel,
									headers ? headers : undefined,
								)
								.then(({data}: Record<string, any>) => {
									this.userDetails = data;
									data.permissions = this.fetchAllPermissions(data.authorities);
									GlobalObserversInstance.userContextObserver.publish(data);
									encryptedStorageHelperInstance
										.setItem(sessionStorageKeys.USER_ROLES, data.permissions)
										.catch(error => {
											subscriber.error(error);
										});
									encryptedStorageHelperInstance
										.setItem(sessionStorageKeys.USER, data)
										.catch(error => {
											subscriber.error(error);
										});
									encryptedStorageHelperInstance.setItem(
										sessionStorageKeys.MENU_ITEMS,
										this.populateMenuItems(data),
									);
									encryptedStorageHelperInstance.setItem(
										sessionStorageKeys.DEFAULT_PATIENT_BANNER_MENUITEM,
										this.selectDefaultMenuItems(data.patientBannerMenuItems),
									);
									this.defaultMenu = this.selectDefaultMenuItems(
										data.menuItems,
									);
									encryptedStorageHelperInstance.setItem(
										sessionStorageKeys.DEFAULT_MENU_ITEMS,
										this.defaultMenu ? this.defaultMenu['path'] : '',
									);
									let userSettings: UserSettingsModel = {};
									if (data.userSettings) {
										userSettings = data.userSettings;
									}
									if (userSettings?.userLanguage) {
										encryptedStorageHelperInstance
											.setItem(
												sessionStorageKeys.CURRENT_LANGUAGE_TAG,
												data.userSettings.userLanguage,
											)
											.then(() => {
												if (userSettings.userLanguage) {
													changeLanguage(userSettings.userLanguage);
												}
											});
										encryptedStorageHelperInstance.setItem(
											sessionStorageKeys.USER_SETTINGS,
											userSettings,
										);
										configWithLoginWSPromise.resolve(null);
										subscriber.next(data);
										//do nothing as routeChangeSuccessHandler will set userSettings into browser's localStorage.
									} else {
										encryptedStorageHelperInstance
											.getItem(sessionStorageKeys.CURRENT_LANGUAGE_TAG)
											.then(currentLang => {
												const lang =
													currentLang ??
													getInLocalStorage(
														sessionStorageKeys.CURRENT_LANGUAGE_TAG,
													);
												if (lang) {
													//if user lang setting doesn't exists,
													//use local storage language on UI, and update this language in userSettings.
													const customHeaders = this.getHeaderWithTempToken(
														APIUrl.UPDATE_USER_SETTINGS,
														tempToken,
													);
													userSettings.userLanguage = lang;
													this.updateUserSettings(
														userSettings,
														customHeaders,
													).then(() => {
														encryptedStorageHelperInstance.setItemSync(
															sessionStorageKeys.USER_SETTINGS,
															userSettings,
														);
														encryptedStorageHelperInstance.setItemSync(
															sessionStorageKeys.CURRENT_LANGUAGE_TAG,
															userSettings.userLanguage,
														);
														configWithLoginWSPromise.resolve(null);
														subscriber.next(data);
														subscriber.complete();
													});
												}
											});
									}
								})
								.catch(error => {
									subscriber.error(error);
									subscriber.complete();
								});
						})
						.catch(error => {
							subscriber.error(error);
						});
				}
			},
		);
	}

	/**
	 *
	 * @param userSettings userSettingsModel for updating user settings for selected language
	 */
	updateUserSettings(
		userSettings: UserSettingsModel,
		customHeaders?: Record<string, any>,
	): Promise<void> {
		return new Promise<void>(resolve => {
			this.userNetworkRepository
				.updateUserSettings(userSettings, customHeaders)
				.then(() => {
					resolve();
				});
		});
	}

	/**
	 * This method is used to fetch config list pre login
	 * @param {RenderConfigWithoutLoginRequestModel} renderConfigWithoutLoginRequestModel request model for renderWithoutLoginConfigList API
	 * @return {Record<string, any>} in format of {data, headers}
	 */
	renderConfigWithoutLogin(
		renderConfigWithoutLoginRequestModel?: RenderConfigWithoutLoginRequestModel,
	): Promise<Record<string, any>> {
		return this.userNetworkRepository.renderConfigWithoutLogin(
			renderConfigWithoutLoginRequestModel,
		);
	}

	/**
	 * This method is used to register device.
	 * @param {RegisterDeviceRequestModel} registerDeviceRequestModel request model for registerDevice API
	 * @return {Record<string, any>} in format of {data, headers}
	 */
	moRegisterDevice(
		registerDeviceRequestModel?: RegisterDeviceRequestModel,
	): Observable<RegisterDeviceResponse | null | undefined> {
		let header: Record<string, any> | undefined;
		return new Observable(
			(subscriber: Subscriber<RegisterDeviceResponse | null | undefined>) => {
				encryptedStorageHelperInstance
					.getItem(sessionStorageKeys.TEMP_AUTH_TOKEN)
					.then((tempToken: string) => {
						console.log('tempToken', tempToken);
						if (tempToken) {
							header = {};
							header = this.getHeaderWithTempToken(
								APIUrl.MO_MOBILE_REGISTER_DEVICE,
								tempToken,
							);
						}
						this.userNetworkRepository
							.moRegisterDevice(
								registerDeviceRequestModel,
								header ? header : undefined,
							)
							.then(({data}: Record<string, any>) => {
								subscriber.next(data);
							})
							.catch(error => {
								subscriber.error(error);
							})
							.finally(() => {
								subscriber.complete();
							});
					});
			},
		);
	}

	/**
	 *
	 * @returns used for getting to the previously set page.
	 */
	public getRedirectAfterLoginUrl(
		userId?: string,
	): Promise<string | undefined> {
		return new Promise<string | undefined>(resolve => {
			encryptedStorageHelperInstance
				.getItem(sessionStorageKeys.REDIRECT_AFTER_LOGIN_URL)
				.then((redirectUrlObject: Record<string, any>) => {
					if (redirectUrlObject && Object.keys(redirectUrlObject).length > 0) {
						if (!redirectUrlObject.user) {
							resolve(redirectUrlObject.redirectAfterLoginUrl);
						} else {
							if (userId && userId !== redirectUrlObject.user) {
								this.removeRedirectAfterLoginUrl();
								resolve(undefined);
							} else {
								resolve(redirectUrlObject.redirectAfterLoginUrl);
							}
						}
					} else {
						resolve(undefined);
					}
				});
		});
	}

	public removeRedirectAfterLoginUrl() {
		encryptedStorageHelperInstance.removeItem(
			sessionStorageKeys.REDIRECT_AFTER_LOGIN_URL,
		);
	}

	/**
	 *
	 * @returns used for setting the previously routed page.
	 */
	public setRedirectAfterLoginUrl(
		redirectAfterLoginUrl: string,
		userId?: string,
	) {
		if (!redirectAfterLoginUrl) {
			return;
		}
		const cookieData: Record<string, any> = {};
		if (userId) {
			cookieData.user = userId;
		}
		cookieData.redirectAfterLoginUrl = redirectAfterLoginUrl;
		encryptedStorageHelperInstance.setItem(
			sessionStorageKeys.REDIRECT_AFTER_LOGIN_URL,
			cookieData,
		);
	}

	/**
	 * This method is used by web only to navigate to default menu item after login is successful.
	 * Prerequisite fot this function to be called is to call getUserDetails API using getUserDetails function.
	 * @param navigateCallback  this is used for callback navigating function in userService.
	 */
	public redirectToLandingPage(navigateCallback?: any) {
		encryptedStorageHelperInstance
			.getItem(sessionStorageKeys.TEMP_AUTH_TOKEN)
			.then(token => {
				if (token) {
					encryptedStorageHelperInstance
						.setItem(sessionStorageKeys.AUTH_TOKEN, token)
						.then(() => {
							encryptedStorageHelperInstance.removeItem(
								sessionStorageKeys.TEMP_AUTH_TOKEN,
							);
							if (global.platform === 'web') {
								if (!this.websocketService.status()) {
									this.websocketService.createWebSocketConnectionWithToken(
										token,
									);
								}
							}
							this.getPostLoginConfig(); //The API call will fetch the required config list from the server
							this.getVitalConfig(); // This API call will fetch the vital config
							this.getUserDashboards(); // This API call will fetch the dashboards of user
							this.getRedirectAfterLoginUrl().then(targetUrlForUser => {
								if (targetUrlForUser && navigateCallback) {
									this.removeRedirectAfterLoginUrl();
									navigateCallback(targetUrlForUser);
								} else {
									navigateCallback(
										this.defaultMenu ? this.defaultMenu.path : '',
									);
								}
							});
						});
				}
			});
	}

	/**
	 *
	 * @returns Promise<boolean> for is user is Authenticated or not
	 */
	isAuthenticated(): Promise<boolean> {
		//todo when sync storageHelper is created, change here
		return new Promise<boolean>(resolve => {
			encryptedStorageHelperInstance
				.getItem(sessionStorageKeys.AUTH_TOKEN)
				.then(token => {
					if (token) {
						encryptedStorageHelperInstance
							.getItem(sessionStorageKeys.USER)
							.then(user => {
								if (user) {
									resolve(true);
								} else {
									resolve(false);
								}
							});
					} else {
						resolve(false);
					}
				});
		});
	}

	/**
	 *
	 * @param authorities Array<Record<string, any>> contains records of particular UserGroup's Roles/Permissions
	 * @returns string[] of User's permissions based on their authorities
	 */
	fetchAllPermissions = (authorities: Array<Record<string, any>>) => {
		const permissions: string[] = authorities.map(
			(item: Record<string, any>) => item.authority,
		);
		return permissions;
	};

	/**
	 * This method is used to logout user.
	 * @param {LogoutRequestModel} logoutRequestModel request model for Logout User
	 * @return {Promise<Record<string, any>>} in format of {key-value} pair
	 */
	logout(
		user?: UserModel,
		logoutRequestModel?: LogoutRequestModel,
	): Promise<Record<string, any>> {
		return new Promise<Record<string, any>>(resolve => {
			encryptedStorageHelperInstance
				.getItem(sessionStorageKeys.USER)
				.then(user => {
					encryptedStorageHelperInstance
						.getItem(sessionStorageKeys['REPORT-VIEW-OTP'])
						.then(reportViewOtp => {
							const customHeaders: Record<string, any> = {};
							if (reportViewOtp) {
								customHeaders['report-view-otp'] = reportViewOtp;
							}
							let logoutApiUrl = APIUrl.LOGOUT;
							const authProvider = user?.authProvider;
							const externalLogoutProviderList =
								global.ConfigurationHolder?.logout?.externalLogoutProviderList;
							if (authProvider && externalLogoutProviderList) {
								if (externalLogoutProviderList.indexOf(authProvider) > -1) {
									logoutApiUrl =
										APIUrl.OAUTH_LOGOUT_PROVIDER +
										'=' +
										authProvider +
										'&source=web';
								}
							}
							this.userNetworkRepository
								.logout(logoutApiUrl, logoutRequestModel)
								.then(({data}: Record<string, any>) => {
									this.fetchRedirectUrl(data, true).then(response => {
										resolve(response);
									});
								})
								.catch(error => {
									console.log(error);
								});
						});
				});
		});
	}

	/**
	 * This method is used to fetch redirect url.
	 * @param {Record<string, any>} data request for fetching redirect url
	 * @return {Promise<Record<string, any>>} in format of {key-value} pair
	 */
	fetchRedirectUrl(
		data: Record<string, any>,
		isLogout?: boolean,
	): Promise<Record<string, any>> {
		return new Promise<Record<string, any>>(resolve => {
			if (data.redirect_to) {
				this.completeUserLogout(false, this.userDetails).then(logoutData => {
					logoutData.redirect_to = data.redirect_to;
					this.removeAllCookies(false, isLogout).then(response => {
						resolve(logoutData);
					});
				});
			} else {
				this.completeUserLogout(true, this.userDetails).then(logoutData => {
					this.removeAllCookies(false, isLogout).then(response => {
						resolve(logoutData);
					});
				});
			}
		});
	}

	/**
	 * This method is used to completing the user logout
	 * @param redirectToURL user for the redirection
	 * @return {Promise<Record<string, any>>} in format of {key-value} pair
	 */
	completeUserLogout(
		redirectToURL: boolean,
		user: UserModel | undefined,
	): Promise<Record<string, any>> {
		let logoutUrlIdentifier: string;
		const logoutData: Record<string, any> = {};

		/*below code is to identify logout url for loggedInUser*/
		if (user) {
			if (user?.authProvider != null) {
				logoutUrlIdentifier = user.authProvider;
			} else if (user?.userType != null) {
				logoutUrlIdentifier = user.userType.toLowerCase();
			}
		}

		return new Promise<Record<string, any>>(resolve => {
			if (redirectToURL) {
				const logoutUrlForProvider: any =
					global.ConfigurationHolder?.logout?.logoutUrlForProvider;

				// required when logoutUrlIdentifier not defined in config then fallback it to default
				if (
					logoutUrlForProvider &&
					!logoutUrlForProvider[logoutUrlIdentifier]
				) {
					logoutUrlIdentifier = 'default';
				}

				if (logoutUrlForProvider[logoutUrlIdentifier]) {
					// here we are checking whether this url is internal or external
					if (logoutUrlForProvider[logoutUrlIdentifier].isInternalUrl) {
						logoutData.logoutStatus = true;
						logoutData.logoutURL =
							logoutUrlForProvider[logoutUrlIdentifier].url;
						logoutData.isInternal = true;
						resolve(logoutData);
					} else {
						logoutData.logoutStatus = true;
						logoutData.logoutURL =
							logoutUrlForProvider[logoutUrlIdentifier].url;
						logoutData.isInternal = false;
						resolve(logoutData);
					}
				} else {
					logoutData.logoutStatus = false;
					resolve(logoutData);
				}
			} else {
				logoutData.logoutStatus = true;
				logoutData.isInternal = true;
				resolve(logoutData);
			}
		});
	}

	/**
	 * This method is used to remove all cookies and delete all userDetails
	 */
	removeAllCookies = (
		clearOnly?: boolean,
		isLogout?: boolean,
	): Promise<void> => {
		if (clearOnly == null) {
			clearOnly = false;
		}
		const clearStorage = () => {
			encryptedStorageHelperInstance.clearStorage();
			storageHelperInstance.clearStorage();
			this.userDetails = undefined;
		};
		if (clearOnly) {
			clearStorage();
			return new Promise<void>(resolve => {
				resolve();
			});
		} else {
			return new Promise<void>(resolve => {
				//GlobalObserversInstance.userContextObserver.publish(null);
				const functionToCall = () => {
					clearStorage();
					this.renderConfigWithoutLogin().then(
						(serverConf: Record<string, any>) => {
							encryptedStorageHelperInstance
								.setItem(sessionStorageKeys.SERVER_CONFIG, serverConf?.data)
								.then(response => {
									this.serverConfigSubject.next(serverConf?.data);
									resolve();
								});
							this.serverConfigSubject.next(serverConf?.data);
						},
					);
				};
				if (
					global.ConfigurationHolder?.redirectToOldUrlAfterLogin === true &&
					isLogout !== true
				) {
					encryptedStorageHelperInstance
						.getItem(sessionStorageKeys.REDIRECT_AFTER_LOGIN_URL)
						.then(redirectUrlObject => {
							if (
								redirectUrlObject &&
								Object.keys(redirectUrlObject).length > 0
							) {
								functionToCall();
								encryptedStorageHelperInstance.setItem(
									sessionStorageKeys.REDIRECT_AFTER_LOGIN_URL,
									redirectUrlObject,
								);
							} else {
								functionToCall();
							}
						});
				} else {
					functionToCall();
				}
			});
		}
	};

	/**
	 * for fetching vital config
	 */
	getVitalConfig() {
		this.vitalNetworkRepository
			.getVitalConfig()
			.then(({data, headers, status}) => {
				encryptedStorageHelperInstance.setItem(
					sessionStorageKeys.VITAL_CONFIG,
					data,
				);
			});
	}

	/**
	 * This method is used to fetch agreements for user to be displayed on UI.
	 * @param {string | undefined} languageTag to fetch language specific agreements in case getUserDetailsFromCache is false
	 * @param {string | undefined} deviceUID only sent in case of mobile application.
	 * @param {boolean} getUserDetailsFromCache denotes whether fresh API call has to be made
	 * @return {Observable<Array<Agreement> | null | undefined>}
	 */
	fetchAgreementsForUser = (
		getUserDetailsFromCache = false,
		languageTag?: string,
		deviceUID?: string,
	): Observable<Array<Agreement> | null | undefined> => {
		return new Observable(
			(subscriber: Subscriber<Array<Agreement> | null | undefined>) => {
				this.getUserDetails(
					getUserDetailsFromCache,
					new UserDetailsRequestModel({deviceUID, userLanguage: languageTag}),
				).subscribe({
					next: (userData?: UserModel | null) => {
						if (userData == null) {
							subscriber.next(null);
						} else {
							const listOfAgreements: Array<Agreement> = [];
							userData?.agreements != null &&
								userData.agreements.forEach((agreement: AgreementsModel) => {
									listOfAgreements.push(new Agreement(agreement));
								});
							subscriber.next(listOfAgreements);
						}
					},
					error: error => {
						subscriber.error(error);
					},
					complete: () => {
						subscriber.complete();
					},
				});
			},
		);
	};

	/**
	 * This method is used to deactivate user.
	 * @param {Record<string, any>} requestObj request for deactivate user
	 * @return {Promise<Record<string, any>>} in format of {key-value} pair
	 */
	deactivateUser(
		requestObj: Record<string, any>,
	): Promise<Record<string, any>> {
		return new Promise<Record<string, any>>(resolve => {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OnScreen,
			});
			this.userNetworkRepository
				.deactivateUser(requestObj)
				.then(({data}: Record<string, any>) => {
					const deactivateUserData: Record<string, any> = {};
					if (data && data.info && data.info[0] && data.info[0].redirect_to) {
						deactivateUserData.redirect_to = data.info[0].redirect_to;
					}
					this.fetchRedirectUrl(deactivateUserData).then(response => {
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.success,
							visibility: NotificationVisibility.global,
							persistOnRouteChange: true,
							message: strings('UserProfile.deactivateApiSuccessText'),
						});
						resolve(response);
					});
				})
				.catch(error => {
					this.notificationMessageSubject.next({
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
						visibility: NotificationVisibility.dialog,
						message: strings('UserProfile.deactivateApiErrorText'),
					});
				})
				.finally(() => {
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OnScreen,
					});
				});
		});
	}

	/**
	 *
	 * @param event event for third party action like changePassword | edit | doc verification
	 * @param user user model for further authProvider checks
	 */
	updateProfileViaExternalProvider(event: string, user: UserModel) {
		encryptedStorageHelperInstance
			.getItem(sessionStorageKeys.SERVER_CONFIG)
			.then(serverConfig => {
				const apiActionURL: string =
					user.authProvider &&
					serverConfig.thirdPartyAllowedLogins?.[user.authProvider] &&
					Object.keys(serverConfig.thirdPartyAllowedLogins[user.authProvider])
						.length > 0
						? serverConfig.thirdPartyAllowedLogins[user.authProvider][event]
						: '';
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OverScreen,
				});
				this.userNetworkRepository
					.updateProfileViaExternalProvider(apiActionURL)
					.then(({data}) => {
						if (data.redirect_to) {
							this.removeAllCookies(true).then(() => {
								window.location.href = data.redirect_to;
							});
						} else {
							this.notificationMessageSubject.next({
								level: NotificationMessageLevel.error,
								type: NotificationMessageType.toast,
								visibility: NotificationVisibility.local,
								message: strings('userProfile.updateApiErrorText'),
							});
						}
					})
					.catch(() => {
						this.notificationMessageSubject.next({
							level: NotificationMessageLevel.error,
							type: NotificationMessageType.toast,
							visibility: NotificationVisibility.local,
							message: strings('userProfile.updateApiErrorText'),
						});
					})
					.finally(() => {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OverScreen,
						});
					});
			});
	}

	/**
	 * This method is used to deactivate user.
	 * @param {Record<string, any>} requestObj request for deactivate user
	 * @return {Promise<Record<string, any>>} in format of {key-value} pair
	 */
	removeConsentFromIdx(source: string): Promise<Record<string, any>> {
		return new Promise<Record<string, any>>(resolve => {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OnScreen,
			});
			this.userNetworkRepository
				.removeConsentFromIdx(source)
				.then(({data}: Record<string, any>) => {
					const removeConsentFromIdxData: Record<string, any> = {};
					if (data && data.redirect_to) {
						removeConsentFromIdxData.redirect_to = data.redirect_to;
						this.fetchRedirectUrl(removeConsentFromIdxData).then(response => {
							resolve(response);
						});
					} else {
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
							visibility: NotificationVisibility.dialog,
							message: strings('UserProfile.updateApiErrorText'),
						});
					}
				})
				.catch(error => {
					this.notificationMessageSubject.next({
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
						visibility: NotificationVisibility.dialog,
						message: strings('UserProfile.updateApiErrorText'),
					});
				})
				.finally(() => {
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OnScreen,
					});
				});
		});
	}

	/**
	 * This method is used to remove request cookie value
	 * @param key
	 */
	removeCookie = (key: string) => {
		encryptedStorageHelperInstance.removeItem(key);
	};

	/**
	 * This method is used to check whether the user is a patient user
	 * This method should be called if getUserDetails is already called
	 * @returns {boolean}
	 */
	isPatientUser(): boolean {
		return this.userDetails?.userType === 'PATIENT' ?? false;
	}

	/**
	 * This method is used to get the user id from cached data
	 * This method should be called if getUserDetails is already called
	 * @returns {number}
	 */
	getUserId(): number {
		return this.userDetails?.id ?? -1;
	}
}
export default UserService;
