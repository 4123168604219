// Words withing keys should be separated by "_" for convention

export const sessionStorageKeys: Record<string, string> = {
	USER_ID: 'user_id',
	AUTH_TOKEN: 'x-auth-token', // needs to be separated by "-" to maintain compatibility with minerva server
	USER_PASSWORD: 'user_password',
	FIRST_LOGIN: 'first_login',
	TEMP_AUTH_TOKEN: 'temp_x_auth_token',
	EXPIRES_ON: 'expires_in',
	PASSWORD_EXPIRED: 'password_expired',
	SIGNUP_SOURCE: 'signup_source',
	USER: 'user',
	USER_ROLES: 'user_roles',
	CURRENT_LANGUAGE_TAG: 'langKey', // needs to be camel case to maintain compatibility with i18n
	LOGIN_TYPE: 'login_type',
	LOGIN_RADIO_SELECTION: 'login_radio_selection',
	USERNAME: 'username',
	MENU_ITEMS: 'menuItems', // needs to be camel case to maintain sharing session with angular app
	DEFAULT_MENU_ITEMS: 'defaultMenu', // needs to be camel case to maintain sharing session with angular app
	PATIENT_BANNER_MENUITEMS: 'patientBannerMenuItems', // needs to be camel case to maintain sharing session with angular app
	DEFAULT_PATIENT_BANNER_MENUITEM: 'defaultPatientBannerMenuItem', // needs to be camel case to maintain sharing session with angular app
	USER_SETTINGS: 'userSettings', // needs to be camel case to maintain sharing session with angular app
	REPORT_VIEW_OTP: 'report_view_otp',
	SERVER_CONFIG: 'server_config',
	POST_LOGIN_SERVER_CONFIG: 'post_login_server_config',
	VITAL_CONFIG: 'vital_config',
	PATIENT_DETAILS: 'patient_details',
	READ_AGREEMENTS: 'read_agreements',
	PASSWORD_REGEX: 'passwordRegex',
	PROFILE_STATE: 'profileState',
	PROFILE_EVENT: 'event',
	OAUTH2_USER_DETAILS_ID: 'oauth2UserDetailsId',
	SOF_WORKFLOW: 'sofWorkflow',
	NONCE_REDIRECT_URL: 'nonceRedirectUrl',
	PATIENT_ID: 'patientId',
	COMPOSITE_FORM: 'compositeFormCache',
	DASHBOARDS: 'dashboards',
	REDIRECT_AFTER_LOGIN_URL: 'redirectAfterLoginUrl',
	SOFT_WORKFLOW: 'sofWorkflow',
	OAUTH2_USER_DETAILSID: 'oauth2UserDetailsId',
	SHARING_PIN: 'sharingPin',
	SHARED_RECORD_URL: 'sharedRecordURL',
	FORM_RESPONSE_ID: 'formResponseId',
	DOCUMENTS: 'documentsCache',
	SHOW_MEDICATION_ADMINISTRATION: 'hasToShowMedicationAdministration',
	'REPORT-VIEW-OTP': 'report-view-otp',
};
