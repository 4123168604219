import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class NotificationSearchRequestModel implements BaseRequestModel {
	private readonly count?: number;
	private readonly skip?: number;
	private readonly sortDesc?: string;
	private readonly priority?: string;
	private readonly auditTrail?: boolean;
	private readonly read?: boolean;

	constructor({
		count,
		skip,
		sortDesc,
		auditTrail,
		read,
		priority,
	}: NotificationSearchConstraints) {
		this.count = count;
		this.skip = skip;
		this.sortDesc = sortDesc;
		this.auditTrail = auditTrail;
		this.read = read;
		this.priority = priority;
	}

	/**
	 * returns request data for notification search API
	 * @returns {NotificationSearchConstraints}
	 */
	getRequestDataObject(): NotificationSearchConstraints {
		return {
			count: this.count,
			skip: this.skip,
			sortDesc: this.sortDesc,
			auditTrail: this.auditTrail,
			read: this.read,
			priority: this.priority,
		};
	}
}

export type NotificationSearchConstraints = {
	count?: number;
	skip?: number;
	sortDesc?: string;
	auditTrail?: boolean;
	read?: boolean;
	priority?: string;
};

export default NotificationSearchRequestModel;
