import {
	formatPhoneNumberIntl,
	isValidPhoneNumber,
	parsePhoneNumber,
} from 'react-phone-number-input';
import {PhoneNumberType} from './PhoneNumberType';

const isPhoneNumberValid = (phoneNumber: PhoneNumberType | string) => {
	if (typeof phoneNumber === 'string') {
		return isValidPhoneNumber(phoneNumber);
	}
	return isValidPhoneNumber(phoneNumber.countryCode + phoneNumber.phoneNumber);
};

const getPhoneNumberObject = (phoneNumber: string): PhoneNumberType => {
	const phoneObject: PhoneNumberType = {
		countryCode: getCountryCallingCodeFromPhoneNumber(phoneNumber) ?? '',
		phoneNumber: getNationalPhoneNumber(phoneNumber) ?? '',
	};
	return phoneObject;
};

const getNationalPhoneNumber = (phoneNumber: string): string | undefined => {
	let phoneNo = parsePhoneNumber(phoneNumber)?.nationalNumber
		? parsePhoneNumber(phoneNumber)?.nationalNumber.toString()
		: undefined;
	return phoneNo;
};

const getCountryCallingCodeFromPhoneNumber = (
	phoneNumber: string,
): string | undefined => {
	const countryCallingCode: string | undefined = parsePhoneNumber(phoneNumber)
		?.countryCallingCode
		? '+' + parsePhoneNumber(phoneNumber)?.countryCallingCode.toString()
		: undefined;

	return countryCallingCode;
};

const formatPhoneNumberWithCountryCode = (
	fullNumber: string,
	type: number,
	ConfigurationHolder?: Record<string, any>,
) => {
	const countryCode = getCountryCallingCodeFromPhoneNumber(fullNumber);
	const phoneNo = getNationalPhoneNumber(fullNumber);

	return ConfigurationHolder
		? formatPhoneNumber(phoneNo, countryCode, type, ConfigurationHolder)
		: formatPhoneNumber(phoneNo, countryCode, type);
};

const formatPhoneNumber = (
	number: string | undefined,
	countryCode: string | undefined,
	type: number,
	ConfigurationHolder?: Record<string, any>,
) => {
	/*
			supported types:
				"INTERNATIONAL": 0 //default,
				"NATIONAL": 1,

			@arguments:
				number: <+1-9999999999> or <+19999999999> or <9999999999> [MANDATORY]
				countryCode: <+1> or null or undefined
				type: 0,1,2,3 based on supported types
		*/
	if (!number) {
		return;
	}
	var numberWithoutCountryCode: any = number;
	if (number.indexOf('-') !== -1) {
		numberWithoutCountryCode = number.split('-')[1];
		countryCode = number.split('-')[0];
	}
	if (!type) {
		type = 0;
	}
	var formattedNumber: any = '';
	if (countryCode && !formattedNumber.includes(countryCode)) {
		formattedNumber = countryCode + numberWithoutCountryCode;
	} else {
		formattedNumber = numberWithoutCountryCode;
	}
	var country = '';
	if (
		ConfigurationHolder &&
		countryCode === ConfigurationHolder.defaultCountryCode
	) {
		country = ConfigurationHolder.defaultCountry.toUpperCase();
	}
	var isValid = isPhoneNumberValid({
		countryCode: country,
		phoneNumber: numberWithoutCountryCode,
	});
	// if(!isValid)
	// 	isValid = telecomHelperService.customPhoneValidator(null, formattedNumber ,countryCode.replace(/\+/g, ""));

	if (country && isValid) {
		formattedNumber = formatPhoneNumberIntl(numberWithoutCountryCode);
	} else if (countryCode && isValid) {
		formattedNumber = formatPhoneNumberIntl(formattedNumber);
	}

	return formattedNumber;
};

export {
	getPhoneNumberObject,
	isPhoneNumberValid,
	getNationalPhoneNumber,
	getCountryCallingCodeFromPhoneNumber,
	formatPhoneNumberWithCountryCode,
	formatPhoneNumber,
};
