import {lazy, Suspense} from 'react';
import {Route} from 'react-router-dom';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import Loader from '../../ui/comps/src/Loader';

const PatientProfile = lazy(() => import('./PatientProfile'));
// const EditPatientProfile = lazy(() => import('./EditPatientProfile'));

const PatientProfileRoutes = (
	<Route path="/">
		<Route
			path={RoutePathInfo.patientProfile.path}
			element={
				<Suspense fallback={<Loader />}>
					<PatientProfile />
				</Suspense>
			}
		/>
		{/* <Route
			path={RoutePathInfo.editPatientProfile.path}
			element={
				<Suspense fallback={<Loader />}>
					<EditPatientProfile />
				</Suspense>
			}
		/> */}
	</Route>
);

export default PatientProfileRoutes;
