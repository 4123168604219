import {IAnalyticsHelper} from '../../common/src/interface/analytics/IAnalyticsHelper';
import {
	getAnalytics,
	logEvent as _logEvent,
	setUserProperties as _setUserProperties,
} from 'firebase/analytics';

/**
 * Log analytics Event
 * @param eventName
 * @param content
 */
const logEvent = (eventName: string, content: Record<string, any>): void => {
	const analytics = getAnalytics();
	_logEvent(analytics, eventName, content);
};

/**
 * Set single user property
 * @param name
 * @param value
 */
const setUserProperty = (name: string, value: string | null): void => {
	const analytics = getAnalytics();
	_setUserProperties(analytics, {name: value});
};

/**
 * Set multiple user properties
 * @param properties
 */
const setUserProperties = (properties: {
	[key: string]: string | null;
}): void => {
	const analytics = getAnalytics();
	_setUserProperties(analytics, properties);
};

const analyticsHelperInstance: IAnalyticsHelper = {
	logEvent: logEvent,
	setUserProperty: setUserProperty,
	setUserProperties: setUserProperties,
	setUserId: function (id: string | null): void {
		throw new Error('Function not implemented.');
	},
};

export default analyticsHelperInstance;
