import React, {lazy, Suspense} from 'react';
import {Route} from 'react-router-dom';
import RoutePathInfo from '../../../common/src/enums/RoutingPath';
import Loader from '../../../ui/comps/src/Loader';

const ShowAllRecordsComponent = lazy(
	() => import('./components/showAllRecords/ShowAllRecords'),
);
const OrderSummaryComponent = lazy(
	() => import('./components/orderSummary/OrderSummary'),
);
const RecordDetailsComponent = lazy(
	() => import('./components/recordDetails/RecordDetails'),
);

const RecordsRoutes = (
	<>
		<Route
			path={RoutePathInfo.showAllRecords.path}
			element={
				<Suspense fallback={<Loader />}>
					<ShowAllRecordsComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.orderSummary.path}
			element={
				<Suspense fallback={<Loader />}>
					<OrderSummaryComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.recordDetails.path}
			element={
				<Suspense fallback={<Loader />}>
					<RecordDetailsComponent />
				</Suspense>
			}
		/>
	</>
);

export default RecordsRoutes;
