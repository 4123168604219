import {Container, Service} from 'typedi';
import {w3cwebsocket as W3CWebSocket} from 'websocket';

import encryptedStorageHelperInstance from '../storage/EncryptedStorageHelper';
import {configWithLoginWSPromise} from '../common/src/application/modules/user/service/User.service';
import {sessionStorageKeys} from '../common/src/interface/storage/constants/SessionKeys';
import NotificationViewModel from '../common/src/application/modules/notifications/viewModels/NotificationViewModel';
import {NotificationResponse} from '../common/src/application/modules/notifications/models/responseModels/NotificationSearchResponseModel';
import {Observable, Subject} from 'rxjs';
import GlobalObserversInstance from '../common/src/utils/observers/GlobalObservers';

@Service()
class WebsocketService {
	private webSocket: any;
	private maxWSConnectionsReached: boolean;
	private onCloseRetryCount: number;
	private webSocketPingIntervalObject?: NodeJS.Timeout;
	private notificationViewModel: NotificationViewModel;
	private readonly documentDownloadModel: Record<string, any>;
	private readonly documentDownloadModelSubject: Subject<Record<string, any>>;

	constructor() {
		this.maxWSConnectionsReached = false;
		this.onCloseRetryCount = 0;
		this.notificationViewModel = Container.get(NotificationViewModel);
		this.documentDownloadModelSubject = new Subject<Record<string, any>>();
		this.documentDownloadModel = {};
		this.documentDownloadModel.downloadReady = false;
		this.documentDownloadModel.downloadStatusList = [];
	}

	openWebSocketConnection() {
		let baseUrl = global.ConfigurationHolder.urls.applicationServer;
		if (baseUrl.indexOf('https') > 0) {
			baseUrl =
				baseUrl.replace('https', 'wss') + '/minerva/webSocketEventEndPoint';
		} else {
			baseUrl =
				baseUrl.replace('http', 'ws') + '/minerva/webSocketEventEndPoint';
		}
		this.webSocket = new W3CWebSocket(baseUrl);
	}

	logout() {
		if (this.webSocket != null) {
			console.info('closing websocket and clearing interval');
			if (this.webSocketPingIntervalObject) {
				clearInterval(this.webSocketPingIntervalObject);
			}
			this.webSocket.close();
			this.webSocketPingIntervalObject = undefined;
			this.webSocket = undefined;
		}
	}

	cleanup() {
		if (this.webSocket != null) {
			console.info('clearing interval');
			if (this.webSocketPingIntervalObject) {
				clearInterval(this.webSocketPingIntervalObject);
			}
			this.webSocketPingIntervalObject = undefined;
			this.webSocket = undefined;
		}
	}

	status() {
		if (this.webSocket != null) {
			return this.webSocket.readyState;
		} else {
			return false;
		}
	}

	send = (message: any) => {
		if (typeof message === 'string') {
			this.webSocket.send(message);
		} else if (typeof message === 'object') {
			this.webSocket.send(JSON.stringify(message));
		}
	};

	onMessage = (event: any) => {
		let message = JSON.parse(event.data);
		if (message) {
			console.debug('onMessage - message received - ' + message.resource);
		}
		switch (message.resource) {
			case 'NOTIFICATION':
				// do with notification type resource.
				console.log('notification recieved');
				global.unreadNotifications = global.unreadNotifications + 1;
				global.showNotificationBadge = true;
				GlobalObserversInstance.notificationCountObserver.publish(
					global.unreadNotifications,
				);
				break;
			case 'PING':
				// do nothing
				//console.debug('response for ping on web-socket'+event.data);
				if (message.message === 'INVALID_TOKEN') {
					console.debug('invalid token, logging out.');
					this.logout();
				} else if (message.message === 'MAX_WS_TOKEN_PER_USER_REACHED') {
					console.debug(
						'max web socket connections for this user reached, disabling and clearing web-socket connection',
					);
					this.maxWSConnectionsReached = true;
					this.logout();
				}
				break;
			case 'DOWNLOAD':
				if (
					message.messageType === 'DOWNLOAD_STATUS' ||
					message.messageType === 'BULK_DOWNLOAD_STATUS'
				) {
					let downloadStatusEntry = {
						id: message.id,
						status: message.message,
						numberOfFileToDownload: message.numberOfFiles,
						retries: message.retries,
						messageType: message.messageType,
					};
					let readyStatusCount = 0;
					this.documentDownloadModel.downloadReady = false;
					if (!this.documentDownloadModel.downloadStatusList) {
						this.documentDownloadModel.downloadStatusList = [];
					}
					if (this.documentDownloadModel.downloadStatusList.length > 0) {
						let alreadyExist = false;
						let checked = false;
						this.documentDownloadModel.downloadStatusList.map(
							(downloadStatus: Record<string, any>, index: number) => {
								console.log(
									'downloadStatus.id, downloadStatusEntry.id : ' +
										(downloadStatus.id === downloadStatusEntry.id),
								);
								if (downloadStatus.id === downloadStatusEntry.id) {
									downloadStatus.id = downloadStatusEntry.id;
									downloadStatus.status = downloadStatusEntry.status;
									downloadStatus.numberOfFileToDownload =
										downloadStatusEntry.numberOfFileToDownload;
									downloadStatus.retries = downloadStatusEntry.retries;
									alreadyExist = true;
								}
								if (
									downloadStatus.status === 'PROCESSED' ||
									(downloadStatus.status === 'FAILED' &&
										downloadStatus.retries === 3)
								) {
									readyStatusCount = readyStatusCount + 1;
								}
								checked = true;
							},
						);
						if (!alreadyExist && checked) {
							this.documentDownloadModel.downloadStatusList.push(
								downloadStatusEntry,
							);
						}
					} else {
						this.documentDownloadModel.downloadStatusList.push(
							downloadStatusEntry,
						);
					}
					if (
						this.documentDownloadModel.downloadStatusList.length ===
						readyStatusCount
					) {
						this.documentDownloadModel.downloadReady = true;
					}
					this.documentDownloadModelSubject.next(this.documentDownloadModel);
				}
				break;

			default:
				console.debug('unknown message format =' + event.data);
		}
	};

	onClose = (event: any) => {
		console.log('web-socket connection closed', event);
		this.cleanup(); /* cleanup existing web-socket */

		if (this.maxWSConnectionsReached) {
			console.debug(
				're-connection will not be attempted since max connections limits per user has been reached',
			);
			return;
		}
		setTimeout(() => {
			var authToken =
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.AUTH_TOKEN,
				) ??
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.TEMP_AUTH_TOKEN,
				);
			if (
				this.onCloseRetryCount <
					global.ConfigurationHolder?.webSocketConnectionRetryCount &&
				authToken
			) {
				console.log(
					'on-close re-opening web-socket connection since auth token is still available. attempt count=' +
						this.onCloseRetryCount,
				);
				this.onCloseRetryCount++;
				this.createConnectionWithToken(authToken);
			} else {
				console.debug(
					'could not re-open web socket since either authToken has expired or max retry has been reached',
				);
			}
		}, (global.ConfigurationHolder.webSocketReconnectInterval = global.ConfigurationHolder.webSocketReconnectInterval ? global.ConfigurationHolder.webSocketReconnectInterval : 120000) /* re attempt connection in every two minutes */);
	};

	createConnectionWithToken(token: string) {
		this.openWebSocketConnection();
		this.webSocket.onmessage = (event: any) => this.onMessage(event);
		this.webSocket.onerror = (event: any) =>
			console.log('webSocket - connection Error', event);

		this.webSocket.onclose = (event: any) => this.onClose(event);

		this.webSocket.onopen = () => {
			var authToken =
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.AUTH_TOKEN,
				) ??
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.TEMP_AUTH_TOKEN,
				);
			var message = {
				token: authToken,
				messageType: 'json',
				message: 'GET_CONNECTION',
			};
			console.info('onOpen web-socket');
			this.webSocket.send(JSON.stringify(message));
			this.onCloseRetryCount = 0;
		};

		// keep the web socket connection alive by pinging in every 2 minutes.
		this.webSocketPingIntervalObject = setInterval(() => {
			var authToken =
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.AUTH_TOKEN,
				) ??
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.TEMP_AUTH_TOKEN,
				);
			if (authToken) {
				var message = {
					token: authToken,
					message: 'WEBSOCKET_PING_STATUS',
				};
				if (this.webSocket !== undefined) {
					if (this.webSocket.readyState === 1) {
						console.debug('pinging web-socket');
						this.webSocket.send(JSON.stringify(message));
					}
				}
			} else {
				//console.log('closing web-socket, token not found while pinging');
				this.logout();
			}
		}, global.ConfigurationHolder.webSocketPingInterval);
	}

	createWebSocketConnectionWithToken(token: string) {
		configWithLoginWSPromise.promise.then(() => {
			if (
				true ===
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.SERVER_CONFIG,
				)?.webSocketPublishEnabled
			) {
				this.createConnectionWithToken(token);
			} else {
				setInterval(() => {
					console.log('inside notifiaciton interval');
					this.notificationViewModel?.fetchUnreadNotifications().subscribe({
						next: (response: NotificationResponse) => {
							global.unreadNotifications =
								response.totalCount && response.totalCount > 0
									? response.totalCount
									: 0;
							global.showNotificationBadge = true;
						},
					});
				}, global.ConfigurationHolder.unreadNotificationCheckDuration);
			}
		});
	}

	/**
	 * This method is used to get the observer where document download details are posted
	 * @returns {Observable<Record<string, any>>}
	 */
	getDocumentDownloadObservable(): Observable<Record<string, any>> {
		return this.documentDownloadModelSubject.asObservable();
	}
}
export default WebsocketService;
