import {strings} from '../../../../../../../localization/i18n';
import {
	getFileSize,
	getFileExtensionAndIconClass,
} from '../../../../utils/uiCompsUtils';
import './../fileUpload.scss';

const UploadedFilesComponent = (props: any): JSX.Element => {
	const {
		uploadedFiles,
		fileUploadOptions,
		deleteFileHandler,
		dynamicConstraints,
		isViewOnlyMode,
	} = props;

	const name = props?.fieldData?.name;

	const getFileExtensionClass = function (fileName: string) {
		var resultObj = getFileExtensionAndIconClass(fileName);
		return resultObj ? resultObj.iconClass : '';
	};

	return (
		<>
			{uploadedFiles.length > 0 && (
				<div
					className=" phyUploadedList--table"
					id={`phyUploadMRL_tCinr_${name}`}>
					<div
						id={`div_om_tableLayout_${name}`}
						className="tableLayout noMargin with-right-action">
						<table
							id={`uploadedFiles_table_${name}`}
							className=" uploadDocumentTable">
							<thead id={`phyUploadMRL_th_${name}`}>
								<tr id={`phyUploadMRL_thR_${name}`}>
									<td className="first" id={`phyUploadMRL_thR_dI_${name}`}>
										{strings(
											'physicianDocuments.list.table.documentIdentification',
										)}
									</td>
									<td className="last width-150 sm-right-action">
										{strings(
											'patientUploadRecords.listDocuments.tableHeader.action',
										)}
									</td>
								</tr>
							</thead>
						</table>
					</div>
					{uploadedFiles.map((file: any, index: number) => (
						<div
							id={`div_om_tableLayout_${name}`}
							className="tableLayout noMargin with-right-action"
							key={index}>
							<table
								id={`uploadedFiles_table_${name}`}
								className=" uploadDocumentTable"
								key={file.path}>
								<thead id={`phyUploadMRL_th_${name}`} style={{display: 'none'}}>
									<tr id={`phyUploadMRL_thR_${name}`}>
										<td className="first" id={`phyUploadMRL_thR_dI_${name}`}>
											{strings(
												'physicianDocuments.list.table.documentIdentification',
											)}
										</td>
										<td className="last width-150 sm-right-action">
											{strings(
												'patientUploadRecords.listDocuments.tableHeader.action',
											)}
										</td>
									</tr>
								</thead>
								<tbody id={`uploadedFiles_tbody_${name}`}>
									<tr id={`uploadedFiles_tr_${name}`} className="noEff">
										<td id={`uploadedFiles_td1_${name}`} className="first">
											{fileUploadOptions.showFilePath && (
												<div
													id={`div_uploadedFiles_td1_${name}`}
													className="docTtl">
													<span
														aria-hidden="true"
														className={
															'fIcon typeIcon ' +
															(file.type == 'DICOM'
																? 'icon-radiology-02'
																: file.name
																? getFileExtensionClass(file.name)
																	? getFileExtensionClass(file.name)
																	: 'icon-otherFile'
																: 'icon-otherFile')
														}></span>
													<span className="text">{file.name}</span>
												</div>
											)}
											{file.size && fileUploadOptions.showFileSize && (
												<p className="dList">
													<i>
														{strings('physicianDocuments.list.table.fileSize')}
													</i>
													<span> {getFileSize(file.size, 2)}</span>
												</p>
											)}
										</td>
										<td
											id={`uploadedFiles_td3_${name}`}
											className="last width-150 sm-right-action">
											<div
												id={`div_uploadedFiles_td3_${name}`}
												className="sm-right-action-position">
												{/* Button trigger modal */}
												<button
													id={`deleteFileBtn_${name}`}
													type="button"
													title={strings('accessibility.remove')}
													disabled={
														isViewOnlyMode === true
															? isViewOnlyMode
															: dynamicConstraints?.disabled ?? false
													}
													className="btn btn-secondary btn-sm btnWithIconOnly btn-circle"
													onClick={() => deleteFileHandler(index)}>
													<span
														aria-hidden="true"
														className="fIcon icon-delete2"></span>
												</button>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default UploadedFilesComponent;
