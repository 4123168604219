import {Service} from 'typedi';
import APIExecutor, {APIRequest} from "../../../network/APIExecutor";
import {APIUrl} from "../../../network/values/URLInfo";
import GetVitalsRequestModel from "../models/requestModel/GetVitalsRequestModel";
import PatientActionBannerRequestModel from "../models/requestModel/PatientActionBannerRequestModel";
import GetVitalDetailsRequestModel from "../models/requestModel/GetVitalDetailsRequestModel";
import DeleteVitalRequestModel from "../models/requestModel/DeleteVitalRequestModel";
import AddAndUpdateVitalRequestModel from "../models/requestModel/AddAndUpdateVitalRequestModel";
import ShareVitalRequestModel from "../models/requestModel/ShareVitalRequestModel";

@Service()
class VitalNetworkRespository{

    /**
     * fetches vital configuration
     * @returns : {Promise<Record<string, any>>}
     */
    getVitalConfig():Promise<Record<string, any>>{
        const vitalConfig = APIExecutor.postHTTP(new APIRequest(APIUrl.GET_VITAL_CONFIG, {}))
        return vitalConfig;
    }

    /**
     * fetches vitals overview details
     * @param {GetVitalsRequestModel}
     * @returns {Promise<Record<string, any>>}
     */
     getAllVitalDetails(getVitalsRequestModel:GetVitalsRequestModel) : Promise<Record<string, any>>{
         const allVitalsData = APIExecutor.postHTTP(new APIRequest(APIUrl.GET_VITALS_DETAILS, getVitalsRequestModel))
        return allVitalsData
    }

    /**
     * fetches patient action banner details
     * @param {PatientActionBannerRequestModel, patientId}
     * @return {Record<string, any>}
     */
    getPatientActionBannerDetails(patientActionBannerrRequestModel:PatientActionBannerRequestModel, patientId:number):Promise<Record<string, any>>{
        const patientBannerDetails = APIExecutor.postHTTP((new APIRequest(APIUrl.PATIENT_ACTION_BANNER_SHOW+`/${patientId}`, patientActionBannerrRequestModel)))
        return patientBannerDetails;
    }

    /**
     * fetches vital details
     * @param {GetVitalDetailsRequestModel}
     * @returns {Promise<Record<string, any>>}
     */
    getVitalDetails(getVitalDetailsRequestModel:GetVitalDetailsRequestModel) : Promise<Record<string, any>>{
        const vitalDetails = APIExecutor.postHTTP(new APIRequest(APIUrl.GET_VITALS, getVitalDetailsRequestModel))
        return vitalDetails
    }

    /**
     * delete vital details
     * @param {DeleteVitalRequestModel}
     * @returns {Promise<Record<string, any>>}
     */
    deleteVital(deleteVitalRequestModel:DeleteVitalRequestModel):Promise<Record<string, any>>{
        const deleteVitalResponse = APIExecutor.postHTTP(new APIRequest(APIUrl.DELETE_VITAL, deleteVitalRequestModel))
        return deleteVitalResponse
    }

    /**
     * Add new vital
     * @param {AddAndUpdateVitalRequestModel}
     * @returns {Promise<Record<string, any>>}
     */
    addVital(addVitalRequestModel:AddAndUpdateVitalRequestModel):Promise<Record<string, any>>{
        const addVitalResponse = APIExecutor.postHTTP(new APIRequest(APIUrl.ADD_VITAL, addVitalRequestModel))
        return addVitalResponse
    }

    /**
     * update existing vital detail
     * @param {AddAndUpdateVitalRequestModel}
     * @returns {Promise<Record<string, any>>}
     */
    updateVital(updateVitalRequestModel:AddAndUpdateVitalRequestModel):Promise<Record<string, any>>{
        const updateVitalResponse = APIExecutor.postHTTP(new APIRequest(APIUrl.UPDATE_VITAL, updateVitalRequestModel))
        return updateVitalResponse
    }

    /**
     * This method is used to share vital
     * @param shareVitalRequestModel
     * @returns {Promise<Record<string, any>>}
     */
    shareVital(shareVitalRequestModel:ShareVitalRequestModel):Promise<Record<string, any>>{
        const shareVitalResponse = APIExecutor.postHTTP(new APIRequest(APIUrl.SHARE_VITAL, shareVitalRequestModel));
        return shareVitalResponse
    }
}
export default  VitalNetworkRespository