import {NavLink} from "react-router-dom";
import { toggleClass, addClass, removeClass } from "../../utils/uiCompsUtils";

function NavBarLogo(props: any) {

    const {navbarLogoDetails}:
        {navbarLogoDetails: Record<string, any>} = props
        
    return (
        <>
        <NavLink className='brandName' to={navbarLogoDetails.path}>
            <img title={navbarLogoDetails.title}
                 alt={navbarLogoDetails.alt} src={navbarLogoDetails.src}
                 className={navbarLogoDetails.className} />
        </NavLink>
        </>
    );
}

export default NavBarLogo;
