import BaseResponseModel from '../../../../network/interface/BaseResponseModel';
import {DynamicFormDataV2} from '../../../../dynamicFormUtil/types/DynamicForm.types';
import {
	CompositeFormType,
	getHeadingForCompositeForm,
} from '../enum/CompositeFormType';
import {CompositeFormPageDetails} from './CompositeFormPageDetails';
import {strings} from '../../../../../../../localization/i18n';

export default class CompositeFormDataResponse implements BaseResponseModel {
	private readonly _dynamicFormData: Array<DynamicFormResponse> | undefined;
	private readonly _type: CompositeFormType | undefined;
	private readonly _formResponseId: number | undefined;
	private readonly _pageTitle: string | undefined;
	private readonly _formResponse: Record<string, any> | undefined;
	private readonly _compositeFormPageDetails: CompositeFormPageDetails;
	private readonly _visitedTabs: Array<string>;

	constructor({
		dynamicFormData,
		type,
		formResponseId,
		formResponse,
        visitedTabs
	}: CompositeFormResponseType) {
		this._dynamicFormData = dynamicFormData;
		this._type = type;
		this._formResponseId = formResponseId;
		this._formResponse = formResponse;
		this._visitedTabs = visitedTabs ?? [];
		this._pageTitle =
			this._type != null ? getHeadingForCompositeForm(this._type) : '';
		this._compositeFormPageDetails = {
			pageTitle: this._pageTitle,
			totalSteps: this._dynamicFormData?.length ?? 0,
			steps:
				this._dynamicFormData?.map(
					(data: DynamicFormResponse, index: number) => {
						return {
							stepTitle: strings('compositeForms.titles.' + data.title ?? ''),
							id: index,
							stepIdentifier: data.title,
						};
					},
				) ?? ([] as {stepTitle: string; id: number; stepIdentifier: string;}[]),
		};
	}

	/**
	 * This method returns the response received from /minerva/formResponse/getFormAttributesAndFormData API call
	 * @returns {CompositeFormResponseType}
	 */
	getResponseDataObject(): CompositeFormResponseType {
		return {
			dynamicFormData: this._dynamicFormData,
			type: this._type,
			formResponseId: this._formResponseId,
			formResponse: this._formResponse,
			visitedTabs: this._visitedTabs,
		};
	}

	/**
	 * This method returns the response received from /minerva/formResponse/getFormAttributesAndFormData API call
	 * @returns {CompositeFormResponseType}
	 */
	getCompositeFormPageDetails(): CompositeFormPageDetails {
		return this._compositeFormPageDetails;
	}
}

export type CompositeFormResponseType = {
	dynamicFormData?: Array<DynamicFormResponse>;
	type?: CompositeFormType;
	formResponseId?: number;
	formResponse?: Record<string, any>;
	visitedTabs?: Array<string>;
};

export type DynamicFormResponse = {
	dynamicForm: DynamicFormDataV2;
	title: string;
};
