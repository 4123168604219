import BaseRequestModel from '../../../../../../../network/interface/BaseRequestModel';

export default class FetchCountriesRequest implements BaseRequestModel {
	private readonly _lang: string;

	constructor({lang}: FetchCountriesRequestType) {
		this._lang = lang;
	}

	/**
	 * This method is used to get the request body to be sent
	 * in /minerva/moCountryDropDown/fetchCountries API call
	 * @returns {FetchCountriesRequestType}
	 */
	getRequestDataObject(): FetchCountriesRequestType {
		return {
			lang: this._lang,
		};
	}
}

export type FetchCountriesRequestType = {
	lang: string;
};
