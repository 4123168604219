import BaseResponseModel from "../../../../network/interface/BaseResponseModel";

class CancleBulkDownLoadRequestResponseModel implements BaseResponseModel {
    private readonly info?: Array<InfoData>;
    private readonly success?: boolean

    constructor({
                    info,
                    success
                }: CancleBulkDownLoadRequestResponsData) {
        this.info = info;
        this.success = success
    }

    /**
     * returns cancel bulk upload response
     * @returns {CancleBulkDownLoadRequestResponsData}
     */
    getResponseDataObject(): CancleBulkDownLoadRequestResponsData {
        return {
            info: this.info,
            success: this.success
        }
    }
}

export type CancleBulkDownLoadRequestResponsData = {
    info?: Array<InfoData>,
    success?: boolean
}

export type InfoData = {
    status?: boolean,
    message?: string,
    responseStatus?: number
}
export default CancleBulkDownLoadRequestResponseModel