import NavBar from '../../ui/comps/src/navBar/NavBar';
import mphrxLogo from '../../minerva-logo.svg';
import Localization from './Localization';
import FilePaths from '../../common/src/enums/FilePaths';
import {useContext, useEffect, useState} from 'react';
import {
	AppContextInterface,
	GlobalStateContext,
} from '../../common/src/utils/context/GlobalStateContext';
import encryptedStorageHelperInstance from '../../storage/EncryptedStorageHelper';
import {sessionStorageKeys} from '../../common/src/interface/storage/constants/SessionKeys';
import {displayUserID, setFocusId} from '../../utils/filters/filters';
import Container from 'typedi';
import UserService from '../../common/src/application/modules/user/service/User.service';
import {MenuItemsModel} from '../../common/src/application/modules/user/models/UserModel';
import {isValueAvailable} from '../../common/src/utils/StringUtils';
import Agreement from '../../common/src/application/modules/agreements/models/Agreement';
import AgreementsViewModel from '../../common/src/application/modules/agreements/viewModel/AgreementsViewModel';
import DialogBox from '../../ui/comps/src/DialogBox/dialogBox.component';
import {
	isAccessible,
	isNotAccessible,
} from '../../common/src/utils/CommonUtils';
import {strings} from '../../localization/i18n';
import {DialogBoxSizeEnum} from '../../ui/comps/src/DialogBox/dialogBoxEnum';
import DeactivateUserModal from './DeactivateUserModal';
import {LoaderProps} from '../../common/src/application/modules/generic/models/Loader';
import Loader from '../../ui/comps/src/Loader';
import {NotificationMessage} from '../../common/src/application/modules/generic/models/NotificationMessage';
import {showNotification} from '../../ui/comps/src/notification/view/ToastNotification';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import {PageButton} from '../../ui/comps/src/ActionButtons/IPageButton';
import RemoveConsent from '../auth/consent/RemoveConsent';
import {AboutUsContent} from './AboutUs';
import NotificationDropdown from './NotificationDropdown';
import {customNavigate} from '../../utils/wrappers/App.decision';
import {useLocation, useNavigate} from 'react-router-dom';

function DefaultHeader(props: any) {
	const authToken = encryptedStorageHelperInstance.getItemSync(
		sessionStorageKeys.AUTH_TOKEN,
	);

	const additionalNavItems = [
		{
			name: 'header.menuItems.QuestionBuilder',
			type: 'dropDown',
			role: ['ROLE_QUESTIONNAIRE'],
			subMenuItems: [
				{
					path: '/activityList',
					name: 'header.menuItems.PatientResponses',
					type: 'link',
				},
				{
					path: '/templates',
					name: 'header.menuItems.Questionnaires',
					type: 'link',
				},
				{
					path: '/createQuestionnaire',
					name: 'header.menuItems.CreateQuestionnaire',
					type: 'link',
				},
				{
					path: '/createEditExternalQuestionnaire',
					name: 'header.menuItems.CreateExternalQuestionnaire',
					type: 'link',
				},
				{
					path: '/questionnaireGroupCreate',
					name: 'questionnaireGroup.createQuestionnaireGroup',
					type: 'link',
					role: ['ROLE_MANAGE_QUESTIONNAIRE_GROUP'],
				},
			],
		},
		{
			name: 'header.menuItems.Appointments',
			type: 'link',
			path: '/appointments',
			role: ['ROLE_PATIENT', 'ROLE_APPOINTMENT'],
		},
	];

	var userService: UserService;
	userService = Container.get(UserService);

	const location = useLocation();
	const navigate = useNavigate();

	const {user, serverConfig} =
		useContext<AppContextInterface>(GlobalStateContext);

	const [showLoader, setShowLoader] = useState<boolean>(false);

	const [navbarLogoDetails, setNavbarLogoDetails] = useState<
		Record<string, any>
	>({
		path: '',
		title: strings('pageTitle.mphrxLogo'),
		alt: strings('pageTitle.mphrxLogo'),
		src: mphrxLogo,
		className: '',
	});

	useEffect(() => {
		const notificationMessageSubscriber = userService
			.fetchNotificationMessageObservable()
			.subscribe({
				next: (response: NotificationMessage) => {
					showNotification({
						type: response.visibility,
						level: response.level,
						message: response.message,
						persistOnRouteChange: response.persistOnRouteChange,
					});
				},
			});

		const loaderSubscriber = userService.fetchLoaderObservable().subscribe({
			next: (response: LoaderProps) => {
				setShowLoader(response.isToShowLoader);
			},
		});

		return () => {
			notificationMessageSubscriber.unsubscribe();
			loaderSubscriber.unsubscribe();
		};
	}, [userService]);

	const logout = () => {
		userService.logout().then(response => {
			if(response?.redirect_to){
				customNavigate(response?.redirect_to, navigate, location, {}, undefined, true);
			}
			else{
				customNavigate(
					global.ConfigurationHolder.urls.applicationFrontEnd.concat(
						'/#' + response.logoutURL,
					),
					navigate,
					location,
					{},
					undefined,
					true,
				);
			}
		});
	};

	const [navItemsRight, setNavItemsRight] = useState<
		Array<Record<string, any>>
	>([]);

	const [navItems, setNavItems] = useState<Array<MenuItemsModel>>([]);
	const [loadDefaultHeaderStatus, setLoadDefaultHeaderStatus] =
		useState<boolean>(false);

	const agreementsViewModel: AgreementsViewModel =
		Container.get(AgreementsViewModel);
	const [readAgreements, setReadAgreements] = useState<Array<Agreement>>();
	useEffect(() => {
		if (user != null && authToken != null) {
			agreementsViewModel.initialize(true, undefined);
			agreementsViewModel.fetchAgreementsObservable().subscribe({
				next() {
					const fetchedReadAgreements =
						agreementsViewModel.fetchReadAgreements();
					const allReadAgreements: Array<Agreement> = [];
					if (fetchedReadAgreements && fetchedReadAgreements.length > 0) {
						fetchedReadAgreements.map((agreement: Agreement) => {
							allReadAgreements.push(agreement);
						});
					}
					setReadAgreements(allReadAgreements);
				},
				error: () => {
					console.log('error occurred while fetching');
				},
			});
		}
	}, [authToken]);

	const [showDialog, setShowDialog] = useState<boolean>(false);
	const [dialogContent, setDialogContent] = useState<Agreement | undefined>(
		undefined,
	);
	const [showDeactivateUserDialog, setShowDeactivateUserDialog] =
		useState<boolean>(false);

	const [showAboutUs, setShowAboutUs] = useState<boolean>(false);

	const [showChangePasswordDialog, setShowChangePasswordDialog] =
		useState<boolean>(false);

	const [showRemoveConsentDialog, setShowRemoveConsentDialog] =
		useState<boolean>(false);

	const toggleDialog = () => {
		setShowDialog(!showDialog);
	};

	const openDeactivateUserDialog = () => {
		setShowDeactivateUserDialog(true);
	};

	const openRemoveConsentDialog = () => {
		setShowRemoveConsentDialog(true);
	};

	const toggleDeactivateUserDialog = () => {
		setShowDeactivateUserDialog(!showDeactivateUserDialog);
	};

	const closeRemoveConsentDialog = () => {
		setShowRemoveConsentDialog(false);
	};

	const deactivateUser = (deactivateReason: string) => {
		const requestObj: Record<string, any> = {
			deactivateReason: deactivateReason,
			authToken: authToken,
			provider: user.authProvider,
			source: 'web',
		};
		userService.deactivateUser(requestObj).then(response => {
			if (response && response.logoutURL) {
				customNavigate(
					response.logoutURL,
					navigate,
					location,
					{},
					undefined,
					true,
				);
			}
		});
	};

	const removeConsent = () => {
		if (serverConfig?.revokeConsentProvider !== 'idp') {
			removeConsentFromIdx();
		} else {
			removeConsentFromIdp();
		}
	};

	const changePasswordForExternalProvider = () => {
		switch (user.authProvider) {
			case 'idx':
				setShowChangePasswordDialog(true);
				break;
		}
	};

	const changePasswordForOauth2 = () => {
		userService.updateProfileViaExternalProvider('changePassword', user);
	};

	const changePasswordActionButtons: PageButton[] = [
		{
			name: 'changePassword.redirect.yesButton',
			isAction: true,
			visible: true,
			type: 'button',
			clickHandler: changePasswordForOauth2,
		},
		{
			name: 'changePassword.redirect.noButton',
			isAction: false,
			visible: true,
			type: 'button',
			clickHandler: () => setShowChangePasswordDialog(false),
		},
	];

	const removeConsentButtons: PageButton[] = [
		{
			name: 'removeConsent.redirect.yesButton',
			isAction: true,
			visible: true,
			type: 'button',
			clickHandler: removeConsent,
		},
		{
			name: 'removeConsent.redirect.noButton',
			isAction: false,
			visible: true,
			type: 'button',
			clickHandler: closeRemoveConsentDialog,
		},
	];

	const removeConsentFromIdx = () => {
		userService.removeConsentFromIdx('web').then(response => {
			if (response && response.logoutURL) {
				customNavigate(
					response.logoutURL,
					navigate,
					location,
					{},
					undefined,
					true,
				);
			}
		});
	};

	const removeConsentFromIdp = () => {
		userService.fetchRedirectUrl({}).then(response => {
			customNavigate(
				response.logoutURL,
				navigate,
				location,
				{},
				undefined,
				true,
			);
			customNavigate(
				global.ConfigurationHolder.urls.idmApplicationFrontEnd +
					'/idm/#' +
					RoutePathInfo.revokeConsent.path +
					'?callbackUri=' +
					encodeURIComponent(
						global.ConfigurationHolder.urls.applicationFrontEnd + '/#',
					) +
					global.ConfigurationHolder.defaultLandingPage,
				navigate,
				location,
				{},
				undefined,
				true,
			);
		});
	};

	useEffect(() => {
		let menuItems = encryptedStorageHelperInstance.getItemSync(
			sessionStorageKeys.MENU_ITEMS,
		);
		if (menuItems) {
			menuItems = [...menuItems, ...additionalNavItems];
			let finalMenuItems = [];
			for (var i = 0; i < menuItems.length; i++) {
				if (isAccessible(menuItems[i].role, user)) {
					finalMenuItems.push(menuItems[i]);
				}
			}
			setNavItems(finalMenuItems);
		}
		const defaultMenuItem = encryptedStorageHelperInstance.getItemSync(
			sessionStorageKeys.DEFAULT_MENU_ITEMS,
		);
		if (isValueAvailable(defaultMenuItem)) {
			setNavbarLogoDetails({
				path: defaultMenuItem,
				title: strings('pageTitle.mphrxLogo'),
				alt: strings('pageTitle.mphrxLogo'),
				src: mphrxLogo,
				className: '',
			});
		}

		const navItemsRightFinal: Array<Record<string, any>> = [];

		if (
			user &&
			encryptedStorageHelperInstance.getItemSync(sessionStorageKeys.AUTH_TOKEN)
		) {
			if (isNotAccessible(['ROLE_GUEST', 'ROLE_EXTERNAL_REPORT_VIEW'], user)) {
				navItemsRightFinal.push({
					path: '/notifications',
					name: 'header.notification',
					type: 'custom',
					component: NotificationDropdown,
				});
			}
		}
		if (
			global.ConfigurationHolder.showLanguageOptions &&
			isNotAccessible(['ROLE_GUEST'], user)
		) {
			navItemsRightFinal.push({
				type: 'custom',
				component: Localization,
			});
		}

		if (user != null && authToken != null) {
			if (isNotAccessible(['ROLE_GUEST', 'ROLE_EXTERNAL_REPORT_VIEW'], user)) {
				const infoIconSubMenuItems: Array<Record<string, any>> = [];
				infoIconSubMenuItems.push({
					path: '/inbox/helpDesk',
					name: 'header.helpDeskLinkText',
					type: 'link',
					role: ['ROLE_HELPDESK_QUERY'],
				});
				infoIconSubMenuItems.push({
					name: 'header.aboutUsLabel',
					type: 'link',
					clickHandler: () => {
						setShowAboutUs(true);
						setFocusId('nav-item_header.info');
					},
				});
				if (
					user &&
					user.userType === 'PATIENT' &&
					global.ConfigurationHolder.enableFaqsForPatient
				) {
					infoIconSubMenuItems.push({
						path: 'patient/faqs',
						name: 'header.faqLabel',
						type: 'link',
					});
				} else if (
					user &&
					user.userType === 'PRACTITIONER' &&
					global.ConfigurationHolder.enableFaqsForPractitioner
				) {
					infoIconSubMenuItems.push({
						path: '/phy/faqs',
						name: 'header.faqLabel',
						type: 'link',
					});
				} else if (
					user &&
					user.userType === 'OTHERS' &&
					global.ConfigurationHolder.enableFaqsForOthers
				) {
					infoIconSubMenuItems.push({
						path: '/oth/faqs/',
						name: 'header.faqLabel',
						type: 'link',
					});
				}
				if (readAgreements && readAgreements.length > 0) {
					readAgreements.map((agreement: Agreement) => {
						infoIconSubMenuItems.push({
							name:
								agreement &&
								agreement.contentResult &&
								agreement.contentResult.length > 0
									? agreement.contentResult[0].name
									: '',
							type: 'link',
							clickHandler: () => {
								setDialogContent(agreement);
								setShowDialog(true);
								setFocusId('nav-item_header.info');
							},
						});
					});
				}
				navItemsRightFinal.push({
					type: 'dropdown',
					name: 'header.info',
					className: 'fIcon icon-help2 rightNavIcon',
					subMenuItems: infoIconSubMenuItems,
				});
			}

			if (isNotAccessible(['ROLE_GUEST', 'ROLE_EXTERNAL_REPORT_VIEW'], user)) {
				const userSubMenuItems: Array<Record<string, any>> = [];
				if (serverConfig?.userHeaderTags?.bulkUploadStudiesLink?.visible) {
					userSubMenuItems.push({
						name: 'questionnaire.uploadFiles.bulkUploadStudiesLabel',
						type: 'link',
						clickHandler: () => {
							console.log('open openSkyWalkerModal');
						},
						role: ['ROLE_BULK_UPLOAD_DICOM'],
					});
				}
				if (
					serverConfig?.userHeaderTags?.updateProfileLink?.visible &&
					user &&
					user.patientId
				) {
					userSubMenuItems.push({
						path: `/patientProfile/view/${user.patientId}?bannerNav=false`,
						name: 'header.updateProfileLinkText',
						type: 'link',
					});
				}
				if (serverConfig?.userHeaderTags?.updateUserProfileLink?.visible) {
					userSubMenuItems.push({
						path: RoutePathInfo.viewUserProfile.path,
						name: 'header.updateUserProfileLinkText',
						type: 'link',
					});
				}
				if (
					serverConfig?.userHeaderTags?.deleteUserLink?.visible &&
					user &&
					user.userType === 'PATIENT'
				) {
					userSubMenuItems.push({
						path: '/userProfile/edit?tab=deleteAccount',
						name: 'header.deleteAccount',
						type: 'link',
					});
				}
				if (
					user &&
					user.authProvider &&
					isValueAvailable(serverConfig?.thirdPartyAllowedLogins) &&
					serverConfig.thirdPartyAllowedLogins?.[user.authProvider] &&
					Object.keys(serverConfig.thirdPartyAllowedLogins[user.authProvider])
						.length > 0 &&
					serverConfig.thirdPartyAllowedLogins[user.authProvider][
						'changePassword'
					] &&
					serverConfig?.userHeaderTags?.changePasswordExternalLink?.visible
				) {
					userSubMenuItems.push({
						name: 'header.changePasswordLinkText',
						type: 'link',
						clickHandler: changePasswordForExternalProvider,
					});
				}
				if (
					user &&
					user.authProvider === 'idx' &&
					serverConfig?.userHeaderTags?.deactivateLink?.visible
				) {
					userSubMenuItems.push({
						name: 'header.deactivateLinkText',
						type: 'link',
						clickHandler: () => {
							openDeactivateUserDialog();
						},
					});
				}
				if (
					user &&
					user.authProvider === 'idx' &&
					serverConfig?.userHeaderTags?.removeConsentLink?.visible
				) {
					userSubMenuItems.push({
						name: 'header.removeConsentLinkText',
						type: 'link',
						clickHandler: () => {
							openRemoveConsentDialog();
						},
					});
				}
				if (
					user &&
					!user.authProvider &&
					global.ConfigurationHolder.disabledUserTypeForChangePassword.indexOf(
						user.userType,
					) < 0 &&
					serverConfig?.userHeaderTags?.changePasswordLink?.visible &&
					(user.signUpSource === '' ||
						user.signUpSource === 'SELF_SIGN_UP_WEB' ||
						!user.signUpSource ||
						user.signUpSource === 'MINERVA')
				) {
					userSubMenuItems.push({
						path: '/userProfile/edit?tab=changePassword',
						name: 'header.changePasswordLinkText',
						type: 'link',
						role: ['ROLE_PASSWORD_CHANGE'],
					});
				}
				if (serverConfig?.userHeaderTags?.logoutLink?.visible) {
					userSubMenuItems.push({
						name: 'header.logoutLinkText',
						type: 'link',
						clickHandler: logout,
					});
				}
				navItemsRightFinal.push({
					name: displayUserID(user),
					type: 'dropdown',
					subMenuItems: userSubMenuItems,
				});
			}
		}

		for (let i = 0; i < navItemsRightFinal.length; i++) {
			if (
				navItemsRightFinal[i].role &&
				!isAccessible(navItemsRightFinal[i].role, user)
			) {
				navItemsRightFinal.splice(i, 1);
			}
			if (navItemsRightFinal[i].subMenuItems) {
				for (var j = 0; j < navItemsRightFinal[i].subMenuItems.length; j++) {
					if (
						navItemsRightFinal[i].subMenuItems[j].role &&
						!isAccessible(navItemsRightFinal[i].subMenuItems[j].role, user)
					) {
						navItemsRightFinal[i].subMenuItems.splice(j, 1);
					}
				}
			}
		}
		setNavItemsRight(navItemsRightFinal);
		setLoadDefaultHeaderStatus(true);
	}, [user, authToken, readAgreements, serverConfig]);

	return (
		<>
			{showLoader && <Loader />}
			{loadDefaultHeaderStatus && (
				<NavBar
					navbarLogoDetails={navbarLogoDetails}
					navItems={navItems}
					navItemsRight={navItemsRight}
					userLoggedIn={
						user &&
						encryptedStorageHelperInstance.getItemSync(
							sessionStorageKeys.AUTH_TOKEN,
						)
							? true
							: false
					}
					configData={global.ConfigurationHolder.navigationBar}
				></NavBar>
			)}
			<DialogBox
				showDialog={showDialog}
				toggleDialogBox={() => toggleDialog()}
				title={
					dialogContent?.contentResult &&
					dialogContent?.contentResult.length > 0 &&
					dialogContent?.contentResult[0].name
						? dialogContent?.contentResult[0].name
						: undefined
				}
				dialogSize={DialogBoxSizeEnum.large}
				showCloseIcon={true}
			>
				{dialogContent &&
					dialogContent.contentResult &&
					dialogContent.contentResult.length > 0 && (
						<div
							dangerouslySetInnerHTML={{
								__html: dialogContent.contentResult[0].content
									? dialogContent.contentResult[0].content
									: '',
							}}
						></div>
					)}
			</DialogBox>
			<div>
				{showDeactivateUserDialog && (
					<DialogBox
						title={strings('userProfile.deactivate.header')}
						showDialog={showDeactivateUserDialog}
						showCloseIcon={true}
						dialogSize={DialogBoxSizeEnum.medium}
						children={
							<DeactivateUserModal
								deactivateUserFormData={serverConfig?.deactivateUserFormData}
								closeDeactivateUserDialog={toggleDeactivateUserDialog}
								deactivateUser={deactivateUser}
							/>
						}
						toggleDialogBox={toggleDeactivateUserDialog}
					/>
				)}
			</div>
			<div>
				{showRemoveConsentDialog && (
					<DialogBox
						title={strings('removeConsent.redirect.ConfirmBoxTag')}
						showDialog={showRemoveConsentDialog}
						showCloseIcon={true}
						dialogSize={DialogBoxSizeEnum.medium}
						children={<RemoveConsent />}
						actionButtons={removeConsentButtons}
						toggleDialogBox={closeRemoveConsentDialog}
					></DialogBox>
				)}
			</div>
			<div>
				<DialogBox
					showCloseIcon={true}
					toggleDialogBox={() => {
						setShowAboutUs(false);
					}}
					showDialog={showAboutUs}
					title={strings('aboutUs.aboutUsLabel')}
					dialogSize={DialogBoxSizeEnum.large}
				>
					<AboutUsContent />
				</DialogBox>
			</div>
			<div>
				<DialogBox
					showCloseIcon={true}
					toggleDialogBox={() => setShowChangePasswordDialog(false)}
					showDialog={showChangePasswordDialog}
					title={strings('changePassword.redirect.title')}
					dialogSize={DialogBoxSizeEnum.medium}
					bodyText={strings('changePassword.redirect.bodyText')}
					actionButtons={changePasswordActionButtons}
				/>
			</div>
		</>
	);
}

export default DefaultHeader;
