import Label from './components/Label';
import HelpText from './components/HelpText';
import ErrorText from './components/ErrorText';
import {strings} from '../../../../../localization/i18n';
import React from 'react';
import {FieldType} from '../types/FieldTypeEnum';
import _ from 'lodash';

const RadioComponent = (props: any) => {
	const {
		fieldData,
		register,
		errors,
		callbackHandler,
		dynamicConstraints,
		configProvider,
		isViewOnlyMode,
	} = props;

	const {label, name, options, constraints, attributeCssClassName} = fieldData;

	const className = attributeCssClassName;

	return (
		<>
			<div
				id={`div_om_radioComp_${name}`}
				className={className ? `form-item ${className}` : 'form-item'}>
				<div id={`div_radioComp_${name}`} className="form-group">
					<div id={`div_im_radioComp_${name}`}>
						<Label
							className="form-check-inline-label"
							htmlFor={name}
							content={label}
							configProvider={configProvider}
							required={dynamicConstraints.required ?? false}
						/>
						<div className="form-check-inline-outer">
							{options.map((option: any, key: any) => (
								<React.Fragment key={key}>
									<div
										id={`div_input_radioComp_${name}+${key}`}
										className="form-check form-check-inline">
										<fieldset
											className="no-wrap"
											disabled={
												isViewOnlyMode === true
													? isViewOnlyMode
													: dynamicConstraints?.disabled ?? false
											}>
											<input
												{...register(`${name}`, {
													...constraints,
													required: {
														value: dynamicConstraints?.required ?? false,
														message: constraints?.required?.message ?? '',
													},
													...(callbackHandler?.onChange != null && {
														onChange: callbackHandler.onChange,
													}),
												})}
												type="radio"
												className="form-check-input"
												aria-describedby={`error_${name} ${name}_helpText_${name}`}
												id={`${name}_${option.value}_${key}`}
												name={name}
												value={option.value}
												defaultChecked={
													option.value === dynamicConstraints?.defaultValue
												}
											/>
											<label
												className="form-check-label preWrap"
												htmlFor={`${name}_${option.value}_${key}`}>
												{strings(option.label)}
											</label>
										</fieldset>
									</div>
								</React.Fragment>
							))}
						</div>
						{_.get(errors, name) == null && (
							<HelpText content={dynamicConstraints?.helpText} name={name} />
						)}
						{errors != null && (
							<ErrorText
								errorObject={_.get(errors, name)}
								name={name}
								label={label}
								fieldType={FieldType.OPTION}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default RadioComponent;
