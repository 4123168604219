import {Container, Service} from 'typedi';

import BasicSubscribersViewModel from '../../viewModel/BasicSubscribers.viewmodel';
import {OTPVerificationNavigationEvents} from '../enums/OTPVerificationNavigationEvents';
import NetworkCallProvider from '../../models/NetworkCallProvider';
import BaseRequestModel from '../../../../network/interface/BaseRequestModel';
import BaseResponseModel from '../../../../network/interface/BaseResponseModel';
import GenericNetworkCallRepository from '../../repository/GenericNetworkCall.repository';
import {LoaderType} from '../../models/Loader';
import {
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../models/NotificationMessage';
import {strings} from '../../../../../../../localization/i18n';

@Service()
export default class OTPVerificationViewModel extends BasicSubscribersViewModel<
	OTPVerificationNavigationEvents,
	Record<string, any>
> {
	private readonly repository: GenericNetworkCallRepository;
	private networkCallProviderForResend?: NetworkCallProvider<
		BaseRequestModel,
		BaseResponseModel,
		null
	>;
	private networkCallProviderForVerify?: NetworkCallProvider<
		BaseRequestModel,
		BaseResponseModel,
		string
	>;

	constructor() {
		super();
		this.repository = Container.get(GenericNetworkCallRepository);
	}

	/**
	 * This method is used to initialize the view model for a particular OTP verification instance.
	 * @param {NetworkCallProvider<
	 * 			BaseRequestModel,
	 * 			BaseResponseModel,
	 * 			null
	 * 		> | undefined} networkCallProviderForResend to provide API call details for resend
	 * @param  {NetworkCallProvider<
	 * 			BaseRequestModel,
	 * 			BaseResponseModel,
	 * 			null
	 * 		> | string} networkCallProviderForVerify to provide API call details for verify
	 */
	initialize(
		networkCallProviderForResend?: NetworkCallProvider<
			BaseRequestModel,
			BaseResponseModel,
			null
		>,
		networkCallProviderForVerify?: NetworkCallProvider<
			BaseRequestModel,
			BaseResponseModel,
			string
		>,
	) {
		this.networkCallProviderForResend = networkCallProviderForResend;
		this.networkCallProviderForVerify = networkCallProviderForVerify;
	}

	/**
	 * This method is called when user clicks on cancel for verifying OTP.
	 */
	onClickCancel(): void {
		this.navigationSubject.next({
			event: OTPVerificationNavigationEvents.CANCEL,
			additionalData: {},
		});
	}

	/**
	 * This method is used to send the API for verifying the OTP
	 * which is sent to user's email or mobile number
	 * @param {string} otp entered by the user
	 */
	onClickContinue(otp: string): void {
		if (this.networkCallProviderForVerify == null) {
			this.notificationMessageSubject.next({
				type: NotificationMessageType.toast,
				level: NotificationMessageLevel.error,
				visibility: NotificationVisibility.local,
				message: strings('errors.somethingWentWrong'),
			});
			return;
		}
		this.loaderSubject.next({
			isToShowLoader: true,
			type: LoaderType.OverScreen,
		});
		this.repository
			.callGenericAPI(
				this.networkCallProviderForVerify.requestGenerator(otp),
				this.networkCallProviderForVerify.url,
				this.networkCallProviderForVerify.headers,
				this.networkCallProviderForVerify.method,
			)
			.then(
				(response: {
					data: Record<string, any>;
					status: number;
					headers: Record<string, any>;
				}) => {
					// this.networkCallProviderForVerify null is checked on function start
					const parsedResponse =
						this.networkCallProviderForVerify!.responseParser(response);
					if (parsedResponse.isSuccessful) {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OverScreen,
						});
						this.navigationSubject.next({
							event: OTPVerificationNavigationEvents.CONTINUE,
							additionalData: {},
						});
						if (parsedResponse.successMessage != null) {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.success,
								visibility: NotificationVisibility.local,
								message: parsedResponse.successMessage,
							});
						}
					} else {
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
							visibility: NotificationVisibility.local,
							message:
								parsedResponse.errorMessage ??
								strings('errors.somethingWentWrong'),
						});
					}
				},
			)
			.catch(error => {
				console.log(
					'OTPVerificationViewModel.onClickContinue error',
					JSON.stringify(error, null, 4),
				);
				// this.networkCallProviderForVerify null is checked on function start
				const parsedResponse =
					this.networkCallProviderForVerify!.responseParser(error.response);
				this.notificationMessageSubject.next({
					type: NotificationMessageType.toast,
					level: NotificationMessageLevel.error,
					visibility: NotificationVisibility.local,
					message:
						parsedResponse.errorMessage ?? strings('errors.somethingWentWrong'),
				});
			})
			.finally(() => {
				this.loaderSubject.next({
					isToShowLoader: false,
					type: LoaderType.OverScreen,
				});
			});
	}

	/**
	 * This method is used to send the API for resending OTP to user's email or mobile number
	 */
	onClickResend(isInitial = false): void {
		if (this.networkCallProviderForResend == null) {
			this.notificationMessageSubject.next({
				type: NotificationMessageType.toast,
				level: NotificationMessageLevel.error,
				visibility: NotificationVisibility.local,
				message: strings('errors.somethingWentWrong'),
			});
			return;
		}
		this.loaderSubject.next({
			isToShowLoader: true,
			type: isInitial ? LoaderType.OnScreen : LoaderType.OverScreen,
		});
		this.repository
			.callGenericAPI(
				this.networkCallProviderForResend.requestGenerator(null),
				this.networkCallProviderForResend.url,
				this.networkCallProviderForResend.headers,
				this.networkCallProviderForResend.method,
			)
			.then(
				(response: {
					data: Record<string, any>;
					status: number;
					headers: Record<string, any>;
				}) => {
					// this.networkCallProviderForResend null is checked on function start
					const parsedResponse =
						this.networkCallProviderForResend!.responseParser(response);
					if (parsedResponse.isSuccessful) {
						if (parsedResponse.successMessage != null) {
							this.notificationMessageSubject.next({
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.success,
								visibility: NotificationVisibility.local,
								message: parsedResponse.successMessage,
							});
						}
					} else {
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
							visibility: NotificationVisibility.local,
							message:
								parsedResponse.errorMessage ??
								strings('errors.somethingWentWrong'),
						});
					}
				},
			)
			.catch(error => {
				console.log(
					'OTPVerificationViewModel.onClickResend error',
					JSON.stringify(error, null, 4),
				);
				// this.networkCallProviderForResend null is checked on function start
				const parsedResponse =
					this.networkCallProviderForResend!.responseParser(error.response);

				this.notificationMessageSubject.next({
					type: NotificationMessageType.toast,
					level: NotificationMessageLevel.error,
					visibility: NotificationVisibility.local,
					message:
						parsedResponse.errorMessage ?? strings('errors.somethingWentWrong'),
				});
			})
			.finally(() => {
				this.loaderSubject.next({
					isToShowLoader: false,
					type: isInitial ? LoaderType.OnScreen : LoaderType.OverScreen,
				});
			});
	}
}
