import {Container, Service} from 'typedi';
import UploaderNetworkRepository from '../networkRepository/UploaderNetworkRepository';
import {Observable, Subject, Subscriber} from 'rxjs';
import UploaderConfigResponseModel, {
	UploaderConfigResponse,
} from '../models/responseModel/UploaderConfigResponseModel';
import CheckInitialSpeedRequestModel, {
	CheckInitialSpeedData,
} from '../models/requestModel/CheckInitialSpeedRequestModel';
import EncounterSearchRequestModel, {
	EncounterSearchData,
} from '../models/requestModel/EncounterSearchRequestModel';
import OrganizationSearchRequestModel, {
	OrganizationSearchData,
} from '../models/requestModel/OrganizationSearchRequestModel';
import LocationSearchRequestModel, {
	LocationSearchRequestData,
} from '../models/requestModel/LocationSearchRequestModel';
import DocumentReferenceSearchCriteriaRequestModel, {
	DocumentReferenceSearchData,
} from '../models/requestModel/DocumentReferenceSearchCriteriaRequestModel';
import ConditionSearchrequestModel, {
	ConditionSearchRequestData,
} from '../models/requestModel/ConditionSearchRequestModel';
import CheckInitialSpeedValidationModel from '../models/validationModel/CheckInitialSpeedValidationModel';
import EncounterSearchValidationModel from '../models/validationModel/EncounterSearchValidationModel';
import OrganizationSearchValidationModel from '../models/validationModel/OrganizationSearchValidationModel';
import DocumentReferenceSearchCriteriaValidationModel from '../models/validationModel/DocumentReferenceSearchCriteriaValidationModel';
import LocationSearchValidationModel from '../models/validationModel/LocationSearchValidationModel';
import DocumentReferenceSearchResponseModel from '../models/responseModel/DocumentReferenceSearchResponseModel';
import ConditionSearchResponseModel from '../models/responseModel/ConditionSearchResponseModel';
import ConditionSearchValidationModel from '../models/validationModel/ConditionSearchValidationModel';
import UploadAndSaveDocumentResponseModel from '../models/responseModel/UploadAndSaveDocumentResponseModel';
import {strings} from '../../../../../../localization/i18n';
import BasicSubscribersViewModel from '../../generic/viewModel/BasicSubscribers.viewmodel';
import {UploadDocumentNavigationEvents} from '../enums/UploadDocumentNavigationEvents';
import UploaderHelperService from '../helper/UploaderHelper';
import {LoaderType} from '../../generic/models/Loader';
import {
	getUniqueElementsFromArrayList,
	isValueAvailable,
} from '../../../../utils/StringUtils';
import _ from 'lodash';
import ConditionSearchRequestModel from '../models/requestModel/ConditionSearchRequestModel';

import moment from 'moment';
import {
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../generic/models/NotificationMessage';
import {UploadDocEnums} from '../enums/UploadDocEnums';
import {generateDeferredPromise} from '../../../network/DeferredPromise';
import {getFormattedDate} from '../../../../utils/DateTimeUtils';

@Service()
class UploaderViewModel extends BasicSubscribersViewModel<
	UploadDocumentNavigationEvents,
	Record<string, any>
> {
	private uploaderNetworkRepository: UploaderNetworkRepository;
	private updateAllFileObject: Subject<Record<string, any>>;
	private validatorLoaderSubject: Subject<boolean>;
	private uploaderHelperService: UploaderHelperService;
	private uploaderModel: Record<string, any>;
	private uploaderModelSubject: Subject<Record<string, any>>;
	private uploaderConfigSubject: Subject<Record<string, any>>;
	private openFileOrFolderSelector: any;
	private config = global.ConfigurationHolder;

	constructor() {
		super();
		this.uploaderNetworkRepository = Container.get(UploaderNetworkRepository);
		this.validatorLoaderSubject = new Subject<boolean>();
		this.uploaderHelperService = Container.get(UploaderHelperService);
		this.updateAllFileObject = new Subject<Record<string, any>>();

		this.uploaderModel = {
			mainFileArray: [],
			patient: {},
			uploaderPageUIObject: {
				landingPage: true,
				showHint: true,
				bulkEditOptionOpened: true,
				hideProgressCounts: true,
			},
			bulkEditObject: {
				formObject: {
					location: '',
					encounter: '',
					physician: '',
					patientCondition: '',
					documentDateSelected: '',
					documentTypeSelected: '',
				},
				flagNameObject: {
					location: 'locationFlag',
					documentDateSelected: 'documentDateFlag',
					physician: 'physicianFlag',
				},
			},
			fileUploadOptions: {
				showBulkUpload: false,
				showUploader: true,
				showUploaderGuidelines: true,
				showUploadedFiles: true,
				showRejectedFiles: false,
				showFilePath: true,
				showFileType: true,
				showFileSize: true,
				showFilePreviewBtn: true,
				showFileEditBtn: true,
				showFileDeleteBtn: true,
				sourceType: 'physician',
				isMultiSelect: true,
			},
			uploaderConfigLoaded: false,
			uploadingObj: {
				uploadingFiles: false,
				listOfDicomFiles: {},
				listOfNonDicomFiles: [],
				nonDicomStudyLinkingObjectsList: [],
				cancelUploadStatus: false,
			},
			uploadPageObj: {
				fileTypeList: [],
				invalidFileNameCounter: 0,
				allowedFileTypeObjects: {dicom: [], nondicom: []},
			},
			uploadBatchObject: {
				settingNewBatchForUpload: false,
				currentUploadingNonDicomBatchIndexList: [],
				currentUploadingDicomBatchIndexList: [],
				dicomBatchList: [],
				nonDicomBatchesList: [],
				uploadedNonDicomBatchList: [],
				uploadedDicomBatchList: [],
			},
			timerObject: {
				sizeUploaded: 0,
				totalTimeTaken: 0,
				remainingTime: 0,
				formattedRemainingTime: 0,
				totalSize: 0,
				zeroStartTime: 0,
				avgSpeed: 0,
				lastUploadTime: 0,
			},

			counterObject: {
				scanned: 0,
				total: 0,
				validItems: 0,
				invalidItems: 0,
				uploaded: 0,
				errorItems: 0,
			},

			scanObj: {
				startCounter: 0,
				prevCounter: 0,
				lastIndexScanned: 0,
				timeoutIncrement: 1000,
				lastIndexRead: 0,
				cancelScan: false,
				showTitleBar: false,
			},

			editFormOpened: false,
			uploadedDocumentsObject: {},
			uploadedFiles: [],
			uploaderType: '',
			uploadDone: false,
		};
		this.uploaderModelSubject = new Subject<Record<string, any>>();
		this.uploaderConfigSubject = new Subject<Record<string, any>>();
	}

	/**
	 * uploads and save the document provided
	 * @returns {<UploadAndSaveDocumentResponseModel>}
	 */
	docUploadAndSave(
		docData: Record<string, any>,
		config: Record<string, any>,
	): Observable<UploadAndSaveDocumentResponseModel> {
		const uploadAndSaveResponse = new Observable(
			(subscriber: Subscriber<UploadAndSaveDocumentResponseModel>) => {
				const form_data = new FormData();
				for (const key in docData) {
					form_data.append(key, docData[key]);
				}
				this.uploaderNetworkRepository
					.uploadAndSaveDocument(form_data, config)
					.then(({data, headers, status}) => {
						const uploadAndSaveDocumentResponseModel: UploadAndSaveDocumentResponseModel =
							new UploadAndSaveDocumentResponseModel(data);
						subscriber.next(uploadAndSaveDocumentResponseModel);
						subscriber.complete();
					})
					.catch(error => {
						const uploadAndSaveDocumentResponseModel: UploadAndSaveDocumentResponseModel =
							new UploadAndSaveDocumentResponseModel(error);
						subscriber.error(uploadAndSaveDocumentResponseModel);
						this.showRetryUploadingMsg();
					});
			},
		);

		return uploadAndSaveResponse;
	}

	/**
	 * fetches necessary config for uploade document module
	 * @param {user}
	 * @returns {<UploaderConfigResponse>}
	 */
	fetchUploaderConfigs(
		user: Record<string, any>,
		actionContext?: string,
	): Observable<UploaderConfigResponse> {
		return new Observable((subscriber: Subscriber<UploaderConfigResponse>) => {
			this.uploaderNetworkRepository
				.fetchUploaderConfig()
				.then(({data, headers, status}) => {
					const response = data;
					const responseMap: Record<string, any> = {};
					responseMap.documentTypeList = response.documentType;
					responseMap.defaultConfig = response.defaultConfig;
					responseMap.defaultDocumentTypeKey = response.defaultDocumentTypeKey;
					this.uploaderHelperService.setDocumentType(
						responseMap.defaultDocumentTypeKey,
					);
					responseMap.doneDisabled = response.singleFileUpload;
					responseMap.locationTypeCategories = response.locationTypeCategories;
					responseMap.documentTypeLocationCategoryMap =
						response.documentTypeLocationCategoryMap;
					responseMap.supersetForAllowedFileTypes =
						this.createSupersetForAllowedFileTypes(responseMap.defaultConfig);
					responseMap.uploaderTypeMapWithUserType =
						response.uploaderTypeMapWithUserType;
					responseMap.bulkEditOptionViewMap = response.bulkEditOptionViewMap;
					responseMap.bulkEditEnabled = response.bulkEditEnabled;
					responseMap.showUploaderUsabilityHint =
						response.showUploaderUsabilityHint;
					responseMap.documentEditOnUploadEnabled =
						response.documentEditOnUploadEnabled;
					responseMap.editableFieldsOnDocumentUpload =
						response.editableFieldsOnDocumentUpload;
					responseMap.invalidFileNameCounter = 0;
					responseMap.allowedFileTypeObjects = {dicom: [], nondicom: []};
					responseMap.fileTypeList =
						this.uploaderHelperService.setFileTypesLists(
							'files',
							responseMap.defaultConfig,
							responseMap,
						);
					this.uploaderModel.uploadPageObj.fileTypeList =
						responseMap.fileTypeList;
					this.uploaderModel.uploadPageObj.allowedFileTypeObjects =
						responseMap.allowedFileTypeObjects;
					this.uploaderModel.documentTypeLocationCategoryMap =
						response.documentTypeLocationCategoryMap;
					this.uploaderModel.locationTypeCategories =
						response.locationTypeCategories;
					this.uploaderModel.uploaderTypeMapWithUserType =
						responseMap.uploaderTypeMapWithUserType;
					this.uploaderModel.bulkEditEnabled = responseMap.bulkEditEnabled;
					this.uploaderModel.bulkEditOptionViewMap =
						responseMap.bulkEditOptionViewMap;
					try {
						this.uploaderModel.uploaderScreenType =
							this.uploaderModel.uploaderTypeMapWithUserType[user?.userType];
						if (!this.uploaderModel.uploaderScreenType) {
							this.uploaderModel.uploaderScreenType = 'restrictedEditable';
						}
						this.uploaderModel.showEditableFields = true;
					} catch (ex) {
						this.uploaderModel.uploaderScreenType = 'restrictedEditable';
						this.uploaderModel.showEditableFields = true;
					}
					this.uploaderModel.uploaderConfigLoaded = true;
					this.uploaderModel.uploadPageObj.defaultConfig =
						responseMap.defaultConfig;
					this.setUploaderTypeAndActionText(user, actionContext);
					this.uploaderModelSubject.next(this.uploaderModel);
					const uploaderConfigResponseModel = new UploaderConfigResponseModel(
						response,
					);
					const uploaderConfigResponse: UploaderConfigResponse =
						uploaderConfigResponseModel.getResponseDataObject();
					this.uploaderConfigSubject.next(responseMap);
					subscriber.next(uploaderConfigResponse);
					subscriber.complete();
				})
				.catch(error => {
					const uploaderConfigResponseModel = new UploaderConfigResponseModel(
						error,
					);
					const uploaderConfigResponse: UploaderConfigResponse =
						uploaderConfigResponseModel.getResponseDataObject();
					subscriber.error(uploaderConfigResponse);
				});
		});
	}

	/**
	 * searches condition for document search
	 * @param {ConditionSearchrequestModel} searchCriteria
	 * @returns {<ConditionSearchResponseModel>}
	 */
	moConditionSearch(
		searchCriteria: ConditionSearchrequestModel,
	): Observable<ConditionSearchResponseModel | ConditionSearchValidationModel> {
		const moConditionSearchResponse = new Observable(
			(
				subscriber: Subscriber<
					ConditionSearchResponseModel | ConditionSearchValidationModel
				>,
			) => {
				const conditionSearchValidationModel: ConditionSearchValidationModel =
					this.validateConditionSearchrequestModel(searchCriteria);
				if (!conditionSearchValidationModel.isError) {
					this.uploaderNetworkRepository
						.moConditionSearch(searchCriteria)
						.then(({data, headers, status}) => {
							const conditionSearchResponseModel: ConditionSearchResponseModel =
								new ConditionSearchResponseModel(data);
							subscriber.next(conditionSearchResponseModel);
							subscriber.complete();
						})
						.catch(error => {
							const conditionSearchResponseModel: ConditionSearchResponseModel =
								new ConditionSearchResponseModel(error);
							subscriber.error(error);
						});
				} else {
					subscriber.next(conditionSearchValidationModel);
				}
			},
		);

		return moConditionSearchResponse;
	}

	/**
	 * validates condition search request model
	 * @param {ConditionSearchrequestModel} data
	 * @returns {ConditionSearchValidationModel}
	 */
	private validateConditionSearchrequestModel(
		data: ConditionSearchrequestModel,
	): ConditionSearchValidationModel {
		const conditionSearchValidationModel: ConditionSearchValidationModel =
			new ConditionSearchValidationModel();
		const conditionSearchrequestData: ConditionSearchRequestData =
			data.getRequestDataObject();
		if (!conditionSearchrequestData.constraints) {
			conditionSearchValidationModel.constraintsError = strings(
				'patientUploadRecords.errors.constraintsMissing',
			);
			conditionSearchValidationModel.isError = true;
		}
		return conditionSearchValidationModel;
	}

	/**
	 * searches document refrences
	 * @param {DocumentReferenceSearchData} searchCriteria
	 * @returns {<Record<string, any>>}
	 */
	docRefSearchData(
		searchCriteria: DocumentReferenceSearchData,
	): Observable<
		| DocumentReferenceSearchResponseModel
		| DocumentReferenceSearchCriteriaValidationModel
	> {
		const documentReferenceSearchCriteriaRequestModel: DocumentReferenceSearchCriteriaRequestModel =
			new DocumentReferenceSearchCriteriaRequestModel(searchCriteria);
		const docRefSearchDataResponse = new Observable(
			(
				subscriber: Subscriber<
					| DocumentReferenceSearchResponseModel
					| DocumentReferenceSearchCriteriaValidationModel
				>,
			) => {
				const docmentRefSearchRequestvalidation: DocumentReferenceSearchCriteriaValidationModel =
					this.validateDocmentRefSearchRequest(
						documentReferenceSearchCriteriaRequestModel,
					);
				if (!docmentRefSearchRequestvalidation.isError) {
					this.uploaderNetworkRepository
						.docRefSearchData(documentReferenceSearchCriteriaRequestModel)
						.then(({data, headers, status}) => {
							const documentReferenceSearchResponseModel: DocumentReferenceSearchResponseModel =
								new DocumentReferenceSearchResponseModel(data);
							subscriber.next(documentReferenceSearchResponseModel);
							subscriber.complete();
						})
						.catch(error => {
							const documentReferenceSearchResponseModel: DocumentReferenceSearchResponseModel =
								new DocumentReferenceSearchResponseModel(error);
							subscriber.error(documentReferenceSearchResponseModel);
						});
				} else {
					subscriber.next(docmentRefSearchRequestvalidation);
				}
			},
		);

		return docRefSearchDataResponse;
	}

	/**
	 * validate document reference searc request data
	 * @param {DocumentReferenceSearchCriteriaRequestModel} requestData
	 * @returns {DocumentReferenceSearchCriteriaValidationModel}
	 */
	validateDocmentRefSearchRequest(
		requestData: DocumentReferenceSearchCriteriaRequestModel,
	): DocumentReferenceSearchCriteriaValidationModel {
		const docRefSearchDataValidation =
			new DocumentReferenceSearchCriteriaValidationModel();
		const searchCriteria: DocumentReferenceSearchData =
			requestData.getRequestDataObject();

		if (!searchCriteria.constraints) {
			docRefSearchDataValidation.isError = true;
			docRefSearchDataValidation.constraintsError = strings(
				'patientUploadRecords.errors.constraintsMissing',
			);
		}

		return docRefSearchDataValidation;
	}

	/**
	 * searches location for filter
	 * @param {LocationSearchRequestModel} searchCriteria
	 * @returns {<Record<string, any>>}
	 */
	locationSearch(
		searchCriteria: LocationSearchRequestModel,
		toBeUpdateModelSubject?: boolean,
	): Observable<Record<string, any> | LocationSearchValidationModel> {
		const locationSearchResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | LocationSearchValidationModel
				>,
			) => {
				const locationSearchValidationModel: LocationSearchValidationModel =
					this.validateLocationSearchRequestModel(searchCriteria);
				if (!locationSearchValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.uploaderNetworkRepository
						.locationSearch(searchCriteria)
						.then(({data, headers, status}) => {
							if (data.list.length) {
								if (toBeUpdateModelSubject) {
									if (_.has(searchCriteria, 'typeIn')) {
										this.uploaderModel.originalLocationList = data.list;
									}
									const locationList = data.list.map(
										(location: Record<string, any>) => {
											return {label: location.name, value: location.id};
										},
									);
									this.uploaderModel.locationList = locationList;
									this.uploaderModelSubject.next(this.uploaderModel);
								}
							}
							subscriber.next(data);
							subscriber.complete();
						})
						.catch(error => {
							subscriber.error(error);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
							if (toBeUpdateModelSubject) {
								this.uploaderModelSubject.next(this.uploaderModel);
							}
						});
				} else {
					subscriber.next(locationSearchValidationModel);
				}
			},
		);

		return locationSearchResponse;
	}

	/**
	 * validate location search rquest data
	 * @param {LocationSearchRequestModel} data
	 * @returns {LocationSearchValidationModel}
	 */
	private validateLocationSearchRequestModel(
		data: LocationSearchRequestModel,
	): LocationSearchValidationModel {
		const locationSearchValidationModel: LocationSearchValidationModel =
			new LocationSearchValidationModel();
		const locationSearchRequestData: LocationSearchRequestData =
			data.getParsedvalues();
		if (!locationSearchRequestData.fields) {
			locationSearchValidationModel.fieldsError = strings(
				'patientUploadRecords.errors.fieldsMissing',
			);
			locationSearchValidationModel.isError = true;
		}
		// if (!locationSearchRequestData.typeIn) {
		// 	locationSearchValidationModel.typeInError = strings(
		// 		'patientUploadRecords.errors.typeInMissing',
		// 	);
		// 	locationSearchValidationModel.isError = true;
		// }
		return locationSearchValidationModel;
	}

	/**
	 * searches organizations
	 * @param {OrganizationSearchRequestModel} searchCriteria
	 * @returns {<Record<string, any>>}
	 */
	organizationSearch(
		searchCriteria: OrganizationSearchRequestModel,
	): Observable<Record<string, any> | OrganizationSearchValidationModel> {
		const organizationSearchResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | OrganizationSearchValidationModel
				>,
			) => {
				const organizationSearchValidationModel: OrganizationSearchValidationModel =
					this.validateOrganizationSearchRequestModel(searchCriteria);
				if (!organizationSearchValidationModel.isError) {
					this.uploaderNetworkRepository
						.organizationSearch(searchCriteria)
						.then(({data, headers, status}) => {
							subscriber.next(data);
							subscriber.complete();
						})
						.catch(error => {
							subscriber.error(error);
						});
				} else {
					subscriber.next(organizationSearchValidationModel);
				}
			},
		);

		return organizationSearchResponse;
	}

	/**
	 * validates the organization search request data
	 * @param {OrganizationSearchRequestModel} data
	 * @returns {OrganizationSearchValidationModel}
	 */
	private validateOrganizationSearchRequestModel(
		data: OrganizationSearchRequestModel,
	): OrganizationSearchValidationModel {
		const organizationSearchValidationModel: OrganizationSearchValidationModel =
			new OrganizationSearchValidationModel();
		const organizationSearchData: OrganizationSearchData = data.getParsedData();
		if (!organizationSearchData.fields) {
			organizationSearchValidationModel.fieldsError = strings(
				'patientUploadRecords.errors.fieldsMissing',
			);
			organizationSearchValidationModel.isError = true;
		}
		if (!organizationSearchData.nameStartWith) {
			organizationSearchValidationModel.nameStartWithError = strings(
				'patientUploadRecords.errors.nameStartWithMissing',
			);
			organizationSearchValidationModel.isError = true;
		}
		if (!organizationSearchData.count) {
			organizationSearchValidationModel.countError = strings(
				'patientUploadRecords.errors.countMissing',
			);
			organizationSearchValidationModel.isError = true;
		}
		return organizationSearchValidationModel;
	}

	/**
	 * searches for ecounters
	 * @param {EncounterSearchRequestModel} searchCriteria
	 * @returns {<Record<string, any> | EncounterSearchValidationModel>}
	 */
	encounterSearch(
		searchCriteria: EncounterSearchRequestModel,
	): Observable<Record<string, any> | EncounterSearchValidationModel> {
		const encounterSearchResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | EncounterSearchValidationModel
				>,
			) => {
				const encounterSearchValidationModel: EncounterSearchValidationModel =
					this.validateEncounterSearchRequestModel(searchCriteria);
				if (!encounterSearchValidationModel.isError) {
					this.loaderSubject.next({
						isToShowLoader: true,
						type: LoaderType.OnScreen,
					});
					this.uploaderNetworkRepository
						.encounterSearch(searchCriteria)
						.then(({data, headers, status}) => {
							subscriber.next(data);
							subscriber.complete();
						})
						.catch(error => {
							subscriber.error(error);
						})
						.finally(() => {
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OnScreen,
							});
						});
				} else {
					subscriber.next(encounterSearchValidationModel);
				}
			},
		);

		return encounterSearchResponse;
	}

	private validateEncounterSearchRequestModel(
		data: EncounterSearchRequestModel,
	): EncounterSearchValidationModel {
		const encounterSearchValidationModel: EncounterSearchValidationModel =
			new EncounterSearchValidationModel();
		const encounterSearchData: EncounterSearchData = data.getParsedDataObject();
		if (!encounterSearchData.patient) {
			encounterSearchValidationModel.patientError = strings(
				'patientUploadRecords.errors.patientMissing',
			);
			encounterSearchValidationModel.isError = true;
		}
		if (!encounterSearchData.field) {
			encounterSearchValidationModel.fieldError = strings(
				'patientUploadRecords.errors.fieldsMissing',
			);
			encounterSearchValidationModel.isError = true;
		}
		if (!encounterSearchData.statusNot) {
			encounterSearchValidationModel.statusNotError = strings(
				'patientUploadRecords.errors.statusNotMissing',
			);
			encounterSearchValidationModel.isError = true;
		}
		return encounterSearchValidationModel;
	}

	/**
	 * checks initialspeed
	 * @param {CheckInitialSpeedRequestModel} finalData
	 * @returns {<Record<string, any>>}
	 */
	checkInitialSpeed(
		finalData: CheckInitialSpeedRequestModel,
	): Observable<Record<string, any> | CheckInitialSpeedValidationModel> {
		const checkInitialSpeedResponse = new Observable(
			(
				subscriber: Subscriber<
					Record<string, any> | CheckInitialSpeedValidationModel
				>,
			) => {
				//const checkInitialSpeedValidationModel: CheckInitialSpeedValidationModel =
				//this.validateCheckInitialSpeedRequestModel(finalData);
				//if (!checkInitialSpeedValidationModel.isError) {
				this.uploaderNetworkRepository
					.checkInitialSpeed(finalData)
					.then(({data, headers, status}) => {
						subscriber.next(data);
						subscriber.complete();
					})
					.catch(error => {
						subscriber.error(error);
					});
				// } else {
				// 	subscriber.next(checkInitialSpeedValidationModel);
				// }
			},
		);

		return checkInitialSpeedResponse;
	}

	/**
	 * validates initial speed check request model
	 * @param {CheckInitialSpeedRequestModel} data
	 * @returns {CheckInitialSpeedValidationModel}
	 */
	private validateCheckInitialSpeedRequestModel(
		data: CheckInitialSpeedRequestModel,
	): CheckInitialSpeedValidationModel {
		const checkInitialSpeedValidationModel: CheckInitialSpeedValidationModel =
			new CheckInitialSpeedValidationModel();
		const checkInitialSpeedData: CheckInitialSpeedData =
			data.getRequestDataObject();
		if (!checkInitialSpeedData.myData) {
			checkInitialSpeedValidationModel.myDataError = strings(
				'patientUploadRecords.errors.myDataMissing',
			);
			checkInitialSpeedValidationModel.isError = true;
		}
		return checkInitialSpeedValidationModel;
	}

	/**
	 * setLabelsForUploaderItem
	 * @param {item}
	 * @param {documentType}
	 * @returns {Item.labelObject}
	 */
	setLabelsForUploaderItem(
		item: Record<string, any>,
		documentType?: string,
	): Record<string, any> {
		let documentTypeSelected;
		if (!documentType) {
			documentTypeSelected =
				item.formObjectTemp && item.formObjectTemp.documentTypeSelected
					? item.formObjectTemp.documentTypeSelected
					: item.formObject.documentTypeSelected;
		} else {
			documentTypeSelected = documentType;
		}
		item.labelObject = {};
		item.labelObject.locationFlag = true;
		item.labelObject.documentDateFlag = true;
		item.labelObject.physicianFlag = true;
		switch (documentTypeSelected) {
			case 'Prescriptions':
			case 'Prescription':
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.prescriptionDateLabelText',
				);
				break;
			case 'Discharge Note':
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.attendingPhysicianLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.dischargeDateLabelText',
				);
				break;
			case 'Laboratory Reports':
			case 'Laboratory Report':
			case 'Test Results':
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.labLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.orderedByLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.testDateLabelText',
				);
				break;

			case 'Radiology Reports':
			case 'Imaging Studies':
			case 'Radiology Report':
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.imagingCentreLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.orderedByLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.testDateLabelText',
				);
				break;
			case 'DICOM Images':
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.imagingCentreLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.orderedByLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.studyDateLabelText',
				);
				break;
			case "Doctor's Letter":
				item.labelObject.locationFlag = false;
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.letterDateLabelText',
				);
				break;

			case 'Referral Note':
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.referralDateLabelText',
				);
				break;
			case 'Consent Forms':
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.consentDateLabelText',
				);
				break;
			case 'Certificates / Letters':
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.letterDateLabelText',
				);
				break;
			case 'Bills / Invoice':
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.invoiceDateLabelText',
				);
				break;
			case 'Patient Image':
			case 'Questionnaire / Assessments':
			case 'Advance Directives':
			case 'Others':
			case 'Patient Images':
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.hospitalLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.createdDateLabelText',
				);
				break;
			case 'Insurance':
				item.labelObject.locationFlag = false;
				item.labelObject.documentDateFlag = false;
				item.labelObject.physicianFlag = false;
				break;
			default:
				item.labelObject.locationTagName = strings(
					'patientUploadRecords.tagsData.locationLabelText',
				);
				item.labelObject.physicianTagName = strings(
					'patientUploadRecords.tagsData.physicianLabelText',
				);
				item.labelObject.documentDateTagName = strings(
					'patientUploadRecords.tagsData.createdDateLabelText',
				);
		}
		return item.labelObject;
	}

	/**
	 * this method used to parse uploading files
	 * @param incomingQueue
	 * @param mainFileArray
	 */
	parseFiles = (
		incomingQueue: Array<File>,
		mainFileArray: Array<any>,
		counterObject: Record<string, any>,
		uploadedDocumentsObject: Array<Record<string, any>>,
		uploadingObj: Record<string, any>,
		uploadPageObj: Record<string, any>,
		scanObj: Record<string, any>,
		parseDicom: any,
	): Observable<Record<string, any>> => {
		const parsedFilesResponse = new Observable(
			(subscriber: Subscriber<Record<string, any>>) => {
				if (incomingQueue.length) {
					this.uploaderModel.uploadDone = false;

					try {
						counterObject.currentScannedItems = 0;
						if (!counterObject.uploaded && !counterObject.errorItems) {
							this.uploaderModel.uploadDone = false;
						}
						counterObject.currentTotalItems = incomingQueue.length;
						mainFileArray =
							this.uploaderHelperService.removeDuplicateFilesFromIncomingList(
								incomingQueue,
								mainFileArray,
								counterObject,
								uploadedDocumentsObject,
							);
						incomingQueue = [];
						counterObject.scanned += counterObject.currentScannedItems;
						counterObject.total += counterObject.currentTotalItems;
						const scanResponseObjectPromise =
							this.uploaderHelperService.parseAndFilterFiles(
								mainFileArray,
								counterObject,
								uploadingObj,
								uploadPageObj,
								scanObj,
								parseDicom,
							);
						scanResponseObjectPromise.subscribe(responseMap => {
							if (responseMap) {
								this.validatorLoaderSubject.next(false);
								this.uploaderModel.mainFileArray = mainFileArray;
								subscriber.next(responseMap);
							}
						});
					} catch (error) {
						console.log(error);
						this.validatorLoaderSubject.next(false);
					} finally {
						this.validatorLoaderSubject.next(false);
					}
				} else {
					subscriber.next({});
				}
			},
		);

		return parsedFilesResponse;
	};

	/**
	 * This method is used to get the observer where uploaded files are required to update
	 * returns Observable<Loader>
	 */
	fetchUpdateAllFileArrayObservable(): Observable<Record<string, any>> {
		return this.updateAllFileObject.asObservable();
	}

	/**
	 * clear all uploaded files from all type of files list
	 * @param {data}
	 */
	updateAllFileObjectInsideArray(data: Record<string, any>) {
		return this.updateAllFileObject.next(data);
	}

	fetchUploaderModelSubjectAsObservable(): Observable<Record<string, any>> {
		return this.uploaderModelSubject.asObservable();
	}

	fetchUploaderConfigSubjectAsObservable(): Observable<Record<string, any>> {
		return this.uploaderConfigSubject.asObservable();
	}

	createSupersetForAllowedFileTypes = (defaultConfigObject: any) => {
		const allowedUploadFolderExtensions =
			defaultConfigObject.allowedUploadFileExtensions;
		const allowedUploadFileExtensions =
			defaultConfigObject.allowedUploadFileExtensions;
		const allowedDropzoneExtensions =
			defaultConfigObject.allowedDropzoneExtensions;
		const uniqueElementsForAllowedFileTypes = getUniqueElementsFromArrayList([
			allowedDropzoneExtensions.dicom,
			allowedDropzoneExtensions.nonDicom,
			allowedUploadFileExtensions.dicom,
			allowedUploadFileExtensions.nonDicom,
			allowedUploadFolderExtensions.dicom,
			allowedUploadFolderExtensions.nonDicom,
		]);
		if (uniqueElementsForAllowedFileTypes.indexOf('') !== -1) {
			uniqueElementsForAllowedFileTypes.splice(
				uniqueElementsForAllowedFileTypes.indexOf(''),
				1,
			);
		}
		return uniqueElementsForAllowedFileTypes.join(', ');
	};

	fetchPatientActiveEncounters(
		patId: number,
	): Observable<Array<Record<string, any>>> {
		return new Observable(
			(subscriber: Subscriber<Array<Record<string, any>>>) => {
				const encounterSearchCriteria: EncounterSearchData = {
					field: 'period,location',
					patient: patId,
					statusNot: 'CANCELLED',
				};
				const encounterSearchRequestModel: EncounterSearchRequestModel =
					new EncounterSearchRequestModel(encounterSearchCriteria);
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OnScreen,
				});
				const deffered = generateDeferredPromise<void>();
				const encounterList: Array<Record<string, any>> = [];
				const promiseList: any = [];
				promiseList.push(deffered.promise);

				const promiseListForEachEncounter: any = [];

				this.encounterSearch(encounterSearchRequestModel).subscribe(
					(response: Record<string, any>) => {
						const tempList = response.list;
						tempList?.forEach(
							(encounter: Record<string, any>, index: number) => {
								const encounterDefer = generateDeferredPromise<void>();
								promiseListForEachEncounter.push(encounterDefer.promise);
								const encounterObj: Record<string, any> = {};
								encounterObj.id = encounter.id;
								encounterObj.startDate = encounter.period
									? encounter.period.startDate
										? getFormattedDate(
												new Date(encounter.period.startDate.value),
												this.config.dates.dateFormat,
										  )
										: ''
									: '';
								encounterObj.endDate = encounter.period
									? encounter.period.endDate
										? getFormattedDate(
												new Date(encounter.period.endDate.value),
												this.config.dates.dateFormat,
										  )
										: ''
									: '';
								if (encounter.location[0] && encounter.location[0].location) {
									const locationDeferred = generateDeferredPromise<void>();
									const loactionSearchCriteria: LocationSearchRequestData = {
										id: Number(encounter.location[0].location.id),
										fields: 'name',
									};
									const locationSearchRequestModel: LocationSearchRequestModel =
										new LocationSearchRequestModel(loactionSearchCriteria);
									this.locationSearch(
										locationSearchRequestModel,
										false,
									).subscribe((responseData: Record<string, any>) => {
										if (responseData.list.length) {
											const locationObject = responseData.list[0];
											if (locationObject && locationObject.name) {
												encounterObj.locationName = locationObject.name;
											}
											locationDeferred.resolve();
											encounterDefer.resolve();
											encounterList.push(encounterObj);
										}
									});
								} else {
									encounterDefer.resolve();
									encounterList.push(encounterObj);
								}
							},
						);
						Promise.all(promiseListForEachEncounter).then(response => {
							deffered.resolve();
						});
					},
				);
				Promise.all(promiseList)
					.then(resp => {
						const tempObj = encounterList.map(
							(encounter: Record<string, any>) => {
								return {
									label: `${encounter.startDate} ${
										encounter.locationName && encounter.startDate ? '-' : ''
									} ${encounter.locationName}`,
									value: encounter.id,
								};
							},
						);
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OnScreen,
						});
						subscriber.next(tempObj);
					})
					.catch(err => {
						console.log('some of promises are rejected');
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OnScreen,
						});
					});
			},
		);
	}

	/**
	 * @param {patId}
	 */
	fetchEncountersForPhysicianUpload = (patId: number) => {
		this.fetchPatientActiveEncounters(patId).subscribe(
			(response: Array<Record<string, any>>) => {
				if (response.length) {
					this.uploaderModel.encounterList = response;
					this.uploaderModelSubject.next(this.uploaderModel);
				}
			},
		);
	};

	/**
	 * @param {user}
	 * @param {patId}
	 * @param {config}
	 */
	fetchPatientConditionsForPhysicianUpload = (
		user: Record<string, any>,
		patId: number,
	) => {
		let searchCriteria: ConditionSearchRequestData;
		if (
			user &&
			user?.patientId == patId &&
			global.ConfigurationHolder?.showSelfRecordRequest
		) {
			searchCriteria = {
				constraints: {
					authParams: 'showSelf',
				},
			};
		} else {
			searchCriteria = {
				constraints: {
					subject: Number(patId),
				},
			};
		}
		const conditionSearchRequestModel: ConditionSearchRequestModel =
			new ConditionSearchRequestModel(searchCriteria);
		this.loaderSubject.next({
			isToShowLoader: true,
			type: LoaderType.OnScreen,
		});

		return this.moConditionSearch(conditionSearchRequestModel);
	};

	setPatientConditionsForPhysicianUpload = (data: Record<string, any>) => {
		let tempList = data.list;
		tempList = tempList?.map((item: Record<string, any>) => {
			return {label: item?.code?.text, value: item.id};
		});
		this.uploaderModel.conditionList = tempList;
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	clearBulkEditObject = () => {
		this.uploaderModel.bulkEditObject = {
			formObject: {
				location: '',
				encounter: '',
				physician: '',
				patientCondition: '',
				documentDateSelected: '',
				documentTypeSelected: '',
			},
			flagNameObject: {
				location: 'locationFlag',
				documentDateSelected: 'documentDateFlag',
				physician: 'physicianFlag',
			},
		};
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	openBulkEditOption = (opened: boolean) => {
		if (!opened) {
			this.clearBulkEditObject();
		}
		this.uploaderModel.uploaderPageUIObject.bulkEditOptionOpened = true;
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	closeBulkEditOption = () => {
		this.clearBulkEditObject();
		this.uploaderModel.uploaderPageUIObject.bulkEditOptionOpened = false;
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	toggleBulkEditOption = () => {
		this.clearBulkEditObject();
		this.uploaderModel.uploaderPageUIObject.bulkEditOptionOpened =
			!this.uploaderModel.uploaderPageUIObject.bulkEditOptionOpened;
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	updateBulkEditObject = (obj: Record<string, any>) => {
		this.uploaderModel.bulkEditObject = obj;
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	showUploader = (): boolean => {
		let show = true;
		if (
			this.uploaderModel?.uploadingObj?.listOfNonDicomFiles ||
			this.uploaderModel?.uploadingObj?.listOfDicomFiles
		) {
			if (
				this.uploaderModel?.uploadingObj?.listOfNonDicomFiles.length > 0 ||
				Object.keys(this.uploaderModel?.uploadingObj?.listOfDicomFiles).length >
					0
			) {
				show = false;
			}
		}
		return show;
	};

	clearUploadingObj = () => {
		this.uploaderModel.uploadingObj = {
			uploadingFiles: false,
			listOfDicomFiles: {},
			listOfNonDicomFiles: [],
			nonDicomStudyLinkingObjectsList: [],
			cancelUploadStatus: false,
		};
		this.uploaderModel.uploadingObj.listOfDicomFiles = {};
		this.uploaderModel.uploaderPageUIObject.landingPage = true;
		this.uploaderModel.uploaderPageUIObject.hideProgressCounts = true;
		this.uploaderModel.uploadingObj.listOfNonDicomFiles = [];
		this.uploaderModel.uploadedDocumentsObject = [];
		this.uploaderModel.mainFileArray = [];

		this.uploaderModel.counterObject = {
			scanned: 0,
			total: 0,
			validItems: 0,
			invalidItems: 0,
			uploaded: 0,
			errorItems: 0,
		};

		this.uploaderModel.timerObject = {
			sizeUploaded: 0,
			totalTimeTaken: 0,
			remainingTime: 0,
			formattedRemainingTime: 0,
			totalSize: 0,
			zeroStartTime: 0,
			avgSpeed: 0,
			lastUploadTime: 0,
		};

		this.uploaderModel.uploadBatchObject = {
			settingNewBatchForUpload: false,
			currentUploadingNonDicomBatchIndexList: [],
			currentUploadingDicomBatchIndexList: [],
			dicomBatchList: [],
			nonDicomBatchesList: [],
			uploadedNonDicomBatchList: [],
			uploadedDicomBatchList: [],
		};

		this.uploaderModel.scanObj = {
			startCounter: 0,
			prevCounter: 0,
			lastIndexScanned: 0,
			timeoutIncrement: 1000,
			lastIndexRead: 0,
			cancelScan: false,
			showTitleBar: false,
		};
		this.uploaderModel.netSpeed = 0;
		this.uploaderModel.timeRemaining = 0;
		this.uploaderModel.totalFileSize = 0;
		this.uploaderModel.zeroStartTime = 0;
		this.uploaderModel.sizeUploaded = 0;
		this.uploaderModel.avgSpeed = 0;
		this.uploaderModel.lastUploadTime = 0;
		this.uploaderModel.stopPoint = 1;
		this.uploaderModel.previousUploaded = 0;
		this.uploaderModel.sizeRemaining = '0 MB';
		this.uploaderModel.uploadingNonDcoms = false;
		this.uploaderModel.lastIndexNonDicomUploaded = 0;
		this.uploaderModel.uploadDone = false;
		this.uploaderModel.uploadPageObj.comment = '';
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	setUploadingFiles = (type: string, files: Array<any>) => {
		if (type === UploadDocEnums.DICOM) {
			this.uploaderModel.uploadingObj.listOfDicomFiles = files;
			this.uploaderModelSubject.next(this.uploaderModel);
		}
		if (type === UploadDocEnums.NON_DICOM) {
			this.uploaderModel.uploadingObj.listOfNonDicomFiles;
			this.uploaderModelSubject.next(this.uploaderModel);
		}
	};

	prepareBulkEditObjectData = (
		documentTypeSelected?: Record<string, any>,
		location?: Record<string, any>,
		documentDateSelected?: Date | null,
		physician?: Record<string, any>,
		encounter?: Record<string, any>,
		patientCondition?: Record<string, any>,
	) => {
		if (documentTypeSelected && documentTypeSelected['value']) {
			this.uploaderModel.bulkEditObject.formObject.documentTypeSelected =
				documentTypeSelected.value;
		}
		if (location && location['value']) {
			this.uploaderModel.bulkEditObject.formObject.location = location;
		}
		if (documentDateSelected != null) {
			this.uploaderModel.bulkEditObject.formObject.documentDateSelected =
				documentDateSelected;
		}
		if (physician && physician['value']) {
			this.uploaderModel.bulkEditObject.formObject.physician = physician;
		}
		if (encounter && encounter['value']) {
			this.uploaderModel.bulkEditObject.formObject.encounter = encounter;
		}
		if (patientCondition && patientCondition['value']) {
			this.uploaderModel.bulkEditObject.formObject.patientCondition =
				patientCondition;
		}
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	locationForLocationTypeFilter = (
		locationObjectList: Array<any>,
		documentTypeLocationCategoryMap: Record<string, any>,
		locationTypeCategories: Record<string, any>,
		documentType: string,
	) => {
		let returningList = [];
		if (!documentType) {
			returningList = locationObjectList;
		} else if (!locationObjectList) {
			returningList = [];
		} else {
			if (!documentTypeLocationCategoryMap || !locationTypeCategories) {
				returningList = locationObjectList;
			} else {
				const locationCategoriesList =
					documentTypeLocationCategoryMap[documentType];
				if (!locationCategoriesList || !locationCategoriesList.length) {
					returningList = [];
				} else {
					locationCategoriesList.forEach((locationCategory: string) => {
						const locationTypeList = locationTypeCategories[locationCategory];
						if (!locationTypeList || !locationTypeList.length) {
							returningList = [];
						} else {
							locationObjectList.forEach(function (locationObject) {
								const type =
									locationObject &&
									locationObject.type[0] &&
									locationObject.type[0].coding &&
									locationObject.type[0].coding[0].code
										? locationObject.type[0].coding[0].code
										: '';
								if (type) {
									locationTypeList.forEach(
										(locationType: Record<string, any>) => {
											if (locationType === type) {
												returningList.push(locationObject);
											}
										},
									);
								}
							});
						}
					});
				}
			}
		}
		return returningList;
	};

	createFormDataTempOfFileObject = (item: any, dicom: any) => {
		if (dicom) {
			if (this.uploaderModel.uploadingObj.listOfDicomFiles[item].formObject) {
				this.uploaderModel.uploadingObj.listOfDicomFiles[item].formObjectTemp =
					_.cloneDeep(
						this.uploaderModel.uploadingObj.listOfDicomFiles[item].formObject,
					);
			} else {
				this.uploaderModel.uploadingObj.listOfDicomFiles[item].formObjectTemp =
					{};
			}
			this.uploaderModelSubject.next({...this.uploaderModel});
		} else {
			const index =
				this.uploaderModel.uploadingObj.listOfNonDicomFiles.indexOf(item);
			if (
				this.uploaderModel.uploadingObj.listOfNonDicomFiles[index].formObject
			) {
				this.uploaderModel.uploadingObj.listOfNonDicomFiles[
					index
				].formObjectTemp = _.cloneDeep(
					this.uploaderModel.uploadingObj.listOfNonDicomFiles[index].formObject,
				);
			} else {
				this.uploaderModel.uploadingObj.listOfNonDicomFiles[
					index
				].formObjectTemp = {};
			}
			this.uploaderModelSubject.next({...this.uploaderModel});
		}
	};

	checkLocationAvailabilityForNonDicom = (
		nonDicom: any,
		location: Record<string, any>,
	) => {
		const allowedNonDicomLocationList = this.locationForLocationTypeFilter(
			this.uploaderModel.originalLocationList,
			this.uploaderModel.documentTypeLocationCategoryMap,
			this.uploaderModel.locationTypeCategories,
			nonDicom.formObject.documentTypeSelected,
		);
		let allowed = false;
		allowedNonDicomLocationList.forEach(locationInAllowedList => {
			if (locationInAllowedList.id === location.value) {
				allowed = true;
			}
		});
		return allowed;
	};

	isValidPastDate = (date: Date) => {
		if (!date) {
			return false;
		}
		const currentDate = moment().toDate();
		const dobObject = moment(date).toDate();
		if (currentDate.getTime() > dobObject.getTime()) {
			return true;
		} else {
			return false;
		}
	};

	validateUploadDate = (item: any, date: Date) => {
		if (item.formObjectTemp) {
			item.formObjectTemp.isValidUploadDate = this.isValidPastDate(date);
		} else {
			item.formObjectTemp = {
				isValidUploadDate: this.isValidPastDate(date),
			};
		}
		if (item.formObject) {
			item.formObject.isValidUploadDate = this.isValidPastDate(date);
		} else {
			item.formObject = {
				isValidUploadDate: this.isValidPastDate(date),
			};
		}
	};

	checkDicomStudiesUploaded = (studyObject: any, dicomBatchList: any) => {
		let uploaded = true;
		const batchIndexList = studyObject.batchIndexList;
		if (!batchIndexList || batchIndexList.length <= 0) {
			uploaded = false;
		} else if (!dicomBatchList) {
			uploaded = false;
		} else {
			batchIndexList.forEach((batchIndex: number) => {
				const batchObject = dicomBatchList[batchIndex];
				if (!batchObject.uploaded) {
					uploaded = false;
					return true;
				}
			});
		}
		return uploaded;
	};

	updateEditedFieldsInAllFileObjects = () => {
		for (const it in this.uploaderModel.bulkEditObject.formObject) {
			if (
				_.has(this.uploaderModel.bulkEditObject.formObject, it) &&
				this.uploaderModel.bulkEditObject.formObject[it]
			) {
				if (
					this.uploaderModel.uploadingObj.listOfNonDicomFiles &&
					this.uploaderModel.uploadingObj.listOfNonDicomFiles.length
				) {
					this.uploaderModel.uploadingObj.listOfNonDicomFiles.forEach(
						(nonDicomItem: any) => {
							if (!nonDicomItem.uploaded) {
								if (
									!this.uploaderModel.bulkEditObject.flagNameObject[it] ||
									nonDicomItem.labelObject[
										this.uploaderModel.bulkEditObject.flagNameObject[it]
									]
								) {
									if (it === 'location') {
										if (
											this.checkLocationAvailabilityForNonDicom(
												nonDicomItem,
												this.uploaderModel.bulkEditObject.formObject[it],
											)
										) {
											nonDicomItem.formObject[it] =
												this.uploaderModel.bulkEditObject.formObject[it];
										}
									} else {
										nonDicomItem.formObject[it] =
											this.uploaderModel.bulkEditObject.formObject[it];
									}
									if (it === 'documentDateSelected') {
										this.validateUploadDate(
											nonDicomItem,
											nonDicomItem.formObject[it],
										);
									}
									if (it === 'documentTypeSelected') {
										nonDicomItem.labelObject =
											this.uploaderModel.bulkEditObject.labelObject;
									}
								}
								this.createFormDataTempOfFileObject(nonDicomItem, false);
							}
						},
					);
				}
				if (
					this.uploaderModel.uploadingObj.listOfDicomFiles &&
					Object.keys(this.uploaderModel.uploadingObj.listOfDicomFiles).length >
						0
				) {
					for (const studyIuid in this.uploaderModel.uploadingObj
						.listOfDicomFiles) {
						if (
							_.has(
								this.uploaderModel.uploadingObj.listOfDicomFiles,
								studyIuid,
							) &&
							it !== 'documentDateSelected' &&
							it !== 'documentTypeSelected' &&
							!this.checkDicomStudiesUploaded(
								this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid],
								this.uploaderModel.uploadBatchObject.dicomBatchList,
							)
						) {
							if (it === 'location') {
								if (
									this.checkLocationAvailabilityForNonDicom(
										this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid],
										this.uploaderModel.bulkEditObject.formObject[it],
									)
								) {
									this.uploaderModel.uploadingObj.listOfDicomFiles[
										studyIuid
									].formObject[it] =
										this.uploaderModel.bulkEditObject.formObject[it];
								}
							} else {
								this.uploaderModel.uploadingObj.listOfDicomFiles[
									studyIuid
								].formObject[it] =
									this.uploaderModel.bulkEditObject.formObject[it];
								this.createFormDataTempOfFileObject(studyIuid, true);
							}
						}
					}
				}
			}
		}
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	showEditForm = (item: any, dicom: boolean) => {
		this.uploaderModel.editFormOpened = true;
		if (dicom) {
			this.uploaderModel.uploadingObj.listOfDicomFiles[item].editForm = true;
		} else {
			this.uploaderModel.uploadingObj.listOfNonDicomFiles[
				this.uploaderModel.uploadingObj.listOfNonDicomFiles.indexOf(item)
			].editForm = true;
		}
		this.createFormDataTempOfFileObject(item, dicom);
		this.uploaderModelSubject.next({...this.uploaderModel});
	};

	clearFormDataOfFileObject = (item: any, dicom: boolean) => {
		if (dicom) {
			this.uploaderModel.uploadingObj.listOfDicomFiles[item].formObjectTemp =
				{};
		} else {
			const index =
				this.uploaderModel.uploadingObj.listOfNonDicomFiles.indexOf(item);
			this.uploaderModel.uploadingObj.listOfNonDicomFiles[
				index
			].formObjectTemp = {};
			this.setLabelsForUploaderItem(
				item,
				item.formObject.documentTypeSelected.value,
			);
		}
		this.uploaderModelSubject.next({...this.uploaderModel});
	};

	hideEditForm = (item: any, dicom: boolean) => {
		if (dicom) {
			this.uploaderModel.uploadingObj.listOfDicomFiles[item].editForm = false;
		} else {
			this.uploaderModel.uploadingObj.listOfNonDicomFiles[
				this.uploaderModel.uploadingObj.listOfNonDicomFiles.indexOf(item)
			].editForm = false;
		}
		let allEditFormClosed = true;
		Object.keys(this.uploaderModel.uploadingObj.listOfDicomFiles).forEach(
			(dicomItemKey: any) => {
				if (
					this.uploaderModel.uploadingObj.listOfDicomFiles[dicomItemKey]
						.editForm
				) {
					allEditFormClosed = false;
					return true;
				}
			},
		);
		this.uploaderModel.uploadingObj.listOfNonDicomFiles.forEach(
			(nonDicomItem: any) => {
				if (nonDicomItem.editForm) {
					allEditFormClosed = false;
					return true;
				}
			},
		);

		this.uploaderModel.editFormOpened = !allEditFormClosed;
		this.clearFormDataOfFileObject(item, dicom);
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	updateLabelOfItem = (item: any, dicom: boolean) => {
		if (dicom) {
			const index =
				this.uploaderModel.uploadingObj.listOfDicomFiles.indexOf(item);
			this.uploaderModel.uploadingObj.listOfDicomFiles[index] = item;
			this.uploaderModelSubject.next({...this.uploaderModel});
		} else {
			const index =
				this.uploaderModel.uploadingObj.listOfNonDicomFiles.indexOf(item);
			this.uploaderModel.uploadingObj.listOfNonDicomFiles[index] = item;
			this.uploaderModelSubject.next({...this.uploaderModel});
		}
	};

	createFormObjectTempDataForAll = (dicom: false) => {
		if (dicom) {
			this.uploaderModel.uploadingObj.listOfDicomFiles.forEach((item: any) => {
				this.createFormDataTempOfFileObject(item, true);
			});
			this.uploaderModelSubject.next({...this.uploaderModel});
		} else {
			this.uploaderModel.uploadingObj.listOfNonDicomFiles.forEach(
				(item: any) => {
					this.createFormDataTempOfFileObject(item, false);
				},
			);
			this.uploaderModelSubject.next({...this.uploaderModel});
		}
	};

	saveFormDataToFileObject = (item: any, dicom: boolean) => {
		if (dicom) {
			this.uploaderModel.uploadingObj.listOfDicomFiles[item].formObject =
				_.cloneDeep(
					this.uploaderModel.uploadingObj.listOfDicomFiles[item].formObjectTemp,
				);
		} else {
			const index =
				this.uploaderModel.uploadingObj.listOfNonDicomFiles.indexOf(item);
			this.uploaderModel.uploadingObj.listOfNonDicomFiles[index].formObject =
				_.cloneDeep(
					this.uploaderModel.uploadingObj.listOfNonDicomFiles[index]
						.formObjectTemp,
				);
		}
		this.hideEditForm(item, dicom);
		this.uploaderModelSubject.next({...this.uploaderModel});
	};

	removeAllFiles = () => {
		this.uploaderModel.uploadingObj.listOfDicomFiles = {};
		this.uploaderModel.uploaderPageUIObject.landingPage = true;
		this.uploaderModel.uploaderPageUIObject.hideProgressCounts = true;
		this.uploaderModel.uploadingObj.listOfNonDicomFiles = [];
		this.uploaderModel.uploadedDocumentsObject = [];
		this.uploaderModel.mainFileArray = [];

		this.uploaderModel.counterObject = {
			scanned: 0,
			total: 0,
			validItems: 0,
			invalidItems: 0,
			uploaded: 0,
			errorItems: 0,
		};

		this.uploaderModel.timerObject = {
			sizeUploaded: 0,
			totalTimeTaken: 0,
			remainingTime: 0,
			formattedRemainingTime: 0,
			totalSize: 0,
			zeroStartTime: 0,
			avgSpeed: 0,
			lastUploadTime: 0,
		};

		this.uploaderModel.uploadBatchObject = {
			settingNewBatchForUpload: false,
			currentUploadingNonDicomBatchIndexList: [],
			currentUploadingDicomBatchIndexList: [],
			dicomBatchList: [],
			nonDicomBatchesList: [],
			uploadedNonDicomBatchList: [],
			uploadedDicomBatchList: [],
		};

		this.uploaderModel.scanObj = {
			startCounter: 0,
			prevCounter: 0,
			lastIndexScanned: 0,
			timeoutIncrement: 1000,
			lastIndexRead: 0,
			cancelScan: false,
			showTitleBar: false,
		};
		this.uploaderModel.netSpeed = 0;
		this.uploaderModel.timeRemaining = 0;
		this.uploaderModel.totalFileSize = 0;
		this.uploaderModel.zeroStartTime = 0;
		this.uploaderModel.sizeUploaded = 0;
		this.uploaderModel.avgSpeed = 0;
		this.uploaderModel.lastUploadTime = 0;
		this.uploaderModel.stopPoint = 1;
		this.uploaderModel.previousUploaded = 0;
		this.uploaderModel.sizeRemaining = '0 MB';
		this.uploaderModel.uploadingNonDcoms = false;
		this.uploaderModel.lastIndexNonDicomUploaded = 0;
		this.uploaderModel.uploadDone = false;
		this.uploaderModel.uploadPageObj.comment = '';
		this.openBulkEditOption(false);
	};

	/**
	 * click on Remove button in case of non-Dicom files,it removes files from UI and queue also..
	 * @param item
	 */
	removeNonDicomV2 = (item: any) => {
		this.uploaderModel.uploadingObj.listOfNonDicomFiles.splice(
			this.uploaderModel.uploadingObj.listOfNonDicomFiles.indexOf(item),
			1,
		);
		const batchIndex = item.batchIndex;
		if (batchIndex !== undefined) {
			const itemListOfRelatedBatch =
				this.uploaderModel.uploadBatchObject.nonDicomBatchesList[batchIndex]
					.itemList;
			itemListOfRelatedBatch.splice(itemListOfRelatedBatch.indexOf(item), 1);
			this.uploaderModel.uploadBatchObject.nonDicomBatchesList[batchIndex]
				.numberOfFiles--;
			this.uploaderModel.uploadBatchObject.nonDicomBatchesList[
				batchIndex
			].batchSize -= item.size;
			this.uploaderModel.timerObject.totalSize -= item.size;
		}
		this.uploaderModel.counterObject.validItems--;
		if (item.error) {
			this.uploaderModel.counterObject.errorItems--;
		}
		this.uploaderModel.mainFileArray.splice(
			this.uploaderModel.mainFileArray.indexOf(item),
			1,
		);
		if (
			Object.keys(this.uploaderModel.uploadingObj.listOfDicomFiles).length === 0
		) {
			this.uploaderModel.uploadingObj.listOfDicomFiles = {};
			if (
				!(
					this.uploaderModel.uploadedDocumentsObject &&
					this.uploaderModel.uploadedDocumentsObject.length
				) &&
				this.uploaderModel.uploadingObj.listOfNonDicomFiles.length === 0
			) {
				this.removeAllFiles();
			}
		}
		if (
			this.uploaderModel.counterObject.uploaded ===
			this.uploaderModel.counterObject.validItems
		) {
			this.uploaderModel.uploadDone = true;
		}
		this.uploaderModelSubject.next({...this.uploaderModel});
	};

	updateBatchIndexesInOtherStudies = (removingStudyIuid: any) => {
		for (const it in this.uploaderModel.uploadingObj.listOfDicomFiles) {
			if (
				_.has(this.uploaderModel.uploadingObj.listOfDicomFiles, it) &&
				removingStudyIuid !== it
			) {
				const studyObject =
					this.uploaderModel.uploadingObj.listOfDicomFiles[it];
				const removingStudyObject =
					this.uploaderModel.uploadingObj.listOfDicomFiles[removingStudyIuid];
				const removedBatchIndexList = removingStudyObject.batchIndexList;
				const batchIndexList = studyObject.batchIndexList;
				if (
					Math.max.apply(null, removedBatchIndexList) <
					Math.max.apply(null, batchIndexList)
				) {
					for (let i = 0; i < batchIndexList.length; i++) {
						batchIndexList[i] =
							batchIndexList[i] - removedBatchIndexList.length;
					}
					for (let i = 0; i < studyObject.instanceList.length; i++) {
						studyObject.instanceList[i].batchIndex -=
							removedBatchIndexList.length;
					}
				}
			}
		}
	};

	updateNonDicomStudyLinkingObjectsList = () => {
		this.uploaderModel.uploadingObj.nonDicomStudyLinkingObjectsList = [];
		for (const studyIuid in this.uploaderModel.uploadingObj.listOfDicomFiles) {
			if (_.has(this.uploaderModel.uploadingObj.listOfDicomFiles, studyIuid)) {
				const studyObject =
					this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid];
				if (studyObject.sendToServer) {
					this.uploaderModel.uploadingObj.nonDicomStudyLinkingObjectsList.push(
						studyObject,
					);
				}
			}
		}
	};

	removeStudyV2 = (studyIuid: any) => {
		this.uploaderModel.uploadingObj.listOfDicomFiles[
			studyIuid
		].instanceList.forEach((instanceObject: any) => {
			this.uploaderModel.mainFileArray.splice(
				this.uploaderModel.mainFileArray.indexOf(instanceObject),
				1,
			);
			this.uploaderModel.counterObject.validItems--;
			if (instanceObject.error) {
				this.uploaderModel.counterObject.errorItems--;
			} else if (instanceObject.uploaded) {
				this.uploaderModel.counterObject.uploaded--;
			}
		});
		if (
			this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid].sendToServer
		) {
			const batchIndexList =
				this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid]
					.batchIndexList;
			if (batchIndexList && batchIndexList.length > 0) {
				batchIndexList.forEach((batchIndex: any) => {
					this.uploaderModel.uploadBatchObject.dicomBatchList[batchIndex] =
						null;
				});
				for (
					let i = 0;
					i < this.uploaderModel.uploadBatchObject.dicomBatchList.length;
					i++
				) {
					if (this.uploaderModel.uploadBatchObject.dicomBatchList[i] === null) {
						this.uploaderModel.uploadBatchObject.dicomBatchList.splice(i, 1);
						i--;
					}
				}
				this.uploaderModel.timerObject.totalSize -=
					this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid].studySize;
				this.updateBatchIndexesInOtherStudies(studyIuid);
			}
		}

		delete this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid];
		if (
			Object.keys(this.uploaderModel.uploadingObj.listOfDicomFiles).length === 0
		) {
			this.uploaderModel.uploadingObj.listOfDicomFiles = {};
			if (
				!(
					this.uploaderModel.uploadedDocumentsObject &&
					this.uploaderModel.uploadedDocumentsObject.length
				) &&
				this.uploaderModel.uploadingObj.listOfNonDicomFiles.length === 0
			) {
				this.removeAllFiles();
			}
		}
		this.updateNonDicomStudyLinkingObjectsList();
		this.uploaderModel.uploadingObj.listOfNonDicomFiles.forEach(
			(nonDicomObject: any) => {
				if (
					nonDicomObject.formObject &&
					nonDicomObject.formObject.study &&
					nonDicomObject.formObject.study.studyIuid === studyIuid
				) {
					nonDicomObject.formObject.study = null;
				}
				if (
					nonDicomObject.formObjectTemp &&
					nonDicomObject.formObjectTemp.study &&
					nonDicomObject.formObjectTemp.study.studyIuid === studyIuid
				) {
					nonDicomObject.formObjectTemp.study = null;
				}
			},
		);

		if (
			this.uploaderModel.counterObject.uploaded ===
			this.uploaderModel.counterObject.validItems
		) {
			this.uploaderModel.uploadDone = true;
		}
		this.uploaderModelSubject.next({...this.uploaderModel});
	};

	removeInstanceFromStudy = (instance: any, studyObject: any) => {
		this.uploaderModel.mainFileArray.splice(
			this.uploaderModel.mainFileArray.indexOf(instance),
			1,
		);
		this.uploaderModel.counterObject.validItems--;
		const batchIndex = instance.batchIndex;
		this.uploaderModel.uploadBatchObject.dicomBatchList.splice(batchIndex, 1);
		studyObject.instanceList.splice(
			studyObject.instanceList.indexOf(instance),
			1,
		);
		studyObject.noOfInstances--;
		studyObject.studySize -= instance.size;
		if (studyObject.instanceList.length === 0) {
			this.removeStudyV2(instance.studyIuid);
		}
	};

	removeInvalidItemsFromList = (invalidFileList: Array<any>) => {
		if (invalidFileList.length > 0) {
			this.notificationMessageSubject.next({
				type: NotificationMessageType.toast,
				level: NotificationMessageLevel.error,
				message: strings(
					'physicianDocuments.list.alert.serverInvalidFilesError',
				),
				visibility: NotificationVisibility.global,
				documentIdToFocus: 'documentListingTitle',
			});
		}
		this.uploaderModel.counterObject.invalidItems += invalidFileList.length;
		for (
			let i = 0;
			i < this.uploaderModel.uploadingObj.listOfNonDicomFiles.length;
			i++
		) {
			if (this.uploaderModel.uploadingObj.listOfNonDicomFiles[i].invalid) {
				this.removeNonDicomV2(
					this.uploaderModel.uploadingObj.listOfNonDicomFiles[i],
				);
				i--;
			}
		}
		for (const studyId in this.uploaderModel.uploadingObj.listOfDicomFiles) {
			if (_.has(this.uploaderModel.uploadingObj.listOfDicomFiles, studyId)) {
				const currentStudy =
					this.uploaderModel.uploadingObj.listOfDicomFiles[studyId];
				for (
					let instanceIndex = 0;
					instanceIndex < currentStudy.instanceList.length;
					instanceIndex++
				) {
					if (currentStudy.instanceList[instanceIndex].invalid) {
						this.removeInstanceFromStudy(
							currentStudy.instanceList[instanceIndex],
							currentStudy,
						);
						//i--;
					}
				}
			}
		}
	};

	handleCounterObjectForErrorFileList = (errorFileList: any) => {
		this.uploaderModel.counterObject.errorItems += errorFileList.length;
	};

	/**
	 * used to start file uploading prcess
	 * @param startUploadInBatch
	 */
	startUploadInBatch = (cancelUploadMap: Record<string, any>) => {
		if (
			!isValueAvailable(this.uploaderModel.uploadingObj.listOfDicomFiles) &&
			!this.uploaderHelperService.checkAtLeastOneValidNonDicomToBeUploaded(
				this.uploaderModel.uploadingObj.listOfNonDicomFiles,
			) &&
			!this.uploaderHelperService.checkAtLeastOneValidStudyToBeUploaded(
				this.uploaderModel.uploadingObj.listOfDicomFiles,
				this.uploaderModel.uploadBatchObject.dicomBatchList,
			)
		) {
			this.notificationMessageSubject.next({
				type: NotificationMessageType.toast,
				level: NotificationMessageLevel.error,
				message: strings('physicianDocuments.list.alert.accessibilityError'),
				visibility: NotificationVisibility.global,
				documentIdToFocus: 'documentListingTitle',
			});
		}
		this.uploaderModel.timerObject.sizeUploaded = 0;
		this.uploaderModel.timerObject.lastUploadTime = 0;
		this.uploaderModel.uploadDone = false;
		this.uploaderModel.uploadingObj.uploadingFiles = true;
		this.uploaderModel.responseObject = {
			successFileList: [],
			errorFileList: [],
			invalidFileList: [],
		};
		this.updateUploaderModelSubject();
		if (this.uploaderModel.uploaderType === 'questionnaire') {
			this.uploaderModel.uploadingObj.setPatientToActive = true;
			const questionnaireUpdatePromise =
				this.uploaderModel.startQuestionnaireUpload();
			questionnaireUpdatePromise.then((response: any) => {
				const initialSpeedResponse = this.checkInitialSpeed(
					this.uploaderModel.timerObject,
				);
				//const tokenRefreshVar = setupTokenRefreshCall()
				initialSpeedResponse.subscribe(responseObject => {
					const uploadResponsePromise =
						this.uploaderHelperService.startUploadInBatch(
							this.uploaderModel.uploadingObj,
							this.uploaderModel.uploadBatchObject,
							this.uploaderModel.counterObject,
							this.uploaderModel.timerObject,
							this,
							cancelUploadMap,
						);
					uploadResponsePromise.then(response => {
						if (response && response[0]) {
							if (response[0].documentListMap) {
								this.uploaderModel.responseObject = response[0].documentListMap;
								this.removeInvalidItemsFromList(
									this.uploaderModel.responseObject.invalidFileAliasList,
								);
								this.handleCounterObjectForErrorFileList(
									this.uploaderModel.responseObject.errorFileAliasList,
								);
							} else {
								this.uploaderModel.responseObject = response[0];
								this.removeInvalidItemsFromList(
									this.uploaderModel.responseObject.invalidFileList,
								);
								this.handleCounterObjectForErrorFileList(
									this.uploaderModel.responseObject.errorFileList,
								);
							}
						}
						this.uploaderModel.uploadingObj.uploadingFiles = false;
						//clearInterval(tokenRefreshVar);
						if (
							this.uploaderModel.doneDisabled &&
							this.uploaderHelperService.getUploaderType() ===
								'questionnaire' &&
							this.uploaderModel.counterObject.uploaded < 1
						) {
							this.uploaderModel.uploadDone = false;
						} else {
							this.uploaderModel.uploadDone = true;
						}
					});
				});
			});
		} else {
			const initialSpeedResponse = this.checkInitialSpeed(
				this.uploaderModel.timerObject,
			);
			initialSpeedResponse.subscribe((responseObject: Record<string, any>) => {
				const uploadResponsePromise =
					this.uploaderHelperService.startUploadInBatch(
						this.uploaderModel.uploadingObj,
						this.uploaderModel.uploadBatchObject,
						this.uploaderModel.counterObject,
						this.uploaderModel.timerObject,
						this,
						cancelUploadMap,
					);
				uploadResponsePromise.then((response: any) => {
					if (response && response[0]) {
						if (response[0].documentListMap) {
							this.uploaderModel.responseObject = response[0].documentListMap;
							this.removeInvalidItemsFromList(
								this.uploaderModel.responseObject.invalidFileAliasList,
							);
							this.handleCounterObjectForErrorFileList(
								this.uploaderModel.responseObject.errorFileAliasList,
							);
						} else {
							this.uploaderModel.responseObject = response[0];
							this.removeInvalidItemsFromList(
								this.uploaderModel.responseObject.invalidFileList,
							);
							this.handleCounterObjectForErrorFileList(
								this.uploaderModel.responseObject.errorFileList,
							);
						}
					}
					this.uploaderModel.uploadingObj.uploadingFiles = false;
					if (
						this.uploaderModel.doneDisabled &&
						this.uploaderModel.uploaderType === 'questionnaire' &&
						this.uploaderModel.counterObject.uploaded < 1
					) {
						this.uploaderModel.uploadDone = false;
					} else {
						this.uploaderModel.uploadDone = true;
					}
					this.uploaderModelSubject.next(this.uploaderModel);
				});
			});
		}
	};

	setUploaderTypeAndActionText = (user: any, actionContext?: string) => {
		let uploaderType = this.uploaderModel.uploaderType;
		uploaderType = user.userType === 'PATIENT' ? 'patient' : 'physician';
		this.uploaderModel.uploaderType = uploaderType;
		this.uploaderHelperService.setUploaderType(uploaderType);
		if (actionContext) {
			this.uploaderHelperService.setActionContext(actionContext);
		}
		this.uploaderModelSubject.next(this.uploaderModel);
	};

	calculateDicomFileSize = (size: number): string | undefined => {
		let result: string | undefined;
		if (size / 1024 < 1.0)
			result =
				parseFloat((Math.round(size * 100) / 100).toString()).toFixed(2) +
				' Bytes';
		else if (size / 1024 / 1024 < 1.0)
			result =
				parseFloat((Math.round((size / 1024) * 100) / 100).toString()).toFixed(
					2,
				) + ' KB';
		else if (size / 1024 / 1024 / 1024 < 1.0)
			result =
				parseFloat(
					(Math.round((size / 1024 / 1024) * 100) / 100).toString(),
				).toFixed(2) + ' MB';
		else if (size / 1024 / 1024 / 1024 / 1024 < 1.0)
			result =
				parseFloat(
					(Math.round((size / 1024 / 1024 / 1024) * 100) / 100).toString(),
				).toFixed(2) + ' GB';
		return result;
	};

	removeNotUploadedBatches = () => {
		for (
			let i = 0;
			i < this.uploaderModel.uploadBatchObject.nonDicomBatchesList.length;
			i++
		) {
			const nonDicomBatch =
				this.uploaderModel.uploadBatchObject.nonDicomBatchesList[i];
			if (!nonDicomBatch.uploaded || nonDicomBatch.error) {
				nonDicomBatch.itemList.forEach((nonDicomItem: any) => {
					nonDicomItem.batchIndex = undefined;
				});
				this.uploaderModel.uploadBatchObject.uploadedNonDicomBatchList.splice(
					this.uploaderModel.uploadBatchObject.uploadedNonDicomBatchList.indexOf(
						i,
					),
					1,
				);
				this.uploaderModel.uploadBatchObject.nonDicomBatchesList.splice(i, 1);
				i--;
			}
		}
		for (
			let i = 0;
			i < this.uploaderModel.uploadBatchObject.dicomBatchList.length;
			i++
		) {
			const dicomBatch = this.uploaderModel.uploadBatchObject.dicomBatchList[i];
			if (!dicomBatch.uploaded || dicomBatch.error) {
				const studyIuid = dicomBatch.itemList[0].studyIuid;
				const studyObject =
					this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid];
				studyObject.batchIndexList.splice(
					studyObject.batchIndexList.indexOf(i),
					1,
				);
				dicomBatch.itemList.forEach((dicomInstance: any) => {
					dicomInstance.batchIndex = undefined;
				});
				this.uploaderModel.uploadBatchObject.uploadedDicomBatchList.splice(
					this.uploaderModel.uploadBatchObject.uploadedDicomBatchList.indexOf(
						i,
					),
					1,
				);
				this.uploaderModel.uploadBatchObject.dicomBatchList.splice(i, 1);
				i--;
			}
		}
	};

	revertAddedFiles = () => {
		for (
			let i = 0;
			i < this.uploaderModel.uploadingObj.listOfNonDicomFiles.length;
			i++
		) {
			if (!this.uploaderModel.uploadingObj.listOfNonDicomFiles[i].batchIndex) {
				this.removeNonDicomV2(
					this.uploaderModel.uploadingObj.listOfNonDicomFiles[i],
				);
				i--;
			}
		}
		for (const it in this.uploaderModel.uploadingObj.listOfDicomFiles) {
			if (_.has(this.uploaderModel.uploadingObj.listOfDicomFiles, it)) {
				const studyObject =
					this.uploaderModel.uploadingObj.listOfDicomFiles[it];
				if (!studyObject.scannedForBatchUpload) {
					this.removeStudyV2(it);
				} else {
					for (let i = 0; i < studyObject.instanceList.length; i++) {
						if (!studyObject.instanceList[i].scannedForBatchUpload) {
							this.removeInstanceFromStudy(
								studyObject.instanceList[i],
								studyObject,
							);
							i--;
						}
					}
				}
			}
		}
		if (this.uploaderModel.counterObject.validItems === 0) {
			this.removeAllFiles();
		}
	};

	createBatchesToUpload = (cancelUploadMap: Record<string, any>) => {
		if (this.uploaderModel.scanObj.cancelScan) {
			this.revertAddedFiles();
			//$('#blockingProgressModal').hide()
			this.uploaderModel.scanObj.cancelScan = false;
		} else {
			//$('#blockingProgressModal').hide();
			if (
				this.uploaderModel.counterObject.invalidItems ===
				this.uploaderModel.counterObject.total
			) {
				this.notificationMessageSubject.next({
					type: NotificationMessageType.toast,
					level: NotificationMessageLevel.error,
					message: strings(
						'physicianDocuments.list.alert.allInvalidFilesError',
					),
					visibility: NotificationVisibility.global,
					documentIdToFocus: 'documentListingTitle',
				});
				this.removeAllFiles();
			} else {
				this.uploaderModel.uploaderPageUIObject.hideProgressCounts = false;
				this.updateNonDicomStudyLinkingObjectsList();
				//this.uploaderService.onAfterAddingAll(this.uploaderModel.);
				this.removeNotUploadedBatches();
				this.uploaderModel.counterObject.errorItems = 0;
				this.uploaderModel.timerObject.totalSize = 0;
				const batchResponseObject =
					this.uploaderHelperService.createBatchesToUpload(
						this.uploaderModel.uploadingObj,
						this.uploaderModel.uploadBatchObject,
						this.uploaderModel.timerObject,
					);
				if (this.uploaderModel?.uploadPageObj?.defaultConfig?.autoUpload) {
					this.startUploadInBatch(cancelUploadMap);
				}
			}
		}
	};
	generateUploadingUniqueId = (
		patientId: number,
		patientData: Record<string, any>,
		inputType: string,
	): Observable<void> => {
		return new Observable((subsriber: Subscriber<void>) => {
			this.uploaderModel.preparingScanning = true;
			this.uploaderModel.uploadingObj.uploadingUniqueId = moment(
				new Date(),
			).format('yyyyMMDDhhmmss');
			this.uploaderModel.patientinfo = patientData;
			this.uploaderModel.uploadingObj.patient = patientData;
			this.uploaderModel.uploadingObj.patientId = patientId;
			if (this.uploaderHelperService.getUploaderType() === 'questionnaire') {
				this.uploaderModel.uploadingObj.checkAnonymous = true;
			}
			if (this.uploaderHelperService.getUploaderType() === 'questionnaire') {
				this.uploaderModel.uploadingObj.applicationId =
					this.uploaderModel.questionnaireResponse.applicationId;
			}
			console.log(
				'uploadingUniqueId: ' +
					this.uploaderModel.uploadingObj.uploadingUniqueId,
			);
			this.uploaderModel.scanObj.timeoutIncrement = 1000;
			this.uploaderModel.scanObj.lastIndexScanned = 0;

			this.uploaderModel.scanObj.startCounter = 0;
			this.uploaderModel.scanObj.prevCounter = 0;
			this.uploaderModel.scanObj.cancelScan = false;
			this.uploaderModel.uploadPageObj.currentInputType = inputType;
			this.uploaderModel.customdetailsmap = null;
			this.uploaderModelSubject.next(this.uploaderModel);
			subsriber.next();
		});
	};

	fetchUploaderValidator = (): Observable<boolean> => {
		return this.validatorLoaderSubject.asObservable();
	};

	checkNonDicomFileProgress = (
		item: any,
		nonDicomBatchesList: Array<Record<string, any>>,
	) => {
		const batchIndex = item.batchIndex;
		if (!nonDicomBatchesList) {
			return 0;
		}
		if (batchIndex === undefined) {
			return 0;
		}
		const batchObject = nonDicomBatchesList[batchIndex];
		if (!batchObject) {
			return 0;
		}
		return batchObject.progress;
	};

	checkDicomStudiesProgress = (
		studyObject: Record<string, any>,
		dicomBatchList: any,
	) => {
		let progress = 0;
		const batchIndexList = studyObject.batchIndexList;
		if (!batchIndexList || batchIndexList.length <= 0) {
			return 0;
		}
		if (!dicomBatchList) {
			return 0;
		}
		batchIndexList.forEach((batchIndex: number) => {
			const batchObject = dicomBatchList[batchIndex];
			if (batchObject) {
				const batchSize = batchObject.batchSize;
				const batchSizePercent = batchSize / studyObject.studySize;
				if (batchSizePercent && batchObject.progress) {
					progress += batchSizePercent * batchObject.progress;
				}
			}
		});
		return progress;
	};

	updateUploaderModelSubject = () => {
		this.uploaderModelSubject.next({...this.uploaderModel});
	};

	setOpenFileOrFolderSelector = (open: any) => {
		this.openFileOrFolderSelector = open;
	};

	callOpenFileOrFolderSelector = (files: any) => {
		this.openFileOrFolderSelector(files);
	};

	/**
	 * used to stop file uploading progresss
	 */
	stopUploading = () => {
		this.uploaderModel.uploadingObj.cancelUploadStatus = true;
		this.uploaderModelSubject.next({...this.uploaderModel});
	};

	/**
	 * show retry message for file uploading
	 */
	showRetryUploadingMsg = () => {
		this.notificationMessageSubject.next({
			type: NotificationMessageType.toast,
			level: NotificationMessageLevel.error,
			message: strings('physicianDocuments.list.retryUploadMessage'),
			visibility: NotificationVisibility.local,
		});
	};

	/**
	 * used to uplaod error files
	 */
	uploadErrorFiles = (cancelUploadMap: Record<string, any>) => {
		this.removeNotUploadedBatches();
		this.uploaderModel.timerObject.totalSize = 0;
		const batchResponseObject =
			this.uploaderHelperService.createBatchesToUpload(
				this.uploaderModel.uploadingObj,
				this.uploaderModel.uploadBatchObject,
				this.uploaderModel.timerObject,
			);
		this.uploaderModel.counterObject.errorItems = 0;
		this.updateUploaderModelSubject();
		this.startUploadInBatch(cancelUploadMap);
	};

	/**
	 * used to show file uploaded message
	 */
	showSuccessNotification = () => {
		this.notificationMessageSubject.next({
			type: NotificationMessageType.toast,
			level: NotificationMessageLevel.success,
			message: strings('physicianDocuments.successfulUpload'),
			visibility: NotificationVisibility.global,
			persistOnRouteChange: true,
			documentIdToFocus: 'documentListingTitle',
		});
	};

	/**
	 * used to show alert for patient mismatch
	 * @param studyIuid
	 */
	onConfirmPatientMismatch = (studyIuid: string) => {
		setTimeout(() => {
			const studyObject: Record<string, any> =
				this.uploaderModel.uploadingObj.listOfDicomFiles[studyIuid];
			studyObject.sendToServer = true;
			studyObject.nameMismatch = false;
			studyObject.patIdMismatch = false;
			this.uploaderHelperService.createBatchesToUpload(
				this.uploaderModel.uploadingObj,
				this.uploaderModel.uploadBatchObject,
				this.uploaderModel.timerObject,
			);
			this.updateNonDicomStudyLinkingObjectsList();
			this.updateUploaderModelSubject();
		}, 100);
	};
}

export default UploaderViewModel;
