import {getCountries, getCountryCallingCode} from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import {FormRuleMap} from '../../../../../../common/src/application/dynamicFormUtil/types/FormRules.types';
import ReactCountryFlag from 'react-country-flag';


// TODO: we can move this to commons
export function getFinalCountryMap(
	selectedKeys: Array<string>,
): Record<string, string> {
	let finalCountries: Record<string, string> = {};
	const countries = getCountries();

	if (
		selectedKeys == null ||
		(Array.isArray(selectedKeys) && selectedKeys.length === 0)
	) {
		countries.forEach(country => {
			finalCountries[country] = getCountryCallingCode(country);
		});
	} else {
		countries.forEach(country => {
			if (selectedKeys.includes(country)) {
				finalCountries[country] = getCountryCallingCode(country);
			}
		});
	}

	return finalCountries;
}

const CountrySelect = (props: any): JSX.Element => {
	const {
		value,
		onChange,
		dynamicConstraints,
		countries,
	}: {
		value: string;
		onChange: Function;
		dynamicConstraints: FormRuleMap;
		countries: Record<string, string>;
	} = props;
	const countriesMap: Record<string, any> = en;
	return (
		<>
			<div id="div_om_countrySelect" className="PhoneInputCountry">
				<select
					id="countrySelect"
					className="countrySelectElement"
					value={value}
					disabled={dynamicConstraints?.disabled ?? false}
					onChange={event => onChange(event.target.value || undefined)}>
					{Object.keys(countries).map(country => {
						const countryNameCode =
							' ' + countriesMap[country] + ', +' + countries[country];
						return (
							<option
								key={country}
								value={country}
								className={'countrySelectOption'}>
								{countryNameCode}
							</option>
						);
					})}
				</select>
				<div id="div_countrySelectFlag">
					<ReactCountryFlag
						countryCode={value}
						svg
						style={{
							width: "1rem",
							height: "1rem"
						}}
					/>
					<span className="float-right fIcon icon-arrow_down2"></span>
				</div>
			</div>
		</>
	);
};

export default CountrySelect;
