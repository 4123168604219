import { Service } from 'typedi';

import APIExecutor, { APIRequest } from '../../../../network/APIExecutor';
import { APIUrl } from '../../../../network/values/URLInfo';

@Service()
class NonceNetworkRepository {

    /**
     * 
     * @returns THIS API is used for generating a nonce url
     */
    generateNonceToken(): Promise<Record<string, any>> {
        return APIExecutor.postHTTP(new APIRequest(APIUrl.GENERATE_NONCE_TOKEN, {}))
    }
}

export default NonceNetworkRepository;
