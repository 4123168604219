import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Loader from '../../ui/comps/src/Loader';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import PrivateRoute from '../../PrivateRoute';

const SettingsComponent = lazy(() => import('./settings.component'));

const accessTo: Array<string> = ['OTHERS'];

const SettingRoutes = (
	<>
		<Route path="/" element={<PrivateRoute accessTo={accessTo} />}>
			{/* Add authenticated routes under privateRoutes */}
			<Route
				path={RoutePathInfo.sysSettings.path}
				element={
					<Suspense fallback={<Loader />}>
						<SettingsComponent />
					</Suspense>
				}
			/>
		</Route>
	</>
);

export default SettingRoutes;
