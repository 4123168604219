import BaseResponseModel from '../../../../network/interface/BaseResponseModel';

export default class SubmitCompositeFormResponse implements BaseResponseModel {
	private readonly _success: boolean | undefined;

	constructor({success}: {success?: boolean}) {
		this._success = success;
	}

	/**
	 * This method returns the response received from /minerva/formResponse/submitFormResponse API call
	 * @returns {SubmitCompositeFormResponseType}
	 */
	getResponseDataObject(): SubmitCompositeFormResponseType {
		return {
			success: this._success ?? false,
		};
	}
}

export type SubmitCompositeFormResponseType = {
	success: boolean;
};
