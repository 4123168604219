import BaseRequestModel from "../../../../network/interface/BaseRequestModel";

class DocumentReferenceUpdateRequestModel implements BaseRequestModel {
    private readonly status?: string

    constructor({status}: DocumentReferenceUpdateRequestData) {
        this.status = status
    }

    /**
     * returns document reference update request
     * @returns {DocumentReferenceUpdateRequestData}
     */
    getRequestDataObject():DocumentReferenceUpdateRequestData {
        return {
            status: this.status
        }
    }
}

export type DocumentReferenceUpdateRequestData = { status?: string }

export default DocumentReferenceUpdateRequestModel;