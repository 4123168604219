import ShareConstraint, {ShareConstraintType} from './ShareConstraint';

export default class SearchCriteria {
	private readonly _constraint: ShareConstraint;

	constructor(constraint: ShareConstraint) {
		this._constraint = constraint;
	}

	getData(): SearchCriteriaType {
		return {
			constraints: this._constraint.getData(),
		};
	}
}

export type SearchCriteriaType = {
	constraints: ShareConstraintType;
};
