/**
 * This method checks whether the provided variable is a valid JSON object
 * @param {any} value
 * @returns {boolean} whether the given value is a valid JSON object
 */
import moment from 'moment';
import {strings} from '../../../../localization/i18n';
import encryptedStorageHelperInstance from '../../../../storage/EncryptedStorageHelper';

export const isObjectValid = (value: any): boolean => {
	if (value != null && typeof value == 'object') {
		return Object.keys(value).length != 0;
	}
	return false;
};

/**
 * This method checks whether the provided variable is a valid array
 * @param {any} value
 * @returns {boolean} whether the given value is a valid array
 */
export const isArrayValid = (value: any): boolean => {
	if (value != null && Array.isArray(value)) {
		return value.length != 0;
	}
	return false;
};

/**
 * This method checks whether the given value is not null and not empty
 * @param {string | Record<string, unknown>} value value to be checked
 * @returns {boolean} whether the given value is not null and not empty
 */
export const isValueAvailable = (
	value: string | Record<string, unknown> | Array<any> | null | undefined,
): boolean => {
	return (
		value != null &&
		value !== 'null' &&
		value !== 'undefined' &&
		value !== '' &&
		(Array.isArray(value) || typeof value == 'object'
			? isObjectValid(value) || isArrayValid(value)
			: true)
	);
};

/**
 * checks whether file is of type dicom or not
 * @param {string} fileName
 * @returns {boolean}
 */
export function isDicomFile(fileName: string) {
	if (!fileName) {
		return;
	}

	var dicomFileExtensions = ['dcm', 'DCM', 'dicom'];

	var ext = fileName.substr(fileName.lastIndexOf('.') + 1);

	var checkExtEmpty = ext === fileName;

	var checkExtIsOfDicomFile = dicomFileExtensions.indexOf(ext) >= 0;

	return checkExtEmpty || checkExtIsOfDicomFile;
}

/**
 * returns extension of file
 * @param {string} fileName
 * @returns {string}
 */
export function getFileExtension(fileName: string) {
	var extension: string | undefined = '';
	if (fileName.indexOf('.') > -1) {
		var fileSplit = fileName.split('.');
		if (fileSplit.length >= 2) {
			extension = fileName.split('.').pop();
		}
	}

	if (extension) {
		extension = extension.toLowerCase();
		var isNumericExtension = /^\d+$/.test(extension);
		if (isNumericExtension) {
			extension = '';
		}
	}

	return extension;
}

/**
 * returns extension of file and icon class
 * @param {string} fileName
 * @returns {any}
 */
export function getFileExtensionAndIconClass(fileName: string) {
	if (!fileName) {
		return null;
	}
	//mapping of fileExtension with iconClass here
	var extensionIconMap: Record<string, any> = {
		doc: {iconClass: 'icon-word-document'},
		docx: {iconClass: 'icon-word-document'},
		pdf: {iconClass: 'icon-PDF-file'},
		zip: {iconClass: 'icon-zip_file'},
		png: {iconClass: 'icon-PNG_image'},
		jpg: {iconClass: 'icon-JPG_image'},
		jpeg: {iconClass: 'icon-JPG_image'},
		txt: {iconClass: 'icon-text-file'},
		mp4: {iconClass: 'icon-MP4'},
		xml: {iconClass: 'icon-XML'},
	};

	var fileExtension = getFileExtension(fileName);
	if (fileExtension && extensionIconMap[fileExtension]) {
		var resultObj = {
			fileExtension: fileExtension,
			iconClass: extensionIconMap[fileExtension]['iconClass'],
		};
		return resultObj;
	} else {
		return null;
	}
}

export function setLabelsForUploaderItem(item: any, documentType?: string) {
	var documentTypeSelected: string;
	if (!documentType) {
		documentTypeSelected = item.formObject.documentTypeSelected;
	} else {
		documentTypeSelected = documentType;
	}
	item.labelObject = {};
	item.labelObject.locationFlag = true;
	item.labelObject.documentDateFlag = true;
	item.labelObject.physicianFlag = true;
	switch (documentTypeSelected) {
		case 'Prescriptions':
		case 'Prescription':
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.hospitalLabelText',
			);
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.physicianLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.prescriptionDateLabelText',
			);
			break;
		case 'Discharge Note':
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.hospitalLabelText',
			);
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.attendingPhysicianLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.dischargeDateLabelText',
			);
			break;
		case 'Laboratory Reports':
		case 'Laboratory Report':
		case 'Test Results':
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.labLabelText',
			);
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.orderedByLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.testDateLabelText',
			);
			break;

		case 'Radiology Reports':
		case 'Imaging Studies':
		case 'Radiology Report':
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.imagingCentreLabelText',
			);
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.orderedByLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.testDateLabelText',
			);
			break;
		case 'DICOM Images':
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.imagingCentreLabelText',
			);
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.orderedByLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.studyDateLabelText',
			);
			break;
		case "Doctor's Letter":
			item.labelObject.locationFlag = false;
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.physicianLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.letterDateLabelText',
			);
			break;

		case 'Referral Note':
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.physicianLabelText',
			);
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.hospitalLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.referralDateLabelText',
			);
			break;
		case 'Consent Forms':
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.physicianLabelText',
			);
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.hospitalLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.consentDateLabelText',
			);
			break;
		case 'Certificates / Letters':
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.physicianLabelText',
			);
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.hospitalLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.letterDateLabelText',
			);
			break;
		case 'Bills / Invoice':
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.physicianLabelText',
			);
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.hospitalLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.invoiceDateLabelText',
			);
			break;
		case 'Patient Image':
		case 'Questionnaire / Assessments':
		case 'Advance Directives':
		case 'Others':
		case 'Patient Images':
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.physicianLabelText',
			);
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.hospitalLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.createdDateLabelText',
			);
			break;
		case 'Insurance':
			item.labelObject.locationFlag = false;
			item.labelObject.documentDateFlag = false;
			item.labelObject.physicianFlag = false;
			break;
		default:
			item.labelObject.locationTagName = strings(
				'patientUploadRecords.tagsData.locationLabelText',
			);
			item.labelObject.physicianTagName = strings(
				'patientUploadRecords.tagsData.physicianLabelText',
			);
			item.labelObject.documentDateTagName = strings(
				'patientUploadRecords.tagsData.createdDateLabelText',
			);
	}
	return item.labelObject;
}

export function getFileSize(bytes: any, precision: number) {
	if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
	if (typeof precision === 'undefined') precision = 1;
	var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'],
		number = Math.floor(Math.log(bytes) / Math.log(1024));
	return (
		(bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
		' ' +
		units[number]
	);
}

export function addClass(elementSelector: string, classes: string) {
	let currentElement: HTMLElement | null =
		document.getElementById(elementSelector);
	if (classes && currentElement && elementSelector) {
		let addClassesList = classes.split(':');
		if (addClassesList) {
			addClassesList.forEach(className => {
				currentElement?.classList.add(className);
			});
		}
	}
}
export function removeClass(elementSelector: string, classes: string) {
	let currentElement: HTMLElement | null =
		document.getElementById(elementSelector);
	if (classes && currentElement && elementSelector) {
		let removeClassesList = classes.split(':');
		if (removeClassesList) {
			removeClassesList.forEach(className => {
				currentElement?.classList.remove(className);
			});
		}
	}
}

export function toggleClass(elementSelector: string, classes: string) {
	let currentElement: HTMLElement | null =
		document.getElementById(elementSelector);
	if (classes && currentElement && elementSelector) {
		let classesList = classes.split(':');
		if (classesList) {
			let dataToggle = currentElement.getAttribute(
				`data-${elementSelector}-toggle`,
			);
			if (dataToggle === 'true') {
				currentElement.setAttribute(`data-${elementSelector}-toggle`, 'false');
				classesList.forEach(className => {
					currentElement?.classList.remove(className);
				});
			} else {
				currentElement.setAttribute(`data-${elementSelector}-toggle`, 'true');
				classesList.forEach(className => {
					currentElement?.classList.add(className);
				});
			}
		}
	}
}

export const unknowCheckFilter = (
	input: string,
	showUnavailableText?: string,
) => {
	var result = input;
	if (
		input == '' ||
		input == null ||
		input == 'null' ||
		input == 'Null' ||
		input == undefined
	) {
		if (showUnavailableText) {
			result = strings('patientBanner.unavailable');
		} else {
			result = strings('patientOverview.filters.unknown');
		}
	}
	return result;
};

/**
 * This method is used to check whether the input date is valid
 * @param {Date | null} date to verify
 * return {boolean}
 */
export const isValidDate = (date?: Date | null): boolean => {
	return date instanceof Date && !isNaN(date.getDate());
};

/**
 * This method is used to get date in required format
 * @param {Date | null} date to format
 * @param {string | null} format according to which date has to be formatted
 * return {string | undefined}
 */
export const getFormattedDate = (
	date?: Date | string | null,
	format?: string | undefined,
): string | undefined => {
	if (
		(date instanceof Date && isValidDate(date) && isValueAvailable(format)) ||
		(typeof date === 'string' &&
			isValueAvailable(date) &&
			isValueAvailable(format))
	) {
		return moment(date).format(format as string);
	} else {
		return undefined;
	}
};

/**
 * This method is used to convert file type object into Base64 format
 * @param {File} file to format
 * return Promise
 */
export const getBase64 = (file: File) => {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = error => reject(error);
	});
};

/**
 * This method is used to convert base64 type object into file format
 * @param {File} file to format
 * return Promise
 */
export const getFilesFromBase64 = (
	fileBase64Array: Array<Record<string, any>>,
) => {
	let convertedFiles: Array<File> = fileBase64Array.map((obj, index) => {
		const fileBase64 = obj.base64String;
		const fileName = obj.name;
		let fileType = fileBase64.substring(
			fileBase64.indexOf(':') + 1,
			fileBase64.lastIndexOf(';'),
		);
		let fileExtension = fileType.split('/');
		return new File(
			[fileBase64],
			fileName ?? `file${index}.${fileExtension[1]}`,
			{
				type: fileType,
			},
		);
	});
	return convertedFiles;
};

/**
 * This method is used to parse object and get array of file
 * @param {formModel}
 * return Array of Objects
 */
export const getArrayOfFilesFromModel = (
	fromModel: Record<string, any>,
	name: string,
): Array<Record<string, any>> => {
	let arr: Array<Record<string, any>> = [];
	var result: Record<string, any> = {};
	for (const [i, value] of Object.entries(fromModel)) {
		if (
			i.includes('.') &&
			i.split('.').length === 3 &&
			i.split('.')[0] === name
		) {
			var keys = i.split('.');
			let keyName: any = keys[2];
			result[keyName] = value;
		}
		if (result && Object.keys(result)?.length === 3) {
			arr.push(result);
			result = {};
		}
	}
	return arr;
};

/**
 * This method stores the name of element id with key idToFocus
 * @param id element id
 */
export function setFocusId(id: string) {
	encryptedStorageHelperInstance.setItemSync('idToFocus', id);
}

/**
 * used to set focus on the button from where the modal was opened when it closes
 */
export function setFocusOnModalClose() {
	console.log(
		document.getElementById(
			encryptedStorageHelperInstance.getItemSync('idToFocus'),
		),
	);
	document
		.getElementById(
			encryptedStorageHelperInstance.getItemSync('idToFocus') ?? '',
		)
		?.focus();
	encryptedStorageHelperInstance.removeItem('idToFocus');
}

/**
 * used to give the id of first input field in dynamic form that has error.
 * @param errors Error object received when onError of dynamic form is called.
 * @returns
 */
export function firstFieldError(errors: any) {
	return (Object.keys(errors) as Array<keyof typeof errors>).reduce<
		keyof typeof errors | null
	>((field, a) => {
		const fieldKey = field as keyof typeof errors;
		return !!errors[fieldKey] ? fieldKey : a;
	}, null) as string;
}
