import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class ConditionSearchRequestModel implements BaseRequestModel {
	private readonly constraints?: SearchConstraints;

	constructor({constraints}: ConditionSearchRequestData) {
		this.constraints = constraints;
	}

	/**
	 * returns condition search request
	 * @returns {ConditionSearchRequestData}
	 */
	getRequestDataObject(): ConditionSearchRequestData {
		return {
			constraints: this.constraints,
		};
	}
}

export type ConditionSearchRequestData = {
	constraints?: SearchConstraints;
};

export type SearchConstraints = {
	subject?: number;
	authParams?: string;
};

export default ConditionSearchRequestModel;
