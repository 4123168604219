import { Container, Service } from 'typedi';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';

import Agreement from '../models/Agreement';
import GlobalObserversInstance from '../../../../utils/observers/GlobalObservers';
import AgreementRepository from '../repository/AgreementRepository';
import SetAgreementStatusRequestModel, {
	AgreementStatusEnum,
} from '../models/SetAgreementStatusRequestModel';
import AgreementViewModelInterface from './interfaces/AgreementViewModel.interface';
import {
	NotificationMessage,
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../generic/models/NotificationMessage';
import { LoaderProps, LoaderType } from '../../generic/models/Loader';
import { strings } from '../../../../../../localization/i18n';
import UserService from '../../user/service/User.service';
import { APIUrl } from '../../../network/values/URLInfo';
import encryptedStorageHelperInstance from '../../../../../../storage/EncryptedStorageHelper';
import { sessionStorageKeys } from '../../../../interface/storage/constants/SessionKeys';
import WebsocketService from '../../../../../../utils/websocket';
import { NavigationEvent } from '../../generic/models/NavigationEvent';

export enum AgreementNavigationEvents {
	LOGIN,
	LANDING_PAGE,
}

@Service()
class AgreementsViewModel implements AgreementViewModelInterface {
	private readonly agreementRepository: AgreementRepository;
	private readonly agreementModelSubject: ReplaySubject<Agreement>;
	private readonly notificationMessageSubject: Subject<NotificationMessage>;
	private readonly loaderSubject: Subject<LoaderProps>;
	// Used for handling navigation when the agreements are accepted or declined,
	// and user should navigate to a screen based on given event
	private readonly navigationSubject: Subject<NavigationEvent<AgreementNavigationEvents, Record<string, any>>>;
	// Used for switching between view only and login flows
	private isViewOnly = false;
	private userService: UserService;
	private websocketService: WebsocketService;

	private unreadAgreementList?: Array<Agreement>;
	private readAgreementList?: Array<Agreement>;
	private languageChangeSubscription?: Subscription;
	private hasScrollEnded = false;

	constructor() {
		this.agreementModelSubject = new ReplaySubject<Agreement>();
		this.notificationMessageSubject = new Subject<NotificationMessage>();
		this.loaderSubject = new Subject<LoaderProps>();
		this.navigationSubject = new Subject<NavigationEvent<AgreementNavigationEvents, Record<string, any>>>();
		this.agreementRepository = Container.get(AgreementRepository);
		this.userService = Container.get(UserService);
		this.websocketService = Container.get(WebsocketService);
	}

	/**
	 * This method is used to mark initialization of the view model instance.
	 * Any data dependency must be passed through this function.
	 * @param isViewOnly
	 * @param agreementId
	 */
	public initialize(
		isViewOnly: boolean,
		agreementId: string | undefined | null,
	): void {
		this.isViewOnly = isViewOnly;
		if (this.isViewOnly) {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OnScreen,
			});
			//TODO move accepted agreement fetch API to help section and cache it. In case of read mode agreement ID will be passed from help section.
			//TODO fetch Agreements based on agreementID passed from cache and send it to the view.
			/*this.agreementRepository?.fetchAgreement(agreementId).then((data:Agreement) =>{
				this.loaderSubject.next({
					isToShowLoader: false,
					type: LoaderType.OnScreen,
				});
				this.agreementModelSubject.next(data);
			});*/
			this.agreementRepository
				?.fetchAllReadAgreements()
				.then(({ data }) => {
					// store read agreements
					// readAgreementList
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OnScreen,
					});
					const { success, acceptedAgreements } = data;
					if (success) {
						this.readAgreementList = [];
						if (
							acceptedAgreements != null &&
							Array.isArray(acceptedAgreements) &&
							acceptedAgreements.length > 0
						) {
							acceptedAgreements.forEach(agreement => {
								this.readAgreementList!.push(new Agreement(agreement));
							});
							this.agreementModelSubject.next(this.readAgreementList[0]);
						} else {
							console.log(
								'AgreementsViewModel.constructor: success received invalid response',
								data,
							);
							this.notificationMessageSubject.next({
								message: strings('errors.somethingWentWrong'),
								type: NotificationMessageType.toast,
								level: NotificationMessageLevel.error,
							});
						}
					} else {
						console.log(
							'AgreementsViewModel.constructor: success received false from API response',
							data,
						);
						this.notificationMessageSubject.next({
							message: strings('errors.somethingWentWrong'),
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
						});
					}
				})
				.catch(error => {
					console.log(
						'AgreementsViewModel.constructor: error while fetching read agreements',
						error,
					);
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OnScreen,
					});
					this.notificationMessageSubject.next({
						message: strings('errors.somethingWentWrong'),
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
					});
				});
		} else {
			this.languageChangeSubscription =
				GlobalObserversInstance.languageChangeObserver.observable().subscribe({
					next: (languageChangedTo: string) => {
						// call get user details API call again and update agreements
						this.loaderSubject.next({
							isToShowLoader: true,
							type: LoaderType.OnScreen,
						});
						this.agreementRepository
							?.fetchAgreements(false, languageChangedTo)
							.then(this.handleUserAgreements.bind(this))
							.catch(error => {
								console.log(
									'AgreementsViewModel.constructor: error while fetching all agreements',
									error,
								);
								this.loaderSubject.next({
									isToShowLoader: false,
									type: LoaderType.OnScreen,
								});
								this.notificationMessageSubject.next({
									message: strings('errors.somethingWentWrong'),
									type: NotificationMessageType.toast,
									level: NotificationMessageLevel.error,
								});
							});
					},
				});

			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OnScreen,
			});
			this.agreementRepository
				?.fetchAgreements(true)
				.then(this.handleUserAgreements.bind(this))
				.catch(error => {
					console.log(
						'AgreementsViewModel.constructor: error while fetching all agreements',
						error,
					);
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OnScreen,
					});
					this.notificationMessageSubject.next({
						message: strings('errors.somethingWentWrong'),
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
					});
				});
		}
	}

	/**
	 * This method is used to get the observer to get agreement model to be loaded on the UI
	 * returns Observable<Agreement>
	 */
	public fetchAgreementsObservable(): Observable<Agreement> {
		return this.agreementModelSubject.asObservable();
	}

	/**
	 * This method is used to get the observer where notification messages are posted
	 * returns Observable<NotificationMessage>
	 */
	public fetchNotificationMessageObservable(): Observable<NotificationMessage> {
		return this.notificationMessageSubject.asObservable();
	}

	/**
	 * This method is used to get the observer where loader information is posted
	 * returns Observable<Loader>
	 */
	public fetchLoaderObservable(): Observable<LoaderProps> {
		return this.loaderSubject.asObservable();
	}

	/**
	 * This method is used to get the observer where navigation information is posted
	 * returns Observable<AgreementNavigationEvents>
	 */
	public fetchNavigationObservable(): Observable<NavigationEvent<AgreementNavigationEvents, Record<string, any>>> {
		return this.navigationSubject.asObservable();
	}

	/**
	 * This method is used to update the checked status of AgreementApprovalCriteria for current agreement.
	 * This method should be called before accept or decline function is invoked.
	 * Does nothing in case unreadAgreementList is empty.
	 * @param agreement to replace current with
	 * @return {boolean} indicated whether update was successful
	 */
	public updateCurrentAgreement(agreement: Agreement): boolean {
		if (
			this.unreadAgreementList != null &&
			this.unreadAgreementList.length > 0
		) {
			this.unreadAgreementList[0] = agreement;
			// to notify change on UI.
			this.agreementModelSubject.next(agreement);
			return true;
		} else {
			return false;
		}
	}

	/**
	 * This method is used to get the read agreements.
	 * Return list returned from moManageAgreements/fetchAcceptedAgreementsByUser API in view only mode
	 * and returns list of accepted agreements till point of invocation of this function in login scenario
	 * Defaults to empty array.
	 * @return {Array<Agreement>}
	 */
	public fetchReadAgreements(): Array<Agreement> {
		return this.readAgreementList ?? [];
	}

	/**
	 * This method is used to accept the passed agreement.
	 * @param {number} agreementId optional number, does nothing if agreement id is undefined.
	 */
	public acceptAgreement(agreementId?: number): boolean {
		if (agreementId != null) {
			// mark agreement as ACCEPTED
			if (
				this.unreadAgreementList != null &&
				this.unreadAgreementList.length > 0
			) {
				if (!this.hasScrollEnded) {
					this.notificationMessageSubject.next({
						message: strings('agreements.errors.readEntireAgreement'),
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
						visibility: NotificationVisibility.global,
					});
					return false;
				}
				const isValid = Agreement.validateAgreementForAcceptance(
					this.unreadAgreementList[0],
				);
				if (!isValid) {
					this.notificationMessageSubject.next({
						message: strings(
							this.unreadAgreementList[0].localizedContentToRender
								?.validationMessage ??
							'agreements.errors.invalidAgreementForAcceptance',
						),
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
						visibility: NotificationVisibility.global,
					});
					return false;
				}
				this.loaderSubject.next({
					isToShowLoader: true,
					type: LoaderType.OverScreen,
				});
				this.callSetAgreementStatusAPI(
					agreementId,
					AgreementStatusEnum.ACCEPTED,
				)
					.then(() => {
						// move agreement from unread list to read list
						this.unreadAgreementList &&
							this.readAgreementList?.concat(
								this.unreadAgreementList.splice(0, 1),
							);

						// check whether more agreements are available
						if (
							this.unreadAgreementList &&
							this.unreadAgreementList.length > 0
						) {
							// reset scroll state for agreement
							this.hasScrollEnded = false;
							// notify observers on new agreement
							this.agreementModelSubject.next(this.unreadAgreementList[0]);
						} else {
							this.agreementRepository
								.saveAllReadAgreements(this.readAgreementList)
								.then(() => {
									// no more agreements are available, user can navigate to dashboard
									this.userService.getAuthToken(true).then(authToken => {
										this.userService.setAuthToken(authToken, false);
										if (global.platform === 'web') {
											if (!this.websocketService.status()) {
												this.websocketService.createWebSocketConnectionWithToken(
													authToken,
												);
											}
										}
										encryptedStorageHelperInstance.removeItem(
											sessionStorageKeys.TEMP_AUTH_TOKEN,
										);
										this.userService.getPostLoginConfig(); //The API call will fetch the required config list from the server
										this.userService.getVitalConfig(); // This API call with fetch the vital config
										this.userService.getUserDashboards();
										this.userService
											.getRedirectAfterLoginUrl()
											.then((redirectUrl: string | undefined) => {
												this.navigationSubject.next({
													event: AgreementNavigationEvents.LANDING_PAGE,
													additionalData: redirectUrl ? { redirectUrl: redirectUrl } : {},
												});
											}).finally(() => this.cleanUp())
										this.loaderSubject.next({
											isToShowLoader: false,
											type: LoaderType.OverScreen,
										});
									});
								})
								.catch(error => {
									this.loaderSubject.next({
										isToShowLoader: false,
										type: LoaderType.OverScreen,
									});
									this.notificationMessageSubject.next({
										message: strings('errors.somethingWentWrong'),
										type: NotificationMessageType.toast,
										level: NotificationMessageLevel.error,
										visibility: NotificationVisibility.global,
									});
								});
						}
					})
					.catch(error => {
						console.log(
							'AgreementsViewModel.acceptAgreement',
							JSON.stringify(error, null, 4),
						);
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OverScreen,
						});
						this.notificationMessageSubject.next({
							message: strings('errors.somethingWentWrong'),
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
						});
					});
				return true;
			} else {
				return false;
			}
		} else {
			this.loaderSubject.next({
				isToShowLoader: false,
				type: LoaderType.OverScreen,
			});
			throw Error('No agreement id passed.');
		}
	}

	/**
	 * This method is used to decline the passed agreement.
	 * @param {number} agreementId optional number, does nothing if agreement id is undefined.
	 */
	public declineAgreement(agreementId?: number): void {
		if (agreementId != null) {
			// mark agreement as REJECTED
			this.callSetAgreementStatusAPI(agreementId, AgreementStatusEnum.REJECTED)
				.then(() => {
					// cleanup user login details from storage
					this.userService.removeAllCookies().then(response => {
						// navigate to login
						this.navigationSubject.next({
							event: AgreementNavigationEvents.LOGIN,
							additionalData: {},
						});
						this.cleanUp();
					});
				})
				.catch(error => {
					console.log(
						'AgreementsViewModel.declineAgreement',
						JSON.stringify(error, null, 4),
					);
					this.notificationMessageSubject.next({
						message: strings('errors.somethingWentWrong'),
						type: NotificationMessageType.toast,
						level: NotificationMessageLevel.error,
					});
				})
				.finally(() => {
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				});
		} else {
			throw Error('No agreement id passed.');
		}
	}

	/**
	 * This method is a common utility method to call setAgreementStatus API
	 * call for both accept and decline cases.
	 * @param agreementId mongo id of agreement received from server.
	 * @param status to be marked.
	 * @private
	 */
	private callSetAgreementStatusAPI(
		agreementId: number,
		status: AgreementStatusEnum,
	): Promise<void> {
		const requestModel = new SetAgreementStatusRequestModel({
			status: status,
			agreementId: agreementId,
		});
		return new Promise<void>((resolve, reject) => {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OverScreen,
			});
			let header: Record<string, any> | null = null;
			this.userService
				.getAuthToken(true)
				.then(tempToken => {
					if (tempToken) {
						header = {};
						header = this.userService.getHeaderWithTempToken(
							APIUrl.SET_AGREEMENT_AS_READ,
							tempToken,
						);
					}
					this.agreementRepository
						?.setAgreementStatus(requestModel, header ? header : undefined)
						.then(({ data, headers, status }: Record<string, any>) => {
							this.unreadAgreementList != null &&
								this.unreadAgreementList.length > 0 &&
								this.unreadAgreementList[0].updateReadDate(
									requestModel.getRequestDataObject().readDate,
								);
							this.loaderSubject.next({
								isToShowLoader: false,
								type: LoaderType.OverScreen,
							});
							resolve();
						})
						.catch(error => {
							reject(error);
						});
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	set scrollEnded(value: boolean) {
		this.hasScrollEnded = value;
	}

	get scrollEnded(): boolean {
		return this.hasScrollEnded;
	}

	/**
	 * This method is used to clean-up observables and subjects so that no open observers are left in memory.
	 * This is final function called for view model and destroys all connections from subscribers.
	 * The application should not invoke any function or get state from current view model instance
	 * once this is called.
	 * @public
	 */
	public cleanUp() {
		this.agreementModelSubject.complete();
		this.notificationMessageSubject.complete();
		this.navigationSubject.complete();
		this.languageChangeSubscription?.unsubscribe();
	}

	/**
	 * Common method to parse and setup agreements
	 * @param agreements to show to the user
	 * @private
	 */
	private handleUserAgreements(agreements: Array<Agreement>) {
		// reset scroll state for agreement
		this.hasScrollEnded = false;
		this.loaderSubject.next({
			isToShowLoader: false,
			type: LoaderType.OnScreen,
		});
		this.unreadAgreementList = agreements;
		this.readAgreementList = [];
		if (this.unreadAgreementList.length > 0) {
			this.agreementModelSubject.next(this.unreadAgreementList[0]);
		} else {
			// no agreements available to display
			this.userService
				.getRedirectAfterLoginUrl()
				.then((redirectUrl: string | undefined) => {
					this.navigationSubject.next({
						event: AgreementNavigationEvents.LANDING_PAGE,
						additionalData: redirectUrl ? { redirectUrl: redirectUrl } : {},
					});
				})
			this.cleanUp();
		}
	}
}

export default AgreementsViewModel;
