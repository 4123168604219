import BaseRequestModel from '../../../../../../../network/interface/BaseRequestModel';

export default class FetchCitiesRequest implements BaseRequestModel {
	private readonly _lang: string;
	private readonly _countryId: number;
	private readonly _stateId: number;

	constructor({lang, countryId, stateId}: FetchCitiesRequestType) {
		this._lang = lang;
		this._countryId = countryId;
		this._stateId = stateId;
	}

	/**
	 * This method is used to get the request body to be sent
	 * in /minerva/moCountryDropDown/fetchCities API call
	 * @returns {FetchCitiesRequestType}
	 */
	getRequestDataObject(): FetchCitiesRequestType {
		return {
			lang: this._lang,
			countryId: this._countryId,
			stateId: this._stateId,
		};
	}
}

export type FetchCitiesRequestType = {
	lang: string;
	countryId: number;
	stateId: number;
};
