import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class DocumentDeleteRequestModel implements BaseRequestModel {
	private readonly subject: string;

	constructor(subject = '') {
		this.subject = subject;
	}

	/**
	 * This method is used to get the request body for delete document API
	 * @returns {DocumentDeleteRequestType}
	 */
	getRequestDataObject(): DocumentDeleteRequestType {
		return {
			status: 'entered-in-error',
		};
	}

	/**
	 * This method is used to get subject for the current request model
	 */
	getSubject(): string {
		return `${this.subject}`;
	}
}

export type DocumentDeleteRequestType = {
	status: string;
};

export default DocumentDeleteRequestModel;
