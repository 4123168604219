class DocumentReferenceUpdateValidationModel {
    constructor(
        private _DocIDError:string = '',
        private _statusError: string = '',
        private _isError: boolean = false
    ) {}

    set DocIDError(value:string){
        this._DocIDError = value
    }

    get DocIDError(){
        return this._DocIDError
    }

    set statusError(value: string) {
        this._statusError = value
    }
    get statusError(): string {
        return this._statusError
    }
    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}
export default DocumentReferenceUpdateValidationModel