import {useContext} from 'react';
import {sessionStorageKeys} from '../../common/src/interface/storage/constants/SessionKeys';
import {
	AppContextInterface,
	GlobalStateContext,
} from '../../common/src/utils/context/GlobalStateContext';
import {
	changeLanguage,
	selectedLanguage,
	strings,
} from '../../localization/i18n';
import encryptedStorageHelperInstance from '../../storage/EncryptedStorageHelper';

const Localization = (props: any): JSX.Element => {
	const {user} = useContext<AppContextInterface>(GlobalStateContext);

	let lang = selectedLanguage();

	const clickHandler = (lang: string) => {
		console.log('selec ', lang);
		changeLanguage(lang);
	};

	//Todo check from local storage an set selected language
	return (
		<>
			<li className="nav-item dropdown">
				<button
					className="btn noBtn-style dropdown-toggle"
					id="dropdownMenuButton"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					aria-label={strings(
						'header.languageOption.' +
							(lang ?? global.ConfigurationHolder.defaultLanguage),
					)}
				>
					<span
						aria-hidden="true"
						className="fIcon icon-language rightNavIcon inline-block"
					></span>

					{!user ? (
						<span className="rightNavIcon-text" aria-hidden="true">
							{strings(
								'header.languageOption.' +
									(lang ?? global.ConfigurationHolder.defaultLanguage),
							)}
						</span>
					) : (
						user &&
						encryptedStorageHelperInstance.getItemSync(
							sessionStorageKeys.TEMP_AUTH_TOKEN,
						) && (
							<span className="rightNavIcon-text" aria-hidden="true">
								{strings(
									'header.languageOption.' +
										(lang ?? global.ConfigurationHolder.defaultLanguage),
								)}
							</span>
						)
					)}
				</button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
					{global.ConfigurationHolder?.languagesList?.map(
						(lang: string, key: any) => (
							<button
								key={key}
								className="btn noBtn-style dropdown-item"
								onClick={() => clickHandler(lang)}
							>
								{strings('header.languageOption.' + lang)}
							</button>
						),
					)}
				</div>
			</li>
		</>
	);
};

export default Localization;
