import BaseRequestModel from '../../../network/interface/BaseRequestModel';

class UserDetailsRequestModel implements BaseRequestModel {
	private readonly deviceUID?: string;
	private readonly userLanguage?: string;
	constructor({deviceUID, userLanguage}: UserDetailsRequest) {
		this.deviceUID = deviceUID;
		this.userLanguage = userLanguage;
	}

	/**
	 * This method is used to get the final request model to be used for API call
	 * @return {UserDetailsRequest}
	 */
	getRequestDataObject(): UserDetailsRequest {
		return {
			deviceUID: this.deviceUID,
			userLanguage: this.userLanguage,
		};
	}
}

export type UserDetailsRequest = {
	deviceUID?: string;
	userLanguage?: string;
};

export default UserDetailsRequestModel;
