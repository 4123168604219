import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class LocationSearchRequestModel implements BaseRequestModel {
	private readonly fields?: string;
	private readonly nameStartWith?: string;
	private readonly typeIn?: any;
	private readonly id?: number;

	constructor({fields, nameStartWith, typeIn, id}: LocationSearchRequestData) {
		this.fields = fields;
		this.nameStartWith = nameStartWith;
		(this.typeIn = typeIn), (this.id = id);
	}

	/**
	 * returns location search request
	 * @returns {Record<string, any>}
	 */
	getRequestDataObject(): Record<string, any> {
		return {
			constraints: {
				_fields: this.fields,
				'name:startsWith': this.nameStartWith,
				'type:in': this.typeIn,
				id: this.id,
			},
		};
	}

	getParsedvalues(): LocationSearchRequestData {
		return {
			fields: this.fields,
			nameStartWith: this.nameStartWith,
			typeIn: this.typeIn,
			id: this.id,
		};
	}
}

export type LocationSearchRequestData = {
	fields?: string;
	nameStartWith?: string;
	typeIn?: any;
	id?: number;
};

export default LocationSearchRequestModel;
