import {AreaCodeModel} from '../common/AreaCodeModel';
import BaseResponseModel from '../../../../../../../network/interface/BaseResponseModel';

export default class FetchCitiesResponse implements BaseResponseModel {
	private readonly _cities: Array<AreaCodeModel>;

	constructor({cities}: FetchCitiesResponseType) {
		this._cities = cities ?? [];
	}

	/**
	 * This method returns the response received from
	 * /minerva/moCountryDropDown/fetchCities API call
	 * @returns {FetchCitiesResponseType}
	 */
	getResponseDataObject(): FetchCitiesResponseType {
		return {
			cities: this._cities,
		};
	}
}

export type FetchCitiesResponseType = {
	cities?: Array<AreaCodeModel>;
};
