import InputComponent from './form-components/Input.component';
import DropDownComponent from './form-components/DropDown.component';
import RemoteDropdownComponent from './form-components/remoteDropdown/RemoteDropDown.component';
import UndefinedComponent from './form-components/UndefinedField.component';
import LabelComponent from './form-components/Label.component';
import RadioComponent from './form-components/Radio.component';
import PasswordComponent from './form-components/password/Password.component';
import LinkComponent from '../LinkComponent/link.component';
import DatePickerComponent from './form-components/DatePicker.component';
import CheckBoxComponent from './form-components/CheckBox.component';
import CheckboxGroupController from './form-components/checkboxGroup/CheckBoxGroupController';
import TextareaComponent from './form-components/Textarea.component';
import NumberComponent from './form-components/Number.component';
import RawHTML from './form-components/RawHTML.component';
import PhotoController from './form-components/photo/PhotoController.component';
import PhoneController from './form-components/phone-component/PhoneController.component';
import {ComponentContext} from './context/Component.context';
import FileUploadController from './form-components/fileUpload/FileUpload.component';

// map of all input types to corresponding components
const componentMap: any = {
	text: (props: any) => <InputComponent {...props} />,

	number: (props: any) => <NumberComponent {...props} />,

	email: (props: any) => <InputComponent {...props} />,

	dropdown: (props: any) => <DropDownComponent {...props} />,

	remoteDropdown: (props: any) => <RemoteDropdownComponent {...props} />,

	label: (props: any) => <LabelComponent {...props} />,

	phone: (props: any) => <PhoneController {...props} />,

	link: (props: any) => <LinkComponent {...props} />,

	radio: (props: any) => <RadioComponent {...props} />,

	password: (props: any) => <PasswordComponent {...props} />,

	date: (props: any) => <DatePickerComponent {...props} />,

	dob: (props: any) => <DatePickerComponent {...props} />,

	time: (props: any) => <DatePickerComponent {...props} />,

	dateTime: (props: any) => <DatePickerComponent {...props} />,

	upload: (props: any) => <FileUploadController {...props} />,

	checkbox: (props: any) => <CheckBoxComponent {...props} />,

	checkboxGroup: (props: any) => <CheckboxGroupController {...props} />,

	photo: (props: any) => <PhotoController {...props} />,

	default: (props: any) => <UndefinedComponent {...props} />,

	textarea: (props: any) => <TextareaComponent {...props} />,

	html: (props: any) => <RawHTML {...props} />,

	noRender: () => {},
};

const FormComponentPicker = (props: any): JSX.Element | null => {
	if (props.fieldData.type === 'custom') {
		return props.fieldData.includeComponent({
			...props,
			...props.fieldData.customProps,
		});
	}
	return (
		componentMap[props.fieldData.type]?.(props) ??
		/* for the default "switch" case */
		null
	);
};

const CompSelector = (props: any): JSX.Element | null => {
	const {formStateAndConstraints, ...rest} = props;

	const fieldStateAndConstraints =
		formStateAndConstraints[rest.fieldData?.name];

	// checking for visibility
	const showField = fieldStateAndConstraints?.visible;

	return (
		<ComponentContext.Consumer>
			{customComponentMap => {
				if (!showField) return null;
				if (customComponentMap[rest.fieldData?.type] != null) {
					return customComponentMap[rest.fieldData?.type]({
						...rest,
						dynamicConstraints: fieldStateAndConstraints,
						allDynamicConstraints: formStateAndConstraints,
					});
				}

				return (
					<FormComponentPicker
						{...rest}
						dynamicConstraints={fieldStateAndConstraints}
						allDynamicConstraints={formStateAndConstraints}
					/>
				);
			}}
		</ComponentContext.Consumer>
	);
};

export default CompSelector;
