import {AreaCodeModel} from '../common/AreaCodeModel';
import BaseResponseModel from '../../../../../../../network/interface/BaseResponseModel';

export default class FetchStatesResponse implements BaseResponseModel {
	private readonly _states: Array<AreaCodeModel>;

	constructor({states}: FetchStatesResponseType) {
		this._states = states ?? [];
	}

	/**
	 * This method returns the response received from
	 * /minerva/moCountryDropDown/fetchStates API call
	 * @returns {FetchStatesResponseType}
	 */
	getResponseDataObject(): FetchStatesResponseType {
		return {
			states: this._states,
		};
	}
}

export type FetchStatesResponseType = {
	states?: Array<AreaCodeModel>;
};
