import {getFormattedDateTime} from '../../../../common/src/utils/DateTimeUtils';
import {strings} from '../../../../localization/i18n';

const AdditionalDetails = (props: any): JSX.Element => {
	const {row} = props;
	const visitDate = getFormattedDateTime(
		row.encounterStartDate,
		row.dateTimeZone,
	);
	const visitLocation = row.encounterLocationName;
	const visitStatus = row.encounterStatus;

	return (
		<>
			<div className="demographics">
				<div className="dListCont inline">
					{
						<p className="dList">
							<em>{strings('formsListing.visitDate')}</em>
							{visitDate ? visitDate : strings('formsListing.notAvailable')}
						</p>
					}
					{
						<p className="dList">
							<em>{strings('formsListing.visitLocation')}</em>
							{visitLocation
								? visitLocation
								: strings('formsListing.notAvailable')}
						</p>
					}
					{
						<p className="dList">
							<em>{strings('formsListing.visitStatus')}</em>
							{visitStatus ? visitStatus : strings('formsListing.notAvailable')}
						</p>
					}
				</div>
			</div>
		</>
	);
};

export default AdditionalDetails;
