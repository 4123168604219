import {getFormattedDateTime} from '../../../../common/src/utils/DateTimeUtils';
import {strings} from '../../../../localization/i18n';
import {displayUserID} from '../../../../utils/filters/filters';

const SubmissionDetails = (props: any): JSX.Element => {
	const {row} = props;
	const status = row.status;
	const submittedBy = displayUserID(row.lastUpdatedBy);
	const submittedOn = getFormattedDateTime(row.submittedDate, row.dateTimeZone);
	return (
		<>
			<div className="demographics">
				<div className="dListCont inline">
					{
						<p className="dList">
							<em>{strings('formsListing.status.title')}</em>
							{status
								? strings('formsListing.status.values.' + status)
								: strings('formsListing.notAvailable')}
						</p>
					}

					{
						<p className="dList">
							<em>{strings('formsListing.submittedBy')}</em>
							{submittedBy ? submittedBy : strings('formsListing.notAvailable')}
						</p>
					}

					{
						<p className="dList">
							<em>{strings('formsListing.submittedOn')}</em>
							{submittedOn ? submittedOn : strings('formsListing.notAvailable')}
						</p>
					}
				</div>
			</div>
		</>
	);
};

export default SubmissionDetails;
