import {strings} from '../../../../../../../localization/i18n';

export enum CompositeFormType {
	PRE_REGISTRATION = 'PRE_REGISTRATION',
	CONSENT_FORM = 'CONSENT_FORM',
	GENERIC_FORM = 'GENERIC_FORM',
}

export const getHeadingForCompositeForm = (type: CompositeFormType) => {
	switch (type) {
		case CompositeFormType.PRE_REGISTRATION:
			return strings('compositeForms.headings.preRegistration');
		case CompositeFormType.CONSENT_FORM:
			return strings('compositeForms.headings.consent');
	}
};

export const getFilterStringForCompositeFormType = (
	type: CompositeFormType,
) => {
	switch (type) {
		case CompositeFormType.PRE_REGISTRATION:
			return strings('compositeForms.list.preRegistrationType');
		case CompositeFormType.CONSENT_FORM:
			return strings('compositeForms.list.consentType');
		default:
			return type;
	}
};

export type PreRegistrationFormName =
	| 'demographics'
	| 'visitInformation'
	| 'replicableInsuranceDetails'
	| 'guarantorInformation'
	| 'medicalInsurance_segment01';
