import {APIDetails} from "../../../../../common/src/interface/dataGrid/IDataGrid";

export default function createRequestBody(
	count: number,
	skip: number,
	apiDetails: APIDetails,
	globalSearchString?: string,
) {
	let countAndSkip:Record<string, number> = {};
	if(apiDetails.countAlias){
		countAndSkip[apiDetails.countAlias] = count
	}else{
		countAndSkip['_count'] = count
	}
	if(apiDetails.skipAlias){
		countAndSkip[apiDetails.skipAlias] = skip
	}else{
		countAndSkip['_skip'] = skip
	}

	if (apiDetails.searchStringName && globalSearchString) {
		let constraints = {
			...apiDetails.requestBody?.constraints,
		};
		apiDetails.requestBody.constraints = {
			...constraints,
			[apiDetails.searchStringName]: globalSearchString,
		};
	}

	/* if (!!params) {
		paramObject = params?.reduce(
			(rest, v) => ({...rest, [v.name]: v.filter}),
			{},
		);
	} */

	if (
		apiDetails &&
		apiDetails.requestBody &&
		apiDetails.countAndSkipParentObject
	) {
		apiDetails.requestBody[apiDetails.countAndSkipParentObject] = {
			...apiDetails.requestBody[apiDetails.countAndSkipParentObject],
			...countAndSkip,
		};
	} else {
		apiDetails.requestBody = {
			...apiDetails.requestBody,
			...countAndSkip,
		};
	}

	let globalSearchStringObject = {};
	if (!!globalSearchString) {
		globalSearchStringObject = {
			searchString: globalSearchString,
		};
	}

	return {...apiDetails.requestBody, ...globalSearchStringObject};
}
