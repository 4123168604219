import { useState } from "react";
import LinkComponent from "../../LinkComponent/link.component";
import Timer from "./Timer";

export const ResendOTPTimer  = (props : any) : JSX.Element  => {

    const [countDownTimerExpired, setCountDownTimerExpired] = useState(false);
	
	let resendTime : number = props.resendTimerInSeconds

	const resendOtp = () => {
		setCountDownTimerExpired(false)
		//send otp logic 
		props.resendOtpHandler();
		
	}

	// final countdown time
	const time = new Date();
	time.setSeconds(time.getSeconds() + resendTime)


    return <>
       <div className="text-right mb15">
		{ props.showResendTimer ? 
			( countDownTimerExpired 
				? <LinkComponent text="resendOTP.linkLabel" linkActionHandler={resendOtp}></LinkComponent>
				:
				<div>
					<Timer expiryTimestamp={time} setCountDownTimerExpired={setCountDownTimerExpired} />
				</div>
			)
			:
			<LinkComponent text="resendOTP.linkLabel" linkActionHandler={resendOtp}></LinkComponent>
		}
	   </div>
    </>
}