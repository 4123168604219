import {NavLink, useLocation} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {strings} from '../../../../../localization/i18n';
import {MenuItemsModel} from '../types/navBar.types';
import CustomNavLink from '../../CustomNavLink';

/**
 * renders NavBarItems on the UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

function NavBarItems(props: any) {
	const {
		navItems,
		configData,
	}: {navItems: Array<MenuItemsModel>; configData: Record<string, any>} = props;

	const [defaultNoOfMenuItems, setDefaultNoOfMenuItems] = useState(
		configData.defaultNumberOfMenuItems,
	);

	const [activeIndex, setActiveIndex] = useState<number | undefined>();
	let location = useLocation();

	const menuItemsData: Array<MenuItemsModel> = [];
	const dropdownItemsData = [];

	const breakPoints = configData.breakpoints;

	/**
	 * adds menuItems in dropdown when screen is resized
	 */
	const whenScreenResized = () => {
		const screenSize = window.innerWidth;
		if (screenSize >= breakPoints.xxl) {
			setDefaultNoOfMenuItems(6);
		} else if (screenSize < breakPoints.xxl && screenSize >= breakPoints.xl) {
			setDefaultNoOfMenuItems(5);
		} else if (screenSize < breakPoints.xl && screenSize >= breakPoints.l) {
			setDefaultNoOfMenuItems(4);
		} else if (screenSize < breakPoints.l && screenSize >= breakPoints.m) {
			setDefaultNoOfMenuItems(3);
		} else if (screenSize < breakPoints.m && screenSize >= breakPoints.s) {
			setDefaultNoOfMenuItems(2);
		} else if (screenSize < breakPoints.s) {
			setDefaultNoOfMenuItems(0);
		}
	};
	for (let i = 0; i < navItems.length; i++) {
		if (i < defaultNoOfMenuItems && navItems[i].type !== 'dropDown') {
			menuItemsData.push(navItems[i]);
		} else {
			dropdownItemsData.push(navItems[i]);
		}
	}
	useEffect(() => {
		menuItemsData.forEach(menuItem => {
			if (location.pathname.includes(menuItem.path)) {
				setActiveIndex(menuItem.id);
			}
		});
	}, [location, menuItemsData]);

	useEffect(() => {
		window.addEventListener('resize', whenScreenResized);
		return () => {
			window.removeEventListener('resize', whenScreenResized);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/**
								aria-haspopup="true"
								aria-expanded="false">
								{strings(menuItem.name)}
							</button>
	 * renders menuItems on the NavBar
	 * @param menuItem
	 * @param key
	 * @returns {JSX.Element}
	 */
	function renderMenuItem(menuItem: any, key: any) {
		switch (menuItem.type) {
			case 'dropDown':
				return (
					<li className="nav-item dropdown" key={key}>
						<button
							className="noBtn-style dropdown-toggle"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false">
							{strings(menuItem.name)}
						</button>
						<ul className="dropdown-menu">
							{menuItem.subMenuItems.map(
								(dropdownItem: any, key: any) =>
									dropdownItem.path && (
										<CustomNavLink
											className={
												activeIndex === menuItem.id
													? 'dropdown-item active'
													: 'dropdown-item inactive'
											}
											ariaCurrent={activeIndex === menuItem.id ? 'page' : false}
											to={dropdownItem.path}
											key={key}
											role="link">
											{strings(dropdownItem.name)}
										</CustomNavLink>
									),
							)}
						</ul>
					</li>
				);
			default:
				return (
					menuItem.path && (
						<li className="nav-item" key={key}>
							<CustomNavLink
								to={menuItem.path}
								key={key}
								role="link"
								className={activeIndex === menuItem.id ? 'active' : 'inactive'}
								ariaCurrent={activeIndex === menuItem.id ? 'page' : false}>
								{strings(menuItem.name)}
							</CustomNavLink>
						</li>
					)
				);
		}
	}

	const menuData =
		menuItemsData.length > 0 &&
		menuItemsData.map((menuItem: any, key: any) => {
			switch (menuItem.type) {
				case 'dropDown':
					return (
						<li className="nav-item dropdown" key={key}>
							<button
								className="noBtn-style fIcon icon-help2 dropdown-toggle"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								{strings(menuItem.name)}
							</button>
							<ul className="dropdown-menu">
								{menuItem.subMenuItems.map(
									(dropdownItem: any, key: any) =>
										dropdownItem.path && (
											<CustomNavLink
												className={
													activeIndex === menuItem.id
														? 'dropdown-item active'
														: 'dropdown-item inactive'
												}
												ariaCurrent={
													activeIndex === menuItem.id ? 'page' : false
												}
												to={dropdownItem.path}
												key={key}
												role="link">
												{strings(dropdownItem.name)}
											</CustomNavLink>
										),
								)}
							</ul>
						</li>
					);
				default:
					return (
						menuItem.path && (
							<li className="nav-item" key={key}>
								<CustomNavLink
									to={menuItem.path}
									key={key}
									role="link"
									className={
										activeIndex === menuItem.id ? 'active' : 'inactive'
									}
									ariaCurrent={activeIndex === menuItem.id ? 'page' : false}>
									{strings('header.menuItems.' + menuItem.name)}
								</CustomNavLink>
							</li>
						)
					);
			}
		});

	return (
		<>
			<div className="leftNavOverlay"></div>
			<ul className="navbar-nav leftNav">
				{menuData}
				{dropdownItemsData.length > 0 && (
					<li className="nav-item dropdown">
						<button
							className="noBtn-style dropdown-toggle threeDots-btn"
							id="dropdownMenuButton"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							aria-label="More">
							<span className="fIcon icon-more1 threeDot"></span>
						</button>
						<ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
							{dropdownItemsData.map((dropdownItem: any, key: any) =>
								renderMenuItem(dropdownItem, key),
							)}
						</ul>
					</li>
				)}
			</ul>
		</>
	);
}

export default NavBarItems;
