export type Deferred<T> = {
	promise: Promise<T>;
	resolve(value: T | PromiseLike<T>): void;
	reject(reason?: any): void;
}

/**
 * This method generates a deferred promise instance.
 * @returns {Deferred} implementation for deferred promise contaning reference to promise, reject and resolve functions
 */
export function generateDeferredPromise<T>(): Deferred<T> {
	let resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void;
	resolve = (_value: T | PromiseLike<T>) => {
		throw new Error('resolve function has not yet been initialized');
	};

	reject = (_reason?: any) => {
		throw new Error('reject function has not yet been initialized');
	};
	const promise = new Promise<T>((_resolve, _reject) => {
		[resolve, reject] = [_resolve, _reject];
	});
	return {
		promise,
		resolve,
		reject,
	};
}
