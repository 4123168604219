import {createContext, useEffect, useState} from 'react';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import {sessionStorageKeys} from '../../common/src/interface/storage/constants/SessionKeys';
import PatientBanner from './PatientBanner.component';
import encryptedStorageHelperInstance from '../../storage/EncryptedStorageHelper';
import {isValueAvailable} from '../../common/src/utils/StringUtils';
import PatientSearchViewModel from '../../common/src/application/modules/patientSearch/viewModels/PatientSearchViewModel';
import Container from 'typedi';

export const PatientDetailsContext = createContext<IPatientContext>({
	patientDetails: {},
	patientId: 0,
});

export type IPatientContext = {
	patientDetails: Record<string, any>;
	patientId: number;
};

const PatientDetailsComponent = (): JSX.Element => {
	const location = useLocation();

	var patientId: any = useParams().patientId;
	if (!patientId) {
		patientId = location.state;
	}
	if (!patientId) {
		patientId = encryptedStorageHelperInstance.getItemSync(
			sessionStorageKeys.PATIENT_ID,
		);
	}

	const [patientDetails, setPatientDetails] = useState(
		encryptedStorageHelperInstance.getItemSync(
			sessionStorageKeys.PATIENT_DETAILS,
		),
	);

	const patientSearchViewModel: PatientSearchViewModel = Container.get(
		PatientSearchViewModel,
	);

	const fetchPatientDetails = () => {
		patientSearchViewModel
			.fetchPatientDetails(patientId)
			.then((patientResponse: any) => {
				setPatientDetails(
					encryptedStorageHelperInstance.getItemSync(
						sessionStorageKeys.PATIENT_DETAILS,
					),
				);
			});
	};

	useEffect(() => {
		if (!isValueAvailable(patientDetails) && isValueAvailable(patientId)) {
			fetchPatientDetails();
		}
	}, [location, location.pathname, patientDetails, patientId]);

	return (
		<>
			<PatientDetailsContext.Provider
				value={{
					patientDetails,
					patientId,
				}}
			>
				{isValueAvailable(patientDetails) && patientId && <PatientBanner />}
				<Outlet></Outlet>
			</PatientDetailsContext.Provider>
		</>
	);
};

export default PatientDetailsComponent;
