import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class DocumentReferenceSearchCriteriaRequestModel implements BaseRequestModel {
    private readonly constraints?: SearchCriteriaData;

    constructor({
                    constraints,
                }: DocumentReferenceSearchData) {
        this.constraints = constraints;
    }

    /**
     * returns document reference search data request
     * @returns {DocumentReferenceSearchData}
     */
    getRequestDataObject(): DocumentReferenceSearchData {
        return {
            constraints: this.constraints
        }
    }
}

export type DocumentReferenceSearchData = {
    constraints?: SearchCriteriaData
}

export type SearchCriteriaData = {
    _fields?: string,
    subject?: number
}


export default DocumentReferenceSearchCriteriaRequestModel;