import {
	ConfigProvider,
	UICOMPS_CONFIG,
} from '../../../ui/comps/src/utils/config.mapper';

export const configTransformer = (appConfig: any): UICOMPS_CONFIG => {
	let uiCompsConfig: UICOMPS_CONFIG = {
		applicationServerUri: appConfig.urls.applicationServer,
	};

	//map all configs here which we need in UIcomps from the ApplicationConfig
	uiCompsConfig.defaultCountry = appConfig.defaultCountry;
	uiCompsConfig.resendTimerInSeconds = appConfig.resendTimerInSeconds;
	uiCompsConfig.countries = appConfig.countries;
	uiCompsConfig.passwordConstraints = appConfig.passwordConstraints;
	uiCompsConfig.photoUploader = appConfig.photoUploader;
	uiCompsConfig.supportedImageTypes = appConfig.supportedImageTypes;
	uiCompsConfig.datesFormatsObject = appConfig.dates;
	uiCompsConfig.optionalToggle = appConfig.optionalToggle;

	return uiCompsConfig;
};

export class ConfigSingleton implements ConfigProvider {
	private static obj: ConfigSingleton | null;
	private config: UICOMPS_CONFIG;

	constructor() {
		this.config = configTransformer(global.ConfigurationHolder);
	}
	static getInstance() {
		if (this.obj == null) {
			console.log('creating new config mapper obj for UIcomps');
			this.obj = new ConfigSingleton();
		}
		return this.obj;
	}
	public getConfig(): UICOMPS_CONFIG {
		return this.config;
	}
}
