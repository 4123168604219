export const nextPage = (
	pageSize: number,
	totalRecords: number,
	skip: number,
): [number, number] => {

	let _skip = skip;
	let _count = totalRecords - skip;

	if (skip + pageSize < totalRecords) {
		_skip = skip + pageSize;
		if (skip + 2 * pageSize > totalRecords) {
			_count = totalRecords - (pageSize + skip);
		} else {
			_count = pageSize;
		}
	}

	return [_skip, _count];
};

export const previousPage = (
	pageSize: number,
	skip: number,
): [number, number] => {
	let _skip = skip;
	let _count = pageSize;

	if (skip - pageSize >= 0) {
		_skip = skip - pageSize;
	}

	return [_skip, _count];
};
