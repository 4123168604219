import {lazy, Suspense} from 'react';
import {Route} from 'react-router-dom';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import PrivateRoute from '../../PrivateRoute';
import Loader from '../../ui/comps/src/Loader';

const HelpDeskQueryList = lazy(() => import('./queryList/HelpDeskQueryList'));

const accessTo: Array<string> = ['PATIENT'];
const HelpDeskRoutes = (
	<>
		<Route path="/" element={<PrivateRoute accessTo={accessTo} />}>
			{/* Add authenticated routes under privateRoutes */}
			<Route
				path={RoutePathInfo.helpDesk.path}
				element={
					<Suspense fallback={<Loader />}>
						<HelpDeskQueryList />
					</Suspense>
				}
			/>
		</Route>
	</>
);

export default HelpDeskRoutes;
