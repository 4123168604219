import {strings} from "../../../../../../../localization/i18n";

export enum CompositeFormStatus {
	DRAFT = 'draft',
	COMPLETED = 'completed',
	NEW = 'new',
	CANCELLED = 'cancelled'
}

export const getCompositeFormStatusString = (type: CompositeFormStatus) => {
	switch (type) {
		case CompositeFormStatus.CANCELLED:
			return strings('compositeForms.list.status.cancelled')
		case CompositeFormStatus.COMPLETED:
			return strings('compositeForms.list.status.submitted')
		case CompositeFormStatus.DRAFT:
			return strings('compositeForms.list.status.inProgress')
		case CompositeFormStatus.NEW:
			return strings('compositeForms.list.status.new')
		default:
			return type
	}
};