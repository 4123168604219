import BaseResponseModel from '../../../../network/interface/BaseResponseModel';

class BulkActionResponseModel implements BaseResponseModel {
	private readonly status?: boolean;
	private readonly message?: string;
	private readonly code?: string;
	private readonly responseStatus?: number;
	private readonly bulkActionRequest?: string;

	constructor({
		status,
		message,
		code,
		responseStatus,
		bulkActionRequest,
	}: BulkRequestResponseData) {
		this.status = status;
		this.message = message;
		this.code = code;
		this.responseStatus = responseStatus;
		this.bulkActionRequest = bulkActionRequest;
	}

	/**
	 * returns bulk download action response
	 * @returns {BulkRequestResponseData}
	 */
	getResponseDataObject(): BulkRequestResponseData {
		return {
			status: this.status,
			message: this.message,
			code: this.code,
			responseStatus: this.responseStatus,
			bulkActionRequest: this.bulkActionRequest,
		};
	}
}

export type BulkRequestResponseData = {
	status?: boolean;
	message?: string;
	code?: string;
	responseStatus?: number;
	bulkActionRequest?: string;
};

export default BulkActionResponseModel;
