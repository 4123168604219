import BaseRequestModel from '../../../../network/interface/BaseRequestModel';

class EncounterSearchRequestModel implements BaseRequestModel {
	private readonly patient?: any;
	private readonly field?: string;
	private readonly statusNot?: string;

	constructor({patient, field, statusNot}: EncounterSearchData) {
		this.patient = patient;
		this.field = field;
		this.statusNot = statusNot;
	}

	/**
	 * returns encounter search request
	 * @returns {Record<string, any>}
	 */
	getRequestDataObject(): Record<string, any> {
		return {
			constraints: {
				patient: this.patient,
				_fields: this.field,
				'status:not': this.statusNot,
			},
		};
	}

	getParsedDataObject(): EncounterSearchData {
		return {
			patient: this.patient,
			field: this.field,
			statusNot: this.statusNot,
		};
	}
}

export type EncounterSearchData = {
	patient?: any;
	field?: string;
	statusNot?: string;
};

export default EncounterSearchRequestModel;
