import {strings} from '../../../../../../localization/i18n';
import ErrorText from '../components/ErrorText';
import HelpText from '../components/HelpText';
import Label from '../components/Label';
import {useState} from 'react';

import PasswordStrength from '../components/PasswordStrength/PasswordStrength';
import PasswordValidity from '../components/PasswordStrength/PasswordValidity';

import {generateValidationMap} from './validations';
import {FieldType} from '../../types/FieldTypeEnum';
import _ from 'lodash';

const PasswordComponent = (props: any): JSX.Element => {
	const {
		fieldData,
		register,
		errors,
		control,
		callbackHandler,
		configProvider,
		dynamicConstraints,
		isViewOnlyMode,
	} = props;
	const {
		label,
		name,
		visible,
		constraints,
		placeholder,
		helpText,
		defaultValue,
		useValidator = false,
		showValidator = false,
		showStrength = false,
		attributeCssClassName,
	} = fieldData;

	const className = attributeCssClassName;

	const [showPassword, setShowPassword] = useState(false);

	const passwordConstraints =
		configProvider && configProvider.getConfig()
			? configProvider?.getConfig()?.passwordConstraints
			: {
					minLength: 8,
					maxLength: 64,
					specialCharacters: true,
					upperCaseCharacters: true,
					numericalCharacters: true,
			  };

	const showPasswordHandler = function (e: any) {
		e.preventDefault();
		setShowPassword(!showPassword);
	};

	const classForPasswordShow = showPassword ? 'icon-hide_password' : '';
	const hideForPassword = showPassword
		? 'accessibility.showPassword'
		: 'accessibility.hidePassword';

	const validationMap =
		useValidator || showValidator
			? generateValidationMap(passwordConstraints)
			: {};

	return (
		<>
			{(visible != null ? visible : true) && (
				<div
					id={`div_om_passwordComp_${name}`}
					className={
						className
							? `form-item has-hintIcon delete ${className}`
							: 'form-item has-hintIcon delete'
					}>
					<div id={`div_passwordComp_${name}`} className="form-group ">
						<div className={'formLabel'}>
							<Label
								htmlFor={name}
								content={label}
								required={dynamicConstraints?.required ?? false}
								configProvider={configProvider}
							/>
						</div>
						<div className={'formInput'}>
							<div id={`div_im_passwordComp_${name}`} className="rel">
								<input
									type={showPassword ? 'text' : 'password'}
									id={name}
									aria-describedby={`error_${name} ${name}_helpText_${name}`}
									disabled={
										isViewOnlyMode === true
											? isViewOnlyMode
											: dynamicConstraints?.disabled ?? false
									}
									placeholder={
										placeholder != null
											? strings(placeholder)
											: strings(`form.placeholder.password`, {
													label: strings(label),
											  })
									}
									{...register(`${name}`, {
										required: {
											value: dynamicConstraints?.required ?? false,
											message: strings(constraints?.required?.message) ?? '',
										},
										maxLength: {
											value: parseInt(constraints?.maxLength?.value) ?? null,
											message: constraints?.maxLength?.message ?? '',
										},
										minLength: {
											value: parseInt(constraints?.minLength?.value) ?? null,
											message: constraints?.minLength?.message ?? '',
										},
										validate: {
											...validationMap,
										},
										shouldUnregister: true,
										value: defaultValue,
									})}
									className="form-control"
									{...callbackHandler}
								/>
								<button
									id={`showPasswordBtn_${name}`}
									type="button"
									onClick={showPasswordHandler}
									title={strings('accessibility.showPassword')}
									aria-label={strings(hideForPassword)}
									className={
										'btn-nostyles fIcon iconSize1 icon-show_password iconDelete removeUnderline ' +
										classForPasswordShow
									}></button>
							</div>
							{showValidator && (
								<PasswordValidity
									validationMap={validationMap}
									control={control}
									name={name}
									passwordConstraints={passwordConstraints}
								/>
							)}
							<ErrorText
								errorObject={_.get(errors, name)}
								name={name}
								label={label}
								fieldType={FieldType.TEXT}
							/>
							{!_.get(errors, name) && (
								<HelpText content={helpText} name={name} />
							)}

							{showStrength && (
								<PasswordStrength
									control={control}
									name={name}
									passwordConstraints={passwordConstraints}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default PasswordComponent;
