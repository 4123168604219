import HelpText from './components/HelpText';
import Label from './components/Label';

const LabelComponent = (props: any): JSX.Element => {
	const {fieldData, dynamicConstraints} = props;
	const {
		label,
		name,
		visible,
		helpText,
		required,
		configProvider,
		attributeCssClassName,
	} = fieldData;

	return (
		<>
			{(dynamicConstraints.visible != null
				? dynamicConstraints.visible
				: true) && (
				<div
					id={`div_labelComp_${name}`}
					className={attributeCssClassName ?? ''}>
					<Label
						htmlFor={name}
						content={label}
						required={
							required ? required : dynamicConstraints?.required ?? false
						}
						configProvider={configProvider}
					/>
					<HelpText content={helpText} name={name} />
				</div>
			)}
		</>
	);
};

export default LabelComponent;
