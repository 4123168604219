import BaseRequestModel from "../../../../network/interface/BaseRequestModel";

export default class DownloadFormRequest implements BaseRequestModel{
    private readonly _formRequestId: number | undefined;
    private readonly _mime: string | undefined;
    private readonly _extension: string | undefined;

    constructor({formRequestId, mime, extension}: DownloadFormRequestType) {
        this._formRequestId = formRequestId;
        this._mime = mime
        this._extension = extension
    }

    /**
     * This method is used to get the request body to be sent
     * in /minerva/formResponse/downloadFormResponse API call
     * @returns {DownloadFormRequestType}
     */
    getRequestDataObject(): DownloadFormRequestType {
        return {
            formRequestId: this._formRequestId,
            mime: this._mime,
            extension: this._extension,
        }
    }

}
export type DownloadFormRequestType = {
    formRequestId?: number;
    mime?: string;
    extension?: string;
};
