import {selectedLanguage, strings} from '../../../../localization/i18n';
import React, {useState, useRef, useEffect} from 'react';
import {removeClass} from '../../../../ui/comps/src/utils/uiCompsUtils';
import {
	removeAccents,
	sanitizeMongoRegexSearch,
} from '../../../../utils/filters/filters';
import {
	convertDateToGMTFormat,
	fromTimezone,
	HoursToSetTypeEnum,
	toTimezone,
} from '../../../../common/src/utils/DateTimeUtils';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AuditEnum} from '../../../../common/src/enums/AuditEnums';
import {CompositeFormsFilterConfig} from '../../../../common/src/application/modules/forms/composite/models/CompositeFormListModel';
import CalendarIcon from '../../../../ui/comps/src/dynamic-form/form-components/CalendarIcon';

const FormsSearchColumn = (props: any): JSX.Element => {
	const {
		searchRequestData,
		formsSearch,
		configDetails,
	}: {
		searchRequestData: Record<string, any>;
		formsSearch: (data: Record<string, any>) => void;
		configDetails: CompositeFormsFilterConfig;
	} = props;

	const uploadedFormTypes = configDetails.uploadedFormTypes;
	const defaultFormStatus = configDetails.defaultFormStatus;
	const formReferenceTypes = configDetails.formReferenceTypes;
	const formEncounter = configDetails.formEncounter;

	const formNameRef = useRef<any>();
	const formStatusRef = useRef<any>();
	const submittedByRef = useRef<any>();
	const referenceRef = useRef<any>();
	const visitRef = useRef<any>();

	useEffect(() => {
		if (formReferenceTypes.length == 1) {
			referenceRef.current.value = formReferenceTypes[0];
			showFieldsBasedOnReference();
		}
	}, [formReferenceTypes]);

	const [dateFilterType, setDateFilterType] = useState<string>(
		AuditEnum.ON_DATE,
	);

	const [visitDateFilterType, setVisitDateFilterType] = useState<string>(
		AuditEnum.ON_DATE,
	);

	const [onDate, setOnDate] = useState<Date | null | undefined>(null);
	const [toDate, setToDate] = useState<Date | null | undefined>(null);
	const [fromDate, setFromDate] = useState<Date | null | undefined>(null);

	const [visitOnDate, setVisitOnDate] = useState<Date | null | undefined>(null);
	const [visitToDate, setVisitToDate] = useState<Date | null | undefined>(null);
	const [visitFromDate, setVisitFromDate] = useState<Date | null | undefined>(
		null,
	);

	const [showVisitLocation, setShowVisitLocation] = useState<boolean>(false);
	const [showVisitDate, setShowVisitDate] = useState<boolean>(false);

	const [langLocale, setLangLocale] = useState<Locale | undefined>();

	useEffect(() => {
		const language = selectedLanguage();
		if (language !== 'en') {
			import(`date-fns/locale/${selectedLanguage()}`).then(localeModule => {
				setLangLocale(localeModule.default);
			});
		} else {
			setLangLocale(undefined);
		}
	}, []);

	const visitOnDateChangeHandler = (date: Date | null | undefined) => {
		setVisitOnDate(date);
	};

	const visitFromDateChangeHandler = (date: Date | null | undefined) => {
		setVisitFromDate(date);
	};

	const visitToDateChangeHandler = (date: Date | null | undefined) => {
		setVisitToDate(date);
	};

	const onDateChangeHandler = (date: Date | null | undefined) => {
		setOnDate(date);
	};

	const fromDateChangeHandler = (date: Date | null | undefined) => {
		setFromDate(date);
	};

	const toDateChangeHandler = (date: Date | null | undefined) => {
		setToDate(date);
	};

	const showFieldsBasedOnReference = () => {
		if (referenceRef.current.value === 'Visit') {
			setShowVisitLocation(true);
			setShowVisitDate(true);
		}
	};

	const handleSearchAction = () => {
		if (formNameRef?.current?.value) {
			searchRequestData.formType = formNameRef.current.value;
		}

		if (formStatusRef?.current?.value) {
			searchRequestData.status = formStatusRef.current.value;
		}

		if (submittedByRef?.current?.value) {
			searchRequestData.lastUpdatedBy = submittedByRef.current.value;
		}

		let startDateQuery: string;
		let endDateQuery: string;

		if (dateFilterType === AuditEnum.ON_DATE && onDate) {
			startDateQuery =
				'ge' +
				convertDateToGMTFormat(
					fromTimezone(new Date(onDate)),
					HoursToSetTypeEnum.START_DAY,
				);
			endDateQuery =
				'lt' +
				convertDateToGMTFormat(
					toTimezone(new Date(onDate)),
					HoursToSetTypeEnum.END_DAY,
				);
			searchRequestData.submittedDate = [startDateQuery, endDateQuery];
		} else if (dateFilterType === AuditEnum.TO_FROM_DATE) {
			if (fromDate != null && toDate != null) {
				startDateQuery =
					'ge' +
					convertDateToGMTFormat(
						fromTimezone(new Date(fromDate)),
						HoursToSetTypeEnum.START_DAY,
					);
				endDateQuery =
					'lt' +
					convertDateToGMTFormat(
						toTimezone(new Date(toDate)),
						HoursToSetTypeEnum.END_DAY,
					);
				searchRequestData.submittedDate = [startDateQuery, endDateQuery];
			} else if (fromDate != null) {
				startDateQuery =
					'ge' +
					convertDateToGMTFormat(
						fromTimezone(new Date(fromDate)),
						HoursToSetTypeEnum.START_DAY,
					);
				searchRequestData.submittedDate = [startDateQuery];
			} else if (toDate != null) {
				endDateQuery =
					'lt' +
					convertDateToGMTFormat(
						toTimezone(new Date(toDate)),
						HoursToSetTypeEnum.END_DAY,
					);
				searchRequestData.submittedDate = [endDateQuery];
			}
		}

		if (visitRef?.current?.value) {
			searchRequestData.encounter = visitRef.current.value;
		}

		let visitStartDateQuery: string;
		let visitEndDateQuery: string;

		if (visitDateFilterType === AuditEnum.ON_DATE && visitOnDate) {
			visitStartDateQuery =
				'ge' +
				convertDateToGMTFormat(
					fromTimezone(new Date(visitOnDate)),
					HoursToSetTypeEnum.START_DAY,
				);
			visitEndDateQuery =
				'lt' +
				convertDateToGMTFormat(
					toTimezone(new Date(visitOnDate)),
					HoursToSetTypeEnum.END_DAY,
				);
			searchRequestData.encounterDate = [
				visitStartDateQuery,
				visitEndDateQuery,
			];
		} else if (visitDateFilterType === AuditEnum.TO_FROM_DATE) {
			if (visitFromDate != null && visitToDate != null) {
				visitStartDateQuery =
					'ge' +
					convertDateToGMTFormat(
						fromTimezone(new Date(visitFromDate)),
						HoursToSetTypeEnum.START_DAY,
					);
				visitEndDateQuery =
					'lt' +
					convertDateToGMTFormat(
						toTimezone(new Date(visitToDate)),
						HoursToSetTypeEnum.END_DAY,
					);
				searchRequestData.encounterDate = [
					visitStartDateQuery,
					visitEndDateQuery,
				];
			} else if (visitFromDate != null) {
				visitStartDateQuery =
					'ge' +
					convertDateToGMTFormat(
						fromTimezone(new Date(visitFromDate)),
						HoursToSetTypeEnum.START_DAY,
					);
				searchRequestData.encounterDate = [visitStartDateQuery];
			} else if (visitToDate != null) {
				visitEndDateQuery =
					'lt' +
					convertDateToGMTFormat(
						toTimezone(new Date(visitToDate)),
						HoursToSetTypeEnum.END_DAY,
					);
				searchRequestData.encounterDate = [visitEndDateQuery];
			}
		}

		formsSearch(searchRequestData);
	};

	const handleReset = () => {
		if (formNameRef?.current?.value) {
			formNameRef.current.value = '';
		}

		if (formStatusRef?.current?.value) {
			formStatusRef.current.value = '';
		}

		if (submittedByRef?.current?.value) {
			submittedByRef.current.value = '';
		}

		if (referenceRef?.current?.value) {
			referenceRef.current.value = '';
		}

		if (visitRef?.current?.value) {
			visitRef.current.value = '';
		}

		setShowVisitLocation(false);
		setShowVisitDate(false);

		setOnDate(null);
		setToDate(null);
		setFromDate(null);

		setVisitOnDate(null);
		setVisitToDate(null);
		setVisitFromDate(null);
	};

	return (
		<>
			<div className="outer-box-left">
				<div className="d-flex">
					<div
						className="left-back-button"
						onClick={() =>
							removeClass('leftFilterViewer', 'outer-box-filterOverlay')
						}
					>
						<button className="btn noBtnStyle">
							<span className="sr-only">Hide Filter</span>{' '}
							<span aria-hidden="true" className="fIcon icon-back2"></span>{' '}
						</button>
					</div>
					<div>
						<h2>{strings('formsListing.searchFilter.title')}</h2>
					</div>
					<div className="resetBtn">
						<button
							className="btn btn-link flip pull-right btn-sm noBtnStyle"
							onClick={handleReset}
						>
							{strings('formsListing.searchFilter.resetBtn')}
						</button>
					</div>
				</div>
				<form className="d-block mt-3" id="formsSearchForm">
					<div className="form-vertical">
						{
							<>
								<div className="accordion-outer accordion-filter">
									<div className="accordion condensed" id="formsSearchSection">
										<div className="card">
											<div className="card-header" id="forms-collapse-heading">
												<h2 className="mb-0">
													<button
														className="btn btn-link btn-block text-left"
														type="button"
														data-toggle="collapse"
														data-target="#forms-collapse"
														aria-expanded="true"
														aria-controls="forms-collapse"
													>
														{strings(
															'formsListing.searchFilter.formDetailsSection.title',
														)}
													</button>
												</h2>
											</div>
											<div
												id="forms-collapse"
												className="collapse show"
												aria-labelledby="forms-collapse-heading"
												data-parent="#formsSearchSection"
											>
												<div className="card-body">
													{configDetails.formListSearchFilters.formName
														.visible && (
														<div className="form-item">
															<div className="form-group">
																<label htmlFor="formsListing-formName">
																	{strings(
																		'formsListing.searchFilter.formDetailsSection.labels.formName',
																	)}
																</label>
																<div className="input-container">
																	<select
																		name="formName"
																		className="form-control"
																		id="formsListing-formName"
																		ref={formNameRef}
																	>
																		<option value="">
																			{strings(
																				'formsListing.searchFilter.formDetailsSection.placeHolders.formName',
																			)}
																		</option>
																		{uploadedFormTypes.map(
																			(formType: string, key) => {
																				return (
																					<option value={formType} key={key}>
																						{strings(
																							`compositeForms.list.${formType}`,
																						)}
																					</option>
																				);
																			},
																		)}
																	</select>
																</div>
															</div>
														</div>
													)}
													{configDetails.formListSearchFilters.formStatus
														.visible && (
														<div className="form-item">
															<div className="form-group">
																<label htmlFor="formsListing-status">
																	{strings(
																		'formsListing.searchFilter.formDetailsSection.labels.status',
																	)}
																</label>
																<div className="input-container">
																	<select
																		name="status"
																		className="form-control"
																		id="formsListing-status"
																		ref={formStatusRef}
																	>
																		<option value="">
																			{strings(
																				'formsListing.searchFilter.formDetailsSection.placeHolders.status',
																			)}
																		</option>
																		{defaultFormStatus.map(
																			(formStatus: string, key) => {
																				return (
																					<option value={formStatus} key={key}>
																						{strings(
																							'formsListing.status.values.' +
																								formStatus,
																						)}
																					</option>
																				);
																			},
																		)}
																	</select>
																</div>
															</div>
														</div>
													)}
													{configDetails.formListSearchFilters.submittedBy
														.visible && (
														<div className="form-item">
															<div className="form-group">
																<label htmlFor="formsListing-submittedBy">
																	{strings(
																		'formsListing.searchFilter.formDetailsSection.labels.submittedBy',
																	)}
																</label>
																<div className="input-container">
																	<input
																		type="text"
																		name="submittedByString"
																		id="formsListing-submittedBy"
																		className="form-control"
																		placeholder={strings(
																			'formsListing.searchFilter.formDetailsSection.placeHolders.submittedBy',
																		)}
																		ref={submittedByRef}
																	/>
																</div>
															</div>
														</div>
													)}
													{configDetails.formListSearchFilters.submittedDate
														.visible && (
														<>
															<label htmlFor="formsListing-submittedDate">
																{strings(
																	'formsListing.searchFilter.formDetailsSection.labels.submittedDate',
																)}
															</label>
															<div className="form-check-block">
																{
																	<>
																		<input
																			className="form-check-input"
																			type="radio"
																			name="submittedDate-dateRange"
																			id="formsListing-submittedDate"
																			value="date"
																			checked={
																				dateFilterType === AuditEnum.ON_DATE
																			}
																			onChange={() => {
																				setDateFilterType(AuditEnum.ON_DATE);
																				setFromDate(null);
																				setToDate(null);
																			}}
																		/>
																		<label
																			className="form-check-label"
																			htmlFor="formsListing-date"
																		>
																			{strings(
																				'formsListing.searchFilter.formDetailsSection.labels.date',
																			)}
																		</label>
																		<div className="clearfix"></div>
																	</>
																}
																{
																	<>
																		<div className="form-group mt-2 mb0">
																			<div className="input-group">
																				<LocalizationProvider
																					dateAdapter={AdapterDateFns}
																					locale={langLocale}
																				>
																					<DatePicker
																						value={onDate}
																						onChange={onDate => {
																							onDateChangeHandler(onDate);
																						}}
																						inputFormat={
																							global.ConfigurationHolder?.dates
																								?.viewDateFormat
																						}
																						disabled={
																							dateFilterType !==
																							AuditEnum.ON_DATE
																						}
																						components={{
																							OpenPickerIcon: CalendarIcon,
																						}}
																						renderInput={({
																							inputRef,
																							inputProps,
																							InputProps,
																						}) => (
																							<div
																								style={{
																									display: 'flex',
																									alignItems: 'center',
																									position: 'relative',
																								}}
																								className="customDatePicker"
																							>
																								<input
																									ref={inputRef}
																									className="form-control"
																									{...inputProps}
																									placeholder={strings(
																										'forms.attributes.datePicker.dataFormats.' +
																											global.ConfigurationHolder?.dates?.viewDateFormat?.toLowerCase(),
																									)}
																								/>
																								{InputProps?.endAdornment}
																							</div>
																						)}
																						className="form-control"
																					/>
																				</LocalizationProvider>
																			</div>
																		</div>
																	</>
																}
															</div>
														</>
													)}
													{configDetails.formListSearchFilters.submittedDate
														.visible && (
														<>
															<div className="form-check-block mt-3">
																{
																	<>
																		<input
																			className="form-check-input"
																			type="radio"
																			name="submittedDate-dateRange"
																			id="formsListing-dateRange"
																			value="dateRange"
																			checked={
																				dateFilterType ===
																				AuditEnum.TO_FROM_DATE
																			}
																			onChange={() => {
																				setDateFilterType(
																					AuditEnum.TO_FROM_DATE,
																				);
																				setOnDate(null);
																			}}
																		/>
																		<label
																			className="form-check-label"
																			htmlFor="formsListing-dateRange"
																		>
																			{strings(
																				'formsListing.searchFilter.formDetailsSection.labels.dateRange',
																			)}
																		</label>
																	</>
																}

																<div className="clearfix"></div>

																{
																	<>
																		<div className="form-group mt-2 mb0">
																			<div className="input-group">
																				<LocalizationProvider
																					dateAdapter={AdapterDateFns}
																					locale={langLocale}
																				>
																					<DatePicker
																						value={fromDate}
																						onChange={fromDate => {
																							fromDateChangeHandler(fromDate);
																						}}
																						disabled={
																							dateFilterType !==
																							AuditEnum.TO_FROM_DATE
																						}
																						components={{
																							OpenPickerIcon: CalendarIcon,
																						}}
																						renderInput={({
																							inputRef,
																							inputProps,
																							InputProps,
																						}) => (
																							<div
																								style={{
																									display: 'flex',
																									alignItems: 'center',
																									position: 'relative',
																								}}
																								className="customDatePicker"
																							>
																								<input
																									ref={inputRef}
																									className="form-control"
																									{...inputProps}
																									placeholder={strings(
																										'formsListing.searchFilter.formDetailsSection.fromDatePlaceholder',
																									)}
																								/>
																								{InputProps?.endAdornment}
																							</div>
																						)}
																						className="form-control"
																					/>
																				</LocalizationProvider>
																			</div>
																		</div>
																	</>
																}
																{
																	<>
																		<div className="form-group mt-2 mb0">
																			<div className="input-group">
																				<LocalizationProvider
																					dateAdapter={AdapterDateFns}
																					locale={langLocale}
																				>
																					<DatePicker
																						value={toDate}
																						onChange={toDate => {
																							toDateChangeHandler(toDate);
																						}}
																						disabled={
																							dateFilterType !==
																							AuditEnum.TO_FROM_DATE
																						}
																						components={{
																							OpenPickerIcon: CalendarIcon,
																						}}
																						renderInput={({
																							inputRef,
																							inputProps,
																							InputProps,
																						}) => (
																							<div
																								style={{
																									display: 'flex',
																									alignItems: 'center',
																									position: 'relative',
																								}}
																								className="customDatePicker"
																							>
																								<input
																									ref={inputRef}
																									className="form-control"
																									{...inputProps}
																									placeholder={strings(
																										'formsListing.searchFilter.formDetailsSection.toDatePlaceholder',
																									)}
																								/>
																								{InputProps?.endAdornment}
																							</div>
																						)}
																						className="form-control"
																					/>
																				</LocalizationProvider>
																			</div>
																		</div>
																	</>
																}
															</div>
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr className="leftFilter-divider" />
								<div className="accordion-outer accordion-filter">
									<div
										className="accordion condensed"
										id="additionalDetailsSection"
									>
										<div className="card">
											<div
												className="card-header"
												id="additionalDetails-collapse-heading"
											>
												<h2 className="mb-0">
													<button
														className="btn btn-link btn-block text-left"
														type="button"
														data-toggle="collapse"
														data-target="#additionalDetails-collapse"
														aria-expanded="true"
														aria-controls="additionalDetails-collapse"
													>
														{strings(
															'formsListing.searchFilter.additionalDetailsSection.title',
														)}
													</button>
												</h2>
											</div>
											<div
												id="additionalDetails-collapse"
												className="collapse show"
												aria-labelledby="additionalDetails-collapse-heading"
												data-parent="#formsSearchSection"
											>
												<div className="card-body">
													{configDetails.formListSearchFilters.reference
														.visible && (
														<div className="form-item">
															<div className="form-group">
																<label htmlFor="formsListing-reference">
																	{strings(
																		'formsListing.searchFilter.additionalDetailsSection.labels.reference',
																	)}
																</label>
																<div className="input-container">
																	<select
																		name="reference"
																		className="form-control"
																		id="formsListing-reference"
																		ref={referenceRef}
																		onChange={() => {
																			showFieldsBasedOnReference();
																		}}
																	>
																		<option value="">
																			{strings(
																				'formsListing.searchFilter.additionalDetailsSection.placeHolders.reference',
																			)}
																		</option>
																		{formReferenceTypes.map(
																			(formReferenceType: string, key) => {
																				return (
																					<option
																						value={formReferenceType}
																						key={key}
																					>
																						{formReferenceType}
																					</option>
																				);
																			},
																		)}
																	</select>
																</div>
															</div>
														</div>
													)}
													{configDetails.formListSearchFilters.reference
														.visible &&
														showVisitLocation && (
															<div className="form-item">
																<div className="form-group">
																	<label htmlFor="formsListing-visitLocation">
																		{strings(
																			'formsListing.searchFilter.additionalDetailsSection.labels.visitLocation',
																		)}
																	</label>
																	<div className="input-container">
																		<select
																			name="visitLocation"
																			className="form-control"
																			id="formsListing-visitLocation"
																			ref={visitRef}
																		>
																			<option value="">
																				{strings(
																					'formsListing.searchFilter.additionalDetailsSection.placeHolders.visitLocation',
																				)}
																			</option>
																			{formEncounter.map(
																				(
																					encounter: Record<string, any>,
																					key,
																				) => {
																					return (
																						<option
																							value={encounter.id}
																							key={key}
																						>
																							{encounter.locationName}
																						</option>
																					);
																				},
																			)}
																		</select>
																	</div>
																</div>
															</div>
														)}
													{configDetails.formListSearchFilters.reference
														.visible &&
														showVisitDate && (
															<>
																<label htmlFor="formsListing-visitDate">
																	{strings(
																		'formsListing.searchFilter.additionalDetailsSection.labels.visitDate',
																	)}
																</label>
																<div className="form-check-block">
																	{
																		<>
																			<input
																				className="form-check-input"
																				type="radio"
																				name="visitDate-dateRange"
																				id="formsListing-visitDate"
																				value="date"
																				checked={
																					visitDateFilterType ===
																					AuditEnum.ON_DATE
																				}
																				onChange={() => {
																					setVisitDateFilterType(
																						AuditEnum.ON_DATE,
																					);
																					setVisitFromDate(null);
																					setVisitToDate(null);
																				}}
																			/>
																			<label
																				className="form-check-label"
																				htmlFor="formsListing-visitDate"
																			>
																				{strings(
																					'formsListing.searchFilter.additionalDetailsSection.labels.date',
																				)}
																			</label>
																			<div className="clearfix"></div>
																		</>
																	}
																	{
																		<>
																			<div className="form-group mt-2 mb0">
																				<div className="input-group">
																					<LocalizationProvider
																						dateAdapter={AdapterDateFns}
																						locale={langLocale}
																					>
																						<DatePicker
																							value={visitOnDate}
																							onChange={visitOnDate => {
																								visitOnDateChangeHandler(
																									visitOnDate,
																								);
																							}}
																							inputFormat={
																								global.ConfigurationHolder
																									?.dates?.viewDateFormat
																							}
																							disabled={
																								visitDateFilterType !==
																								AuditEnum.ON_DATE
																							}
																							components={{
																								OpenPickerIcon: CalendarIcon,
																							}}
																							renderInput={({
																								inputRef,
																								inputProps,
																								InputProps,
																							}) => (
																								<div
																									style={{
																										display: 'flex',
																										alignItems: 'center',
																										position: 'relative',
																									}}
																									className="customDatePicker"
																								>
																									<input
																										ref={inputRef}
																										className="form-control"
																										{...inputProps}
																										placeholder={strings(
																											'forms.attributes.datePicker.dataFormats.' +
																												global.ConfigurationHolder?.dates?.viewDateFormat?.toLowerCase(),
																										)}
																									/>
																									{InputProps?.endAdornment}
																								</div>
																							)}
																							className="form-control"
																						/>
																					</LocalizationProvider>
																				</div>
																			</div>
																		</>
																	}
																</div>
															</>
														)}
													{configDetails.formListSearchFilters.reference
														.visible &&
														showVisitDate && (
															<>
																<div className="form-check-block mt-3">
																	{
																		<>
																			<input
																				className="form-check-input"
																				type="radio"
																				name="visitDate-dateRange"
																				id="formsListing-visitDateRange"
																				value="dateRange"
																				checked={
																					visitDateFilterType ===
																					AuditEnum.TO_FROM_DATE
																				}
																				onChange={() => {
																					setVisitDateFilterType(
																						AuditEnum.TO_FROM_DATE,
																					);
																					setVisitOnDate(null);
																				}}
																			/>
																			<label
																				className="form-check-label"
																				htmlFor="formsListing-visitDateRange"
																			>
																				{strings(
																					'formsListing.searchFilter.additionalDetailsSection.labels.dateRange',
																				)}
																			</label>
																		</>
																	}

																	<div className="clearfix"></div>

																	{
																		<>
																			<div className="form-group mt-2 mb0">
																				<div className="input-group">
																					<LocalizationProvider
																						dateAdapter={AdapterDateFns}
																						locale={langLocale}
																					>
																						<DatePicker
																							value={visitFromDate}
																							onChange={visitFromDate => {
																								visitFromDateChangeHandler(
																									visitFromDate,
																								);
																							}}
																							disabled={
																								visitDateFilterType !==
																								AuditEnum.TO_FROM_DATE
																							}
																							components={{
																								OpenPickerIcon: CalendarIcon,
																							}}
																							renderInput={({
																								inputRef,
																								inputProps,
																								InputProps,
																							}) => (
																								<div
																									style={{
																										display: 'flex',
																										alignItems: 'center',
																										position: 'relative',
																									}}
																									className="customDatePicker"
																								>
																									<input
																										ref={inputRef}
																										className="form-control"
																										{...inputProps}
																										placeholder={strings(
																											'formsListing.searchFilter.additionalDetailsSection.fromDatePlaceholder',
																										)}
																									/>
																									{InputProps?.endAdornment}
																								</div>
																							)}
																							className="form-control"
																						/>
																					</LocalizationProvider>
																				</div>
																			</div>
																		</>
																	}
																	{
																		<>
																			<div className="form-group mt-2 mb0">
																				<div className="input-group">
																					<LocalizationProvider
																						dateAdapter={AdapterDateFns}
																						locale={langLocale}
																					>
																						<DatePicker
																							value={visitToDate}
																							onChange={visitToDate => {
																								visitToDateChangeHandler(
																									visitToDate,
																								);
																							}}
																							disabled={
																								visitDateFilterType !==
																								AuditEnum.TO_FROM_DATE
																							}
																							components={{
																								OpenPickerIcon: CalendarIcon,
																							}}
																							renderInput={({
																								inputRef,
																								inputProps,
																								InputProps,
																							}) => (
																								<div
																									style={{
																										display: 'flex',
																										alignItems: 'center',
																										position: 'relative',
																									}}
																									className="customDatePicker"
																								>
																									<input
																										ref={inputRef}
																										className="form-control"
																										{...inputProps}
																										placeholder={strings(
																											'formsListing.searchFilter.additionalDetailsSection.toDatePlaceholder',
																										)}
																									/>
																									{InputProps?.endAdornment}
																								</div>
																							)}
																							className="form-control"
																						/>
																					</LocalizationProvider>
																				</div>
																			</div>
																		</>
																	}
																</div>
															</>
														)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						}

						<div className="mt-3">
							<button
								type="button"
								onClick={e => handleSearchAction()}
								className="btn btn-primary btn-block"
							>
								{strings('formsListing.searchFilter.searchBtn')}
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default FormsSearchColumn;
