import {
	Routes,
	Route,
	Navigate,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import AppContainer from './AppContainer';
import SignupV2Routes from './components/signup/signup.routes';
import ExtraRoutes from './utils/extra.routes';
import SettingRoutes from './components/settings/settings.routes';
import AuthRoutes from './components/auth/auth.routes';
import RoutePathInfo from './common/src/enums/RoutingPath';
import PatientSearchRoutes from './components/patientList/patientSearch.routes';
import PatientProfileRoutes from './components/patientProfile/patientProfile.routes';
import FormBuilderRoutes from './formBuilder/formBuilder.routes';
import {
	angularAppRoutes,
	customNavigate,
	getRoutesForPatientBanner,
	getUpdatedRouteForPatientBanner,
	isRouteToBeExcluded,
} from './utils/wrappers/App.decision';
import AuditRoutes from './components/auditTrail/audit.routes';
import {useEffect, useState} from 'react';
import {routeChangeDataStateObserver} from './ui/comps/src/notification/service/RouteChangeService';
import LinkedPatientRoutes from './components/linkedPatient/linketPatient.routes';
import encryptedStorageHelperInstance from './storage/EncryptedStorageHelper';
import {sessionStorageKeys} from './common/src/interface/storage/constants/SessionKeys';
import UserProfileRoutes from './components/userProfile/routes/UserProfile.routes';
import HelpDeskRoutes from './components/helpDesk/helpdesk.routes';
import Loader from './ui/comps/src/Loader';
import NotificationRoutes from './components/notifications/notification.routes';
import Container from 'typedi';
import UserService from './common/src/application/modules/user/service/User.service';
import ImageViewerRoutes from './components/patientList/records/ImageViewer.routes';
import {useParams} from 'react-router';

const AppRoutedComponent = (): JSX.Element => {
	const userService: UserService = Container.get(UserService);
	let location = useLocation();
	let locationState = location?.state as any;
	let patientId = locationState?.patientId;
	const params = useParams();
	if (!patientId) {
		patientId = params?.patientId;
	}
	if (!patientId) {
		patientId = encryptedStorageHelperInstance.getItemSync(
			sessionStorageKeys.PATIENT_ID,
		);
	}

	const [isRedirectingToAngular, setIsRedirectingToAngular] =
		useState<boolean>(false);

	useEffect(() => {
		routeChangeDataStateObserver.publish(location);
	}, [location]);
	
	useEffect(() => {
		const routeValue = window.location.hash
		if (routeValue && routeValue.charAt(1) !== '/') {
			window.location.href = window.location.origin + window.location.pathname + window.location.search + "#/" + routeValue.slice(1);
		}
	}, [location, location.pathname])

	if (global.ConfigurationHolder.enableAngularTransition) {
		if (angularAppRoutes.indexOf(location.pathname) !== -1) {
			setIsRedirectingToAngular(true);
			if (
				global.ConfigurationHolder.redirectToOldUrlAfterLogin &&
				location.pathname.indexOf(
					global.ConfigurationHolder.logout.redirectUrl,
				) < 0 &&
				encryptedStorageHelperInstance.getItemSync(
					sessionStorageKeys.AUTH_TOKEN,
				)
			) {
				userService.setRedirectAfterLoginUrl(location.pathname);
			}
			window.location.href =
				global.ConfigurationHolder.urls.angularApplicationFrontend +
				location.pathname;
		} else if (
			getRoutesForPatientBanner(patientId).indexOf(location.pathname) !== -1
		) {
			const routesForPatientBanner = getRoutesForPatientBanner(patientId);
			setIsRedirectingToAngular(true);
			window.location.href =
				global.ConfigurationHolder.urls.angularApplicationFrontend +
				getUpdatedRouteForPatientBanner(
					patientId,
					routesForPatientBanner[
						routesForPatientBanner.indexOf(location.pathname)
					],
				);
		} else if (
			location.pathname.indexOf(RoutePathInfo.editUserProfile.path) !== -1
		) {
			if (location.search.indexOf('?tab=') === -1) {
				window.location.href =
					global.ConfigurationHolder.urls.angularApplicationFrontend +
					location.pathname;
			}
		} else if (location.pathname.match(new RegExp(/\/patResponse\/*/g))) {
			setIsRedirectingToAngular(true);
			window.location.href =
				global.ConfigurationHolder.urls.angularApplicationFrontend +
				location.pathname;
		}
	}

	if (
		global.ConfigurationHolder.redirectToOldUrlAfterLogin &&
		global.ConfigurationHolder.excludeRedirectionOldUrlList.indexOf(
			location.pathname,
		) < 0 &&
		encryptedStorageHelperInstance.getItemSync(sessionStorageKeys.AUTH_TOKEN)
	) {
		userService.setRedirectAfterLoginUrl(location.pathname);
	}

	return (
		<div>
			{isRedirectingToAngular ? (
				<Loader />
			) : (
				<Routes>
					<Route path="/" element={<AppContainer></AppContainer>}>
						{AuthRoutes}
						{SignupV2Routes}
						{SettingRoutes}
						{PatientSearchRoutes}
						{PatientProfileRoutes}
						{FormBuilderRoutes}
						{AuditRoutes}
						{LinkedPatientRoutes}
						{HelpDeskRoutes}
						{NotificationRoutes}
						{UserProfileRoutes}
						{ImageViewerRoutes}
						{/* routes only for development */}
						{process.env.NODE_ENV === 'development' && <>{ExtraRoutes}</>}
						<Route
							path="*"
							element={
								<>
									{locationState?.comingFromDefault ? (
										<div className="pageBreak" aria-hidden="true">
											<span className="image"></span>
											<span className="text text-hint">Page Not Found</span>
										</div>
									) : (
										<Navigate
											to={RoutePathInfo.defaultLandingPage.path}
											replace
										/>
									)}
								</>
							}
						/>
					</Route>
				</Routes>
			)}
		</div>
	);
};

export default AppRoutedComponent;
