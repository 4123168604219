import GlobalObserversInstance from '../../common/src/utils/observers/GlobalObservers';
/**
 * The GlobalSubscribers class is used to subscribe to and listen to generic
 * observable events, as well as to handle screen navigation.
 * */
class GlobalSubscribers {
	/**
	 * This is function is uses to initialize the subscriber to start listening to the appropriate events.
	 * */
	public static init() {
		this.subscribeLogoutObserver();
		this.subscribePermissionDeniedObserver();
		this.subscribeServerDownObserver();
	}

	/**
	 * This function subscribe to the logout observer and manages the navigation to appropriate screen
	 * TODO - Navigation to be handled
	 * @private
	 */
	private static subscribeLogoutObserver() {
		GlobalObserversInstance.logoutObserver.observable().subscribe(value => {
			GlobalObserversInstance.userContextObserver.publish(null);
			console.log('401 - logout user event ' + value);
		});
	}

	/**
	 * This function subscribe to the permission denied observer and manages the navigation to appropriate screen
	 * TODO - Navigation to be handled
	 * @private
	 */
	private static subscribePermissionDeniedObserver() {
		GlobalObserversInstance.permissionDeniedObserver
			.observable()
			.subscribe(value => {
				console.log(`${value} - permission denied event`);
			});
	}

	/**
	 * This function subscribe to the server down observer and manages the navigation to appropriate screen
	 * TODO - Navigation to be handled
	 * @private
	 */
	private static subscribeServerDownObserver() {
		GlobalObserversInstance.serverDownObserver.observable().subscribe(value => {
			console.log(`${value} - server down event`);
		});
	}
}

export default GlobalSubscribers;
