class PatientActionBannerValidationModel{
    constructor(
        private _constraintsError:string="",
        private _isError:boolean = false
    ) {
    }

    get constraintsError(): string {
        return this._constraintsError;
    }

    set constraintsError(value: string) {
        this._constraintsError = value;
    }

    get isError(): boolean {
        return this._isError;
    }

    set isError(value: boolean) {
        this._isError = value;
    }
}
export default PatientActionBannerValidationModel