class SendToEmrOrPacsRequestValidation {
	constructor(
		private _uploadRecordId: string = '',
		private _isError: boolean = false,
	) {}
	set uploadRecordId(value: string) {
		this._uploadRecordId = value;
	}
	get uploadRecordId(): string {
		return this._uploadRecordId;
	}
	set isError(value: boolean) {
		this._isError = value;
	}
	get isError(): boolean {
		return this._isError;
	}
}
export default SendToEmrOrPacsRequestValidation;
