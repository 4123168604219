export default class ShareConstraint {
	private readonly _reason: string | undefined;
	private readonly _startDate: string | undefined;
	private readonly _endDate: string | undefined;
	private readonly _patientId: string;

	constructor({reason, startDate, endDate, patientId}: ShareConstraintType) {
		this._reason = reason || '';
		this._startDate = startDate || '';
		this._endDate = endDate || '';
		this._patientId = patientId;
	}

	getData(): ShareConstraintType {
		return {
			reason: this._reason,
			startDate: this._startDate,
			endDate: this._endDate,
			patientId: this._patientId,
		};
	}
}

export type ShareConstraintType = {
	reason?: string;
	startDate?: string;
	endDate?: string;
	patientId: string;
};
