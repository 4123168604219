import {Route} from 'react-router-dom';
import {lazy, Suspense} from 'react';
import Loader from '../../ui/comps/src/Loader';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import PrivateRoute from '../../PrivateRoute';
import PatientDetailsComponent from './PatientDetails';
import FormsListing from './forms/FormsListing';
import VitalRoutes from '../vitals/Vital.routes';
import RecordsRoutes from './records/Records.routes';

const PatientOverView = lazy(
	() => import('../patientOverview/patientOverview.component'),
);

const CompositeForm = lazy(
	() => import('./forms/composite-forms/CompositeForm'),
);

const PatientListComponent = lazy(() => import('./patientList.component'));

const PatientTimelineComponent = lazy(
	() => import('../patientTimeline/patientTimeline.component'),
);

const QuestionnaireResponsesList = lazy(
	() => import('./questionnaire-response/QuestionnaireResponsesList'),
);

const DocumentUploadComponent = lazy(
	() => import('../documents/upload/documentUpload.component'),
);
const DocumentListing = lazy(
	() => import('../documents/listing/document.listing'),
);

const PatientProfile = lazy(() => import('../patientProfile/PatientProfile'));

const Dashboard = lazy(() => import('../dashboard/components/Dashboard'));

const MedicationListComponent = lazy(
	() => import('../patientOverview/views/medications/MedicationListComponent'),
);

const accessTo: Array<string> = ['PRACTITIONER', 'OTHERS', 'PATIENT'];

const PatientSearchRoutes = (
	<>
		{/* Add authenticated routes under privateRoutes */}
		<Route path="/" element={<PrivateRoute accessTo={accessTo} />}>
			<Route
				path={RoutePathInfo.patientSearch.path}
				element={
					<Suspense fallback={<Loader />}>
						<PatientListComponent />
					</Suspense>
				}
			/>
			<Route
				path={RoutePathInfo.patient.path}
				element={<PatientDetailsComponent />}
			>
				<Route
					path={RoutePathInfo.patientOverview.path}
					element={
						<Suspense fallback={<Loader />}>
							<PatientOverView />
						</Suspense>
					}
				/>
				<Route
					path={RoutePathInfo.questionnaireResponse.path}
					element={
						<Suspense fallback={<Loader />}>
							<QuestionnaireResponsesList />
						</Suspense>
					}
				/>
				<Route
					path={RoutePathInfo.patientTimeline.path}
					element={
						<Suspense fallback={<Loader />}>
							<PatientTimelineComponent />
						</Suspense>
					}
				/>
				<Route
					path={RoutePathInfo.uploadMedicalRecords.path}
					element={
						<Suspense fallback={<Loader />}>
							<DocumentUploadComponent />
						</Suspense>
					}
				/>
				<Route
					path={RoutePathInfo.uploadedDocuments.path}
					element={
						<Suspense fallback={<Loader />}>
							<DocumentListing />
						</Suspense>
					}
				/>
				<Route element={<PrivateRoute accessTo={['PATIENT']} />}>
					<Route
						path={RoutePathInfo.formListing.path}
						element={
							<Suspense fallback={<Loader />}>
								<FormsListing />
							</Suspense>
						}
					/>
				</Route>
				<Route element={<PrivateRoute accessTo={['PATIENT', 'OTHERS']} />}>
					<Route
						path={RoutePathInfo.createForm.path}
						element={
							<Suspense fallback={<Loader />}>
								<CompositeForm />
							</Suspense>
						}
					/>
				</Route>
				<Route element={<PrivateRoute accessTo={['PATIENT', 'OTHERS']} />}>
					<Route
						path={'createForm'}
						element={
							<Suspense fallback={<Loader />}>
								<CompositeForm />
							</Suspense>
						}
					/>
				</Route>
				<Route
					path={RoutePathInfo.patientProfileFromSearch.path}
					element={
						<Suspense fallback={<Loader />}>
							<PatientProfile />
						</Suspense>
					}
				/>
				<Route
					path={RoutePathInfo.dashboard.path}
					element={
						<Suspense fallback={<Loader />}>
							<Dashboard />
						</Suspense>
					}
				/>
				<Route
					path={RoutePathInfo.medications.path}
					element={
						<Suspense fallback={<Loader />}>
							<MedicationListComponent />
						</Suspense>
					}
				/>
				{VitalRoutes}
				{RecordsRoutes}
			</Route>
		</Route>
	</>
);

export default PatientSearchRoutes;
