import BaseResponseModel from '../../../../network/interface/BaseResponseModel';

export default class FormVerifyOTPResponse implements BaseResponseModel {
	private readonly _success: boolean | undefined;
	private readonly _info: InfoModel | undefined;
	private readonly _error: ErrorModel | undefined;

	constructor({
		success,
		info,
		errors,
	}: {
		success?: boolean;
		info?: Array<InfoModel>;
		errors?: Array<ErrorModel>;
	}) {
		this._success = success;
		if (info != null && Array.isArray(info) && info.length > 0) {
			this._info = info[0];
		}
		if (errors != null && Array.isArray(errors) && errors.length > 0) {
			this._error = errors[0];
		}
	}

	/**
	 * This method returns the response received from /minerva/moSignUp/verifyOneTimePassword API call
	 * @returns {FormVerifyOTPResponseType}
	 */
	getResponseDataObject(): FormVerifyOTPResponseType {
		return {
			success: this._success ?? false,
			info: this._info,
			error: this._error,
		};
	}
}

export type FormVerifyOTPResponseType = {
	success: boolean;
	info?: InfoModel;
	error?: ErrorModel;
};

export type InfoModel = {
	status?: boolean;
	responseStatus?: number;
};

export type ErrorModel = {
	status?: boolean;
	message?: string;
	code?: string;
	responseStatus?: number;
	attemptsLeft?: number;
};
