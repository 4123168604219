import BaseResponseModel from "../../../../network/interface/BaseResponseModel";

class ConditionSearchResponseModel implements BaseResponseModel {
    private readonly list?: Array<any>
    private readonly totalCount?: number

    constructor({
                    list,
                    totalCount
                }: ConditionSearchResponseData) {
        this.list = list;
        this.totalCount = totalCount;
    }

    /**
     * returns condition search response
     * @returns {ConditionSearchResponseData}
     */
    getResponseDataObject(): ConditionSearchResponseData {
        return {
            list: this.list,
            totalCount: this.totalCount,
        }
    }
}


export type ConditionSearchResponseData = {
    list?: Array<any>,
    totalCount?: number
}
export default ConditionSearchResponseModel