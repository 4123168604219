import {Container, Service} from 'typedi';

import AddressViewModelInterface from './interfaces/AddressViewModelInterface';
import AddressRepository from '../repository/Address.repository';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {DropdownOption} from '../../../../../../dynamicFormUtil/types/DynamicForm.types';
import FetchCountriesRequest from '../models/country/FetchCountriesRequest';
import {FetchCountriesResponseType} from '../models/country/FetchCountriesResponse';
import {AreaCodeModel} from '../models/common/AreaCodeModel';
import FetchStatesRequest from '../models/state/FetchStatesRequest';
import {FetchStatesResponseType} from '../models/state/FetchStatesResponse';
import {
	selectedLanguage,
	strings,
} from '../../../../../../../../../localization/i18n';
import {FetchCitiesResponseType} from '../models/city/FetchCitiesResponse';
import FetchCitiesRequest from '../models/city/FetchCitiesRequest';
import {LoaderProps, LoaderType} from '../../../../models/Loader';
import FetchZipCountyRequest, {
	FetchZipCountyRequestType,
} from '../models/county/FetchZipCountyRequest';
import {FetchZipCountyResponseType} from '../models/county/FetchZipCountyResponse';
import {
	NotificationMessage,
	NotificationMessageLevel,
	NotificationMessageType,
	NotificationVisibility,
} from '../../../../models/NotificationMessage';

@Service()
class AddressViewModel implements AddressViewModelInterface {
	private readonly repository: AddressRepository;

	private readonly countriesSubject: BehaviorSubject<
		Array<DropdownOption & {code: string}>
	>;
	private readonly statesSubject: BehaviorSubject<
		Array<DropdownOption & {code: string}>
	>;
	private readonly citiesSubject: BehaviorSubject<
		Array<DropdownOption & {code: string}>
	>;
	private readonly countiesSubject: BehaviorSubject<Array<DropdownOption>>;
	private readonly notificationMessageSubject: Subject<NotificationMessage>;
	private readonly stateSubject: BehaviorSubject<string>;
	private readonly citySubject: BehaviorSubject<string>;
	private readonly loaderSubject: ReplaySubject<LoaderProps>;

	private countries?: Array<DropdownOption & {code: string}>;
	private states: Record<string, Array<DropdownOption & {code: string}>> = {};
	private cities: Record<string, Array<DropdownOption & {code: string}>> = {};
	private counties?: Array<DropdownOption>;
	private state?: string;
	private city?: string;

	constructor() {
		this.repository = Container.get(AddressRepository);
		this.countriesSubject = new BehaviorSubject<
			Array<DropdownOption & {code: string}>
		>([]);
		this.statesSubject = new BehaviorSubject<
			Array<DropdownOption & {code: string}>
		>([]);
		this.citiesSubject = new BehaviorSubject<
			Array<DropdownOption & {code: string}>
		>([]);
		this.countiesSubject = new BehaviorSubject<Array<DropdownOption>>([]);
		this.state = undefined;
		this.city = undefined;
		this.notificationMessageSubject = new Subject<NotificationMessage>();
		this.stateSubject = new BehaviorSubject<string>('');
		this.citySubject = new BehaviorSubject<string>('');
		this.loaderSubject = new ReplaySubject<LoaderProps>(3);
	}

	/**
	 * This method is used to get the observable over which the countries array is posted
	 */
	public getCountriesObservable(): Observable<Array<DropdownOption>> {
		return this.countriesSubject.asObservable();
	}

	/**
	 * This method is used to get the observable over which the countries array is posted
	 */
	public getStatesObservable(): Observable<Array<DropdownOption>> {
		return this.statesSubject.asObservable();
	}

	/**
	 * This method is used to get the observable over which the countries array is posted
	 */
	public getCitiesObservable(): Observable<Array<DropdownOption>> {
		return this.citiesSubject.asObservable();
	}

	/**
	 * This method is used to get the observable over which the counties array is posted
	 */
	public getCountiesObservable(): Observable<Array<DropdownOption>> {
		return this.countiesSubject.asObservable();
	}

	/**
	 * This method is used to get the observable over which the state string is posted
	 */
	public getStateObservable(): Observable<string> {
		return this.stateSubject.asObservable();
	}

	/**
	 * This method is used to get the observable over which the city string is posted
	 */
	public getCityObservable(): Observable<string> {
		return this.citySubject.asObservable();
	}

	/**
	 * This method is used to get the observer where loader information is posted
	 * returns Observable<Loader>
	 */
	public fetchLoaderObservable(): Observable<LoaderProps> {
		return this.loaderSubject.asObservable();
	}

	/**
	 * This method is used to get the observer where notification messages are posted
	 * returns Observable<NotificationMessage>
	 */
	public fetchNotificationMessageObservable(): Observable<NotificationMessage> {
		return this.notificationMessageSubject.asObservable();
	}

	/**
	 * This method is used to fetch countries from repository.
	 * If data exists in member variable, then the same is posted to countriesSubject.
	 */
	fetchCountries(): void {
		if (this.countries != null) {
			this.loaderSubject.next({
				isToShowLoader: false,
				type: LoaderType.OverScreen,
			});
			this.countriesSubject.next(this.countries);
		} else {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OverScreen,
			});
			this.repository
				.fetchCountries(
					new FetchCountriesRequest({
						lang: selectedLanguage(),
					}),
				)
				.then((response: FetchCountriesResponseType) => {
					this.processCountries(response);
				})
				.catch(error => {
					console.log(
						'AddressDropdownViewModel.fetchCountries: Error: ',
						JSON.stringify(error, null, 4),
					);
				})
				.finally(() => {
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				});
		}
	}

	/**
	 * processes country response model for dropdown
	 * @param response country response model
	 */
	private processCountries(response: FetchCountriesResponseType) {
		const list: Array<DropdownOption & {code: string}> = [];
		response.countries?.forEach((o: AreaCodeModel) => {
			list.push({label: `${o.name}`, value: `${o.name}`, code: `${o.id}`});
		});
		this.countries = list;
		this.countriesSubject.next(this.countries);
	}

	/**
	 * This method is used to fetch states from repository.
	 * If data exists in member variable, then the same is posted to statesSubject.
	 */
	fetchStates(country: string): void {
		const countryId = this.getCountryCode(country);

		if (this.states[countryId] != null) {
			this.loaderSubject.next({
				isToShowLoader: false,
				type: LoaderType.OverScreen,
			});
			this.statesSubject.next(this.states[countryId]);
		} else {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OverScreen,
			});
			this.repository
				.fetchStates(
					new FetchStatesRequest({
						lang: selectedLanguage(),
						countryId: countryId,
					}),
				)
				.then((response: FetchStatesResponseType) => {
					this.processStates(response, `${countryId}`);
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				})
				.catch(error => {
					console.log(
						'AddressDropdownViewModel.fetchStates: Error: ',
						JSON.stringify(error, null, 4),
					);
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				});
		}
	}

	/**
	 * processes state response model for dropdown
	 * @param response state response model
	 * @param countryId state id
	 */
	private processStates(response: FetchStatesResponseType, countryId: string) {
		const list: Array<DropdownOption & {code: string}> = [];
		response.states?.forEach((o: AreaCodeModel) => {
			list.push({label: `${o.name}`, value: `${o.name}`, code: `${o.id}`});
		});
		this.states[countryId] = list;
		this.statesSubject.next(this.states[countryId]);
	}

	/**
	 * This method is used to fetch cities from repository.
	 * If data exists in member variable, then the same is posted to citiesSubject.
	 */
	fetchCities(country: string, state: string): void {
		const countryId = this.getCountryCode(country);
		const stateId = this.getStateCode(countryId, state);

		if (this.cities[`${countryId}_${stateId}`] != null) {
			this.loaderSubject.next({
				isToShowLoader: false,
				type: LoaderType.OverScreen,
			});
			this.citiesSubject.next(this.cities[`${countryId}_${stateId}`]);
		} else {
			this.loaderSubject.next({
				isToShowLoader: true,
				type: LoaderType.OverScreen,
			});
			this.repository
				.fetchCities(
					new FetchCitiesRequest({
						lang: selectedLanguage(),
						countryId: countryId,
						stateId: stateId,
					}),
				)
				.then((response: FetchCitiesResponseType) => {
					this.processCities(response, `${countryId}`, `${stateId}`);
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				})
				.catch(error => {
					console.log(
						'AddressDropdownViewModel.fetchCities: Error: ',
						JSON.stringify(error, null, 4),
					);
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				});
		}
	}

	/**
	 * This function is used to get the countryId
	 * @param {string} country
	 */
	getCountryCode(country: string): number {
		const value = this.countries?.find(o => {
			return o.value === country;
		})?.code;
		return value != null ? parseInt(value) : -1;
	}

	/**
	 * This function is used to get the stateId
	 * @param {number} countryId
	 * @param {string} state
	 */
	getStateCode(countryId: number, state: string): number {
		const value = this.states[countryId]?.find(o => {
			return o.value === state;
		})?.code;
		return value != null ? parseInt(value) : -1;
	}

	/**
	 * processes city responses model for dropdown
	 * @param response city response model
	 * @param countryId country id
	 * @param stateId state id
	 */
	processCities(
		response: FetchCitiesResponseType,
		countryId: string,
		stateId: string,
	) {
		const list: Array<DropdownOption & {code: string}> = [];
		response.cities?.forEach((o: AreaCodeModel) => {
			list.push({label: `${o.name}`, value: `${o.name}`, code: `${o.id}`});
		});
		this.cities[`${countryId}_${stateId}`] = list;
		this.citiesSubject.next(this.cities[`${countryId}_${stateId}`]);
	}

	/**
	 * This method is used to fetch counties from repository.
	 * If data exists in member variable, then the same is posted to countiesSubject.
	 */
	fetchCounties(zipCode: string): void {
		this.loaderSubject.next({
			isToShowLoader: true,
			type: LoaderType.OverScreen,
		});
		this.repository
			.fetchZipCounties(
				new FetchZipCountyRequest({
					zipCode,
				}),
			)
			.then((response: FetchZipCountyResponseType) => {
				this.loaderSubject.next({
					isToShowLoader: false,
					type: LoaderType.OverScreen,
				});
				this.processCounties(response);
			})
			.catch(error => {
				this.handleErrorResponse(error);
			})
			.finally(() => {
				this.loaderSubject.next({
					isToShowLoader: false,
					type: LoaderType.OverScreen,
				});
			});
	}

	/**
	 * processes zip county responses model for dropdown
	 * @param response zip county response model
	 */
	private processCounties(response: FetchZipCountyResponseType) {
		const countyList: Array<DropdownOption> = [];
		response.county?.forEach((county: DropdownOption) => {
			if (county.label && county.value) {
				countyList.push({label: `${county.label}`, value: `${county.value}`});
			}
		});
		this.counties = countyList;

		const stateList: Array<DropdownOption & {code: string}> = [];
		if (response.state) {
			stateList.push({
				label: response.state,
				value: response.state,
				code: response.state,
			});
			this.state = response.state;
		}

		const cityList: Array<DropdownOption & {code: string}> = [];
		response.city?.forEach((city: DropdownOption & {code: string}) => {
			if (city.label && city.value) {
				cityList.push({
					label: `${city.label}`,
					value: `${city.value}`,
					code: `${city.value}`,
				});
			}
		});

		this.countiesSubject.next(countyList);
		this.statesSubject.next(stateList);
		this.citiesSubject.next(cityList);

		this.stateSubject.next(this.state || '');
	}

	/**
	 * This method is used to handle error response.
	 * @param response error response
	 */
	private handleErrorResponse({
		response,
	}: {
		response?: {
			data?: Record<string, any>;
			status?: number;
		};
	}) {
		this.countiesSubject.next([]);
		this.statesSubject.next([]);
		this.citiesSubject.next([]);
		if (response != null) {
			const {data, status} = response;
			const code =
				data != null && Array.isArray(data?.errors) && data?.errors?.length > 0
					? data?.errors[0]?.code
					: '';
			switch (status) {
				case 400:
					if (code === 'ZC001') {
						this.notificationMessageSubject.next({
							type: NotificationMessageType.toast,
							level: NotificationMessageLevel.error,
							message: strings('address.errors.zipcode.ambiguityFound'),
							visibility: NotificationVisibility.local,
						});
					}
					break;
			}
		}
	}

	/**
	 * initializes dropdown data and default values
	 * @param defaultValues default values for address dropdown
	 * @param isToCallApi determines weather to call fetchZipCounties for zipcode or not
	 */
	initialize(
		defaultValues?: {
			country?: string;
			state?: string;
			city?: string;
			zipcode?: string;
		},
		isToCallApi?: boolean,
	): void {
		this.loaderSubject.next({
			isToShowLoader: true,
			type: LoaderType.OverScreen,
		});
		if (
			defaultValues?.country != null ||
			defaultValues?.state != null ||
			defaultValues?.city != null ||
			defaultValues?.zipcode != null
		) {
			this.repository
				.fetchCountries(
					new FetchCountriesRequest({
						lang: selectedLanguage(),
					}),
				)
				.then(data => {
					this.processCountries(data);
					if (defaultValues?.zipcode != null && isToCallApi) {
						this.repository
							.fetchZipCounties(
								new FetchZipCountyRequest({
									zipCode: defaultValues.zipcode,
								}),
							)
							.then(data => {
								this.processCounties(data);
								this.loaderSubject.next({
									isToShowLoader: false,
									type: LoaderType.OverScreen,
								});
							})
							.catch(error => {
								this.handleErrorResponse(error);
								this.loaderSubject.next({
									isToShowLoader: false,
									type: LoaderType.OverScreen,
								});
							});
					} else if (
						defaultValues?.country != null &&
						defaultValues?.state != null
					) {
						const countryId = this.getCountryCode(defaultValues.country);
						this.repository
							.fetchStates(
								new FetchStatesRequest({
									lang: selectedLanguage(),
									countryId: countryId,
								}),
							)
							.then(data => {
								this.processStates(data, `${countryId}`);
								if (
									defaultValues?.country != null &&
									defaultValues?.state != null &&
									defaultValues?.city != null
								) {
									const stateId = this.getStateCode(
										countryId,
										defaultValues.state,
									);
									this.repository
										.fetchCities(
											new FetchCitiesRequest({
												lang: selectedLanguage(),
												countryId: countryId,
												stateId: stateId,
											}),
										)
										.then(data => {
											this.processCities(data, `${countryId}`, `${stateId}`);
											this.loaderSubject.next({
												isToShowLoader: false,
												type: LoaderType.OverScreen,
											});
										})
										.catch(error => {
											console.log(
												'AddressDropdownViewModel.initialize: fetchCities: Error: ',
												JSON.stringify(error, null, 4),
											);
											this.loaderSubject.next({
												isToShowLoader: false,
												type: LoaderType.OverScreen,
											});
										});
								} else {
									this.loaderSubject.next({
										isToShowLoader: false,
										type: LoaderType.OverScreen,
									});
								}
							})
							.catch(error => {
								console.log(
									'AddressDropdownViewModel.initialize: fetchStates: Error: ',
									JSON.stringify(error, null, 4),
								);
								this.loaderSubject.next({
									isToShowLoader: false,
									type: LoaderType.OverScreen,
								});
							});
					} else {
						this.loaderSubject.next({
							isToShowLoader: false,
							type: LoaderType.OverScreen,
						});
					}
				})
				.catch(error => {
					console.log(
						'AddressDropdownViewModel.initialize: fetchCountries: Error: ',
						JSON.stringify(error, null, 4),
					);
					this.loaderSubject.next({
						isToShowLoader: false,
						type: LoaderType.OverScreen,
					});
				});
		} else {
			this.fetchCountries();
		}
	}
}

export default AddressViewModel;
