class EncounterSearchValidationModel {
    constructor(
        private _patientError: string = '',
        private _fieldError: string = '',
        private _statusNotError: string = '',
        private _isError: boolean = false
    ) {}
    set patientError(value: string) {
        this._patientError = value
    }
    get patientError(): string {
        return this._patientError
    }
    set fieldError(value: string) {
        this._fieldError = value
    }
    get fieldError(): string {
        return this._fieldError
    }
    set statusNotError(value: string) {
        this._statusNotError = value
    }
    get statusNotError(): string {
        return this._statusNotError
    }
    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}
export default EncounterSearchValidationModel