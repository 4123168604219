import {useDropzone} from 'react-dropzone';
import {strings} from '../../../../../../../localization/i18n';
import {FieldType} from '../../../types/FieldTypeEnum';
import ErrorText from '../../components/ErrorText';
import HelpText from '../../components/HelpText';
import Label from '../../components/Label';
import UploadedFilesComponent from './UploadedFiles.component';
import './../fileUpload.scss';
import _ from 'lodash';

const UploadComponent = (props: any) => {
	const {
		fieldData,
		dynamicConstraints,
		configProvider,
		errors,
		isViewOnlyMode,
		uploadedFiles,
		fileInputRef,
		folderInputRef,
	} = props;

	const {label, name, visible, fileUploadOptions, attributeCssClassName} =
		fieldData;

	const filePickerHandler = () => {
		fileInputRef.current.click();
	};

	const folderPickerHandler = () => {
		folderInputRef.current.click();
	};

	const selectMultiple = fileUploadOptions.isMultiSelect;

	const accepts = fileUploadOptions.accept;

	const {fileRejections, getRootProps, open, getInputProps} = useDropzone({
		noClick: true,
		noKeyboard: true,
		onDrop: props.handleChange,
		validator: props.fileValidator,
		accept: {},
		multiple: fileUploadOptions.isMultiSelect,
	});

	const fileRejectionItems = fileRejections.map((file: any, index) => (
		<>
			<div
				id={`div_fileRejectionItems_${name}`}
				className="tableLayout noMargin">
				<table
					id={`table_fileUpload_${name}`}
					className="mt__1"
					key={file.file.path}>
					<tbody id={`tbody_fileUpload_${name}`}>
						<tr className="noEff">
							<td className="first width-40p">
								{fileUploadOptions.showFilePath ? (
									<div>{file.file.path}</div>
								) : null}
								{fileUploadOptions.showFileType ? (
									<div>{file.file.type}</div>
								) : null}
							</td>
							<td className="">
								{fileUploadOptions.showFileSize ? (
									<div>{file.file.size} bytes</div>
								) : null}
							</td>
							<td className="last width-150">
								{file.errors.map((e: any) => (
									<p key={e.code}>{e.message}</p>
								))}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	));

	return (
		<>
			{(visible != null ? visible : true) && (
				<div id={`div_om_fileUpload_${name}`} className="w-100">
					{fileUploadOptions.showUploader &&
					fileUploadOptions.showDragAndDropArea !== false ? (
						<div
							className={
								attributeCssClassName
									? `form-item ${attributeCssClassName}`
									: 'form-item'
							}>
							<div
								id={`div_upload_section_${name}`}
								className="uploadSection mt15 form-group">
								<div
									id={`div_uploader_box_${name}`}
									className="boxStyle--dashed--blue uploaderBox text-center bg-white">
									<div id={`div_file_dropzone_${name}`} className="dropzone">
										<Label
											htmlFor={name}
											content={label}
											required={dynamicConstraints?.required ?? false}
											configProvider={configProvider}
										/>
										<input
											type="file"
											onChange={e => props.handleChange(e.target.files)}
											name={name}
											ref={fileInputRef}
											aria-describedby={`error_${name} ${name}_helpText_${name}`}
											style={{display: 'none'}}
											accept={accepts}
											multiple={selectMultiple}
										/>

										{errors != null && (
											<ErrorText
												errorObject={_.get(errors, name)}
												label={label}
												name={name}
												fieldType={FieldType.TEXT}
											/>
										)}

										{!errors?.[name] && (
											<HelpText
												content={dynamicConstraints?.helpText}
												name={name}
											/>
										)}
										<span
											className="fileIcon"
											id={`phyUploadMRF_upldrIcon_${name}`}></span>
										<h3>{strings('ui.comps.uploader.dropInfoTitle')}</h3>
										<div id={`div_select_file_btn_${name}`}>
											<Label
												htmlFor={name}
												content={label}
												required={dynamicConstraints?.required ?? false}
												configProvider={configProvider}
											/>
											<button
												id={`select_file_btn_${name}`}
												className="btn btn-primary"
												type="button"
												disabled={
													isViewOnlyMode === true
														? isViewOnlyMode
														: dynamicConstraints?.disabled ?? false
												}
												onClick={filePickerHandler}>
												{strings('ui.comps.uploader.selectFileBtn')}
											</button>
											{fileUploadOptions?.showSelectFolderBtn === true && (
												<button
													id={`folderPicker_${name}`}
													className="btn btn-primary"
													type="button"
													onClick={folderPickerHandler}>
													{strings('ui.comps.uploader.selectFolderBtn')}
												</button>
											)}
											{fileUploadOptions?.showSelectFolderBtn === true && (
												<input
													id={`filePicker_${name}`}
													name={name}
													style={{visibility: 'hidden'}}
													type="file"
													ref={folderInputRef}
													onChange={e => props.handleChange(e.target.files)}
													webkitdirectory=""
													accept={accepts}
													multiple
													disabled={
														isViewOnlyMode === true
															? isViewOnlyMode
															: dynamicConstraints?.disabled ?? false
													}
												/>
											)}
											{errors != null && (
												<ErrorText
													errorObject={_.get(errors, name)}
													label={label}
													name={name}
													fieldType={FieldType.TEXT}
												/>
											)}
											{!errors?.[name] && (
												<HelpText
													content={dynamicConstraints?.helpText}
													name={name}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					) : fileUploadOptions.showUploader &&
					  fileUploadOptions.showDragAndDropArea === false ? (
						<div
							className={
								attributeCssClassName
									? `form-item ${attributeCssClassName}`
									: 'form-item'
							}>
							<div id={`div_select_file_btn_${name}`} className="form-group">
								<Label
									htmlFor={name}
									content={label}
									required={dynamicConstraints?.required ?? false}
									configProvider={configProvider}
								/>
								<input
									type="file"
									onChange={e => props.handleChange(e.target.files)}
									name={name}
									ref={fileInputRef}
									accept={accepts}
									aria-describedby={`error_${name} ${name}_helpText_${name}`}
									style={{display: 'none'}}
									multiple={selectMultiple}
								/>
								<ErrorText
									errorObject={errors?.[name]}
									label={label}
									fieldType={FieldType.TEXT}
								/>
								{!errors?.[name] && (
									<HelpText
										content={dynamicConstraints?.helpText}
										name={name}
									/>
								)}
								<button
									id={`select_file_btn_${name}`}
									className="btn btn-primary mt10"
									type="button"
									disabled={
										isViewOnlyMode === true
											? isViewOnlyMode
											: dynamicConstraints?.disabled ?? false
									}
									onClick={filePickerHandler}>
									{strings('ui.comps.uploader.selectFileBtn')}
								</button>
								{fileUploadOptions?.showSelectFolderBtn === true && (
									<button
										id={`folderPicker_${name}`}
										className="btn btn-primary"
										type="button"
										onClick={folderPickerHandler}>
										{strings('ui.comps.uploader.selectFolderBtn')}
									</button>
								)}
								{fileUploadOptions?.showSelectFolderBtn === true && (
									<input
										id={`filePicker_${name}`}
										style={{visibility: 'hidden'}}
										type={'file'}
										ref={folderInputRef}
										onChange={e => props.handleChange(e.target.files)}
										webkitdirectory=""
										accept={accepts}
										multiple
										name={name}
										disabled={
											isViewOnlyMode === true
												? isViewOnlyMode
												: dynamicConstraints?.disabled ?? false
										}
									/>
								)}
							</div>
						</div>
					) : null}
					{fileUploadOptions.showUploadedFiles &&
					uploadedFiles &&
					uploadedFiles.length > 0 ? (
						<div
							id={`div_om_file_upload_prevSec_${name}`}
							className="previewSection  mt15 mb15">
							<div
								id={`div_uploaded_files_${name}`}
								className={`m-pt0 bg-white ${
									(isViewOnlyMode === true
										? isViewOnlyMode
										: dynamicConstraints?.disabled ?? false) && 'disabled'
								}`}>
								<UploadedFilesComponent
									fileUploadOptions={fileUploadOptions}
									uploadedFiles={uploadedFiles}
									dynamicConstraints={dynamicConstraints}
									isViewOnlyMode={isViewOnlyMode}
									deleteFileHandler={props.deleteFileHandler}
								/>
							</div>
						</div>
					) : null}
					{fileUploadOptions.showRejectedFiles &&
					fileRejectionItems &&
					fileRejectionItems.length > 0 ? (
						<div
							id={`div_om_file_upload_prevSec_${name}`}
							className="previewSection mt15 mb15">
							<div
								id={`div_uploaded_files_${name}`}
								className=" p15 m-pt0 bg-white">
								{fileRejectionItems}
							</div>
						</div>
					) : null}
				</div>
			)}
		</>
	);
};

export default UploadComponent;
