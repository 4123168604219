class BulkActionValidationModel {
    constructor(
        private _actionNameError:string = "",
        private _selectedResourceIdsError:string = "",
        private _searchCriteriaError:string = "",
        private _isError:boolean = false
    ) {}
    set actionNameError(value: string) {
        this._actionNameError = value
    }
    get actionNameError(): string {
        return this._actionNameError
    }
    set selectedResourceIdsError(value: string) {
        this._selectedResourceIdsError = value
    }
    get selectedResourceIdsError(): string {
        return this._selectedResourceIdsError
    }
    set searchCriteriaError(value: string) {
        this._searchCriteriaError = value
    }
    get searchCriteriaError(): string {
        return this._searchCriteriaError
    }
    set isError(value: boolean) {
        this._isError = value;
    }
    get isError(): boolean {
        return this._isError
    }
}
export default BulkActionValidationModel