import {useLocation, useNavigate} from 'react-router-dom';
import {customNavigate} from '../../../utils/wrappers/App.decision';
const CustomNavLink = ({
	id,
	className,
	to,
	children,
	callBackHandler,
	callBackData,
	ariaSelected,
	ariaLabel,
	ariaCurrent,
	role,
}: {
	id?: string;
	className?: string;
	to: string;
	children?: any;
	callBackHandler?: (data?: Record<string, any>) => void;
	callBackData?: Record<string, any>;
	ariaSelected?: boolean | undefined;
	ariaLabel?: string;
	ariaCurrent?: 'page' | false;
	role?: string;
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const navigateTo = () => {
		customNavigate(to, navigate, location);
		if (callBackHandler) {
			callBackHandler(callBackData);
		}
	};
	return (
		<button
			aria-label={ariaLabel}
			role={role}
			aria-selected={ariaSelected}
			aria-current={ariaCurrent}
			id={id}
			className={`p15 noBtnStyle ${className ?? className}`}
			onClick={() => navigateTo()}>
			{children}
		</button>
	);
};
export default CustomNavLink;