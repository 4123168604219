class PatientSearchRequestValidationModel {
	constructor(
		private _sortCondition: string = '',
		private _countError: string = '',
		private _skipError: string = '',
		private _profilePictureTypeError: string = '',
		private _isError: boolean = false,
	) {}

	set sortCondition(value: string) {
		this._sortCondition = value;
	}

	get sortCondition() {
		return this._sortCondition;
	}
	set countError(value: string) {
		this._countError = value;
	}

	get countError() {
		return this._countError;
	}

	set skipError(value: string) {
		this._skipError = value;
	}

	get skipError() {
		return this._skipError;
	}

	set profilePictureTypeError(value: string) {
		this._profilePictureTypeError = value;
	}

	get profilePictureTypeError() {
		return this._profilePictureTypeError;
	}

	set isError(value: boolean) {
		this._isError = value;
	}

	get isError() {
		return this._isError;
	}
}

export default PatientSearchRequestValidationModel;
