import './VerifiedIcon.scss';

type Props = {
	verified: boolean;
};

export default function VerifiedIcon({verified}: Props) {
	return (
		<div className="verificationContactIcon">
			{verified ? (
				<span className="icon-ok_02 fIcon iconSize success_icon"></span>
			) : (
				<span className="icon-info fIcon iconSize warning_icon"></span>
			)}
		</div>
	);
}
