import {strings} from '../../localization/i18n';
import {GridActionButtonOrIcon} from '../../common/src/interface/dataGrid/IDataGrid';
import {useContext} from 'react';
import {
	AppContextInterface,
	GlobalStateContext,
} from '../../common/src/utils/context/GlobalStateContext';
import {
	hasAnyRoles,
	isAccessible,
	isNotAccessible,
} from '../../common/src/utils/CommonUtils';
import {useLocation, useNavigate} from 'react-router-dom';
import {customNavigate} from '../../utils/wrappers/App.decision';

const PatientActionDropdown = ({
	patientId,
	data,
}: {
	patientId: number;
	data: Array<string>;
}): JSX.Element => {
	const {user} = useContext<AppContextInterface>(GlobalStateContext);

	const navigate = useNavigate();

	const location = useLocation();

	const nonPatientUserRoles = {
		access: [
			'ROLE_FULL_ACCESS',
			'ROLE_REQUESTING_PHYSICIAN_ACCESS',
			'ROLE_REFERRING_PHYSICIAN_ACCESS',
			'ROLE_PERFORMING_PHYSICIAN_ACCESS',
			'ROLE_ATTENDING_PHYSICIAN_ACCESS',
			'ROLE_CONSULTING_PHYSICIAN_ACCESS',
			'ROLE_ADMITTING_PHYSICIAN_ACCESS',
			'ROLE_CARE_TEAM_MEMBER_ACCESS',
			'ROLE_LOCATION_ACCESS',
			'ROLE_ORGANIZATIONAL_ACCESS',
			'ROLE_EXTERNAL',
		],
	};

	const patientUserRoles = {
		access: ['ROLE_PATIENT'],
	};

	const gridDropdownActionButtons: Array<GridActionButtonOrIcon> = [];

	const getAddTaskUrl = (patientId: number) => {
		alert(patientId);
	};
	const openAppointmentSlotModal = (patientId: number, status: boolean) => {
		alert(patientId);
	};

	if (data?.length > 0) {
		if (user && hasAnyRoles(nonPatientUserRoles.access, user)) {
			if (data.includes('orderTest')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.orderTest'),
					clickHandler: () =>
						customNavigate(
							`/patient/orders/create/${patientId}`,
							navigate,
							location,
						),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('sendQuestionnaire')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.sendQuestionnaire'),
					clickHandler: () =>
						customNavigate(
							`/sendQuestionnaire/${patientId}`,
							navigate,
							location,
						),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('referForCarePlan')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.referForCarePlan'),
					clickHandler: () =>
						customNavigate(
							`/createReferralRequest/${patientId}`,
							navigate,
							location,
						),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('assignCarePlan')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.assignCarePlan'),
					clickHandler: () =>
						customNavigate(`/assignCarePlan/${patientId}`, navigate, location),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('addTaskLabel')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.addTaskLabel'),
					clickHandler: () => getAddTaskUrl(patientId),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('exportPatientDataLabel')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.exportPatientDataLabel'),
					clickHandler: () =>
						customNavigate(
							`/exportPatientRecords/${patientId}`,
							navigate,
							location,
						),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('scheduleMeeting')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.scheduleMeeting'),
					clickHandler: () =>
						customNavigate(`/scheduleMeeting/${patientId}`, navigate, location),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('bookConsultation')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.bookConsultation'),
					clickHandler: () => openAppointmentSlotModal(patientId, true),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('bookService')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.bookService'),
					clickHandler: () => openAppointmentSlotModal(patientId, false),
					disabled: false,
					visible: true,
				});
			}
			if (data.includes('requestAccessBtn')) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.requestAccessBtn'),
					clickHandler: () =>
						customNavigate(
							`/patient/${patientId}/consentRequest`,
							navigate,
							location,
						),
					disabled: false,
					visible: true,
				});
			}
		}
		if (user && isAccessible(patientUserRoles.access, user)) {
			if (
				user &&
				isAccessible(
					['ROLE_CREATE_APPOINTMENT', 'ROLE_MANAGE_APPOINTMENTS'],
					user,
				)
			) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.bookAppointment'),
					clickHandler: () =>
						customNavigate(
							`/patient/${patientId}/bookAppointment`,
							navigate,
							location,
						),
					disabled: false,
					visible: true,
				});
			}
			if (user && isNotAccessible(['ROLE_ORGANIZATION_ACCESS'], user)) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.uploadDocumentLabel'),
					clickHandler: () =>
						customNavigate(
							`/patient/${patientId}/uploadDocumentRecords`,
							navigate,
							location,
						),
					disabled: false,
					visible: true,
				});
			}
			if (
				user &&
				hasAnyRoles(
					['ROLE_BOOK_HOME_APPOINTMENTS', 'ROLE_CREATE_APPOINTMENT'],
					user,
				)
			) {
				gridDropdownActionButtons.push({
					label: strings('patientBanner.actions.bookHomeServices'),
					clickHandler: () =>
						customNavigate(
							`/patient/${patientId}/bookHomeServices`,
							navigate,
							location,
						),
					disabled: false,
					visible: true,
				});
			}
		}
	}

	return (
		<div className="dropdown">
			<button
				className="btn btn-link dropdown-toggle underline-reverse"
				type="button"
				id="dropdownMenuButton"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				{strings('patientBanner.actions.heading')}
				<span className="fIcon icon-arrow_down1 vab" aria-hidden="true"></span>
			</button>
			<div
				className="dropdown-menu dropdown-menu-right"
				aria-labelledby="dropdownMenuButton"
			>
				{gridDropdownActionButtons.map(
					(buttonOption: GridActionButtonOrIcon, key: number) => {
						return (
							<button
								className="dropdown-item"
								onClick={buttonOption.clickHandler}
								key={key}
							>
								{buttonOption.label && strings(buttonOption.label)}
							</button>
						);
					},
				)}
			</div>
		</div>
	);
};

export default PatientActionDropdown;
