import storageHelperInstance from '../../../storage/StorageHelper';
import {sessionStorageKeys} from '../interface/storage/constants/SessionKeys';
import _ from 'lodash';
import {isValueAvailable} from './StringUtils';
import {Extension} from '../interface/patientBanner/IPatientBannerHelper';

/**
 * used to make ui components accessible on role basis of user.
 * @param roleList
 * @param user
 * @returns boolean after checking if the user has the passed roles.
 */
export const isAccessible = (roleList: Array<string>, user: any) => {
	let isAccessible = true;
	if (roleList && roleList.length > 0 && user) {
		const userRoles: Array<string> = user.permissions;
		roleList.forEach(function (role: string) {
			if (userRoles.indexOf(role) === -1) {
				isAccessible = false;
			}
		});
	}
	return isAccessible;
};

/**
 * used to make ui components accessible on role basis of user.
 * @param roleList
 * @param user
 * @returns boolean after checking if the user has the any roles.
 */
export const hasAnyRoles = (roleList: Array<string>, user: any) => {
	let isAccessible = false;
	if (roleList && roleList.length > 0 && user) {
		const userRoles: Array<string> = user.permissions;
		roleList.forEach(function (role: string) {
			if (userRoles.indexOf(role) > 0) {
				isAccessible = true;
				return;
			}
		});
	}
	return isAccessible;
};

/**
 * used to make ui components inaccessible on role basis of user.
 * @param roleList
 * @param user
 * @returns boolean after checking if the user has the passed roles.
 */
export const isNotAccessible = (roleList: Array<string>, user: any) => {
	let isAccessible = true;
	if (roleList && roleList.length > 0 && user) {
		const userRoles: Array<string> = user.permissions;
		roleList.forEach(function (role: string) {
			if (userRoles.indexOf(role) !== -1) {
				isAccessible = false;
			}
		});
	}
	return isAccessible;
};

/**
 * For Web Use Only
 * used to navigate to the menuitem clicked on patient banner on click and reload.
 * @param ConfigurationHolder
 * @param patientId
 * @param navigationCallback
 */
export const navigateToPatientBannerMenuItem = (
	patientId: number,
	navigationCallback: (arg0: string) => void,
	patientBannerMenuItem?: Record<string, any>,
) => {
	let defaultPatientBannerMenuItem: Record<string, any> | undefined = {};
	if (isValueAvailable(patientBannerMenuItem)) {
		defaultPatientBannerMenuItem = patientBannerMenuItem;
	} else {
		defaultPatientBannerMenuItem = storageHelperInstance.getItemSync(
			sessionStorageKeys.DEFAULT_PATIENT_BANNER_MENUITEM,
		);
	}
	if (
		global.ConfigurationHolder?.urlWithPatientIdInSuffix.indexOf(
			defaultPatientBannerMenuItem?.name,
		) !== -1
	) {
		switch (defaultPatientBannerMenuItem?.name) {
			case 'records':
				navigationCallback(
					'/patient' +
						defaultPatientBannerMenuItem?.path +
						'/%3Fsubject=' +
						patientId,
				);
				break;
			default:
				navigationCallback(
					'/patient' + defaultPatientBannerMenuItem?.path + '/' + patientId,
				);
		}
	} else {
		navigationCallback(
			'/patient/' + patientId + defaultPatientBannerMenuItem?.path,
		);
	}
};

/**
 * This method is used for xds-b environment.
 * @param documentObj
 * @returns {{isXDSDocument: boolean}}
 */
export const getXDSDocument = (
	documentObj: Record<string, any>,
): Record<string, any> => {
	const xdsMap: Record<string, any> = {};
	xdsMap.isXDSDocument = false;
	xdsMap.repoUniqueId = documentObj.repositoryUniqueID;
	xdsMap.docUniqueId = documentObj.documentUniqueID;
	if (xdsMap.repoUniqueId && xdsMap.docUniqueId) {
		xdsMap.isXDSDocument = true;
		//assigning complete document reference object in xds map which will be used further in send To EMR API as currently we xds does supports search only on patient id hence not able fetch document in API
		xdsMap.documentReferenceBO = _.cloneDeep(
			documentObj.customFields.documentReferenceBO,
		);
	}
	return xdsMap;
};

/**
 * This method is use to find all Identifier
 * @param identifiers
 * @param label
 * @param hiddenId
 * @param serverConfig
 * @returns
 */
export const findAllIdentifierFilter = (
	identifiers: Array<Record<string, any>>,
	label: string,
	hiddenId: string,
	serverConfig: any,
) => {
	let identifierTypeText = '';
	let identifierSystem = '';
	let identifierValue = '';

	const assigningAuthorityPrecedence =
		serverConfig.assigningAuthorityCodingPrecedence;
	const finalID = [];
	//Created a new var as modifying a model causes an error
	let reversedIdentifiers = _.cloneDeep(identifiers);
	if (reversedIdentifiers) {
		reversedIdentifiers = _.reverse(reversedIdentifiers);
	}
	if (reversedIdentifiers && assigningAuthorityPrecedence) {
		for (let j = 0; j < assigningAuthorityPrecedence.length; j++) {
			for (let i = 0; i < reversedIdentifiers.length; i++) {
				const reservedIdentifierObj = reversedIdentifiers[i];
				identifierSystem = reservedIdentifierObj.system;
				identifierValue = reservedIdentifierObj.value;
				identifierTypeText = '';
				if (
					reservedIdentifierObj.type &&
					reservedIdentifierObj.type.coding &&
					reservedIdentifierObj.type.coding.length > 0
				) {
					identifierTypeText = reservedIdentifierObj.type.coding[0].code;
				}
				if (
					hiddenId !== undefined &&
					hiddenId !== null &&
					hiddenId.indexOf(identifierSystem) === -1
				) {
					if (
						identifierValue != null &&
						identifierValue.trim() !== '' &&
						identifierSystem != null &&
						identifierSystem.trim() !== '' &&
						identifierSystem.indexOf(assigningAuthorityPrecedence[j]) !== -1
					) {
						finalID.push({
							system: identifierSystem,
							value: identifierValue,
							type: identifierTypeText,
						});
					}
				}
			}
		}
		for (let i = 0; i < reversedIdentifiers.length; i++) {
			//console.log(".indexOf"+JSON.stringify(reversedIdentifiers[i]))
			const identifierObj = reversedIdentifiers[i];
			identifierSystem = identifierObj.system;
			identifierValue = identifierObj.value;
			identifierTypeText = '';
			if (
				identifierObj.type &&
				identifierObj.type.coding &&
				identifierObj.type.coding.length > 0
			) {
				identifierTypeText = identifierObj.type.coding[0].code;
			}
			if (
				hiddenId != null &&
				hiddenId !== undefined &&
				hiddenId.indexOf(identifierSystem) === -1
			) {
				if (
					identifierValue != null &&
					identifierValue !== undefined &&
					identifierValue.trim() !== ''
				) {
					const patientIdFound = finalID.some(function (id) {
						return (
							id.system == identifierSystem &&
							id.value == identifierValue &&
							id.type == identifierTypeText
						);
					});
					if (!patientIdFound) {
						finalID.push({
							system: identifierSystem,
							value: identifierValue,
							type: identifierTypeText,
						});
					}
					//console.log(".identifierTypeListt"+JSON.stringify(identifierValueList))
				}
			}
		}
	}
	for (const identifier in finalID) {
		if (
			finalID[identifier].system == null ||
			finalID[identifier].system === undefined
		)
			finalID[identifier].system = '';
		if (
			finalID[identifier].type == null ||
			finalID[identifier].type === undefined
		)
			finalID[identifier].type = '';
	}
	if (label === 'single' && finalID && finalID.length > 0) {
		return finalID[0].value;
	}
	return finalID;
};

export const getBrowserName = () => {
	let browserName;

	if (
		navigator.userAgent.indexOf('Chrome') !== -1 ||
		navigator.userAgent.indexOf('CriOS') !== -1
	) {
		browserName = 'Chrome';
	} else if (
		navigator.userAgent.indexOf('Firefox') !== -1 ||
		navigator.userAgent.indexOf('FxiOS') !== -1
	) {
		browserName = 'Firefox';
	} else if (navigator.userAgent.toLowerCase().indexOf('msie 9.0') != -1) {
		browserName = 'IE9';
	} else if (navigator.userAgent.toLowerCase().indexOf('msie 10.0') != -1) {
		browserName = 'IE10';
	} else if (navigator.userAgent.toLowerCase().indexOf('.net4.0e') != -1) {
		browserName = 'IE11';
	} else if (
		(navigator.userAgent.indexOf('Opera') != -1 ||
			navigator.userAgent.indexOf('OPR')) != -1
	) {
		browserName = 'Opera';
	} else if (navigator.userAgent.indexOf('Safari') != -1) {
		browserName = 'Safari';
	} else if (navigator.userAgent.indexOf('Edge') > -1) {
		browserName = 'Edge';
	}

	return browserName;
};

export const calculateFileSize = (size: number) => {
	let result;
	if (size / 1024 < 1.0)
		result =
			parseFloat((Math.round(size * 100) / 100).toString()).toFixed(2) +
			' Bytes';
	else if (size / 1024 / 1024 < 1.0)
		result =
			parseFloat((Math.round((size / 1024) * 100) / 100).toString()).toFixed(
				2,
			) + ' KB';
	else if (size / 1024 / 1024 / 1024 < 1.0)
		result =
			parseFloat(
				(Math.round((size / 1024 / 1024) * 100) / 100).toString(),
			).toFixed(2) + ' MB';
	else if (size / 1024 / 1024 / 1024 / 1024 < 1.0)
		result =
			parseFloat(
				(Math.round((size / 1024 / 1024 / 1024) * 100) / 100).toString(),
			).toFixed(2) + ' GB';
	return result;
};

/**
 * returns extension of file
 * @param {string} fileName
 * @returns {string}
 */
export function getFileExtension(fileName: string) {
	let extension: string | undefined = '';
	if (fileName.indexOf('.') > -1) {
		const fileSplit = fileName.split('.');
		if (fileSplit.length >= 2) {
			extension = fileName.split('.').pop();
		}
	}

	if (extension) {
		extension = extension.toLowerCase();
		const isNumericExtension = /^\d+$/.test(extension);
		if (isNumericExtension) {
			extension = '';
		}
	}

	return extension;
}

/**
 * @param bytes
 * @param precision
 * @returns size of file
 */
export function getFileSize(bytes: any, precision: number) {
	if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
	if (typeof precision === 'undefined') precision = 1;
	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'],
		number = Math.floor(Math.log(bytes) / Math.log(1024));
	return (
		(bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
		' ' +
		units[number]
	);
}

/**
 * fetches element's value from extension list
 * @param extension
 * @param property
 * @param subProperty
 */
export function findElementInExtensionList(
	extension: Extension[],
	property: string,
	subProperty?: string,
) {
	let returnValue = '';
	if (extension && extension.length) {
		const targetProperty = subProperty
			? 'ext.value[0][subProperty]'
			: 'ext.value[0]';
		extension.forEach(function (ext, index) {
			if (ext.url === property) {
				returnValue = eval(targetProperty);
			}
		});
	}
	return returnValue;
}

/**
 * returns file name without extension
 * @param fileName
 */
export function fileNameWithoutExtension(fileName: string) {
	if (!fileName) {
		return;
	}
	let fileNameWithoutExtension = fileName.split('.');

	if (fileNameWithoutExtension && fileNameWithoutExtension.length > 1) {
		fileNameWithoutExtension = fileNameWithoutExtension.slice(0, -1);
	}

	return fileNameWithoutExtension.join('.');
}

/***
 * check demographics
 */
export const checkDemographics = (value: boolean | string | undefined) => {
	let returnValue = false;
	if (value == 'true' || value == true) {
		returnValue = true;
	}
	return returnValue;
};

/**
 * checkAtleastOneValidFile
 */
export const checkAtleastOneValidFile = (
	listOfDicomFiles: Record<string, any> | undefined,
) => {
	let result = false;
	for (const key in listOfDicomFiles) {
		if (_.has(listOfDicomFiles, key)) {
			const val = listOfDicomFiles[key];
			if (val.sendToServer) {
				result = true;
				break;
			}
		}
	}
	return result;
};

export function nameFromList(
	nameList: Array<Record<string, any>>,
	subPart?: string,
) {
	if (nameList && nameList.length > 0) {
		let mainObject = nameList[nameList.length - 1];
		if (mainObject.useCode == 'nickname') {
			mainObject = nameList[nameList.length - 2];
		}
		if (subPart && mainObject[subPart]) {
			return mainObject[subPart][0];
		}
		return mainObject.text;
	} else {
		return '';
	}
}

/**
 *
 * @param key setting key for setting in lcoalStorege
 * @param value setting value of key in lcoalStorege
 */
export function setInLocalStorage(key: string, value: any) {
	localStorage.setItem(key, value);
}

/**
 *
 * @param key fetching value from a key from lcoalStorege
 */
export function getInLocalStorage(key: string) {
	return localStorage.getItem(key);
}

/**
 * used to get the default RegEx for email
 */
export function getEmailRegex():RegExp {
	return RegExp(
		`^(([^\\<\\>\\(\\)\\[\\]\\\\.,;:\\s@"]+(\\.[^\\<\\>\\(\\)\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@(([[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`,
	);
}

/**
 * used to extract files from folder
 * @param dataTransferItems
 * @returns Promise<Array<any>>
 */
export function getFilesDataTransferItems(
	dataTransferItems: any,
): Promise<Array<any>> {
	function traverseFileTreePromise(item: any, path = '', folder: any) {
		return new Promise(resolve => {
			if (item.isFile) {
				item.file((file: any) => {
					file.filepath = path || '' + file.name; //save full path
					folder.push(file);
					resolve(file);
				});
			} else if (item.isDirectory) {
				const dirReader = item.createReader();
				dirReader.readEntries((entries: any) => {
					const entriesPromises = [];
					const subfolder: Array<any> = [];
					folder.push({name: item.name, subfolder: subfolder});
					for (const entr of entries)
						entriesPromises.push(
							traverseFileTreePromise(
								entr,
								path || '' + item.name + '/',
								subfolder,
							),
						);
					resolve(Promise.all(entriesPromises));
				});
			}
		});
	}

	const files: Array<File> = [];
	return new Promise((resolve, reject) => {
		const entriesPromises = [];
		for (const it of dataTransferItems)
			entriesPromises.push(
				traverseFileTreePromise(it.webkitGetAsEntry(), '', files),
			);
		Promise.all(entriesPromises).then(entries => {
			resolve(files);
		});
	});
}

