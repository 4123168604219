import React, {lazy, Suspense} from 'react';
import {Route} from 'react-router-dom';
import RoutePathInfo from '../../common/src/enums/RoutingPath';
import Loader from '../../ui/comps/src/Loader';

const VitalOverviewComponent = lazy(() => import('./components/VitalOverview'));
const VitalDetailComponent = lazy(() => import('./components/VitalDetail'));
const VitalRoutes = (
	<>
		<Route
			path={RoutePathInfo.vitals.path}
			element={
				<Suspense fallback={<Loader />}>
					<VitalOverviewComponent />
				</Suspense>
			}
		/>
		<Route
			path={RoutePathInfo.vitalDetail.path}
			element={
				<Suspense fallback={<Loader />}>
					<VitalDetailComponent />
				</Suspense>
			}
		/>
	</>
);

export default VitalRoutes;
