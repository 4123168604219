import {useEffect, useRef, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FileUploaded} from '../../../../../../common/src/application/dynamicFormUtil/types/DynamicForm.types';
import {getBase64, getFilesFromBase64} from '../../../utils/uiCompsUtils';
import UploadComponent from './sub-components/Upload.component';
import './fileUpload.scss';

const FileUploadController = (props: any): JSX.Element => {
	const {
		fieldData,
		dynamicConstraints,
		register,
		configProvider,
		control,
		isViewOnlyMode,
		errors,
		formModel,
	} = props;

	const fileInputRef = useRef<any>(null);
	const folderInputRef = useRef<any>(null);

	const {constraints, name, fileUploadOptions} = fieldData;

	const formContext = useFormContext();

	const getAllPersistedFiles = (files: Array<Record<string, any>>) => {
		const convertedFiles = getFilesFromBase64(files);
		return convertedFiles;
	};

	const [uploadedFiles, setUploadedFiles] = useState<Array<Record<string, any>>|Array<File>>();

	useEffect(() => {
		if (formModel && formModel[name] && formModel[name]?.files?.length > 0) {
			const convertedFiles = getAllPersistedFiles(formModel[name].files);
			setUploadedFiles(convertedFiles);
		}
		return () => formContext.unregister(name);
	}, []);

	const handleSetFiles = (fileItems: any, field: any, deleteFlag:boolean = false) => {
		let files:Array<File> = [];
		if(fileUploadOptions.isMultiSelect){
			if(uploadedFiles && uploadedFiles.length > 0 && !deleteFlag){
				let newFiles = [...fileItems];
				let uploadedFileNames = uploadedFiles?.map(item=>item.name);
				newFiles = newFiles.filter(element => {
					return !uploadedFileNames.includes(element.name)
				});
				files = [...uploadedFiles, ...newFiles];
			}else{
				files =  [...fileItems];
			}
		}else{
			files = [...fileItems];
		}
		let base64Files: Array<FileUploaded> = [];
		setUploadedFiles(files);
		if (files.length > 0) {
			files.forEach((file: File) => {
				getBase64(file).then(base64String => {
					if (base64String != null && file.type != null && name != null) {
						base64Files.push({
							mimeType: file.type,
							name: file.name,
							base64String: base64String,
						});
					}
					field.onChange({files: base64Files});
				});
			});
		} else {
			field.onChange(null);
			fileInputRef.current.value = '';
		}
	};

	const deleteFileHandler = (index: number, field: any) => {
		if (uploadedFiles && uploadedFiles.length > 0) {
			uploadedFiles?.splice(index, 1);
			const copy = uploadedFiles?.slice();
			handleSetFiles(copy, field, true);
			fileInputRef.current.value = '';
		}
	};

	const fileValidator = (file: any) => {
		//file validations can be done here
		return null;
	};

	return (
		<Controller
			control={control}
			name={name}
			rules={{
				required: {
					value: dynamicConstraints?.required ?? false,
					message: constraints?.required?.message ?? '',
				},
			}}
			defaultValue={dynamicConstraints.defaultValue ?? null}
			render={({field}) => {
				if (
					field?.value?.files &&
					typeof field.value.files === 'object' &&
					(!uploadedFiles || uploadedFiles?.length === 0)
				) {
					const convertedFiles = getAllPersistedFiles(field?.value?.files);
					setUploadedFiles(convertedFiles);
				}
				return (
					<UploadComponent
						fieldData={fieldData}
						dynamicConstraints={dynamicConstraints}
						register={register}
						configProvider={configProvider}
						handleChange={(files: any) => handleSetFiles(files, field)}
						deleteFileHandler={(index: number) =>
							deleteFileHandler(index, field)
						}
						fileValidator={fileValidator}
						errors={errors}
						isViewOnlyMode={isViewOnlyMode}
						uploadedFiles={uploadedFiles}
						fileInputRef={fileInputRef}
						folderInputRef={folderInputRef}
					/>
				);
			}}
		/>
	);
};

export default FileUploadController;
