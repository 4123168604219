import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {initI18n} from './localization/i18n';
import {BrowserRouter, HashRouter} from 'react-router-dom';

import getFirebaseAnalyticsConfig from './common/src/application/analytics/getFirebaseAnalyticsConfig';
import {initializeApp} from 'firebase/app';
import GlobalSubscribers from './utils/subscribers/GlobalSubscribers';
import 'reflect-metadata';
import './app.imports';
import loadConfiguration from './utils/ConfigurationLoader';

global.platform = 'web';

//todo @aniket will find a better way to initiate the same
// if (
// 	(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
// 	window.location.href === Configuration.urls.applicationFrontendBase
// ) {
// 	window.location.href =
// 		Configuration.urls.applicationFrontendBase.concat('newApp');
// }

GlobalSubscribers.init();
loadConfiguration()
	.then()
	.finally(() => {
		initI18n()
			.then(r => {
				//getFirebaseAnalyticsConfig();
				// .then((firebaseConfig: any) => {
				// 	if (firebaseConfig) {
				// 		const firebaseApp = initializeApp(firebaseConfig);
				// 	}
				// })
			})
			.finally(() => {
				ReactDOM.render(
					<React.StrictMode>
						<HashRouter>
							<App />
						</HashRouter>
					</React.StrictMode>,
					document.getElementById('root'),
				);
			});
	});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
